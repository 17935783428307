import { USER_API_URL } from "config/appConfig";
import { callAPI } from "./call-api";

export const getGroup = async (organizationId: string, groupId: string) =>
  await callAPI({
    method: "GET",
    url: `${USER_API_URL}/organizations/${organizationId}/groups/${groupId}`,
  });

export const getGroups = async (organizationId: string) =>
  await callAPI({
    method: "GET",
    url: `${USER_API_URL}/organizations/${organizationId}/groups`,
  });

export const postGroup = async (organizationId: string, data: any) =>
  await callAPI({
    method: "POST",
    url: `${USER_API_URL}/organizations/${organizationId}/groups`,
    data,
  });

export const patchGroup = async (
  organizationId: string,
  groupId: string,
  data: any
) =>
  await callAPI({
    method: "PATCH",
    url: `${USER_API_URL}/organizations/${organizationId}/groups/${groupId}`,
    data,
  });

export const deleteGroup = async (organizationId: string, groupId: string) =>
  await callAPI({
    method: "DELETE",
    url: `${USER_API_URL}/organizations/${organizationId}/groups/${groupId}`,
  });

export const getGroupMembers = async (
  organizationId: string,
  groupId: string
) =>
  await callAPI({
    method: "GET",
    url: `${USER_API_URL}/organizations/${organizationId}/groups/members/${groupId}`,
  });

export const postGroupMembers = async (
  organizationId: string,
  groupId: string,
  userIds: string[]
) =>
  await callAPI({
    method: "POST",
    url: `${USER_API_URL}/organizations/${organizationId}/groups/members`,
    data: { group_id: groupId, user_ids: userIds.join(",") },
  });

export const deleteGroupMembers = async (
  organizationId: string,
  groupId: string,
  userIds: string[]
) =>
  await callAPI({
    method: "DELETE",
    url: `${USER_API_URL}/organizations/${organizationId}/groups/members/${groupId}/${userIds.join(
      ","
    )}`,
  });

const groupService = {
  getGroup,
  patchGroup,
  deleteGroup,
  postGroupMembers,
  getGroupMembers,
  deleteGroupMembers,
};

export default groupService;
