import { useMemo, type PropsWithChildren } from "react";
import { LDProvider, type LDContext } from "launchdarkly-react-client-sdk";
import { useAuth0 } from "@auth0/auth0-react";
import { useUserState } from "store";
import { launchDarklyConfig } from "config/launchDarklyConfig";

export function LaunchDarkly({ children }: PropsWithChildren) {
  const { user, isLoading, isAuthenticated } = useAuth0();
  const userId = useUserState((slice) => slice.userID);

  const context = useMemo<LDContext | undefined>(() => {
    if (isLoading) {
      // wait for Auth0 to finish loading
      return;
    }
    if (!isAuthenticated) {
      // anonymous user
      return {
        kind: "user",
        anonymous: true,
      };
    }
    // wait for sign-in to finish
    if (user && userId) {
      // authenticated user
      return {
        kind: "user",
        key: userId,
        email: user.email,
      };
    }
  }, [isLoading, isAuthenticated, user, userId]);

  return (
    <LDProvider {...launchDarklyConfig} context={context}>
      {children}
    </LDProvider>
  );
}
