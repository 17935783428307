import { ASSET_API_URL, CANVAS_API_URL } from "config/appConfig";
import { callAPI } from "./call-api";
import {
  postConstant,
  putConstant,
  deleteConstantById,
  postConstantVersion,
  editConstantVersion,
  createConstantVersion,
  deleteConstantVersion,
  putConstantVersionStatus,
  putArchiveConstantVersion,
  putConstantVersionSecret,
  putConstantAssetArchive,
} from "./assets/constants-service";
import {
  postFunction,
  putFunction,
  deleteFunctionById,
  deleteFunctionVersion,
  postFunctionVersion,
  putFunctionAssetArchive,
  patchFunctionVersion,
  putFunctionVersionStatus,
  putFunctionVersionArchive,
} from "./assets/functions-service";

import {
  deleteQueryById,
  postQuery,
  putQuery,
  patchQueryVersion,
  putQueryArchive,
  putQueryVersionArchive,
  postQueryVersion,
  putQueryVersionStatus,
  deleteQueryVersion,
} from "./assets/queries-service";

import {
  AssetPageTabUrl,
  type AssetUrlPageType,
  type CanvasAssetMembershipAddResponseType,
  type CanvasAssetMembershipDeleteResponseType,
  type CanvasAssetMembershipUpdateResponseType,
} from "pages/Assets/types/abstractTypes";

const getAssetsByUser = async (assetType: AssetUrlPageType) =>
  await callAPI({
    url: `${ASSET_API_URL}/${AssetPageTabUrl[assetType]}/user`,
    method: "GET",
  });

const getAssetsByWorkspaceId = async (
  workspaceID: string,
  assetType: AssetUrlPageType
) =>
  await callAPI({
    url: `${ASSET_API_URL}/${AssetPageTabUrl[assetType]}/workspace/${workspaceID}`,
    method: "GET",
  });

const getAssetsByCanvasId = async (
  canvasId: string,
  assetType: AssetUrlPageType
) =>
  await callAPI({
    url: `${ASSET_API_URL}/${AssetPageTabUrl[assetType]}/membership/canvas/${canvasId}`,
    method: "GET",
  });

const addAssetVersionToCanvas = async ({
  canvasId,
  alias,
  assetId,
  assetVersionId,
  assetType,
  wsConnectionId,
}: {
  canvasId: string;
  alias?: string;
  assetId: string;
  assetVersionId: string;
  assetType: AssetUrlPageType;
  wsConnectionId?: string;
}) => {
  let url = `${CANVAS_API_URL}/canvas/${canvasId}/assets`;

  if (wsConnectionId) {
    url = url + `?connection_id=${wsConnectionId}`;
  }

  return await callAPI<CanvasAssetMembershipAddResponseType>({
    url,
    method: "POST",
    data: {
      alias,
      asset_id: assetId,
      asset_version_id: assetVersionId,
      asset_type: AssetPageTabUrl[assetType],
    },
  });
};

const updateAssetCanvasMembership = async ({
  canvasId,
  alias,
  assetId,
  assetVersionId,
  assetMembershipId,
  assetType,
  wsConnectionId,
}: {
  canvasId: string;
  alias?: string;
  assetId: string;
  assetVersionId: string;
  assetMembershipId: string;
  assetType: AssetUrlPageType;
  wsConnectionId?: string;
}) => {
  let url = `${CANVAS_API_URL}/canvas/${canvasId}/assets`;

  if (wsConnectionId) {
    url = url + `?connection_id=${wsConnectionId}`;
  }

  return await callAPI<CanvasAssetMembershipUpdateResponseType>({
    url,
    method: "PATCH",
    data: {
      alias,
      asset_id: assetId,
      asset_version_id: assetVersionId,
      asset_membership_id: assetMembershipId,
      asset_type: AssetPageTabUrl[assetType],
    },
  });
};

const deleteAssetFromCanvas = async ({
  assetId,
  assetType,
  assetVersionId,
  canvasId,
  wsConnectionId,
}: {
  assetId: string;
  assetType: AssetUrlPageType;
  assetVersionId: string;
  canvasId: string;
  wsConnectionId?: string;
}) => {
  let url = `${CANVAS_API_URL}/canvas/${canvasId}/assets`;

  if (wsConnectionId) {
    url = url + `?connection_id=${wsConnectionId}`;
  }

  return await callAPI<CanvasAssetMembershipDeleteResponseType>({
    url,
    method: "DELETE",
    data: {
      asset_id: assetId,
      asset_version_id: assetVersionId,
      asset_type: AssetPageTabUrl[assetType],
    },
  });
};

export {
  createConstantVersion,
  deleteAssetFromCanvas,
  deleteConstantById,
  deleteConstantVersion,
  deleteFunctionById,
  deleteFunctionVersion,
  deleteQueryById,
  editConstantVersion,
  getAssetsByUser,
  getAssetsByWorkspaceId,
  addAssetVersionToCanvas,
  updateAssetCanvasMembership,
  getAssetsByCanvasId,
  patchFunctionVersion,
  postConstant,
  putConstant,
  postConstantVersion,
  postFunction,
  putFunction,
  postFunctionVersion,
  putArchiveConstantVersion,
  putConstantAssetArchive,
  putConstantVersionSecret,
  putConstantVersionStatus,
  putFunctionAssetArchive,
  putFunctionVersionStatus,
  putFunctionVersionArchive,
  postQuery,
  putQuery,
  patchQueryVersion,
  putQueryArchive,
  putQueryVersionArchive,
  postQueryVersion,
  putQueryVersionStatus,
  deleteQueryVersion,
};
