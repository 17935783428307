import moment from "moment";
import pluralize from "pluralize";
import { useCanvasSorting } from "hooks/useCanvasSorting";
import {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
  type MouseEvent,
} from "react";
import { BiX } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import { useConfirmModal } from "hooks/useConfirmModal";
import { useToastsState, useLocalAppPreferencesState } from "store";
import canvasService from "api/http/canvas-service";
import { Button } from "components/common/Button/Button";
import Typography from "components/Typography/Typography";
import IconButton from "components/common/IconButton/IconButton";
import { type SORTING_COLUMN } from "config/localPreferences";
import { type SORTING_DIRECTION } from "config/appConfig";
import styles from "components/canvases/CanvasesListLayout/CanvasesListLayout.module.scss";
import type { AppCardType } from "models/app";
import { ConfirmAppsDeletionModal } from "components/blocks/ConfirmAppsDeletionModal/ConfirmAppsDeletionModal";

enum APP_ACTION {
  delete = "delete",
  rename = "rename",
  toggleStar = "toggleStar",
}

interface UseAppsProps {
  isLoadingInitial?: boolean;
}

export function useApps({ isLoadingInitial = false }: UseAppsProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = location.pathname;

  const addToast = useToastsState((slice) => slice.addToast);
  const { openConfirmModal } = useConfirmModal();

  const [isLoading, setIsLoading] = useState(isLoadingInitial);
  const [searchQuery, setSearchQuery] = useState("");
  const [apps, setApps] = useState<AppCardType[]>([]);
  const [selectedAppIds, setSelectedAppIds] = useState<Record<string, string>>(
    {}
  );
  // app id to action map
  const [processedAppsIdActionMap, setProcessedAppsIdActionMap] = useState<
    Record<string, APP_ACTION>
  >({});

  // timeout is needed to prevent firing onClick before onDoubleClick
  const selectAppTimeoutId = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    return () => {
      clearTimeout(selectAppTimeoutId.current);
    };
  }, []);

  const {
    appsDisplayMode,
    appsSortingColumn,
    appsSortingDirection,
    setAppsDisplayMode,
    setAppsSortingColumn,
    setAppsSortingDirection,
  } = useLocalAppPreferencesState((slice) => {
    return {
      appsDisplayMode: slice.appsDisplayMode,
      appsSortingColumn: slice.appsSortingColumn,
      appsSortingDirection: slice.appsSortingDirection,
      setAppsDisplayMode: slice.setAppsDisplayMode,
      setAppsSortingColumn: slice.setAppsSortingColumn,
      setAppsSortingDirection: slice.setAppsSortingDirection,
    };
  });

  const currentSorting = useMemo(() => {
    return {
      column: appsSortingColumn,
      direction: appsSortingDirection,
    };
  }, [appsSortingColumn, appsSortingDirection]);

  const selectedAppsIdsList = useMemo(() => {
    return Object.values(selectedAppIds);
  }, [selectedAppIds]);

  const sortedItems = useCanvasSorting(currentSorting, apps, searchQuery);

  const handleSearchQueryChange = useCallback((e) => {
    setSearchQuery(e.target.value);
  }, []);

  const handleDropSelection = useCallback(() => {
    setSelectedAppIds({});
  }, []);

  const setProcessedAppIdAction = useCallback(
    (id: string, action: APP_ACTION) => {
      setProcessedAppsIdActionMap((ids) => {
        return {
          ...ids,
          [id]: action,
        };
      });
    },
    []
  );

  const removeProcessedAppId = useCallback((id: string) => {
    setProcessedAppsIdActionMap((ids) => {
      const { [id]: _, ...rest } = ids;

      return rest;
    });
  }, []);

  useEffect(() => {
    if (!sortedItems.length && selectedAppsIdsList.length > 0) {
      handleDropSelection();

      return;
    }

    const idsToRemove: string[] = [];
    selectedAppsIdsList.forEach((id) => {
      if (!sortedItems.some((app) => app.id === id)) {
        idsToRemove.push(id);
      }
    });

    if (!idsToRemove.length) {
      return;
    }

    setSelectedAppIds((ids) => {
      return idsToRemove.reduce((acc, curr) => {
        const { [curr]: _, ...rest } = acc;

        return rest;
      }, ids);
    });
  }, [sortedItems]);

  const noApps = apps.length === 0;
  const notFound = !noApps && sortedItems.length === 0;

  const handleChangeSorting = ({
    column,
    direction,
  }: {
    column: SORTING_COLUMN;
    direction: SORTING_DIRECTION;
  }) => {
    setAppsSortingColumn(column);
    setAppsSortingDirection(direction);
  };

  const appNameChangeHandler = (appId: string) => (name: string) => {
    // set name to the canvas state to avoid new/old name flashing
    let prevName: string = "Untitled";
    // TODO: get lastModified from response when BE returns updated canvas data
    let lastModified: AppCardType["lastModified"];
    const trimmedName = name.trim();
    setApps((items) => {
      return items.map((item) => {
        if (item.id !== appId) {
          return item;
        }

        prevName = item.name;
        lastModified = item.lastModified;

        return {
          ...item,
          name: trimmedName,
          lastModified: moment().format(),
        };
      });
    });
    setProcessedAppIdAction(appId, APP_ACTION.rename);
    canvasService
      .putRenameApp(appId, trimmedName)
      .catch(() => {
        // revert name
        setApps((items) => {
          return items.map((item) => {
            if (item.id !== appId) {
              return item;
            }

            return {
              ...item,
              name: prevName,
              lastModified,
            };
          });
        });
        addToast({
          message: "Error renaming app",
          variant: "error",
        });
      })
      .finally(() => {
        removeProcessedAppId(appId);
      });
  };

  const openApp = useCallback((appId: string) => {
    navigate(`${currentUrl}/${appId}`);
  }, []);

  const toggleSelectApp = useCallback((appId: string) => {
    setSelectedAppIds((ids) => {
      if (ids[appId]) {
        const { [appId]: _, ...rest } = ids;

        return rest;
      }

      return {
        ...ids,
        [appId]: appId,
      };
    });
  }, []);

  const handleAppClick = useCallback(
    (appId: string, event: MouseEvent<HTMLElement>) => {
      clearTimeout(selectAppTimeoutId.current);
      // event.detail: 1 - single click, 2 - double click, 3 - triple click
      if (event.detail !== 1) {
        openApp(appId);
        return;
      }

      selectAppTimeoutId.current = setTimeout(() => {
        toggleSelectApp(appId);
      }, 250);
    },
    []
  );

  const handleDeleteApps = useCallback(
    async (appIds: string[]) => {
      if (!appIds.length) {
        return;
      }

      const confirmed = await openConfirmModal({
        message: <ConfirmAppsDeletionModal appIds={appIds} apps={apps} />,
        confirmButtonLabel: "Delete",
        confirmButtonVariant: "crucial",
      });

      if (!confirmed) {
        return;
      }

      setProcessedAppsIdActionMap((ids) => {
        return appIds.reduce((acc, curr) => {
          return {
            ...acc,
            [curr]: APP_ACTION.delete,
          };
        }, ids);
      });

      try {
        await canvasService.deleteAppsByIds(appIds);
        setApps((items) =>
          items.filter((item) => {
            return !appIds.includes(item.id);
          })
        );
        addToast({
          variant: "success",
          message:
            appIds.length > 1
              ? "Successfully deleted apps"
              : "Successfully deleted app",
        });
      } catch (rejection) {
        addToast({
          variant: "error",
          message: `${rejection}`,
        });
      }

      setProcessedAppsIdActionMap((ids) => {
        const newIds = { ...ids };
        appIds.forEach((id) => {
          delete newIds[id];
        });
        return newIds;
      });
    },
    [apps]
  );

  const handleDeleteSelectedApps = useCallback(() => {
    handleDeleteApps(selectedAppsIdsList);
  }, [selectedAppsIdsList, handleDeleteApps]);

  const toolbar = useMemo(() => {
    if (isLoading) {
      return null;
    }

    const selectedAppsCount = selectedAppsIdsList.length;

    if (!selectedAppsCount) {
      return null;
    }

    const { openAppsDisabled, deleteAppsDisabled, clearSelectedDisabled } =
      (() => {
        const disabledButtonMap = {
          openAppsDisabled: false,
          deleteAppsDisabled: false,
          clearSelectedDisabled: false,
        };

        if (!Object.keys(processedAppsIdActionMap).length) {
          return disabledButtonMap;
        }

        for (const selectedAppId of selectedAppsIdsList) {
          const action = processedAppsIdActionMap[selectedAppId];

          if (!action) {
            continue;
          }
          if (action === APP_ACTION.delete) {
            disabledButtonMap.openAppsDisabled = true;
            disabledButtonMap.deleteAppsDisabled = true;
            disabledButtonMap.clearSelectedDisabled = true;
            break;
          }

          disabledButtonMap.deleteAppsDisabled = true;
          break;
        }

        return disabledButtonMap;
      })();

    return (
      <div className={styles.toolbar}>
        <div>
          <Typography
            component="span"
            variant="caption1"
            className={styles.selected_count}
          >
            {selectedAppsCount}
          </Typography>
          <Typography
            component="span"
            variant="caption1"
            className={styles.selected_label}
          >
            {`${pluralize("Item", selectedAppsCount)} Selected`}
          </Typography>
        </div>
        <div className={styles.toolbar_actions}>
          {selectedAppsCount === 1 && (
            <Button
              className={styles.toolbar_actions_button}
              variant="secondary"
              disabled={openAppsDisabled}
              onClick={() => {
                openApp(selectedAppsIdsList[0]);
              }}
            >
              Open
            </Button>
          )}
          <Button
            className={styles.toolbar_actions_button}
            variant="crucial"
            disabled={deleteAppsDisabled}
            onClick={handleDeleteSelectedApps}
          >
            Delete
          </Button>
          <IconButton
            disabled={clearSelectedDisabled}
            onClick={handleDropSelection}
          >
            <BiX size={20} />
          </IconButton>
        </div>
      </div>
    );
  }, [
    selectedAppsIdsList,
    handleDeleteSelectedApps,
    processedAppsIdActionMap,
    isLoading,
  ]);

  return {
    searchQuery,
    isLoading,
    noApps,
    notFound,
    sortedItems,
    appsDisplayMode,
    appsSortingColumn,
    appsSortingDirection,
    processedAppsIdActionMap,
    selectedAppIds,
    toolbar,
    appNameChangeHandler,
    handleDropSelection,
    handleAppClick,
    handleDeleteApps,
    handleChangeSorting,
    handleSearchQueryChange,
    openApp,
    removeProcessedAppId,
    setApps,
    setAppsDisplayMode,
    setIsLoading,
    setProcessedAppIdAction,
    setSearchQuery,
  };
}
