import { useMemo } from "react";
import { Outlet } from "react-router-dom";
import {
  BiShapeSquare,
  BiAnalyse,
  BiLibrary,
  BiCog,
  BiBell,
} from "react-icons/bi";
import { useFlags } from "launchdarkly-react-client-sdk";
import { NavigationTabs } from "components/common/NavigationTabs/NavigationTabs";
import { type NavigationTabsItem } from "components/common/NavigationTabs/NavigationTabs";

export function PersonalTabs() {
  const { enableEnvironments, enableNotifications } = useFlags();

  const tabs = useMemo(() => {
    const items: NavigationTabsItem[] = [
      {
        key: "canvases",
        label: (
          <>
            <BiShapeSquare size={20} />
            <span>Canvases</span>
          </>
        ),
        to: "/canvases",
      },
    ];
    if (enableEnvironments) {
      items.push({
        key: "environments",
        label: (
          <>
            <BiAnalyse size={20} />
            <span>Environments</span>
          </>
        ),
        to: "/environments",
      });
    }
    items.push({
      key: "assets",
      label: (
        <>
          <BiLibrary size={20} />
          <span>Assets</span>
        </>
      ),
      to: "/assets",
    });
    if (enableNotifications) {
      items.push({
        key: "notifications",
        label: (
          <>
            <BiBell size={20} />
            <span>Notifications</span>
          </>
        ),
        to: "/notifications",
      });
    }
    items.push({
      key: "settings",
      label: (
        <>
          <BiCog size={20} />
          <span>Settings</span>
        </>
      ),
      to: "/settings",
    });
    return items;
  }, [enableEnvironments, enableNotifications]);

  return (
    <>
      <NavigationTabs orientation="horizontal" items={tabs} />
      <Outlet />
    </>
  );
}
