import { CANVAS_API_URL } from "config/appConfig";
import { callAPI } from "./call-api";
import type {
  Requirement,
  LinuxPackage,
  EnvironmentVariable,
  Executor,
} from "models/requirements";

export async function getBuildLogs(canvas_id: string) {
  return await callAPI({
    url: `${CANVAS_API_URL}/canvas/build_logs/${canvas_id}`,
    method: "GET",
  });
}

export async function getCanvasExecutor(canvas_id: string) {
  return await callAPI<Executor>({
    url: `${CANVAS_API_URL}/executor/${canvas_id}`,
    method: "GET",
  });
}

export async function postUpdateRequirements(
  canvas_id: string,
  requirements: any[]
) {
  return await callAPI({
    url: `${CANVAS_API_URL}/canvas/requirements/${canvas_id}`,
    method: "POST",
    data: { requirement_list: requirements },
  });
}

export async function resetRequirementsEnvironment({
  canvasId,
}: {
  canvasId: string;
}) {
  return await callAPI({
    url: `${CANVAS_API_URL}/canvas_requirements/reset_environment/${canvasId}`,
    method: "DELETE",
  });
}

export async function getCanvasRequirements(canvasId: string) {
  return await callAPI({
    url: `${CANVAS_API_URL}/canvas_requirements/${canvasId}`,
    method: "GET",
  });
}

export async function postCanvasRequirements({
  canvasId,
  requirements,
  linuxPackages,
  environmentVariables,
}: {
  canvasId: string;
  requirements: Requirement[];
  linuxPackages: LinuxPackage[];
  environmentVariables: EnvironmentVariable[];
}) {
  return await callAPI({
    url: `${CANVAS_API_URL}/canvas_requirements/`,
    method: "POST",
    data: {
      canvas_id: canvasId,
      requirements,
      linux_packages: linuxPackages,
      environment_variables: environmentVariables,
    },
  });
}

type RequirementsResponse = {
  requirements: string[];
  required_requirements: string[];
};

export async function getPythonRequirements(canvasId: string) {
  return await callAPI<RequirementsResponse>({
    url: `${CANVAS_API_URL}/canvas_requirements/requirements/${canvasId}`,
    method: "GET",
  });
}

export async function getLinuxPackages(canvasId: string) {
  return await callAPI<string[]>({
    url: `${CANVAS_API_URL}/canvas_requirements/linux_packages/${canvasId}`,
    method: "GET",
  });
}

export async function getEnvironmentVariables(canvasId: string) {
  return await callAPI<string[]>({
    url: `${CANVAS_API_URL}/canvas_requirements/environment_variables/${canvasId}`,
    method: "GET",
  });
}

export async function patchPythonRequirements({
  canvasId,
  requirements,
}: {
  canvasId: string;
  requirements: string[];
}) {
  return await callAPI({
    url: `${CANVAS_API_URL}/canvas_requirements/requirements`,
    method: "PATCH",
    data: { canvas_id: canvasId, requirements },
  });
}

export async function patchLinuxPackages({
  canvasId,
  linuxPackages,
}: {
  canvasId: string;
  linuxPackages: string[];
}) {
  return await callAPI({
    url: `${CANVAS_API_URL}/canvas_requirements/linux_packages`,
    method: "PATCH",
    data: { canvas_id: canvasId, linux_packages: linuxPackages },
  });
}

export async function patchEnvironmentVariables({
  canvasId,
  environmentVariables,
}: {
  canvasId: string;
  environmentVariables: string[];
}) {
  return await callAPI({
    url: `${CANVAS_API_URL}/canvas_requirements/environment_variables`,
    method: "PATCH",
    data: { canvas_id: canvasId, environment_variables: environmentVariables },
  });
}
