import { type StateCreator } from "store";
import type {
  CreatedFunctionType,
  FunctionItemType,
  FunctionVersionType,
} from "pages/Assets/types/functionTypes";
import { DATA_LOAD_STATUS } from "config/appConfig";
import type { AssetItemOperationType } from "pages/Assets/types/abstractTypes";
import type { BaseAssetCanvasType } from "pages/Assets/types/baseAssetTypes";

const FUNCTIONS_INITIAL_STATE = {
  functionsData: {},
  canvasFunctionsData: {},
  isDisplayArchivedFunctions: false,
  selectedFunction: {
    id: null,
    versionNumber: null,
  },
  createdFunctionData: {
    assetOperationType: null,
    error: null,
    loading: false,
    functionData: {
      name: "",
      language: "python",
    },
  },
  functionsDataLoadState: DATA_LOAD_STATUS.NOT_LOADED,
  canvasFunctionsDataLoadState: DATA_LOAD_STATUS.NOT_LOADED,
};

interface FunctionsAssets {
  functionsData: Record<string, FunctionItemType>;
  canvasFunctionsData: Record<string, FunctionItemType>;
  isDisplayArchivedFunctions: boolean;
  selectedFunction: {
    id: string | null;
    versionNumber: number | null;
  };
  createdFunctionData: {
    assetOperationType: AssetItemOperationType;
    functionData: CreatedFunctionType;
    error: string | null;
    loading: boolean;
  };
  functionsDataLoadState: DATA_LOAD_STATUS;
  canvasFunctionsDataLoadState: DATA_LOAD_STATUS;
}

export type FunctionVersionTypeWithAssetId = FunctionVersionType & {
  asset_id: string;
};

interface FunctionsActions {
  setCanvasFunction: (data: FunctionItemType) => void;
  setFunctionsData: (functionsData: Record<string, FunctionItemType>) => void;
  setCanvasFunctionsData: (
    functionsData: Record<string, FunctionItemType>
  ) => void;
  setSelectedFunctionId: (selectedFunctionId: string | null) => void;
  setSelectedFunctionVersionNumber: (selectedFunctionId: number | null) => void;
  setCreatedFunctionData: (
    createdFunctionData: Partial<CreatedFunctionType>
  ) => void;
  setFunctionOperationType: (operationType: AssetItemOperationType) => void;
  setIsDisplayArchivedFunctions: (isDisplay: boolean) => void;
  clearSelectedFunction: () => void;
  clearCreatedFunctionData: () => void;
  setCreatedFunctionError: (error: string | null) => void;
  setCreatedFunctionLoading: (loading: boolean) => void;
  updateFunctionVersion: (version: FunctionVersionTypeWithAssetId) => void;
  updateFunctionVersionData: (
    assetId: FunctionVersionType["asset_id"],
    version: FunctionVersionType["version"],
    data: Partial<FunctionVersionType>
  ) => void;
  deleteFunctionVersion: (versionNumber: number) => void;
  setFunctionsDataLoadState: (state: DATA_LOAD_STATUS) => void;
  setCanvasFunctionsDataLoadState: (state: DATA_LOAD_STATUS) => void;
  deleteFunctionById: (id: string) => void;
  deleteCanvasFunctionById: (id: string) => void;
  deleteFunctionVersionCanvasUsage: (data: {
    assetId: string;
    versionId: string;
    canvasId: string;
  }) => void;
  addFunctionVersionCanvasUsage: (data: {
    assetId: string;
    versionId: string;
    canvasData: BaseAssetCanvasType;
  }) => void;
  clearStore: () => void;
}

export interface FunctionsAssetsSlice {
  functions: FunctionsAssets;
  functionsActions: FunctionsActions;
}

const functionsAssetsSlice: StateCreator<FunctionsAssetsSlice> = function (
  set,
  getState
) {
  return {
    functions: { ...FUNCTIONS_INITIAL_STATE },
    functionsActions: {
      setCanvasFunction: (data) => {
        const assetId = data.function.id;

        set(
          (store) => {
            store.assetsSlice.functions.canvasFunctionsData[assetId] = data;
            ``;
          },
          false,
          "assets/setCanvasFunction"
        );
      },
      setFunctionsData: (functionsData) => {
        set(
          (store) => {
            store.assetsSlice.functions.functionsData = functionsData;
          },
          false,
          "assets/setFunctionsData"
        );
      },
      setCanvasFunctionsData(canvasFunctionsData) {
        set(
          (store) => {
            store.assetsSlice.functions.canvasFunctionsData =
              canvasFunctionsData;
          },
          false,
          "assets/setCanvasFunctionsData"
        );
      },
      setIsDisplayArchivedFunctions: (isDisplay) => {
        set(
          (store) => {
            store.assetsSlice.functions.isDisplayArchivedFunctions = isDisplay;
          },
          false,
          "assets/setIsDisplayArchivedFunctions"
        );
      },
      setSelectedFunctionId: (selectedFunctionId) => {
        set(
          (store) => {
            store.assetsSlice.functions.selectedFunction.id =
              selectedFunctionId;
          },
          false,
          "assets/setSelectedFunctionId"
        );
      },
      setSelectedFunctionVersionNumber: (versionNumber) => {
        set(
          (store) => {
            store.assetsSlice.functions.selectedFunction.versionNumber =
              versionNumber;
          },
          false,
          "assets/setSelectedFunctionVersionNumber"
        );
      },
      setFunctionOperationType: (operationType) => {
        set(
          (store) => {
            store.assetsSlice.functions.createdFunctionData.assetOperationType =
              operationType;
          },
          false,
          "assets/setFunctionOperationType"
        );
      },
      setCreatedFunctionData: (data) => {
        set(
          (store) => {
            store.assetsSlice.functions.createdFunctionData.functionData = {
              ...store.assetsSlice.functions.createdFunctionData.functionData,
              ...data,
            };
          },
          false,
          "assets/setCreatedFunctionData"
        );
      },
      setCreatedFunctionError: (error) => {
        set(
          (store) => {
            store.assetsSlice.functions.createdFunctionData.error = error;
          },
          false,
          "assets/setCreatedFunctionError"
        );
      },
      setCreatedFunctionLoading: (loading) => {
        set(
          (store) => {
            store.assetsSlice.functions.createdFunctionData.loading = loading;
          },
          false,
          "assets/setCreatedFunctionLoading"
        );
      },
      updateFunctionVersion: (updatedVersionData) => {
        set(
          (store) => {
            if (
              !store.assetsSlice.functions.functionsData[
                updatedVersionData.asset_id
              ]?.versions
            ) {
              return;
            }

            store.assetsSlice.functions.functionsData[
              updatedVersionData.asset_id
            ].versions[updatedVersionData.version] = updatedVersionData;
          },
          false,
          "assets/updateFunctionVersion"
        );
      },
      updateFunctionVersionData: (assetId, versionNumber, data) => {
        set(
          (store) => {
            const versionData =
              store.assetsSlice.functions.functionsData?.[assetId]?.versions?.[
                versionNumber
              ];

            if (!versionData) {
              return;
            }

            Object.assign(versionData, data);
          },
          false,
          "assets/updateFunctionVersionData"
        );
      },
      deleteFunctionVersion: (versionNumber) => {
        set(
          (store) => {
            if (!store.assetsSlice.functions.selectedFunction.id) {
              return;
            }

            delete store.assetsSlice.functions.functionsData[
              store.assetsSlice.functions.selectedFunction.id
            ].versions[versionNumber];
          },
          false,
          "assets/deleteFunctionVersion"
        );
      },
      clearSelectedFunction: () => {
        set(
          (store) => {
            store.assetsSlice.functions.selectedFunction.id = null;
            store.assetsSlice.functions.selectedFunction.versionNumber = null;
          },
          false,
          "assets/clearSelectedFunction"
        );
      },
      clearCreatedFunctionData: () => {
        set(
          (store) => {
            store.assetsSlice.functions.createdFunctionData = {
              ...FUNCTIONS_INITIAL_STATE.createdFunctionData,
            };
          },
          false,
          "assets/clearCreatedFunctionData"
        );
      },
      setFunctionsDataLoadState: (state) => {
        set(
          (store) => {
            store.assetsSlice.functions.functionsDataLoadState = state;
          },
          false,
          "assets/setFunctionsDataLoadState"
        );
      },
      setCanvasFunctionsDataLoadState: (state) => {
        set(
          (store) => {
            store.assetsSlice.functions.canvasFunctionsDataLoadState = state;
          },
          false,
          "assets/setCanvasFunctionsDataLoadState"
        );
      },
      deleteFunctionById: (id) => {
        const { [id]: _, ...rest } =
          getState().assetsSlice.functions.functionsData;
        set(
          (store) => {
            store.assetsSlice.functions.functionsData = rest;
          },
          false,
          "assets/deleteFunction"
        );
      },
      deleteCanvasFunctionById: (id) => {
        const { [id]: _, ...rest } =
          getState().assetsSlice.functions.canvasFunctionsData;
        set(
          (store) => {
            store.assetsSlice.functions.canvasFunctionsData = rest;
          },
          false,
          "assets/deleteCanvasFunction"
        );
      },
      deleteFunctionVersionCanvasUsage: ({ assetId, versionId, canvasId }) => {
        const { [assetId]: assetData } =
          getState().assetsSlice.functions.functionsData;
        if (!assetData) {
          return;
        }
        const versionData = Object.values(assetData.versions).find(
          (version) => {
            return version.id === versionId;
          }
        );

        if (!versionData) {
          return;
        }
        const versionNumber = versionData.version;
        const canvases = versionData.canvases;

        if (!canvases?.length) {
          return;
        }

        set(
          (store) => {
            store.assetsSlice.functions.functionsData[assetId].versions[
              versionNumber
            ].canvases = canvases.filter((item) => item.canvas_id !== canvasId);
          },
          false,
          "assets/deleteFunctionVersionCanvasUsage"
        );
      },
      addFunctionVersionCanvasUsage: ({ assetId, versionId, canvasData }) => {
        const { [assetId]: assetData } =
          getState().assetsSlice.functions.functionsData;
        if (!assetData) {
          return;
        }
        const versionData = Object.values(assetData.versions).find(
          (version) => {
            return version.id === versionId;
          }
        );

        if (!versionData) {
          return;
        }
        const versionNumber = versionData.version;
        const canvases = versionData.canvases;

        if (!Array.isArray(canvases)) {
          return;
        }

        const canvasId = canvasData.canvas_id;

        if (canvases.findIndex((el) => el.canvas_id === canvasId) >= 0) {
          return;
        }

        set(
          (store) => {
            store.assetsSlice.functions.functionsData[assetId].versions[
              versionNumber
            ].canvases = [...canvases, canvasData];
          },
          false,
          "assets/addFunctionVersionCanvasUsage"
        );
      },
      clearStore: () => {
        set(
          (store) => {
            store.assetsSlice.functions = { ...FUNCTIONS_INITIAL_STATE };
          },
          false,
          "assets/clearFunctionsStore"
        );
      },
    },
  };
};

export default functionsAssetsSlice;
