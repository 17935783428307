import styles from "./ShowLogsSection.module.scss";
import cn from "classnames";
import { BiTerminal, BiCaretRight, BiX } from "react-icons/bi";
import { useCanvasState, useCanvasRequirementsState } from "store";
import Tooltip from "components/common/Tooltip/Tooltip";
import {
  REQUIREMENTS_STATUS,
  REQUIREMENTS_BUILD_COMPLETED_STATUSES,
  REQUIREMENTS_BUILDING_PROGRESS,
} from "config/canvasConfig";

export function ShowLogsSection() {
  const clearFullscreenBlockId = useCanvasState(
    (slice) => slice.clearFullscreenBlockId
  );
  const { requirementsStatus, showRequirementsLogs, setShowRequirementsLogs } =
    useCanvasRequirementsState((slice) => ({
      requirementsStatus: slice.requirementsStatus,
      showRequirementsLogs: slice.showRequirementsLogs,
      setShowRequirementsLogs: slice.setShowRequirementsLogs,
    }));

  // the status text label
  const completedStatus = REQUIREMENTS_BUILD_COMPLETED_STATUSES.some(
    (s) => s === requirementsStatus
  )
    ? requirementsStatus
    : null;

  return (
    <div
      role="button"
      className={styles.container}
      onClick={() => {
        setShowRequirementsLogs(!showRequirementsLogs);
        clearFullscreenBlockId();
      }}
    >
      <span className={styles.text}>
        <BiTerminal size={20} />

        <span>Logs</span>

        {completedStatus ? (
          <span
            className={cn(styles.statusLabel, {
              [styles.ready]: completedStatus === REQUIREMENTS_STATUS.READY,
              [styles.error]: completedStatus === REQUIREMENTS_STATUS.ERROR,
            })}
          >
            ({REQUIREMENTS_BUILDING_PROGRESS[completedStatus].label})
          </span>
        ) : null}
      </span>

      <Tooltip
        withArrow
        placement="top"
        text={showRequirementsLogs ? "Hide Logs" : "Show Logs"}
        className={styles.toggleStatusIcon}
      >
        {showRequirementsLogs ? <BiX size={16} /> : <BiCaretRight size={16} />}
      </Tooltip>
    </div>
  );
}
