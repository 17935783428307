import { useRef, useCallback, useMemo, useEffect } from "react";
import {
  getCanvasExecutor,
  getPythonRequirements,
  getLinuxPackages,
  getEnvironmentVariables,
  patchPythonRequirements,
  patchLinuxPackages,
  patchEnvironmentVariables,
  postUpdateRequirements,
  resetRequirementsEnvironment,
} from "api/http/canvas-requirements-service";
import {
  useCanvasState,
  useCanvasRequirementsState,
  useToastsState,
} from "store";
import { useConfirmModal } from "hooks/useConfirmModal";
import { debouncePromise } from "utils/helpers";
import { DATA_LOAD_STATUS } from "config/appConfig";
import {
  REQUIREMENTS_STATUS,
  REQUIREMENTS_BUILDING_STATUSES,
} from "config/canvasConfig";
import { SAVING_STATUS } from "components/common/SavingStatusIndicator/SavingStatusIndicator";
import type {
  Requirement,
  LinuxPackage,
  EnvironmentVariable,
} from "models/requirements";

const OPERATOR_REGEX = /([a-zA-Z0-9._-]+)([<>=]+)?([a-zA-Z0-9._-]+)?/;
const REPO_REGEX =
  /([a-zA-Z0-9._-]+)@([a-zA-Z0-9._-]+)([<>=]+)?([a-zA-Z0-9._-]+)?/;
// https://pip.pypa.io/en/stable/topics/vcs-support/
const PIP_INSTALL_FROM_VCS_URL_REGEX =
  /(git|hg|svn|bzr)\+[a-zA-Z0-9+.-]+:\/\/.*/;

const parseRequirement = (
  requirement: string,
  required: boolean
): Requirement => {
  // split requirements string into name, operator and version
  if (
    PIP_INSTALL_FROM_VCS_URL_REGEX.test(requirement) ||
    REPO_REGEX.test(requirement)
  ) {
    return { name: requirement, operator: "", version: "", required };
  } else {
    const [, name, operator, version] = requirement.match(OPERATOR_REGEX) || [];

    return {
      name: operator && version ? name : requirement,
      operator,
      version,
      required,
    };
  }
};

export function useRequirements() {
  const addToast = useToastsState((slice) => slice.addToast);
  const { openConfirmModal } = useConfirmModal();

  const requirementsSavingStatusTimeout = useRef<NodeJS.Timeout>();
  const linuxPackagesSavingStatusTimeout = useRef<NodeJS.Timeout>();
  const environmentVariablesSavingStatusTimeout = useRef<NodeJS.Timeout>();
  const isEditor = useCanvasState((slice) => slice.isEditor);
  const canvasId = useCanvasState((slice) => slice.canvasId);

  const {
    getRequirementsStatus,
    getRequirements,
    setRequirementsLoadingStatus,
    setRequirementsSavingStatus,
    setRequirements,
    setRequirementsStatus,

    setLinuxPackagesLoadingStatus,
    setLinuxPackagesSavingStatus,
    setLinuxPackages,

    setEnvironmentVariablesLoadingStatus,
    setEnvironmentVariablesSavingStatus,
    setEnvironmentVariables,

    setExecutor,
    setExecutorLoadingStatus,

    clearStore,
  } = useCanvasRequirementsState((slice) => ({
    getRequirementsStatus: slice.getRequirementsStatus,
    getRequirements: slice.getRequirements,
    setRequirementsLoadingStatus: slice.setRequirementsLoadingStatus,
    setRequirementsSavingStatus: slice.setRequirementsSavingStatus,
    setRequirements: slice.setRequirements,
    setRequirementsStatus: slice.setRequirementsStatus,

    setLinuxPackagesLoadingStatus: slice.setLinuxPackagesLoadingStatus,
    setLinuxPackagesSavingStatus: slice.setLinuxPackagesSavingStatus,
    setLinuxPackages: slice.setLinuxPackages,

    setEnvironmentVariablesLoadingStatus:
      slice.setEnvironmentVariablesLoadingStatus,
    setEnvironmentVariablesSavingStatus:
      slice.setEnvironmentVariablesSavingStatus,
    setEnvironmentVariables: slice.setEnvironmentVariables,

    setExecutor: slice.setExecutor,
    setExecutorLoadingStatus: slice.setExecutorLoadingStatus,

    clearStore: slice.clearStore,
  }));

  // cleanup timeouts on unmount
  useEffect(() => {
    return () => {
      if (requirementsSavingStatusTimeout.current) {
        clearTimeout(requirementsSavingStatusTimeout.current);
      }
      if (linuxPackagesSavingStatusTimeout.current) {
        clearTimeout(linuxPackagesSavingStatusTimeout.current);
      }
      if (environmentVariablesSavingStatusTimeout.current) {
        clearTimeout(environmentVariablesSavingStatusTimeout.current);
      }
    };
  }, []);

  const fetchRequirements = useCallback(() => {
    setRequirementsLoadingStatus(DATA_LOAD_STATUS.LOADING);
    return debouncePromise({ promise: getPythonRequirements(canvasId) })
      .then((response) => {
        const { requirements, required_requirements } = response;
        const processedRequirements = requirements.map((requirement) => {
          return parseRequirement(requirement, false);
        });
        const processedRequiredRequirements = required_requirements.map(
          (requirement) => {
            return parseRequirement(requirement, true);
          }
        );
        const allRequirements = [
          ...processedRequirements,
          ...processedRequiredRequirements,
        ];
        setRequirementsLoadingStatus(DATA_LOAD_STATUS.LOADED);
        setRequirements(allRequirements);
      })
      .catch(() => {
        setRequirementsLoadingStatus(DATA_LOAD_STATUS.ERROR);
      });
  }, [canvasId]);

  const fetchLinuxPackages = useCallback(() => {
    setLinuxPackagesLoadingStatus(DATA_LOAD_STATUS.LOADING);
    return debouncePromise({ promise: getLinuxPackages(canvasId) })
      .then((response) => {
        const processedRequirements = response.map((name) => {
          return { name };
        });
        setLinuxPackagesLoadingStatus(DATA_LOAD_STATUS.LOADED);
        setLinuxPackages(processedRequirements);
      })
      .catch(() => {
        setLinuxPackagesLoadingStatus(DATA_LOAD_STATUS.ERROR);
      });
  }, [canvasId]);

  const fetchEnvironmentVariables = useCallback(() => {
    setEnvironmentVariablesLoadingStatus(DATA_LOAD_STATUS.LOADING);
    return debouncePromise({ promise: getEnvironmentVariables(canvasId) })
      .then((response) => {
        const processedEnvironmentVariables = response.map((requirement) => {
          const [name, value] = requirement.split("=");
          return { name, value };
        });
        setEnvironmentVariablesLoadingStatus(DATA_LOAD_STATUS.LOADED);
        setEnvironmentVariables(processedEnvironmentVariables);
      })
      .catch(() => {
        setEnvironmentVariablesLoadingStatus(DATA_LOAD_STATUS.ERROR);
      });
  }, [canvasId]);

  const updateRequirements = useCallback(
    ({ requirements }: { requirements: Requirement[] }) => {
      if (!isEditor) {
        return;
      }
      const processedRequirements = requirements.map((requirement) => {
        const operator = requirement.operator || "==";
        return requirement.version
          ? `${requirement.name}${operator}${requirement.version}`
          : requirement.name;
      });
      if (requirementsSavingStatusTimeout.current) {
        clearTimeout(requirementsSavingStatusTimeout.current);
      }
      setRequirementsSavingStatus(SAVING_STATUS.PENDING);
      return debouncePromise({
        promise: patchPythonRequirements({
          canvasId,
          requirements: processedRequirements,
        }),
      })
        .then(() => {
          setRequirementsSavingStatus(SAVING_STATUS.COMPLETED);
          requirementsSavingStatusTimeout.current = setTimeout(() => {
            setRequirementsSavingStatus(SAVING_STATUS.IDLE);
          }, 3000);
        })
        .catch(() => {
          setRequirementsSavingStatus(SAVING_STATUS.FAILED);
        });
    },
    [canvasId, isEditor]
  );

  const updateLinuxPackages = useCallback(
    ({ linuxPackages }: { linuxPackages: LinuxPackage[] }) => {
      if (!isEditor) {
        return;
      }
      const processedLinuxPackages = linuxPackages.map(
        (linuxPackage) => linuxPackage.name
      );
      if (linuxPackagesSavingStatusTimeout.current) {
        clearTimeout(linuxPackagesSavingStatusTimeout.current);
      }
      setLinuxPackagesSavingStatus(SAVING_STATUS.PENDING);
      return debouncePromise({
        promise: patchLinuxPackages({
          canvasId,
          linuxPackages: processedLinuxPackages,
        }),
      })
        .then(() => {
          setLinuxPackagesSavingStatus(SAVING_STATUS.COMPLETED);
          linuxPackagesSavingStatusTimeout.current = setTimeout(() => {
            setLinuxPackagesSavingStatus(SAVING_STATUS.IDLE);
          }, 3000);
        })
        .catch(() => {
          setLinuxPackagesSavingStatus(SAVING_STATUS.FAILED);
        });
    },
    [canvasId, isEditor]
  );

  const updateEnvironmentVariables = useCallback(
    ({
      environmentVariables,
    }: {
      environmentVariables: EnvironmentVariable[];
    }) => {
      if (!isEditor) {
        return;
      }
      const processedEnvironmentVariables = environmentVariables.map(
        (environmentVariable) =>
          `${environmentVariable.name}=${environmentVariable.value}`
      );
      if (environmentVariablesSavingStatusTimeout.current) {
        clearTimeout(environmentVariablesSavingStatusTimeout.current);
      }
      setEnvironmentVariablesSavingStatus(SAVING_STATUS.PENDING);
      return debouncePromise({
        promise: patchEnvironmentVariables({
          canvasId,
          environmentVariables: processedEnvironmentVariables,
        }),
      })
        .then(() => {
          setEnvironmentVariablesSavingStatus(SAVING_STATUS.COMPLETED);
          environmentVariablesSavingStatusTimeout.current = setTimeout(() => {
            setEnvironmentVariablesSavingStatus(SAVING_STATUS.IDLE);
          }, 3000);
        })
        .catch(() => {
          setEnvironmentVariablesSavingStatus(SAVING_STATUS.FAILED);
        });
    },
    [canvasId, isEditor]
  );

  const fetchExecutor = useCallback(async () => {
    if (!canvasId) {
      return;
    }

    setExecutorLoadingStatus(DATA_LOAD_STATUS.LOADING);

    try {
      const data = await debouncePromise({
        promise: getCanvasExecutor(canvasId),
      });
      setExecutor(data);
      setRequirementsStatus(data.is_default ? null : data.status);
      setExecutorLoadingStatus(DATA_LOAD_STATUS.LOADED);
    } catch (err) {
      setExecutorLoadingStatus(DATA_LOAD_STATUS.ERROR);
    }
  }, [canvasId]);

  const checkIsBuildDisabled = useCallback(
    ({
      requirementsStatus,
      executorLoadingStatus,
    }: {
      requirementsStatus: REQUIREMENTS_STATUS | null;
      executorLoadingStatus: DATA_LOAD_STATUS;
    }) => {
      return (
        !isEditor ||
        REQUIREMENTS_BUILDING_STATUSES.some((s) => s === requirementsStatus) ||
        requirementsStatus === REQUIREMENTS_STATUS.RESETTING ||
        executorLoadingStatus !== DATA_LOAD_STATUS.LOADED
      );
    },
    [isEditor]
  );

  const buildRequirements = useCallback(async () => {
    if (!canvasId) {
      return;
    }

    if (!isEditor) {
      addToast({
        message: "You don't have permission to build requirements",
        variant: "warning",
      });

      return;
    }

    const currRequirementsStatus = getRequirementsStatus();

    if (
      REQUIREMENTS_BUILDING_STATUSES.some(
        (status) => status === currRequirementsStatus
      )
    ) {
      addToast({
        message: "Build process is already underway",
        variant: "warning",
      });

      return;
    }

    const confirmed = await openConfirmModal({
      message: (
        <>
          Are you sure you want to build the requirements for this canvas?
          <br />
          This can take some time. If you haven't added any new packages, this
          may not be necessary.
        </>
      ),
      confirmButtonLabel: "Build",
    });

    if (!confirmed) {
      return;
    }

    const prevStatus = getRequirementsStatus();
    setRequirementsStatus(REQUIREMENTS_STATUS.SUBMITTING);

    const requirements = getRequirements() || [];
    const nonRequiredRequirements = requirements.filter(
      (requirement) => !requirement.required
    );
    const requirementStrings = nonRequiredRequirements.map((requirement) => {
      const { name, operator, version } = requirement;
      if (version) {
        return `${name}${operator || "=="}${version}`;
      }
      return name;
    });

    try {
      await postUpdateRequirements(canvasId, requirementStrings);
    } catch (err) {
      setRequirementsStatus(prevStatus);
      addToast({ message: "Error updating requirements", variant: "error" });
    }
  }, [canvasId, isEditor]);

  const resetRequirements = useCallback(async () => {
    if (!canvasId) {
      return;
    }

    if (!isEditor) {
      addToast({
        message: "You don't have permission to reset requirements",
        variant: "warning",
      });

      return;
    }

    const confirmed = await openConfirmModal({
      message: "Are you sure you want to reset canvas requirements?",
      confirmButtonLabel: "Reset",
    });

    if (!confirmed) {
      return;
    }

    const prevStatus = getRequirementsStatus();
    setRequirementsStatus(REQUIREMENTS_STATUS.RESETTING);

    try {
      await debouncePromise({
        promise: resetRequirementsEnvironment({ canvasId }),
      });
      clearStore();

      addToast({
        message: "Canvas requirements successfully reset",
        variant: "success",
      });
    } catch (err: any) {
      setRequirementsStatus(prevStatus);
      const cause =
        typeof err?.cause?.detail === "string" ? err.cause.detail : "";
      const errorMessage = cause
        ? `Error resetting requirements: "${cause}".`
        : "Error resetting requirements.";
      addToast({
        message: errorMessage,
        variant: "error",
      });
    }
  }, [canvasId, isEditor]);

  return useMemo(
    () => ({
      fetchRequirements,
      fetchLinuxPackages,
      fetchEnvironmentVariables,
      updateRequirements,
      updateLinuxPackages,
      updateEnvironmentVariables,
      fetchExecutor,
      checkIsBuildDisabled,
      buildRequirements,
      resetRequirements,
    }),
    [
      fetchRequirements,
      fetchLinuxPackages,
      fetchEnvironmentVariables,
      updateRequirements,
      updateLinuxPackages,
      updateEnvironmentVariables,
      fetchExecutor,
      checkIsBuildDisabled,
      buildRequirements,
      resetRequirements,
    ]
  );
}
