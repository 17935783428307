import { type FC } from "react";
import styles from "./Typography.module.scss";

interface TypographyProps {
  children?: React.ReactNode;
  className?: string;
  component?: "p" | "span" | "div" | "h1" | "h2" | "h3" | "label";
  variant:
    | "h1"
    | "h2"
    | "h3"
    | "body1"
    | "body2"
    | "caption1"
    | "caption2"
    | "shortcuts"
    | "code";
  otherProps?: any;
}
const Typography: FC<TypographyProps> = ({
  children,
  className: classNameProp,
  component: Component = "div",
  variant,
  otherProps,
}: TypographyProps) => {
  const fontFamily =
    variant === "shortcuts" ? styles.fontInter : styles.fontPlexSans;
  const className = `${styles[variant]} ${fontFamily} ${classNameProp ?? ""}`;
  return (
    <Component className={className} {...otherProps}>
      {children}
    </Component>
  );
};

export default Typography;
