import { USER_API_URL } from "config/appConfig";
import { callAPI } from "./call-api";
import type { Workspace } from "models/organization";

export const getWorkspace = async (
  organizationId: string,
  workspaceId: string
) =>
  await callAPI({
    method: "GET",
    url: `${USER_API_URL}/organizations/${organizationId}/workspaces/${workspaceId}`,
  });

export const getWorkspaces = async (organizationId: string) =>
  await callAPI({
    method: "GET",
    url: `${USER_API_URL}/organizations/${organizationId}/workspaces`,
  });

export const postWorkspace = async (organizationId: string, data: any) =>
  await callAPI({
    method: "POST",
    url: `${USER_API_URL}/organizations/${organizationId}/workspaces`,
    data,
  });

export const patchWorkspace = async (
  organizationId: string,
  workspaceId: string,
  data: any
) =>
  await callAPI<Workspace>({
    method: "PATCH",
    url: `${USER_API_URL}/organizations/${organizationId}/workspaces/${workspaceId}`,
    data,
  });

export const deleteWorkspace = async (
  organizationId: string,
  workspaceId: string
) =>
  await callAPI({
    method: "DELETE",
    url: `${USER_API_URL}/organizations/${organizationId}/workspaces/${workspaceId}`,
  });

export const getWorkspaceMembers = async (
  organizationId: string,
  workspaceId: string
) =>
  await callAPI({
    method: "GET",
    url: `${USER_API_URL}/organizations/${organizationId}/workspaces/${workspaceId}/members`,
  });

export const postWorkspaceMembers = async (
  organizationId: string,
  workspaceId: string,
  userIds: string[]
) =>
  await callAPI({
    method: "POST",
    url: `${USER_API_URL}/organizations/${organizationId}/workspaces/${workspaceId}/members`,
    data: { workspace_id: workspaceId, user_ids: userIds.join(",") },
  });

export const updateWorkspaceMember = async (
  organizationId: string,
  workspaceId: string,
  userId: string,
  roleId: string
) =>
  await callAPI({
    method: "PATCH",
    url: `${USER_API_URL}/organizations/${organizationId}/workspaces/${workspaceId}/members`,
    data: { workspace_id: workspaceId, user_id: userId, role_id: roleId },
  });

export const deleteWorkspaceMembers = async (
  organizationId: string,
  workspaceId: string,
  userIds: string[]
) =>
  await callAPI({
    method: "DELETE",
    url: `${USER_API_URL}/organizations/${organizationId}/workspaces/${workspaceId}/members/${userIds.join(
      ","
    )}`,
  });

// APIs for supporting workspace group members
export const getWorkspaceGroupMembers = async (
  organizationId: string,
  workspaceId: string
) =>
  await callAPI({
    method: "GET",
    url: `${USER_API_URL}/organizations/${organizationId}/workspaces/${workspaceId}/groups/members`,
  });

export const postWorkspaceGroupMembers = async (
  organizationId: string,
  workspaceId: string,
  groupIds: string[]
) =>
  await callAPI({
    method: "POST",
    url: `${USER_API_URL}/organizations/${organizationId}/workspaces/${workspaceId}/groups/members`,
    data: { workspace_id: workspaceId, group_ids: groupIds.join(",") },
  });

export const updateWorkspaceGroupMember = async (
  organizationId: string,
  workspaceId: string,
  groupId: string,
  roleId: string
) =>
  await callAPI({
    method: "PATCH",
    url: `${USER_API_URL}/organizations/${organizationId}/workspaces/${workspaceId}/groups/members`,
    data: { workspace_id: workspaceId, group_id: groupId, role_id: roleId },
  });

export const deleteWorkspaceGroupMembers = async (
  organizationId: string,
  workspaceId: string,
  groupIds: string[]
) =>
  await callAPI({
    method: "DELETE",
    url: `${USER_API_URL}/organizations/${organizationId}/workspaces/${workspaceId}/groups/members/${groupIds.join(
      ","
    )}`,
  });

const workspaceService = {
  getWorkspace,
  patchWorkspace,
  deleteWorkspace,
  postWorkspaceMembers,
  getWorkspaceMembers,
  updateWorkspaceMember,
  deleteWorkspaceMembers,
  postWorkspaceGroupMembers,
  getWorkspaceGroupMembers,
  updateWorkspaceGroupMember,
  deleteWorkspaceGroupMembers,
};

export default workspaceService;
