import { type ProfileSettings } from "store/slices/user-settings-slice";

export enum FIELD_NAME {
  username = "username",
  firstName = "firstName",
  lastName = "lastName",
  bio = "bio",
  birthday = "birthday",
  city = "city",
  timezone = "timezone",
  socialMediaLinks = "socialMediaLinks",
}

export type UserSettingsProfileFormField =
  (typeof FIELD_NAME)[keyof typeof FIELD_NAME];
export type UserSettingsProfileFormValues = Partial<
  Pick<ProfileSettings, UserSettingsProfileFormField>
>;
