import { IconBase, type IconBaseProps } from "react-icons";

export default function SprintIcon(props: IconBaseProps) {
  return (
    <IconBase viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M6.09609 17.624V15.424H9.04409V16.722L9.77009 17.448H14.3241L15.0721 16.7V14.148L14.3461 13.422H8.51609L6.14009 11.046V6.976L8.51609 4.6H15.4681L17.8441 6.976V9.198H14.8961V7.878L14.1701 7.152H9.81409L9.08809 7.878V10.144L9.81409 10.87H15.6441L18.0201 13.246V17.58L15.6001 20H8.47209L6.09609 17.624Z"
          fill="currentColor"
        />
      </g>
    </IconBase>
  );
}
