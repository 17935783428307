import styles from "./WorkspaceAppLogsViewer.module.scss";
import { useState, useEffect, useRef } from "react";
import cn from "classnames";
import { useHotkeys, useHotkeysContext } from "react-hotkeys-hook";
import CodeEditor from "components/common/CodeEditor/CodeEditor";
import canvasService from "api/http/canvas-service";
import { useToastsState } from "store";
import IconButton from "components/common/IconButton/IconButton";
import { BiX } from "react-icons/bi";
import { HOTKEY_SCOPE_ID } from "config/hotkeyConfig";

export type WorkspaceAppLogsViewerProps = {
  appId: string;
  showAppLogs: boolean;
  showBuildLogs: boolean;
  setShowAppLogs: (show: boolean) => void;
};

export function WorkspaceAppLogsViewer({
  appId,
  showAppLogs,
  showBuildLogs,
  setShowAppLogs,
}: WorkspaceAppLogsViewerProps) {
  const { enableScope, disableScope, enabledScopes } = useHotkeysContext();
  const [appLogs, setAppLogs] = useState("");
  const shouldFetchLogs = showAppLogs || showBuildLogs;
  const addToast = useToastsState((slice) => slice.addToast);
  const canvasExecutorLogsRequestAbortController = useRef<AbortController>();

  const getBuildLogs = () => {
    const controller = new AbortController();
    canvasExecutorLogsRequestAbortController.current?.abort("new request");
    canvasExecutorLogsRequestAbortController.current = controller;
    canvasService
      .getAppBuildlogs({ app_id: appId, signal: controller.signal })
      .then((res) => {
        const logs = res?.length ? res.join("\n") : "";
        setAppLogs(logs);
      })
      .catch(() => {
        if (controller.signal.aborted) {
          // do nothing if request has been cancelled
          return;
        }
        addToast({
          message: "Failed to fetch app build logs, please try again.",
          variant: "error",
        });
      });
  };

  const getECSLogs = () => {
    const controller = new AbortController();
    canvasExecutorLogsRequestAbortController.current?.abort("new request");
    canvasExecutorLogsRequestAbortController.current = controller;
    canvasService
      .getAppECSLogs({ app_id: appId, signal: controller.signal })
      .then((res) => {
        const logs = res?.length ? res.join("\n") : "";
        setAppLogs(logs);
      })
      .catch(() => {
        if (controller.signal.aborted) {
          // do nothing if request has been cancelled
          return;
        }
        addToast({
          message: "Failed to fetch app logs, please try again.",
          variant: "error",
        });
      });
  };

  useEffect(() => {
    const getLogs = () => {
      if (showBuildLogs) {
        getBuildLogs();
      } else if (showAppLogs) {
        getECSLogs();
      }
    };

    let interval;
    if (shouldFetchLogs) {
      getLogs();

      interval = setInterval(getLogs, 5000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [appId, showBuildLogs, showAppLogs, shouldFetchLogs]);

  useEffect(() => {
    setAppLogs("");
  }, [showBuildLogs, showAppLogs]);

  const [isBlinking, setIsBlinking] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsBlinking((prevIsBlinking) => !prevIsBlinking);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    enableScope(HOTKEY_SCOPE_ID.WORKSPACE_APP_LOGS);

    return () => {
      disableScope(HOTKEY_SCOPE_ID.WORKSPACE_APP_LOGS);
    };
  }, []);

  useHotkeys(
    "esc",
    () => {
      setShowAppLogs(false);
      canvasExecutorLogsRequestAbortController.current?.abort("user abort");
    },
    {
      enabled:
        enabledScopes[enabledScopes.length - 1] ===
        HOTKEY_SCOPE_ID.WORKSPACE_APP_LOGS,
      preventDefault: true,
      enableOnFormTags: true,
      scopes: HOTKEY_SCOPE_ID.WORKSPACE_APP_LOGS,
    },
    []
  );

  return (
    <div className={styles.logsWindow}>
      <div className={styles.header}>
        <span className={styles.title}>
          {showBuildLogs ? "Build Logs" : "App Logs"}
        </span>
        <div className={styles.blinkerWrapper}>
          {shouldFetchLogs && (
            <>
              <div
                className={
                  isBlinking
                    ? styles.blinking_circle
                    : cn(styles.blinking_circle, styles.blinking_circle__active)
                }
              />
              <span className={styles.updating}>
                updating logs (every 5 seconds)...
              </span>
            </>
          )}
          <IconButton
            onClick={() => {
              setShowAppLogs(false);
              canvasExecutorLogsRequestAbortController.current?.abort(
                "user abort"
              );
            }}
          >
            <BiX size={16} />
          </IconButton>
        </div>
      </div>
      <div className={styles.logs}>
        <CodeEditor
          placeholderText="Logs will appear here when you build an app"
          value={appLogs || ""}
          readOnly
          language="shell"
          lineNumbers={!!appLogs}
          enableMinimap={!!appLogs}
          scrollbarVisibility="visible"
          onMount={(editor) => {
            if (appLogs) {
              editor.focus();
            }
          }}
        />
      </div>
    </div>
  );
}
