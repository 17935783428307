import { useMemo } from "react";
import { defineSortingFuction } from "../utils/sort";
import { type SORTING_COLUMN } from "config/localPreferences";
import {
  type SORTING_DIRECTION,
  SORTING_COLUMN_SORTING_TYPE,
  SORTING_TYPE,
} from "config/appConfig";

const MIN_SEARCH_QUERY = 1;

export interface SortingShape {
  column: SORTING_COLUMN;
  direction: SORTING_DIRECTION;
}

interface SortableItem {
  name: string;
}

export const useCanvasSorting = <T extends SortableItem>(
  currentSorting: SortingShape,
  items: T[],
  searchQuery = ""
) => {
  return useMemo(() => {
    let itemsResult = [...items];

    // filter items
    if (searchQuery.length >= MIN_SEARCH_QUERY) {
      const lowerCaseSearchQuery = searchQuery.toLowerCase();
      itemsResult = itemsResult.filter((item) =>
        (item.name || "untitled").toLowerCase().includes(lowerCaseSearchQuery)
      );
    }

    // sort items
    const type =
      SORTING_COLUMN_SORTING_TYPE[currentSorting.column] || SORTING_TYPE.TEXT;
    const sortingFunction = defineSortingFuction(type, currentSorting.column);
    itemsResult =
      currentSorting.direction === "ascending"
        ? itemsResult.sort(sortingFunction)
        : itemsResult.sort(sortingFunction).reverse();

    return itemsResult;
  }, [items, currentSorting, searchQuery]);
};
