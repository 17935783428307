import { type FC, type ReactNode } from "react";
import { Link, type LinkProps } from "react-router-dom";
import type { IconBaseProps, IconType } from "react-icons";
import cn from "classnames";

import styles from "./MenuOption.module.scss";

export interface MenuOptionProps {
  key?: string;
  label: ReactNode;
  showDivider?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  icon?: IconType | null;
  iconProps?: IconBaseProps;
  description?: ReactNode;
  rightSideContent?: ReactNode;
  linkProps?: LinkProps;
  onClose?: () => void;
  preventDefaultHandler?: boolean;
  wrapText?: boolean;
}

const disabledLinkClickHandler = (e) => {
  e.preventDefault();
};

const MenuOption: FC<MenuOptionProps> = ({
  label,
  showDivider = false,
  disabled,
  onClick,
  icon: Icon,
  iconProps,
  description,
  rightSideContent,
  linkProps,
  onClose,
  preventDefaultHandler = true,
  wrapText = false,
}: MenuOptionProps) => {
  const handleClick = (e) => {
    if (preventDefaultHandler) {
      // in case of link e.preventDefault() prevents the link from following the URL
      !linkProps && e.preventDefault();
      e.stopPropagation();
    }
    onClick?.();
    onClose?.();
  };

  const renderContent = () => {
    return (
      <>
        <div
          className={cn(styles.main, {
            [styles.with_description]: !!description,
          })}
        >
          {Icon && (
            <div className={styles.icon_container}>
              <Icon size={16} {...iconProps} />
            </div>
          )}
          <div className={styles.main_content}>
            <div
              className={cn(styles.label, {
                [styles.wrap_text]: wrapText,
              })}
            >
              {label}
            </div>
            {description && (
              <div className={styles.description}>{description}</div>
            )}
          </div>
        </div>
        {rightSideContent}
      </>
    );
  };

  return linkProps ? (
    <Link
      role="menuitem"
      className={cn(styles.option, styles.link, {
        [styles.option_with_divider]: showDivider,
        [styles.option_disabled]: disabled,
      })}
      {...linkProps}
      onClick={disabled ? disabledLinkClickHandler : handleClick}
    >
      {renderContent()}
    </Link>
  ) : (
    <button
      role="menuitem"
      onClick={handleClick}
      disabled={disabled}
      className={cn(styles.option, {
        [styles.option_with_divider]: showDivider,
        [styles.option_disabled]: disabled,
      })}
    >
      {renderContent()}
    </button>
  );
};

export default MenuOption;
