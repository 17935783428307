import { type StateCreator } from "store";
import {
  LOCAL_STORAGE_KEY,
  DISPLAY_MODE,
  SORTING_COLUMN,
  LOCAL_PREFERENCE_KEY,
} from "config/localPreferences";
import { SORTING_DIRECTION } from "config/appConfig";

type State = {
  [LOCAL_PREFERENCE_KEY.appsDisplayMode]: DISPLAY_MODE;
  [LOCAL_PREFERENCE_KEY.appsSortingColumn]: SORTING_COLUMN;
  [LOCAL_PREFERENCE_KEY.appsSortingDirection]: SORTING_DIRECTION;
};

type Actions = {
  setAppsDisplayMode: (mode: DISPLAY_MODE) => void;
  setAppsSortingColumn: (column: SORTING_COLUMN) => void;
  setAppsSortingDirection: (direction: SORTING_DIRECTION) => void;
};

const initialState: State = getInitialState();

export type LocalAppPreferencesSlice = State & Actions;

export const createLocalAppPreferencesSlice: StateCreator<
  LocalAppPreferencesSlice
> = (set) => ({
  ...initialState,
  setAppsDisplayMode: (mode) => {
    set(
      (store) => {
        store.localAppPreferencesSlice.appsDisplayMode = mode;
        setPreference(LOCAL_PREFERENCE_KEY.appsDisplayMode, mode);
      },
      false,
      "localPreferences/setAppsDisplayMode"
    );
  },
  setAppsSortingColumn: (column) => {
    set(
      (store) => {
        store.localAppPreferencesSlice.appsSortingColumn = column;
        setPreference(LOCAL_PREFERENCE_KEY.appsSortingColumn, column);
      },
      false,
      "localPreferences/setAppsSortingColumn"
    );
  },
  setAppsSortingDirection: (direction) => {
    set(
      (store) => {
        store.localAppPreferencesSlice.appsSortingDirection = direction;
        setPreference(LOCAL_PREFERENCE_KEY.appsSortingDirection, direction);
      },
      false,
      "localPreferences/setAppsSortingDirection"
    );
  },
});

/**
 * Restore preference from the local storage.
 * If the restored value is empty or invalid, use the default.
 */
function getInitialState() {
  // restore/validate apps display mode
  let appsDisplayMode = getPreference(LOCAL_PREFERENCE_KEY.appsDisplayMode);
  if (!Object.values(DISPLAY_MODE).includes(appsDisplayMode)) {
    appsDisplayMode = DISPLAY_MODE.CARDS;
    setPreference(LOCAL_PREFERENCE_KEY.appsDisplayMode, appsDisplayMode);
  }

  // restore/validate apps sorting column/direction
  let appsSortingColumn = getPreference(LOCAL_PREFERENCE_KEY.appsSortingColumn);
  let appsSortingDirection = getPreference(
    LOCAL_PREFERENCE_KEY.appsSortingDirection
  );
  if (
    !Object.values(SORTING_COLUMN).includes(appsSortingColumn) ||
    !Object.values(SORTING_DIRECTION).includes(appsSortingDirection)
  ) {
    appsSortingColumn = SORTING_COLUMN.createdAt;
    appsSortingDirection = SORTING_DIRECTION.DESCENDING;
    setPreference(LOCAL_PREFERENCE_KEY.appsSortingColumn, appsSortingColumn);
    setPreference(
      LOCAL_PREFERENCE_KEY.appsSortingDirection,
      appsSortingDirection
    );
  }

  return {
    appsDisplayMode,
    appsSortingColumn,
    appsSortingDirection,
  };
}

/**
 * Get the object with all preferences from the local storage.
 */
function getAllPreferences() {
  return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || "{}");
}

/**
 * Get a preference value from the local storage.
 */
function getPreference(key: LOCAL_PREFERENCE_KEY) {
  const allPreferences = getAllPreferences();
  return allPreferences[key] ?? null;
}

/**
 * Set a preference value into the local storage.
 */
function setPreference(
  key: LOCAL_PREFERENCE_KEY.appsDisplayMode,
  value: DISPLAY_MODE
): void;
function setPreference(
  key: LOCAL_PREFERENCE_KEY.appsSortingColumn,
  value: SORTING_COLUMN
): void;
function setPreference(
  key: LOCAL_PREFERENCE_KEY.appsSortingDirection,
  value: SORTING_DIRECTION
): void;
function setPreference(key: LOCAL_PREFERENCE_KEY, value: any): void {
  const allPreferences = getAllPreferences();
  allPreferences[key] = value;
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(allPreferences));
}
