import styles from "./CanvasCard.module.scss";
import { useMemo, type MouseEvent } from "react";
import cn from "classnames";
import GeoPattern from "geopattern";
import { BiShareAlt } from "react-icons/bi";
import KebabMenu from "components/common/KebabMenu/KebabMenu";
import FavoriteIconButton from "components/common/FavoriteIconButton/FavoriteIconButton";
import { TimeAgo } from "components/common/TimeAgo/TimeAgo";
import { EditableTitle } from "components/canvases/EditableTitle/EditableTitle";
import type { CanvasCardType } from "models/canvas";
import type { ContextMenuItem } from "components/common/ContextMenu/ContextMenu";
import CheckmarkIcon from "components/common/ContextMenu/MenuOption/CheckmarkIcon";

const NOOP = () => {};

export type CanvasCardProps = {
  item: CanvasCardType;
  options: ContextMenuItem[];
  isProcessed?: boolean;
  isSelected?: boolean;
  onClick: (
    canvasID: CanvasCardType["id"],
    event: MouseEvent<HTMLElement>
  ) => void;
};

export function CanvasCard({
  item,
  options,
  isProcessed = false,
  isSelected = false,
  onClick,
}: CanvasCardProps) {
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    onClick(item.id, event);
  };

  const thumbnail = useMemo(() => {
    const pattern = GeoPattern.generate(item.id, {
      generator: "squares",
    });

    return pattern.toDataUrl();
  }, []);

  return (
    <div
      className={cn(styles.card, {
        [styles.card_processed]: isProcessed,
        [styles.card_selected]: isSelected,
      })}
      onClick={handleClick}
    >
      {/* Thumbnail */}
      <div className={styles.cardBody}>
        {isSelected && <CheckmarkIcon className={styles.checkmarkIcon} />}
        {thumbnail ? (
          <>
            {thumbnail.startsWith("blob") ? (
              <img
                className={styles.keyImage}
                src={thumbnail}
                alt="Canvas image"
              />
            ) : (
              <div
                className={styles.keyPattern}
                style={{ backgroundImage: thumbnail }}
              />
            )}
          </>
        ) : null}
        <div className={styles.mask} />
        <div className={styles.buttons}>
          {item.onCanvasShare ? (
            <BiShareAlt size={16} onClick={item.onCanvasShare(item.name)} />
          ) : null}
          <KebabMenu
            classes={{ floatingContainer: styles.context_menu }}
            menuOptions={options}
          />
        </div>
      </div>

      {/* Footer */}
      <div className={styles.cardFooter}>
        <div className={styles.cardFooterLeft}>
          <EditableTitle
            title={item.name || "Untitled"}
            placeholder="Type canvas name..."
            // first priority is card double click
            readOnly={!item.onTitleChange}
            switchToEditingOnDoubleClick={false}
            onSave={item.onTitleChange || NOOP}
          />
          <TimeAgo
            date={item.createdAt}
            label={(fromNow) => `Created ${fromNow}`}
          />
        </div>
        {item.onStarClick ? (
          <div className={styles.cardFooterRight}>
            <FavoriteIconButton
              isFavorite={item.starred}
              handleToggleFavorite={item.onStarClick}
              className={styles.star}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}
