import * as Yup from "yup";

export const FORM_ERROR_MESSAGE = {
  provideRequiredInfo: "Please provide the required information.",
  maxLength: "The text entered exceeds the maximum length",
  shouldBeNumber: "Should be a number",
  shouldBeValidPort:
    "Should be a valid port number. The port number must be an integer in the range from 1 to 65535",
};

export const TEXT_FIELD_MAX_SYMBOLS_COUNT = 255;
export const TEXT_FIELD_MIN_SYMBOLS_COUNT = 2;

export const getMaxSymbolsCountMessage = (
  count: number = TEXT_FIELD_MAX_SYMBOLS_COUNT
) => {
  return `${FORM_ERROR_MESSAGE.maxLength} (${count} characters)`;
};

export const getMinSymbolsCountMessage = (
  count: number = TEXT_FIELD_MIN_SYMBOLS_COUNT
) => {
  return `At least ${count} characters are required`;
};

export const YUP_VALIDATION_METHODS_CHAIN = {
  stringRequiredMinMax: Yup.string()
    .required(FORM_ERROR_MESSAGE.provideRequiredInfo)
    .min(TEXT_FIELD_MIN_SYMBOLS_COUNT, getMinSymbolsCountMessage())
    .max(TEXT_FIELD_MAX_SYMBOLS_COUNT, getMaxSymbolsCountMessage()),
};
