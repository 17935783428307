import styles from "./WorkspaceApps.module.scss";
import { useCallback, useEffect, useMemo } from "react";
import cn from "classnames";
import { useParams } from "react-router-dom";
import { useUserState, useToastsState } from "store";
import { Button } from "components/common/Button/Button";
import Spinner from "components/common/Spinner/Spinner";
import { EmptyState } from "components/common/EmptyState/EmptyState";
import canvasService from "api/http/canvas-service";
import { useModal } from "hooks/useModal";
import { CreateAppModal } from "components/modals/CreateAppModal/CreateAppModal";
import { useApps } from "pages/Apps/useApps";
import type { App } from "pages/OrganizationHome/types/app";
import type { AppCardType } from "models/app";
import { AppsListLayout } from "components/apps/AppsListLayout/AppsListLayout";
import { SORTING_COLUMN } from "config/localPreferences";

const SORT_OPTIONS = Object.freeze([
  { value: SORTING_COLUMN.name, label: "Name" },
  { value: SORTING_COLUMN.createdAt, label: "Date Created" },
  { value: SORTING_COLUMN.lastModified, label: "Last Modified" },
]);

export function WorkspaceApps() {
  const { orgID = "", workspaceID = "" } = useParams();
  const { openModal } = useModal();

  const userID = useUserState((slice) => slice.userID);
  const addToast = useToastsState((slice) => slice.addToast);

  const {
    searchQuery,
    noApps,
    notFound,
    isLoading,
    sortedItems,
    appsDisplayMode,
    appsSortingColumn,
    appsSortingDirection,
    processedAppsIdActionMap,
    selectedAppIds,
    toolbar,
    appNameChangeHandler,
    handleAppClick,
    handleDeleteApps,
    handleChangeSorting,
    handleSearchQueryChange,
    openApp,
    setApps,
    setAppsDisplayMode,
    setIsLoading,
  } = useApps({ isLoadingInitial: true });

  useEffect(() => {
    !isLoading && setIsLoading(true);
    canvasService
      .getWorkspaceApps(workspaceID)
      .then((data: App[]) => {
        setApps(
          data.map((item) => {
            const { id, name } = item;
            return {
              id,
              name,
              createdAt: item.time_created,
              lastModified: item.time_updated || item.time_created,
              onTitleChange: appNameChangeHandler(id),
            };
          })
        );
      })
      .catch(() => {
        addToast({
          message: "Error loading canvases",
          variant: "error",
        });
        setApps([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [workspaceID]);

  const handleGetAppOptions = useCallback(
    (app: AppCardType) => {
      return [
        {
          key: "open",
          type: "option" as const,
          label: "Open",
          onClick: () => {
            openApp(app.id);
          },
        },
        {
          key: "delete",
          type: "option" as const,
          label: "Delete",
          onClick: () => {
            handleDeleteApps([app.id]);
          },
        },
      ];
    },
    [openApp, handleDeleteApps]
  );

  const handleAppCreate = useCallback(() => {
    if (!userID) {
      return;
    }
    openModal({
      title: "Create App",
      content: ({ onModalClose }) => {
        return (
          <CreateAppModal
            workspaceId={workspaceID}
            organizationId={orgID}
            onModalClose={onModalClose}
          />
        );
      },
    });
  }, [userID, workspaceID]);

  const noAppsPlaceholder = useMemo(() => {
    if (!noApps || isLoading) {
      return null;
    }

    return (
      <EmptyState
        variant="info"
        size="large"
        containerClassName={styles.placeholder}
        title="No apps yet..."
        description={
          <>
            Apps you will create will be here.
            <br />
            You have not created any apps yet.
          </>
        }
      >
        <Button size="large" variant="text" onClick={handleAppCreate}>
          Create App
        </Button>
      </EmptyState>
    );
  }, [isLoading, noApps, handleAppCreate]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <Button className={styles.createCanvasButton} onClick={handleAppCreate}>
          Create App
        </Button>
      </div>
      <div
        className={cn(styles.container, {
          [styles.container_with_toolbar]: toolbar,
        })}
      >
        <AppsListLayout
          topBarClassName={styles.top_bar}
          apps={sortedItems}
          onGetAppOptions={handleGetAppOptions}
          noApps={noApps}
          noAppsPlaceholder={noAppsPlaceholder}
          notFound={notFound}
          selectedAppsIds={selectedAppIds}
          processedAppsIdActionMap={processedAppsIdActionMap}
          searchQuery={searchQuery}
          sortingColumn={appsSortingColumn}
          sortingDirection={appsSortingDirection}
          sortOptions={SORT_OPTIONS}
          displayMode={appsDisplayMode}
          toolbar={toolbar}
          onSearchQueryChange={handleSearchQueryChange}
          onChangeSorting={handleChangeSorting}
          setDisplayMode={setAppsDisplayMode}
          onAppClick={handleAppClick}
        />
      </div>
    </div>
  );
}
