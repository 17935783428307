import { v4 as uuidv4 } from "uuid";
import { type StateCreator } from "store";
import { getSessionId } from "context/Session";
import { CANVAS_WS_URL } from "config/appConfig";
import {
  PROMISEABLE_WS_INSTRUCTIONS,
  SESSION_PHOBIC_WS_INSTRUCTIONS,
  type WS_INSTRUCTION,
} from "config/canvasConfig";

type State = {
  ws: WebSocket | null;
  isInitialConnectionOpen: boolean;
  isWebSocketDisconnected: boolean;
  deferredActions: Map<
    string,
    {
      promise: Promise<unknown>;
      resolve: (...args: unknown[]) => void;
      reject: (...args: unknown[]) => void;
    }
  >;
};

type Actions = {
  openConnection: ({
    canvasId,
    isPublic,
    token,
    onMessage,
    onOpen,
    onClose,
    onError,
  }: {
    canvasId: string;
    isPublic: boolean;
    token: string;
    onMessage?: (data: {
      instruction: WS_INSTRUCTION;
      [key: string]: unknown;
    }) => void;
    onOpen?: (event: Event) => void;
    onClose?: (event: CloseEvent) => void;
    onError?: (event: Event) => void;
  }) => void;
  send: ({
    instruction,
    message,
  }: {
    instruction: WS_INSTRUCTION;
    message: unknown;
  }) => Promise<unknown>;
  setIsInitialConnectionOpen: (value: boolean) => void;
  setIsWebSocketDisconnected: (value: boolean) => void;
  clearStore: () => void;
};

const getInitialState = (): State => ({
  ws: null,
  isInitialConnectionOpen: false,
  isWebSocketDisconnected: false,
  deferredActions: new Map(),
});

export type WebsocketSlice = State & Actions;

export const createWebsocketSlice: StateCreator<WebsocketSlice> = (
  set,
  get
) => ({
  ...getInitialState(),
  openConnection: ({
    canvasId,
    isPublic,
    token = "",
    onMessage,
    onOpen,
    onClose,
    onError,
  }) => {
    const wsUrl = isPublic
      ? `${CANVAS_WS_URL}/ws/${canvasId}/public?token=${token}`
      : `${CANVAS_WS_URL}/ws/${canvasId}?token=${token}`;
    // open new connection
    const ws = new WebSocket(wsUrl);

    ws.onmessage = (event) => {
      // notify subscribers
      const data = JSON.parse(JSON.parse(event.data));
      onMessage?.(data);

      // resolve a promise that is waiting for a response, if any
      const deferred = get().websocketSlice.deferredActions.get(
        data?.action_id
      );
      if (deferred) {
        deferred.resolve(data);

        // cleanup store
        set(
          (store) => {
            store.websocketSlice.deferredActions.delete(data.action_id);
          },
          false,
          "ws/openConnection/onMessage"
        );
      }
    };

    ws.onopen = (event) => {
      onOpen?.(event);
    };

    ws.onclose = (event) => {
      onClose?.(event);
    };

    ws.onerror = (event) => {
      onError?.(event);
    };

    set(
      (store) => {
        store.websocketSlice.ws = ws;
      },
      false,
      "ws/openConnection"
    );
  },
  send: async ({ instruction, message }) => {
    const ws = get().websocketSlice.ws;

    // websocket is not open
    if (ws?.readyState !== WebSocket.OPEN) {
      let returnValue;
      if (PROMISEABLE_WS_INSTRUCTIONS.has(instruction)) {
        returnValue = await Promise.reject(
          new Error("Cannot send message. WebSocket not open")
        );
      }
      return returnValue;
    }

    // We'd like to add more properties to the message, e.g. `session_id`, `action_id`.
    // But we can do this if the message is an object and not an array.
    const messageIsObject =
      typeof message === "object" &&
      message !== null &&
      !Array.isArray(message);

    let extendedMessage = message;

    // When a message is sent to the backend, it should contain a `session_id` to identify the session.
    // The session ends after certain inactivity time. So it doesn't make sense to bump the session
    // on every message, e.g. "PING".
    if (messageIsObject && !SESSION_PHOBIC_WS_INSTRUCTIONS.has(instruction)) {
      const session_id = getSessionId();

      // attach session_id to the message
      extendedMessage = { ...(extendedMessage as object), session_id };
    }

    // If a WS message is supplied with `action_id`, the backend will echo it back with a response to an action.
    // It doesn't make sense for all messages because not all messages get a response, e.g. "PING".
    // If it makes sense, we return a promise that will be resolved when the backend responds with the same action_id.
    let promise;
    if (messageIsObject && PROMISEABLE_WS_INSTRUCTIONS.has(instruction)) {
      // generate random action_id to identify the action;
      const action_id = uuidv4();

      // create a promise that can be resolved later
      let resolveRef;
      let rejectRef;
      promise = new Promise((resolve, reject) => {
        resolveRef = resolve;
        rejectRef = reject;
      });

      // store the promise
      set(
        (store) => {
          store.websocketSlice.deferredActions.set(action_id, {
            promise,
            resolve: resolveRef,
            reject: rejectRef,
          });
        },
        false,
        "ws/send/setActionPromise"
      );

      // attach action_id to the message
      extendedMessage = { ...(extendedMessage as object), action_id };
    }

    // send the message to the backend
    ws.send(JSON.stringify({ instruction, message: extendedMessage }));

    if (promise) {
      // return a promise that will be resolved when the backend
      // responds with a message with the same action_id
      return await promise;
    }
  },
  setIsInitialConnectionOpen: (value) => {
    set(
      (store) => {
        store.websocketSlice.isInitialConnectionOpen = value;
      },
      false,
      "ws/setIsInitialConnectionOpen"
    );
  },
  setIsWebSocketDisconnected: (value) => {
    set(
      (store) => {
        store.websocketSlice.isWebSocketDisconnected = value;
      },
      false,
      "ws/setIsWebSocketDisconnected"
    );
  },
  clearStore: () => {
    set(
      (store) => {
        get().websocketSlice.ws?.close();
        Object.assign(store.websocketSlice, getInitialState());
      },
      false,
      "ws/clearStore"
    );
  },
});
