import React from "react";
import { FiStar } from "react-icons/fi";
import cn from "classnames";

import styles from "./FavoriteIconButton.module.scss";
import stylesEx from "../../../styles/_exports.module.scss";

type FavoriteIconButtonProps = {
  isFavorite: boolean | undefined;
  handleToggleFavorite: (...args) => void;
  className?: string;
};

const FavoriteIconButton = ({
  isFavorite,
  handleToggleFavorite,
  className,
}: FavoriteIconButtonProps) => {
  return (
    <FiStar
      size={20}
      fill={isFavorite ? stylesEx["marking-yellow-color"] : "none"}
      color={
        isFavorite
          ? stylesEx["marking-yellow-color"]
          : stylesEx["light-text-color"]
      }
      className={cn(styles["favorite-icon"], className)}
      onClick={handleToggleFavorite}
    />
  );
};

export default React.memo(FavoriteIconButton);
