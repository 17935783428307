import { useState, useRef, type ReactNode, type MouseEvent } from "react";
import {
  autoUpdate,
  flip,
  type Placement,
  type UseHoverProps,
  shift,
  useFloating,
  useHover,
  offset,
  arrow,
  useDismiss,
  useDelayGroupContext,
  useInteractions,
  FloatingPortal,
} from "@floating-ui/react";
import { motion, AnimatePresence } from "framer-motion";
import cn from "classnames";

import styles from "./Tooltip.module.scss";

export type TooltipProps = {
  children: ReactNode;
  text?: ReactNode;
  tipElement?: ReactNode;
  className?: string;
  tooltipContentClassName?: string;
  placement?: Placement;
  hotkey?: string;
  withArrow?: boolean;
  mainAxisOffset?: number;
  crossAxisOffset?: number;
  hideOnClick?: boolean;
  hoverProps?: UseHoverProps;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
};

const DEFAULT_HOVER_PROPS: UseHoverProps = { delay: { open: 100 } };

export default function Tooltip({
  text,
  tipElement,
  className = "",
  tooltipContentClassName = "",
  placement = "bottom",
  withArrow,
  hotkey,
  mainAxisOffset = 8,
  crossAxisOffset = 0,
  hideOnClick = false,
  hoverProps,
  onClick,
  children,
}: TooltipProps) {
  const [open, setOpen] = useState(false);
  const arrowRef = useRef(null);
  const { setCurrentId } = useDelayGroupContext();

  const {
    x,
    y,
    refs,
    strategy,
    context,
    placement: floatingPlacement,
    middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
  } = useFloating({
    open,
    onOpenChange(open) {
      setOpen(open);
      if (open) {
        setCurrentId(text);
      }
    },

    whileElementsMounted: autoUpdate,
    placement,
    middleware: [
      flip(),
      shift(),
      offset({ mainAxis: mainAxisOffset, crossAxis: crossAxisOffset }),
      arrow({ element: arrowRef }),
    ],
  });

  useDismiss(context, {
    bubbles: false,
  });

  useInteractions([
    useHover(context, { ...DEFAULT_HOVER_PROPS, ...hoverProps }),
  ]);

  const staticSide =
    {
      top: "bottom",
      right: "left",
      bottom: "top",
      left: "right",
    }[floatingPlacement.split("-")[0]] ?? "bottom";

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    hideOnClick && open && setOpen(false);
    onClick?.(e);
  };

  return (
    <AnimatePresence>
      <div
        ref={refs.setReference}
        className={cn(styles.tooltip, className)}
        onClick={handleClick}
      >
        {children}
        {open && (text || tipElement) && (
          <FloatingPortal>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 0.1 } }}
              className={cn(styles.tooltip__content, tooltipContentClassName)}
              ref={refs.setFloating}
              onClick={(e) => {
                e.stopPropagation();
                hideOnClick && setOpen(false);
              }}
              style={{
                position: strategy,
                top: `${y || 0}px`,
                left: `${x || 0}px`,
                zIndex: 100,
              }}
            >
              {text && <span>{text}</span>}
              {tipElement && tipElement}
              {withArrow && (
                <div
                  className={cn(
                    styles.tooltip__arrow,
                    styles[`tooltip__arrow__${staticSide}`]
                  )}
                  ref={arrowRef}
                  style={{
                    left: arrowX ? `${arrowX}px` : "",
                    top: arrowY ? `${arrowY}px` : "",
                    [staticSide]: "-5px",
                  }}
                />
              )}
              {hotkey && (
                <span className={styles.tooltip__content__hotkey}>
                  {hotkey}
                </span>
              )}
            </motion.div>
          </FloatingPortal>
        )}
      </div>
    </AnimatePresence>
  );
}
