import type { ContextMenuItem } from "components/common/ContextMenu/ContextMenu";

const DOCUMENTATION_URL = "https://docs.zerve.ai/guide";
const DISCORD_URL = "https://discord.gg/AMF7rrs3hP";
const EMAIL_SUPPORT_URL = "mailto:support@zerve.ai";

export const HELP_MENU_EXTERNAL_LINK_OPTION: Record<
  "documentation" | "discord" | "emailSupport",
  ContextMenuItem
> = Object.freeze({
  documentation: {
    key: "documentation",
    type: "externalLink",
    label: "Documentation",
    externalLinkProps: {
      href: DOCUMENTATION_URL,
      target: "_blank",
    },
  },
  discord: {
    key: "discord",
    type: "externalLink",
    label: "Join Discord",
    externalLinkProps: {
      href: DISCORD_URL,
      target: "_blank",
    },
  },
  emailSupport: {
    key: "email-support",
    type: "externalLink",
    label: "Email Support",
    externalLinkProps: {
      href: EMAIL_SUPPORT_URL,
    },
  },
});
