import { type FC, useEffect } from "react";
import cn from "classnames";
import styles from "./ToasterMessage.module.scss";

import { BiCheckCircle, BiError, BiErrorCircle } from "react-icons/bi";

import { motion, AnimatePresence } from "framer-motion";

export interface ToasterMessageProps {
  variant: "success" | "error" | "warning" | "info";
  action?: string;
  onClick?: () => void;
  children: React.ReactNode;
  hotkey?: string;
  onRemove?: () => void;
  duration?: number;
  animate?: boolean;
  fullWidth?: boolean;
  className?: string;
}

const ToasterMessage: FC<ToasterMessageProps> = ({
  variant,
  hotkey,
  action,
  onClick,
  children,
  onRemove,
  duration = 3000,
  animate = true,
  fullWidth = false,
  className: customClassName,
}: ToasterMessageProps) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      onRemove?.();
    }, duration);
    return () => {
      clearTimeout(timeout);
    };
  }, [duration, onRemove]);

  const StatusIcon = {
    success: BiCheckCircle,
    error: BiError,
    warning: BiErrorCircle,
    info: BiErrorCircle,
  }[variant];

  return (
    <AnimatePresence>
      <motion.div
        className={cn(styles["toaster"], customClassName)}
        style={{ width: fullWidth ? "100%" : "auto" }}
        initial={
          animate
            ? { opacity: 0, y: 50, scale: 0.3 }
            : { opacity: 1, y: 0, scale: 1 }
        }
        animate={{ opacity: 1, y: 0, scale: 1 }}
        exit={{ opacity: 0, y: 20, scale: 0.5 }}
      >
        <div
          className={cn(
            styles["toaster__variant"],
            styles[`toaster__variant__${variant}`]
          )}
          style={{ width: fullWidth ? "100%" : "" }}
        >
          <div className={styles["toaster__content"]}>
            {StatusIcon && (
              <StatusIcon
                className={cn(
                  styles["toaster__content__icon"],
                  styles[`toaster__content__icon--${variant}`]
                )}
              />
            )}
            {children}
          </div>
          <div
            className={cn(
              styles["toaster__action-wrapper"],
              action ? styles[`toaster__action-wrapper--${variant}`] : ""
            )}
          >
            {action && (
              <div className={styles["toaster__action"]} onClick={onClick}>
                {action}
              </div>
            )}
            {hotkey && (
              <div className={styles["toaster__hotkey"]}>{hotkey}</div>
            )}
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default ToasterMessage;
