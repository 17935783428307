import styles from "./AssetsTabHeader.module.scss";
import { type FC, useCallback } from "react";
import cn from "classnames";
import { Button } from "components/common/Button/Button";
import { Checkbox } from "components/common/Checkbox/Checkbox";
import Typography from "components/Typography/Typography";
import { useAssetsData } from "hooks/useAssetsData";
import { DATA_LOAD_STATUS } from "config/appConfig";
import { type AssetPageTabType } from "../../types/abstractTypes";

interface AssetsTabHeaderProps {
  title: string;
  subtitle: string;
  assetType: AssetPageTabType;
}

const AssetsTabHeader: FC<AssetsTabHeaderProps> = ({
  title,
  subtitle,
  assetType,
}: AssetsTabHeaderProps) => {
  const {
    getCreatedAssetItemActions,
    getCurrentAssetItemActions,
    getCurrentAssetItemsData,
    getCreatedAssetItemData,
  } = useAssetsData();
  const { setAssetItemOperationType, setCreatedAssetItemData } =
    getCreatedAssetItemActions(assetType);
  const { assetOperationType } = getCreatedAssetItemData(assetType);
  const { setIsDisplayArchivedItems } = getCurrentAssetItemActions(assetType);
  const { isDisplayArchivedItems, assetDataLoadState } =
    getCurrentAssetItemsData(assetType);
  const handleCreateNewAssetItem = useCallback(() => {
    setAssetItemOperationType("create");
    setCreatedAssetItemData({
      name: "",
      value: "",
      description: "",
      secret: false,
    });
  }, [assetType, setAssetItemOperationType]);

  const handleChangeDisplayArchivedItems = useCallback(() => {
    setIsDisplayArchivedItems(!isDisplayArchivedItems);
  }, [setIsDisplayArchivedItems, isDisplayArchivedItems]);

  return (
    <div
      className={cn(styles.container, {
        [styles.containerActionsDisabled]:
          assetOperationType === "create" || assetOperationType === "editName",
      })}
    >
      <div>
        <Typography variant="h3">{title}</Typography>
        <Typography variant="caption2">{subtitle}</Typography>
      </div>

      <div className={styles.controls}>
        <Checkbox
          checked={isDisplayArchivedItems}
          onChange={handleChangeDisplayArchivedItems}
          label={<Typography variant="h3">Display Archived Items</Typography>}
        />

        <Button
          variant="secondary"
          onClick={handleCreateNewAssetItem}
          className={styles.createAssetButton}
          disabled={assetDataLoadState !== DATA_LOAD_STATUS.LOADED}
        >
          {`Create ${assetType}`}
        </Button>
      </div>
    </div>
  );
};

export default AssetsTabHeader;
