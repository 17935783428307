import styles from "./Home.module.scss";
import { useEffect, useState, useMemo, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { BiX } from "react-icons/bi";
import cn from "classnames";
import { useUserState, useUserSettingsState, useToastsState } from "store";
import { useModal } from "hooks/useModal";
import canvasService from "api/http/canvas-service";
import userService from "api/http/user-service";
import { CanvasCreateCard } from "components/canvases/CanvasCreateCard/CanvasCreateCard";
import { LinkButton } from "components/common/Button/LinkButton";
import { Button } from "components/common/Button/Button";
import IconButton from "components/common/IconButton/IconButton";
import ToasterMessage from "components/common/ToasterMessage";
import { EmptyState } from "components/common/EmptyState/EmptyState";
import { CanvasesListLayout } from "components/canvases/CanvasesListLayout/CanvasesListLayout";
import Spinner from "components/common/Spinner/Spinner";
import { CreateCanvasModal } from "components/modals";
import { SORTING_COLUMN } from "config/localPreferences";
import {
  CANVASES_SORTING_COLUMN_DEFAULT,
  CANVASES_SORTING_DIRECTION_DEFAULT,
} from "store/slices/local-canvas-preferences";
import { useCanvases, CANVAS_ACTION, SORT_OPTIONS } from "../useCanvases";
import { CANVAS_PAGE_FILTERS, CANVAS_PERMISSION } from "config/canvasConfig";
import type { CanvasCardType } from "models/canvas";

const emptyStateComponentProps = Object.freeze({
  variant: "info",
  size: "large",
  containerClassName: styles.placeholder,
});

const SORT_OPTIONS_WITH_SHARED_INFO = Object.freeze([
  ...SORT_OPTIONS,
  { value: SORTING_COLUMN.shareDate, label: "Share Date" },
  { value: SORTING_COLUMN.sharedBy, label: "Shared By" },
  { value: SORTING_COLUMN.permission, label: "Permission" },
]);

const Home = () => {
  const { openModal } = useModal();
  const location = useLocation();

  const { setUsernamePromptDismissed } = useUserState((state) => ({
    setUsernamePromptDismissed: state.setUsernamePromptDismissed,
  }));

  const handleToolbarClose = () => {
    setUsernamePromptDismissed(true);
  };

  const { userID, usernamePromptDismissed } = useUserState((state) => ({
    userID: state.userID,
    usernamePromptDismissed: state.usernamePromptDismissed,
  }));
  const { username } = useUserSettingsState((slice) => slice.profile);
  const addToast = useToastsState((slice) => slice.addToast);

  const urlComponents = location.pathname.split("/");
  const pageFilter = urlComponents[2] as CANVAS_PAGE_FILTERS;

  const [needFetch, setNeedFetch] = useState(true);

  const lastDataRequestId = useRef<symbol>();

  const pageSortingColumn = useRef<Record<string, SORTING_COLUMN>>({});

  const pageVariation = useMemo(
    () =>
      ({
        "": {
          placeholder: <CanvasCreateCard onCreateCanvas={handleCreateCanvas} />,
          endpoint: canvasService.getUserCanvases,
        },
        "community-canvases": {
          placeholder: (
            <EmptyState
              {...emptyStateComponentProps}
              title="No canvases published to the Zerve community..."
              description={
                <>
                  Canvases published to the Zerve community will be displayed
                  here.
                  <br />
                  You don't have published community canvases yet.
                </>
              }
            />
          ),
          endpoint: canvasService.getCommunityCanvases,
        },
        "shared-with-me": {
          placeholder: (
            <EmptyState
              {...emptyStateComponentProps}
              title="No shared canvases yet..."
              description={
                <>
                  Canvases that will be shared with you will be displayed here.
                  <br />
                  You don't have shared with you canvases yet.
                </>
              }
            />
          ),
          endpoint: canvasService.getSharedCanvases,
        },
        starred: {
          placeholder: (
            <EmptyState
              {...emptyStateComponentProps}
              title="No starred canvases yet..."
              description={
                <>
                  You can star your canvas and will automatically go here.
                  <br />
                  You have not starred any canvases yet.
                </>
              }
            />
          ),
          endpoint: canvasService.getStarredCanvases,
        },
        "my-canvases": {
          placeholder: (
            <EmptyState
              {...emptyStateComponentProps}
              title="No canvases yet..."
              description={
                <>
                  Canvases you will create will be here.
                  <br />
                  You have not created any canvases yet.
                </>
              }
            >
              <Button
                size="large"
                variant="secondary"
                onClick={handleCreateCanvas}
              >
                Create Canvas
              </Button>
            </EmptyState>
          ),
          endpoint: canvasService.getOwnedCanvases,
        },
        trash: {
          placeholder: (
            <EmptyState
              {...emptyStateComponentProps}
              title="Trash is empty..."
              description={
                <>
                  Canvases you will remove will get here.
                  <br />
                  You have not removed any canvases yet.
                </>
              }
            />
          ),
          endpoint: () => Promise.resolve([]), // todo: need method for deleted canvases
        },
      })[pageFilter] || {
        placeholder: <CanvasCreateCard onCreateCanvas={handleCreateCanvas} />,
        endpoint: canvasService.getUserCanvases,
      },
    [pageFilter]
  );

  const {
    searchQuery,
    noCanvases,
    notFound,
    isLoading,
    sortedItems,
    canvasesDisplayMode,
    canvasesSortingColumn,
    canvasesSortingDirection,
    processedCanvasesIdActionMap,
    selectedCanvasesIds,
    toolbar,
    canvasNameChangeHandler,
    handleDropSelection,
    handleCanvasClick,
    handleDeleteCanvases,
    handleChangeSorting,
    handleSearchQueryChange,
    openCanvas,
    handleOpenInvitationModal,
    handleOpenCanvasSettingsModal,
    removeProcessedCanvasId,
    setCanvases,
    setCanvasesDisplayMode,
    setIsLoading,
    setProcessedCanvasIdAction,
    setSearchQuery,
  } = useCanvases({ isLoadingInitial: true });

  useEffect(() => {
    handleDropSelection();
  }, [pageFilter]);

  // clear search query on page change
  useEffect(() => {
    setSearchQuery("");
  }, [pageFilter]);

  useEffect(() => {
    setNeedFetch(true);
  }, [pageVariation.endpoint]);

  const canvasOnStarClickHandler =
    (canvasID: string, projectID: string) => async (e: Event) => {
      e.stopPropagation();

      if (!userID) {
        return;
      }

      setProcessedCanvasIdAction(canvasID, CANVAS_ACTION.toggleStar);

      const starred = await new Promise<boolean>((resolve) => {
        setCanvases((items) => {
          return items.map((item) => {
            if (item.id !== canvasID) {
              return item;
            }

            const itemIsStarred = !!item.starred;
            resolve(itemIsStarred);

            return {
              ...item,
              starred: !itemIsStarred,
            };
          });
        });
      });

      userService
        .putUpdateStarredCanvasResources(userID, projectID, !starred)
        .then(() => {
          // // delete canvas if pageFilter === "starred"
          if (pageFilter === "starred") {
            setCanvases((items) => {
              return items.filter((item) => {
                return item.id !== canvasID;
              });
            });
          }
        })
        .catch(() => {
          // revert starred
          setCanvases((items) => {
            return items.map((item) => {
              if (item.id !== canvasID) {
                return item;
              }

              return {
                ...item,
                starred,
              };
            });
          });
          addToast({
            message: "Error starring canvas",
            variant: "error",
          });
        })
        .finally(() => {
          removeProcessedCanvasId(canvasID);
        });
    };

  useEffect(() => {
    if (userID && needFetch) {
      setNeedFetch(false);
      !isLoading && setIsLoading(true);

      const requestId = Symbol("last-data-request");
      lastDataRequestId.current = requestId;

      pageVariation
        .endpoint()
        .then((canvases) => {
          if (lastDataRequestId.current !== requestId) {
            // do not process outdated request data
            return [];
          }

          const canvasesWithEnhancedData = canvases.map((canvas) => {
            const canvasReadOnly =
              pageFilter === CANVAS_PAGE_FILTERS.SharedWithMe &&
              canvas.permission !== CANVAS_PERMISSION.WRITE;

            const extraProps: Partial<CanvasCardType> = {};

            if (!canvasReadOnly) {
              extraProps.onTitleChange = canvasNameChangeHandler(canvas.id);
            }

            extraProps.onStarClick = !canvasReadOnly
              ? canvasOnStarClickHandler(canvas.id, canvas.project_id)
              : (e) => {
                  e.stopPropagation();
                  addToast({
                    message:
                      "You can't star this canvas because it's currently read-only",
                    variant: "warning",
                  });
                };

            return {
              ...canvas,
              createdAt: canvas.time_created,
              lastModified: canvas.time_updated || canvas.time_created,
              shareDate: canvas.membership_time_created || null,
              sharedBy: canvas.shared_by_email || "",
              onCanvasShare: (canvasName) => (e) => {
                e.stopPropagation();
                handleOpenInvitationModal(
                  canvas.id,
                  canvasName,
                  canvas.project_id,
                  pageFilter
                );
              },
              ...extraProps,
            };
          });
          setCanvases(canvasesWithEnhancedData);
        })
        .catch(() => {
          if (lastDataRequestId.current !== requestId) {
            // ignore outdated requests
            return;
          }

          addToast({
            message: "Error loading canvases",
            variant: "error",
          });
          setCanvases([]);
        })
        .finally(() => {
          if (lastDataRequestId.current === requestId) {
            setIsLoading(false);
          }
        });
    }
  }, [needFetch, userID, handleDeleteCanvases, handleOpenInvitationModal]);

  const handleGetCanvasOptions = useCallback(
    (canvas: CanvasCardType) => {
      const canvasReadOnly =
        pageFilter === CANVAS_PAGE_FILTERS.SharedWithMe &&
        canvas.permission !== CANVAS_PERMISSION.WRITE;
      const options = [
        {
          key: "open",
          type: "option" as const,
          label: "Open",
          onClick: () => {
            openCanvas(canvas.id);
          },
        },
        {
          key: "share",
          type: "option" as const,
          label: "Share",
          onClick: () => {
            handleOpenInvitationModal(
              canvas.id,
              canvas.name,
              canvas.project_id,
              pageFilter
            );
          },
        },
        {
          key: "settings",
          type: "option" as const,
          label: "Settings",
          disabled: canvasReadOnly,
          onClick: () => {
            handleOpenCanvasSettingsModal(canvas.id);
          },
        },
        {
          key: "delete",
          type: "option" as const,
          label: "Delete",
          disabled: canvasReadOnly,
          onClick: () => {
            handleDeleteCanvases([canvas.id]);
          },
        },
      ];

      return options.filter((option) => !option.disabled);
    },
    [
      openCanvas,
      handleOpenInvitationModal,
      handleOpenCanvasSettingsModal,
      handleDeleteCanvases,
      pageFilter,
    ]
  );

  function handleCreateCanvas() {
    if (!userID) {
      return;
    }
    openModal({
      title: "Create Canvas",
      content: ({ onModalClose }) => {
        return <CreateCanvasModal onModalClose={onModalClose} />;
      },
    });
  }

  useEffect(() => {
    pageSortingColumn.current[pageFilter] = canvasesSortingColumn;
  }, [canvasesSortingColumn]);

  const sortOptions = useMemo(() => {
    if (pageFilter === "shared-with-me") {
      return SORT_OPTIONS_WITH_SHARED_INFO;
    }

    return SORT_OPTIONS;
  }, [pageFilter]);

  useEffect(() => {
    if (!sortOptions.some((option) => option.value === canvasesSortingColumn)) {
      const pagePrevSortingColumn = pageSortingColumn.current[pageFilter];
      let sortingColumn;
      let sortingDirection = canvasesSortingDirection;
      if (
        pagePrevSortingColumn &&
        sortOptions.some((item) => item.value === pagePrevSortingColumn)
      ) {
        sortingColumn = pagePrevSortingColumn;
      } else {
        sortingColumn = CANVASES_SORTING_COLUMN_DEFAULT;
        sortingDirection = CANVASES_SORTING_DIRECTION_DEFAULT;
      }
      handleChangeSorting({
        column: sortingColumn,
        direction: sortingDirection,
      });
    }
  }, [sortOptions, canvasesSortingColumn]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div
      className={cn(styles.container, {
        [styles.container_with_toolbar]: toolbar,
      })}
    >
      {/* "No username" banner */}
      {!username && !usernamePromptDismissed ? (
        <ToasterMessage
          variant="info"
          animate={false}
          className={styles.usernameBanner}
        >
          <p>
            We noticed that you haven't added a username yet. Adding a username
            will help you connect with other users and build your profile on
            Zerve.
          </p>
          <div className={styles.controls}>
            <LinkButton to="/settings/personal-profile">
              Go to Profile
            </LinkButton>
            <IconButton
              variant="text"
              onClick={handleToolbarClose}
              tooltip="Dismiss"
            >
              <BiX size={18} />
            </IconButton>
          </div>
        </ToasterMessage>
      ) : null}

      <CanvasesListLayout
        topBarClassName={styles.top_bar}
        canvases={sortedItems}
        onGetCanvasOptions={handleGetCanvasOptions}
        noCanvases={noCanvases}
        noCanvasesPlaceholder={pageVariation.placeholder}
        notFound={notFound}
        selectedCanvasesIds={selectedCanvasesIds}
        processedCanvasesIdActionMap={processedCanvasesIdActionMap}
        searchQuery={searchQuery}
        sortingColumn={canvasesSortingColumn}
        sortingDirection={canvasesSortingDirection}
        sortOptions={sortOptions}
        displayMode={canvasesDisplayMode}
        toolbar={toolbar}
        showSharingInfo={pageFilter === "shared-with-me"}
        onSearchQueryChange={handleSearchQueryChange}
        onChangeSorting={handleChangeSorting}
        setDisplayMode={setCanvasesDisplayMode}
        onCanvasClick={handleCanvasClick}
      />
    </div>
  );
};

export default Home;
