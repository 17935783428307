import type { LDFlagSet } from "launchdarkly-react-client-sdk";
import { useStore } from "store";
import {
  CANVAS_LEFT_SIDEBAR,
  CANVAS_PERMISSION,
  BLOCKS_RUN_DISABILITY_REASON,
  type BLOCK_TYPE,
  type LAYER_DISABILITY_REASON,
} from "config/canvasConfig";
import type { CanvasSlice } from "store/slices/canvas-slice";
import type { LayerType, BlockType, EdgeType } from "models/canvas";

export const transformBlock = (block: {
  id: BlockType["id"];
  type: number;
  width: number;
  height: number;
  x: number;
  y: number;
}): BlockType => {
  return {
    id: block.id,
    type: String(block.type) as BLOCK_TYPE,
    width: block.width,
    height: block.height,
    position: { x: block.x, y: block.y },
    data: { dynamicallyMoved: false },
  };
};

export const transformEdge = (edge: any): EdgeType => {
  return { ...edge, animated: true, selectable: false };
};

export const animateMoveBlock = (
  block: BlockType | null,
  newBlock: {
    id: BlockType["id"];
    layer_id: LayerType["id"];
    x: number;
    y: number;
  },
  moveBlock: CanvasSlice["moveBlock"]
) => {
  const ANIMATION_TIME = 50;
  const ANIMATION_FRAMES = 5;
  const ANIMATION_FRAME_DURATION = ANIMATION_TIME / ANIMATION_FRAMES;

  if (!block) {
    moveBlock(newBlock);
    return;
  }
  const xStep = (newBlock.x - block.position.x) / ANIMATION_FRAMES;
  const yStep = (newBlock.y - block.position.y) / ANIMATION_FRAMES;
  let x: number = block.position.x;
  let y: number = block.position.y;
  for (let i = 0; i < ANIMATION_FRAMES; i++) {
    setTimeout(() => {
      x += xStep;
      y += yStep;
      moveBlock({ ...newBlock, x, y });
    }, ANIMATION_FRAME_DURATION * i);
  }
  setTimeout(() => {
    moveBlock(newBlock);
  }, ANIMATION_TIME);
};

export const getLeftSidebarOptions = ({
  canvasPermission,
  featureFlags,
}: {
  canvasPermission: CANVAS_PERMISSION | null;
  featureFlags?: LDFlagSet;
}) => {
  const options = [
    CANVAS_LEFT_SIDEBAR.COLLAPSED,
    CANVAS_LEFT_SIDEBAR.LAYERS,
    CANVAS_LEFT_SIDEBAR.FILES,
    CANVAS_LEFT_SIDEBAR.REQUIREMENTS,
    CANVAS_LEFT_SIDEBAR.IMPORTS,
    CANVAS_LEFT_SIDEBAR.ASSETS,
  ] as CANVAS_LEFT_SIDEBAR[];

  if (canvasPermission === CANVAS_PERMISSION.WRITE) {
    if (!featureFlags || featureFlags?.enableGitIntegration) {
      options.push(CANVAS_LEFT_SIDEBAR.GITHUB);
    }
    if (!featureFlags || featureFlags?.enableAIModelIntegration) {
      options.push(CANVAS_LEFT_SIDEBAR.MODELS);
    }
  }

  return options;
};

export const computeLayerBlocksRunDisabilityReasons = ({
  userSubscriptionTier,
  organizationId,
  layerEditDisabilityReasons,
}: {
  userSubscriptionTier: number | null;
  organizationId: string | null;
  layerEditDisabilityReasons: Set<LAYER_DISABILITY_REASON> | null;
}) => {
  const reasons: Set<LAYER_DISABILITY_REASON | BLOCKS_RUN_DISABILITY_REASON> =
    new Set(layerEditDisabilityReasons);
  // TODO: change 1000 to null when BE is ready
  // https://linear.app/zerve-ai/issue/FRO-1638/disable-the-ability-to-run-blocks-on-the-frontend-for-users-with-null
  if (userSubscriptionTier === 1000 && !organizationId) {
    reasons.add(BLOCKS_RUN_DISABILITY_REASON.USER_TIER);
  }

  return reasons;
};

export const getLayerBlocksRunDisabilityReasons = ({
  layerId,
}: {
  layerId: LayerType["id"];
}) => {
  const userSubscriptionTier =
    useStore.getState().userSettingsSlice.profile.subscriptionTier;
  const organizationId = useStore.getState().canvasSlice.organizationId;
  const layerEditDisabilityReasons =
    useStore.getState().canvasSlice.layersEditDisabilityReasons[layerId] ??
    null;

  return computeLayerBlocksRunDisabilityReasons({
    userSubscriptionTier,
    organizationId,
    layerEditDisabilityReasons,
  });
};
