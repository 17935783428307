import { Navigate, type RouteObject } from "react-router-dom";
import Home from "pages/Canvases/Home/Home";
import Imports from "pages/Environments/Imports/Imports";
import Requirements from "pages/Environments/Requirements/Requirements";

export const PERSONAL_ENVIRONMENTS_ROUTES: RouteObject[] = [
  {
    path: ":envID",
    element: <Navigate replace to=":envID/requirements" />,
  },
  {
    path: ":envID/requirements",
    element: <Requirements />,
  },
  {
    path: ":envID/imports",
    element: <Imports />,
  },
  {
    path: ":envID/assets",
    element: <Home />,
  },
];
