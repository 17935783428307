import styles from "./CodeEditor.module.scss";
import {
  memo,
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo,
  type ReactNode,
} from "react";
import { type editor } from "monaco-editor";
import Editor from "@monaco-editor/react";
import cn from "classnames";
import { CODE_EDITOR_FONT_FAMILY } from "components/common/CodeEditor/config";
import { PlaceholderContentWidget } from "components/common/CodeEditor/PlaceholderWidget/PlaceholderWidget";
import { canvasHotKeys } from "config/hotkeyConfig";
import type {
  Editor as EditorType,
  CodeEditorOnMountType,
  CodeEditorLanguagesType,
} from "components/common/CodeEditor/types";
const DEFAULT_PADDING = { top: 0, bottom: 0 };
const DEFAULT_CUSTOM_ACTIONS = [];
const NOOP = () => {};

type CodeEditorProps = {
  value: string;
  language: CodeEditorLanguagesType;
  loadingMessage?: ReactNode;
  placeholderText?: string;
  fontSize?: number;
  lineNumbers?: boolean;
  readOnly?: boolean;
  enableMinimap?: boolean;
  folding?: boolean;
  wordWrap?: boolean;
  renderLineHighlight?: boolean;
  transparentBackground?: boolean;
  className?: string;
  wrapperClassName?: string;
  extraEditorClassName?: string;
  allowMouseWheel?: boolean;
  alwaysConsumeMouseWheel?: boolean; // NOTE: this option cannot be updated using updateOptions()
  padding?: editor.IEditorPaddingOptions;
  customActions?: editor.IActionDescriptor[];
  scrollbarVisibility?: editor.IEditorScrollbarOptions["vertical"];
  inheritCursor?: boolean;
  onMount?: CodeEditorOnMountType;
  onChange?: (value: string | undefined) => void;
  onBlur?: (value: string) => void;
};
export default memo(function CodeEditor({
  value,
  language,
  loadingMessage,
  placeholderText = "",
  fontSize = 12,
  lineNumbers = true,
  readOnly = false,
  enableMinimap = false,
  folding = true,
  wordWrap = false,
  renderLineHighlight = true,
  transparentBackground = false,
  className = "",
  wrapperClassName = "",
  extraEditorClassName = "",
  allowMouseWheel = true,
  alwaysConsumeMouseWheel = true,
  padding = DEFAULT_PADDING,
  customActions = DEFAULT_CUSTOM_ACTIONS,
  scrollbarVisibility,
  inheritCursor = false,
  onChange,
  onBlur = NOOP,
  onMount = NOOP,
}: CodeEditorProps) {
  const [editor, setEditor] = useState<EditorType>();
  const [placeholderWidget, setPlaceholderWidget] =
    useState<PlaceholderContentWidget>();
  const onBlurRef = useRef(onBlur);
  const onMountRef = useRef(onMount);

  // Save a reference to callbacks so "onDidBlurEditorWidget" will always call the latest versions
  useEffect(() => {
    onBlurRef.current = onBlur;
    onMountRef.current = onMount;
  }, [onBlur, onMount]);

  // Subscribe to blur; store editor reference.
  const handleMount = useCallback<CodeEditorOnMountType>((editor, monaco) => {
    setEditor(editor);
    editor.onDidBlurEditorWidget(() => {
      onBlurRef.current(editor.getValue());
    });
    onMountRef.current(editor, monaco);
  }, []);

  // Add custom actions
  useEffect(() => {
    if (!editor || !customActions.length) {
      return;
    }

    const disposables: ReturnType<typeof editor.addAction>[] = [];

    customActions.forEach((action) => {
      // register a custom action
      const disposeAction = editor.addAction(action);
      disposables.push(disposeAction);
    });

    return () => {
      disposables.forEach((disposable) => {
        // unregister a custom action
        disposable.dispose();
      });
    };
  }, [editor, customActions]);

  // Add CMD + S shortcut to call onChange
  useEffect(() => {
    if (!editor || readOnly || !onChange) {
      return;
    }
    const disposable = editor.addAction({
      id: "zerve.save",
      label: "Save",
      keybindings: canvasHotKeys.SAVE.editorKeybindings,
      run: () => {
        onChange(editor.getValue());
      },
    });
    return () => {
      disposable.dispose();
    };
  }, [editor, readOnly, onChange]);

  // Specify editor options
  const options = useMemo(
    (): editor.IStandaloneEditorConstructionOptions => ({
      contextmenu: false,
      overviewRulerLanes: 0,
      fontSize,
      lineNumbers: lineNumbers ? "on" : "off",
      readOnly,
      domReadOnly: readOnly,
      minimap: {
        enabled: enableMinimap,
        autohide: true,
      },
      folding,
      wordWrap: wordWrap ? "on" : "off",
      renderLineHighlight: renderLineHighlight ? "line" : "none",
      padding,
      fontFamily: CODE_EDITOR_FONT_FAMILY,
      quickSuggestions: false,
      scrollBeyondLastLine: false,
      stickyScroll: {
        defaultModel: "outlineModel",
        enabled: true,
        maxLineCount: 3,
        scrollWithEditor: true,
      },
      scrollbar: {
        handleMouseWheel: allowMouseWheel,
        alwaysConsumeMouseWheel,
        horizontalScrollbarSize: 8,
        verticalScrollbarSize: 8,
        horizontal: scrollbarVisibility,
        vertical: scrollbarVisibility,
      },
      inlineSuggest: {
        enabled: true,
        keepOnBlur: false,
        mode: "prefix",
        showToolbar: "never",
      },
      extraEditorClassName,
    }),
    [
      allowMouseWheel,
      alwaysConsumeMouseWheel,
      fontSize,
      lineNumbers,
      readOnly,
      enableMinimap,
      folding,
      wordWrap,
      renderLineHighlight,
      padding,
      extraEditorClassName,
      scrollbarVisibility,
    ]
  );

  // Update editor options.
  // `options` prop of <Editor /> is flaky sometimes (see: https://linear.app/zerve-ai/issue/FRO-1074).
  // But we can't stop using the prop because some of the options can't be updated using `updateOptions()`.
  useEffect(() => {
    if (!editor) {
      return;
    }
    editor.updateOptions(options);
  }, [editor, options]);

  // attach widgets to editor
  useEffect(() => {
    if (!editor) {
      return;
    }
    const placeholderWidget = new PlaceholderContentWidget(
      placeholderText,
      editor
    );
    setPlaceholderWidget(placeholderWidget);

    return () => {
      placeholderWidget.dispose();
    };
  }, [editor, placeholderText]);

  useEffect(() => {
    if (placeholderWidget) {
      placeholderWidget.updateFontSize(fontSize);
    }
  }, [fontSize, placeholderWidget]);

  // Specify wrapper container props. They are passed as <div {...wrapperProps} />
  const wrapperProps = useMemo(() => {
    return {
      className: cn(
        styles.wrapper,
        {
          [styles.transparent]: transparentBackground,
          [styles.inherit_cursor]: inheritCursor,
        },
        wrapperClassName
      ),
    };
  }, [transparentBackground, wrapperClassName, inheritCursor]);

  return (
    <Editor
      options={options}
      theme="zerve-dark"
      className={className}
      loading={loadingMessage}
      value={value}
      language={language}
      wrapperProps={wrapperProps}
      // https://linear.app/zerve-ai/issue/FRO-1314/incoming-value-changes-in-readonly-codeeditor-are-triggerring-onchange
      onChange={readOnly ? NOOP : onChange}
      onMount={handleMount}
    />
  );
});
