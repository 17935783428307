import { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAssetsState, useUserState, useToastsState } from "store";
import {
  createConnectionDuplicate as createConnectionDuplicateAPICall,
  deleteConnection as deleteConnectionAPICall,
  getConnection as getConnectionAPICall,
  postConnection,
  postWorkspaceConnection,
  putConnection,
  putConnectionArchive,
  updateConnection as updateConnectionConfiguration,
} from "api/http/assets/connections-service";
import { wait } from "utils/helpers";
import { getAssetsBaseUrl } from "pages/Assets/utils";
import { type Connection } from "pages/Assets/types/connectionTypes";

const useConnectionActions = () => {
  const navigate = useNavigate();

  const {
    addDataItem,
    updateDataItem,
    getDataItem,
    clearCreatedItem,
    getCreatedItem,
    setCreatedItemError,
    setCreatedItemLoading,
    deleteDataItem,
  } = useAssetsState((slice) => slice.connectionsActions);

  const userID = useUserState((slice) => slice.userID);
  const addToast = useToastsState((slice) => slice.addToast);
  const { orgID, workspaceID } = useParams();

  const addConnection = useCallback(
    (requestData) => {
      if (workspaceID) {
        return postWorkspaceConnection(workspaceID, requestData);
      } else {
        return postConnection(requestData);
      }
    },
    [workspaceID]
  );

  const baseURL = useMemo(() => {
    return getAssetsBaseUrl({
      organizationId: orgID,
      workspaceId: workspaceID,
    });
  }, [orgID, workspaceID]);

  const createConnection = useCallback(() => {
    const { name, connection_type_id } = getCreatedItem().data;
    const requestData = {
      name,
      connection_type_id,
    };
    if (userID) {
      setCreatedItemLoading(true);
      addConnection(requestData)
        .then((responseData) => {
          addDataItem(responseData);
          clearCreatedItem();
          navigate(`${baseURL}/connections/${responseData.id}`);
          addToast({
            message: "Success! New connection has been successfully created",
            variant: "success",
          });
        })
        .catch((error) => {
          setCreatedItemError(error.message);
        })
        .finally(() => {
          setCreatedItemLoading(false);
        });
    }
  }, [userID, baseURL]);

  const createConnectionDuplicate = useCallback(
    (id: string) => {
      if (userID) {
        createConnectionDuplicateAPICall(userID, id)
          .then((responseData) => {
            addDataItem(responseData);
            navigate(`${baseURL}/connections/${responseData.id}`);
            addToast({
              message: "Success! Connection duplicate has been created",
              variant: "success",
            });
          })
          .catch((error) => {
            addToast({
              message:
                error?.message || "Failed to create connection duplicate",
              variant: "error",
            });
          });
      }
    },
    [userID, baseURL]
  );

  const updateConnectionName = useCallback(() => {
    const { name, id } = getCreatedItem().data;

    if (!userID || !id || getDataItem(id)?.connection?.name === name) {
      clearCreatedItem();

      return;
    }

    setCreatedItemLoading(true);
    putConnection(id, { id, name })
      .then((responseData) => {
        updateDataItem(id, responseData);
        navigate(`${baseURL}/connections/${responseData.id}`, {
          replace: true,
        });
        addToast({
          message: "Success! Connection name has been successfully updated",
          variant: "success",
        });
      })
      .catch((error) => {
        addToast({
          message: error?.message ?? "Failed to update connection name",
          variant: "error",
        });
      })
      .finally(() => {
        clearCreatedItem();
        setCreatedItemLoading(false);
      });
  }, [userID, baseURL]);

  const updateConnection = useCallback(
    async (id: string, data: Connection["value"]) => {
      if (!userID || !id) {
        return;
      }

      const responseData = await updateConnectionConfiguration(id, {
        id,
        value: data,
      });
      updateDataItem(responseData.id, responseData);
      navigate(`${baseURL}/connections/${responseData.id}`, { replace: true });
      addToast({
        message: "Connection configuration successfully saved",
        variant: "success",
      });
    },
    [userID, baseURL]
  );

  // fake action, to be changed when related API is provided
  // added it only to reproduce user interaction with forms
  const testConnection = useCallback(
    async (id: string) => {
      if (!userID || !id) {
        return;
      }

      await wait(500);

      addToast({
        message: "Test functionality will be added soon",
        variant: "success",
      });
    },
    [userID]
  );

  const switchArchiveConnection = useCallback(
    (id: string, archive: boolean) => {
      if (!id) {
        return;
      }

      putConnectionArchive({ id, archive })
        .then((responseData) => {
          updateDataItem(responseData.id, responseData);
          navigate(`${baseURL}/connections/${responseData.id}`, {
            replace: true,
          });
          addToast({
            message: `Connection has been successfully ${
              archive ? "archived" : "unarchived"
            }.`,
            variant: "success",
          });
        })
        .catch((error) => {
          addToast({
            variant: "error",
            message:
              error?.message ??
              `Failed to ${archive ? "archive" : "unarchive"} connection`,
          });
        });
    },
    [baseURL]
  );

  const deleteConnection = useCallback(
    (id: string) => {
      if (!id) {
        return;
      }

      deleteConnectionAPICall(id)
        .then(() => {
          deleteDataItem(id);
          navigate(`${baseURL}/connections`, { replace: true });
          addToast({
            variant: "success",
            message: "Connection has been successfully deleted",
          });
        })
        .catch((error) => {
          addToast({
            variant: "error",
            message: error?.message ?? "Failed to delete connection",
          });
        });
    },
    [baseURL]
  );

  const getConnection = useCallback(
    (
      id: string,
      config: {
        showSuccessMessage?: boolean;
        showErrorMessage?: boolean;
        successMessage?: string;
        errorMessage?: string;
      } = {
        showSuccessMessage: true,
        showErrorMessage: true,
      }
    ) => {
      if (!id) {
        return;
      }

      const {
        showSuccessMessage,
        showErrorMessage,
        successMessage,
        errorMessage,
      } = config;

      return getConnectionAPICall(id)
        .then((responseData) => {
          updateDataItem(responseData.id, responseData);

          showSuccessMessage &&
            addToast({
              message: successMessage || "Connection data has been updated",
              variant: "success",
            });
        })
        .catch((error) => {
          showErrorMessage &&
            addToast({
              variant: "error",
              message:
                errorMessage ||
                (error?.message ?? "Failed to update connection data"),
            });
        });
    },
    []
  );

  return {
    createConnection,
    createConnectionDuplicate,
    deleteConnection,
    getConnection,
    switchArchiveConnection,
    testConnection,
    updateConnectionName,
    updateConnection,
  };
};

export default useConnectionActions;
