import styles from "./ConstantModal.module.scss";
import { type FC, useCallback, useEffect, useMemo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button } from "components/common/Button/Button";
import Loader from "components/common/Loader/Loader";
import { Modal } from "components/common/Modal/Modal";
import SecuredField from "components/common/SecuredField/SecuredField";
import Switch from "components/common/Switch/Switch";
import TextField from "components/common/TextField/TextField";
import Typography from "components/Typography/Typography";
import { useAssetsData } from "hooks/useAssetsData";
import {
  type ConstantVersionType,
  type CreatedConstantType,
} from "pages/Assets/types/constantTypes";

import { SECRET_VALUE_PLACEHOLDER } from "pages/Assets/config";
import {
  FIELD_MAX_SYMBOLS_COUNT,
  FORM_FIELD,
  ERROR_MESSAGE,
  CONSTANT_NAME_SKIP_VALIDATION_OPERATION_TYPES,
  getAcceptButtonLabel,
  getMaxSymbolsCountExceededMessage,
  getModalTitle,
} from "./constants";
import { useCreatedConstantsData } from "hooks/assets/useCreatedAssetsData/components";

const getEditAssetRequestData = (
  values: CreatedConstantType,
  initialValues: CreatedConstantType
) => {
  return Object.keys(values).reduce((res, key) => {
    const prevValue = initialValues[key];
    const currValue = values[key];

    if (currValue === prevValue) {
      return res;
    }
    if (key === FORM_FIELD.secret) {
      if (!currValue) {
        return res;
      }
    }

    if (key === FORM_FIELD.value) {
      if (values[FORM_FIELD.secret] && !currValue) {
        return res;
      }
    }

    res[key] = currValue;

    return res;
  }, {});
};

const ConstantModal: FC = () => {
  const {
    getCreatedAssetItemActions,
    getCreatedAssetItemData,
    getCurrentAssetItemsData,
  } = useAssetsData();
  const { createdAssetItemData, assetOperationType, createdAssetLoading } =
    getCreatedAssetItemData("constant");

  const { name, value, description, secret } =
    createdAssetItemData as CreatedConstantType;

  const { setAssetItemOperationType } = getCreatedAssetItemActions("constant");
  const { activeItemData, activeItemVersionId } =
    getCurrentAssetItemsData("constant");
  const { createdConstantsActions } = useCreatedConstantsData();
  const { createAssetItem, editAssetItem, addNewVersion } =
    createdConstantsActions;

  const handleOnClose = useCallback(() => {
    setAssetItemOperationType(null);
  }, [setAssetItemOperationType]);

  const modalTitle = useMemo(() => {
    return getModalTitle(assetOperationType);
  }, [assetOperationType]);

  const acceptButtonLabel = useMemo(() => {
    return getAcceptButtonLabel(assetOperationType);
  }, [assetOperationType]);

  const previousVersionDataRow = useMemo(() => {
    if (
      activeItemData &&
      "constant" in activeItemData &&
      "versions" in activeItemData
    ) {
      const activeVersionData: ConstantVersionType | null =
        Object.values(activeItemData.versions).find(
          (version) => version.id === activeItemVersionId
        ) ?? null;

      const getPreviousVersionValue = () => {
        if (activeVersionData) {
          if (activeVersionData.secret) {
            return SECRET_VALUE_PLACEHOLDER;
          }
          return activeVersionData.value;
        }
      };

      return (
        <div className={styles.previousVersionDataRow}>
          <div>
            <Typography variant="h3" className={styles.dataValue}>
              {"constant" in activeItemData ? activeItemData.constant.name : ""}
            </Typography>
            <Typography variant="body2" className={styles.value}>
              Name
            </Typography>
          </div>
          <div>
            <Typography variant="h3" className={styles.dataValue}>
              {`v.${activeVersionData ? activeVersionData.version : ""}`}
            </Typography>
            <Typography variant="body2" className={styles.value}>
              Previous Version
            </Typography>
          </div>
          <div>
            <Typography variant="h3" className={styles.dataValue}>
              {getPreviousVersionValue()}
            </Typography>
            <Typography variant="body2" className={styles.value}>
              Previous Value
            </Typography>
          </div>
        </div>
      );
    }
  }, [activeItemData, activeItemVersionId]);

  const formInitialValues = useMemo(() => {
    return {
      [FORM_FIELD.name]: name,
      [FORM_FIELD.value]: value,
      [FORM_FIELD.description]: description,
      [FORM_FIELD.secret]: secret,
    };
  }, [name, value, description, secret]);

  const onSubmit = useCallback(
    async (values: CreatedConstantType) => {
      switch (assetOperationType) {
        case "create":
          try {
            const result = await createAssetItem(values);
            if (result?.success) {
              handleOnClose();
            }
          } catch (error) {
            // pass
          }
          break;
        case "edit": {
          const requestData = getEditAssetRequestData(
            values,
            formInitialValues
          );
          /* make secret does not work in edit mode for now as BE does not process secret field
          in constant PATCH request. It will be automatically fixed when 
          https://linear.app/zerve-ai/issue/ZER-1113/constant-patch-request-should-process-secret-field-on-be-side
          is done */
          try {
            const result = await editAssetItem(requestData);
            if (result?.success) {
              handleOnClose();
            }
          } catch (error) {
            // pass
          }
          break;
        }
        case "newVersion":
          try {
            const result = await addNewVersion(values);
            if (result?.success) {
              handleOnClose();
            }
          } catch (error) {
            // pass
          }
          break;
        default:
          break;
      }
    },
    [
      assetOperationType,
      formInitialValues,
      createAssetItem,
      editAssetItem,
      addNewVersion,
    ]
  );

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      [FORM_FIELD.name]: Yup.string().when([], {
        is: () =>
          !CONSTANT_NAME_SKIP_VALIDATION_OPERATION_TYPES.includes(
            assetOperationType
          ),
        then: () =>
          Yup.string()
            .required(ERROR_MESSAGE.required)
            .matches(/^[A-Za-z0-9_]*$/, ERROR_MESSAGE.nameAllowedSymbols)
            .matches(/^[^0-9]+.*/, ERROR_MESSAGE.nameStartWithNumber)
            .max(
              FIELD_MAX_SYMBOLS_COUNT[FORM_FIELD.name],
              getMaxSymbolsCountExceededMessage(
                FIELD_MAX_SYMBOLS_COUNT[FORM_FIELD.name]
              )
            ),
        otherwise: () => Yup.string(),
      }),
      [FORM_FIELD.description]: Yup.string().max(
        FIELD_MAX_SYMBOLS_COUNT[FORM_FIELD.description],
        getMaxSymbolsCountExceededMessage(
          FIELD_MAX_SYMBOLS_COUNT[FORM_FIELD.description]
        )
      ),
    });
  }, [assetOperationType]);

  const {
    values,
    touched,
    errors,
    isValid,
    dirty,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: { ...formInitialValues },
    validationSchema,
    onSubmit,
  });

  const handleSecretSwitchChange = useCallback(
    (value: boolean) => {
      if (secret) {
        return;
      }

      setFieldValue("secret", value);
    },
    [secret]
  );

  const onSecretValueReset = useCallback(() => {
    setFieldValue(FORM_FIELD.value, "");
  }, []);

  const constantValue = values[FORM_FIELD.value];
  const constantIsSecret = values[FORM_FIELD.secret];

  useEffect(() => {
    if (!secret && constantIsSecret && !constantValue) {
      setFieldValue(FORM_FIELD.secret, false);
    }
  }, [constantValue]);

  return (
    <Modal title={modalTitle} onClose={handleOnClose}>
      {assetOperationType === "newVersion" ? previousVersionDataRow : null}
      <form autoComplete="off" onSubmit={handleSubmit}>
        {assetOperationType !== "newVersion" ? (
          <>
            <Typography variant="body1" className={styles.rowTitle}>
              Constant Name
            </Typography>
            <TextField
              name={FORM_FIELD.name}
              placeholder="Enter name for constant..."
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[FORM_FIELD.name]}
              error={touched[FORM_FIELD.name] ? errors[FORM_FIELD.name] : ""}
              isDisabled={assetOperationType === "edit"}
            />
          </>
        ) : null}
        <Typography variant="body1" className={styles.rowTitle}>
          Value
        </Typography>
        {secret && assetOperationType === "edit" ? (
          <SecuredField
            name={FORM_FIELD.value}
            onChange={handleChange}
            value={constantValue}
            onReset={onSecretValueReset}
            dummyText={SECRET_VALUE_PLACEHOLDER}
            showDummyTextIfEmptyValue
          />
        ) : (
          <TextField
            name={FORM_FIELD.value}
            placeholder="Set value for constant..."
            onChange={handleChange}
            value={constantValue}
          />
        )}
        <Typography variant="body1" className={styles.rowTitle}>
          Description
        </Typography>
        <TextField
          name={FORM_FIELD.description}
          placeholder="Add description..."
          type="textarea"
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            touched[FORM_FIELD.description]
              ? errors[FORM_FIELD.description]
              : ""
          }
          value={values[FORM_FIELD.description]}
        />
        <div className={styles.secretRow}>
          <Switch
            isChecked={constantIsSecret}
            onChange={handleSecretSwitchChange}
            disabled={secret || !constantValue}
            className={styles.switch}
          />
          <div>
            <Typography variant="body1">Make secret</Typography>
            <Typography variant="body2" className={styles.helpText}>
              By making value secret you are hiding it inside of your code. You
              won’t be able to unsecret this value.
            </Typography>
          </div>
        </div>
        <div className={styles.controls}>
          <Button
            variant="secondary"
            className={styles.cancel}
            onClick={handleOnClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={!isValid || !dirty || createdAssetLoading}
          >
            {createdAssetLoading ? <Loader size={16} /> : acceptButtonLabel}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ConstantModal;
