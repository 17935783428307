// This component displays an error message if the user service fails to get the user's data
// It shows a status code a divider and a message
// It also shows a retry button that will attempt to get the user's data again

import styles from "./ErrorMessage.module.scss";
import { Button } from "components/common/Button/Button";
import Typography from "components/Typography/Typography";
interface ErrorMessageProps {
  statusCode?: number;
  message: string;
  onRetry: () => void;
  disableRetry?: boolean;
}

export const ErrorMessage = ({
  statusCode,
  message,
  onRetry,
  disableRetry,
}: ErrorMessageProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.content__message}>
          <Typography variant={"h2"}>{statusCode}</Typography>
          <div className={styles.content__message__divider}>|</div>
          <Typography variant={"h2"}>{message}</Typography>
        </div>
        <Button
          size={"large"}
          variant="secondary"
          disabled={disableRetry}
          onClick={onRetry}
        >
          Retry
        </Button>
      </div>
    </div>
  );
};
