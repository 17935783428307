import { useState, useEffect } from "react";

const useMultiselection = <T,>(elementsArray: T[]) => {
  const [isSelectedAll, setIsSelectedAll] = useState<boolean>(false);
  const [selectedElements, setSelectedElements] = useState<T[]>([]);

  useEffect(() => {
    if (isSelectedAll) {
      setSelectedElements(elementsArray);
    } else if (
      !isSelectedAll &&
      selectedElements.length < elementsArray.length
    ) {
      // eslint-disable-next-line
      return;
    } else {
      setSelectedElements([]);
    }
  }, [isSelectedAll]);

  useEffect(() => {
    const selectedAllStatus =
      elementsArray.length !== 0 &&
      selectedElements.length === elementsArray.length;

    setIsSelectedAll(selectedAllStatus);
  }, [selectedElements]);

  const selectAll = (e) => {
    e.stopPropagation();
    setIsSelectedAll((prev) => !prev);
  };

  return {
    isSelectedAll,
    selectedElements,
    setSelectedElements,
    selectAll,
  };
};

export default useMultiselection;
