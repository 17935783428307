import styles from "./ConnectionsPages.module.scss";
import { useEffect, useMemo } from "react";
import { Outlet, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { useAssetsState } from "store";
import { useConnectionsDataLoadActions } from "hooks/assets/useConnectionsDataLoadActions";
import { EmptyState } from "components/common/EmptyState/EmptyState";
import ConnectionsTabHeader from "pages/Assets/Connections/components/ConnectionsTabHeader";
import ConnectionsNav from "pages/Assets/Connections/components/ConnectionsNav";
import CreateConnectionConfigForm from "pages/Assets/Connections/components/CreateConnectionConfigForm";

const LABEL = {
  headerTitle: "Connections",
  headerSubtitle:
    "Enables you to establish and manage connections to various resources and services within your cloud environment.",
  noConnectionDetails: "No connection details yet...",
  noConnectionSelected:
    "Looks like you don’t have any connection selected yet.",
};

const noItemSelectedBlock = (
  <motion.div
    className={styles.no_item_selected}
    exit={{ opacity: 0 }}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ delay: 0.1, type: "spring" }}
  >
    <EmptyState
      variant="info"
      title={LABEL.noConnectionDetails}
      description={LABEL.noConnectionSelected}
    />
  </motion.div>
);

export function ConnectionsPages() {
  const {
    createdItem: { assetOperationType },
    displayArchivedItems,
    selectedItem: { id: activeItemId },
    data,
  } = useAssetsState((slice) => slice.connections);
  const {
    clearSelectedItem,
    setSelectedItemId,
    setDisplayArchivedItems,
    clearStore,
  } = useAssetsState((slice) => slice.connectionsActions);

  const { connectionId, workspaceID = "" } = useParams();

  const { loadInitialConnectionsData } = useConnectionsDataLoadActions();

  useEffect(() => {
    loadInitialConnectionsData({ workspaceId: workspaceID });

    return () => {
      clearStore();
    };
  }, [workspaceID]);

  useEffect(() => {
    if (!connectionId) {
      if (activeItemId) {
        clearSelectedItem();

        return;
      }

      return;
    }

    const connectionData = data[connectionId];

    if (connectionData) {
      if (connectionId !== activeItemId) {
        setSelectedItemId(connectionId);
      }

      if (!displayArchivedItems && connectionData.connection?.archive) {
        setDisplayArchivedItems(true);
      }
    }
  }, [connectionId, data, displayArchivedItems, activeItemId]);

  const content = useMemo(() => {
    if (assetOperationType === "create") {
      return <CreateConnectionConfigForm />;
    }

    if (activeItemId) {
      return <Outlet />;
    }

    return noItemSelectedBlock;
  }, [assetOperationType, activeItemId]);

  return (
    <div className={styles.page}>
      <ConnectionsTabHeader
        title={LABEL.headerTitle}
        subtitle={LABEL.headerSubtitle}
      />
      <div className={styles.content}>
        <ConnectionsNav />
        {content}
      </div>
    </div>
  );
}
