import { useState, useEffect } from "react";
import styles from "./Unauthorized.module.scss";
import userService from "api/http/user-service";
import { LinkButton } from "components/common/Button/LinkButton";
import { Button } from "components/common/Button/Button";
import LoadingScreen from "components/common/LoadingScreen/LoadingScreen";
import Loader from "components/common/Loader/Loader";
import Typography from "components/Typography/Typography";
import { debouncePromise } from "utils/helpers";
import { useToastsState } from "store";

export type BlockOutputProps = {
  canvasId: string;
};

export default function Unauthorized({ canvasId }: BlockOutputProps) {
  const addToast = useToastsState((slice) => slice.addToast);
  const [showResourceAccessRequest, setShowResourceAccessRequest] =
    useState(false);
  const [isRequestingAccess, setIsRequestingAccess] = useState(false);
  const [isAccessRequestLoading, setIsAccessRequestLoading] = useState(true);
  const [message, setMessage] = useState("");

  // find if the user has requested access for the canvas
  useEffect(() => {
    if (!canvasId) {
      return;
    }

    setIsAccessRequestLoading(true);
    userService
      .getUserResourceRequestedAccess(canvasId)
      .then((res) => {
        const hasRequestedAccess = !!res;
        setShowResourceAccessRequest(!hasRequestedAccess);
        hasRequestedAccess
          ? setMessage("Your access request is pending approval")
          : setMessage("Unauthorized");
      })
      .catch(() => {
        setShowResourceAccessRequest(false);
        setMessage("Unauthorized");
      })
      .finally(() => {
        setIsAccessRequestLoading(false);
      });
  }, [canvasId]);

  const requestResourceAccess = () => {
    setMessage("Sending access request...");
    setIsRequestingAccess(true);
    debouncePromise({
      promise: userService.requestResourceAccess(canvasId),
    })
      .then(() => {
        setShowResourceAccessRequest(false);
        setMessage("Your access request has been sent for approval");
      })
      .catch(() => {
        setShowResourceAccessRequest(true);
        setIsRequestingAccess(false);
        setMessage("Unauthorized");
        addToast({
          variant: "error",
          message: "Failed to request access. Try again later.",
        });
      });
  };

  return (
    <>
      {isAccessRequestLoading ? (
        <LoadingScreen text="Checking permissions..." />
      ) : (
        <div className="fullScreenMessage">
          <Typography variant="h3">{message}</Typography>
          <div className={styles.actions}>
            <LinkButton variant="primary" to="/">
              Go Home
            </LinkButton>
            {showResourceAccessRequest && (
              <Button
                variant="primary"
                onClick={requestResourceAccess}
                disabled={isRequestingAccess}
              >
                {isRequestingAccess ? (
                  <Loader size={20} />
                ) : (
                  <span>Request Access</span>
                )}
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
}
