import { useMemo } from "react";
import { useCanvasState, useUserSettingsState } from "store";
import { computeLayerBlocksRunDisabilityReasons } from "utils/canvas";
import type { LayerType } from "models/canvas";

export const useLayerBlocksRunDisabilityReasons = ({
  layerId,
}: {
  layerId?: LayerType["id"];
}) => {
  const userSubscriptionTier = useUserSettingsState(
    (slice) => slice.profile.subscriptionTier
  );

  const organizationId = useCanvasState((slice) => slice.organizationId);
  const layerEditDisabilityReasons = useCanvasState((slice) => {
    if (!layerId) {
      return null;
    }

    return slice.layersEditDisabilityReasons[layerId] ?? null;
  });

  return useMemo(() => {
    return computeLayerBlocksRunDisabilityReasons({
      userSubscriptionTier,
      organizationId,
      layerEditDisabilityReasons,
    });
  }, [userSubscriptionTier, organizationId, layerEditDisabilityReasons]);
};
