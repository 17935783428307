import { getFirstConnectedCodeBlock } from "utils/helpers";
import { BLOCK_TYPE } from "config/canvasConfig";
import { SAGEMAKER_DEPLOYMENT_DISABILITY } from "config/deploymentConfig";
import type { BlockType, EdgeType } from "models/canvas";

/**
 * Check if SageMaker Endpoint can be deployed. If not, return the reasons.
 */
export function getDeploymentDisabilityReasons({
  blocks,
  edges,
}: {
  blocks: BlockType[];
  edges: EdgeType[];
}) {
  // get SageMaker endpoint
  const { sageMakerEndpoint } = blocks.reduce<{
    sageMakerEndpoint: BlockType | null;
  }>(
    (acc, block) => {
      if (block.type === BLOCK_TYPE.SAGEMAKER_ENDPOINT) {
        acc.sageMakerEndpoint = block;
      }
      return acc;
    },
    { sageMakerEndpoint: null }
  );

  const disabilityReasons: {
    disability: SAGEMAKER_DEPLOYMENT_DISABILITY;
    blockId?: BlockType["id"];
  }[] = [];

  // no code connected to SageMaker endpoint
  if (sageMakerEndpoint) {
    const hasCodeConnected = !!getFirstConnectedCodeBlock({
      blockId: sageMakerEndpoint.id,
      blocks,
      edges,
    });
    if (!hasCodeConnected) {
      disabilityReasons.push({
        disability: SAGEMAKER_DEPLOYMENT_DISABILITY.NO_CODE_CONNECTED,
        blockId: sageMakerEndpoint.id,
      });
    }
  }

  return disabilityReasons;
}
