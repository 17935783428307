import styles from "./EmptyState.module.scss";
import { useMemo, type ReactNode, type PropsWithChildren } from "react";
import type { IconType } from "react-icons";
import { BiArchive, BiError, BiErrorCircle } from "react-icons/bi";
import cn from "classnames";

type Variant = "info" | "error" | "warning";

type EmptyStateProps = PropsWithChildren<{
  variant?: Variant | null;
  size?: "large" | "small";
  icon?: IconType | null;
  title: ReactNode;
  description?: ReactNode;
  containerClassName?: string;
  iconClassName?: string;
  titleClassName?: string;
  descriptionClassName?: string;
}>;

const ICON_SIZE = {
  small: 24,
  large: 42,
};

export const EmptyState = ({
  variant,
  size = "small",
  icon,
  title,
  description,
  containerClassName = "",
  iconClassName = "",
  titleClassName = "",
  descriptionClassName = "",
  children,
}: EmptyStateProps) => {
  const Icon = useMemo(() => {
    if (!icon && variant) {
      switch (variant) {
        case "info":
          return BiArchive;
        case "error":
          return BiError;
        case "warning":
          return BiErrorCircle;
      }
    }

    return icon;
  }, [icon, variant]);

  return (
    <div className={cn(styles.container, containerClassName)}>
      {Icon && (
        <div
          className={cn(styles.icon, iconClassName, {
            [styles.icon_info]: variant === "info",
            [styles.icon_error]: variant === "error",
            [styles.icon_warning]: variant === "warning",
          })}
        >
          <Icon size={ICON_SIZE[size]} />
        </div>
      )}
      <div
        className={cn(styles.title, titleClassName, {
          [styles.title_size_small]: size === "small",
          [styles.title_size_large]: size === "large",
        })}
      >
        {title}
      </div>
      {description && (
        <div
          className={cn(styles.description, descriptionClassName, {
            [styles.description_size_small]: size === "small",
            [styles.description_size_large]: size === "large",
          })}
        >
          {description}
        </div>
      )}
      {children ? <div className={styles.actions}>{children}</div> : null}
    </div>
  );
};
