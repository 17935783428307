import styles from "./SelectConnection.module.scss";
import { type FC, useMemo, useCallback } from "react";
import { Button } from "components/common/Button/Button";
import { DropdownMenuButton } from "components/common/DropdownMenuButton/DropdownMenuButton";
import { EmptyState } from "components/common/EmptyState/EmptyState";
import ResizableNav from "components/common/ResizableNav/ResizableNav";
import Typography from "components/Typography/Typography";
import { useAssetsData } from "hooks/useAssetsData";
import { useNavigate } from "react-router-dom";
import { useAssetsState } from "store";
import { getConnectionTypeIcon } from "pages/Assets/Connections/utils";
import { useQueryData } from "hooks/assets/useQueryData";
import type { QueryVersionType } from "pages/Assets/types/queryTypes";
import { ASSET_PERMISSION } from "pages/Assets/config";

const SelectConnection: FC = () => {
  const { getCurrentAssetItemsData } = useAssetsData();
  const { updateQueryVersionData } = useQueryData();

  const navigate = useNavigate();
  const { data: connectionsData, connectionTypes } = useAssetsState(
    (slice) => slice.connections
  );

  const { activeItemVersionId, activeVersionData: activeVersionStoreData } =
    getCurrentAssetItemsData("query");

  const activeVersionData = activeVersionStoreData as QueryVersionType;

  const isEditor = activeVersionData?.permission === ASSET_PERMISSION.write;

  const { selectedConnection } = useMemo(() => {
    const connectionId = activeVersionData?.connection_id;
    if (!connectionId) {
      return {};
    }
    const connection = connectionsData[connectionId];
    const connectionTypeObj =
      connectionTypes[connection?.connection.connection_type_id];
    const connectionIcon = getConnectionTypeIcon(
      connectionTypeObj?.connection_type
    );
    return {
      selectedConnection: {
        name: connection?.connection.name ?? "",
        icon: connectionIcon,
      },
    };
  }, [connectionsData, connectionTypes, activeVersionData]);

  const handleCreateConnection = () => {
    navigate("/assets/connections", { replace: true });
  };

  const onConnectionSelect = useCallback(
    (connectionId: string, id?: string) => {
      if (!id) {
        return;
      }
      updateQueryVersionData("connection_id", connectionId, id);
    },
    []
  );

  const connectionsOptions = useMemo(
    () =>
      Object.values(connectionsData).map(
        ({
          connection: {
            connection_type_id: connectionTypeId,
            name: connectionName,
            id: connectionId,
          },
        }) => {
          const connectionTypeObj = connectionTypes[connectionTypeId];
          const connectionIcon = getConnectionTypeIcon(
            connectionTypeObj?.connection_type
          );

          return {
            key: connectionId,
            label: connectionName,
            value: connectionId,
            icon: connectionIcon,
            iconProps: { width: 16, height: 16 },
            type: "option" as const,
            onClick: () => {
              onConnectionSelect(connectionId, activeItemVersionId ?? "");
            },
          };
        }
      ),
    [connectionsData, connectionTypes, activeItemVersionId]
  );

  const noConnectionContent = (
    <EmptyState
      variant="info"
      title="No connections created yet..."
      description="You currently don’t have any connections created. To create your first connection click “Create Connection” button below."
      containerClassName={styles.noConnectionsDropdown}
    >
      <Button
        onClick={handleCreateConnection}
        variant="text"
        className={styles.button}
      >
        Create Connection
      </Button>
    </EmptyState>
  );

  const selectedConnectionContent = useMemo(() => {
    const Icon = selectedConnection?.icon;

    return (
      <div className={styles.selectedConnectionContent}>
        {Icon && <Icon width={16} height={16} />}
        <span className={styles.selectedText}>{selectedConnection?.name}</span>
      </div>
    );
  }, [selectedConnection]);

  return (
    <ResizableNav position="right" defaultSize={274}>
      <>
        {activeItemVersionId ? (
          <DropdownMenuButton
            menuOptions={
              connectionsOptions.length
                ? connectionsOptions
                : [
                    {
                      key: "no-connection-content",
                      type: "custom",
                      component: noConnectionContent,
                    },
                  ]
            }
            nodesClassNames={{
              container: styles.dropdownContainer,
              button: styles.dropdownButton,
              contextMenu: styles.dropdownMenu,
            }}
            disabled={!isEditor}
            contextMenuFitWidth
          >
            {selectedConnection ? (
              selectedConnectionContent
            ) : (
              <Typography
                variant="caption1"
                className={styles.dropdownPlaceholder}
              >
                Select Connection
              </Typography>
            )}
          </DropdownMenuButton>
        ) : null}
        <EmptyState
          variant="info"
          title="No connection selected..."
          description="To see database maps you need to select a connection in the selector above."
          containerClassName={styles.emptyConnections}
        />
      </>
    </ResizableNav>
  );
};

export default SelectConnection;
