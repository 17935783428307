import styles from "./OrganizationRoles.module.scss";
import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { getOrganizationCustomRoles } from "api/http/role-service";
import { getOrganization } from "api/http/organization-service";
import { useModal } from "hooks/useModal";
import { Button } from "components/common/Button/Button";
import { CreateRoleModal } from "components/modals/CreateRoleModal/CreateRoleModal";
import { TitleSection } from "components/organization/TitleSection/TitleSection";
import { OrgEmptyState } from "components/organization/OrgEmptyState/OrgEmptyState";
import { RoleCard } from "components/organization/RoleCard/RoleCard";
import { RoleCardSkeleton } from "components/organization/RoleCardSkeleton/RoleCardSkeleton";
import { debouncePromise } from "utils/helpers";
import type { Organization } from "models/organization";
import type { Role } from "../OrganizationHome/types";

export function OrganizationRoles() {
  const { openModal } = useModal();

  const [isLoading, setIsLoading] = useState(true);
  const [organization, setOrganization] = useState<Organization>();
  const [roles, setRoles] = useState<Role[]>([]);
  const { orgID = "" } = useParams();

  const isAdmin = organization?.role === "Organization Admin";

  // fetch organization and roles
  useEffect(() => {
    setIsLoading(true);
    debouncePromise({ promise: getOrganization(orgID) }).then((orgResult) => {
      setOrganization(orgResult);
      getOrganizationCustomRoles(orgID)
        .then((returnedValues) => {
          setRoles(returnedValues);
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  }, [orgID]);

  const handleCreateRole = useCallback(() => {
    openModal({
      title: "Create Role",
      content: ({ onModalClose }) => (
        <CreateRoleModal
          organizationId={orgID}
          roles={roles}
          onModalClose={onModalClose}
        />
      ),
    });
  }, [roles, openModal]);

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <TitleSection
          title="Roles"
          rightControls={
            isAdmin ? (
              <Button size="large" variant="primary" onClick={handleCreateRole}>
                Create Role
              </Button>
            ) : null
          }
        />

        {/* Loading */}
        {isLoading ? (
          <>
            <RoleCardSkeleton />
            <RoleCardSkeleton />
            <RoleCardSkeleton />
          </>
        ) : null}

        {/* Empty State */}
        {!isLoading && roles.length === 0 ? (
          <OrgEmptyState
            title="No roles..."
            description={
              isAdmin
                ? "Create a new role to organize permissions."
                : "The organization doesn't have any roles yet."
            }
          >
            {isAdmin ? (
              <Button
                size="large"
                variant="secondary"
                onClick={handleCreateRole}
              >
                Create Role
              </Button>
            ) : null}
          </OrgEmptyState>
        ) : null}

        {/* Role Cards */}
        {roles.map((role) => {
          return <RoleCard key={role.id} orgID={orgID} role={role} />;
        })}
      </div>
    </div>
  );
}
