import styles from "./FunctionsPages.module.scss";
import { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { AssetsNav, AssetsTabHeader } from "pages/Assets/components";
import { useAssetsData } from "hooks/useAssetsData";
import { useAssetsDataLoadActions } from "hooks/assets/useAssetsDataLoadActions";
import CodeEditor from "components/common/CodeEditor/CodeEditor";
import { WindowHeader } from "components/common/WindowHeader/WindowHeader";

const headerTitle = "Functions & Classes";
const headerSubtitle =
  "Save and share functions to create consistency and avoid repetitive coding.";

const noVersionSection = (
  <div className={styles.noVersionSection}>
    {/* https://linear.app/zerve-ai/issue/FRO-1193/hide-requirements-panel-on-functions-page-on-assets */}
    {/* <ResizableNav position="right" defaultSize={300}>
      <>
        <WindowHeader>Requirements</WindowHeader>
        <EmptyState
          variant="info"
          title="No Requirements yet..."
          description="Looks like you haven't added any requirements yet."
          containerClassName={styles.requirementsContentWrapper}
        />
      </>
    </ResizableNav> */}
    <div className={styles.codeBlock}>
      <WindowHeader>Function Definition</WindowHeader>
      <div className={styles.codeSection}>
        <CodeEditor
          language="python"
          placeholderText="# Nothing to show here. Start by clicking “Create Function” and then write some code here..."
          value=""
          onChange={() => {}}
          readOnly
          folding={false}
          lineNumbers={false}
          renderLineHighlight={false}
        />
      </div>
    </div>
  </div>
);

export function FunctionsPages() {
  const { workspaceID = "" } = useParams();
  const {
    getCurrentAssetItemsData,
    getCurrentAssetItemActions,
    getCreatedAssetItemData,
  } = useAssetsData();
  const { loadInitialAssetsData } = useAssetsDataLoadActions();
  const { activeItemVersionId } = getCurrentAssetItemsData("function");
  const { assetOperationType } = getCreatedAssetItemData("function");
  const { clearStore } = getCurrentAssetItemActions("function");

  useEffect(() => {
    loadInitialAssetsData({
      assetType: "function",
      workspaceId: workspaceID,
    });

    return () => {
      clearStore();
    };
  }, [workspaceID]);

  return (
    <div className={styles.page}>
      <AssetsTabHeader
        title={headerTitle}
        subtitle={headerSubtitle}
        assetType="function"
      />
      <div className={styles.page__body}>
        <AssetsNav assetType="function" />
        {activeItemVersionId === null || assetOperationType === "create"
          ? noVersionSection
          : null}
        <Outlet />
      </div>
    </div>
  );
}
