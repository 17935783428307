import styles from "./DeleteDeveloperSettingsModal.module.scss";
import { useState } from "react";
import { Button } from "components/common/Button/Button";
import TextField from "components/common/TextField/TextField";
import Typography from "components/Typography/Typography";

interface DeleteDeveloperSettingsModalProps {
  onCancelDelete: () => void;
  onDeleteDeveloperSettings: () => void;
}

export function DeleteDeveloperSettingsModal({
  onCancelDelete,
  onDeleteDeveloperSettings,
}: DeleteDeveloperSettingsModalProps) {
  const [inputValue, setInputValue] = useState("");

  const handleOnInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const isInputError = inputValue !== "DELETE" && inputValue.length > 0;
  const handleDeveloperSettingsDeletion = () => {
    if (isInputError || inputValue.length === 0) {
      return;
    }
    onDeleteDeveloperSettings();
  };

  return (
    <>
      <Typography variant="body2" className={styles.subHeading}>
        If you are sure you want to continue with the deletion your developer
        API settings, please continue below.
      </Typography>
      <Typography variant="body2" className={styles.subHeading}>
        This action will delete your developer API settings.
      </Typography>
      <TextField
        label="To verify, type DELETE below:"
        containerClassName={styles.deleteInput}
        onChange={handleOnInputChange}
      />
      <div className={styles.controls}>
        <Button variant="secondary" onClick={onCancelDelete}>
          Cancel
        </Button>
        <Button
          variant="crucial"
          disabled={isInputError || inputValue.length === 0}
          onClick={handleDeveloperSettingsDeletion}
        >
          Delete Settings
        </Button>
      </div>
    </>
  );
}
