import { createContext, useContext } from "react";

export const SidebarAccordionContext = createContext<{
  activeIds: Set<string>;
  onToggleId: (id: string) => void;
} | null>(null);

export function useSidebarAccordionContext() {
  const context = useContext(SidebarAccordionContext);

  if (!context) {
    throw new Error(
      "useSidebarAccordionContext has to be used within <SidebarAccordionContext.Provider>"
    );
  }

  return context;
}
