import { type FC } from "react";
import { Button } from "components/common/Button/Button";
import Typography from "components/Typography/Typography";
import styles from "./DeleteAccount.module.scss";

interface DeleteAccountProps {
  onModalOpen: () => void;
}

const DeleteAccount: FC<DeleteAccountProps> = ({
  onModalOpen,
}: DeleteAccountProps) => {
  return (
    <div className={styles.wrapper}>
      <div>
        <Typography variant="h2">Delete Account</Typography>
        <Typography variant="body2" className={styles.subTitle}>
          Once you delete your account, there is no going back. Please be
          certain.
        </Typography>
      </div>
      <Button variant="crucial" onClick={onModalOpen}>
        Delete Account
      </Button>
    </div>
  );
};

export default DeleteAccount;
