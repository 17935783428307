import styles from "./UserSettingsSecurity.module.scss";
import { type FC } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import cn from "classnames";
import { Button } from "components/common/Button/Button";
import ToasterMessage from "components/common/ToasterMessage/ToasterMessage";
import PasswordField from "components/common/PasswordField/PasswordField";
import { useUserSettings } from "hooks/useUserSettings";
import { useUserSettingsState } from "store";
import { UserSettingsTabHeader } from "components/UserSettingsTabHeader";
import { UserMFASettings } from "./UserMFASettings/UserMFASettings";

const UserSettingsSecurity: FC = () => {
  const { user } = useAuth0();
  const {
    updateUserSecuritySettingsField,
    updateSecuritySettingsIsUpdated,
    updateUserPasswordApi,
  } = useUserSettings();
  const securityFields = useUserSettingsState((slice) => slice.security.fields);
  const securityIsUpdated = useUserSettingsState(
    (slice) => slice.security.isUpdated
  );
  const securityErrors = useUserSettingsState((slice) => slice.errors.security);

  const isGoogleAuth = user?.sub?.includes("google-oauth2") ?? false;

  const allFieldsAreFilled = Object.values(securityFields).every((field) =>
    Boolean(field)
  );
  const hasSecurityErrors =
    !allFieldsAreFilled ||
    Object.values(securityErrors).some((error) => Boolean(error));

  const onPasswordUpdateToasterRemove = (): void => {
    updateSecuritySettingsIsUpdated(false);
  };

  const handleUpdatePassword = (): void => {
    if (isGoogleAuth || hasSecurityErrors) {
      return;
    }
    updateUserPasswordApi();
  };

  const handleFieldOnBlur = (field: string, value: string): void => {
    updateUserSecuritySettingsField(field, value);
  };

  return (
    <div className={styles.container}>
      <UserSettingsTabHeader heading="Security" />

      <div className={styles.heading}>Multi-Factor Authentication (MFA)</div>
      <UserMFASettings />

      <div className={styles.heading}>Password</div>
      {isGoogleAuth ? (
        <ToasterMessage variant="warning" animate={false}>
          Important Note! You have signed up Zerve AI using your Google account,
          which means you can&apos;t change your password directly from here.
        </ToasterMessage>
      ) : null}
      <PasswordField
        label="Old Password"
        placeholder="Enter old password..."
        containerClassName={cn(styles.rowMarginBottom, {
          [styles.rowMarginTop]: isGoogleAuth,
        })}
        onBlur={(e) => {
          handleFieldOnBlur("oldPassword", e.target.value);
        }}
        defaultValue={securityFields.oldPassword}
        error={securityErrors.oldPassword}
        isDisabled={isGoogleAuth}
      />
      <PasswordField
        label="New Password"
        placeholder="Enter new password..."
        helperText="Make sure it's at least 8 characters in length and contains at least 3 of the following 4 types of characters: lower-case letters (a-z), upper-case letters (A-Z), numbers (i.e. 0-9), and special characters (e.g !@#$%^&*)."
        containerClassName={styles.rowMarginBottom}
        onBlur={(e) => {
          handleFieldOnBlur("newPassword", e.target.value);
        }}
        defaultValue={securityFields.newPassword}
        error={securityErrors.newPassword}
        isDisabled={isGoogleAuth}
      />
      <PasswordField
        label="Confirm New Password"
        placeholder="Confirm new password..."
        onBlur={(e) => {
          handleFieldOnBlur("confirmPassword", e.target.value);
        }}
        defaultValue={securityFields.confirmPassword}
        error={securityErrors.confirmPassword}
        isDisabled={isGoogleAuth}
      />
      <Button
        className={styles.updatePasswordButton}
        onClick={handleUpdatePassword}
        disabled={isGoogleAuth || hasSecurityErrors}
      >
        Update Password
      </Button>
      {securityIsUpdated ? (
        <div className={styles.passwordUpdatedToastWrapper}>
          <ToasterMessage
            variant="success"
            className={styles.passwordUpdatedToast}
            onRemove={onPasswordUpdateToasterRemove}
          >
            Password successfully changed
          </ToasterMessage>
        </div>
      ) : null}
    </div>
  );
};

export default UserSettingsSecurity;
