import styles from "./PublishedReportForm.module.scss";
import { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import { useFormik } from "formik";
import * as Yup from "yup";
import { BiLinkExternal } from "react-icons/bi";
import { useCanvasState } from "store";
import { Button } from "components/common/Button/Button";
import Loader from "components/common/Loader/Loader";
import TextField from "components/common/TextField";
import Typography from "components/Typography";
import ToasterMessage from "components/common/ToasterMessage";
import { TimeAgo } from "components/common/TimeAgo/TimeAgo";
import { usePublishReportFormActions } from "./usePublishedReportFormActions";
import type { BlockType } from "models/canvas";
import type { PublishedReport } from "models/publishedReport";

type PublishedReportFormProps = {
  blockId: BlockType["id"];
  data?: PublishedReport | null;
  onSubmitSuccess?: () => void;
  onCancel?: () => void;
  onDelete?: () => void;
};

export function PublishedReportForm({
  blockId,
  data,
  onSubmitSuccess,
  onCancel,
  onDelete,
}: PublishedReportFormProps) {
  const {
    isSubmitActionInProgress,
    submitCreatePublishedReport,
    submitUpdatePublishedReportJob,
  } = usePublishReportFormActions();

  const isCreateMode = !data;

  const getInitalFormValues = () => {
    return {
      name: isCreateMode ? "" : data.name,
      active: false,
    };
  };

  const formInitialValues = useMemo(() => {
    const initialValues = getInitalFormValues();
    return initialValues;
  }, []);

  const { workspaceId } = useCanvasState((slice) => ({
    workspaceId: slice.workspaceId,
  }));

  const onSubmit = (formData) => {
    if (isCreateMode) {
      submitCreatePublishedReport({
        blockId,
        data: {
          name: formData.name.trim().toLowerCase(),
          workspace_id: workspaceId ? workspaceId : undefined,
        },
        onSuccess: onSubmitSuccess,
      });
    } else {
      submitUpdatePublishedReportJob({
        reportId: data.id,
        blockId: blockId,
        data: {
          name: formData.name.trim(),
        },
        onSuccess: onSubmitSuccess,
      });
    }
  };

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      name: Yup.string()
        .trim()
        .required("Required")
        .matches(
          /^[a-zA-Z0-9_\-. ]*$/,
          "Only letters, numbers, underscores, dots, and hyphens are allowed"
        )
        .max(64, `The text entered exceeds the maximum length (64 characters)`),
    });
  }, []);

  const {
    values,
    touched,
    errors,
    isValid,
    handleBlur,
    handleChange,
    handleSubmit,
    validateForm,
  } = useFormik({
    initialValues: { ...formInitialValues },
    validationSchema,
    enableReinitialize: true,
    onSubmit,
  });

  useEffect(() => {
    validateForm();
  }, [validationSchema]);

  const getFieldErrorMessage = (fieldName: string) => {
    const fieldNameParts = fieldName.split(".");

    if (!fieldNameParts.length) {
      return;
    }

    if (fieldNameParts.length >= 1) {
      const touchedInfo = fieldNameParts.reduce(
        (prev, curr) => prev?.[curr],
        touched
      );

      if (!touchedInfo) {
        return;
      }
      const errorInfo = fieldNameParts.reduce(
        (prev, curr) => prev?.[curr],
        errors
      );

      if (errorInfo && typeof errorInfo === "string") {
        return errorInfo;
      }
    }
  };

  const formFieldDisabled = isSubmitActionInProgress;

  const isSubmitButtonDisabled = !isValid || isSubmitActionInProgress;

  const timeUpdated = data?.time_updated || data?.time_created;

  return (
    <form className={styles.form} autoComplete="off" onSubmit={handleSubmit}>
      <div className={styles.fields}>
        {!isCreateMode && (
          <>
            <Typography variant="body1">
              {/* open in new tab */}
              <Link
                className={styles.link}
                to={`/report/${data?.name}`}
                target="_blank"
              >
                {`app.zerve.ai/report/${data.name}`} <BiLinkExternal />
              </Link>
            </Typography>
            <Typography
              variant="caption1"
              className={styles.lastPublishedLabel}
            >
              <span>Last Published:</span> <TimeAgo date={timeUpdated} />
            </Typography>
            <ToasterMessage fullWidth variant="info" animate={false}>
              When you update the report the current version in the block will
              be published. If you change the report name, the URL will be
              updated.
            </ToasterMessage>
          </>
        )}
        <TextField
          autoFocus
          name="name"
          label="Report Name"
          placeholder="Report name..."
          value={values.name || ""}
          onChange={handleChange}
          onBlur={handleBlur}
          error={getFieldErrorMessage("name")}
          isDisabled={formFieldDisabled}
          helperText={`Report address: app.zerve.ai/reports/${values.name.trim() ? values.name.trim() : "<report_name>"}`}
        />
      </div>
      <div
        className={cn(styles.controls, {
          [styles.controls__flexEnd]: isCreateMode,
        })}
      >
        <>
          {!isCreateMode && onDelete && (
            <Button
              variant="crucial"
              size="large"
              className={styles.button}
              onClick={onDelete}
            >
              Delete Report
            </Button>
          )}
        </>
        <div className={styles.buttonGroup}>
          {onCancel ? (
            <Button
              variant="secondary"
              size="large"
              className={styles.button}
              onClick={onCancel}
            >
              Cancel
            </Button>
          ) : null}
          <Button
            className={cn(styles.button, styles.submit_button)}
            type="submit"
            size="large"
            disabled={isSubmitButtonDisabled}
          >
            {isSubmitActionInProgress ? (
              <Loader size={16} />
            ) : isCreateMode ? (
              "Publish"
            ) : (
              "Update"
            )}
          </Button>
        </div>
      </div>
    </form>
  );
}
