import cn from "classnames";
import { type MouseEvent } from "react";
import AvatarPlaceholder from "assets/icons/avatar-placeholder.svg";
import styles from "./Avatar.module.scss";
import stylesEx from "styles/_exports.module.scss";

type AvatarProps = {
  userName?: string;
  mode?: "letter" | "icon" | "image";
  size?: "small" | "medium" | "large";
  userPicture?: string | null;
  wrapperClassName?: string;
  className?: string;
  userColor?: string;
  showTopBorder?: boolean;
  onClick?: (e?: MouseEvent<HTMLDivElement>) => void;
  outline?: boolean;
};

const colorMap: string[] = [
  stylesEx["code-blue"],
  stylesEx["code-green"],
  stylesEx["code-orange"],
  stylesEx["code-violet"],
  stylesEx["greyish-blue"],
  stylesEx["soft-pink"],
  stylesEx["brick-red"],
];

const USER_NAME_DEFAULT = "Unknown";

const Avatar = ({
  userName,
  mode = "letter",
  size = "medium",
  userPicture,
  wrapperClassName,
  className,
  userColor = "",
  showTopBorder,
  onClick,
  outline,
}: AvatarProps) => {
  const componentMode = userPicture ? "image" : mode;
  const letter = (userName || USER_NAME_DEFAULT)[0]?.toUpperCase();
  const color = colorMap[letter.charCodeAt(0) % 7];

  const style = {
    ...(outline ? { border: `1px solid ${userColor}` } : {}),
    ...(showTopBorder
      ? { borderTop: `2px solid ${userColor}` }
      : { borderTop: "none" }),
    ...(userColor ? { borderColor: userColor } : {}),
  };

  const defineImage = (image: any) => {
    return (
      <div
        onClick={onClick}
        className={cn(
          styles["avatar"],
          { [styles.clickable]: !!onClick },
          wrapperClassName
        )}
        style={style}
      >
        <img
          src={image}
          loading="lazy"
          className={cn(
            styles.avatar__image,
            styles[`avatar__image__${size}`],
            className
          )}
          alt="user_avatar"
          referrerPolicy="no-referrer"
          draggable={false}
        />
      </div>
    );
  };

  switch (componentMode) {
    case "image":
      return defineImage(userPicture);
    case "icon":
      return defineImage(AvatarPlaceholder);
    case "letter":
      return (
        <div
          className={cn(
            styles["avatar"],
            { [styles.clickable]: !!onClick },
            wrapperClassName
          )}
          style={userColor ? { borderColor: userColor } : {}}
        >
          <div
            className={cn(
              styles.avatar__letter,
              styles[`avatar__letter__${size}`],
              className
            )}
            style={{ backgroundColor: color }}
          >
            {letter}
          </div>
        </div>
      );
  }
};

export default Avatar;
