import { type StateCreator } from "store";
import type {
  ConstantItemType,
  ConstantVersionType,
  CreatedConstantType,
} from "pages/Assets/types/constantTypes";
import { DATA_LOAD_STATUS } from "config/appConfig";
import type { AssetItemOperationType } from "pages/Assets/types/abstractTypes";
import type { BaseAssetCanvasType } from "pages/Assets/types/baseAssetTypes";

const CONSTANTS_INITIAL_STATE = {
  constantsData: {},
  canvasConstantsData: {},
  isDisplayArchivedConstants: false,
  selectedConstant: {
    id: null,
    versionNumber: null,
  },
  createdConstantData: {
    assetOperationType: null,
    error: null,
    loading: false,
    constantData: {
      name: "",
      value: "",
      description: "",
      secret: false,
    },
  },
  constantsDataLoadState: DATA_LOAD_STATUS.NOT_LOADED,
  canvasConstantsDataLoadState: DATA_LOAD_STATUS.NOT_LOADED,
};

interface ConstantsAssets {
  constantsData: Record<string, ConstantItemType>;
  canvasConstantsData: Record<string, ConstantItemType>;
  isDisplayArchivedConstants: boolean;
  selectedConstant: {
    id: string | null;
    versionNumber: number | null;
  };
  createdConstantData: {
    assetOperationType: AssetItemOperationType;
    constantData: CreatedConstantType;
    error: string | null;
    loading: boolean;
  };
  constantsDataLoadState: DATA_LOAD_STATUS;
  canvasConstantsDataLoadState: DATA_LOAD_STATUS;
}

type ConstantVersionTypeWithAssetId = ConstantVersionType & {
  asset_id: string;
};

interface ConstantsActions {
  setCanvasConstant: (data: ConstantItemType) => void;
  setConstantsData: (constantsData: Record<string, ConstantItemType>) => void;
  setCanvasConstantsData: (
    canvasConstantsData: Record<string, ConstantItemType>
  ) => void;
  setSelectedConstantId: (selectedConstantId: string | null) => void;
  setSelectedConstantVersionNumber: (selectedVersionId: number | null) => void;
  clearSelectedConstant: () => void;
  clearCreatedConstantData: () => void;
  setConstantOperationType: (operationType: AssetItemOperationType) => void;
  setCreatedConstantData: (
    createdConstantData: Partial<CreatedConstantType>
  ) => void;
  setIsDisplayArchivedConstants: (isDisplay: boolean) => void;
  setCreatedConstantError: (error: string | null) => void;
  setCreatedConstantLoading: (loading: boolean) => void;
  updateConstantVersion: (version: ConstantVersionTypeWithAssetId) => void;
  deleteConstantVersion: (versionNumber: number) => void;
  setConstantDataLoadState: (dataLoadState: DATA_LOAD_STATUS) => void;
  setCanvasConstantDataLoadState: (dataLoadState: DATA_LOAD_STATUS) => void;
  deleteConstantById: (id: string) => void;
  deleteCanvasConstantById: (id: string) => void;
  deleteConstantVersionCanvasUsage: (data: {
    assetId: string;
    versionId: string;
    canvasId: string;
  }) => void;
  addConstantVersionCanvasUsage: (data: {
    assetId: string;
    versionId: string;
    canvasData: BaseAssetCanvasType;
  }) => void;
  clearStore: () => void;
}

export interface ConstantsAssetsSlice {
  constants: ConstantsAssets;
  constantsActions: ConstantsActions;
}

const constantsAssetsSlice: StateCreator<ConstantsAssetsSlice> = function (
  set,
  getState
) {
  return {
    constants: { ...CONSTANTS_INITIAL_STATE },
    constantsActions: {
      setCanvasConstant: (data) => {
        const assetId = data.constant.id;

        set(
          (store) => {
            store.assetsSlice.constants.canvasConstantsData[assetId] = data;
          },
          false,
          "assets/setCanvasConstant"
        );
      },
      setConstantsData: (constantsData) => {
        set(
          (store) => {
            store.assetsSlice.constants.constantsData = constantsData;
          },
          false,
          "assets/setConstantsData"
        );
      },
      setCanvasConstantsData: (canvasConstantsData) => {
        set(
          (store) => {
            store.assetsSlice.constants.canvasConstantsData =
              canvasConstantsData;
          },
          false,
          "assets/setCanvasConstantsData"
        );
      },
      setIsDisplayArchivedConstants: (isDisplay) => {
        set(
          (store) => {
            store.assetsSlice.constants.isDisplayArchivedConstants = isDisplay;
          },
          false,
          "assets/setIsDisplayArchivedConstants"
        );
      },
      setSelectedConstantId: (selectedConstantId) => {
        set(
          (store) => {
            store.assetsSlice.constants.selectedConstant.id =
              selectedConstantId;
          },
          false,
          "assets/setSelectedConstantId"
        );
      },
      setSelectedConstantVersionNumber: (selectedVersionNumber) => {
        set(
          (store) => {
            store.assetsSlice.constants.selectedConstant.versionNumber =
              selectedVersionNumber;
          },
          false,
          "assets/setSelectedConstantVersionNumber"
        );
      },
      setConstantOperationType: (operationType) => {
        set(
          (store) => {
            store.assetsSlice.constants.createdConstantData.assetOperationType =
              operationType;
          },
          false,
          "assets/setConstantOperationType"
        );
      },
      setCreatedConstantData: (createdConstantData) => {
        set(
          (store) => {
            store.assetsSlice.constants.createdConstantData.constantData = {
              ...store.assetsSlice.constants.createdConstantData.constantData,
              ...createdConstantData,
            };
          },
          false,
          "assets/setCreatedConstantData"
        );
      },
      clearSelectedConstant: () => {
        set(
          (store) => {
            store.assetsSlice.constants.selectedConstant = {
              id: null,
              versionNumber: null,
            };
          },
          false,
          "assets/clearSelectedConstant"
        );
      },
      setCreatedConstantError: (error) => {
        set(
          (store) => {
            store.assetsSlice.constants.createdConstantData.error = error;
          },
          false,
          "assets/setCreatedConstantError"
        );
      },
      setCreatedConstantLoading: (loading) => {
        set(
          (store) => {
            store.assetsSlice.constants.createdConstantData.loading = loading;
          },
          false,
          "assets/setCreatedConstantLoading"
        );
      },
      updateConstantVersion: (updatedVersionData) => {
        set(
          (store) => {
            if (
              !store.assetsSlice.constants.constantsData[
                updatedVersionData.asset_id
              ]?.versions
            ) {
              return;
            }

            store.assetsSlice.constants.constantsData[
              updatedVersionData.asset_id
            ].versions[updatedVersionData.version] = updatedVersionData;
          },
          false,
          "assets/updateConstantVersion"
        );
      },
      deleteConstantVersion: (versionNumber: number) => {
        set(
          (store) => {
            if (!store.assetsSlice.constants.selectedConstant.id) {
              return;
            }

            delete store.assetsSlice.constants.constantsData[
              store.assetsSlice.constants.selectedConstant.id
            ].versions[versionNumber];
          },
          false,
          "assets/deleteConstantVersion"
        );
      },
      clearCreatedConstantData: () => {
        set(
          (store) => {
            store.assetsSlice.constants.createdConstantData = {
              ...CONSTANTS_INITIAL_STATE.createdConstantData,
            };
          },
          false,
          "assets/clearCreatedConstantData"
        );
      },
      setConstantDataLoadState: (dataLoadState) => {
        set(
          (store) => {
            store.assetsSlice.constants.constantsDataLoadState = dataLoadState;
          },
          false,
          "assets/setConstantDataLoadState"
        );
      },
      setCanvasConstantDataLoadState: (dataLoadState) => {
        set(
          (store) => {
            store.assetsSlice.constants.canvasConstantsDataLoadState =
              dataLoadState;
          },
          false,
          "assets/setCanvasConstantDataLoadState"
        );
      },
      deleteConstantById: (id) => {
        const { [id]: _, ...rest } =
          getState().assetsSlice.constants.constantsData;
        set(
          (store) => {
            store.assetsSlice.constants.constantsData = rest;
          },
          false,
          "assets/deleteConstant"
        );
      },
      deleteCanvasConstantById: (id) => {
        const { [id]: _, ...rest } =
          getState().assetsSlice.constants.canvasConstantsData;
        set(
          (store) => {
            store.assetsSlice.constants.canvasConstantsData = rest;
          },
          false,
          "assets/deleteCanvasConstant"
        );
      },
      deleteConstantVersionCanvasUsage: ({ assetId, versionId, canvasId }) => {
        const { [assetId]: assetData } =
          getState().assetsSlice.constants.constantsData;
        if (!assetData) {
          return;
        }
        const versionData = Object.values(assetData.versions).find(
          (version) => {
            return version.id === versionId;
          }
        );

        if (!versionData) {
          return;
        }
        const versionNumber = versionData.version;
        const canvases = versionData.canvases;

        if (!canvases?.length) {
          return;
        }

        set(
          (store) => {
            store.assetsSlice.constants.constantsData[assetId].versions[
              versionNumber
            ].canvases = canvases.filter((item) => item.canvas_id !== canvasId);
          },
          false,
          "assets/deleteConstantVersionCanvasUsage"
        );
      },
      addConstantVersionCanvasUsage: ({ assetId, versionId, canvasData }) => {
        const { [assetId]: assetData } =
          getState().assetsSlice.constants.constantsData;
        if (!assetData) {
          return;
        }
        const versionData = Object.values(assetData.versions).find(
          (version) => {
            return version.id === versionId;
          }
        );

        if (!versionData) {
          return;
        }
        const versionNumber = versionData.version;
        const canvases = versionData.canvases;

        if (!Array.isArray(canvases)) {
          return;
        }

        const canvasId = canvasData.canvas_id;

        if (canvases.findIndex((el) => el.canvas_id === canvasId) >= 0) {
          return;
        }

        set(
          (store) => {
            store.assetsSlice.constants.constantsData[assetId].versions[
              versionNumber
            ].canvases = [...canvases, canvasData];
          },
          false,
          "assets/addConstantVersionCanvasUsage"
        );
      },
      clearStore: () => {
        set(
          (store) => {
            store.assetsSlice.constants = { ...CONSTANTS_INITIAL_STATE };
          },
          false,
          "assets/clearConstantsStore"
        );
      },
    },
  };
};

export default constantsAssetsSlice;
