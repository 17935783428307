import styles from "./AssetsNav.module.scss";
import { type FC, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import sortBy from "lodash-es/sortBy";
import { EmptyState } from "components/common/EmptyState/EmptyState";
import Fill from "components/common/Fill/Fill";
import ResizableNav from "components/common/ResizableNav/ResizableNav";
import { Button } from "components/common/Button/Button";
import AssetsNavItem from "../AssetsNavItem";
import { useAssetsData } from "hooks/useAssetsData";
import { useAssetsDataLoadActions } from "hooks/assets/useAssetsDataLoadActions";
import type {
  AbstractDataTypeForTab,
  AssetPageTabType,
  AssetPageTabTypeToDataType,
} from "../../types/abstractTypes";
import { emptyStateText, errorStateText } from "./emptyStateText";
import EditableAssetNavItem from "../EditableAssetNavItem";
import { AssetItemsSkeletons } from "../AssetItemsSkeletons";
import { DATA_LOAD_STATUS } from "config/appConfig";

interface AssetsNavProps {
  assetType: AssetPageTabType;
}

const AssetNav: FC<AssetsNavProps> = ({ assetType }: AssetsNavProps) => {
  const { workspaceID = "", versionID } = useParams();
  const {
    getCurrentAssetItemsData,
    getCreatedAssetItemData,
    getCreatedAssetItemActions,
    getCurrentAssetItemActions,
  } = useAssetsData();
  const { loadInitialAssetsData } = useAssetsDataLoadActions();
  const { assetOperationType } = getCreatedAssetItemData(assetType);
  const { assetsData, isDisplayArchivedItems, assetDataLoadState } =
    getCurrentAssetItemsData(assetType);
  const { setAssetItemOperationType } = getCreatedAssetItemActions(assetType);
  const { setActiveItemByVersionId } = getCurrentAssetItemActions(assetType);
  const checkIfAllAssetVersionsAreArchived = (
    itemData: AbstractDataTypeForTab<AssetPageTabType>
  ): boolean => {
    return Object.values(itemData.versions).every((version) => {
      return version.archive;
    });
  };

  useEffect(() => {
    if (versionID && assetDataLoadState === DATA_LOAD_STATUS.LOADED) {
      setActiveItemByVersionId(versionID);
    }
  }, [versionID, assetDataLoadState]);

  const isEditableAssetNavItemVisible = useMemo(
    () =>
      assetOperationType === "create" &&
      (assetType === "function" || assetType === "query"),
    [assetOperationType, assetType]
  );

  const assetsListData: AbstractDataTypeForTab<
    keyof AssetPageTabTypeToDataType
  >[] = useMemo(() => {
    const assets = Object.values(assetsData);
    const filteredAssets = isDisplayArchivedItems
      ? assets
      : assets.filter((asset) => {
          // TODO: remove !checkIfAllAssetVersionsAreArchived(asset) when BE provides fix for version/asset archiving
          // (https://linear.app/zerve-ai/issue/ZER-1294/put-asset-serviceassettypeversionversionidarchive-issues);
          // currently if all versions are archived, asset is not archived, but it should be
          // archived in this case
          return (
            !asset[assetType]?.archive &&
            !checkIfAllAssetVersionsAreArchived(asset)
          );
        });

    return sortBy(
      filteredAssets,
      (item: AbstractDataTypeForTab<keyof AssetPageTabTypeToDataType>) =>
        item?.[assetType]?.name
    );
  }, [assetsData, isDisplayArchivedItems]);

  const renderListContent = () => {
    if (assetDataLoadState === DATA_LOAD_STATUS.LOADING) {
      return <AssetItemsSkeletons />;
    }
    if (assetDataLoadState === DATA_LOAD_STATUS.ERROR) {
      return (
        <EmptyState
          variant="error"
          title={errorStateText[assetType].title}
          description={errorStateText[assetType].subtitle}
          containerClassName={styles.empty_state_container}
        >
          <Button
            variant="text"
            className={styles.button}
            onClick={() => {
              loadInitialAssetsData({
                assetType,
                workspaceId: workspaceID,
              });
            }}
          >
            {errorStateText[assetType].buttonText}
          </Button>
        </EmptyState>
      );
    }

    if (assetDataLoadState === DATA_LOAD_STATUS.LOADED) {
      if (!assetsListData.length) {
        return (
          <EmptyState
            variant="info"
            title={emptyStateText[assetType].title}
            description={emptyStateText[assetType].subtitle}
            containerClassName={styles.empty_state_container}
          >
            <Button
              variant="text"
              className={styles.button}
              onClick={() => {
                setAssetItemOperationType("create");
              }}
            >
              {emptyStateText[assetType].buttonText}
            </Button>
          </EmptyState>
        );
      }

      return assetsListData.map((assetData) => {
        return (
          <AssetsNavItem
            {...assetData}
            assetType={assetType}
            key={assetData[assetType]?.id}
          />
        );
      });
    }

    return null;
  };

  return (
    <ResizableNav position={"right"} defaultSize={274}>
      <>
        {isEditableAssetNavItemVisible ? (
          <EditableAssetNavItem assetType={assetType} />
        ) : null}
        <Fill className={styles.container}>{renderListContent()}</Fill>
      </>
    </ResizableNav>
  );
};

export default AssetNav;
