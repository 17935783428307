import { type FC, type MouseEvent, type ReactNode } from "react";
import cn from "classnames";
import { flexRender, type Table as TableType } from "@tanstack/react-table";
import { BiCaretDown, BiCaretUp } from "react-icons/bi";
import Typography from "components/Typography/Typography";
import styles from "./Table.module.scss";

type Error = {
  message: string;
};

interface TableProps {
  table: TableType<any>;
  error?: Error;
  emptyState?: ReactNode;
  bottomBar?: any;
  nodesClassNames?: {
    row?: string;
    wrapper?: string;
    container?: string;
    table?: string;
    header?: string;
  };
  getRowClassName?: (row) => string;
  onRowClick?: (row, event?: MouseEvent<HTMLElement>) => void;
}

const SORT_ORDER_ICON_MAP = {
  asc: <BiCaretUp size={12} />,
  desc: <BiCaretDown size={12} />,
};

const Table: FC<TableProps> = ({
  table,
  error,
  emptyState,
  bottomBar,
  nodesClassNames: {
    row: rowClassName = "",
    wrapper: wrapperClassName = "",
    container: containerClassName = "",
    table: tableClassName = "",
    header: headerClassName = "",
  } = {},
  getRowClassName,
  onRowClick,
}) => {
  return (
    <div className={wrapperClassName}>
      <div
        className={cn(styles.container, containerClassName, {
          [styles.errorTable]: error,
        })}
      >
        <table
          className={cn(styles.table, tableClassName, {
            [styles.tableWithBar]: !!bottomBar,
          })}
        >
          <thead className={cn(styles.table__header, headerClassName)}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {!header.isPlaceholder ? (
                      <div
                        onClick={header.column.getToggleSortingHandler()}
                        className={styles.sortingLabel}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </div>
                    ) : null}
                    {header.column.getCanSort() ? (
                      <div className={styles.sortIconWrapper}>
                        {SORT_ORDER_ICON_MAP[
                          header.column.getIsSorted() as string
                        ] ?? null}
                      </div>
                    ) : null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                className={cn(
                  styles["table__row"],
                  {
                    [styles["table__row_clickable"]]: !!onRowClick,
                  },
                  rowClassName,
                  getRowClassName?.(row)
                )}
                onClick={(event) => {
                  onRowClick?.(row, event);
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className={styles["table__cell"]}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>

        {bottomBar}

        {emptyState ? (
          <div className={styles.emptyBlock}>{emptyState}</div>
        ) : null}
      </div>

      {error ? (
        <Typography
          className={styles.errorMessage}
          component="span"
          variant="body2"
        >
          {error.message}
        </Typography>
      ) : null}
    </div>
  );
};

export default Table;
