import { IconBase, type IconBaseProps } from "react-icons";

export default function GPUIcon(props: IconBaseProps) {
  return (
    <IconBase viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M5.77641 17.47V7.13L8.30641 4.6H15.9624L18.4484 7.086V9.44H15.4564V8.186L14.4224 7.152H9.89041L8.76841 8.274V16.326L9.89041 17.448H14.4664L15.5444 16.37V13.972H12.2444V11.42H18.4484V17.47L15.9184 20H8.30641L5.77641 17.47Z"
          fill="currentColor"
        />
      </g>
    </IconBase>
  );
}
