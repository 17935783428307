/*
This component gets the user details from the user service and stores them in the user state
Once a successful response is received it proceeds to the Outlet
If an error happens the status code and message is displayed along with a retry button
*/

import { useAuth0 } from "@auth0/auth0-react";
import userService from "api/http/user-service";
import useToken from "hooks/useToken";
import type { PropsWithChildren } from "react";
import { useEffect, useState } from "react";
import { useUserState } from "store";
import { ErrorMessage } from "./ErrorMessage/ErrorMessage";
import LoadingScreen from "components/common/LoadingScreen/LoadingScreen";
import { AtCapacityPage } from "pages/AtCapacityPage/AtCapacityPage";
import { useFlags } from "launchdarkly-react-client-sdk";
import { debouncePromise } from "utils/helpers";

const ERROR_MESSAGE_401 = "Access denied. Please try again.";
const ERROR_MESSAGE_403 = "Access denied. Please try again.";
const ERROR_MESSAGE_500 = "Something went wrong. Please try again.";
const ERROR_MESSAGE_503 = "Service unavailable. Please try again.";

type UserSignInProps = PropsWithChildren;

export function UserSignIn({ children }: UserSignInProps) {
  const { user } = useAuth0();
  // token is fetched silently from auth0
  const { isAccessTokenLoading, hasAccessToken } = useToken();

  const { enableAtCapacityPage } = useFlags();

  const { userID, setUserID, setIsNewUser } = useUserState((slice) => ({
    userID: slice.userID,
    setUserID: slice.setUserID,
    setIsNewUser: slice.setIsNewUser,
  }));

  const [errorStatusCode, setErrorStatusCode] = useState<number>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isRequestingSignIn, setIsRequestingSignIn] = useState<boolean>(false);
  // this is to prevent the outlet from flickering will isRequestingSignIn is being set in the same useEffect
  const [madeInitialRequest, setMadeInitialRequest] = useState<boolean>(false);
  const [retryCount, setRetryCount] = useState<number>(0);

  useEffect(() => {
    // I've opted to not check isAccessTokenLoading here because both
    // isAccessTokenLoading and hasAccessToken are set in the same useEffect
    // and if it fails the user is logged out
    if (hasAccessToken && !userID) {
      setErrorMessage(undefined);
      setIsRequestingSignIn(true);
      setMadeInitialRequest(true);
      debouncePromise({ promise: userService.postAuthSignIn(user?.email) })
        .then((json) => {
          setIsNewUser(json.new_user);
          setUserID(json.id);
        })
        .catch((error) => {
          // due to the way we are handling errors in the callAPI if
          // no status code is returned we can assume that the service is down
          const statusCode = error?.cause?.status || 503;
          setErrorStatusCode(statusCode);
          if (statusCode === 401) {
            setErrorMessage(ERROR_MESSAGE_401);
          } else if (statusCode === 403) {
            setErrorMessage(ERROR_MESSAGE_403);
          } else if (statusCode === 500) {
            setErrorMessage(ERROR_MESSAGE_500);
          } else if (statusCode === 503) {
            setErrorMessage(ERROR_MESSAGE_503);
          } else {
            setErrorMessage(ERROR_MESSAGE_500);
          }
        })
        .finally(() => {
          setIsRequestingSignIn(false);
        });
    }
  }, [hasAccessToken, userID, retryCount]);

  // show the at capacity page to users who are targeted by the
  // at capacity page flag.
  if (enableAtCapacityPage) {
    return <AtCapacityPage />;
  }

  // including the userID is to prevent the loading screen from showing
  // when the token is being refreshed
  if (
    !userID &&
    (!madeInitialRequest || isAccessTokenLoading || isRequestingSignIn)
  ) {
    return <LoadingScreen text="Signing in..." />;
  } else if (errorMessage) {
    // TODO: move the condition to loader
    // See: https://github.com/remix-run/react-router/discussions/10223#discussioncomment-5909050
    return (
      <ErrorMessage
        statusCode={errorStatusCode}
        message={errorMessage}
        onRetry={() => {
          setRetryCount((prev) => prev + 1);
        }}
        disableRetry={isRequestingSignIn}
      />
    );
  }

  return children;
}
