import styles from "./RootCrumb.module.scss";
import { useMemo } from "react";
import { BiPlusCircle } from "react-icons/bi";
import { useFlags } from "launchdarkly-react-client-sdk";
import Avatar from "components/common/Avatar/Avatar";
import { Breadcrumb } from "layout/Layout";
import type { ContextMenuItem } from "components/common/ContextMenu/ContextMenu";
import type { Organization } from "models/organization";

type RootCrumbProps = {
  userAvatar: string;
  userName: string;
  organizations: Organization[];
  organizationId: Organization["id"];
};

export function RootCrumb({
  userAvatar,
  userName,
  organizations,
  organizationId,
}: RootCrumbProps) {
  const { enableOrganizations } = useFlags();

  // get the correct image and name to display in the header
  const { avatar, name } = useMemo(() => {
    const currentOrg = organizationId
      ? organizations.find((organization) => organization.id === organizationId)
      : null;

    const avatar = currentOrg ? null : userAvatar;
    const name = currentOrg ? currentOrg?.name : userName;

    return { avatar, name };
  }, [organizationId, organizations, userAvatar, userName]);

  const menuOptions = useMemo(() => {
    const list: ContextMenuItem[] = [];

    list.push(
      // heading (personal account)
      {
        key: "personal-account-heading",
        type: "heading",
        label: "Personal Account",
      },
      // personal account link
      {
        key: "personal-account",
        type: "option",
        label: (
          <div className={styles.menuItemWithAvatar}>
            <Avatar
              className={styles.avatar}
              userName={userName}
              userPicture={userAvatar}
            />
            <span>{userName}</span>
          </div>
        ),
        linkProps: { to: "/" },
      },
      // heading (organizations)
      {
        key: "organizations-heading",
        type: "heading",
        label: "Organizations",
      }
    );

    // organizations list
    organizations.forEach((organization, index) => {
      list.push({
        key: organization.id,
        type: "option",
        label: (
          <div className={styles.menuItemWithAvatar}>
            <Avatar className={styles.avatar} userName={organization.name} />
            <span>{organization.name}</span>
          </div>
        ),
        linkProps: { to: `/org/${organization.id}` },
        showDivider: enableOrganizations && index === organizations.length - 1,
      });
    });
    // do not show create organization option if the feature is disabled
    //  https://linear.app/zerve-ai/issue/FRO-1407/change-organizations-feature-flag
    if (enableOrganizations) {
      // create organization
      list.push({
        key: "create-organization",
        type: "option",
        icon: BiPlusCircle,
        label: "Create Organization",
        linkProps: { to: "/organization/create" },
      });
    }

    return list;
  }, [userAvatar, userName, organizations, enableOrganizations]);

  return (
    <Breadcrumb
      to={organizationId ? `/org/${organizationId}` : "/"}
      menuOptions={menuOptions}
    >
      <Avatar className={styles.avatar} userName={name} userPicture={avatar} />
      <span>{name}</span>
    </Breadcrumb>
  );
}
