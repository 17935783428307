import styles from "./WorkspaceCardSkeleton.module.scss";

export function WorkspaceCardSkeleton() {
  return (
    <div className={styles.card}>
      <div className={styles.title} />
      <div className={styles.stats}>
        {Array(3)
          .fill(0)
          .map((_, i) => {
            return (
              <div key={i} className={styles.stat}>
                <div className={styles.stat__icon} />
                <div className={styles.stat__number} />
              </div>
            );
          })}
      </div>
    </div>
  );
}
