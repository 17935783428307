import { type FC, useCallback, useRef } from "react";
import { Button } from "components/common/Button/Button";
import Typography from "components/Typography/Typography";
import AvatarPlaceholder from "assets/icons/avatar-placeholder.svg?react";
import styles from "./ProfilePicture.module.scss";
import { useUserSettings } from "hooks/useUserSettings";
import { useUserSettingsState } from "store";

const ProfilePicture: FC = () => {
  const { updateUserAvatar } = useUserSettings();
  const avatar = useUserSettingsState((slice) => slice.profile.avatar);

  const inputRef = useRef<HTMLInputElement>(null);

  const onImageUpload = (e) => {
    const file = e.target.files[0];
    updateUserAvatar(file);
    if (inputRef.current?.value) {
      inputRef.current.value = "";
    }
  };

  const handleUploadButtonClick = useCallback(() => {
    inputRef.current?.click();
  }, [inputRef, inputRef?.current]);

  return (
    <>
      <Typography variant="h3">Profile Picture</Typography>
      <div className={styles.pictureChangeWrapper}>
        {avatar ? (
          <img src={avatar} className={styles.avatar} alt="user-avatar" />
        ) : (
          <AvatarPlaceholder className={styles.avatar} />
        )}
        <div className={styles.buttonAndText}>
          <Button variant="secondary" onClick={handleUploadButtonClick}>
            Upload Photo
          </Button>
          <Typography variant="caption2" className={styles.subTitle}>
            We support PNGs, JPEGs under 10MB.
          </Typography>
        </div>
      </div>
      <input
        ref={inputRef}
        className={styles.hiddenInput}
        onChange={onImageUpload}
        type="file"
        accept="image/*"
      />
    </>
  );
};

export default ProfilePicture;
