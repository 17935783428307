import styles from "./RequirementsMenu.module.scss";
import { useEffect, useMemo } from "react";
import { BiReset, BiLogoDocker } from "react-icons/bi";

import {
  useCanvasState,
  useCanvasRequirementsState,
  useLocalCanvasPreferencesState,
} from "store";
import * as canvasRequirementsService from "api/http/canvas-requirements-service";
import { ShowLogsSection } from "components/ShowLogsSection/ShowLogsSection";
import SidebarAccordion from "components/SidebarAccordion/SidebarAccordion";
import { Button } from "components/common/Button/Button";
import MenuHeader from "components/common/MenuHeader/MenuHeader";
import SideNavContent from "components/common/SideNavContent/SideNavContent";
import Loader from "components/common/Loader/Loader";
import { EmptyState } from "components/common/EmptyState/EmptyState";
import IconButton from "components/common/IconButton/IconButton";
import { SavingStatusIndicator } from "components/common/SavingStatusIndicator/SavingStatusIndicator";
import {
  REQUIREMENTS_STATUS,
  REQUIREMENTS_BUILDING_STATUSES,
} from "config/canvasConfig";
import { CANVASES_REQUIREMENTS_SECTION } from "config/localPreferences";
import { useRequirements } from "components/menus/RequirmentsMenu/useRequirements";
import { RequirementsBuildingProgress } from "components/menus/RequirmentsMenu/RequirementsBuildingProgress";
import { Requirements } from "components/menus/RequirmentsMenu/Requirements";
import { LinuxPackages } from "components/menus/RequirmentsMenu/LinuxPackages";
import { EnvironmentVariables } from "components/menus/RequirmentsMenu/EnvironmentVariables";
import { DATA_LOAD_STATUS, VALIDATION_STATUS } from "config/appConfig";

export const HEADER_DESCRIPTION =
  "Customize the requirements to your canvas. These will be installed when you build and will be the default runtime for blocks in this canvas.";

export default function RequirementsMenu() {
  const canvasId = useCanvasState((slice) => slice.canvasId);

  const {
    requirementsLoadingStatus,
    requirementsSavingStatus,
    requirementsValidationStatus,
    requirements,
    linuxPackagesLoadingStatus,
    linuxPackagesSavingStatus,
    linuxPackages,
    environmentVariablesLoadingStatus,
    environmentVariablesSavingStatus,
    environmentVariables,
    requirementsStatus,
    executor,
    executorLoadingStatus,
    setExecutorLoadingStatus,
    setExecutor,
    setRequirementsLogs,
  } = useCanvasRequirementsState((slice) => ({
    requirementsLoadingStatus: slice.requirementsLoadingStatus,
    requirementsSavingStatus: slice.requirementsSavingStatus,
    requirementsValidationStatus: slice.requirementsValidationStatus,
    requirements: slice.requirements,
    linuxPackagesLoadingStatus: slice.linuxPackagesLoadingStatus,
    linuxPackagesSavingStatus: slice.linuxPackagesSavingStatus,
    linuxPackages: slice.linuxPackages,
    environmentVariablesLoadingStatus: slice.environmentVariablesLoadingStatus,
    environmentVariablesSavingStatus: slice.environmentVariablesSavingStatus,
    environmentVariables: slice.environmentVariables,
    requirementsStatus: slice.requirementsStatus,
    executor: slice.executor,
    executorLoadingStatus: slice.executorLoadingStatus,
    setExecutor: slice.setExecutor,
    setExecutorLoadingStatus: slice.setExecutorLoadingStatus,
    setRequirements: slice.setRequirements,
    setRequirementsLogs: slice.setRequirementsLogs,
  }));

  const { canvasRequirementsSections, setCanvasRequirementsSections } =
    useLocalCanvasPreferencesState((slice) => ({
      canvasRequirementsSections: slice.canvasRequirementsSections,
      setCanvasRequirementsSections: slice.setCanvasRequirementsSections,
    }));

  const {
    checkIsBuildDisabled,
    buildRequirements,
    fetchExecutor,
    fetchRequirements,
    fetchLinuxPackages,
    fetchEnvironmentVariables,
    resetRequirements,
  } = useRequirements();

  const activeSections = useMemo(
    () => new Set(canvasRequirementsSections),
    [canvasRequirementsSections]
  );

  // fetch executor
  useEffect(() => {
    if (executorLoadingStatus === DATA_LOAD_STATUS.NOT_LOADED) {
      fetchExecutor();
    }
  }, [executorLoadingStatus, fetchExecutor]);

  // force refetch executor on next component load
  useEffect(() => {
    return () => {
      setExecutorLoadingStatus(DATA_LOAD_STATUS.NOT_LOADED);
      setExecutor(null);
    };
  }, []);

  // fetch requirements
  useEffect(() => {
    if (requirementsLoadingStatus !== DATA_LOAD_STATUS.NOT_LOADED) {
      return;
    }

    fetchRequirements();
  }, [requirementsLoadingStatus, fetchRequirements]);

  // fetch linux packages
  useEffect(() => {
    if (linuxPackagesLoadingStatus !== DATA_LOAD_STATUS.NOT_LOADED) {
      return;
    }

    fetchLinuxPackages();
  }, [linuxPackagesLoadingStatus, fetchLinuxPackages]);

  // fetch environment variables
  useEffect(() => {
    if (environmentVariablesLoadingStatus !== DATA_LOAD_STATUS.NOT_LOADED) {
      return;
    }

    fetchEnvironmentVariables();
  }, [environmentVariablesLoadingStatus, fetchEnvironmentVariables]);

  // fetch build logs
  useEffect(() => {
    if (
      [
        REQUIREMENTS_STATUS.INITIALIZING,
        REQUIREMENTS_STATUS.VALIDATING,
        REQUIREMENTS_STATUS.DEPLOYING,
      ].some((status) => status === requirementsStatus)
    ) {
      canvasRequirementsService
        .getBuildLogs(canvasId)
        .then((log) => {
          const logString = log?.join("") || "";
          setRequirementsLogs(logString);
        })
        .catch((err) => {
          if (err?.response?.status !== 404) {
            console.log(err);
          }
        });
    }
  }, [requirementsStatus]);

  const { isBuilding, isBuildDisabled, isResetDisabled } = useMemo(() => {
    const buildDisabled = checkIsBuildDisabled({
      requirementsStatus,
      executorLoadingStatus,
    });

    const resetDisabled =
      buildDisabled ||
      (executor?.is_default &&
        executorLoadingStatus === DATA_LOAD_STATUS.LOADED);

    return {
      isBuilding: REQUIREMENTS_BUILDING_STATUSES.some(
        (status) => status === requirementsStatus
      ),
      isBuildDisabled: buildDisabled,
      isResetDisabled: resetDisabled,
    };
  }, [
    requirementsStatus,
    executorLoadingStatus,
    executor?.is_default,
    checkIsBuildDisabled,
  ]);

  return (
    <SideNavContent>
      <MenuHeader
        title="Requirements"
        description={HEADER_DESCRIPTION}
        borderBottom
      >
        <div className={styles.headerContent}>
          {/* Executor loading */}
          {executorLoadingStatus === DATA_LOAD_STATUS.NOT_LOADED ||
          executorLoadingStatus === DATA_LOAD_STATUS.LOADING ? (
            <Loader
              withOverlay
              classes={{ overlay: styles.executorLoadingOverlay }}
            >
              Loading...
            </Loader>
          ) : null}

          {/* Executor loaded */}
          {executorLoadingStatus === DATA_LOAD_STATUS.LOADED ? (
            isBuilding && requirementsStatus ? (
              <RequirementsBuildingProgress />
            ) : (
              <>
                {/* Build */}
                <Button
                  variant="secondary"
                  className={styles.buildButton}
                  disabled={isBuildDisabled}
                  onClick={buildRequirements}
                >
                  <BiLogoDocker size={20} />
                  Build
                </Button>

                {/* Reset */}
                <IconButton
                  variant="secondary"
                  tooltip="Reset requirements"
                  tooltipPlacement="right"
                  tooltipHideOnClick
                  tooltipClassName={styles.resetButtonContainer}
                  disabled={isResetDisabled}
                  onClick={resetRequirements}
                >
                  {requirementsStatus === REQUIREMENTS_STATUS.RESETTING ? (
                    <Loader size={20} />
                  ) : (
                    <BiReset size={20} />
                  )}
                </IconButton>
              </>
            )
          ) : null}

          {/* Executor loading error */}
          {executorLoadingStatus === DATA_LOAD_STATUS.ERROR ? (
            <div className={styles.errorMessageContainer}>
              <EmptyState
                variant="error"
                title="Something went wrong"
                description="Failed to load executor details."
              >
                <Button variant="text" onClick={fetchExecutor}>
                  Try again
                </Button>
              </EmptyState>
            </div>
          ) : null}
        </div>
      </MenuHeader>

      <ShowLogsSection />

      <div className={styles.content}>
        <SidebarAccordion
          activeIds={activeSections}
          onToggleId={(id) => {
            const sectionId = id as CANVASES_REQUIREMENTS_SECTION;
            const newSet = new Set(activeSections);
            if (newSet.has(sectionId)) {
              newSet.delete(sectionId);
            } else {
              newSet.add(sectionId);
            }
            setCanvasRequirementsSections([...newSet]);
          }}
        >
          {/* Requirements */}
          <SidebarAccordion.Item
            id={CANVASES_REQUIREMENTS_SECTION.REQUIREMENTS}
          >
            <SidebarAccordion.Header>
              <span className={styles.accordionHeaderLabel}>
                Requirements
                {requirements ? ` (${requirements.length})` : null}
              </span>
              {requirementsValidationStatus === VALIDATION_STATUS.INVALID ? (
                <span className={styles.validationStatus}>Invalid</span>
              ) : (
                <SavingStatusIndicator
                  status={requirementsSavingStatus}
                  className={styles.savingStatusIndicator}
                />
              )}
            </SidebarAccordion.Header>
            <SidebarAccordion.Body>
              <Requirements />
            </SidebarAccordion.Body>
          </SidebarAccordion.Item>

          {/* Linux Packages */}
          <SidebarAccordion.Item
            id={CANVASES_REQUIREMENTS_SECTION.LINUX_PACKAGES}
          >
            <SidebarAccordion.Header>
              <span className={styles.accordionHeaderLabel}>
                Linux Packages
                {linuxPackages ? ` (${linuxPackages.length})` : null}
              </span>
              <SavingStatusIndicator
                status={linuxPackagesSavingStatus}
                className={styles.savingStatusIndicator}
              />
            </SidebarAccordion.Header>
            <SidebarAccordion.Body>
              <LinuxPackages />
            </SidebarAccordion.Body>
          </SidebarAccordion.Item>

          {/* Environment Variables */}
          <SidebarAccordion.Item
            id={CANVASES_REQUIREMENTS_SECTION.ENVIRONMENT_VARIABLES}
          >
            <SidebarAccordion.Header>
              <span className={styles.accordionHeaderLabel}>
                Environment Variables
                {environmentVariables
                  ? ` (${environmentVariables.length})`
                  : null}
              </span>
              <SavingStatusIndicator
                status={environmentVariablesSavingStatus}
                className={styles.savingStatusIndicator}
              />
            </SidebarAccordion.Header>
            <SidebarAccordion.Body>
              <EnvironmentVariables />
            </SidebarAccordion.Body>
          </SidebarAccordion.Item>
        </SidebarAccordion>
      </div>
    </SideNavContent>
  );
}
