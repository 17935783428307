import styles from "./CodeEditorAndResults.module.scss";
import { useCallback, useEffect, useState, useRef } from "react";
import { useDebouncedCallback } from "use-debounce";
import { patchQueryVersion } from "api/http/assets/queries-service";
import { WindowHeader } from "components/common/WindowHeader/WindowHeader";
import { useAssetsData } from "hooks/useAssetsData";
import { Button } from "components/common/Button/Button";
import { EmptyState } from "components/common/EmptyState/EmptyState";
import CodeEditor from "components/common/CodeEditor/CodeEditor";
import {
  SAVING_STATUS,
  SavingStatusIndicator,
} from "components/common/SavingStatusIndicator/SavingStatusIndicator";
import { useAssetsState, useToastsState } from "store";
import { ASSET_PERMISSION } from "pages/Assets/config";
import type { QueryVersionType } from "../../../types/queryTypes";

export default function CodeEditorAndResults() {
  const addToast = useToastsState((slice) => slice.addToast);
  const { getCurrentAssetItemsData } = useAssetsData();
  const { activeItemVersionId, activeVersionData: activeVersionStoreData } =
    getCurrentAssetItemsData("query");
  const { updateQueryVersionData: updateQueryVersionDataInStore } =
    useAssetsState((slice) => slice.queriesActions);

  const activeItemVersionIdRef = useRef(activeItemVersionId);
  const idleTimeout = useRef<NodeJS.Timeout | null>(null);

  const [savingStatusMap, setSavingStatusMap] = useState<
    Map<
      QueryVersionType["id"],
      {
        status: SAVING_STATUS;
        timestamp: number;
      }
    >
  >(new Map());

  const activeVersionData = activeVersionStoreData as QueryVersionType | null;

  // duplicate activeItemVersionId to ref
  useEffect(() => {
    activeItemVersionIdRef.current = activeItemVersionId;
  }, [activeItemVersionId]);

  const updateSavingStatus = useCallback(
    ({
      versionId,
      status,
      timestamp,
    }: {
      versionId: QueryVersionType["id"];
      status: SAVING_STATUS;
      timestamp: number;
    }) => {
      setSavingStatusMap((prevState) => {
        // ignore status change if it's outdated
        const currentStatus = prevState.get(versionId);
        if (currentStatus && currentStatus.timestamp > timestamp) {
          return prevState;
        }

        // clear previous idleTimeout if any
        if (idleTimeout.current) {
          clearTimeout(idleTimeout.current);
          idleTimeout.current = null;
        }

        // if "saved", reset status to "idle" after 3 seconds
        if (status === SAVING_STATUS.COMPLETED) {
          idleTimeout.current = setTimeout(() => {
            updateSavingStatus({
              versionId,
              status: SAVING_STATUS.IDLE,
              timestamp,
            });
          }, 3000);
        }

        // save new status, timestamp, and timeout
        const newState = new Map(prevState);
        newState.set(versionId, { status, timestamp });
        return newState;
      });
    },
    []
  );

  const debouncedPatchQueryVersion = useDebouncedCallback(
    useCallback(
      (value: string, versionId: QueryVersionType["id"]) => {
        const requestStartTimestamp = Date.now();

        patchQueryVersion(versionId, { id: versionId, code: value })
          .then(() => {
            if (activeItemVersionIdRef.current === versionId) {
              // show "✓ Saved" status
              updateSavingStatus({
                versionId,
                status: SAVING_STATUS.COMPLETED,
                timestamp: requestStartTimestamp,
              });
            } else {
              // clear status if version is not active anymore
              updateSavingStatus({
                versionId,
                status: SAVING_STATUS.IDLE,
                timestamp: requestStartTimestamp,
              });
            }
          })
          .catch((error) => {
            // clear status
            updateSavingStatus({
              versionId,
              status: SAVING_STATUS.FAILED,
              timestamp: requestStartTimestamp,
            });

            addToast({
              variant: "error",
              message: error?.message || "Failed to update version code",
            });
          });
      },
      [updateSavingStatus]
    ),
    3000
  );
  // patch version immediatelly after active version change or unmount
  useEffect(() => {
    return () => {
      debouncedPatchQueryVersion.flush();
    };
  }, [debouncedPatchQueryVersion, activeItemVersionId]);

  // clear COMPLETED statuses and idleTimeout on active version change
  useEffect(() => {
    setSavingStatusMap((prevState) => {
      const newState = new Map(prevState);
      for (const [versionId, status] of prevState.entries()) {
        if (status.status === SAVING_STATUS.COMPLETED) {
          newState.delete(versionId);
        }
      }
      return newState;
    });

    if (idleTimeout.current) {
      clearTimeout(idleTimeout.current);
    }
  }, [activeItemVersionId]);

  const handleOnCodeChange = useCallback(
    (value?: string) => {
      if (
        !activeItemVersionId ||
        !activeVersionData?.asset_id ||
        activeVersionData?.version === undefined
      ) {
        return;
      }

      // immediately reflect changes in store
      updateQueryVersionDataInStore(
        activeVersionData.asset_id,
        activeVersionData.version,
        { code: value ?? "" }
      );

      // show "Saving..." status
      updateSavingStatus({
        versionId: activeItemVersionId,
        status: SAVING_STATUS.PENDING,
        timestamp: Date.now(),
      });

      // debounce PATCH request
      debouncedPatchQueryVersion(value ?? "", activeItemVersionId);
    },
    [
      activeItemVersionId,
      debouncedPatchQueryVersion,
      activeVersionData?.asset_id,
      activeVersionData?.version,
    ]
  );

  const isEditor = activeVersionData?.permission === ASSET_PERMISSION.write;

  const readOnly =
    activeItemVersionId === null || activeVersionData?.archive || !isEditor;

  return (
    <div className={styles.codeAndResultsWrapper}>
      <div className={styles.codeSectionContainer}>
        <WindowHeader contentClassName={styles.codeSectionHeaderContent}>
          <div>Code Editor</div>
          {activeItemVersionId && (
            <SavingStatusIndicator
              className={styles.savingStatusIndicator}
              status={savingStatusMap.get(activeItemVersionId)?.status}
            />
          )}
        </WindowHeader>
        <div className={styles.codeBlockWrapper}>
          <CodeEditor
            language="sql"
            placeholderText="# -- Enter your query here..."
            value={activeVersionData?.code || ""}
            onChange={handleOnCodeChange}
            readOnly={readOnly}
            folding={false}
            lineNumbers
            renderLineHighlight={false}
          />
        </div>
        <div className={styles.runCodeButtonContainer}>
          <Button disabled>Run Сode</Button>
        </div>
      </div>
      <div className={styles.resultsSectionContainer}>
        <WindowHeader>Result Viewer</WindowHeader>
        <div className={styles.resultsSection}>
          <EmptyState
            variant="info"
            title="No connection selected..."
            description="To see database maps you need to select a connection in the selector above."
          />
        </div>
      </div>
    </div>
  );
}
