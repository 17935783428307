import { type FC } from "react";
import {
  useTimezoneSelect,
  allTimezones,
  type ITimezoneOption,
} from "react-timezone-select";
import FormDropdown, {
  type FormDropdownProps,
} from "../FormDropdown/FormDropdown";
import styles from "./TimeZoneDropdown.module.scss";

interface TimeZoneDropdownProps
  extends Omit<FormDropdownProps, "options" | "onChange"> {
  useDefaultUserTimeZone?: boolean;
  onTimezoneChange: (newTimezone: string) => void;
}
const TimeZoneDropdown: FC<TimeZoneDropdownProps> = ({
  value,
  useDefaultUserTimeZone = false,
  onTimezoneChange,
  ...rest
}: TimeZoneDropdownProps) => {
  const { options } = useTimezoneSelect({
    labelStyle: "original",
    timezones: allTimezones,
  });

  const handleSetTimezone = (value: ITimezoneOption["value"]) => {
    onTimezoneChange(value);
  };

  const dropdownValue =
    value || !useDefaultUserTimeZone
      ? value
      : value ?? Intl.DateTimeFormat().resolvedOptions().timeZone;

  return (
    <div className={styles.wrapper}>
      <FormDropdown
        autocomplete
        options={options}
        value={dropdownValue}
        onChange={handleSetTimezone}
        {...rest}
      />
    </div>
  );
};

export default TimeZoneDropdown;
