import type { ConnectionItemType } from "pages/Assets/types/connectionTypes";

export const USAGE_TYPE = Object.freeze({
  all: "all",
  canvases: "canvases",
  queries: "queries",
});

// review dataItemUsageIdPropName when data is provided
export const USAGE_TYPE_CONFIG = Object.freeze({
  [USAGE_TYPE.all]: {
    name: "All",
    dataItemUsageIdPropName: "id",
  },
  [USAGE_TYPE.canvases]: {
    name: "Canvas",
    dataItemUsageIdPropName: "canvas_id",
  },
  [USAGE_TYPE.queries]: {
    name: "Query",
    dataItemUsageIdPropName: "query_id",
  },
});

export const DATA_SPECIFIC_USAGE_TYPES = Object.freeze([
  USAGE_TYPE.canvases,
  USAGE_TYPE.queries,
]);

export const USAGE_TYPE_ALL_OPTION = Object.freeze({
  id: USAGE_TYPE.all,
  label: USAGE_TYPE_CONFIG[USAGE_TYPE.all].name,
  value: USAGE_TYPE.all,
});

export const USAGE_TYPES_OPTIONS = Object.freeze([
  USAGE_TYPE_ALL_OPTION,
  {
    id: USAGE_TYPE.canvases,
    label: USAGE_TYPE_CONFIG[USAGE_TYPE.canvases].name,
    value: USAGE_TYPE.canvases,
  },
  {
    id: USAGE_TYPE.queries,
    label: USAGE_TYPE_CONFIG[USAGE_TYPE.queries].name,
    value: USAGE_TYPE.queries,
  },
]);

export const LABEL = {
  assetsDropdown: "Connection",
  assetOwner: "Asset Owner",
  typesDropdown: "Type",
  open: "Open",
  remove: "Remove",
  unknown: "Unknown",
};

export const TEXT = {
  manageUsageDescription:
    "You can check and visit canvas or query which is using this connection. Only canvases and queries that you have access to are included in this list.",
  notFoundTitle: "Connection is not used...",
  notFoundSearchTitle: "No canvas or query found...",
  notFoundText:
    "You are currently not using the selected connection in canvases or \n queries. To start using it, assign it to a canvas or query.",
  notFoundSearchText: `We're sorry, but we couldn't find any canvas or query \n that match the name you entered.`,
  noCanvasFoundTitle: "No canvas found...",
  noCanvasFoundText:
    "You are currently not using the selected connection in canvases. \n To start using it, assign it to a canvas.",
  noCanvasFoundSearchText: `We're sorry, but we couldn't find any canvas \n that match the name you entered.`,
  noQueryFoundTitle: "No query found...",
  noQueryFoundText:
    "You are currently not using the selected connection in queries. \n To start using it, assign it to a query.",
  noQueryFoundSearchText: `We're sorry, but we couldn't find any query \n that match the name you entered.`,
};

export const getAssetOption = (assetData: ConnectionItemType) => {
  if (!assetData) {
    return;
  }

  const { id, name } = assetData.connection;

  return { id, label: name };
};

export const getUsageLink = (id: string, usageType: string) => {
  switch (usageType) {
    case USAGE_TYPE.canvases:
      return `/canvas/${id}`;
    case USAGE_TYPE.queries:
      return `/assets/queries/${id}`;
    default:
      return null;
  }
};
