import styles from "./AssetItemSkeleton.module.scss";
import { memo } from "react";
import { Skeleton } from "components/common/Skeleton/Skeleton";

export type AssetItemSkeletonProps = {
  nameWidth?: number;
};

export const AssetItemSkeleton = memo(function FileRowSkeleton({
  nameWidth = 95,
}: AssetItemSkeletonProps) {
  return (
    <div className={styles.container}>
      <Skeleton className={styles.icon} />
      <Skeleton className={styles.name} width={`${nameWidth}px`} />
    </div>
  );
});
