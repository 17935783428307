import styles from "./UserSettings.module.scss";
import { Outlet } from "react-router-dom";
import { UserSettingsSideMenu } from "components/Settings";

export function UserSettings() {
  return (
    <div className={styles.container}>
      <aside className={styles.sideNav}>
        <UserSettingsSideMenu />
      </aside>

      <main className={styles.tabWrapper}>
        <div className={styles.tabContent}>
          <Outlet />
        </div>
      </main>
    </div>
  );
}
