import styles from "./RootCrumbSkeleton.module.scss";
import { Skeleton } from "components/common/Skeleton/Skeleton";

export function RootCrumbSkeleton() {
  return (
    <div className={styles.contextCrumbSkeleton}>
      <Skeleton className={styles.avatar} />
      <Skeleton className={styles.name} />
    </div>
  );
}
