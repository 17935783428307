import { type StateCreator } from "store";
import { type BedrockModel } from "models/canvas";

type State = {
  bedrockModels?: BedrockModel[];
  bedrockOnDemandModels?: BedrockModel[];
  bedrockFineTuningModels?: BedrockModel[];
  isBedrockLoading: boolean;
};

type Actions = {
  setBedrockModels: (bedrockModels: BedrockModel[]) => void;
  setIsBedrockLoading: (isBedrockLoading: boolean) => void;
};

const filterOnDemandModels = (bedrockModels: BedrockModel[]) => {
  return bedrockModels.filter((model) => model.modelType === "on_demand");
};

const filterFineTuningModels = (bedrockModels: BedrockModel[]) => {
  return bedrockModels.filter((model) => model.modelType === "fine_tuning");
};

const initialState: State = {
  bedrockModels: undefined,
  isBedrockLoading: false,
  bedrockFineTuningModels: undefined,
  bedrockOnDemandModels: undefined,
};

export type ModelSlice = State & Actions;

export const createModelSlice: StateCreator<ModelSlice> = (set) => ({
  ...initialState,
  setBedrockModels: (bedrockModels) => {
    set(
      (store) => {
        store.modelSlice.bedrockModels = bedrockModels;
        store.modelSlice.bedrockFineTuningModels =
          filterFineTuningModels(bedrockModels);
        store.modelSlice.bedrockOnDemandModels =
          filterOnDemandModels(bedrockModels);
      },
      false,
      "modelSlice/setBedrockModels"
    );
  },
  setIsBedrockLoading: (isBedrockLoading) => {
    set(
      (store) => {
        store.modelSlice.isBedrockLoading = isBedrockLoading;
      },
      false,
      "modelSlice/setIsBedrockLoading"
    );
  },
});
