import {
  forwardRef,
  useCallback,
  useMemo,
  useState,
  type ReactNode,
  type FocusEvent,
} from "react";
import cn from "classnames";
import { BiShow, BiHide, BiReset } from "react-icons/bi";
import Tooltip from "components/common/Tooltip/Tooltip";
import TextField, {
  type TextFieldProps,
} from "components/common/TextField/TextField";
import styles from "./SecuredField.module.scss";

const DUMMY_TEXT_DEFAULT = "*".repeat(10);

interface SecuredFieldProps
  extends Omit<TextFieldProps, "type" | "innerControls" | "placeholder"> {
  showDummyTextIfEmptyValue?: boolean;
  dummyText?: string;
  onReset?: () => void;
}

const SecuredField = forwardRef<HTMLInputElement, SecuredFieldProps>(
  (props, ref) => {
    const {
      inputClassName,
      value,
      showDummyTextIfEmptyValue = false,
      dummyText = DUMMY_TEXT_DEFAULT,
      isDisabled,
      onFocus,
      onBlur,
      onReset,
      ...rest
    } = props;
    const [isPasswordShown, setIsPasswordShown] = useState(false);
    const [isInputFocused, setIsInputFocused] = useState(false);

    const handleResetValue = useCallback(() => {
      setIsPasswordShown(false);
      onReset?.();
    }, [onReset]);

    const handleBlur = useCallback(
      (e: FocusEvent<HTMLInputElement>) => {
        setIsInputFocused(false);
        onBlur?.(e);
      },
      [onBlur]
    );

    const handleFocus = useCallback(
      (e: FocusEvent<HTMLInputElement>) => {
        setIsInputFocused(true);
        onFocus?.(e);
      },
      [onFocus]
    );

    const isDummyTextShown =
      !isInputFocused && showDummyTextIfEmptyValue && !value;
    const showResetIcon = !isDummyTextShown && onReset;
    const showPasswordIcon = !showDummyTextIfEmptyValue || value;

    const innerControls = useMemo(() => {
      const controls: ReactNode[] = [];

      if (showPasswordIcon) {
        controls.push(
          <Tooltip
            key="paasword_control"
            placement="top"
            text={isPasswordShown ? "Hide value" : "Show value"}
            withArrow
          >
            <button
              type="button"
              disabled={isDisabled}
              className={styles.control}
              onMouseDown={() => {
                setIsPasswordShown((prev) => !prev);
              }}
            >
              {isPasswordShown ? <BiHide size={20} /> : <BiShow size={20} />}
            </button>
          </Tooltip>
        );
      }
      if (showResetIcon) {
        controls.push(
          <Tooltip
            key="reset_control"
            placement="top"
            text="Reset value"
            withArrow
          >
            <button
              type="button"
              className={styles.control}
              disabled={isDisabled}
              onMouseDown={handleResetValue}
            >
              <BiReset size={20} />
            </button>
          </Tooltip>
        );
      }

      return controls;
    }, [
      showResetIcon,
      showPasswordIcon,
      isPasswordShown,
      isDisabled,
      handleResetValue,
    ]);

    const renderInnerControls = () => {
      if (!innerControls.length) {
        return null;
      }

      return (
        <div className={styles.inner_controls_container}>{innerControls}</div>
      );
    };

    return (
      <TextField
        {...rest}
        placeholder={
          showDummyTextIfEmptyValue ? "Enter new value..." : "Enter value..."
        }
        type={isPasswordShown ? "text" : "password"}
        ref={ref}
        inputClassName={cn(
          inputClassName,
          styles[`input_has_controls_count_${innerControls.length}`]
        )}
        value={isDummyTextShown ? dummyText : value}
        innerControls={renderInnerControls()}
        isDisabled={isDisabled}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    );
  }
);

SecuredField.displayName = "SecuredField";

export default SecuredField;
