import styles from "./AssetsPages.module.scss";
import { useMemo, useCallback } from "react";
import { useLocation, Outlet, useParams } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
  TabsButtons,
  type TabButton,
} from "components/common/TabsButtons/TabsButtons";
import AssetListItemIcon from "components/menus/AssetsMenu/components/AssetListItemIcon";
import { MenuOptions } from "components/menus/AssetsMenu/types";
import { AssetsDataProvider } from "context/AssetsData";

enum TAB_KEY {
  FUNCTIONS = "functions-and-classes",
  CONSTANTS = "constants-and-secrets",
  CONNECTIONS = "connections",
  QUERIES = "queries",
}

export function AssetsPages() {
  const flags = useFlags();
  const location = useLocation();
  const urlComponents = location.pathname.split("/");
  const { workspaceID, orgID } = useParams();

  const assetsTabUrl = useMemo(() => {
    return workspaceID ? urlComponents[6] : urlComponents[2];
  }, [urlComponents, workspaceID]);

  const tabLink = useCallback(
    (tab: string) => {
      if (orgID && workspaceID) {
        return `/org/${orgID}/workspaces/${workspaceID}/assets/${tab}`;
      } else {
        return `/assets/${tab}`;
      }
    },
    [orgID, workspaceID]
  );

  const activeTab = useMemo(() => {
    switch (assetsTabUrl) {
      case "functions":
        return TAB_KEY.FUNCTIONS;
      case "constants":
        return TAB_KEY.CONSTANTS;
      case "connections":
        return TAB_KEY.CONNECTIONS;
      case "queries":
        return TAB_KEY.QUERIES;
      default:
        return null;
    }
  }, [assetsTabUrl]);

  const tabs = useMemo(() => {
    const options: TabButton<TAB_KEY>[] = [
      {
        key: TAB_KEY.FUNCTIONS,
        label: (
          <>
            <AssetListItemIcon
              assetType={MenuOptions.Function}
              isActive={activeTab === TAB_KEY.FUNCTIONS}
            />
            <span>Functions & Classes</span>
          </>
        ),
        to: tabLink("functions"),
      },
      {
        key: TAB_KEY.CONSTANTS,
        label: (
          <>
            <AssetListItemIcon
              assetType={MenuOptions.Constant}
              isActive={activeTab === TAB_KEY.CONSTANTS}
            />
            <span>Constants & Secrets</span>
          </>
        ),
        to: tabLink("constants"),
      },
      {
        key: TAB_KEY.CONNECTIONS,
        label: (
          <>
            <AssetListItemIcon
              assetType={MenuOptions.Connections}
              isActive={activeTab === TAB_KEY.CONNECTIONS}
            />
            <span>Connections</span>
          </>
        ),
        to: tabLink("connections"),
      },
    ];

    if (flags.enableQueryAssets) {
      options.push({
        key: TAB_KEY.QUERIES,
        label: (
          <>
            <AssetListItemIcon
              assetType={MenuOptions.Query}
              isActive={activeTab === TAB_KEY.QUERIES}
            />
            <span>Queries</span>
          </>
        ),
        to: tabLink("queries"),
      });
    }

    return options;
  }, [flags, activeTab]);

  return (
    <AssetsDataProvider>
      <TabsButtons activeTab={activeTab} tabs={tabs} className={styles.tabs} />
      <Outlet />
    </AssetsDataProvider>
  );
}
