import { patchQueryVersion } from "api/http/assets/queries-service";
import { useAssetsState, useUserState } from "store";
import type { QueryVersionType } from "pages/Assets/types/queryTypes";

export function useQueryData() {
  const userID = useUserState((slice) => slice.userID);
  const { queriesData, selectedQuery } = useAssetsState(
    (slice) => slice.queries
  );
  const { updateQueryVersion } = useAssetsState(
    (slice) => slice.queriesActions
  );

  const updateQueryVersionData = <K extends keyof QueryVersionType>(
    fieldName: K,
    fieldData: QueryVersionType[K],
    versionId?: string
  ) => {
    let selectedQueryVersionId = versionId ?? "";
    if (
      selectedQuery.id !== null &&
      selectedQuery.versionNumber !== null &&
      !versionId
    ) {
      selectedQueryVersionId =
        queriesData[selectedQuery.id].versions[selectedQuery.versionNumber].id;
    }

    const requestData = {
      id: selectedQueryVersionId,
      [fieldName]: fieldData,
    };

    if (userID) {
      patchQueryVersion(selectedQueryVersionId, requestData).then(
        (updatedVersion) => {
          updateQueryVersion(updatedVersion);
        }
      );
    }
  };

  return { updateQueryVersionData };
}
