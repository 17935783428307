/* 
  LaunchDarkly config 
  https://app.launchdarkly.com/default/test/features
*/
import { type ProviderConfig } from "launchdarkly-react-client-sdk";

export const launchDarklyConfig: ProviderConfig = {
  clientSideID: import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID || "",
  options: {
    bootstrap: "localStorage",
  },
  reactOptions: {
    useCamelCaseFlagKeys: false, // do not transform flag keys to avoid collisions and confusion
    sendEventsOnFlagRead: import.meta.env.PROD,
  },
  deferInitialization: true,
};
