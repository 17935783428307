import { useMemo, useEffect, useState } from "react";
import { useNavigate, useParams, Outlet } from "react-router-dom";
import {
  BiCollection,
  BiCloud,
  BiUserPlus,
  BiGroup,
  BiCrown,
  BiCog,
} from "react-icons/bi";
import { getOrganization } from "api/http/organization-service";
import { useToastsState } from "store";
import { NavigationTabs } from "components/common/NavigationTabs/NavigationTabs";
import { type NavigationTabsItem } from "components/common/NavigationTabs/NavigationTabs";
import type { Organization } from "models/organization";

export function OrganizationTabs() {
  const navigate = useNavigate();
  const { orgID = "" } = useParams();
  const addToast = useToastsState((slice) => slice.addToast);

  const [organization, setOrganization] = useState<Organization>();

  // Fetch organization when the component loads
  useEffect(() => {
    async function initializeOrganization() {
      try {
        const organizationResult = await getOrganization(orgID);
        setOrganization(organizationResult);
      } catch (error) {
        addToast({
          message: "Could not find organization.",
          variant: "error",
        });
        // if we can't load the organization, navigate to home.
        navigate("/");
      }
    }
    if (orgID) {
      initializeOrganization();
    }
  }, [orgID]);

  const isAdmin = useMemo(() => {
    return !!organization && organization.role === "Organization Admin"; // TODO: use a proper key.
  }, [organization]);

  const tabs = useMemo(() => {
    const list: NavigationTabsItem[] = [];

    if (!organization) {
      return list;
    }

    list.push({
      key: "workspaces",
      label: (
        <>
          <BiCollection size={20} />
          <span>Workspaces</span>
        </>
      ),
      to: `/org/${organization.id}/workspaces`,
    });

    if (isAdmin) {
      list.push({
        key: "self-hosting",
        label: (
          <>
            <BiCloud size={20} />
            <span>Self-Hosting</span>
          </>
        ),
        to: `/org/${organization.id}/self_hosting`,
      });
    }

    list.push(
      {
        key: "people",
        label: (
          <>
            <BiUserPlus size={20} />
            <span>People</span>
          </>
        ),
        to: `/org/${organization.id}/people`,
      },
      {
        key: "Groups",
        label: (
          <>
            <BiGroup size={20} />
            <span>Groups</span>
          </>
        ),
        to: `/org/${organization.id}/groups`,
      }
    );

    if (isAdmin) {
      list.push(
        {
          key: "custom-roles",
          label: (
            <>
              <BiCrown size={20} />
              <span>Custom Roles</span>
            </>
          ),
          to: `/org/${organization.id}/roles`,
        },
        {
          key: "settings",
          label: (
            <>
              <BiCog size={20} />
              <span>Settings</span>
            </>
          ),
          to: `/org/${organization.id}/settings`,
        }
      );
    }
    return list;
  }, [organization, isAdmin]);

  return (
    <>
      <NavigationTabs orientation="horizontal" items={tabs} />
      <Outlet />
    </>
  );
}
