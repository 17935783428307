import { type ChangeEventHandler } from "react";
import cn from "classnames";
import { BiSearch } from "react-icons/bi";

import styles from "./Search.module.scss";

export type SideBarSearchProps = {
  value?: string;
  disabled?: boolean;
  onChange?: ChangeEventHandler;
};

export default function Search({
  value,
  disabled = false,
  onChange,
}: SideBarSearchProps) {
  return (
    <div className={styles["search"]}>
      <BiSearch
        className={cn(styles["search__icon"], {
          [styles["search__icon_disabled"]]: disabled,
        })}
        size={20}
      />
      <input
        defaultValue={value}
        onChange={onChange}
        className={styles["search__input"]}
        placeholder="Search..."
        disabled={disabled}
      />
    </div>
  );
}
