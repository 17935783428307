import { useCallback, useEffect, useMemo, useRef } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import canvasService from "api/http/canvas-service";
import { getBlockContent } from "api/http/blocks-service";
import {
  useCanvasState,
  useCanvasBlocksState,
  useCanvasGitHubState,
  useCanvasLayoutState,
  useCanvasUsersState,
} from "store";
import { useCanvasScheduledJobsActions } from "hooks/useCanvasScheduledJobsActions";
import { useSageMakerDeploymentActions } from "hooks/useSageMakerDeploymentActions";
import { useOrganizationsActions } from "hooks/useOrganizationsActions";
import { getLeftSidebarOptions } from "utils/canvas";
import {
  CANVAS_PERMISSION,
  LAYER_TYPE,
  BLOCK_ID_QUERY_PARAM,
  LAYER_ID_QUERY_PARAM,
  LEFT_SIDEBAR_QUERY_PARAM,
} from "config/canvasConfig";

export const useCanvasLayoutSetup = () => {
  const flags = useFlags();

  const flagsRef = useRef(flags);

  const {
    getIsLayoutInitialized,
    setCanAccessCanvas,
    setIsEditor,
    setIsLayoutLoading,
    setAll,
    setIsLayoutInitialized,
  } = useCanvasState((slice) => ({
    getIsLayoutInitialized: slice.getIsLayoutInitialized,
    setCanAccessCanvas: slice.setCanAccessCanvas,
    setIsEditor: slice.setIsEditor,
    setIsLayoutLoading: slice.setIsLayoutLoading,
    setAll: slice.setAll,
    setIsLayoutInitialized: slice.setIsLayoutInitialized,
  }));

  const { setBlocksContent, setBlocksData } = useCanvasBlocksState((slice) => ({
    setBlocksContent: slice.setBlocksContent,
    setBlocksData: slice.setBlocksData,
  }));

  const setInitialLeftSidbar = useCanvasLayoutState(
    (slice) => slice.setInitialLeftSidbar
  );

  const setConnectedUsers = useCanvasUsersState(
    (slice) => slice.setConnectedUsers
  );

  const { getScheduledJobByLayerId } = useCanvasScheduledJobsActions();

  const { fetchDeployment } = useSageMakerDeploymentActions();

  const { fetchCloudCredentials } = useOrganizationsActions();

  const { setCanvasBranch, setIncludedRepositories } = useCanvasGitHubState(
    (slice) => ({
      setCanvasBranch: slice.setCanvasBranch,
      setIncludedRepositories: slice.setIncludedRepositories,
    })
  );

  // update flags
  useEffect(() => {
    flagsRef.current = flags;
  }, [flags]);

  // fetch layout and block content
  const setupCanvasLayout = useCallback(
    async ({ canvasId }: { canvasId: string }) => {
      const isInitialSetup = !getIsLayoutInitialized();

      setIsLayoutLoading(true);
      isInitialSetup && setCanAccessCanvas(null);
      canvasService
        .getCanvasLayout(canvasId)
        .then((response) => {
          const { canvas, permission, connected_users, ...layout } = response;

          setIsEditor(permission === CANVAS_PERMISSION.WRITE);
          setCanAccessCanvas(true);

          // get block content for each block in the layout
          const promises = Object.keys(layout).map((layer) => {
            const blockPromises = layout[layer].blocks.map((block) => {
              return getBlockContent(block.id).then((res) => {
                block.content = res.block_content;
                return block;
              });
            });
            return Promise.all(blockPromises).then((blocks) => {
              layout[layer].blocks = blocks;
              return { [layer]: layout[layer] };
            });
          });

          return Promise.all(promises).then((layoutWithContent) => {
            // convert layoutWithContent from an array of objects to an object
            // with the layers as keys
            const updatedLayout = layoutWithContent.reduce((acc, curr) => {
              const layer = Object.keys(curr)[0];
              acc[layer] = curr[layer];
              return acc;
            }, {});

            const { blocksData, blocksContent } = Object.values(updatedLayout)
              .map((l) => l.blocks)
              .flat()
              .reduce<{
                blocksData: Record<string, any>;
                blocksContent: Record<string, string>;
              }>(
                (acc, block) => {
                  acc.blocksContent[block.id] = block.content;
                  acc.blocksData[block.id] = block;
                  return acc;
                },
                { blocksData: {}, blocksContent: {} }
              );

            // get layer_id/block_id/sidebar from query params
            const searchParams = new URLSearchParams(window.location.search);
            const initialLayerId = searchParams.get(LAYER_ID_QUERY_PARAM);
            const initialBlockId = searchParams.get(BLOCK_ID_QUERY_PARAM);
            const initialLeftSidebar = searchParams.get(
              LEFT_SIDEBAR_QUERY_PARAM
            );

            setAll({
              name: canvas.name,
              canvasId,
              projectId: canvas.project_id,
              workspaceId: canvas.workspace_id || "",
              organizationId: canvas.organization_id || "",
              layers: canvas.layers,
              layout: updatedLayout,
              initialLayerId,
              initialBlockId,
              permission,
            });

            setConnectedUsers(connected_users);

            setCanvasBranch(canvas.canvas_branch || null);
            setIncludedRepositories(canvas.included_repos || []);
            setBlocksContent(blocksContent);

            setBlocksData(blocksData);

            setInitialLeftSidbar(
              initialLeftSidebar,
              getLeftSidebarOptions({
                canvasPermission: permission,
                featureFlags: flagsRef.current,
              })
            );

            // fetch layers data
            canvas.layers.forEach((layer) => {
              // fetch scheduled jobs data
              if (layer.type === LAYER_TYPE.SCHEDULED_JOBS) {
                getScheduledJobByLayerId(layer.id);
              }

              // fetch SageMaker deployments data
              if (layer.type === LAYER_TYPE.SAGEMAKER) {
                fetchDeployment({
                  canvasId,
                  deploymentId: layer.deployment_id,
                  timeout: 0, // do not debounce the promise
                });
              }
            });

            // fetch cloud credentials if canvas belongs to an organization;
            // credentials are needed for deployment addresses.
            if (canvas.organization_id) {
              fetchCloudCredentials(canvas.organization_id);
            }

            setIsLayoutLoading(false);
            setIsLayoutInitialized(true);
          });
        })
        .catch(() => {
          setCanAccessCanvas(false);
          setIsLayoutLoading(false);
        });
    },
    []
  );

  return useMemo(
    () => ({
      setupCanvasLayout,
    }),
    [setupCanvasLayout]
  );
};
