import styles from "./AssetsNavItemDropdown.module.scss";
import { type FC, type ReactNode, useMemo } from "react";
import { type IconType } from "react-icons";
import { useNavigate, useParams } from "react-router-dom";
import {
  BiArchiveIn,
  BiDotsHorizontal,
  BiInfoCircle,
  BiLayerPlus,
  BiTrash,
} from "react-icons/bi";
import { useUserState } from "store";
import { useModal } from "hooks/useModal";
import { useConfirmModal } from "hooks/useConfirmModal";
import { ManageUsageModal } from "components/modals";
import { DropdownMenuButton } from "components/common/DropdownMenuButton/DropdownMenuButton";
import Typography from "components/Typography/Typography";
import { getEnabledContextMenuOptions } from "utils/helpers";
import {
  type AssetPageTabType,
  AssetPageTabUrl,
} from "../../types/abstractTypes";
import { useAssetsData } from "hooks/useAssetsData";
import { LABEL, MESSAGE } from "pages/Assets/config";

export const createCustomLabel = (text: string, icon: IconType): ReactNode => {
  const Icon = icon;
  return (
    <div className={styles.customLabel}>
      <Icon size={20} className={styles.labelIcon} />
      <Typography variant="caption1" className={styles.text}>
        {text}
      </Typography>
    </div>
  );
};

interface AssetsNavItemDropdownProps {
  assetType: AssetPageTabType;
  assetId: string;
}

const AssetsNavItemDropdown: FC<AssetsNavItemDropdownProps> = ({
  assetType,
  assetId,
}: AssetsNavItemDropdownProps) => {
  const navigate = useNavigate();
  const userID = useUserState((slice) => slice.userID);
  const { orgID, workspaceID } = useParams();
  const { openModal } = useModal();
  const { openConfirmModal } = useConfirmModal();
  const {
    getCurrentAssetItemActions,
    getCreatedAssetItemActions,
    getCurrentAssetItemsData,
  } = useAssetsData();
  const { deleteAssetItem, setActiveVersionNumber, setAssetIsArchived } =
    getCurrentAssetItemActions(assetType);
  const { setAssetItemOperationType, setCreatedAssetItemData, addNewVersion } =
    getCreatedAssetItemActions(assetType);
  const { activeItemData, activeItemId } = getCurrentAssetItemsData(assetType);

  const baseURL = useMemo(() => {
    if (orgID && workspaceID) {
      return `/org/${orgID}/workspaces/${workspaceID}/assets`;
    }
    return `/assets`;
  }, [orgID, workspaceID]);

  const handleAddNewConstantVersion = (): void => {
    if (activeItemData) {
      const versionsKeys = Object.keys(activeItemData.versions);
      const versionsArray = versionsKeys.map(
        (key) => activeItemData.versions[key]
      );
      const lastVersion = Math.max(
        ...versionsArray.map((version) => version.version)
      );
      const lastVersionNumber = versionsArray.find(
        (version) => version.version === lastVersion
      )?.version;

      setCreatedAssetItemData({
        name: "",
        description: "",
        value: "",
        secret: false,
      });

      setAssetItemOperationType("newVersion");
      if (lastVersionNumber) {
        setActiveVersionNumber(lastVersionNumber);
      }
    }
  };

  const handleAddNewVersion = (): void => {
    if (assetType === "constant") {
      handleAddNewConstantVersion();
    } else {
      addNewVersion();
    }
  };

  const handleDelete = async () => {
    const confirmed = await openConfirmModal({
      title: MESSAGE.deleteAsset,
      message: MESSAGE.assetDeleteConfirm,
      confirmButtonLabel: LABEL.deleteConfirmButton,
      confirmButtonVariant: "crucial",
    });

    if (confirmed) {
      navigate(`${baseURL}/${AssetPageTabUrl[assetType]}`, { replace: true });
      deleteAssetItem();
    }
  };

  const handleConfirmArchive = async () => {
    if (!activeItemId) {
      return;
    }
    const confirmed = await openConfirmModal({
      message: MESSAGE.assetArchiveConfirm,
      confirmButtonLabel: LABEL.archiveConfirmButton,
    });

    if (confirmed) {
      setAssetIsArchived(activeItemId, true);
    }
  };

  const { assetOwnerName, isAssetOwner } = useMemo(() => {
    const res = { assetOwnerName: "", isAssetOwner: false };
    if (!activeItemData) {
      return res;
    }

    const assetOwnerData = activeItemData?.[assetType]?.owner;

    res.assetOwnerName =
      assetOwnerData?.username || assetOwnerData?.email || "";
    res.isAssetOwner =
      !!userID && !!assetOwnerData?.id && userID === assetOwnerData?.id;

    return res;
  }, [activeItemData, assetType, userID]);
  // we will need assetType prop to get appropriate actions for each asset type
  const menuOptions = [
    {
      key: "add-new-version",
      type: "option" as const,
      label: createCustomLabel("Add New Version", BiLayerPlus),
      onClick: handleAddNewVersion,
    },
    {
      key: "manage-usage",
      type: "option" as const,
      label: createCustomLabel("Manage Usage", BiInfoCircle),
      disabled: !isAssetOwner,
      onClick: () => {
        openModal({
          title: "Manage Usage",
          content: () => (
            <ManageUsageModal
              assetId={assetId}
              assetType={assetType}
              workspaceID={workspaceID}
            />
          ),
        });
      },
    },
    {
      key: "archive",
      type: "option" as const,
      disabled: !isAssetOwner,
      label: createCustomLabel("Archive", BiArchiveIn),
      showDivider: true,
      onClick: handleConfirmArchive,
    },
    {
      key: "delete",
      type: "option" as const,
      disabled: !isAssetOwner,
      label: createCustomLabel("Delete", BiTrash),
      onClick: handleDelete,
    },
    {
      key: "owner",
      type: "custom" as const,
      component: (
        <div className={styles.ownerRow} key={`asset-owner-${assetOwnerName}`}>
          <Typography variant="caption2" className={styles.title}>
            Asset Owner:
          </Typography>
          <Typography variant="caption1" className={styles.name}>
            {assetOwnerName}
          </Typography>
        </div>
      ),
    },
  ];

  const enabledMenuOptions = getEnabledContextMenuOptions(menuOptions);

  return (
    <DropdownMenuButton
      buttonVariant="text"
      hideChevron
      menuOptions={enabledMenuOptions}
      text={<BiDotsHorizontal />}
      nodesClassNames={{
        text: styles.textWrapper,
        contextMenu: styles.contextMenu,
        container: styles.dropdownContainer,
        button: styles.button,
      }}
      hideFloatingElWhenRefElIsHidden
      wrapContextMenuWithFloatingPortal
      contextMenuFitWidth
    />
  );
};

export default AssetsNavItemDropdown;
