import styles from "./Checkbox.module.scss";
import { type ReactNode, type ChangeEventHandler } from "react";
import cn from "classnames";

interface CheckboxProps {
  id?: string;
  name?: string;
  checked?: boolean;
  label?: ReactNode;
  className?: string;
  disabled?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

export const Checkbox = ({
  id,
  name,
  checked,
  onChange,
  label = "",
  className = "",
  disabled,
}: CheckboxProps) => (
  <label
    className={cn(styles.checkbox, className, {
      [styles.checkbox_disabled]: disabled,
    })}
  >
    <span
      className={cn(styles["checkbox-container"], {
        [styles.checked]: checked,
        [styles.disabled]: disabled,
      })}
    >
      <input
        id={id}
        name={name}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className={styles["checkbox-input"]}
        disabled={disabled}
      />
      <svg
        className={styles["checkmark"]}
        width="13"
        height="11"
        viewBox="0 0 13 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0753 1.99463L4.75857 10.2869L0.0390625 6.52083L1.16178 5.11388L4.54227 7.81144L10.7256 0.803711L12.0753 1.99463Z"
          fill="currentColor"
        />
      </svg>
    </span>

    {label ? <span>{label}</span> : null}
  </label>
);
