import styles from "./Loader.module.scss";
import { type ReactNode, type FC } from "react";
import { BiLoader } from "react-icons/bi";
import { type IconBaseProps } from "react-icons";
import cn from "classnames";

const ConditionalWrapper = ({ condition, wrapper, children }) => {
  if (condition) {
    return wrapper(children);
  }

  return children;
};

interface LoaderProps {
  size?: IconBaseProps["size"];
  withOverlay?: boolean;
  children?: ReactNode;
  classes?: {
    container?: string;
    overlay?: string;
  };
}

const Loader: FC<LoaderProps> = ({
  size = 24,
  withOverlay = false,
  children,
  classes = {},
}: LoaderProps) => {
  return (
    <ConditionalWrapper
      condition={withOverlay}
      wrapper={(children) => (
        <div className={cn(styles.overlay, classes.overlay)}>{children}</div>
      )}
    >
      <div className={cn(styles.loaderContainer, classes.container)}>
        <BiLoader className={styles.loaderIcon} size={size} />
      </div>
      {children}
    </ConditionalWrapper>
  );
};

export default Loader;
