import styles from "components/comments/Mention.module.scss";
import { useCallback, useMemo } from "react";
import cn from "classnames";
import {
  useToastsState,
  useUserState,
  useUserSettingsState,
  useCommentsState,
  useCanvasState,
} from "store";
import { useConfirmModal } from "hooks/useConfirmModal";
import * as commentsService from "api/http/comments-service";
import userService from "api/http/user-service";
import { getUserDisplayName, debouncePromise } from "utils/helpers";
import type { CommentType, ReplyType } from "models/comments";
import { CANVAS_PERMISSION } from "config/canvasConfig";

export function useComments() {
  const addToast = useToastsState((slice) => slice.addToast);
  const { openConfirmModal } = useConfirmModal();

  const userId = useUserState((slice) => slice.userID);
  const projectId = useCanvasState((slice) => slice.projectId);
  const permission = useCanvasState((slice) => slice.permission);
  const {
    setIsCommentsDataLoading,
    setCommentsErrorMessage,
    setComments,
    getCommentById,
    resolveCommentInStore,
    unresolveCommentInStore,
    deleteCommentFromStore,
    addCommentToStore,
    editCommentInStore,
    deleteReplyFromStore,
    addReplyToStore,
    editReplyInStore,
  } = useCommentsState((slice) => ({
    setIsCommentsDataLoading: slice.setIsCommentsDataLoading,
    setCommentsErrorMessage: slice.setCommentsErrorMessage,
    setComments: slice.setComments,
    getCommentById: slice.getCommentById,
    resolveCommentInStore: slice.resolveComment,
    unresolveCommentInStore: slice.unresolveComment,
    deleteCommentFromStore: slice.deleteComment,
    addCommentToStore: slice.addComment,
    editCommentInStore: slice.editComment,
    deleteReplyFromStore: slice.deleteReply,
    addReplyToStore: slice.addReply,
    editReplyInStore: slice.editReply,
  }));

  const { username, firstName, lastName, email } = useUserSettingsState(
    (slice) => slice.profile
  );

  // name to show for the current user
  const currentUserDisplayName = useMemo(() => {
    return getUserDisplayName({
      username,
      firstName,
      lastName,
      email,
    });
  }, [username, firstName, lastName, email]);

  // can the current user add a comment?
  const canAddComment = useMemo(() => {
    return (
      !!userId &&
      (permission === CANVAS_PERMISSION.READ ||
        permission === CANVAS_PERMISSION.WRITE)
    );
  }, [permission, userId]);

  // fetch all comments and store them in state
  const fetchCommentsData = useCallback(
    async ({ canvasId }: { canvasId: string }) => {
      setIsCommentsDataLoading(true);
      setCommentsErrorMessage(null);

      try {
        const comments = await debouncePromise({
          promise: commentsService.getCanvasComments({ canvasId }),
        });
        setComments(comments);
        setIsCommentsDataLoading(false);
      } catch (rejection: any) {
        const cause =
          typeof rejection?.cause?.detail === "string"
            ? rejection.cause.detail
            : "";
        const errorMessage = (
          <>
            {cause
              ? `Failed to load comments: "${cause}".`
              : "Failed to load comments."}
            <br />
            Try again later or contact support.
          </>
        );
        setCommentsErrorMessage(errorMessage);
        setIsCommentsDataLoading(false);
      }
    },
    []
  );

  // check what the current user can do with the comment
  const getCommentPermissions = useCallback(
    ({ commentId }: { commentId: CommentType["id"] }) => {
      const permissions = {
        canResolve: false,
        canDelete: false,
        canEdit: false,
        canReply: false,
      };

      const comment = getCommentById(commentId);
      if (comment) {
        permissions.canResolve = canAddComment || comment.user_id === userId;
        permissions.canDelete = comment.user_id === userId;
        permissions.canEdit = comment.user_id === userId;
        permissions.canReply = canAddComment;
      }

      return permissions;
    },
    [userId, canAddComment]
  );

  // check what the current user can do with the reply
  const getReplyPermissions = useCallback(
    ({
      commentId,
      replyId,
    }: {
      commentId: CommentType["id"];
      replyId: ReplyType["id"];
    }) => {
      const permissions = {
        canDelete: false,
        canEdit: false,
      };

      const reply = getCommentById(commentId)?.block_comment_replies.find(
        (reply) => reply.id === replyId
      );
      if (reply) {
        permissions.canDelete = reply.user_id === userId;
        permissions.canEdit = reply.user_id === userId;
      }

      return permissions;
    },
    [userId]
  );

  // add a new comment to a block
  const addComment = useCallback(
    async ({
      blockId,
      value,
    }: {
      blockId: CommentType["block_id"];
      value: CommentType["comment"];
    }) => {
      if (!userId || !value) {
        return;
      }
      try {
        const comment = await debouncePromise({
          promise: commentsService.postBlockComment({
            userId,
            blockId,
            comment: value,
          }),
        });
        addCommentToStore(comment);
        return comment;
      } catch (rejection) {
        addToast({
          variant: "error",
          message: "Failed to submit a comment.",
        });

        // pass rejection to the caller
        throw rejection;
      }
    },
    [userId]
  );

  // edit an existing comment
  const editComment = useCallback(
    async ({
      commentId,
      value,
    }: {
      commentId: CommentType["id"];
      value: CommentType["comment"];
    }) => {
      const comment = getCommentById(commentId);
      if (!comment) {
        return;
      }

      try {
        const editedComment = await debouncePromise({
          promise: commentsService.updateBlockComment({
            blockId: comment.block_id,
            blockCommentId: comment.id,
            updatedComment: value,
          }),
        });
        editCommentInStore(editedComment);
      } catch (rejection) {
        addToast({
          variant: "error",
          message: "Failed to edit a comment.",
        });

        // pass rejection to the caller
        throw rejection;
      }
    },
    [userId]
  );

  // mark comment as resolved
  const resolveComment = useCallback(
    async ({ commentId }: { commentId: CommentType["id"] }) => {
      const comment = getCommentById(commentId);
      if (!comment) {
        return;
      }

      // resolve comment right away and unresolve it if request fails
      resolveCommentInStore(commentId);

      try {
        await commentsService.resolveBlockComment({
          blockId: comment.block_id,
          blockCommentId: comment.id,
        });
      } catch (rejection) {
        unresolveCommentInStore(commentId);
        addToast({
          variant: "error",
          message: "Failed to mark comment as resolved.",
        });
      }
    },
    []
  );

  // mark comment as not resolved
  const unresolveComment = useCallback(
    async ({ commentId }: { commentId: CommentType["id"] }) => {
      const comment = getCommentById(commentId);
      if (!comment) {
        return;
      }

      // unresolve comment right away and unresolve it if request fails
      unresolveCommentInStore(commentId);

      try {
        await commentsService.unresolveBlockComment({
          blockId: comment.block_id,
          blockCommentId: comment.id,
        });
      } catch (rejection) {
        resolveCommentInStore(commentId);
        addToast({
          variant: "error",
          message: "Failed to unresolve comment.",
        });
      }
    },
    []
  );

  // delete a comment with all its replies
  const deleteComment = useCallback(
    async ({ commentId }: { commentId: CommentType["id"] }) => {
      const comment = getCommentById(commentId);
      if (!comment) {
        return;
      }

      const confirmed = await openConfirmModal({
        message: (
          <>
            {comment.block_comment_replies.length > 0
              ? "Are you sure you want to delete the comment with all the replies?"
              : "Are you sure you want to delete the comment?"}
            <br />
            This action cannot be undone.
          </>
        ),
        confirmButtonLabel: "Delete",
        confirmButtonVariant: "crucial",
      });

      if (!confirmed) {
        return;
      }

      // delete comment from state right away and put it back if request fails
      deleteCommentFromStore(commentId);

      try {
        await commentsService.deleteBlockComment({
          blockId: comment.block_id,
          blockCommentId: comment.id,
        });
      } catch (rejection) {
        addCommentToStore(comment);
        addToast({
          variant: "error",
          message: "Failed to delete comment.",
        });
      }
    },
    []
  );

  // delete a reply
  const deleteReply = useCallback(
    async ({
      commentId,
      replyId,
    }: {
      commentId: CommentType["id"];
      replyId: ReplyType["id"];
    }) => {
      const comment = getCommentById(commentId);
      const reply = comment?.block_comment_replies.find(
        (reply) => reply.id === replyId
      );
      if (!comment || !reply) {
        return;
      }

      const confirmed = await openConfirmModal({
        message: (
          <>
            Are you sure you want to delete the reply?
            <br />
            This action cannot be undone.
          </>
        ),
        confirmButtonLabel: "Delete",
        confirmButtonVariant: "crucial",
      });

      if (!confirmed) {
        return;
      }

      // delete reply from state right away and put it back if request fails
      deleteReplyFromStore({ commentId, replyId });

      try {
        await commentsService.deleteBlockCommentReply({
          blockId: comment.block_id,
          blockCommentId: comment.id,
          blockCommentReplyId: replyId,
        });
      } catch (rejection) {
        addReplyToStore(reply);
        addToast({
          variant: "error",
          message: "Failed to delete reply.",
        });
      }
    },
    []
  );

  // submit a reply
  const replyToComment = useCallback(
    async ({
      commentId,
      value,
    }: {
      commentId: ReplyType["block_comment_id"];
      value: ReplyType["reply"];
    }) => {
      const comment = getCommentById(commentId);
      if (!comment || !userId) {
        return;
      }
      try {
        const reply = await debouncePromise({
          promise: commentsService.postBlockCommentReply({
            userId,
            blockId: comment.block_id,
            blockCommentId: comment.id,
            reply: value,
          }),
        });
        addReplyToStore(reply);
      } catch (rejection) {
        addToast({
          variant: "error",
          message: "Failed to submit a reply.",
        });

        // pass rejection to the caller
        throw rejection;
      }
    },
    [userId]
  );

  // edit a reply
  const editReply = useCallback(
    async ({
      commentId,
      replyId,
      value,
    }: {
      commentId: ReplyType["block_comment_id"];
      replyId: ReplyType["id"];
      value: ReplyType["reply"];
    }) => {
      const comment = getCommentById(commentId);
      if (!comment) {
        return;
      }
      const reply = comment.block_comment_replies.find(
        ({ id }) => id === replyId
      );
      if (!reply) {
        return;
      }

      try {
        const editedReply = await debouncePromise({
          promise: commentsService.updateBlockCommentReply({
            blockId: comment.block_id,
            blockCommentId: comment.id,
            blockCommentReplyId: reply.id,
            updatedReply: value,
          }),
        });
        editReplyInStore(editedReply);
      } catch (rejection) {
        addToast({
          variant: "error",
          message: "Failed to edit a reply.",
        });

        // pass rejection to the caller
        throw rejection;
      }
    },
    []
  );

  // fetch the list of usernames matching the query that can be mentioned in a comment
  const fetchUsersForMentions = useCallback(
    async (query: string) => {
      const users = await userService.findResourceUsersMatchingQuery(
        projectId,
        query
      );
      return users;
    },
    [projectId]
  );

  // replace mentions `@[username](id)` with `<span>@username</span>`
  const parseTextWithMentions = useCallback(
    (text: string) => {
      const SPLIT_PATTERN = /(@\[[^\]]+\]\([^)]+\))/;
      const MENTION_PATTERN = /(?:@\[([^\]]+)\]\(([^)]+)\))/;

      return text
        .split(SPLIT_PATTERN)
        .filter((part) => part !== "")
        .map((part, index) => {
          if (!part.startsWith("@")) {
            return part;
          }

          const match = part.match(MENTION_PATTERN);

          if (!match || match.length < 3) {
            return part;
          }

          const [, username, id] = match;

          return (
            <span
              key={index}
              className={cn(styles.mention, {
                [styles.highlighted]: id === userId,
              })}
            >
              @{username}
            </span>
          );
        });
    },
    [userId]
  );

  return useMemo(() => {
    return {
      currentUserDisplayName,
      canAddComment,
      fetchCommentsData,
      getCommentPermissions,
      getReplyPermissions,
      addComment,
      editComment,
      resolveComment,
      unresolveComment,
      deleteComment,
      deleteReply,
      replyToComment,
      editReply,
      fetchUsersForMentions,
      parseTextWithMentions,
    };
  }, [
    currentUserDisplayName,
    canAddComment,
    fetchCommentsData,
    getCommentPermissions,
    getReplyPermissions,
    addComment,
    editComment,
    resolveComment,
    unresolveComment,
    deleteComment,
    deleteReply,
    replyToComment,
    editReply,
    fetchUsersForMentions,
    parseTextWithMentions,
  ]);
}
