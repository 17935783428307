import { useCallback, useMemo } from "react";
import { useCanvasState, useAssetsState } from "store";
import type {
  CanvasAssetMembershipDeletedWsPayload,
  CanvasAssetMembershipAddedWsPayload,
  CanvasAssetMembershipUpdatedWsPayload,
} from "pages/Assets/types/abstractTypes";

export const useCanvasAssetsActions = () => {
  const canvasId = useCanvasState((state) => state.canvasId);

  const {
    setCanvasFunction,
    deleteCanvasFunctionById,
    deleteFunctionVersionCanvasUsage,
  } = useAssetsState((state) => state.functionsActions);

  const {
    setCanvasConstant,
    deleteCanvasConstantById,
    deleteConstantVersionCanvasUsage,
  } = useAssetsState((state) => state.constantsActions);

  const {
    setCanvasQuery,
    deleteCanvasQueryById,
    deleteQueryVersionCanvasUsage,
  } = useAssetsState((state) => state.queriesActions);

  const {
    setCanvasConnection,
    deleteCanvasConnectionById,
    deleteConnectionCanvasUsage,
  } = useAssetsState((state) => state.connectionsActions);

  // handle websocket message payload when asset has been removed from canvas
  const onWsCanvasAssetMembershipDeleted = useCallback(
    (data: CanvasAssetMembershipDeletedWsPayload) => {
      if (!canvasId || !data || data.canvas_id !== canvasId) {
        return;
      }

      const {
        asset_type: assetType,
        asset_id: assetId,
        asset_version_id: assetVersionId,
      } = data;

      switch (assetType) {
        case "functions": {
          deleteCanvasFunctionById(assetId);
          deleteFunctionVersionCanvasUsage({
            assetId,
            versionId: assetVersionId,
            canvasId,
          });
          break;
        }
        case "constants": {
          deleteCanvasConstantById(assetId);
          deleteConstantVersionCanvasUsage({
            assetId,
            versionId: assetVersionId,
            canvasId,
          });
          break;
        }
        case "queries": {
          deleteCanvasQueryById(assetId);
          deleteQueryVersionCanvasUsage({
            assetId,
            versionId: assetVersionId,
            canvasId,
          });
          break;
        }
        case "connections": {
          deleteCanvasConnectionById(assetId);
          deleteConnectionCanvasUsage(assetId, canvasId);
          break;
        }
        default:
          break;
      }
    },
    [canvasId]
  );

  // handle websocket message payload when asset has been added to canvas
  const onWsCanvasAssetMembershipAdded = useCallback(
    (data: CanvasAssetMembershipAddedWsPayload) => {
      if (!canvasId || !data || data.canvas_id !== canvasId) {
        return;
      }

      const { asset_type: assetType, canvas_asset_data } = data;

      switch (assetType) {
        case "functions": {
          setCanvasFunction(canvas_asset_data);
          break;
        }
        case "constants": {
          setCanvasConstant(canvas_asset_data);
          break;
        }
        case "queries": {
          setCanvasQuery(canvas_asset_data);
          break;
        }
        case "connections": {
          setCanvasConnection(canvas_asset_data);
          break;
        }
        default:
          break;
      }
    },
    [canvasId]
  );

  // handle websocket message payload when asset canvas membership has been updated (changed version used on canvas)
  const onWsCanvasAssetMembershipUpdated = useCallback(
    (data: CanvasAssetMembershipUpdatedWsPayload) => {
      if (!canvasId || !data || data.canvas_id !== canvasId) {
        return;
      }

      const { asset_type: assetType, canvas_asset_data } = data;

      switch (assetType) {
        case "functions": {
          setCanvasFunction(canvas_asset_data);
          break;
        }
        case "constants": {
          setCanvasConstant(canvas_asset_data);
          break;
        }
        case "queries": {
          setCanvasQuery(canvas_asset_data);
          break;
        }
        default:
          break;
      }
    },
    [canvasId]
  );

  return useMemo(() => {
    return {
      onWsCanvasAssetMembershipAdded,
      onWsCanvasAssetMembershipDeleted,
      onWsCanvasAssetMembershipUpdated,
    };
  }, [
    onWsCanvasAssetMembershipAdded,
    onWsCanvasAssetMembershipDeleted,
    onWsCanvasAssetMembershipUpdated,
  ]);
};
