import styles from "./WorkspaceSettings.module.scss";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams, useNavigate } from "react-router-dom";
import { useUserState, useOrganizationsState, useToastsState } from "store";
import { useModal } from "hooks/useModal";
import { Button } from "components/common/Button/Button";
import TextField from "components/common/TextField/TextField";
import Typography from "components/Typography/Typography";
import {
  getWorkspace,
  patchWorkspace,
  deleteWorkspace,
  getWorkspaces,
} from "api/http/workspace-service";
import { DeleteWorkspaceModal } from "./DeleteWorkspaceModal";
import type { Workspace } from "models/organization";
import { WORKSPACE_NAME_YUP_VALIDATION_BASE } from "config/organizationsConfig";

export function WorkspaceSettings() {
  const userID = useUserState((slice) => slice.userID);
  const addToast = useToastsState((slice) => slice.addToast);
  const { workspaceID = "", orgID = "" } = useParams();
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();
  const [workspace, setWorkspace] = useState<Workspace>();
  const [workspaces, setWorkspaces] = useState<Workspace[]>([]);
  const updateOrganizationWorkspaceInStore = useOrganizationsState(
    (slice) => slice.updateOrganizationWorkspace
  );
  const deleteOrganizationWorkspaceFromStore = useOrganizationsState(
    (slice) => slice.deleteOrganizationWorkspace
  );

  // Fetch users workspace when the component loads.
  useEffect(() => {
    async function initializeWorkspace() {
      // TODO: add error handling
      const workspacesResult = await getWorkspace(orgID, workspaceID);
      if (workspacesResult) {
        // store workspace
        setWorkspace(workspacesResult);
        formik.resetForm({
          values: {
            name: workspacesResult.name,
          },
        });
      }
      const allWorkspaces = await getWorkspaces(orgID);
      setWorkspaces(allWorkspaces);
    }
    if (userID && workspaceID) {
      initializeWorkspace();
    }
  }, [userID, workspaceID]);

  // Delete the workspace.
  const handleDeleteWorkspace = () => {
    if (userID) {
      deleteWorkspace(orgID, workspaceID)
        .then(() => {
          closeModal();
          formik.resetForm({
            values: {
              name: "",
            },
          });
          addToast({
            message: "Workspace successfully deleted",
            variant: "success",
          });
          deleteOrganizationWorkspaceFromStore(orgID, workspaceID);
          navigate(`/org/${orgID}`);
        })
        .catch((error) => {
          if (error.cause) {
            formik.setErrors(error.cause);
          }
          addToast({
            message: error.message,
            variant: "error",
          });
        });
    }
  };

  const onSubmit = (values) => {
    if (!userID) {
      return;
    }

    patchWorkspace(orgID, workspaceID, {
      name: values.name.trim(),
    })
      .then((returnedValues) => {
        addToast({
          message: "Workspace successfully updated",
          variant: "success",
        });
        formik.resetForm({
          values: {
            name: returnedValues.name,
          },
        });
        updateOrganizationWorkspaceInStore(orgID, workspaceID, {
          name: returnedValues.name,
        });
      })
      .catch((error) => {
        if (error.cause) {
          formik.setErrors(error.cause);
        }
        addToast({
          message: error.message,
          variant: "error",
        });
      });
  };

  const onDelete = () => {
    openModal({
      title: "Delete Workspace",
      content: () => (
        <DeleteWorkspaceModal
          onCancelDelete={closeModal}
          onDeleteWorkspace={handleDeleteWorkspace}
        />
      ),
    });
  };

  // Yup validation schema
  const validationSchema = Yup.object().shape({
    name: WORKSPACE_NAME_YUP_VALIDATION_BASE.test(
      "unique-name",
      "Workspace name must be unique",
      function (value) {
        if (!value) {
          return true;
        }

        // Convert the input value to lowercase for case-insensitive comparison
        const lowercaseValue = value.toLowerCase();

        // Check if the name already exists in the list of existing workspaces
        const nameExists = workspaces?.some(
          (existingWorkspace) =>
            existingWorkspace.name.toLowerCase() === lowercaseValue &&
            existingWorkspace.id !== workspaceID
        );

        // Return false if the name already exists, true otherwise
        return !nameExists;
      }
    ),
  });

  // Initialize formik.
  const formik = useFormik({
    initialValues: {
      name: workspace?.name || "",
    },
    validationSchema,
    onSubmit,
  });

  return (
    <div className={styles.settingsWrapper}>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.form}>
          <TextField
            id="name"
            name="name"
            label="Workspace Name"
            placeholder={"Name of workspace..."}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            error={formik.errors.name}
          />
        </div>
        <div className={styles.buttonSection}>
          <Button
            type="submit"
            className={styles.updateWorkspaceButton}
            disabled={!formik.isValid || !formik.dirty}
          >
            Save Workspace
          </Button>
        </div>
      </form>
      {workspaceID && (
        <div className={styles.deleteWrapper}>
          <div className={styles.deleteExplanationText}>
            <Typography variant="h2">Delete Workspace</Typography>
            <Typography variant="body2" className={styles.subTitle}>
              This action will permanently delete this workspace and all of
              it&#39;s memberships. If there is other resource data associated
              with this workspace, you must delete all of it first. Please be
              certain.
            </Typography>
          </div>
          <Button
            variant="crucial"
            onClick={() => {
              onDelete();
            }}
          >
            Delete Workspace
          </Button>
        </div>
      )}
    </div>
  );
}
