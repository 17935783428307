import styles from "./TitleSection.module.scss";
import { type ReactNode } from "react";
import Typography from "components/Typography/Typography";

type TitleSectionProps = {
  title: string;
  subTitle?: ReactNode;
  leftControls?: ReactNode;
  rightControls?: ReactNode;
};

export function TitleSection({
  title,
  subTitle,
  leftControls,
  rightControls,
}: TitleSectionProps) {
  return (
    <section className={styles.container}>
      {leftControls ? <div>{leftControls}</div> : null}

      <div className={styles.titleWrap}>
        <Typography variant="h1" className={styles.title}>
          {title}
        </Typography>

        {subTitle && (
          <Typography variant="body2" className={styles.subTitle}>
            {subTitle}
          </Typography>
        )}
      </div>

      {rightControls ? <div>{rightControls}</div> : null}
    </section>
  );
}
