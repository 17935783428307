import { CANVAS_API_URL } from "config/appConfig";
import { callAPI } from "./call-api";
import type {
  COMPUTE_ENV_TYPE,
  MARATHON_SIZE,
  SAGEMAKER_INSTANCE_TYPE,
} from "config/canvasConfig.ts";
import type { BlockType, BlockInputVariableBase } from "models/canvas";

export async function getBlockContent(blockId: BlockType["id"]) {
  return await callAPI<{ block_id: BlockType["id"]; block_content: string }>({
    url: `${CANVAS_API_URL}/block/content/${blockId}`,
    method: "GET",
  });
}

export async function getBlockState({
  blockId,
  useCache = true,
}: {
  blockId: BlockType["id"];
  useCache?: boolean;
}) {
  const query = new URLSearchParams({
    use_cache: useCache ? "true" : "false",
  });
  return await callAPI({
    url: `${CANVAS_API_URL}/block/state/${blockId}?${query.toString()}`,
    method: "GET",
  });
}

export async function getBlockDisplay({
  blockId,
  displayFileName,
  signal,
}: {
  blockId: BlockType["id"];
  displayFileName: string;
  signal?: AbortSignal;
}) {
  // gets a presigned url to download and then downloads the csv
  return await callAPI({
    url: `${CANVAS_API_URL}/block/${blockId}/display/${displayFileName}`,
    method: "GET",
    signal,
  }).then(async (response) => {
    const extension = displayFileName.split(".").pop();

    // if html, return the response as is which is a presigned url
    if (extension === "html") {
      return response;
    }

    // download the file with fetch and process based on content type
    const response2 = await fetch(response, { signal });

    switch (extension) {
      case "png": {
        const data = await response2.blob();
        return URL.createObjectURL(data);
      }
      case "json":
        return await response2.json();
      default:
        return await response2.text();
    }
  });
}

export async function getBlockLiveLogsUrl({
  blockId,
  signal,
}: {
  blockId: BlockType["id"];
  signal?: AbortSignal;
}) {
  const expiration = 24 * 60 * 60;
  const query = new URLSearchParams({
    expiration: expiration.toString(),
  });
  return await callAPI({
    url: `${CANVAS_API_URL}/block/${blockId}/storage_urls/live_logs.txt?${query.toString()}`,
    method: "GET",
    signal,
  });
}

export async function putAddBlockStateVariables(
  blockId: BlockType["id"],
  stateVariables: BlockInputVariableBase[]
) {
  return await callAPI({
    url: `${CANVAS_API_URL}/block/state_variables/${blockId}`,
    method: "PUT",
    data: stateVariables,
  });
}

export async function getBlockStateVariables(blockId: BlockType["id"]) {
  return await callAPI<BlockInputVariableBase[]>({
    url: `${CANVAS_API_URL}/block/state_variables/${blockId}`,
    method: "GET",
  });
}

export async function getBlockStateFile(
  blockId: BlockType["id"],
  stateFile: string
) {
  return await callAPI({
    url: `${CANVAS_API_URL}/block/${blockId}/state/${stateFile}`,
    method: "GET",
  });
}

export async function getBlockImageFile(
  blockId: BlockType["id"],
  imageFile: string
) {
  return await callAPI({
    url: `${CANVAS_API_URL}/block/${blockId}/image/${imageFile}`,
    method: "GET",
  });
}

export async function deleteBlockStateVariables(blockId: BlockType["id"]) {
  return await callAPI({
    url: `${CANVAS_API_URL}/block/state_variables/${blockId}`,
    method: "DELETE",
  });
}

export async function deleteBlockStateVariable(
  blockId: BlockType["id"],
  variable: BlockInputVariableBase
) {
  return await callAPI({
    url: `${CANVAS_API_URL}/block/state_variables/${blockId}/${variable.name}/${variable.creator_block_id}/${variable.source_block_id}`,
    method: "DELETE",
  });
}

export type BlockUpdatePayload =
  | {
      compute_environment_type: COMPUTE_ENV_TYPE.MARATHON;
      size: MARATHON_SIZE;
    }
  | {
      compute_environment_type: COMPUTE_ENV_TYPE.SPRINT;
    }
  | {
      compute_environment_type: COMPUTE_ENV_TYPE.GPU;
      gpu_instance_type: SAGEMAKER_INSTANCE_TYPE;
    };

export async function updateBlock(
  blockId: BlockType["id"],
  payload: BlockUpdatePayload
) {
  return await callAPI({
    method: "PATCH",
    url: `${CANVAS_API_URL}/block/${blockId}`,
    data: payload,
  });
}

export async function updateBlockProperties({
  blockId,
  payload,
  signal,
}: {
  blockId: BlockType["id"];
  payload: any;
  signal?: AbortSignal;
}) {
  return await callAPI({
    method: "PATCH",
    url: `${CANVAS_API_URL}/block/${blockId}/properties`,
    data: payload,
    signal,
  });
}

export type SqlBlockUpdatePayload =
  | {
      connection_id: string;
    }
  | {
      output_variable: string;
    }
  | {
      input_dataframe: boolean;
    };

export async function updateSqlBlockProperties({
  blockId,
  payload,
}: {
  blockId: BlockType["id"];
  payload: SqlBlockUpdatePayload;
}) {
  return await callAPI({
    url: `${CANVAS_API_URL}/block/${blockId}/sql/properties`,
    method: "PATCH",
    data: payload,
  });
}

export type GenAiBlockUpdatePayload =
  | {
      model: string;
    }
  | {
      output_variable: string;
    };

export async function updateGenAiBlockProperties({
  blockId,
  payload,
}: {
  blockId: BlockType["id"];
  payload: GenAiBlockUpdatePayload;
}) {
  return await callAPI({
    url: `${CANVAS_API_URL}/block/${blockId}/gen_ai/properties`,
    method: "PATCH",
    data: payload,
  });
}

export type BedrockBlockUpdatePayload =
  | {
      input: string;
      input_source_block_id: string;
    }
  | {
      model: string;
    }
  | {
      output_variable: string;
    }
  | {
      model_input_image: { [key: string]: string };
    };

export async function updateBedrockBlockProperties({
  blockId,
  payload,
}: {
  blockId: BlockType["id"];
  payload: BedrockBlockUpdatePayload;
}) {
  return await callAPI({
    method: "PATCH",
    url: `${CANVAS_API_URL}/block/${blockId}/bedrock/properties`,
    data: payload,
  });
}

export type SnowparkBlockUpdatePayload = {
  connection_id: string;
};

export async function updateSnowparkBlockProperties({
  blockId,
  payload,
}: {
  blockId: BlockType["id"];
  payload: SnowparkBlockUpdatePayload;
}) {
  return await callAPI({
    url: `${CANVAS_API_URL}/block/${blockId}/snowpark/properties`,
    method: "PATCH",
    data: payload,
  });
}

export async function clearOutput(blockId: BlockType["id"]) {
  return await callAPI({
    url: `${CANVAS_API_URL}/block/${blockId}/output`,
    method: "DELETE",
  });
}
