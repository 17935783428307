import { useState } from "react";
import {
  autoUpdate,
  flip,
  offset,
  shift,
  useDismiss,
  useFloating,
} from "@floating-ui/react";
import { BiCaretDown, BiCaretUp } from "react-icons/bi";
import ContextMenu from "../../../ContextMenu/ContextMenu";
import styles from "./LanguageVersionSelect.module.scss";
import cn from "classnames";

interface LanguageVersionSelectProps {
  versions: string[];
  className?: string;
}

const LanguageVersionSelect = ({
  versions,
  className,
}: LanguageVersionSelectProps) => {
  const [selectedVersion, setSelectedVersion] = useState<string>(versions[0]);
  const [versionOpen, setVersionOpen] = useState(false);

  const complementedVersions = versions.map((item) => {
    return {
      key: item,
      label: item,
      type: "option" as const,
      onClick: () => {
        setSelectedVersion(item);
      },
    };
  });

  const {
    x: xVersion,
    y: yVersion,
    refs: refsVersion,
    strategy: strategyVersion,
    context: contextVersion,
  } = useFloating({
    open: versionOpen,
    onOpenChange: setVersionOpen,
    whileElementsMounted: autoUpdate,
    placement: "bottom-end",
    middleware: [offset(5), flip(), shift()],
  });

  useDismiss(contextVersion, {
    escapeKey: true,
    outsidePress: true,
  });

  return (
    <div
      className={cn(
        styles.dropdownSelect,
        { [styles.dropdownSelectOpen]: versionOpen },
        className
      )}
      onClick={() => {
        setVersionOpen((prev) => !prev);
      }}
      ref={refsVersion.setReference}
    >
      <div>{selectedVersion}</div>

      <div className={styles.dropdownIcon}>
        {versionOpen ? <BiCaretUp size={12} /> : <BiCaretDown size={12} />}
      </div>
      {versionOpen && (
        <div
          ref={refsVersion.setFloating}
          style={{
            position: strategyVersion,
            top: yVersion ?? 0,
            left: xVersion ?? 0,
            width: 110,
            zIndex: 3,
          }}
          className={styles.dropdownMenu}
        >
          <ContextMenu
            menuOptions={complementedVersions || []}
            fitWidth
            onClose={() => {
              setVersionOpen(false);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default LanguageVersionSelect;
