import { type StateCreator } from "store";
import {
  LOCAL_STORAGE_KEY,
  SORTING_COLUMN,
  LOCAL_PREFERENCE_KEY,
} from "config/localPreferences";
import { SORTING_DIRECTION } from "config/appConfig";

type State = {
  [LOCAL_PREFERENCE_KEY.reportsSortingColumn]: SORTING_COLUMN;
  [LOCAL_PREFERENCE_KEY.reportsSortingDirection]: SORTING_DIRECTION;
};

type Actions = {
  setReportsSortingColumn: (column: SORTING_COLUMN) => void;
  setReportsSortingDirection: (direction: SORTING_DIRECTION) => void;
};

const initialState: State = getInitialState();

export type LocalReportPreferencesSlice = State & Actions;

export const createLocalReportPreferencesSlice: StateCreator<
  LocalReportPreferencesSlice
> = (set) => ({
  ...initialState,
  setReportsSortingColumn: (column) => {
    set(
      (store) => {
        store.localReportPreferencesSlice.reportsSortingColumn = column;
        setPreference(LOCAL_PREFERENCE_KEY.reportsSortingColumn, column);
      },
      false,
      "localPreferences/setReportsSortingColumn"
    );
  },
  setReportsSortingDirection: (direction) => {
    set(
      (store) => {
        store.localReportPreferencesSlice.reportsSortingDirection = direction;
        setPreference(LOCAL_PREFERENCE_KEY.reportsSortingDirection, direction);
      },
      false,
      "localPreferences/setReportsSortingDirection"
    );
  },
});

/**
 * Restore preference from the local storage.
 * If the restored value is empty or invalid, use the default.
 */
function getInitialState() {
  // restore/validate reports sorting column/direction
  let reportsSortingColumn = getPreference(
    LOCAL_PREFERENCE_KEY.reportsSortingColumn
  );
  let reportsSortingDirection = getPreference(
    LOCAL_PREFERENCE_KEY.reportsSortingDirection
  );
  if (
    !Object.values(SORTING_COLUMN).includes(reportsSortingColumn) ||
    !Object.values(SORTING_DIRECTION).includes(reportsSortingDirection)
  ) {
    reportsSortingColumn = SORTING_COLUMN.createdAt;
    reportsSortingDirection = SORTING_DIRECTION.DESCENDING;
    setPreference(
      LOCAL_PREFERENCE_KEY.reportsSortingColumn,
      reportsSortingColumn
    );
    setPreference(
      LOCAL_PREFERENCE_KEY.reportsSortingDirection,
      reportsSortingDirection
    );
  }

  return {
    reportsSortingColumn,
    reportsSortingDirection,
  };
}

/**
 * Get the object with all preferences from the local storage.
 */
function getAllPreferences() {
  return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || "{}");
}

/**
 * Get a preference value from the local storage.
 */
function getPreference(key: LOCAL_PREFERENCE_KEY) {
  const allPreferences = getAllPreferences();
  return allPreferences[key] ?? null;
}

/**
 * Set a preference value into the local storage.
 */
function setPreference(
  key: LOCAL_PREFERENCE_KEY.reportsSortingColumn,
  value: SORTING_COLUMN
): void;
function setPreference(
  key: LOCAL_PREFERENCE_KEY.reportsSortingDirection,
  value: SORTING_DIRECTION
): void;
function setPreference(key: LOCAL_PREFERENCE_KEY, value: any): void {
  const allPreferences = getAllPreferences();
  allPreferences[key] = value;
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(allPreferences));
}
