import { USER_API_URL } from "config/appConfig";
import { callAPI } from "./call-api";

export const getRoles = async (organizationId: string) =>
  await callAPI({
    method: "GET",
    url: `${USER_API_URL}/organizations/${organizationId}/roles/including`,
  });

export const getFeatures = async () =>
  await callAPI({
    method: "GET",
    url: `${USER_API_URL}/features`,
  });

export const getOrganizationCustomRoles = async (organizationId: string) =>
  await callAPI({
    method: "GET",
    url: `${USER_API_URL}/organizations/${organizationId}/roles`,
  });

export const getRole = async (organizationId: string, roleId: string) =>
  await callAPI({
    method: "GET",
    url: `${USER_API_URL}/organizations/${organizationId}/roles/${roleId}`,
  });

export const postRole = async (organizationId: string, data: any) =>
  await callAPI({
    method: "POST",
    url: `${USER_API_URL}/organizations/${organizationId}/roles`,
    data,
  });

export const patchRole = async (
  organizationId: string,
  roleId: string,
  data: any
) =>
  await callAPI({
    method: "PATCH",
    url: `${USER_API_URL}/organizations/${organizationId}/roles/${roleId}`,
    data,
  });

export const deleteRole = async (organizationId: string, roleId: string) =>
  await callAPI({
    method: "DELETE",
    url: `${USER_API_URL}/organizations/${organizationId}/roles/${roleId}`,
  });

export const updateRoleFeaturePermission = async (
  organizationId: string,
  role_id: string,
  feature_id: string,
  permission: string
) =>
  await callAPI({
    url: `${USER_API_URL}/organizations/${organizationId}/roles/${role_id}/permission`,
    method: "PATCH",
    data: {
      role_id,
      feature_id,
      permission,
    },
  });

const roleService = {
  getRoles,
  getFeatures,
  getOrganizationCustomRoles,
  getRole,
  postRole,
  patchRole,
  deleteRole,
  updateRoleFeaturePermission,
};

export default roleService;
