import styles from "./Spinner.module.scss";
import cn from "classnames";
import spinner from "assets/animations/zerve_spinner.gif";

type SpinnerProps = {
  className?: string;
};

export default function Spinner({ className }: SpinnerProps) {
  return (
    <div className={cn(styles.container, className)}>
      <img className={styles.image} src={spinner} />
    </div>
  );
}
