import { ASSET_API_URL } from "config/appConfig";
import { callAPI } from "../call-api";
import { type Connection } from "pages/Assets/types/connectionTypes";

const getUserConnections = async () => {
  return await callAPI({
    url: `${ASSET_API_URL}/connections/user`,
    method: "GET",
  });
};

const getWorkspaceConnections = async (workspaceId: string) => {
  return await callAPI({
    url: `${ASSET_API_URL}/connections/workspace/${workspaceId}`,
    method: "GET",
  });
};

const getConnectionTypes = async () =>
  await callAPI({
    url: `${ASSET_API_URL}/connections/get_supported_connections`,
    method: "GET",
  });

const postConnection = async (data: {
  name: string;
  connection_type_id: string | null;
}) =>
  await callAPI<Connection>({
    url: `${ASSET_API_URL}/connections`,
    method: "POST",
    data,
  });

const postWorkspaceConnection = async (
  workspaceId: string,
  data: {
    name: string;
    connection_type_id: string | null;
  }
) =>
  await callAPI<Connection>({
    url: `${ASSET_API_URL}/connections/workspace/${workspaceId}`,
    method: "POST",
    data,
  });

const putConnection = async (
  id: string,
  data: {
    id: string;
    name: string;
  }
) =>
  await callAPI<Connection>({
    url: `${ASSET_API_URL}/connections/${id}`,
    method: "PUT",
    data,
  });

const updateConnection = async (
  id: string,
  data: {
    id: string;
    value: Connection["value"];
  }
) =>
  await callAPI<Connection>({
    url: `${ASSET_API_URL}/connections/${id}`,
    method: "PATCH",
    data,
  });

const putConnectionArchive = async (data: { id: string; archive: boolean }) =>
  await callAPI<Connection>({
    url: `${ASSET_API_URL}/connections/${data.id}/archive`,
    method: "PUT",
    data: {
      archive: data.archive,
    },
  });

const deleteConnection = async (id: string) =>
  await callAPI({
    url: `${ASSET_API_URL}/connections/${id}`,
    method: "DELETE",
  });

const getConnection = async (id: string) =>
  await callAPI<Connection>({
    url: `${ASSET_API_URL}/connections/${id}`,
    method: "GET",
  });

const createConnectionDuplicate = async (
  userId: string,
  connectionId: string
) =>
  await callAPI<Connection>({
    url: `${ASSET_API_URL}/connections/${connectionId}/duplicate`,
    method: "POST",
    data: {},
  });

export {
  getUserConnections,
  getWorkspaceConnections,
  createConnectionDuplicate,
  getConnectionTypes,
  getConnection,
  deleteConnection,
  postConnection,
  postWorkspaceConnection,
  putConnection,
  putConnectionArchive,
  updateConnection,
};
