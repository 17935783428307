import {
  type FunctionItemType,
  type FunctionVersionType,
} from "./functionTypes";
import { type QueryItemType, type QueryVersionType } from "./queryTypes";
import {
  type ConstantItemType,
  type CreatedConstantType,
  type ConstantVersionType,
} from "./constantTypes";
import type { CanvasConnection } from "./connectionTypes";
import type { DATA_LOAD_STATUS } from "config/appConfig";
import type { UserPermission } from "models/user";

export interface AssetsDataContextInterface {
  getCurrentAssetItemsData: (
    assetType: AssetPageTabType
  ) => AssetItemsDataInterface<AssetPageTabType>;
  getCurrentAssetItemActions: (
    assetType: AssetPageTabType
  ) => AssetItemActionsInterface<AssetPageTabType>;
  getCreatedAssetItemData: (
    assetType: AssetPageTabType
  ) => CreatedAssetItemInterface<AssetPageTabType>;
  getCreatedAssetItemActions: (
    assetType: AssetPageTabType
  ) => CreatedAssetItemActionsInterface<AssetPageTabType>;
  // will define this later on since it's quite unclear at the moment
  getAssetSpecificData: (assetType: AssetPageTabType) => any;
}

export interface AssetPageTabTypeToDataType {
  function: FunctionItemType;
  query: QueryItemType;
  constant: ConstantItemType;
}

export interface CreatedAssetTypeToDataType {
  function: any;
  constant: CreatedConstantType;
  // will be added later
  query: any;
}

export type AssetPageTabTypeToVersionDataType = {
  function: FunctionVersionType;
  constant: ConstantVersionType;
  query: QueryVersionType;
};

export type CreatedAssetType = keyof CreatedAssetTypeToDataType;

export type AbstractCreatedAssetTypeData<T extends CreatedAssetType> =
  CreatedAssetTypeToDataType[T];

export type AssetPageTabType = keyof AssetPageTabTypeToDataType;

export enum AssetPageTabUrl {
  function = "functions",
  query = "queries",
  constant = "constants",
  connection = "connections",
}

export enum AssetUrlToPageTab {
  functions = "function",
  queries = "query",
  constants = "constant",
  connections = "connection",
}

export type AssetUrlPageType = keyof typeof AssetPageTabUrl;

// these statuses copied from BE to match them
export enum AssetStatus {
  NO_STATUS = 1,
  PRIMARY = 2,
  DRAFT = 3,
  OUTDATED = 4,
}
export type AssetVersionStatus = keyof typeof AssetStatus;

export type AbstractDataTypeForTab<T extends AssetPageTabType> =
  AssetPageTabTypeToDataType[T];

export interface AssetItemsDataInterface<T extends AssetPageTabType> {
  assetsData: Record<string, AbstractDataTypeForTab<T>>;
  activeItemData: AbstractDataTypeForTab<T> | null;
  activeVersionData: AbstractDataTypeForTab<T>["versions"][string] | null;
  activeItemId: string | null;
  activeItemVersionId: string | null;
  isDisplayArchivedItems: boolean;
  assetDataLoadState: DATA_LOAD_STATUS;
}

export interface AssetItemActionsInterface<T extends AssetPageTabType> {
  setActiveItemId: (id: string) => void;
  setActiveVersionNumber: (versionNumber: number) => void;
  setIsDisplayArchivedItems: (isDisplay: boolean) => void;
  setAssetsData: (data: Record<string, AbstractDataTypeForTab<T>>) => void;
  deleteAssetItem: (id?: string) => void;
  deleteAssetVersion: (id: string, versionN: number) => void;
  setAssetVersionStatus: (
    id: string,
    status: number,
    versionNumber: number
  ) => void;
  setAssetVersionIsArchived: (versionId: string, isArchived: boolean) => void;
  setAssetIsArchived: (assetId: string, isArchived: boolean) => void;
  setActiveItemByVersionId: (versionId: string) => void;
  clearActiveItemData: () => void;
  clearStore: () => void;
}

export interface CreatedAssetItemInterface<T extends CreatedAssetType> {
  createdAssetItemData: AbstractCreatedAssetTypeData<T> | null;
  assetOperationType: AssetItemOperationType;
  createdAssetError: string | null;
  createdAssetLoading: boolean;
}

type CreateEditAssetDataType = CreatedAssetTypeToDataType[CreatedAssetType];

export interface CreatedAssetItemActionsInterface<T extends CreatedAssetType> {
  setCreatedAssetItemData: (
    data: AbstractCreatedAssetTypeData<T> | null
  ) => void;
  setAssetItemOperationType: (operationType: AssetItemOperationType) => void;
  createAssetItem: (data?: CreateEditAssetDataType) => void;
  editAssetItem: (data?: CreateEditAssetDataType) => void;
  updateAssetItemName: () => void;
  addNewVersion: (data?: CreateEditAssetDataType) => void;
  setCreatedAssetError: (error: string | null) => void;
  clearCreatedAsset: () => void;
}

export type AssetItemOperationType =
  | "create"
  | "edit"
  | "editName"
  | "newVersion"
  | "delete"
  | null;

export interface AssetInviteData {
  id: string;
  email: string;
  username: string;
  avatar: string;
  permission: UserPermission;
  date_created: string;
  is_owner: boolean;
}

export type AssetDataTypes =
  | Record<string, FunctionItemType>
  | Record<string, ConstantItemType>
  | Record<string, QueryItemType>;

export type CanvasAssetMembershipDeleteResponseType = {
  asset_type: AssetPageTabUrl;
  asset_id: string;
  asset_version_id: string;
  connection_id?: string;
  canvas_id: string;
  detail: string;
};

type CanvasAssetMembershipUpdateTypeData =
  | {
      asset_type: "functions";
      canvas_asset_data: FunctionItemType;
    }
  | {
      asset_type: "constants";
      canvas_asset_data: ConstantItemType;
    }
  | {
      asset_type: "queries";
      canvas_asset_data: QueryItemType;
    };

type CanvasAssetMembershipAddedTypeData =
  | CanvasAssetMembershipUpdateTypeData
  | {
      asset_type: "connections";
      canvas_asset_data: CanvasConnection;
    };

export type CanvasAssetMembershipAddResponseType = {
  asset_id: string;
  asset_version_id: string;
  connection_id?: string;
  canvas_id: string;
} & CanvasAssetMembershipAddedTypeData;

export type CanvasAssetMembershipUpdateResponseType = {
  asset_id: string;
  asset_version_id: string;
  connection_id?: string;
  canvas_id: string;
} & CanvasAssetMembershipUpdateTypeData;

export type CanvasAssetMembershipDeletedWsPayload =
  CanvasAssetMembershipDeleteResponseType;

export type CanvasAssetMembershipAddedWsPayload =
  CanvasAssetMembershipAddResponseType;

export type CanvasAssetMembershipUpdatedWsPayload =
  CanvasAssetMembershipUpdateResponseType;
