import { useState } from "react";

import { type PropsWithModal } from "hooks/useModal";
import Typography from "components/Typography";
import { Button } from "components/common/Button/Button";
import { Checkbox } from "components/common/Checkbox/Checkbox";
import styles from "./RemoveEnvModal.module.scss";

type RemoveEnvModalProps = PropsWithModal;

const RemoveEnvModal = ({ onModalClose }: RemoveEnvModalProps) => {
  const [dontAsk, setDontAsk] = useState<boolean>(false);

  const onChange = () => {
    setDontAsk((dontAsk) => !dontAsk);
  };

  const onCancel = () => {
    console.log("Cancel");
    onModalClose();
  };

  const onRemove = () => {
    console.log("Remove");
    onModalClose();
  };

  return (
    <div>
      <Typography
        component="span"
        variant="body2"
        className={styles["modal__text"]}
      >
        Are you sure you want to remove this environment? This environment can’t
        be restored.
      </Typography>
      <div className={styles["modal__controls"]}>
        <div>
          <Checkbox
            checked={dontAsk}
            onChange={onChange}
            label={
              <Typography component="span" variant="body1">
                Don’t ask again
              </Typography>
            }
          />
        </div>

        <div className={styles["modal__buttons"]}>
          <Button variant="secondary" onClick={onCancel} size="large">
            Cancel
          </Button>
          <Button size="large" variant="crucial" onClick={onRemove}>
            Remove
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RemoveEnvModal;
