import { useState } from "react";
import { ResizableBox, type ResizableBoxProps } from "react-resizable";
import styles from "./ResizableNav.module.scss";
import cn from "classnames";

type ResizableNavProps = {
  position: "right" | "left" | "top";
  defaultSize?: number;
  onResize?: (e: any, data: any) => void;
  children: any;
  boxProps?: Partial<ResizableBoxProps>;
};

export default function ResizableNav({
  position = "right",
  defaultSize = 224,
  onResize,
  children,
  boxProps,
}: ResizableNavProps) {
  const [size, setSize] = useState(defaultSize);

  const handleClass = {
    right: styles["handle__right"],
    left: styles["handle__left"],
    top: styles["handle__top"],
  }[position];

  const MyHandle = (props: any) => {
    const { innerRef, ...rest } = props;

    return (
      <div
        ref={innerRef}
        className={cn(styles.handle, handleClass)}
        {...rest}
      ></div>
    );
  };

  const containerClass = {
    right: styles["container__right"],
    left: styles["container__left"],
    top: styles["container__top"],
  }[position];

  const sizeProps = {
    right: {
      width: size,
      height: Infinity,
      minConstraints: [190, Infinity],
      maxConstraints: [Infinity, Infinity],
      axis: "x",
      resizeHandles: ["e"],
    },
    left: {
      width: size,
      height: Infinity,
      minConstraints: [190, Infinity],
      maxConstraints: [Infinity, Infinity],
      axis: "x",
      resizeHandles: ["w"],
    },
    top: {
      width: Infinity,
      height: size,
      minConstraints: [Infinity, 10],
      maxConstraints: [Infinity, Infinity],
      axis: "y",
      resizeHandles: ["n"],
    },
  }[position];

  return (
    // @ts-expect-error legacy code before eslint
    <ResizableBox
      className={cn(styles.container, containerClass)}
      {...sizeProps}
      onResize={(e, data) => {
        setSize(position === "top" ? data.size.height : data.size.width);
        onResize?.(e, data);
      }}
      handle={(handleAxis, ref) => <MyHandle innerRef={ref} />}
      {...boxProps}
    >
      {children}
    </ResizableBox>
  );
}
