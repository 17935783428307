import styles from "./ModalTabs.module.scss";
import cn from "classnames";

type ModalTabsProps<T> = {
  items: { id: string | number; label: string; value: T }[];
  selectedItem: T;
  setSelectedItem: (value: T) => void;
};

const ModalTabs = <T,>({
  items,
  selectedItem,
  setSelectedItem,
}: ModalTabsProps<T>) => {
  return (
    <div
      className={styles["tabs"]}
      style={{ gridTemplateColumns: Array(items.length).fill("1fr").join(" ") }}
    >
      {items.map((item) => {
        return (
          <button
            key={item.id}
            className={cn(styles["tabs__tab"], {
              [styles["tabs__tab--active"]]: item.value === selectedItem,
            })}
            onClick={() => {
              setSelectedItem(item.value);
            }}
          >
            {item.label}
          </button>
        );
      })}
    </div>
  );
};

export default ModalTabs;
