import sharedStepStyles from "../sharedStepStyles.module.scss";
import Typography from "components/Typography/Typography";
import Loader from "components/common/Loader/Loader";
import newUserWelcomeImage from "assets/images/new-user-welcome.png";

const HEADLINE =
  "Create your first canvas and begin coding your next AI & Data Science project.";

interface WelcomeStepProps {
  onCreate: () => void;
  onClose: () => void;
  isCreatingCanvas: boolean;
  errorCreatingCanvas: boolean;
}

export default function WelcomeStep({
  onCreate,
  onClose,
  isCreatingCanvas,
  errorCreatingCanvas,
}: WelcomeStepProps) {
  return (
    <>
      <div className={sharedStepStyles.message}>
        <Typography className={sharedStepStyles.headline} variant={"h2"}>
          {HEADLINE}
        </Typography>
        <button
          className={sharedStepStyles.button}
          onClick={onCreate}
          disabled={isCreatingCanvas}
        >
          {isCreatingCanvas ? (
            <span className={sharedStepStyles.message__button__loader}>
              <Loader />
            </span>
          ) : (
            "Get Started"
          )}
        </button>
        <div className={sharedStepStyles.helperTextContainer}>
          {errorCreatingCanvas && (
            <Typography
              component="span"
              variant="body2"
              className={sharedStepStyles.error}
            >
              Error creating canvas. Please try again.
            </Typography>
          )}
        </div>
      </div>
      <img src={newUserWelcomeImage} className={sharedStepStyles.image} />
      <div className={sharedStepStyles.skip} onClick={onClose}>
        Skip
      </div>
    </>
  );
}
