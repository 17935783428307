import styles from "./SelfHostingStatusBadge.module.scss";
import { memo } from "react";
import cn from "classnames";
import { EXECUTOR_STATUS } from "config/organizationsConfig";

type SelfHostingStatusBadgeProps = {
  status: EXECUTOR_STATUS;
};

export const SelfHostingStatusBadge = memo(function SelfHostingStatusBadge({
  status,
}: SelfHostingStatusBadgeProps) {
  let label;
  switch (status) {
    case EXECUTOR_STATUS.READY:
      label = "Ready";
      break;
    case EXECUTOR_STATUS.INITIALIZING:
      label = "Initializing";
      break;
    case EXECUTOR_STATUS.DEPLOYING:
      label = "Deploying";
      break;
    case EXECUTOR_STATUS.ERROR:
      label = "Error";
      break;
    default:
      label = "Unknown";
      break;
  }

  return (
    <div
      className={cn(styles.statusBadge, {
        [styles.statusInitializing]: status === EXECUTOR_STATUS.INITIALIZING,
        [styles.statusDeploying]: status === EXECUTOR_STATUS.DEPLOYING,
        [styles.statusReady]: status === EXECUTOR_STATUS.READY,
        [styles.statusError]: status === EXECUTOR_STATUS.ERROR,
      })}
    >
      <span>{label}</span>
    </div>
  );
});
