import { useCallback, useMemo } from "react";
import canvasService from "api/http/canvas-service";
import { CANVAS_PRIVACY } from "config/canvasConfig";

export const useResourceSharingActions = () => {
  const getCanvasPrivacy = useCallback(async (canvasId: string) => {
    try {
      const data = await canvasService.getCanvas(canvasId);
      if (data.is_community) {
        return CANVAS_PRIVACY.COMMUNITY;
      } else if (data.is_public) {
        return CANVAS_PRIVACY.PUBLIC;
      } else {
        return CANVAS_PRIVACY.PRIVATE;
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }, []);

  const updateCanvasPrivacy = useCallback(
    async (canvasId: string, privacy: CANVAS_PRIVACY) => {
      const payload = {
        is_public:
          privacy === CANVAS_PRIVACY.PUBLIC ||
          privacy === CANVAS_PRIVACY.COMMUNITY,
        is_community: privacy === CANVAS_PRIVACY.COMMUNITY,
      };

      try {
        const data = await canvasService.patchCanvas({
          canvasId,
          payload,
        });

        return data;
      } catch (error) {
        return Promise.reject(error);
      }
    },
    []
  );

  return useMemo(
    () => ({
      getCanvasPrivacy,
      updateCanvasPrivacy,
    }),
    [getCanvasPrivacy, updateCanvasPrivacy]
  );
};
