import styles from "./Connections.module.scss";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { useAssetsState } from "store";
import ConnectionForm, {
  notSupportedConnectionTypeBlock,
} from "./components/ConnectionForm";
import { CONNECTION_TYPES } from "./constants";

export function Connections() {
  const { key } = useLocation();

  const {
    data,
    connectionTypes,
    selectedItem: { id: activeItemId },
    createdItem: { assetOperationType },
  } = useAssetsState((slice) => slice.connections);

  if (!activeItemId || assetOperationType === "create") {
    return null;
  }

  const itemData = data[activeItemId];
  // TODO: to review what to show if item was not found
  // keep in mind that we should do it after data is loaded
  if (!itemData) {
    return null;
  }

  const connectionTypeId = itemData.connection.connection_type_id;

  const connectionType = connectionTypeId
    ? connectionTypes[connectionTypeId]?.connection_type
    : null;

  const isSupportedConnectionType =
    connectionType && CONNECTION_TYPES.includes(connectionType);

  if (!isSupportedConnectionType) {
    return notSupportedConnectionTypeBlock;
  }

  return (
    <motion.div
      className={styles.container}
      exit={{ opacity: 0 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.1, type: "spring" }}
    >
      <ConnectionForm
        key={key}
        connectionType={connectionType}
        data={itemData?.connection}
      />
    </motion.div>
  );
}
