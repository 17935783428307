import { useState, useEffect, useMemo, useCallback } from "react";

import {
  getAssetsByUser,
  getAssetsByWorkspaceId,
  updateAssetCanvasMembership,
  deleteAssetFromCanvas,
} from "api/http/assets-service";
import type {
  AssetPageTabType,
  AssetPageTabTypeToDataType,
} from "pages/Assets/types/abstractTypes";
import { useToastsState, useUserState, useAssetsState } from "../store";
import type {
  BaseAssetItemType,
  BaseAssetVersionType,
} from "pages/Assets/types/baseAssetTypes";

const allElement = { id: "1", label: "All", value: "all" };
const types = [
  allElement,
  { id: "2", label: "Canvas", value: "canvases" },
  { id: "3", label: "Environment", value: "environments" },
];

const notFoundState = {
  title: "No environment or canvas found...",
  text: `We're sorry, but we couldn't find any environment or canvas \n that match the name you entered.`,
};

/* created this allElementVersion for compatibility with versions.
Previously allElement was used in prepareAsset return, but it was incompatible with version
(open question: why allElement was mixed with versions, do we really needed this?) */
const allElementVersion = {
  ...allElement,
  canvases: [] as TransformedCanvas[],
  owner: {},
};

type EmptyState = {
  title: string;
  text?: string;
};

interface TransformedCanvas {
  id: string;
  name?: string | null;
  type: string;
  alias: string | null;
  membershipId: string;
  assetVersionNumber: number;
  versions: {
    id: BaseAssetVersionType["id"];
    version: BaseAssetVersionType["version"];
    owner: BaseAssetVersionType["owner"];
  }[];
}

const useAssetUsage = (
  assetType: AssetPageTabType,
  assetId: string,
  versionId?: string
) => {
  const functionsData = useAssetsState(
    (slice) => slice.functions.functionsData
  );
  const constantsData = useAssetsState(
    (slice) => slice.constants.constantsData
  );
  const queriesData = useAssetsState((slice) => slice.queries.queriesData);
  const setConstantsData = useAssetsState(
    (slice) => slice.constantsActions.setConstantsData
  );
  const setFunctionsData = useAssetsState(
    (slice) => slice.functionsActions.setFunctionsData
  );
  const setQueriesData = useAssetsState(
    (slice) => slice.queriesActions.setQueriesData
  );

  const [assets, setAssets] = useState(() => {
    let initialAsset;

    switch (assetType) {
      case "function":
        initialAsset = functionsData;
        break;
      case "constant":
        initialAsset = constantsData;
        break;
      case "query":
        initialAsset = queriesData;
        break;
    }

    return prepareAsset(initialAsset, assetType);
  });

  const [selectedAssetId, setSelectedAssetId] = useState<string>(assetId);
  const [selectedVersionId, setSelectedVersionId] = useState(
    versionId || allElement.id
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [tableData, setTableData] = useState<any>([]);
  const [filteredTableData, setFilteredTableData] = useState<any>([]);
  const [emptyState, setEmptyState] = useState<EmptyState>();
  const [selectedType, setSelectedType] = useState(types[0]);
  const addToast = useToastsState((slice) => slice.addToast);
  const userID = useUserState((slice) => slice.userID);

  const selectedAsset = useMemo(() => {
    return assets.find((el) => el.id === selectedAssetId);
  }, [assets, selectedAssetId]);

  const selectedVersion = useMemo(() => {
    const selected = selectedAsset?.versions.find(
      (el) => el.id === selectedVersionId
    );

    return selected ?? allElementVersion;
  }, [selectedAsset, selectedVersionId]);

  useEffect(() => {
    const searchText = searchQuery.toLocaleLowerCase();
    const filteredData = tableData.filter((entry) => {
      return entry?.name?.toLocaleLowerCase().includes(searchText);
    });

    setFilteredTableData(filteredData);
  }, [searchQuery, tableData]);

  useEffect(() => {
    const clientType =
      selectedType.value === "all" ? "canvases" : selectedType.value;

    if (selectedVersion.id !== allElement.id) {
      setTableData(selectedVersion[clientType]);
    } else {
      const allClients = selectedAsset?.versions.reduce((acc, curr) => {
        if (curr[clientType]) {
          acc = acc.concat(curr[clientType]);
        }
        return acc;
      }, []);
      setTableData(allClients);
    }
  }, [selectedAsset, selectedVersion, selectedType]);

  useEffect(() => {
    if (!selectedAsset?.versions.some((el) => el.id === selectedVersionId)) {
      const prevVersionLabel = assets.reduce((acc, curr) => {
        const prev = curr.versions.find((el) => el.id === selectedVersionId);
        if (prev) {
          acc = prev.label;
        }
        return acc;
      }, "");

      const equalVersionInAnotherAsset = selectedAsset?.versions.find(
        (el) => el.label === prevVersionLabel
      );

      if (equalVersionInAnotherAsset) {
        setSelectedVersionId(equalVersionInAnotherAsset.id);
      } else {
        setSelectedVersionId(allElement.id);
      }
    }
  }, [selectedAssetId, assets]);

  useEffect(() => {
    if (filteredTableData.length === 0) {
      setEmptyState(notFoundState);
      return;
    }

    setEmptyState(undefined);
  }, [tableData, filteredTableData]);

  const getAssets = useCallback((workspaceID, assetType) => {
    if (workspaceID) {
      return getAssetsByWorkspaceId(workspaceID, assetType);
    } else {
      return getAssetsByUser(assetType);
    }
  }, []);

  const updateAssets = (workspaceID?) => {
    if (userID) {
      void getAssets(workspaceID, "function").then(
        (data: Record<string, AssetPageTabTypeToDataType["function"]>) => {
          setFunctionsData(data);
        }
      );
      void getAssets(workspaceID, "constant").then(
        (data: Record<string, AssetPageTabTypeToDataType["constant"]>) => {
          setConstantsData(data);
        }
      );
      void getAssets(workspaceID, "query").then(
        (data: Record<string, AssetPageTabTypeToDataType["query"]>) => {
          setQueriesData(data);
        }
      );
    }
  };

  const updateAttachedVersion = (
    usageId: string,
    type: AssetPageTabType,
    data,
    prevVersionId: string,
    config?: {
      skipErrorMessage: boolean;
    }
  ) => {
    return new Promise<{ success: boolean }>((resolve) => {
      // add logic get this API depending on selected type, when environments are supported
      updateAssetCanvasMembership({
        assetId,
        assetType: type,
        assetVersionId: data.asset_version_id,
        assetMembershipId: data.id,
        alias: data.alias,
        canvasId: usageId,
      })
        .then((res) => {
          const { asset_version_id: nextVersionId } = res;
          // add logic to get this prop depending on selected type, when environments are supported
          const versionUsageProp = "canvases";

          const selectedAssetId = selectedAsset?.id;
          const currentAsset = assets.find(
            (asset) => asset.id === selectedAssetId
          );
          const oldVersion = currentAsset?.versions.find(
            (v) => v.id === prevVersionId
          );
          const nextVersion = currentAsset?.versions.find(
            (v) => v.id === nextVersionId
          );

          if (oldVersion) {
            const usageEl = oldVersion[versionUsageProp].find(
              (el) => el.id === usageId
            );

            if (usageEl) {
              oldVersion[versionUsageProp] = oldVersion[
                versionUsageProp
              ].filter((el) => el.id !== usageId);

              if (nextVersion) {
                usageEl.assetVersionNumber = Object.values(
                  res.canvas_asset_data.versions
                )[0].version;
                nextVersion?.[versionUsageProp].push(usageEl);
              }
            }
          }

          const nextAssets = assets.map((asset) => {
            if (currentAsset && asset.id === selectedAssetId) {
              return {
                ...currentAsset,
                versions: currentAsset?.versions.map((version) => {
                  if (oldVersion && version.id === oldVersion.id) {
                    return { ...oldVersion };
                  }

                  if (nextVersion && version.id === nextVersion.id) {
                    return { ...nextVersion };
                  }

                  return { ...version };
                }),
              };
            }

            return asset;
          });

          setAssets(nextAssets);
          resolve({ success: true });
        })
        .catch((e) => {
          resolve({ success: false });
          console.error(e);
          if (!config?.skipErrorMessage) {
            addToast({
              message: "Failed to update",
              variant: "error",
            });
          }
        });
    });
  };

  const deleteAttachedVersion = (
    usageId: string,
    type: AssetPageTabType,
    versionId: string
  ) => {
    // add logic get this API depending on selected type, when environments are supported
    return deleteAssetFromCanvas({
      assetType: type,
      assetId: selectedAssetId,
      canvasId: usageId,
      assetVersionId: versionId,
    })
      .then((res) => {
        const selectedAssetId = selectedAsset?.id;
        const currentAsset = assets.find(
          (asset) => asset.id === selectedAssetId
        );
        const currentVersion = currentAsset?.versions.find(
          (v) => v.id === versionId
        );
        // add logic to get this prop depending on selected type, when environments are supported
        const versionUsageProp = "canvases";

        if (currentVersion) {
          currentVersion[versionUsageProp] = currentVersion[
            versionUsageProp
          ].filter((el) => el.id !== usageId);
        }

        const nextAssets = assets.map((asset) => {
          if (currentAsset && asset.id === selectedAssetId) {
            return {
              ...currentAsset,
              versions: currentAsset?.versions.map((version) => {
                if (currentVersion && version.id === currentVersion.id) {
                  return { ...currentVersion };
                }

                return { ...version };
              }),
            };
          }

          return asset;
        });

        setAssets(nextAssets);

        return res;
      })
      .catch(() => {
        addToast({
          message: "Something went wrong",
          variant: "error",
        });
      });
  };

  function prepareAsset(
    rawData: Record<
      string,
      AssetPageTabTypeToDataType[keyof AssetPageTabTypeToDataType]
    >,
    type: AssetPageTabType
  ) {
    return Object.values(rawData).map((dataObj) => {
      const { name, id } = dataObj[type] as BaseAssetItemType;

      const allVersions = Object.values(dataObj.versions).map((v) => {
        const { id, owner, version } = v;

        return {
          id,
          version,
          owner,
        };
      });

      const versions = Object.values(dataObj.versions).map(
        (versionObj: BaseAssetVersionType) => {
          const { id, version: label, canvases, owner } = versionObj;

          const transformedCanvases: TransformedCanvas[] = canvases.map(
            (canv) => {
              const {
                id: membershipId,
                canvas_id,
                alias,
                asset_version_number: assetVersionNumber,
                name,
              } = canv;

              return {
                id: canvas_id,
                name,
                type: "Canvas",
                alias,
                membershipId,
                assetVersionNumber,
                versions: allVersions,
              };
            }
          );

          return {
            id,
            owner,
            label: `v.${label}`,
            canvases: transformedCanvases,
            environments: [],
          };
        }
      );

      return { id, label: name, versions: [allElementVersion, ...versions] };
    });
  }

  return {
    selectedAsset,
    selectedVersion,
    tableData: filteredTableData,
    emptyState,
    searchQuery,
    setSearchQuery,
    assets,
    types,
    selectedType,
    setSelectedType,
    updateAttachedVersion,
    deleteAttachedVersion,
    setSelectedAssetId,
    setSelectedVersionId,
    updateAssets,
  };
};

export default useAssetUsage;
