import styles from "./CloudSettingsModal.module.scss";
import { useState, useMemo, useEffect } from "react";
import { BiInfoCircle } from "react-icons/bi";
import { useFlags } from "launchdarkly-react-client-sdk";
import { getGpuInstanceTypes } from "api/http/gpu-instance-service";
import { useCanvasBlocksState, useCanvasState } from "store";
import { type PropsWithModal } from "hooks/useModal";
import Typography from "components/Typography/Typography";
import { ComputeInstanceItem } from "components/ComputeInstanceItem/ComputeInstanceItem";
import ToasterMessage from "components/common/ToasterMessage";
import ModalTabs from "components/common/ModalTabs/ModalTabs";
import { Button } from "components/common/Button/Button";
import { DropdownMenuButton } from "components/common/DropdownMenuButton/DropdownMenuButton";
import type { ContextMenuItem } from "components/common/ContextMenu/ContextMenu";
import { DATA_LOAD_STATUS } from "config/appConfig";
import {
  BLOCK_TYPE,
  COMPUTE_ENV_TYPE,
  MARATHON_SIZE,
  MARATHON_SIZE_DETAILS,
  SAGEMAKER_INSTANCE_TYPE,
  type GpuInstanceType,
} from "config/canvasConfig";
import type { BlockUpdatePayload } from "api/http/blocks-service";
import type { BlockType } from "models/canvas";

type CloudSettingsModalProps = PropsWithModal<{
  block: BlockType;
  handleConfirm: (block_update: BlockUpdatePayload) => void;
}>;

export default function CloudSettingsModal({
  block,
  handleConfirm,
  onModalClose,
}: CloudSettingsModalProps) {
  const flags = useFlags();
  const size = useCanvasBlocksState((slice) => slice.data[block.id]?.size);
  const organizationId = useCanvasState((slice) => slice.organizationId);
  const computeEnvironmentType = useCanvasBlocksState(
    (slice) => slice.data[block.id]?.computeEnvironmentType
  );
  const gpuInstanceType = useCanvasBlocksState(
    (slice) => slice.data[block.id]?.gpuInstanceType
  );

  const [selectedModeTab, setSelectedModeTab] = useState(
    computeEnvironmentType
  );

  const [selectedMarathonSize, setSelectedMarathonSize] =
    useState<MARATHON_SIZE>(size || MARATHON_SIZE.SMALL);

  const [gpuInstanceTypeDetails, setGpuInstanceTypeDetails] = useState<
    GpuInstanceType[]
  >([]);
  const [selectedGpuInstanceType, setSelectedGpuInstanceType] =
    useState<SAGEMAKER_INSTANCE_TYPE>(
      gpuInstanceType || SAGEMAKER_INSTANCE_TYPE.ML_P2_XLARGE
    );

  const [gpuLoadStatus, setGpuLoadStatus] = useState(DATA_LOAD_STATUS.LOADING);

  // GPU options are disabled for non-workspace canvases due to
  // https://linear.app/zerve-ai/issue/FRO-1612/disable-gpu-options-for-non-workspace-canvases
  const gpuTabDisabled = !organizationId;
  // Marathon options are disabled for non-workspace canvases due to
  // https://linear.app/zerve-ai/issue/FRO-1637/disable-marathon-on-options-in-ui-when-a-canvas-is-not-in-a-workspace
  const marathonTabDisabled = !organizationId;

  const tabItems = useMemo(() => {
    const list = [
      { id: 1, label: "Sprint", value: COMPUTE_ENV_TYPE.SPRINT },
      { id: 2, label: "Marathon", value: COMPUTE_ENV_TYPE.MARATHON },
    ];
    if (flags.enableGPUCompute && block.type === BLOCK_TYPE.PYTHON) {
      list.push({
        id: 3,
        label: "GPU",
        value: COMPUTE_ENV_TYPE.GPU,
      });
    }

    return list;
  }, [flags.enableGPUCompute, block.type]);

  const marathonSizeOptions = useMemo(() => {
    const availableSizes = [
      MARATHON_SIZE.SMALL,
      MARATHON_SIZE.MEDIUM,
      MARATHON_SIZE.LARGE,
    ];

    if (organizationId) {
      availableSizes.push(MARATHON_SIZE.XLARGE, MARATHON_SIZE.XXLARGE);
    }

    return availableSizes.map<ContextMenuItem>((size) => {
      const details = MARATHON_SIZE_DETAILS[size];
      return {
        type: "option",
        key: size,
        label: size,
        description: (
          <ComputeInstanceItem cpu={details.cpu} memory={details.memory} />
        ),
        onClick: () => setSelectedMarathonSize(size),
      };
    });
  }, [organizationId]);

  const gpuInstanceTypeOptions = useMemo(() => {
    return gpuInstanceTypeDetails.map<ContextMenuItem>((instanceType) => {
      return {
        type: "option",
        key: instanceType.name,
        label: instanceType.gpu_model,
        description: (
          <ComputeInstanceItem
            cpu={instanceType.cpu}
            memory={instanceType.memory}
            gpuMemory={instanceType.gpu_memory}
          />
        ),
        onClick: () => setSelectedGpuInstanceType(instanceType.name),
      };
    });
  }, [gpuInstanceTypeDetails]);

  const selectedGpuInstanceTypeDetails = useMemo(() => {
    return gpuInstanceTypeDetails.find(
      (instanceType) => instanceType.name === selectedGpuInstanceType
    );
  }, [selectedGpuInstanceType, gpuInstanceTypeDetails]);

  // fetch available GPU instance types
  useEffect(() => {
    getGpuInstanceTypes()
      .then((instanceTypes) => {
        setGpuInstanceTypeDetails(instanceTypes);
        setGpuLoadStatus(DATA_LOAD_STATUS.LOADED);
      })
      .catch(() => {
        setGpuLoadStatus(DATA_LOAD_STATUS.ERROR);
      });
  }, []);

  const onConfirm = () => {
    let blockUpdate: BlockUpdatePayload;
    if (selectedModeTab === COMPUTE_ENV_TYPE.SPRINT) {
      blockUpdate = {
        compute_environment_type: COMPUTE_ENV_TYPE.SPRINT,
      };
    } else if (selectedModeTab === COMPUTE_ENV_TYPE.MARATHON) {
      blockUpdate = {
        compute_environment_type: COMPUTE_ENV_TYPE.MARATHON,
        size: selectedMarathonSize,
      };
    } else {
      blockUpdate = {
        compute_environment_type: COMPUTE_ENV_TYPE.GPU,
        gpu_instance_type: selectedGpuInstanceType,
      };
    }
    handleConfirm(blockUpdate);
    onModalClose();
  };

  const descriptonBlock = (text: string) => {
    return (
      <div className={styles.description}>
        <BiInfoCircle size={20} className={styles.icon} />
        <p className={styles.text}>{text}</p>
      </div>
    );
  };

  const buttonsBlock = (
    <div className={styles.buttons}>
      <Button variant="secondary" size="large" onClick={onModalClose}>
        Cancel
      </Button>
      <Button
        variant="primary"
        size="large"
        onClick={onConfirm}
        disabled={
          (selectedModeTab === COMPUTE_ENV_TYPE.GPU &&
            (gpuLoadStatus !== DATA_LOAD_STATUS.LOADED || gpuTabDisabled)) ||
          (selectedModeTab === COMPUTE_ENV_TYPE.MARATHON && marathonTabDisabled)
        }
      >
        Confirm
      </Button>
    </div>
  );

  return (
    <>
      <ModalTabs
        items={tabItems}
        selectedItem={selectedModeTab}
        setSelectedItem={setSelectedModeTab}
      />

      {/* SPRINT */}
      {selectedModeTab === COMPUTE_ENV_TYPE.SPRINT && (
        <section className={styles.sprint}>
          {descriptonBlock(
            "Sprint mode utilizes functions to execute the code without creating containers. Startup is faster, but is limited to 15 minutes of runtime."
          )}

          <ToasterMessage
            fullWidth
            variant="warning"
            animate={false}
            className={styles.warning}
          >
            Sprint mode is limited to 15 minutes of runtime.
          </ToasterMessage>

          <div className={styles.resources}>
            <div className={styles.resource}>
              <div className={styles.value}>1</div>
              <div className={styles.label}>CPU</div>
            </div>
            <div className={styles.resource}>
              <div className={styles.value}>10</div>
              <div className={styles.label}>Memory (GB)</div>
            </div>
          </div>
          {buttonsBlock}
        </section>
      )}

      {/* MARATHON */}
      {selectedModeTab === COMPUTE_ENV_TYPE.MARATHON && (
        <section className={styles.marathon}>
          {descriptonBlock(
            "Marathon mode creates a container with an unlimited runtime, but takes slightly longer to setup."
          )}

          {marathonTabDisabled ? (
            <ToasterMessage
              fullWidth
              variant="warning"
              animate={false}
              className={styles.warning}
            >
              Available soon on the Free Tier
            </ToasterMessage>
          ) : null}

          <Typography variant="h3" className={styles.title}>
            Instance Type
          </Typography>

          <DropdownMenuButton
            buttonSize="large"
            placement="bottom"
            nodesClassNames={{
              container: styles.dropdownContainer,
              button: styles.dropdownButton,
              contextMenu: styles.dropdownContextMenu,
              contextMenuContainer: styles.dropdownContextMenuContainer,
            }}
            menuOptions={marathonSizeOptions}
            disabled={marathonTabDisabled}
            text={
              MARATHON_SIZE_DETAILS[selectedMarathonSize] ? (
                <ComputeInstanceItem
                  name={selectedMarathonSize}
                  cpu={MARATHON_SIZE_DETAILS[selectedMarathonSize].cpu}
                  memory={MARATHON_SIZE_DETAILS[selectedMarathonSize].memory}
                />
              ) : (
                "Select marathon size..."
              )
            }
          />

          {buttonsBlock}
        </section>
      )}

      {/* GPU */}
      {selectedModeTab === COMPUTE_ENV_TYPE.GPU && (
        <section className={styles.gpu}>
          {descriptonBlock("GPU mode runs the code on a GPU-enabled compute.")}

          <ToasterMessage
            fullWidth
            variant="warning"
            animate={false}
            className={styles.warning}
          >
            {!gpuTabDisabled
              ? "This feature is in beta and subject to running time and compute availability limitations. Pytorch, tensorflow, and huggingface packages are pre-installed."
              : "Available soon on the Free Tier"}
          </ToasterMessage>

          <Typography variant="h3" className={styles.title}>
            Instance Type
          </Typography>

          {gpuLoadStatus === DATA_LOAD_STATUS.ERROR ? (
            <ToasterMessage
              fullWidth
              variant="error"
              animate={false}
              className={styles.error_message}
            >
              Failed to load instance types, please try again or contact support
            </ToasterMessage>
          ) : (
            <DropdownMenuButton
              buttonSize="large"
              placement="bottom"
              nodesClassNames={{
                container: styles.dropdownContainer,
                button: styles.dropdownButton,
                contextMenu: styles.dropdownContextMenu,
                contextMenuContainer: styles.dropdownContextMenuContainer,
              }}
              menuOptions={gpuInstanceTypeOptions}
              disabled={
                gpuTabDisabled || gpuLoadStatus !== DATA_LOAD_STATUS.LOADED
              }
              text={
                gpuLoadStatus === DATA_LOAD_STATUS.LOADED ? (
                  selectedGpuInstanceTypeDetails ? (
                    <ComputeInstanceItem
                      name={selectedGpuInstanceTypeDetails.gpu_model}
                      cpu={selectedGpuInstanceTypeDetails.cpu}
                      memory={selectedGpuInstanceTypeDetails.memory}
                      gpuMemory={selectedGpuInstanceTypeDetails.gpu_memory}
                    />
                  ) : (
                    "Select instance type..."
                  )
                ) : (
                  "Loading..."
                )
              }
            />
          )}

          {buttonsBlock}
        </section>
      )}
    </>
  );
}
