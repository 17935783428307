/*
This component is used to display the onboarding modal that prompts the user to create their first canvas
it will be displayed when the user logs in for the first time and is not being directed to a canvas which was shared with them
In the future it would be nice to add more onboarding screens to do things like:
- creating a team
- inviting team members
- etc.
*/

import { useMemo, useCallback, useState } from "react";
import { NewUserWelcomeModal } from "components/NewUserWelcomeModal/NewUserWelcomeModal";
import { useUserState } from "store";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import canvasService from "api/http/canvas-service";
import { useAuth0 } from "@auth0/auth0-react";

export function OnBoarding() {
  const { user } = useAuth0();

  const { userID, isNewUser, onboardedUser, setOnboardedUser } = useUserState(
    (state) => ({
      userID: state.userID,
      isNewUser: state.isNewUser,
      onboardedUser: state.onboardedUser,
      setOnboardedUser: state.setOnboardedUser,
    })
  );

  const location = useLocation();
  const navigate = useNavigate();

  // check if the user is directed to a canvas
  const isDirectedToCanvas = useMemo(() => {
    return location.pathname.startsWith("/canvas/");
  }, []);

  const [isOpen, setIsOpen] = useState<boolean>(
    isNewUser && !isDirectedToCanvas && !onboardedUser
  );
  const [isCreatingCanvas, setIsCreatingCanvas] = useState<boolean>(false);
  const [errorCreatingCanvas, setErrorCreatingCanvas] =
    useState<boolean>(false);

  const handleCreate = useCallback(() => {
    const canvasName = user?.given_name
      ? user.given_name + "'s First Canvas"
      : "My First Canvas";
    setIsCreatingCanvas(true);
    setErrorCreatingCanvas(false);
    canvasService
      .postCreateCanvas({
        user_id: userID || "",
        name: canvasName,
        introductory_canvas: true,
      })
      .then((returnedValues) => {
        const { id = "" }: { id: string } = returnedValues;
        navigate(`/canvas/${id}`);
      })
      .then(() => {
        setOnboardedUser(true);
        setIsOpen(false);
      })
      .catch(() => {
        setErrorCreatingCanvas(true);
      })
      .finally(() => {
        setIsCreatingCanvas(false);
      });
  }, [userID, user, setIsOpen]);

  const handleClose = useCallback(() => {
    setOnboardedUser(true);
    setIsOpen(false);
  }, []);

  return (
    <>
      {isOpen && (
        <NewUserWelcomeModal
          onCreate={handleCreate}
          onClose={handleClose}
          isCreatingCanvas={isCreatingCanvas}
          errorCreatingCanvas={errorCreatingCanvas}
        />
      )}
      <Outlet />
    </>
  );
}
