import styles from "./PublishedReportModal.module.scss";
import { useEffect, useState, useCallback } from "react";
import {
  getPublishedReportByBlockId,
  deletePublishedReport,
} from "api/http/canvas-report-service";
import { useToastsState } from "store";
import { useConfirmModal } from "hooks/useConfirmModal";
import { type PropsWithModal } from "hooks/useModal";
import { DATA_LOAD_STATUS } from "config/appConfig";
import { debouncePromise } from "utils/helpers";
import { EmptyState } from "components/common/EmptyState";
import Loader from "components/common/Loader/Loader";
import { Button } from "components/common/Button/Button";
import { PublishedReportForm } from "components/PublishedReport/PublishedReportForm/PublishedReportForm";
import type { BlockType } from "models/canvas";
import type { PublishedReport } from "models/publishedReport";

type PublishedReportModal = PropsWithModal<{
  blockId: BlockType["id"];
}>;

export function PublishedReportModal({
  blockId,
  onModalClose,
}: PublishedReportModal) {
  const addToast = useToastsState((slice) => slice.addToast);

  const { openConfirmModal } = useConfirmModal();

  const [reportLoadingStatus, setReportLoadingStatus] = useState(
    DATA_LOAD_STATUS.LOADING
  );
  const [publishedReportData, setPublishedReportData] =
    useState<PublishedReport | null>(null);

  const handleFetchPublishedReport = useCallback((id: BlockType["id"]) => {
    setReportLoadingStatus(DATA_LOAD_STATUS.LOADING);
    debouncePromise({ promise: getPublishedReportByBlockId(id) })
      .then((data) => {
        setPublishedReportData(data);
        setReportLoadingStatus(DATA_LOAD_STATUS.LOADED);
      }) // if 404, set data to null
      .catch((response) => {
        if (response?.cause?.status === 404) {
          setPublishedReportData(null);
          setReportLoadingStatus(DATA_LOAD_STATUS.LOADED);
          return;
        }
        setReportLoadingStatus(DATA_LOAD_STATUS.ERROR);
      });
  }, []);

  // fetch report data on init
  useEffect(() => {
    handleFetchPublishedReport(blockId);
  }, [blockId, handleFetchPublishedReport]);

  const handleDelete = useCallback(async () => {
    if (!publishedReportData) {
      return;
    }
    const confirmed = await openConfirmModal({
      message: (
        <>
          Are you sure you want to delete the report{" "}
          <strong>{publishedReportData.name}</strong>?
        </>
      ),
      confirmButtonLabel: "Delete",
      confirmButtonVariant: "crucial",
    });

    if (!confirmed) {
      return;
    }

    // delete
    try {
      onModalClose();
      await deletePublishedReport(publishedReportData.id);
      addToast({
        message: "Report successfully deleted",
        variant: "success",
      });
    } catch (err) {
      addToast({
        message: "Failed to delete the report",
        variant: "error",
      });
    }
  }, [publishedReportData, onModalClose]);

  return (
    <div className={styles.container}>
      {/* Loader */}
      {reportLoadingStatus === DATA_LOAD_STATUS.NOT_LOADED ||
      reportLoadingStatus === DATA_LOAD_STATUS.LOADING ? (
        <div className={styles.loaderContainer}>
          <Loader size={16} />
          <span>Loading...</span>
        </div>
      ) : null}

      {/* Error */}
      {reportLoadingStatus === DATA_LOAD_STATUS.ERROR ? (
        <EmptyState
          variant="error"
          title="Failed to load the report data"
          description="Try again or contact support."
          containerClassName={styles.errorEmptyState}
        >
          <Button
            variant="outline"
            onClick={() => {
              handleFetchPublishedReport(blockId);
            }}
          >
            Retry
          </Button>
        </EmptyState>
      ) : null}

      {/* Report Form */}
      {reportLoadingStatus === DATA_LOAD_STATUS.LOADED ? (
        <PublishedReportForm
          blockId={blockId}
          data={publishedReportData}
          onSubmitSuccess={onModalClose}
          onCancel={onModalClose}
          onDelete={handleDelete}
        />
      ) : null}
    </div>
  );
}
