import { type FC, useMemo } from "react";
import {
  BiArchiveOut,
  BiCaretDown,
  BiCaretRight,
  BiTrash,
} from "react-icons/bi";
import cn from "classnames";
import { useNavigate, useParams } from "react-router-dom";
import { useUserState } from "store";
import { useAssetsData } from "hooks/useAssetsData";
import RowButtons from "components/common/RowButtons/RowButtons";
import Typography from "components/Typography/Typography";
import { useConfirmModal } from "hooks/useConfirmModal";
import { LABEL, MESSAGE } from "pages/Assets/config";
import { getAssetsBaseUrl } from "pages/Assets/utils";
import type {
  AbstractDataTypeForTab,
  AssetPageTabType,
} from "../../types/abstractTypes";
import AssetsNavVersionItem from "../AssetsNavVersionItem";
import AssetsNavItemDropdown from "../AssetsNavItemDropdown";
import { AssetPageTabUrl } from "../../types/abstractTypes";
import EditableAssetNavItem from "../EditableAssetNavItem/EditableAssetNavItem";
import styles from "./AssetsNavItem.module.scss";

type AssetsNavItemProps<T extends AssetPageTabType> =
  AbstractDataTypeForTab<T> & {
    assetType: T;
  };

const AssetsNavItem: FC<AssetsNavItemProps<AssetPageTabType>> = ({
  versions,
  assetType,
  ...restProps
}: AssetsNavItemProps<AssetPageTabType>) => {
  const userID = useUserState((slice) => slice.userID);
  const navigate = useNavigate();
  const { openConfirmModal } = useConfirmModal();
  const { orgID, workspaceID } = useParams();
  const {
    getCurrentAssetItemsData,
    getCurrentAssetItemActions,
    getCreatedAssetItemData,
    getCreatedAssetItemActions,
  } = useAssetsData();
  const { activeItemId, isDisplayArchivedItems } =
    getCurrentAssetItemsData(assetType);
  const {
    assetOperationType,
    createdAssetItemData: { id: createdAssetItemId },
  } = getCreatedAssetItemData(assetType);
  const {
    setActiveItemId,
    setActiveVersionNumber,
    clearActiveItemData,
    setAssetIsArchived,
    deleteAssetItem,
  } = getCurrentAssetItemActions(assetType);
  const { setAssetItemOperationType, setCreatedAssetItemData } =
    getCreatedAssetItemActions(assetType);
  const { id: assetId, name: assetName } = restProps[assetType];
  const isArchived = restProps[assetType].archive;
  const isActive = assetId === activeItemId;

  const ownerId = restProps[assetType]?.owner?.id;
  const isAssetOwner = !!userID && !!ownerId && userID === ownerId;

  const baseURL = useMemo(() => {
    return getAssetsBaseUrl({
      organizationId: orgID,
      workspaceId: workspaceID,
    });
  }, [orgID, workspaceID]);

  const icon = useMemo(
    () =>
      isActive ? (
        <BiCaretDown
          className={cn(
            styles.dropdownArrow,
            isActive && !isArchived ? styles.activeColor : styles.inActiveColor
          )}
        />
      ) : (
        <BiCaretRight
          className={cn(styles.dropdownArrow, styles.inActiveColor, {
            [styles.archived]: isArchived,
          })}
        />
      ),
    [isActive, isArchived]
  );

  const filterOutArchivedVersions = (
    versions: AbstractDataTypeForTab<AssetPageTabType>["versions"]
  ) =>
    Object.fromEntries(
      Object.entries(versions).filter(([, obj]) => !obj.archive)
    );

  const handleItemClick = (): void => {
    if (assetOperationType === "create" || assetOperationType === "editName") {
      return;
    }
    if (isActive) {
      clearActiveItemData();
      navigate(`${baseURL}/${AssetPageTabUrl[assetType]}`);
      return;
    }
    const assetVersions = isDisplayArchivedItems
      ? versions
      : filterOutArchivedVersions(versions);
    clearActiveItemData();
    const lastVersion = Object.values(assetVersions).sort(
      (a, b) => b.version - a.version
    )[0];
    setActiveItemId(assetId);
    setActiveVersionNumber(lastVersion.version);
    navigate(
      `${baseURL}/${AssetPageTabUrl[assetType]}/${lastVersion.id as string}`
    );
  };

  const handleUnarchive = (): void => {
    setAssetIsArchived(assetId, false);
  };

  const handleDelete = async (e) => {
    e.stopPropagation();
    const confirmed = await openConfirmModal({
      title: MESSAGE.deleteAsset,
      message: MESSAGE.assetDeleteConfirm,
      confirmButtonLabel: LABEL.deleteConfirmButton,
      confirmButtonVariant: "crucial",
    });

    if (confirmed) {
      deleteAssetItem(assetId);
    }
  };

  const setEditableItemData = (e) => {
    e.stopPropagation();

    if (
      isArchived ||
      assetOperationType === "editName" ||
      assetOperationType === "create" ||
      !isAssetOwner
    ) {
      return;
    }

    setAssetItemOperationType("editName");
    setCreatedAssetItemData({
      name: assetName ?? "",
      id: assetId,
    });
  };

  if (!isDisplayArchivedItems && isArchived) {
    return null;
  }

  if (assetOperationType === "editName" && assetId === createdAssetItemId) {
    return (
      <>
        <EditableAssetNavItem assetType={assetType} />
        {isActive && (
          <div>
            {Object.values(versions).map((versionData) => (
              <AssetsNavVersionItem
                {...versionData}
                assetType={assetType}
                key={versionData.id}
                disabled
                baseURL={baseURL}
                isWorkspaceAsset={!!workspaceID}
              />
            ))}
          </div>
        )}
      </>
    );
  }

  const actionsDisabled =
    assetOperationType === "create" || assetOperationType === "editName";

  return (
    <>
      <div
        className={cn(styles.container, {
          [styles.containerActionsDisabled]: actionsDisabled,
        })}
      >
        <div className={styles.titleWrapper} onClick={handleItemClick}>
          <div className={styles.iconAndText}>
            <div className={styles.icon}>{icon}</div>
            <div className={styles.text} onDoubleClick={setEditableItemData}>
              <Typography
                variant="caption1"
                className={cn(styles.functionTitle, {
                  [styles.archived]: isArchived,
                })}
              >
                {assetName ?? ""}
              </Typography>
            </div>
          </div>

          {isActive && !isArchived && (
            <AssetsNavItemDropdown assetType={assetType} assetId={assetId} />
          )}
          {isArchived && isAssetOwner ? (
            <RowButtons
              className={styles.rowButtons}
              buttons={[
                {
                  icon: BiArchiveOut,
                  tooltip: "Unarchive",
                  onClick: handleUnarchive,
                },
                {
                  icon: BiTrash,
                  tooltip: "Delete",
                  onClick: handleDelete,
                },
              ]}
              size="medium"
            />
          ) : null}
        </div>
      </div>
      {isActive ? (
        <div>
          {Object.values(versions).map((versionData) => (
            <AssetsNavVersionItem
              {...versionData}
              assetType={assetType}
              key={versionData.id}
              baseURL={baseURL}
              workspaceId={workspaceID}
            />
          ))}
        </div>
      ) : null}
    </>
  );
};

export default AssetsNavItem;
