import { ConnectionsIcon } from "./components/icons";
import { CONNECTION_TYPE_CONFIG } from "./constants";

export const getConnectionTypeIcon = (connection_type: string) => {
  if (!connection_type) {
    return ConnectionsIcon;
  }

  const icon = CONNECTION_TYPE_CONFIG[connection_type]?.icon ?? ConnectionsIcon;

  return icon;
};
