import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, Outlet } from "react-router-dom";
import {
  BiShapeSquare,
  BiAnalyse,
  BiLibrary,
  BiUserPlus,
  BiGroup,
  BiCog,
  BiWindowAlt,
  BiSolidReport,
} from "react-icons/bi";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useUserState, useToastsState } from "store";
import { getOrganization } from "api/http/organization-service";
import { getWorkspace } from "api/http/workspace-service";
import {
  checkWorkspacePermission,
  CANVASES,
  ENVIRONMENTS,
} from "api/http/permission-service";
import { NavigationTabs } from "components/common/NavigationTabs/NavigationTabs";
import { type NavigationTabsItem } from "components/common/NavigationTabs/NavigationTabs";
import type { Organization, Workspace } from "models/organization";

export function WorkspaceTabs() {
  const userID = useUserState((slice) => slice.userID);
  const addToast = useToastsState((slice) => slice.addToast);
  const navigate = useNavigate();
  const { orgID = "", workspaceID = "" } = useParams();
  const { enableEnvironments, enableAppDeployments } = useFlags();
  const [organization, setOrganization] = useState<Organization>();
  const [workspace, setWorkspace] = useState<Workspace>();
  const [canvasesPermission, setCanvasesPermission] = useState(false);
  const [environmentsPermission, setEnvironmentsPermission] = useState(false);

  // Fetch organization members and workspace members when the component loads
  useEffect(() => {
    async function initializeWorkspace() {
      try {
        const [organizationResult, workspaceResult] = await Promise.all([
          getOrganization(orgID),
          getWorkspace(orgID, workspaceID),
        ]);
        setOrganization(organizationResult);
        setWorkspace(workspaceResult);
      } catch (error) {
        addToast({
          message: "Could not find organization or workspace.",
          variant: "error",
        });
        // if we can't load the organization or workspace, navigate to home.
        navigate("/");
        return;
      }
      const hasCanvasesPermission = await checkWorkspacePermission(
        workspaceID,
        CANVASES,
        "read"
      );
      setCanvasesPermission(hasCanvasesPermission);
      if (!enableEnvironments) {
        const hasEnvironmentsPermission = await checkWorkspacePermission(
          workspaceID,
          ENVIRONMENTS,
          "read"
        );
        setEnvironmentsPermission(hasEnvironmentsPermission);
      }
    }
    if (userID && workspaceID) {
      initializeWorkspace();
    }
  }, [userID, orgID, workspaceID]);

  const isAdmin = useMemo(() => {
    return (
      !!organization &&
      !!workspace &&
      organization.role === "Organization Admin" && // TODO: use a proper key.
      workspace.is_admin
    );
  }, [organization, workspace]);

  const tabs = useMemo(() => {
    const list: NavigationTabsItem[] = [];

    if (!organization || !workspace) {
      return list;
    }

    if (canvasesPermission) {
      list.push({
        key: "canvases",
        label: (
          <>
            <BiShapeSquare size={20} />
            <span>Canvases</span>
          </>
        ),
        to: `/org/${organization.id}/workspaces/${workspace.id}/canvases`,
      });
    }

    if (enableAppDeployments) {
      list.push({
        key: "apps",
        label: (
          <>
            <BiWindowAlt size={20} />
            <span>Hosted Apps</span>
          </>
        ),
        to: `/org/${organization.id}/workspaces/${workspace.id}/apps`,
      });
    }

    list.push({
      key: "reports",
      label: (
        <>
          <BiSolidReport size={20} />
          <span>Reports</span>
        </>
      ),
      to: `/org/${organization.id}/workspaces/${workspace.id}/reports`,
    });

    if (enableEnvironments && environmentsPermission) {
      list.push({
        key: "environments",
        label: (
          <>
            <BiAnalyse size={20} />
            <span>Environments</span>
          </>
        ),
        to: `/org/${organization.id}/workspaces/${workspace.id}/environments`,
      });
    }

    list.push(
      {
        key: "assets",
        label: (
          <>
            <BiLibrary size={20} />
            <span>Assets</span>
          </>
        ),
        to: `/org/${organization.id}/workspaces/${workspace.id}/assets`,
      },
      {
        key: "members",
        label: (
          <>
            <BiUserPlus size={20} />
            <span>Members</span>
          </>
        ),
        to: `/org/${organization.id}/workspaces/${workspace.id}/members`,
      },
      {
        key: "group-members",
        label: (
          <>
            <BiGroup size={20} />
            <span>Group Members</span>
          </>
        ),
        to: `/org/${organization.id}/workspaces/${workspace.id}/group_members`,
      }
    );

    if (isAdmin) {
      list.push({
        key: "settings",
        label: (
          <>
            <BiCog size={20} />
            <span>Settings</span>
          </>
        ),
        to: `/org/${organization.id}/workspaces/${workspace.id}/settings`,
      });
    }

    return list;
  }, [
    organization,
    workspace,
    isAdmin,
    enableEnvironments,
    canvasesPermission,
    environmentsPermission,
  ]);

  return (
    <>
      <NavigationTabs orientation="horizontal" items={tabs} />
      <Outlet />
    </>
  );
}
