import { useRef, useCallback, useMemo, type ReactNode } from "react";
import { useModal } from "hooks/useModal";
import { ConfirmModal } from "components/common/ConfirmModal/ConfirmModal";
import { type ModalProps } from "components/common/Modal/Modal";
import { type ConfirmModalProps } from "components/common/ConfirmModal/ConfirmModal";

export function useConfirmModal() {
  const resolveRef = useRef<((confirmed: boolean) => void) | null>(null);
  const { openModal, closeModal } = useModal();

  const handleConfirm = useCallback(() => {
    resolveRef.current?.(true);
    resolveRef.current = null;
    closeModal();
  }, []);

  const handleCancel = useCallback(() => {
    resolveRef.current?.(false);
    resolveRef.current = null;
    closeModal();
  }, []);

  const openConfirmModal = useCallback(
    async ({
      title = "Are you sure?",
      message,
      classes,
      cancelButtonVariant,
      confirmButtonVariant,
      cancelButtonLabel,
      confirmButtonLabel,
      dontAskAgain,
      closeOnLocationChange,
      onSetDontAskAgain,
    }: {
      title?: ModalProps["title"];
      message: ReactNode;
      classes?: ModalProps["classes"];
      cancelButtonVariant?: ConfirmModalProps["cancelButtonVariant"];
      confirmButtonVariant?: ConfirmModalProps["confirmButtonVariant"];
      cancelButtonLabel?: ConfirmModalProps["cancelButtonLabel"];
      confirmButtonLabel?: ConfirmModalProps["confirmButtonLabel"];
      dontAskAgain?: boolean;
      closeOnLocationChange?: ModalProps["closeOnLocationChange"];
      onSetDontAskAgain?: ConfirmModalProps["onSetDontAskAgain"];
    }) => {
      if (dontAskAgain) {
        // assume user has already confirmed the deletion
        return true;
      }
      const confirmed = await new Promise<boolean>((resolve) => {
        resolveRef.current = resolve;
        openModal({
          title,
          classes,
          closeOnLocationChange,
          content: ({ onModalClose }) => {
            return (
              <ConfirmModal
                cancelButtonVariant={cancelButtonVariant}
                confirmButtonVariant={confirmButtonVariant}
                cancelButtonLabel={cancelButtonLabel}
                confirmButtonLabel={confirmButtonLabel}
                onCancel={handleCancel}
                onConfirm={handleConfirm}
                onSetDontAskAgain={onSetDontAskAgain}
                onModalClose={onModalClose}
              >
                {message}
              </ConfirmModal>
            );
          },
          onClose: handleCancel,
        });
      });

      return confirmed;
    },
    []
  );

  const closeConfirmModal = useCallback(() => {
    closeModal();
  }, []);

  return useMemo(() => {
    return { openConfirmModal, closeConfirmModal };
  }, [openConfirmModal, closeConfirmModal]);
}
