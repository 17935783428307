import { CANVAS_API_URL, type AI_MODEL } from "config/appConfig";
import { type CANVAS_PERMISSION } from "config/canvasConfig";
import { callAPI } from "./call-api";
import type {
  CanvasResponseType,
  LayerType,
  UploadFileS3PresignedInfo,
  UserApiType,
  ConnectedUserApiResponse,
  CanvasExecutorLog,
} from "models/canvas";
import type { AppResponseType } from "models/app";
import type { CanvasBranchItemTypeBase } from "models/github-integration";

const getCanvas = async (canvas_id: string) =>
  await callAPI<CanvasResponseType>({
    url: `${CANVAS_API_URL}/canvas/${canvas_id}`,
    method: "GET",
  });

const getApp = async (app_id: string) =>
  await callAPI<AppResponseType>({
    url: `${CANVAS_API_URL}/apps/${app_id}`,
    method: "GET",
  });

const getUserCanvases = async () =>
  await callAPI<CanvasResponseType[]>({
    url: `${CANVAS_API_URL}/canvas/user/all`,
    method: "GET",
  });

const getOwnedCanvases = async () =>
  await callAPI<CanvasResponseType[]>({
    url: `${CANVAS_API_URL}/canvas/user/owned`,
    method: "GET",
  });

const getStarredCanvases = async () =>
  await callAPI<CanvasResponseType[]>({
    url: `${CANVAS_API_URL}/canvas/user/starred`,
    method: "GET",
  });

const getCommunityCanvases = async () =>
  await callAPI<CanvasResponseType[]>({
    url: `${CANVAS_API_URL}/canvas/user/community`,
    method: "GET",
  });

const getSharedCanvases = async () =>
  await callAPI<CanvasResponseType[]>({
    url: `${CANVAS_API_URL}/canvas/user/shared`,
    method: "GET",
  });

const getWorkspaceCanvases = async (workspaceId: string) =>
  await callAPI({
    url: `${CANVAS_API_URL}/canvas/workspace/${workspaceId}`,
    method: "GET",
  });

const getWorkspaceApps = async (workspaceId: string) =>
  await callAPI({
    url: `${CANVAS_API_URL}/apps/workspace/${workspaceId}`,
    method: "GET",
  });

const postCanvasByProjectIDs = async (project_ids: string[]) =>
  await callAPI<CanvasResponseType[]>({
    url: `${CANVAS_API_URL}/canvas/get_by_project_id`,
    method: "POST",

    data: project_ids,
  });

const putRenameCanvas = async (canvas_id: string, canvas_name: string) =>
  await callAPI({
    url: `${CANVAS_API_URL}/canvas/${canvas_id}`,
    method: "PUT",

    data: { canvas_name },
  });

const putRenameApp = async (app_id: string, app_name: string) =>
  await callAPI({
    url: `${CANVAS_API_URL}/apps/${app_id}`,
    method: "PATCH",

    data: { name: app_name },
  });

export type patchUpdateApp =
  | {
      name?: string;
    }
  | {
      dns_name?: string;
    }
  | {
      s3_path?: string | null;
    }
  | {
      requirements?: string[];
    }
  | {
      python_script_name?: string;
    };

async function patchUpdateApp({
  appID,
  payload,
}: {
  appID: string;
  payload: patchUpdateApp;
}) {
  return await callAPI<AppResponseType>({
    method: "PATCH",
    url: `${CANVAS_API_URL}/apps/${appID}`,
    data: payload,
  });
}

interface PostCreateCanvasParams {
  user_id: string;
  name?: string;
  description?: string;
  workspace_id?: string;
  introductory_canvas?: boolean;
  skip_default_block?: boolean;
}

const postCreateCanvas = async ({
  user_id,
  name,
  description,
  workspace_id,
  introductory_canvas = false,
  skip_default_block = true,
}: PostCreateCanvasParams) =>
  await callAPI({
    url: `${CANVAS_API_URL}/canvas/`,
    method: "POST",

    data: {
      user_id,
      name,
      description,
      ...(workspace_id && { workspace_id }),
      introductory_canvas,
      skip_default_block,
    },
  });

const deleteCanvasById = async (canvas_id: string) =>
  await callAPI({
    url: `${CANVAS_API_URL}/canvas/${canvas_id}`,
    method: "DELETE",
  });

const deleteCanvasesByIds = async (canvas_ids: string[]) =>
  await callAPI({
    url: `${CANVAS_API_URL}/canvas/canvases`,
    method: "DELETE",
    data: { canvas_ids },
  });

const deleteAppsByIds = async (app_ids: string[]) =>
  await callAPI({
    url: `${CANVAS_API_URL}/apps/`,
    method: "DELETE",
    data: { app_ids },
  });

const getCanvasLayout = async (canvas_id: string) =>
  await callAPI<
    Record<
      LayerType["id"],
      { blocks: any[]; edges: any[]; user_api: UserApiType | null }
    > & {
      canvas: {
        id: string;
        project_id: string;
        organization_id: string | null;
        workspace_id: string | null;
        is_public: boolean;
        name: string;
        time_created: string;
        time_updated: string | null;
        python_global_imports: string;
        r_global_imports: string;
        canvas_branch?: CanvasBranchItemTypeBase;
        layers: LayerType[];
        included_repos: string[];
        fleet_id: string | null;
        slice_ids: string[];
      };
      connected_users: ConnectedUserApiResponse[];
      permission: CANVAS_PERMISSION;
    }
  >({
    url: `${CANVAS_API_URL}/canvas/canvas_layout/${canvas_id}`,
    method: "GET",
  });

const getLayerLayout = async (layer_id: string) =>
  await callAPI({
    url: `${CANVAS_API_URL}/canvas/layout/${layer_id}`,
    method: "GET",
  });

const deleteAppFile = async (
  organization_id: string,
  app_id: string,
  file_name: string
) => {
  return await callAPI({
    url: `${CANVAS_API_URL}/apps/files/${organization_id}/${app_id}/${file_name}`,
    method: "DELETE",
  });
};

const deployApp = async (app_id: string) => {
  return await callAPI({
    url: `${CANVAS_API_URL}/apps/deploy_app/${app_id}`,
    method: "POST",
  });
};

const deleteAppService = async (app_id: string) => {
  return await callAPI({
    url: `${CANVAS_API_URL}/apps/delete_app_service/${app_id}`,
    method: "DELETE",
  });
};

const getPythonGlobalImports = async (canvasId: string) =>
  await callAPI<string>({
    url: `${CANVAS_API_URL}/canvas/global_imports_by_language/${canvasId}/python`,
    method: "GET",
  });

const getRGlobalImports = async (canvasId: string) =>
  await callAPI<string>({
    url: `${CANVAS_API_URL}/canvas/global_imports_by_language/${canvasId}/r`,
    method: "GET",
  });

const getUploadAppFileToS3PresignedInfo = async (
  organization_id: string,
  app_id: string,
  file_name: string
) => {
  return await callAPI<UploadFileS3PresignedInfo>({
    url: `${CANVAS_API_URL}/apps/files/${organization_id}/${app_id}`,
    method: "POST",
    data: { file_name },
  });
};

const uploadKeyImage = async (canvasID: string, blob: any) => {
  const file_name = ".zerve-meta/key_image.png";
  return await callAPI({
    url: `${CANVAS_API_URL}/canvas/files/${canvasID}`,
    method: "POST",

    data: { file_name },
  }).then(async (response) => {
    const url = response.url;
    const xhr = new XMLHttpRequest();
    const formData = new FormData();
    // add all key value pairs from response.fields
    Object.keys(response.fields).forEach((key) => {
      formData.append(key, response.fields[key]);
    });
    // add the file
    formData.append("file", blob);

    xhr.open("POST", url, true);
    // xhr.setRequestHeader('Content-Type', 'multipart/form-data'); //file.type
    xhr.onerror = () => {
      console.log("XHR error");
    };
    xhr.send(formData);
    return response;
  });
};

const getCanvasKeyImage = async (canvas_id: string) =>
  await callAPI({
    url: `${CANVAS_API_URL}/canvas/files/${canvas_id}/.zerve-meta/key_image.png`,
    method: "GET",
  }).then(async (response) => {
    const response2 = await fetch(response);
    const data = await response2.blob();
    if (data.size < 500) {
      return null;
    }
    return URL.createObjectURL(data);
  });

const getAutoPipelineSettings = async (
  pipeline: string,
  pipeline_type: string
) =>
  await callAPI({
    url: `${CANVAS_API_URL}/canvas/auto_pipeline/${pipeline}/${pipeline_type}`,
    method: "GET",
  });

const postCodeAutoComplete = async (
  prompt: string,
  aiModel: AI_MODEL,
  language: string
) =>
  await callAPI<{ suggestion: string }>({
    url: `${CANVAS_API_URL}/aimodel/code_complete`,
    method: "POST",

    data: { prompt, model: aiModel, language },
  });

const postTextAutoComplete = async (prompt: string, aiModel: AI_MODEL) =>
  await callAPI<{ suggestion: string }>({
    url: `${CANVAS_API_URL}/aimodel/text_complete`,
    method: "POST",

    data: { prompt, model: aiModel },
  });

const postSuggestName = async (code: string, aiModel: AI_MODEL) =>
  await callAPI<{ suggestion: string }>({
    url: `${CANVAS_API_URL}/aimodel/suggest_name`,
    method: "POST",

    data: { code, model: aiModel },
  });

const postErrorAssist = async (
  aiModel: AI_MODEL,
  code?: string,
  language?: string | null,
  error?: string | null
) =>
  await callAPI({
    url: `${CANVAS_API_URL}/aimodel/error_assist`,
    method: "POST",

    data: { code, model: aiModel, error, language },
  });

const patchCanvas = async ({
  canvasId,
  payload,
}: {
  canvasId: string;
  payload: Partial<{
    name: string;
    description: string;
    is_public: boolean;
    is_community: boolean;
    included_repos: string[];
  }>;
}) =>
  await callAPI({
    url: `${CANVAS_API_URL}/canvas/${canvasId}`,
    method: "PATCH",
    data: payload,
  });

interface PostCreateAppParams {
  name: string;
  workspace_id?: string;
  organization_id?: string;
}

const postCreateApp = async ({
  name,
  workspace_id,
  organization_id,
}: PostCreateAppParams) =>
  await callAPI({
    url: `${CANVAS_API_URL}/apps/`,
    method: "POST",

    data: {
      name,
      workspace_id,
      organization_id,
    },
  });

const getAppBuildlogs = async ({
  app_id,
  signal,
}: {
  app_id: string;
  signal?: AbortSignal;
}) =>
  await callAPI<string[]>({
    url: `${CANVAS_API_URL}/apps/build_logs/${app_id}`,
    method: "GET",
    signal,
  });

const getAppECSLogs = async ({
  app_id,
  signal,
}: {
  app_id: string;
  signal?: AbortSignal;
}) =>
  await callAPI<string[]>({
    url: `${CANVAS_API_URL}/apps/ecs_logs/${app_id}`,
    method: "GET",
    signal,
  });

const getCanvasExecutorLogs = async (
  canvas_id: string,
  language: string,
  compute_type: string,
  log_start_time: number,
  signal?: AbortSignal
) =>
  await callAPI<CanvasExecutorLog[]>({
    url: `${CANVAS_API_URL}/exec/${canvas_id}/build_logs/${canvas_id}/executor_logs/${language}/${compute_type}?log_start_time=${log_start_time}`,
    method: "GET",
    signal,
  });

const canvasService = {
  getCanvas,
  postCreateCanvas,
  patchCanvas,
  putRenameCanvas,
  postCanvasByProjectIDs,
  getCanvasLayout,
  getLayerLayout,
  deleteCanvasById,
  deleteCanvasesByIds,
  getPythonGlobalImports,
  getRGlobalImports,
  uploadKeyImage,
  getCanvasKeyImage,
  getAutoPipelineSettings,
  postSuggestName,
  postErrorAssist,
  postCodeAutoComplete,
  postTextAutoComplete,
  getUserCanvases,
  getOwnedCanvases,
  getCommunityCanvases,
  getStarredCanvases,
  getSharedCanvases,
  getWorkspaceCanvases,
  postCreateApp,
  putRenameApp,
  getWorkspaceApps,
  deleteAppsByIds,
  getApp,
  patchUpdateApp,
  getUploadAppFileToS3PresignedInfo,
  deleteAppFile,
  deployApp,
  deleteAppService,
  getAppBuildlogs,
  getAppECSLogs,
  getCanvasExecutorLogs,
};

export default canvasService;
