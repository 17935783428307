import styles from "./ConnectionsNav.module.scss";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import sortBy from "lodash-es/sortBy";
import { EmptyState } from "components/common/EmptyState/EmptyState";
import { Button } from "components/common/Button/Button";
import Fill from "components/common/Fill/Fill";
import ResizableNav from "components/common/ResizableNav/ResizableNav";
import { useAssetsState } from "store";
import { DATA_LOAD_STATUS } from "config/appConfig";
import { useConnectionsDataLoadActions } from "hooks/assets/useConnectionsDataLoadActions";
import { type ConnectionItemType } from "pages/Assets/types/connectionTypes";
import {
  emptyStateText,
  errorStateText,
} from "pages/Assets/components/AssetsNav/emptyStateText";
import ConnectionsNavItem from "pages/Assets/Connections/components/ConnectionsNavItem";
import EditableConnectionsNavItem from "pages/Assets/Connections/components/EditableConnectionsNavItem";
import { AssetItemsSkeletons } from "pages/Assets/components/AssetItemsSkeletons";

const ConnectionsNav = () => {
  const { workspaceID = "" } = useParams();
  const {
    createdItem: { assetOperationType },
    data,
    displayArchivedItems,
    loadInfo: { dataLoadStatus, connectionTypesLoadStatus },
  } = useAssetsState((slice) => slice.connections);

  const { loadInitialConnectionsData } = useConnectionsDataLoadActions();

  const listData: ConnectionItemType[] = useMemo(() => {
    const assets = Object.values(data);
    const filteredAssets = displayArchivedItems
      ? assets
      : assets.filter((asset) => !asset?.connection?.archive);

    return sortBy(
      filteredAssets,
      (item: ConnectionItemType) => item?.connection?.name
    );
  }, [data, displayArchivedItems]);

  const renderListContent = () => {
    if (
      dataLoadStatus === DATA_LOAD_STATUS.LOADING ||
      connectionTypesLoadStatus === DATA_LOAD_STATUS.LOADING
    ) {
      return <AssetItemsSkeletons />;
    }
    if (
      dataLoadStatus === DATA_LOAD_STATUS.ERROR ||
      connectionTypesLoadStatus === DATA_LOAD_STATUS.ERROR
    ) {
      return (
        <EmptyState
          variant="error"
          title={errorStateText.connection.title}
          description={errorStateText.connection.subtitle}
          containerClassName={styles.empty_state_container}
        >
          <Button
            variant="text"
            className={styles.button}
            onClick={() => {
              loadInitialConnectionsData({
                workspaceId: workspaceID,
                forceLoadConnectionTypes:
                  connectionTypesLoadStatus === DATA_LOAD_STATUS.ERROR,
                forceLoadConnectionsData:
                  dataLoadStatus === DATA_LOAD_STATUS.ERROR,
              });
            }}
          >
            {errorStateText.connection.buttonText}
          </Button>
        </EmptyState>
      );
    }

    if (
      dataLoadStatus === DATA_LOAD_STATUS.LOADED &&
      connectionTypesLoadStatus === DATA_LOAD_STATUS.LOADED
    ) {
      if (!listData.length) {
        return (
          <EmptyState
            variant="info"
            title={emptyStateText.connection.title}
            description={emptyStateText.connection.subtitle}
            containerClassName={styles.empty_state_container}
          />
        );
      }

      return listData.map((item) => {
        return <ConnectionsNavItem key={item.connection.id} {...item} />;
      });
    }

    return null;
  };

  return (
    <ResizableNav position="right" defaultSize={274}>
      <>
        {assetOperationType === "create" && <EditableConnectionsNavItem />}
        <div className={styles.list_container}>
          <Fill className={styles.list}>{renderListContent()}</Fill>
        </div>
      </>
    </ResizableNav>
  );
};

export default ConnectionsNav;
