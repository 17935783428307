import styles from "./CreateOrganization.module.scss";
import { useEffect } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import TextField from "components/common/TextField/TextField";
import { Button } from "components/common/Button/Button";
import Typography from "components/Typography/Typography";
import { useToastsState, useOrganizationsState } from "store";
import { useFormik } from "formik";
import * as Yup from "yup";
import { postOrganization } from "api/http/organization-service";
import { useOrganizationsActions } from "hooks/useOrganizationsActions";
import { ORGANIZATION_NAME_YUP_VALIDATION_BASE } from "config/organizationsConfig";
import { useNavigate } from "react-router-dom";

const TITLE = "Set up your Organization";
const SUBTITLE = "Tell us about your organization";

export const CreateOrganization = () => {
  const { enableOrganizations } = useFlags();
  const navigate = useNavigate();
  const addToast = useToastsState((slice) => slice.addToast);
  const organizations = useOrganizationsState((slice) => slice.organizations);
  const { fetchOrganizations } = useOrganizationsActions();

  useEffect(() => {
    fetchOrganizations();
  }, []);

  const handleCreateOrganization = (values) => {
    postOrganization({
      name: values.organizationName,
    })
      .then((returnedValues) => {
        const { id = "" }: { id: string } = returnedValues;
        navigate(`/org/${id}`);
      })
      .catch((error) => {
        if (error.cause) {
          setErrors(error.cause);
        }
        addToast({
          message: error.message,
          variant: "error",
        });
      });
  };

  const {
    values,
    touched,
    errors,
    isValid,
    dirty,
    handleBlur,
    handleChange,
    setErrors,
    handleSubmit,
  } = useFormik({
    initialValues: {
      organizationName: "",
    },
    validationSchema: Yup.object({
      organizationName: ORGANIZATION_NAME_YUP_VALIDATION_BASE.test(
        "unique-name",
        "Organization name must be unique",
        function (value) {
          if (!value) {
            return true;
          }

          // Convert the input value to lowercase for case-insensitive comparison
          const lowercaseValue = value.toLowerCase();

          // Check if the name already exists in the list of existing organizations
          const nameExists = organizations?.some(
            (existingOrganization) =>
              existingOrganization.name.toLowerCase() === lowercaseValue
          );

          // Return false if the name already exists, true otherwise
          return !nameExists;
        }
      ),
    }),
    onSubmit: handleCreateOrganization,
  });
  // https://linear.app/zerve-ai/issue/FRO-1407/change-organizations-feature-flag
  if (!enableOrganizations) {
    return null;
  }

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <div className={styles.header}>
          <Typography variant="h1" className={styles.title}>
            {TITLE}
          </Typography>
          <Typography variant={"caption1"} className={styles.subtitle}>
            {SUBTITLE}
          </Typography>
        </div>
        <form className={styles.form} onSubmit={handleSubmit}>
          <TextField
            id="organizationName"
            name="organizationName"
            label="Organization Name"
            placeholder="Enter your organization name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.organizationName}
            error={touched.organizationName ? errors.organizationName : ""}
          />
          <Button
            className={styles.button}
            disabled={!isValid || !dirty}
            size="large"
            type="submit"
          >
            Submit
          </Button>
        </form>
      </div>
    </div>
  );
};
