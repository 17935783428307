import styles from "./ContextMenu.module.scss";
import cn from "classnames";
import {
  useState,
  memo,
  Fragment,
  type AnchorHTMLAttributes,
  type ReactNode,
} from "react";
import {
  autoUpdate,
  flip,
  hide,
  offset,
  shift,
  safePolygon,
  useFloating,
  useFloatingNodeId,
  useHover,
  useInteractions,
  FloatingPortal,
  FloatingNode,
  type Side,
} from "@floating-ui/react";
import { BiCaretRight } from "react-icons/bi";
import MenuOption, { type MenuOptionProps } from "./MenuOption/MenuOption";
import MutedMenuOptionContent from "./MenuOption/MutedMenuOptionContent";
import SectionHeader from "./SectionHeader/SectionHeader";
import Search, { type SideBarSearchProps } from "../Search/Search";

export type ContextMenuItem = { key: string } & (
  | {
      type: "heading";
      label: ReactNode;
    }
  | ({
      type: "option";
      label: ReactNode;
    } & MenuOptionProps)
  | ({
      type: "sub-menu";
      label: string;
    } & SubMenuProps)
  | ({
      type: "search";
      label: string;
    } & SideBarSearchProps)
  | ({
      type: "externalLink";
      externalLinkProps: AnchorHTMLAttributes<HTMLAnchorElement>;
    } & MenuOptionProps)
  | {
      type: "custom";
      component: ReactNode;
    }
);

interface SubMenuProps extends MenuOptionProps {
  subMenu: ContextMenuItem[];
  subMenuPlacement?: Extract<Side, "left" | "right">;
}

interface ContextMenuProps {
  menuOptions: ContextMenuItem[];
  fitWidth?: boolean;
  closeOnSelect?: boolean;
  containerClassName?: string;
  onClose: () => void;
}

interface SubMenuComponentProps extends SubMenuProps {
  onClose: ContextMenuProps["onClose"];
}

// keep SubMenu and ContextMenu functions in the same file
// to avoid circular imports as we use SubMenu and ContextMenu recursively
const SubMenu = ({
  subMenu,
  subMenuPlacement = "right",
  onClose,
  ...rest
}: SubMenuComponentProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const floatingNodeId = useFloatingNodeId();
  const { refs, context, floatingStyles } = useFloating({
    nodeId: floatingNodeId,
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    placement: subMenuPlacement,
    middleware: [offset(1), flip(), shift(), hide()],
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, {
      delay: { open: 100 },
      handleClose: safePolygon({
        requireIntent: false,
        blockPointerEvents: true,
      }),
    }),
  ]);

  return (
    <div
      ref={refs.setReference}
      {...getReferenceProps({
        // allow clicks on sub-menu when useDismiss outsidePress is used in parent menu
        onPointerDown: (e) => e.stopPropagation(),
      })}
    >
      <MenuOption
        {...rest}
        onClose={() => {
          setIsOpen(!isOpen);
        }}
        rightSideContent={
          <MutedMenuOptionContent>
            <BiCaretRight />
          </MutedMenuOptionContent>
        }
      />
      <FloatingNode id={floatingNodeId}>
        {isOpen && (
          <FloatingPortal>
            <div
              ref={refs.setFloating}
              className={styles.submenu}
              style={floatingStyles}
              {...getFloatingProps()}
            >
              <ContextMenu
                containerClassName={styles.submenu_menu}
                menuOptions={subMenu}
                onClose={onClose}
              />
            </div>
          </FloatingPortal>
        )}
      </FloatingNode>
    </div>
  );
};

const ContextMenu = memo(function ContextMenuComponent({
  menuOptions,
  fitWidth,
  closeOnSelect = true,
  containerClassName = "",
  onClose,
}: ContextMenuProps) {
  const handleOnClose = (): void => {
    if (closeOnSelect) {
      onClose();
    }
  };
  return (
    <div
      role="menu"
      className={cn(styles.container, containerClassName, {
        [styles.container_full_width]: fitWidth,
      })}
    >
      {menuOptions.map((menuOption) => {
        const { key, ...option } = menuOption;
        switch (option.type) {
          case "heading":
            return <SectionHeader key={key} label={option.label} />;
          case "option": {
            return <MenuOption key={key} {...option} onClose={handleOnClose} />;
          }
          case "sub-menu":
            return <SubMenu key={key} {...option} onClose={handleOnClose} />;
          case "search":
            return <Search key={key} {...option} />;
          case "externalLink": {
            const { externalLinkProps, label } = option;

            return (
              <a
                key={key}
                {...externalLinkProps}
                className={styles.externalLink}
              >
                {label}
              </a>
            );
          }
          case "custom":
          default:
            return <Fragment key={key}>{option.component}</Fragment>;
        }
      })}
    </div>
  );
});

export default ContextMenu;
