import styles from "./CanvasesPage.module.scss";
import { useMemo, useCallback } from "react";
import { Outlet } from "react-router-dom";
import { BiShapeSquare, BiStar, BiShareAlt, BiGroup } from "react-icons/bi";
import { useUserState } from "store";
import { useModal } from "hooks/useModal";
import { CreateCanvasModal } from "components/modals";
import { NavigationTabs } from "components/common/NavigationTabs/NavigationTabs";
import { Button } from "components/common/Button/Button";
import { useFlags } from "launchdarkly-react-client-sdk";

export function CanvasesPage() {
  const userID = useUserState((slice) => slice.userID);
  const { openModal } = useModal();

  const flags = useFlags();

  const handleCreateCanvas = useCallback(() => {
    if (!userID) {
      return;
    }
    openModal({
      title: "Create Canvas",
      content: ({ onModalClose }) => (
        <CreateCanvasModal onModalClose={onModalClose} />
      ),
    });
  }, [userID]);

  const navItems = useMemo(
    () => [
      {
        key: "my-canvases",
        label: (
          <>
            <BiShapeSquare size={20} />
            <span>My Canvases</span>
          </>
        ),
        to: "/canvases/my-canvases",
      },
      // add community-canvases item based on feature flag
      ...(flags.enableCommunity
        ? [
            {
              key: "community-canvases",
              label: (
                <>
                  <BiGroup size={20} />
                  <span>Community</span>
                </>
              ),
              to: "/canvases/community-canvases",
            },
          ]
        : []),
      {
        key: "starred",
        label: (
          <>
            <BiStar size={20} />
            <span>Starred</span>
          </>
        ),
        to: "/canvases/starred",
      },
      {
        key: "shared-with-me",
        label: (
          <>
            <BiShareAlt size={20} />
            <span>Shared with Me</span>
          </>
        ),
        to: "/canvases/shared-with-me",
      },
    ],
    [flags.enableCommunity]
  );

  return (
    <div className={styles.container}>
      <aside className={styles.sidebar}>
        <Button
          size="large"
          onClick={handleCreateCanvas}
          className={styles.createButton}
        >
          Create Canvas
        </Button>

        <NavigationTabs orientation="vertical" items={navItems} />
      </aside>

      <Outlet />
    </div>
  );
}
