import styles from "./EditableTitle.module.scss";
import {
  memo,
  useCallback,
  useState,
  useEffect,
  useMemo,
  useRef,
  type ChangeEvent,
  type ReactNode,
} from "react";
import { BiEditAlt, BiError } from "react-icons/bi";
import cn from "classnames";
import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  shift,
  useFloating,
} from "@floating-ui/react";
import Typography from "components/Typography/Typography";

type EditableTitleProps = {
  title: string;
  align?: "left" | "center";
  switchToEditingOnDoubleClick?: boolean;
  readOnly?: boolean;
  containerClassName?: string;
  textClassName?: string;
  placeholder?: string;
  autoAdjustInputWidth?: boolean;
  onSave: (newTitle: string) => void;
  getError?: (newTitle: string) => string | null;
};

export const EditableTitle = memo(function EditableTitle({
  title,
  align = "left",
  switchToEditingOnDoubleClick = true,
  readOnly = false,
  autoAdjustInputWidth = false,
  containerClassName,
  textClassName,
  placeholder,
  onSave,
  getError,
}: EditableTitleProps) {
  const inputCalibrationElRef = useRef<HTMLHeadingElement | null>(null);

  const [value, setValue] = useState(title);
  const [isInEditingMode, setIsInEditingMode] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [hasFocus, setHasFocus] = useState(false);

  useEffect(() => {
    setValue(title);
  }, [title]);

  useEffect(() => {
    if (inputRef?.current && !hasFocus) {
      inputRef.current.focus();
    }
  }, [hasFocus]);

  const error = useMemo(
    () => (getError ? getError(value) : null),
    [getError, value]
  );

  const handleSave = (e) => {
    setHasFocus(false);

    if (error) {
      return;
    }

    const { value } = e.target;
    const trimmedValue = value.trim();

    if (e.code === "Escape" || !trimmedValue) {
      setValue(title);
      setIsInEditingMode(false);
    } else {
      setValue(trimmedValue);

      if (trimmedValue !== title) {
        onSave(trimmedValue);
        setTimeout(() => {
          setIsInEditingMode(false);
        }, 200);
      } else {
        setIsInEditingMode(false);
      }
    }
  };

  const { x, y, refs, strategy } = useFloating({
    open: Boolean(error),
    whileElementsMounted: autoUpdate,
    placement: "bottom",
    middleware: [offset(8), flip(), shift()],
  });

  const toggleEditingMode = (e) => {
    e.stopPropagation();
    if (readOnly) {
      setIsInEditingMode(false);
    } else {
      setIsInEditingMode((prev) => !prev);
    }
  };

  const handleInputClick = (e) => {
    // prevent canvas selection on Home page
    e.stopPropagation();
  };

  function handleFocus() {
    setHasFocus(true);
  }

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      event.target.blur();
    }
    if (event.key === "Escape") {
      setHasFocus(false);
      setIsInEditingMode(false);
      setValue(title);
    }
  }

  const onInput = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (inputCalibrationElRef.current) {
      inputCalibrationElRef.current.innerText = e.target.value;
    }
  }, []);

  const renderErrorNotification = (errorText: string): ReactNode => (
    <div
      ref={refs.setFloating}
      style={{
        position: strategy,
        top: y ?? 0,
        left: x ?? 0,
      }}
      className={styles.error}
    >
      <BiError className={styles.error_icon} size={20} />
      <Typography variant="caption1" className={styles.error_text}>
        {errorText}
      </Typography>
    </div>
  );

  return (
    <div
      className={cn(
        styles.container,
        {
          [styles.readonly]: readOnly,
          [styles.alignLeft]: align === "left",
          [styles.alignCenter]: align === "center",
          [styles.autoAdjustInputWidthContainer]: autoAdjustInputWidth,
        },
        containerClassName
      )}
    >
      {isInEditingMode ? (
        <>
          <div ref={refs.setReference} className={styles.inputWrapper}>
            {autoAdjustInputWidth ? (
              <h4
                ref={inputCalibrationElRef}
                className={styles.input_size_calibration}
              >
                {value}
              </h4>
            ) : null}
            <input
              ref={inputRef}
              autoFocus
              type="text"
              className={cn(styles.input, textClassName)}
              defaultValue={title}
              placeholder={placeholder}
              onKeyDown={handleKeyPress}
              onBlur={handleSave}
              onClick={handleInputClick}
              onFocus={handleFocus}
              onChange={(e) => {
                setValue(e.target.value);
              }}
              onInput={autoAdjustInputWidth ? onInput : undefined}
            />
          </div>
          {!readOnly && autoAdjustInputWidth ? (
            <BiEditAlt
              size={textClassName ? undefined : 16}
              className={cn(
                styles.editIcon,
                textClassName,
                styles.editIconPlaceholder
              )}
            />
          ) : null}
        </>
      ) : (
        <div className={styles.titleWrapper}>
          <h3
            className={cn(styles.title, textClassName)}
            title={value}
            onDoubleClick={
              switchToEditingOnDoubleClick ? toggleEditingMode : undefined
            }
          >
            {value}
          </h3>
          {!readOnly ? (
            <BiEditAlt
              size={textClassName ? undefined : 16}
              onClick={toggleEditingMode}
              className={cn(styles.editIcon, textClassName)}
            />
          ) : null}
        </div>
      )}
      {error && (
        <FloatingPortal>{renderErrorNotification(error)}</FloatingPortal>
      )}
    </div>
  );
});
