import styles from "./UsernameStep.module.scss";
import sharedStepStyles from "../sharedStepStyles.module.scss";
import { Button } from "components/common/Button/Button";
import Typography from "components/Typography/Typography";
import { useMemo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Loader from "components/common/Loader/Loader";
import TextField from "components/common/TextField/TextField";
import { useUserSettings } from "hooks/useUserSettings";
import { useUserSettingsState, useToastsState } from "store";
import {
  stringIsTooLongErrorMessage,
  stringIsTooShortErrorMessage,
} from "utils/validation";
import newUserWelcomeImage from "assets/images/new-user-welcome.png";

const HEADLINE =
  "Create your username. Comment, publish, and connect on Zerve.";

const FORM_FIELD = {
  username: "username",
} as const;

const FORM_FIELD_VALIDATION = {
  [FORM_FIELD.username]: Yup.string()
    .required("Username is required")
    .test(
      "usernameHasSpaces",
      "Your username must not contain spaces.",
      (value) => !value?.includes(" ")
    )
    .test(
      "usernameTooShort",
      stringIsTooShortErrorMessage(5),
      (value) => !value || value.length >= 5
    )
    .test(
      "usernameTooLong",
      stringIsTooLongErrorMessage(64),
      (value) => !value || value.length <= 64
    ),
};

const VALIDATION_SCHEMA = Yup.object().shape(FORM_FIELD_VALIDATION);
interface UsernameStepProps {
  onNext: () => void;
}

export default function UsernameStep({ onNext }: UsernameStepProps) {
  const addToast = useToastsState((slice) => slice.addToast);
  const { handleSubmitProfileSetting, isSubmitProfileSettingInProgress } =
    useUserSettings();
  const username = useUserSettingsState(
    (slice) => slice.profile?.username || ""
  );

  const formInitialValues = useMemo(() => {
    return {
      [FORM_FIELD.username]: username,
    };
  }, [username]);

  const onSubmit = (values: Record<string, string>) => {
    const dataToSubmit: Record<string, string> = {};

    if (values.username !== formInitialValues?.username) {
      dataToSubmit.username = values.username;
    }

    handleSubmitProfileSetting({
      data: dataToSubmit,
      onSuccess: () => {
        resetForm();
        onNext();
        addToast({
          variant: "success",
          message: "Username has been saved successfully",
        });
      },
      onError: (err) => {
        let errMessage = "Failed to save username";

        if (err instanceof Error) {
          if (err?.message) {
            errMessage = err.message;
          }

          const errDetails = err?.cause as any;

          if (errDetails) {
            setErrors(errDetails);
          }
        }

        addToast({
          variant: "error",
          message: errMessage,
        });
      },
    });
  };

  const {
    values,
    errors,
    isValid,
    dirty,
    handleBlur,
    handleChange,
    handleSubmit,
    setErrors,
    resetForm,
  } = useFormik({
    initialValues: { ...formInitialValues },
    validationSchema: VALIDATION_SCHEMA,
    enableReinitialize: true,
    onSubmit,
  });

  const isActionButtonDisabled = !isValid || isSubmitProfileSettingInProgress;
  const isFieldDisabled = isSubmitProfileSettingInProgress;

  return (
    <>
      <div className={styles.message}>
        <Typography className={sharedStepStyles.headline} variant={"h2"}>
          {HEADLINE}
        </Typography>
      </div>

      <form className={styles.form} autoComplete="off" onSubmit={handleSubmit}>
        <TextField
          placeholder="Your username..."
          value={values[FORM_FIELD.username]}
          name={FORM_FIELD.username}
          autoFocus
          onChange={handleChange}
          onBlur={handleBlur}
          isDisabled={isFieldDisabled}
          error={errors[FORM_FIELD.username]}
          containerClassName={styles.textFieldContainer}
        />
        <div className={styles.controls}>
          <Button
            size="large"
            variant="primary"
            type="submit"
            disabled={isActionButtonDisabled || !dirty}
          >
            {isSubmitProfileSettingInProgress ? (
              <span>
                <Loader />
              </span>
            ) : (
              "Save"
            )}
          </Button>
        </div>
      </form>
      <img src={newUserWelcomeImage} className={sharedStepStyles.image} />
    </>
  );
}
