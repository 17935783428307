import styles from "../FunctionsPages/FunctionsPages.module.scss";
import constantsPagesStyles from "./ConstantsPages.module.scss";
import { useEffect, useMemo } from "react";
import { Outlet, useParams } from "react-router-dom";
import { AssetsNav, AssetsTabHeader } from "pages/Assets/components";
import { useAssetsData } from "hooks/useAssetsData";
import { useAssetsDataLoadActions } from "hooks/assets/useAssetsDataLoadActions";
import { ConstantModal } from "pages/Assets/Constants/components";
import { EmptyState } from "components/common/EmptyState/EmptyState";

export function ConstantsPages() {
  const { workspaceID = "" } = useParams();
  const {
    getCreatedAssetItemData,
    getCurrentAssetItemActions,
    getCurrentAssetItemsData,
  } = useAssetsData();
  const { loadInitialAssetsData } = useAssetsDataLoadActions();
  const { assetOperationType } = getCreatedAssetItemData("constant");
  const { clearStore } = getCurrentAssetItemActions("constant");
  const { activeItemVersionId } = getCurrentAssetItemsData("constant");

  useEffect(() => {
    loadInitialAssetsData({
      assetType: "constant",
      workspaceId: workspaceID,
    });

    return () => {
      clearStore();
    };
  }, [workspaceID]);

  const noVersionSelected = useMemo(
    () => (
      <EmptyState
        variant="info"
        title="No Version Details yet..."
        description="Looks like you don’t have any version selected yet."
        containerClassName={constantsPagesStyles.noVersionContainer}
      />
    ),
    []
  );

  const modalIsOpen = !!assetOperationType && assetOperationType !== "editName";

  return (
    <div className={styles.page}>
      <AssetsTabHeader
        title="Constants & Secrets"
        subtitle="Save and share constants in order to quickly reuse common values and safeguard secrets."
        assetType="constant"
      />
      <div className={styles.page__body}>
        <AssetsNav assetType="constant" />
        <Outlet />
        {activeItemVersionId === null ? noVersionSelected : null}
      </div>
      {modalIsOpen && <ConstantModal />}
    </div>
  );
}
