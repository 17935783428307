import { createContext, useContext } from "react";

export const ItemContext = createContext<{
  id: string;
  isActive: boolean;
} | null>(null);

export const useSidebarAccordionItemContext = () => {
  const context = useContext(ItemContext);

  if (!context) {
    throw new Error(
      "useSidebarAccordionItemContext has to be used within <ItemContext.Provider>"
    );
  }

  return context;
};
