import styles from "./MenuHeader.module.scss";
import { useRef } from "react";
import cn from "classnames";
import { Button } from "components/common/Button/Button";
import { HelperTooltip } from "components/common/HelperTooltip/HelperTooltip";

type MenuHeaderProps = {
  title: string;
  description: string;
  icon?: any;
  buttonText?: string;
  onClick?: (e) => void;
  borderBottom?: boolean;
  children?: any;
  disabled?: boolean;
};

const MenuHeader = ({
  title,
  description,
  buttonText,
  onClick,
  borderBottom,
  children,
  disabled,
}: MenuHeaderProps) => {
  const textRef = useRef<any>(null);

  return (
    <div
      className={
        borderBottom
          ? cn(styles.container, styles.container__border)
          : styles.container
      }
    >
      <div className={styles.container__title}>
        {title}{" "}
        <HelperTooltip variant="help" title={title} text={description} />
      </div>
      {children ||
        (buttonText && (
          <Button
            variant="secondary"
            size={"small"}
            onClick={onClick}
            className={styles.container__button}
            disabled={disabled}
          >
            {buttonText}
          </Button>
        ))}
      <span ref={textRef} className={styles.descriptionMeasuringBox}>
        {description}
      </span>
    </div>
  );
};

export default MenuHeader;
