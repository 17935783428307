export const getAssetsBaseUrl = ({
  organizationId,
  workspaceId,
}: {
  organizationId?: string | null;
  workspaceId?: string | null;
}) => {
  if (organizationId && workspaceId) {
    return `/org/${organizationId}/workspaces/${workspaceId}/assets`;
  }

  return `/assets`;
};
