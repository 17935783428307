import styles from "./EnvironmentsPages.module.scss";
import { useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import PageHeader from "components/common/PageHeader/PageHeader";
import LogWindow from "components/common/LogWindow/LogWindow";
import Typography from "components/Typography";
import Fill from "components/common/Fill/Fill";
import CircularProgressBlock from "components/common/CircularProgressBlock/CircularProgressBlock";
import Tabs from "components/common/Tabs/Tabs";
import { CreateEnvModal } from "components/modals";
import { useModal } from "hooks/useModal";
import {
  EnvRequirementsPanel,
  EnvImportsPanel,
  EnvAssetsPanel,
  EnvironmentsNav,
} from "layout/panels";

export function EnvironmentsPages() {
  const navigate = useNavigate();
  const location = useLocation();
  const urlComponents = location.pathname.split("/");
  const { openModal } = useModal();

  const [buildingProgress, setBuildingProgress] = useState<number>(100);
  const activeID = urlComponents[2];

  const tabs = useMemo(
    () => [
      {
        id: 1,
        label: "Requirements",
        onClick: () => {
          navigate(`/environments/${activeID}/requirements`);
        },
        active: urlComponents[3] === "requirements",
      },
      {
        id: 2,
        label: "Global Imports",
        onClick: () => {
          navigate(`/environments/${activeID}/imports`);
        },
        active: urlComponents[3] === "imports",
      },
      {
        id: 3,
        label: "Assets",
        onClick: () => {
          navigate(`/environments/${activeID}/assets`);
        },
        active: urlComponents[3] === "assets",
      },
    ],
    [urlComponents]
  );

  const handleBuild = () => {
    // mock progressbar logic
    let value = 0;

    const interval = setInterval(() => {
      if (value === 99) {
        clearInterval(interval);
      }
      value += 1;
      setBuildingProgress(value);
    }, 80);
  };

  const handleCreateEnvironment = () => {
    openModal({
      title: "Create Environment",
      classes: { container: styles["page__create-modal"] },
      content: ({ onModalClose }) => (
        <CreateEnvModal onModalClose={onModalClose} />
      ),
    });
  };

  // sample logs as  a string
  const logs = `[2021-08-12 12:00:00] [INFO] [main] [main.py:12] - Starting application
[2021-08-12 12:00:00] [INFO] [main] [main.py:12] - Starting application
[2021-08-12 12:00:00] [INFO] [main] [main.py:12] - Starting application
`;

  const getPanel = () => {
    switch (urlComponents[3]) {
      case "requirements":
        return <EnvRequirementsPanel />;
      case "imports":
        return <EnvImportsPanel />;
      case "assets":
        return <EnvAssetsPanel />;
      default:
        return <div>no panel here</div>;
    }
  };

  const panelSizeProp = {
    ...(urlComponents[3] !== "assets" ? { size: 225 } : { flex: 0.5 }),
  };

  return (
    <div className={styles.page}>
      <PageHeader
        handleBuild={handleBuild}
        handleCreateEnvironment={handleCreateEnvironment}
      />

      <ReflexContainer orientation="vertical">
        <ReflexElement size={224}>
          <EnvironmentsNav />
        </ReflexElement>

        <ReflexSplitter propagate={true} />

        {buildingProgress === 100 ? (
          <ReflexElement className={styles.resizable}>
            <Tabs tabs={tabs} />
            <ReflexContainer
              orientation="vertical"
              className={styles["page__body__content"]}
            >
              {!!urlComponents[3] && (
                <ReflexElement {...panelSizeProp}>{getPanel()}</ReflexElement>
              )}

              {!!urlComponents[3] && <ReflexSplitter />}

              <ReflexElement>
                <Fill>
                  <Typography
                    component="div"
                    variant="caption1"
                    className={styles["page__body__log-header"]}
                  >
                    Log Window
                  </Typography>

                  <div className={styles["page__log-wrapper"]}>
                    <LogWindow logs={logs} />
                  </div>
                </Fill>
              </ReflexElement>
            </ReflexContainer>
          </ReflexElement>
        ) : (
          <ReflexElement className={styles["page__progressbar"]}>
            <CircularProgressBlock
              title={"Building Your Environment..."}
              text={"It may take couple of minutes, \n so please wait."}
              progress={buildingProgress}
            />
          </ReflexElement>
        )}
      </ReflexContainer>
    </div>
  );
}
