import styles from "./ConfirmCanvasesDeletionModal.module.scss";
import { useMemo } from "react";
import type { CanvasCardType } from "models/canvas";

const MAX_CANVASES_TO_SHOW = 5;

type ConfirmCanvasesDeletionModalProps = {
  canvasIds: CanvasCardType["id"][];
  canvases: CanvasCardType[];
};

export function ConfirmCanvasesDeletionModal({
  canvasIds,
  canvases,
}: ConfirmCanvasesDeletionModalProps) {
  const canvasesToDisplay = useMemo(() => {
    return canvasIds.slice(0, MAX_CANVASES_TO_SHOW);
  }, [canvasIds]);

  const extraCount = useMemo(() => {
    return canvasIds.length - canvasesToDisplay.length;
  }, [canvasIds, canvasesToDisplay]);

  return (
    <>
      {/* Question */}
      {canvasIds.length === 1
        ? "Are you sure you want to delete this canvas?"
        : `Are you sure you want to delete these ${canvasIds.length} canvases?`}

      {/* Canvases list */}
      <ul className={styles.canvasesList}>
        {canvasesToDisplay.map((canvasId) => {
          const canvas = canvases.find((canvas) => canvas.id === canvasId);
          if (!canvas) {
            return null;
          }
          return (
            <li key={canvasId} className={styles.listItem}>
              <span className={styles.name}>{canvas.name}</span>
            </li>
          );
        })}
      </ul>

      {extraCount > 0 ? (
        <div className={styles.extraCount}>... and {extraCount} more</div>
      ) : null}
    </>
  );
}
