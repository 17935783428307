import styles from "components/canvases/CanvasesListLayout/CanvasesListLayout.module.scss";
import {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
  type MouseEvent,
} from "react";
import { BiX } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import pluralize from "pluralize";
import canvasService from "api/http/canvas-service";
import { useToastsState, useLocalCanvasPreferencesState } from "store";
import { useCanvasSorting } from "hooks/useCanvasSorting";
import { useModal } from "hooks/useModal";
import { useConfirmModal } from "hooks/useConfirmModal";
import { useResourceSharingActions } from "hooks/useResourceSharingActions";
import { Button } from "components/common/Button/Button";
import Typography from "components/Typography/Typography";
import IconButton from "components/common/IconButton/IconButton";
import { ShareResourceModal, UpdateCanvasModal } from "components/modals";
import shareResourceModalStyles from "components/modals/ShareResourceModal/ShareResourceModal.module.scss";
import { ConfirmCanvasesDeletionModal } from "components/blocks/ConfirmCanvasesDeletionModal/ConfirmCanvasesDeletionModal";
import { SORTING_COLUMN } from "config/localPreferences";
import { COMMUNITY_BASE_URL, type SORTING_DIRECTION } from "config/appConfig";
import { CANVAS_PRIVACY, type CANVAS_PAGE_FILTERS } from "config/canvasConfig";
import { RESOURCE_TYPE } from "models/user";
import type { CanvasCardType } from "models/canvas";

const CANVAS_ACTION = Object.freeze({
  delete: "delete",
  rename: "rename",
  toggleStar: "toggleStar",
});

const SORT_OPTIONS = Object.freeze([
  { value: SORTING_COLUMN.name, label: "Name" },
  { value: SORTING_COLUMN.createdAt, label: "Date Created" },
  { value: SORTING_COLUMN.lastModified, label: "Last Modified" },
]);

type CanvasAction = (typeof CANVAS_ACTION)[keyof typeof CANVAS_ACTION];

pluralize.addSingularRule("canvas", "canvas");
pluralize.addPluralRule("canvas", "canvases");

interface UseCanvasesProps {
  isLoadingInitial?: boolean;
}

const useCanvases = ({ isLoadingInitial = false }: UseCanvasesProps) => {
  const navigate = useNavigate();
  const addToast = useToastsState((slice) => slice.addToast);
  const { openModal } = useModal();
  const { openConfirmModal } = useConfirmModal();

  const { getCanvasPrivacy, updateCanvasPrivacy } = useResourceSharingActions();

  const [isLoading, setIsLoading] = useState(isLoadingInitial);
  const [searchQuery, setSearchQuery] = useState("");
  const [canvases, setCanvases] = useState<CanvasCardType[]>([]);
  const [selectedCanvasesIds, setSelectedCanvasesIds] = useState<
    Record<string, string>
  >({});
  // canvas id to action map
  const [processedCanvasesIdActionMap, setProcessedCanvasesIdActionMap] =
    useState<Record<string, CanvasAction>>({});

  // timeout is needed to prevent firing onClick before onDoubleClick
  const selectCanvasTimeoutId = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    return () => {
      clearTimeout(selectCanvasTimeoutId.current);
    };
  }, []);

  const {
    canvasesDisplayMode,
    canvasesSortingColumn,
    canvasesSortingDirection,
    setCanvasesDisplayMode,
    setCanvasesSortingColumn,
    setCanvasesSortingDirection,
  } = useLocalCanvasPreferencesState((slice) => {
    return {
      canvasesDisplayMode: slice.canvasesDisplayMode,
      canvasesSortingColumn: slice.canvasesSortingColumn,
      canvasesSortingDirection: slice.canvasesSortingDirection,
      setCanvasesDisplayMode: slice.setCanvasesDisplayMode,
      setCanvasesSortingColumn: slice.setCanvasesSortingColumn,
      setCanvasesSortingDirection: slice.setCanvasesSortingDirection,
    };
  });

  const currentSorting = useMemo(() => {
    return {
      column: canvasesSortingColumn,
      direction: canvasesSortingDirection,
    };
  }, [canvasesSortingColumn, canvasesSortingDirection]);

  const selectedCanvasesIdsList = useMemo(() => {
    return Object.values(selectedCanvasesIds);
  }, [selectedCanvasesIds]);

  const sortedItems = useCanvasSorting(currentSorting, canvases, searchQuery);

  const handleSearchQueryChange = useCallback((e) => {
    setSearchQuery(e.target.value);
  }, []);

  const handleDropSelection = useCallback(() => {
    setSelectedCanvasesIds({});
  }, []);

  const setProcessedCanvasIdAction = useCallback(
    (id: string, action: CanvasAction) => {
      setProcessedCanvasesIdActionMap((ids) => {
        return {
          ...ids,
          [id]: action,
        };
      });
    },
    []
  );

  const removeProcessedCanvasId = useCallback((id: string) => {
    setProcessedCanvasesIdActionMap((ids) => {
      const { [id]: _, ...rest } = ids;

      return rest;
    });
  }, []);

  useEffect(() => {
    if (!sortedItems.length && selectedCanvasesIdsList.length > 0) {
      handleDropSelection();

      return;
    }

    const idsToRemove: string[] = [];
    selectedCanvasesIdsList.forEach((id) => {
      if (!sortedItems.some((canvas) => canvas.id === id)) {
        idsToRemove.push(id);
      }
    });

    if (!idsToRemove.length) {
      return;
    }

    setSelectedCanvasesIds((ids) => {
      return idsToRemove.reduce((acc, curr) => {
        const { [curr]: _, ...rest } = acc;

        return rest;
      }, ids);
    });
  }, [sortedItems]);

  const noCanvases = canvases.length === 0;
  const notFound = !noCanvases && sortedItems.length === 0;

  const handleChangeSorting = ({
    column,
    direction,
  }: {
    column: SORTING_COLUMN;
    direction: SORTING_DIRECTION;
  }) => {
    setCanvasesSortingColumn(column);
    setCanvasesSortingDirection(direction);
  };

  const canvasNameChangeHandler = (canvasID: string) => (name: string) => {
    // set name to the canvas state to avoid new/old name flashing
    let prevName: string = "Untitled";
    // TODO: get lastModified from response when BE returns updated canvas data
    let lastModified: CanvasCardType["lastModified"] = null;
    const trimmedName = name.trim();
    setCanvases((items) => {
      return items.map((item) => {
        if (item.id !== canvasID) {
          return item;
        }

        prevName = item.name;
        lastModified = item.lastModified;

        return {
          ...item,
          name: trimmedName,
          lastModified: moment().format(),
        };
      });
    });
    setProcessedCanvasIdAction(canvasID, CANVAS_ACTION.rename);
    canvasService
      .putRenameCanvas(canvasID, trimmedName)
      .catch(() => {
        // revert name
        setCanvases((items) => {
          return items.map((item) => {
            if (item.id !== canvasID) {
              return item;
            }

            return {
              ...item,
              name: prevName,
              lastModified,
            };
          });
        });
        addToast({
          message: "Error renaming canvas",
          variant: "error",
        });
      })
      .finally(() => {
        removeProcessedCanvasId(canvasID);
      });
  };

  const openCanvas = useCallback((canvasID: string) => {
    navigate(`/canvas/${canvasID}`);
  }, []);

  const handleOpenInvitationModal = useCallback(
    (
      canvasId: string,
      canvasName: string,
      projectId: string,
      pageFilter?: CANVAS_PAGE_FILTERS
    ) => {
      openModal({
        classes: {
          container: shareResourceModalStyles.modal,
          title: shareResourceModalStyles.modal_title,
        },
        title: `Share Canvas (${canvasName || "Untitled"})`,
        content: ({ onModalClose }) => (
          <ShareResourceModal
            resourceId={projectId}
            resourceType={RESOURCE_TYPE.CANVAS}
            resourceLink={`${window.location.origin}/canvas/${canvasId}`}
            communityLink={`${COMMUNITY_BASE_URL}/community/canvas/${canvasId}`}
            onModalClose={onModalClose}
            getCanvasPrivacy={() => {
              return getCanvasPrivacy(canvasId);
            }}
            updateCanvasPrivacy={async (privacy: CANVAS_PRIVACY) => {
              await updateCanvasPrivacy(canvasId, privacy);
              setCanvases((items) => {
                return items.map((item) => {
                  if (item.id === canvasId) {
                    return {
                      ...item,
                      is_public:
                        privacy === CANVAS_PRIVACY.PUBLIC ||
                        privacy === CANVAS_PRIVACY.COMMUNITY,
                      is_community: privacy === CANVAS_PRIVACY.COMMUNITY,
                    };
                  }
                  return item;
                });
              });
            }}
            onLeaveResource={async () => {
              setCanvases((items) =>
                items.filter((item) => {
                  return item.id !== canvasId;
                })
              );
            }}
          />
        ),
        onClose: () => {
          // on community-canvases page we need to filter out the current canvas from the list if it is not a community canvas
          if (pageFilter === "community-canvases") {
            setCanvases((items) => {
              return items.filter((item) => {
                return item.is_community || item.id !== canvasId;
              });
            });
          }
        },
      });
    },
    [getCanvasPrivacy, updateCanvasPrivacy]
  );

  const handleOpenCanvasSettingsModal = useCallback((canvasId: string) => {
    openModal({
      title: `Canvas Settings`,
      content: ({ onModalClose }) => (
        <UpdateCanvasModal
          canvasId={canvasId}
          onModalClose={onModalClose}
          onCanvasUpdated={(data) => {
            setCanvases((items) => {
              return items.map((item) => {
                if (item.id === canvasId) {
                  return {
                    ...item,
                    name: data.name,
                    description: data.description,
                  };
                }
                return item;
              });
            });
          }}
        />
      ),
    });
  }, []);

  const toggleSelectCanvas = useCallback((canvasID: string) => {
    setSelectedCanvasesIds((ids) => {
      if (ids[canvasID]) {
        const { [canvasID]: _, ...rest } = ids;

        return rest;
      }

      return {
        ...ids,
        [canvasID]: canvasID,
      };
    });
  }, []);

  const handleCanvasClick = useCallback(
    (canvasID: string, event: MouseEvent<HTMLElement>) => {
      clearTimeout(selectCanvasTimeoutId.current);
      // event.detail: 1 - single click, 2 - double click, 3 - triple click
      if (event.detail !== 1) {
        openCanvas(canvasID);

        return;
      }

      selectCanvasTimeoutId.current = setTimeout(() => {
        toggleSelectCanvas(canvasID);
      }, 250);
    },
    []
  );

  const handleDeleteCanvases = useCallback(
    async (canvasIds: string[]) => {
      if (!canvasIds.length) {
        return;
      }

      const confirmed = await openConfirmModal({
        message: (
          <ConfirmCanvasesDeletionModal
            canvasIds={canvasIds}
            canvases={canvases}
          />
        ),
        confirmButtonLabel: "Delete",
        confirmButtonVariant: "crucial",
      });

      if (!confirmed) {
        return;
      }

      setProcessedCanvasesIdActionMap((ids) => {
        return canvasIds.reduce((acc, curr) => {
          return {
            ...acc,
            [curr]: CANVAS_ACTION.delete,
          };
        }, ids);
      });

      try {
        await canvasService.deleteCanvasesByIds(canvasIds);
        setCanvases((items) =>
          items.filter((item) => {
            return !canvasIds.includes(item.id);
          })
        );
        addToast({
          variant: "success",
          message:
            canvasIds.length > 1
              ? "Successfully deleted canvases"
              : "Successfully deleted canvas",
        });
      } catch (rejection) {
        addToast({
          variant: "error",
          message:
            canvasIds.length > 1
              ? "Failed to delete canvases"
              : "Failed to delete canvas",
        });
      }

      setProcessedCanvasesIdActionMap((ids) => {
        const newIds = { ...ids };
        canvasIds.forEach((id) => {
          delete newIds[id];
        });
        return newIds;
      });
    },
    [canvases]
  );

  const handleDeleteSelectedCanvases = useCallback(() => {
    handleDeleteCanvases(selectedCanvasesIdsList);
  }, [selectedCanvasesIdsList, handleDeleteCanvases]);

  const toolbar = useMemo(() => {
    if (isLoading) {
      return null;
    }

    const selectedCanvasesCount = selectedCanvasesIdsList.length;

    if (!selectedCanvasesCount) {
      return null;
    }

    const {
      openCanvasesDisabled,
      deleteCanvasesDisabled,
      clearSelectedDisabled,
    } = (() => {
      const disabledButtonMap = {
        openCanvasesDisabled: false,
        deleteCanvasesDisabled: false,
        clearSelectedDisabled: false,
      };

      if (!Object.keys(processedCanvasesIdActionMap).length) {
        return disabledButtonMap;
      }

      for (const selectedCanvasId of selectedCanvasesIdsList) {
        const action = processedCanvasesIdActionMap[selectedCanvasId];

        if (!action) {
          continue;
        }
        if (action === CANVAS_ACTION.delete) {
          disabledButtonMap.openCanvasesDisabled = true;
          disabledButtonMap.deleteCanvasesDisabled = true;
          disabledButtonMap.clearSelectedDisabled = true;
          break;
        }

        disabledButtonMap.deleteCanvasesDisabled = true;
        break;
      }

      return disabledButtonMap;
    })();

    return (
      <div className={styles.toolbar}>
        <div>
          <Typography
            component="span"
            variant="caption1"
            className={styles.selected_count}
          >
            {selectedCanvasesCount}
          </Typography>
          <Typography
            component="span"
            variant="caption1"
            className={styles.selected_label}
          >
            {`${pluralize("Item", selectedCanvasesCount)} Selected`}
          </Typography>
        </div>
        <div className={styles.toolbar_actions}>
          {selectedCanvasesCount === 1 && (
            <Button
              className={styles.toolbar_actions_button}
              variant="secondary"
              disabled={openCanvasesDisabled}
              onClick={() => {
                openCanvas(selectedCanvasesIdsList[0]);
              }}
            >
              Open
            </Button>
          )}
          <Button
            className={styles.toolbar_actions_button}
            variant="crucial"
            disabled={deleteCanvasesDisabled}
            onClick={handleDeleteSelectedCanvases}
          >
            Delete
          </Button>
          <IconButton
            disabled={clearSelectedDisabled}
            onClick={handleDropSelection}
          >
            <BiX size={20} />
          </IconButton>
        </div>
      </div>
    );
  }, [
    selectedCanvasesIdsList,
    handleDeleteSelectedCanvases,
    processedCanvasesIdActionMap,
    isLoading,
  ]);

  return {
    searchQuery,
    isLoading,
    noCanvases,
    notFound,
    sortedItems,
    canvasesDisplayMode,
    canvasesSortingColumn,
    canvasesSortingDirection,
    processedCanvasesIdActionMap,
    selectedCanvasesIds,
    toolbar,
    canvasNameChangeHandler,
    handleDropSelection,
    handleCanvasClick,
    handleDeleteCanvases,
    handleChangeSorting,
    handleSearchQueryChange,
    openCanvas,
    handleOpenInvitationModal,
    handleOpenCanvasSettingsModal,
    removeProcessedCanvasId,
    setCanvases,
    setCanvasesDisplayMode,
    setIsLoading,
    setProcessedCanvasIdAction,
    setSearchQuery,
  };
};

export { useCanvases, CANVAS_ACTION, SORT_OPTIONS, type CanvasAction };
