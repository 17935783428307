import {
  createContext,
  useEffect,
  useCallback,
  useMemo,
  useState,
} from "react";
import { type ProfileSettings } from "store/slices/user-settings-slice";
import { useUserState, useUserSettingsState, useToastsState } from "store";
import {
  deleteUserAccount,
  getUserAvatar,
  getUserPreferences,
  getUserSettings,
  patchUserPassword,
  patchUserPreferences,
  patchUserSettings,
  postUserAvatar,
} from "api/http/user-settings-service";
import {
  camelCaseToSnakeCase,
  changeObjectSnakeCaseNameToCamelCase,
  changeObjectCamelCaseToSnakeCase,
  debouncePromise,
  snakeCaseToCamelCase,
} from "utils/helpers";
import { useAuth0 } from "@auth0/auth0-react";
import { getStringValidationError } from "utils/validation";
import { type UserSettingsProfileFormValues } from "components/Settings/UserSettingsProfile/config";
import { type FIELD_NAME as PREFERENCES_FIELD_NAME } from "components/Settings/UserSettingsPreferences/config";
import type { AI_MODEL } from "config/appConfig";

export const UserSettingsContext = createContext<{
  updateUserSecuritySettingsField: (
    fieldName: string,
    fieldValue: string
  ) => void;
  updateSecuritySettingsIsUpdated: (isUpdated: boolean) => void;
  updateUserPasswordApi: () => void;
  updateUserAvatar: (avatar: string) => void;
  updatePreferences: ((
    fieldName: PREFERENCES_FIELD_NAME,
    newData: boolean
  ) => void) &
    ((
      fieldName: PREFERENCES_FIELD_NAME.codeFontSize,
      newData: number
    ) => void) &
    ((fieldName: PREFERENCES_FIELD_NAME.aiModel, newData: AI_MODEL) => void) &
    ((fieldName: PREFERENCES_FIELD_NAME.aiModel, newData: boolean) => void);
  deleteAccount: () => void;
  cleanAccountDeleteError: () => void;
  handleSubmitProfileSetting: ({
    data,
    onSuccess,
    onError,
  }: {
    data: UserSettingsProfileFormValues;
    onSuccess?: () => void;
    onError: (error: unknown) => void;
  }) => Promise<void>;
  isFetchingSettings: boolean;
  isSubmitProfileSettingInProgress: boolean;
} | null>(null);

export const UserSettingsProvider = ({ children }: any) => {
  const userID = useUserState((slice) => slice.userID);
  const { user } = useAuth0();
  const addToast = useToastsState((slice) => slice.addToast);
  const [
    isSubmitProfileSettingInProgress,
    setIsSubmitProfileSettingInProgress,
  ] = useState(false);
  const [isFetchingSettings, setIsFetchingSettings] = useState(true);

  const securitySettingsStoreFields = useUserSettingsState(
    (slice) => slice.security.fields
  );
  const preferencesStore = useUserSettingsState((slice) => slice.preferences);

  const updateProfileSettingsStore = useUserSettingsState(
    (slice) => slice.updateProfileSettings
  );
  const updateSecuritySettingsFieldsStore = useUserSettingsState(
    (slice) => slice.updateSecuritySettingsFields
  );
  const updateSecuritySettingsIsUpdatedStore = useUserSettingsState(
    (slice) => slice.updateSecuritySettingsIsUpdated
  );
  const updatePreferencesSettingsStore = useUserSettingsState(
    (slice) => slice.updatePreferencesSettings
  );
  const updateProfileSettingsErrorStore = useUserSettingsState(
    (slice) => slice.updateProfileSettingsErrors
  );
  const updateSecuritySettingsErrorStore = useUserSettingsState(
    (slice) => slice.updateSecuritySettingsErrors
  );

  const updateProfileSettingsApi = useCallback(
    async (data: any) => {
      if (userID) {
        return await patchUserSettings(data);
      }
    },
    [userID]
  );

  const handleSubmitProfileSetting = useCallback(
    async ({
      data,
      onSuccess,
      onError,
    }: {
      data: UserSettingsProfileFormValues;
      onSuccess?: () => void;
      onError?: (error: unknown) => void;
    }) => {
      try {
        setIsSubmitProfileSettingInProgress(true);
        const requestData = Object.keys(data).reduce((res, key) => {
          res[camelCaseToSnakeCase(key)] = data[key] || null;

          return res;
        }, {});
        const responseData = await debouncePromise({
          promise: updateProfileSettingsApi(requestData),
        });
        /* update only fields were sent in request (prevents from BE issue, e.g there is incorrect
        avatar data in update response)
        https://linear.app/zerve-ai/issue/ZER-1318/patch-apiuser-serviceuser-settings-improvements
        https://linear.app/zerve-ai/issue/ZER-1317/add-full-path-to-all-apis-that-retrieve-a-user-avatar-filename
        */
        const filteredResponseData = Object.keys(requestData).reduce(
          (result, requestDataKey) => {
            result[snakeCaseToCamelCase(requestDataKey)] =
              responseData[requestDataKey];

            return result;
          },
          {}
        );
        updateProfileSettingsStore({ ...filteredResponseData });
        setIsSubmitProfileSettingInProgress(false);
        onSuccess?.();
      } catch (err) {
        setIsSubmitProfileSettingInProgress(false);
        onError?.(err);
      }
    },
    [updateProfileSettingsApi]
  );

  const updatePreferencesApi = useCallback(
    async (data: any) => {
      if (userID) {
        return await patchUserPreferences(data);
      }
    },
    [userID]
  );

  const updatePreferences = useCallback(
    (fieldName: PREFERENCES_FIELD_NAME, newData: number | boolean | string) => {
      updatePreferencesSettingsStore({ [fieldName]: newData });
      updatePreferencesApi(
        changeObjectCamelCaseToSnakeCase({
          ...preferencesStore,
          [fieldName]: newData,
        })
      );
    },
    [updatePreferencesApi, preferencesStore]
  );

  const updateUserAvatar = useCallback(
    async (avatar: string) => {
      if (userID) {
        await postUserAvatar(avatar).then((avatar) => {
          updateProfileSettingsStore({ avatar });
        });
      }
    },
    [userID]
  );

  const updateUserPasswordApi = useCallback(async () => {
    if (userID) {
      const passData = {
        user_auth0_id: user?.sub,
        old_password: securitySettingsStoreFields.oldPassword,
        new_password: securitySettingsStoreFields.newPassword,
      };
      await patchUserPassword(passData)
        .then(() => {
          updateSecuritySettingsFieldsStore({
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          });
          updateSecuritySettingsIsUpdatedStore(true);
        })
        .catch((err: any) => {
          updateSecuritySettingsErrorStore({
            oldPassword: err.message,
          });
        });
    }
  }, [userID, user, securitySettingsStoreFields]);

  const deleteAccount = useCallback(async () => {
    if (userID) {
      await deleteUserAccount(userID)
        .then(() => {
          updateProfileSettingsStore({ isAccountDeleted: true });
        })
        .catch(() => {
          updateProfileSettingsErrorStore({
            isAccountDeleted: "Error while deleting account",
          });
          addToast({
            message: "Failed to delete your account",
            variant: "error",
          });
        });
    }
  }, [userID]);

  const cleanAccountDeleteError = useCallback(() => {
    updateProfileSettingsErrorStore({ isAccountDeleted: null });
  }, []);

  const updateUserSecuritySettingsField = useCallback(
    (fieldName: string, fieldValue: string) => {
      let validationError: null | string = null;

      if (
        fieldName === "confirmPassword" &&
        fieldValue !== securitySettingsStoreFields.newPassword
      ) {
        validationError = "Passwords do not match";
      } else {
        validationError = getStringValidationError(fieldName, fieldValue);
      }

      if (validationError) {
        updateSecuritySettingsErrorStore({ [fieldName]: validationError });
      } else {
        updateSecuritySettingsErrorStore({ [fieldName]: null });
      }
      updateSecuritySettingsFieldsStore({ [fieldName]: fieldValue });
    },
    [securitySettingsStoreFields]
  );

  const updateSecuritySettingsIsUpdated = useCallback((isUpdated: boolean) => {
    updateSecuritySettingsIsUpdatedStore(isUpdated);
  }, []);

  // fetch initial data
  useEffect(() => {
    if (!userID) {
      return;
    }

    const getInitialSettings = async () => {
      setIsFetchingSettings(true);
      // fetch data
      const [userPreferencesResult, userSettingsResult, avatarResult] =
        await Promise.allSettled([
          getUserPreferences(),
          getUserSettings(),
          getUserAvatar(),
        ]);

      // store preferences
      if (userPreferencesResult.status === "fulfilled") {
        updatePreferencesSettingsStore({
          ...changeObjectSnakeCaseNameToCamelCase(userPreferencesResult.value),
        });
      }

      // format settings
      let settings: Partial<ProfileSettings> = { email: user?.email };
      if (userSettingsResult.status === "fulfilled") {
        settings = { ...settings, ...userSettingsResult.value };
      }
      if (avatarResult.status === "fulfilled") {
        settings = { ...settings, avatar: avatarResult.value };
      }

      // store settings
      updateProfileSettingsStore(
        changeObjectSnakeCaseNameToCamelCase(settings)
      );
      setIsFetchingSettings(false);
    };

    getInitialSettings();
  }, [userID]);

  const contextValue = useMemo(() => {
    return {
      updateUserSecuritySettingsField,
      updateSecuritySettingsIsUpdated,
      updateUserPasswordApi,
      updateUserAvatar,
      updatePreferences,
      deleteAccount,
      cleanAccountDeleteError,
      handleSubmitProfileSetting,
      isFetchingSettings,
      isSubmitProfileSettingInProgress,
    };
  }, [
    updateUserSecuritySettingsField,
    updateSecuritySettingsIsUpdated,
    updateUserPasswordApi,
    updateUserAvatar,
    updatePreferences,
    deleteAccount,
    cleanAccountDeleteError,
    handleSubmitProfileSetting,
    isFetchingSettings,
    isSubmitProfileSettingInProgress,
  ]);

  return (
    <UserSettingsContext.Provider value={contextValue}>
      {children}
    </UserSettingsContext.Provider>
  );
};
