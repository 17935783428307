import styles from "./ConfirmBlocksDeletionModal.module.scss";
import { useMemo } from "react";
import { useCanvasState, useCanvasBlocksState } from "store";
import { getBlockName } from "utils/helpers";
import { BLOCK_DETAILS } from "config/canvasConfig";
import type { BlockType } from "models/canvas";

const MAX_BLOCKS_TO_SHOW = 5;

type ConfirmBlocksDeletionModalProps = {
  blockIds: BlockType["id"][];
};

export function ConfirmBlocksDeletionModal({
  blockIds,
}: ConfirmBlocksDeletionModalProps) {
  const getBlock = useCanvasState((slice) => slice.getBlock);
  const getBlockData = useCanvasBlocksState((slice) => slice.getBlockData);

  const blocksToDisplay = useMemo(() => {
    return blockIds.slice(0, MAX_BLOCKS_TO_SHOW);
  }, [blockIds]);

  const extraCount = useMemo(() => {
    return blockIds.length - blocksToDisplay.length;
  }, [blockIds, blocksToDisplay]);

  return (
    <>
      {/* Question */}
      {blockIds.length === 1
        ? "Are you sure you want to delete this block?"
        : `Are you sure you want to delete these ${blockIds.length} blocks?`}

      {/* Blocks list */}
      <ul className={styles.blocksList}>
        {blocksToDisplay.map((blockId) => {
          const block = getBlock(blockId);
          if (!block) {
            return null;
          }

          const name = getBlockData(blockId)?.name;

          const blockName = getBlockName({
            name,
            type: block.type,
          });
          const Icon = BLOCK_DETAILS[block.type].Icon;

          return (
            <li key={blockId} className={styles.listItem}>
              <span className={styles.iconWithName}>
                {Icon ? <Icon className={styles.typeIcon} /> : null}
                {blockName}
              </span>
            </li>
          );
        })}
      </ul>

      {extraCount > 0 ? (
        <div className={styles.extraCount}>... and {extraCount} more</div>
      ) : null}
    </>
  );
}
