import styles from "./BlockContainer.module.scss";
import { type PropsWithChildren } from "react";
import cn from "classnames";
import BlockHeader from "components/blocks/BlockHeader/BlockHeader";
import { type BLOCK_STATUS } from "config/canvasConfig";
import { CONTAINER_STATUS_CLASS } from "./config";

type BlockLikeContainerProps = PropsWithChildren<{
  title: string;
  status: BLOCK_STATUS;
}>;

export function BlockLikeContainer({
  title,
  status,
  children,
}: BlockLikeContainerProps) {
  const containerStatusClass = CONTAINER_STATUS_CLASS[status];

  return (
    <div className={cn(styles.container, containerStatusClass)}>
      <div className={styles.container__content}>
        <BlockHeader
          zoom={1}
          title={title}
          status={status}
          disableEdit={true}
        />
        {children}
      </div>
    </div>
  );
}
