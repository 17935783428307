import styles from "./WorkspaceGroupMembers.module.scss";
import { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useUserState, useToastsState } from "store";
import { Button } from "components/common/Button/Button";
import { Checkbox } from "components/common/Checkbox/Checkbox";
import Table from "components/common/Table/Table";
import FormDropdown from "components/common/FormDropdown/FormDropdown";
import { TitleSection } from "components/organization/TitleSection/TitleSection";
import { EmptyState } from "components/common/EmptyState/EmptyState";
import { getOrganization } from "api/http/organization-service";
import {
  getWorkspace,
  getWorkspaceGroupMembers,
  postWorkspaceGroupMembers,
  updateWorkspaceGroupMember,
  deleteWorkspaceGroupMembers,
} from "api/http/workspace-service";
import { getGroups } from "api/http/group-service";
import { getRoles } from "api/http/role-service";
import {
  useReactTable,
  getCoreRowModel,
  createColumnHelper,
} from "@tanstack/react-table";
import type { Organization, Workspace } from "models/organization";

// type declaration for organization table rows
type OrganizationGroupMember = {
  id: string;
  name: string;
};

// type declaration for table rows
type WorkspaceGroupMember = {
  id: string;
  role_id: string;
  group: {
    id: string;
    name: string;
  };
};
// type declaration for role options for role selector dropdown
type RoleOption = {
  label: string;
  value: string;
};

export function WorkspaceGroupMembers() {
  const userID = useUserState((slice) => slice.userID);
  const { workspaceID = "", orgID = "" } = useParams();
  const addToast = useToastsState((slice) => slice.addToast);
  const [organization, setOrganization] = useState<Organization>();
  const [workspace, setWorkspace] = useState<Workspace>();

  const [organizationRoleOptions, setOrganizationRoleOptions] = useState<
    RoleOption[]
  >([]);
  const [workspaceRoleOptions, setWorkspaceRoleOptions] = useState<
    RoleOption[]
  >([]);

  // Fetch organization members and workspace members when the component loads.
  useEffect(() => {
    async function initializeWorkspace() {
      const organizationResult = await getOrganization(orgID);
      setOrganization(organizationResult);
      const workspaceResult = await getWorkspace(orgID, workspaceID);
      setWorkspace(workspaceResult);
      updateWorkspaceGroupMembers(workspaceID);
      updateOrganizationGroupMembers(orgID);
      const allRoles = await getRoles(orgID);
      setOrganizationRoleOptions(
        allRoles
          .filter(
            (role) =>
              role.name === "Organization Admin" || role.name === "Member"
          )
          .map((nextRole) => {
            return { label: nextRole.name, value: nextRole.id };
          })
      );
      setWorkspaceRoleOptions(
        allRoles
          .filter(
            (role) =>
              (role.name !== "Super Admin" &&
                role.name !== "Organization Admin" &&
                role.name !== "Member") ||
              role.organization_id === orgID
          )
          .map((nextRole) => {
            return { label: nextRole.name, value: nextRole.id };
          })
      );
    }
    if (userID && workspaceID) {
      initializeWorkspace();
    }
  }, [userID, orgID, workspaceID]);

  // States for organization members table
  const [allOrganizationGroupMembers, setAllOrganizationGroupMembers] =
    useState<any[]>([]);
  const [orgTableData, setOrgTableData] = useState<any[]>([]);
  const [checkedForAddition, setCheckedForAddition] = useState<string[]>([]);
  const [isOrgSelectedAll, setOrgIsSelectedAll] = useState(false);

  // Handle select all for the organization table
  const handleOrgSelectAll = () => {
    if (isOrgSelectedAll) {
      setCheckedForAddition([]);
    } else {
      setCheckedForAddition(
        orgTableData.map((nextItem: OrganizationGroupMember) => nextItem.id)
      );
    }
  };

  // Check if all items are selected in the organization table
  useEffect(() => {
    if (checkedForAddition.length === 0) {
      setOrgIsSelectedAll(false);
      return;
    }
    for (const nextItem of orgTableData) {
      if (!checkedForAddition.includes(nextItem.id)) {
        setOrgIsSelectedAll(false);
        return;
      }
    }
    setOrgIsSelectedAll(true);
  }, [checkedForAddition]);

  // States for workspace membership table
  const [workspaceTableData, setWorkspaceTableData] = useState<any[]>([]);
  const [checkedForDeletion, setCheckedForDeletion] = useState<string[]>([]);
  const [isWorkspaceSelectedAll, setIsWorkspaceSelectedAll] = useState(false);

  // Handle select all for the workspace table
  const handleSelectAll = () => {
    if (isWorkspaceSelectedAll) {
      setCheckedForDeletion([]);
    } else {
      setCheckedForDeletion(
        workspaceTableData.map(
          (nextItem: WorkspaceGroupMember) => nextItem.group.id
        )
      );
    }
  };

  // Check if all items are selected in the workspace table
  useEffect(() => {
    if (checkedForDeletion.length === 0) {
      setIsWorkspaceSelectedAll(false);
      return;
    }
    for (const nextItem of workspaceTableData) {
      if (!checkedForDeletion.includes(nextItem.group.id)) {
        setIsWorkspaceSelectedAll(false);
        return;
      }
    }
    setIsWorkspaceSelectedAll(true);
  }, [checkedForDeletion]);

  // Check if a user is an admin of the workspace
  const isWorkspaceAdmin = () => {
    if (
      organization?.role === "Organization Admin" ||
      workspace?.is_admin === true
    ) {
      return true;
    }
    return false;
  };

  // Check if a user is already in the workspace
  const isInWorkspace = (id: string) => {
    const found = workspaceTableData.findIndex(
      (nextRow) => nextRow.group.id === id
    );
    return found !== -1;
  };

  // Update the list of organization members
  const updateOrganizationGroupMembers = (organizationId: string) => {
    getGroups(organizationId)
      .then((returnedValues) => {
        setAllOrganizationGroupMembers(returnedValues);
      })
      .catch((error) => {
        addToast({
          message: error.message,
          variant: "error",
        });
      });
  };

  // Update the list of workspace members
  const updateWorkspaceGroupMembers = (workspaceId: string) => {
    getWorkspaceGroupMembers(orgID, workspaceId)
      .then((returnedValues) => {
        setWorkspaceTableData(returnedValues);
      })
      .catch((error) => {
        addToast({
          message: error.message,
          variant: "error",
        });
      });
  };

  // When the workspace or organization members change, update the organization table data
  useEffect(() => {
    if (
      !allOrganizationGroupMembers ||
      allOrganizationGroupMembers.length === 0
    ) {
      return;
    }
    const filtererOrganizationGroupMembers = allOrganizationGroupMembers.filter(
      (nextMember) => {
        return !isInWorkspace(nextMember.id);
      }
    );
    setOrgTableData(filtererOrganizationGroupMembers);
  }, [workspaceTableData, allOrganizationGroupMembers]);

  // Get a workspace role name from the id
  const getWorkspaceRoleLabelFromId = (roleId: string) => {
    if (!workspaceRoleOptions || workspaceRoleOptions.length === 0) {
      return "";
    }
    const selectedOption = workspaceRoleOptions.find((option) => {
      return option.value === roleId;
    });
    return selectedOption?.label ? selectedOption.label : "";
  };

  const addMembers = () => {
    postWorkspaceGroupMembers(orgID, workspaceID, checkedForAddition)
      .then(() => {
        addToast({
          message: "Member(s) successfully added to workspace.",
          variant: "success",
          duration: 1000,
        });
        updateWorkspaceGroupMembers(workspaceID);
        setCheckedForAddition([]);
      })
      .catch((error) => {
        addToast({
          message: error.message,
          variant: "error",
          duration: 1000,
        });
      });
  };

  const removeMembers = () => {
    deleteWorkspaceGroupMembers(orgID, workspaceID, checkedForDeletion)
      .then(() => {
        addToast({
          message: "Member(s) successfully deleted from workspace.",
          variant: "success",
          duration: 1000,
        });
        updateWorkspaceGroupMembers(workspaceID);
        setCheckedForDeletion([]);
      })
      .catch((error) => {
        addToast({
          message: error.message,
          variant: "error",
          duration: 1000,
        });
      });
  };

  // Organization Table column definitions
  const organizationColumnHelper =
    createColumnHelper<OrganizationGroupMember>();
  const organizationColumns = useMemo(
    () => [
      organizationColumnHelper.accessor((row) => row.id, {
        id: "checkbox",
        cell: (info) => {
          const handleCheck = (check: boolean, rowId: string) => {
            if (check) {
              setCheckedForAddition([...checkedForAddition, rowId]);
            } else {
              setCheckedForAddition(
                checkedForAddition.filter((value) => value !== rowId)
              );
            }
          };
          return (
            <div style={{ width: "40px" }}>
              <Checkbox
                onChange={() => {
                  handleCheck(
                    !checkedForAddition.includes(info.getValue()),
                    info.getValue()
                  );
                }}
                checked={checkedForAddition.includes(info.getValue())}
                disabled={isInWorkspace(info.getValue()) || !isWorkspaceAdmin()}
              />
            </div>
          );
        },
        header: () => (
          <Checkbox
            checked={isOrgSelectedAll}
            onChange={handleOrgSelectAll}
            disabled={!isWorkspaceAdmin()}
          />
        ),
        enableSorting: false,
      }),
      organizationColumnHelper.accessor((row) => row.name, {
        id: "name",
        cell: (info) => (
          <span
            className={
              isInWorkspace(info.row.original.id) || !isWorkspaceAdmin()
                ? styles.mutedRow
                : ""
            }
          >
            {info.getValue()}
          </span>
        ),
        header: () => <span>User Name</span>,
        enableSorting: false,
      }),
    ],
    [
      checkedForAddition,
      isOrgSelectedAll,
      orgTableData,
      workspaceTableData,
      organizationRoleOptions,
    ]
  );

  // Workspace table column definitions
  const workspaceColumnHelper = createColumnHelper<WorkspaceGroupMember>();
  const workspaceColumns = useMemo(
    () => [
      workspaceColumnHelper.accessor((row) => row.group.id, {
        id: "checkbox",
        cell: (info) => {
          const handleCheck = (check: boolean, rowId: string) => {
            if (check) {
              setCheckedForDeletion([...checkedForDeletion, rowId]);
            } else {
              setCheckedForDeletion(
                checkedForDeletion.filter((value) => value !== rowId)
              );
            }
          };
          return (
            <div style={{ width: "40px" }}>
              <Checkbox
                onChange={() => {
                  handleCheck(
                    !checkedForDeletion.includes(info.getValue()),
                    info.getValue()
                  );
                }}
                checked={checkedForDeletion.includes(info.getValue())}
                disabled={!isWorkspaceAdmin()}
              />
            </div>
          );
        },
        header: () => (
          <Checkbox
            checked={isWorkspaceSelectedAll}
            onChange={handleSelectAll}
            disabled={!isWorkspaceAdmin()}
          />
        ),
        enableSorting: false,
      }),
      workspaceColumnHelper.accessor((row) => row.group.name, {
        id: "name",
        cell: (info) => (
          <span className={!isWorkspaceAdmin() ? styles.mutedRow : ""}>
            {info.getValue()}
          </span>
        ),
        header: () => <span>User Name</span>,
        enableSorting: false,
      }),
      workspaceColumnHelper.accessor((row) => row.role_id, {
        id: "role_select",
        cell: (info) => {
          const handleChange = (roleId) => {
            updateWorkspaceGroupMember(
              orgID,
              workspaceID,
              info.row.original.group.id,
              roleId
            ).then(() => {
              addToast({
                message: "Members role successfully changed.",
                variant: "success",
                duration: 1000,
              });
              updateWorkspaceGroupMembers(workspaceID);
            });
          };
          return (
            <div style={{ width: "95%" }}>
              <FormDropdown
                id="role"
                name="role"
                label=""
                placeholder="Select Role"
                options={workspaceRoleOptions}
                value={getWorkspaceRoleLabelFromId(info.getValue())}
                onChange={handleChange}
                disabled={!isWorkspaceAdmin()}
              />
            </div>
          );
        },
        header: () => <span>Workspace Member Role</span>,
        enableSorting: false,
      }),
    ],
    [
      checkedForDeletion,
      isWorkspaceSelectedAll,
      workspaceTableData,
      workspaceRoleOptions,
    ]
  );

  // Create react table for the organization members
  const organizationTable = useReactTable({
    data: orgTableData,
    columns: organizationColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  // Create react table for the workspace members
  const workspaceTable = useReactTable({
    data: workspaceTableData,
    columns: workspaceColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  return !workspaceID ? (
    <div className={styles.emptyBlockWrapper}>
      <EmptyState
        variant="info"
        title="No Workspace..."
        description="You must first set up a workspace before adding members."
      />
    </div>
  ) : (
    <div className={styles.page}>
      <div className={styles.container}>
        <TitleSection
          title="Workspace Group Members"
          subTitle={
            <>
              Manage groups in your workspace and set their access level. Only
              groups already in your organization can be added to a workspace.
            </>
          }
        />
        <div className={styles.tableSectionContainer}>
          <div className={styles.tableSection}>
            <div className={styles.tableTitle}>Available Groups</div>
            <Table table={organizationTable} />
            <div className={styles.buttonSection}>
              <Button
                variant="primary"
                onClick={addMembers}
                disabled={checkedForAddition.length === 0}
              >
                Add Selected Groups
              </Button>
            </div>
          </div>
          <div className={styles.tableSection}>
            <div className={styles.tableTitle}>Included Groups</div>
            <Table table={workspaceTable} />
            <div className={styles.buttonSection}>
              <Button
                variant="crucial"
                onClick={removeMembers}
                disabled={checkedForDeletion.length === 0}
              >
                Remove Selected Groups
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
