import { ASSET_API_URL } from "config/appConfig";
import { callAPI } from "../call-api";
import type {
  FunctionType,
  FunctionItemType,
  FunctionVersionType,
} from "pages/Assets/types/functionTypes";

const getUserFunctions = async () => {
  return await callAPI({
    url: `${ASSET_API_URL}/functions/user`,
    method: "GET",
  });
};

const getWorkspaceFunctions = async (workspaceId: string) => {
  return await callAPI({
    url: `${ASSET_API_URL}/functions/workspace/${workspaceId}`,
    method: "GET",
  });
};

const postFunction = async (data: {
  asset: {
    name: string;
  };
  version: {
    language: string;
  };
}) =>
  await callAPI({
    url: `${ASSET_API_URL}/functions`,
    method: "POST",

    data,
  });

const postWorkspaceFunction = async (
  workspaceId: string,
  data: {
    asset: {
      name: string;
    };
    version: {
      language: string;
    };
  }
) =>
  await callAPI({
    url: `${ASSET_API_URL}/functions/workspace/${workspaceId}`,
    method: "POST",

    data,
  });

const putFunction = async (
  id: string,
  data: {
    name: string;
  }
) =>
  await callAPI<FunctionType>({
    url: `${ASSET_API_URL}/functions/${id}`,
    method: "PUT",

    data,
  });

const deleteFunctionById = async (functionId: string) =>
  await callAPI({
    url: `${ASSET_API_URL}/functions/${functionId}`,
    method: "DELETE",
  });

const postFunctionVersion = async (data: {
  asset_id: string;
  language: string;
}) =>
  await callAPI({
    url: `${ASSET_API_URL}/functions/version`,
    method: "POST",

    data,
  });

const patchFunctionVersion = async (
  assetVersionId: string,
  data: {
    code?: string;
    requirements?: [];
    imports?: string;
    language?: string;
    language_version?: string;
    id: string;
  }
) =>
  await callAPI({
    url: `${ASSET_API_URL}/functions/version/${assetVersionId}`,
    method: "PATCH",

    data,
  });

const putFunctionAssetArchive = async (data: {
  asset_id: string;
  archive: boolean;
}) =>
  await callAPI<Record<string, FunctionItemType>>({
    url: `${ASSET_API_URL}/functions/${data.asset_id}/archive`,
    method: "PUT",

    data,
  });

const deleteFunctionVersion = async (functionVersionId: string) =>
  await callAPI({
    url: `${ASSET_API_URL}/functions/version/${functionVersionId}`,
    method: "DELETE",
  });

const putFunctionVersionArchive = async (id: string, archive: boolean) =>
  await callAPI<FunctionVersionType>({
    url: `${ASSET_API_URL}/functions/version/${id}/archive`,
    method: "PUT",

    data: {
      archive,
    },
  });

const putFunctionVersionStatus = async (id: string, status: number) =>
  await callAPI({
    url: `${ASSET_API_URL}/functions/version/${id}/status`,
    method: "PUT",

    data: {
      status,
    },
  });

export {
  getUserFunctions,
  getWorkspaceFunctions,
  postFunction,
  postWorkspaceFunction,
  putFunction,
  deleteFunctionById,
  deleteFunctionVersion,
  postFunctionVersion,
  putFunctionAssetArchive,
  putFunctionVersionStatus,
  putFunctionVersionArchive,
  patchFunctionVersion,
};
