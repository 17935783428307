import styles from "./Modal.module.scss";
import { useCallback, useEffect, useRef, type PropsWithChildren } from "react";
import { motion } from "framer-motion";
import cn from "classnames";
import { BiX } from "react-icons/bi";
import { useHotkeys, useHotkeysContext } from "react-hotkeys-hook";
import IconButton from "components/common/IconButton/IconButton";
import { useLocationChange } from "hooks/useLocationChange";
import { FORM_TAGS } from "config/appConfig";
import { HOTKEY_SCOPE_ID } from "config/hotkeyConfig";

export type ModalProps = PropsWithChildren<{
  id?: string;
  title: string;
  classes?: {
    container?: string;
    title?: string;
  };
  closeOnLocationChange?: boolean;
  onClose: () => void;
}>;

export function Modal({
  id,
  children,
  title,
  classes,
  closeOnLocationChange = true,
  onClose,
}: ModalProps) {
  const contentElementRef = useRef<HTMLDivElement>(null);
  const { enableScope, disableScope, enabledScopes } = useHotkeysContext();
  const hotkeyScopeId = id ? `${HOTKEY_SCOPE_ID.MODAL}-${id}` : undefined;

  useEffect(() => {
    hotkeyScopeId && enableScope(hotkeyScopeId);

    return () => {
      hotkeyScopeId && disableScope(hotkeyScopeId);
    };
  }, [hotkeyScopeId]);

  const handleCloseModal = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleBackdropClick = useCallback(() => {
    handleCloseModal();
  }, [handleCloseModal]);

  useLocationChange({
    onChange: () => {
      closeOnLocationChange && handleCloseModal();
    },
  });

  useHotkeys(
    "esc",
    () => {
      const activeElement = document.activeElement;

      if (
        activeElement instanceof HTMLElement &&
        contentElementRef.current?.contains(activeElement) &&
        FORM_TAGS.includes(activeElement.tagName)
      ) {
        /* deactivate focused form field as we do not want
        to loose the form/field data when press hotkey unintentionally. */
        activeElement.blur();

        return;
      }

      handleCloseModal();
    },
    {
      enabled: hotkeyScopeId
        ? enabledScopes[enabledScopes.length - 1] === hotkeyScopeId
        : true,
      preventDefault: true,
      enableOnFormTags: true,
      scopes: hotkeyScopeId,
    },
    [handleCloseModal]
  );

  return (
    <div className={styles.backdrop} onClick={handleBackdropClick}>
      <motion.div
        ref={contentElementRef}
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={cn(styles.modal, classes?.container)}
        key="modal"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          duration: 0.2,
          type: "spring",
          damping: 12,
          stiffness: 100,
        }}
      >
        {/* header */}
        <div className={styles.header}>
          <h4 className={cn(styles.title, classes?.title)}>{title}</h4>
          <IconButton variant="plain" onClick={handleCloseModal}>
            <BiX size={20} />
          </IconButton>
        </div>

        {/* content */}
        {children}
      </motion.div>
    </div>
  );
}
