import styles from "./EditableConnectionsNavItem.module.scss";
import {
  type ChangeEvent,
  type KeyboardEvent,
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  autoUpdate,
  flip,
  offset,
  shift,
  useFloating,
  FloatingPortal,
} from "@floating-ui/react";
import cn from "classnames";
import { BiError } from "react-icons/bi";
import Loader from "components/common/Loader/Loader";
import TextField from "components/common/TextField/TextField";
import Typography from "components/Typography/Typography";
import { useAssetsState } from "store";
import useConnectionActions from "hooks/assets/useConnectionActions";
import { getStringValidationError } from "utils/validation";
import { getConnectionTypeIcon } from "../../utils";
import { ConnectionsIcon } from "../icons";

const INPUT_PLACEHOLDER = "Enter Connection Name";

const EditableConnectionsNavItem = () => {
  const { createConnection, updateConnectionName } = useConnectionActions();
  const { clearCreatedItem, setCreatedItemError, setCreatedItemData } =
    useAssetsState((slice) => slice.connectionsActions);

  const { connectionTypes, createdItem } = useAssetsState(
    (slice) => slice.connections
  );

  const {
    assetOperationType,
    error,
    data: { name, connection_type_id },
    loading,
  } = createdItem;

  const [hasFocus, setHasFocus] = useState(false);
  const textFieldRef = useRef<HTMLInputElement>(null);

  const handleNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setCreatedItemError(null);
    setCreatedItemData({ name: e.target.value });
  }, []);

  const handleFocus = useCallback(() => {
    setHasFocus(true);
  }, []);

  const validate = useCallback(() => {
    const error = getStringValidationError("assetConnectionName", name);

    if (error) {
      setCreatedItemError(error);
    }

    return error;
  }, [name]);

  useEffect(() => {
    if (textFieldRef.current && error && !hasFocus) {
      textFieldRef.current.focus();
    }
  }, [error, hasFocus]);

  useEffect(() => {
    return () => {
      setCreatedItemError(null);
    };
  }, []);

  const handleSubmit = useCallback(() => {
    if (loading) {
      return;
    }
    if (assetOperationType === "create") {
      createConnection();
    } else if (assetOperationType === "edit") {
      updateConnectionName();
    } else {
      clearCreatedItem();
    }

    setHasFocus(false);
  }, [assetOperationType, updateConnectionName, createConnection, loading]);

  const handleCancel = useCallback(() => {
    clearCreatedItem();
  }, []);

  const handleBlur = useCallback(() => {
    setCreatedItemError(null);
    const error = validate();

    if (!error) {
      handleSubmit();
    } else {
      setHasFocus(false);
    }
  }, [validate]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLElement>) => {
      setCreatedItemError(null);
      const { key } = e;

      if (key === "Enter") {
        const error = validate();

        if (!error) {
          handleSubmit();
        }
      }
      if (key === "Escape") {
        handleCancel();
      }
    },
    [validate, handleSubmit]
  );

  const Icon = useMemo(() => {
    if (!connection_type_id) {
      return ConnectionsIcon;
    }

    const connectionTypeData = connectionTypes[connection_type_id];
    const icon = getConnectionTypeIcon(connectionTypeData?.connection_type);

    return icon;
  }, [connectionTypes, connection_type_id]);

  const { x, y, refs, strategy } = useFloating({
    open: Boolean(error),
    whileElementsMounted: autoUpdate,
    placement: "right-start",
    middleware: [offset(8), flip(), shift()],
  });

  const renderErrorNotification = (errorText: string): ReactNode => (
    <div
      ref={refs.setFloating}
      style={{
        position: strategy,
        top: y ?? 0,
        left: x ?? 0,
      }}
      className={styles.error}
    >
      <BiError className={styles.error_icon} size={20} />
      <Typography variant="caption1" className={styles.error_text}>
        {errorText}
      </Typography>
    </div>
  );

  return (
    <div
      ref={refs.setReference}
      className={cn(styles.container, {
        [styles.container_edit_mode]: assetOperationType === "edit",
      })}
    >
      <div className={styles.form}>
        <div className={styles.icon}>
          <Icon />
        </div>
        <TextField
          autoFocus
          placeholder={INPUT_PLACEHOLDER}
          inputClassName={cn(styles.input, {
            [styles.loading]: loading,
          })}
          containerClassName={styles.text_field}
          value={name}
          onChange={handleNameChange}
          ref={textFieldRef}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          innerControls={
            loading ? (
              <Loader
                size={16}
                classes={{
                  overlay: styles.spinnerContainer,
                }}
                withOverlay
              />
            ) : null
          }
        />
      </div>
      {error && (
        <FloatingPortal>{renderErrorNotification(error)}</FloatingPortal>
      )}
    </div>
  );
};

export default EditableConnectionsNavItem;
