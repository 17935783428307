import LoadingScreen from "components/common/LoadingScreen/LoadingScreen";
import { HtmlWidget } from "components/outputs/HtmlWidget/HtmlWidget";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Typography from "components/Typography";
import { LinkButton } from "components/common/Button/LinkButton";
import styles from "./PublishedReport.module.scss";
import { fetchPresignedUrl } from "api/http/canvas-report-service";
import { Button } from "components/common/Button/Button";

const errorMessage = "No report to show";

export function PublishedReport() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);
  // get reportName from url using useParams
  const { reportName } = useParams<{ reportName: string }>();

  const [presignedUrl, setPresignedUrl] = useState<string | null>(null);

  const getPresignedUrl = useCallback(async () => {
    try {
      setIsError(false);
      setIsLoaded(false);
      // fetch presigned url from published report service
      if (!reportName) {
        throw new Error("Report name is missing");
      }
      const url = await fetchPresignedUrl(reportName);
      setPresignedUrl(url);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoaded(true);
    }
  }, [reportName]);

  // fetch presigned url from published report service
  useEffect(() => {
    getPresignedUrl();
  }, []);

  if (!isLoaded) {
    return <LoadingScreen text="Loading report..." />;
  }

  if (isError) {
    return (
      <div className="fullScreenMessage">
        <Typography variant="h3">{errorMessage}</Typography>
        <div className={styles.actions}>
          <LinkButton variant="primary" to="/">
            Go Home
          </LinkButton>
          <Button variant="secondary" onClick={getPresignedUrl}>
            Try Again
          </Button>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.container}>
      <HtmlWidget url={presignedUrl || ""} />
    </div>
  );
}
