import { type SVGProps } from "react";

const ConnectionsIcon = ({
  color = "currentColor",
  width = "20",
  height = "20",
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      id="connections"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g fill={color} fillRule="nonzero">
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.99967 17.0832C10.9201 17.0832 11.6663 16.337 11.6663 15.4165C11.6663 14.496 10.9201 13.7498 9.99967 13.7498C9.0792 13.7498 8.33301 14.496 8.33301 15.4165C8.33301 16.337 9.0792 17.0832 9.99967 17.0832ZM13.7497 6.24984C14.6701 6.24984 15.4163 5.50365 15.4163 4.58317C15.4163 3.6627 14.6701 2.9165 13.7497 2.9165C12.8292 2.9165 12.083 3.6627 12.083 4.58317C12.083 5.50365 12.8292 6.24984 13.7497 6.24984ZM6.24967 6.24984C7.17015 6.24984 7.91634 5.50365 7.91634 4.58317C7.91634 3.6627 7.17015 2.9165 6.24967 2.9165C5.3292 2.9165 4.58301 3.6627 4.58301 4.58317C4.58301 5.50365 5.3292 6.24984 6.24967 6.24984ZM6.24967 7.49984C7.8605 7.49984 9.16634 6.194 9.16634 4.58317C9.16634 2.97234 7.8605 1.6665 6.24967 1.6665C4.63884 1.6665 3.33301 2.97234 3.33301 4.58317C3.33301 6.194 4.63884 7.49984 6.24967 7.49984ZM16.6663 4.58317C16.6663 6.194 15.3605 7.49984 13.7497 7.49984C12.1388 7.49984 10.833 6.194 10.833 4.58317C10.833 2.97234 12.1388 1.6665 13.7497 1.6665C15.3605 1.6665 16.6663 2.97234 16.6663 4.58317ZM12.9163 15.4165C12.9163 17.0273 11.6105 18.3332 9.99967 18.3332C8.38884 18.3332 7.08301 17.0273 7.08301 15.4165C7.08301 13.8057 8.38884 12.4998 9.99967 12.4998C11.6105 12.4998 12.9163 13.8057 12.9163 15.4165Z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.25 6.875C6.59518 6.875 6.875 7.15482 6.875 7.5V7.82971C6.875 8.31794 7.2141 8.74066 7.6907 8.84658L8.83047 9.09986C9.29129 9.20226 9.69369 9.4393 10 9.76404C10.3063 9.4393 10.7087 9.20226 11.1695 9.09986L12.3093 8.84658C12.7859 8.74066 13.125 8.31794 13.125 7.82971V7.5C13.125 7.15482 13.4048 6.875 13.75 6.875C14.0952 6.875 14.375 7.15482 14.375 7.5V7.82971C14.375 8.90381 13.629 9.8338 12.5805 10.0668L11.4407 10.3201C10.9641 10.426 10.625 10.8487 10.625 11.337V12.5C10.625 12.8452 10.3452 13.125 10 13.125C9.65482 13.125 9.375 12.8452 9.375 12.5V11.337C9.375 10.8487 9.0359 10.426 8.5593 10.3201L7.41953 10.0668C6.37101 9.8338 5.625 8.90382 5.625 7.82971V7.5C5.625 7.15482 5.90482 6.875 6.25 6.875Z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default ConnectionsIcon;
