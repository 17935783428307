import styles from "./DropdownMenuButton.module.scss";
import {
  useEffect,
  useMemo,
  useState,
  type ElementType,
  type PropsWithChildren,
  type ReactNode,
  type ReactElement,
  useCallback,
} from "react";
import {
  autoUpdate,
  flip,
  hide,
  offset,
  shift,
  useDismiss,
  useFloating,
  type Placement,
  FloatingPortal,
} from "@floating-ui/react";
import { Button } from "components/common/Button/Button";
import ContextMenu from "components/common/ContextMenu/ContextMenu";
import { type ContextMenuItem } from "components/common/ContextMenu/ContextMenu";
import { type ButtonProps } from "components/common/Button/Button";

import cn from "classnames";
import { BiCaretDown, BiCaretUp } from "react-icons/bi";

const ConditionalFloatingPortalWrapper = ({
  wrapWithPortal = false,
  children,
}: {
  wrapWithPortal: boolean;
  children: ReactElement;
}) => {
  if (wrapWithPortal) {
    return <FloatingPortal>{children}</FloatingPortal>;
  }

  return children;
};

type DropdownMenuButtonProps = PropsWithChildren<{
  text?: ReactNode;
  icon?: ElementType;
  menuOptions: ContextMenuItem[];
  placement?: Placement;
  disabled?: boolean;
  hideChevron?: boolean;
  buttonSize?: ButtonProps["size"];
  buttonVariant?: ButtonProps["variant"];
  closeOnSelect?: boolean;
  contextMenuFitWidth?: boolean;
  wrapContextMenuWithFloatingPortal?: boolean;
  hideFloatingElWhenRefElIsHidden?: boolean;
  nodesClassNames?: {
    text?: string;
    container?: string;
    containerIsOpen?: string;
    button?: string;
    contextMenu?: string;
    contextMenuContainer?: string;
  };
  onDropdownMenuOpen?: () => void;
}>;

export function DropdownMenuButton({
  text,
  icon: Icon,
  disabled,
  menuOptions,
  placement = "bottom-end",
  hideChevron,
  children,
  buttonSize = "small",
  buttonVariant = "secondary",
  closeOnSelect = true,
  contextMenuFitWidth = false,
  wrapContextMenuWithFloatingPortal = false,
  hideFloatingElWhenRefElIsHidden = false,
  nodesClassNames: {
    text: textClassName = "",
    container: containerClassName = "",
    containerIsOpen: containerIsOpenClassName = "",
    button: buttonClassName = "",
    contextMenu: contextMenuClassName = "",
    contextMenuContainer: contextMenuContainerClassName = "",
  } = {},
  onDropdownMenuOpen,
}: DropdownMenuButtonProps) {
  const [open, setOpen] = useState(false);

  const toggleDropdownMenu = useCallback(() => {
    setOpen((prev) => {
      if (!prev) {
        onDropdownMenuOpen?.();
      }
      return !prev;
    });
  }, [onDropdownMenuOpen]);

  const floatingMiddleware = useMemo(() => {
    const middleware = [offset(5), flip(), shift()];

    if (hideFloatingElWhenRefElIsHidden) {
      middleware.push(hide());
    }

    return middleware;
  }, [hideFloatingElWhenRefElIsHidden]);

  const { x, y, refs, strategy, context, middlewareData } = useFloating({
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    placement,
    middleware: floatingMiddleware,
  });

  useEffect(() => {
    if (
      hideFloatingElWhenRefElIsHidden &&
      middlewareData.hide?.referenceHidden
    ) {
      setOpen(false);
    }
  }, [hideFloatingElWhenRefElIsHidden, middlewareData.hide?.referenceHidden]);

  useDismiss(context, {
    escapeKey: true,
    outsidePress: true,
  });

  function handleButtonClick(e) {
    e.stopPropagation();
    toggleDropdownMenu();
  }

  function cheveronClick(e) {
    if (disabled) {
      return;
    }
    e.stopPropagation();
    toggleDropdownMenu();
  }

  return (
    <div
      ref={refs.setReference}
      className={cn(styles.container, containerClassName, {
        [containerIsOpenClassName]: open,
      })}
    >
      <Button
        disabled={disabled}
        size={buttonSize}
        variant={buttonVariant}
        onClick={handleButtonClick}
        className={cn(styles.button, buttonClassName, {
          [styles["button-open"]]: open,
        })}
      >
        <>
          {Icon && (
            <Icon
              className={styles.button__icon}
              style={text ? {} : { marginRight: 0 }}
            />
          )}
          {text ? (
            <span className={cn(styles.buttonText, textClassName)}>{text}</span>
          ) : null}
          {children ? children : null}
          {!hideChevron &&
            (open ? (
              <BiCaretUp
                className={styles.button__arrow}
                onClick={cheveronClick}
              />
            ) : (
              <BiCaretDown
                className={styles.button__arrow}
                onClick={cheveronClick}
              />
            ))}
        </>
      </Button>
      {open && (
        <ConditionalFloatingPortalWrapper
          wrapWithPortal={wrapContextMenuWithFloatingPortal}
        >
          <div
            ref={refs.setFloating}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
            onClick={() => {
              toggleDropdownMenu();
            }}
            className={cn(styles.contextMenuContainer, contextMenuClassName)}
          >
            <ContextMenu
              menuOptions={menuOptions}
              onClose={() => {
                setOpen(false);
              }}
              closeOnSelect={closeOnSelect}
              fitWidth={contextMenuFitWidth}
              containerClassName={contextMenuContainerClassName}
            />
          </div>
        </ConditionalFloatingPortalWrapper>
      )}
    </div>
  );
}
