import React from "react";
import styles from "./HtmlWidget.module.scss";

interface HtmlWidgetProps {
  url: string;
}

export const HtmlWidget: React.FC<HtmlWidgetProps> = ({ url }) => {
  return (
    <div className={styles.htmlWidget}>
      <iframe src={url} title="HTML Widget" className={styles.iframe} />
    </div>
  );
};
