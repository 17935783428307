import styles from "./BlockComputeTypeInfo.module.scss";
import { type ReactNode } from "react";
import cn from "classnames";
import { safePolygon } from "@floating-ui/react";
import { useCanvasBlocksState } from "store";
import { useCanvasActions } from "hooks/useCanvasActions";
import { useLayerBlocksRunDisabilityReasons } from "hooks/useLayerBlocksRunDisabilityReasons";
import { Button } from "components/common/Button/Button";
import Tooltip from "components/common/Tooltip/Tooltip";
import { ComputeInstanceItem } from "components/ComputeInstanceItem/ComputeInstanceItem";

import type { BlockType } from "models/canvas";
import {
  COMPUTE_ENV_TYPE,
  COMPUTE_ENV_TYPE_CONFIG,
  MARATHON_SIZE_DETAILS,
  SAGEMAKER_INSTANCE_TYPE_DETAILS,
} from "config/canvasConfig";

type BlockComputeTypeInfo = {
  blockId: BlockType["id"];
  containerClassName?: string;
};

export const BlockComputeTypeInfo = ({
  blockId,
  containerClassName = "",
}: BlockComputeTypeInfo) => {
  const computeEnvironmentType = useCanvasBlocksState(
    (slice) => slice.data[blockId]?.computeEnvironmentType
  );
  const size = useCanvasBlocksState((slice) => slice.data[blockId]?.size);
  const gpuInstanceType = useCanvasBlocksState(
    (slice) => slice.data[blockId]?.gpuInstanceType
  );

  const blockLayerId = useCanvasBlocksState(
    (slice) => slice.data[blockId]?.layer_id
  );
  const blockRunDisabled =
    useLayerBlocksRunDisabilityReasons({ layerId: blockLayerId }).size > 0;

  const { openCloudSettings } = useCanvasActions();

  const computeEnvironmentTypeConfig = computeEnvironmentType
    ? COMPUTE_ENV_TYPE_CONFIG[computeEnvironmentType] ?? null
    : null;

  if (!computeEnvironmentTypeConfig) {
    return null;
  }

  const { label, Icon } = computeEnvironmentTypeConfig;

  const renderInfoRow = ({
    label,
    value,
  }: {
    label: string;
    value: ReactNode;
  }) => {
    return (
      <div className={styles.row}>
        <div className={styles.label}>{label}</div>
        <div className={styles.value}>{value}</div>
      </div>
    );
  };

  const renderInstanceTypeInfo = () => {
    if (computeEnvironmentType === COMPUTE_ENV_TYPE.MARATHON && size) {
      const config = MARATHON_SIZE_DETAILS[size];
      if (!config) {
        return null;
      }

      return renderInfoRow({
        label: "Instance Type:",
        value: (
          <>
            <div>{size}</div>
            <ComputeInstanceItem cpu={config.cpu} memory={config.memory} />
          </>
        ),
      });
    }

    if (computeEnvironmentType === COMPUTE_ENV_TYPE.GPU && gpuInstanceType) {
      const config = SAGEMAKER_INSTANCE_TYPE_DETAILS[gpuInstanceType];
      if (!config) {
        return null;
      }
      return renderInfoRow({
        label: "Instance Type:",
        value: (
          <>
            <div>{config.gpu_model}</div>
            <ComputeInstanceItem
              cpu={config.cpu}
              memory={config.memory}
              gpuMemory={config.gpu_memory}
            />
          </>
        ),
      });
    }

    return null;
  };

  const renderTooltipContent = () => {
    return (
      <div className={styles.tooltip}>
        <div className={styles.rows}>
          {renderInfoRow({ label: "Cloud Compute:", value: label })}
          {renderInstanceTypeInfo()}
        </div>
        {!blockRunDisabled ? (
          <div className={styles.actions}>
            <Button
              className={styles.details_button}
              variant="primary"
              onClick={() => {
                openCloudSettings(blockId);
              }}
            >
              Compute Settings
            </Button>
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <Tooltip
      className={cn(styles.tooltip_container, containerClassName)}
      tooltipContentClassName={styles.tooltip_content}
      placement="top"
      tipElement={renderTooltipContent()}
      hoverProps={{
        handleClose: safePolygon({
          requireIntent: false,
          buffer: 4,
          blockPointerEvents: true,
        }),
        delay: {
          open: 250,
          close: 0,
        },
      }}
      hideOnClick
      withArrow
    >
      <Icon size={16} />
    </Tooltip>
  );
};
