import { useCallback, useMemo } from "react";
import { useOrganizationsState } from "store";
import { getOrganizations as getOrganizationsAPICall } from "api/http/organization-service";
import { getCloudSetting as getCloudSettingAPICall } from "api/http/cloud-setting-service";
import { DATA_LOAD_STATUS } from "config/appConfig";
import type { Organization } from "models/organization";

export const useOrganizationsActions = () => {
  const setOrganizations = useOrganizationsState(
    (slice) => slice.setOrganizations
  );
  const setOrganizationsLoadStatus = useOrganizationsState(
    (slice) => slice.setOrganizationsLoadStatus
  );
  const setCloudCredentials = useOrganizationsState(
    (slice) => slice.setCloudCredentials
  );
  const setCloudCredentialsLoadStatus = useOrganizationsState(
    (slice) => slice.setCloudCredentialsLoadStatus
  );

  const fetchOrganizations = useCallback(async () => {
    setOrganizationsLoadStatus(DATA_LOAD_STATUS.LOADING);
    try {
      const data = await getOrganizationsAPICall();
      setOrganizations(data);
      setOrganizationsLoadStatus(DATA_LOAD_STATUS.LOADED);
    } catch (error) {
      setOrganizationsLoadStatus(DATA_LOAD_STATUS.ERROR);
    }
  }, []);

  const fetchCloudCredentials = useCallback(
    async (organizationId: Organization["id"]) => {
      setCloudCredentialsLoadStatus(DATA_LOAD_STATUS.LOADING);
      try {
        const data = await getCloudSettingAPICall(organizationId);
        setCloudCredentials(data);
        setCloudCredentialsLoadStatus(DATA_LOAD_STATUS.LOADED);
      } catch (rejection: any) {
        if (rejection?.cause?.status === 404) {
          // No cloud credentials found
          setCloudCredentialsLoadStatus(DATA_LOAD_STATUS.LOADED);
        } else {
          setCloudCredentialsLoadStatus(DATA_LOAD_STATUS.ERROR);
        }
      }
    },
    []
  );

  return useMemo(
    () => ({ fetchOrganizations, fetchCloudCredentials }),
    [fetchOrganizations, fetchCloudCredentials]
  );
};
