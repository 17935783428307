import styles from "./ShareResourceModal.module.scss";
import { useState, useEffect, useCallback, useMemo } from "react";
import cn from "classnames";
import { BiCopy, BiX } from "react-icons/bi";
import { useFlags } from "launchdarkly-react-client-sdk";
import userService from "api/http/user-service";
import { useUserState, useUserSettingsState, useToastsState } from "store";
import { useNavigate } from "react-router-dom";
import { type PropsWithModal } from "hooks/useModal";
import { useConfirmModal } from "hooks/useConfirmModal";
import { Button } from "components/common/Button/Button";
import { DropdownMenuButton } from "components/common/DropdownMenuButton/DropdownMenuButton";
import type { ContextMenuItem } from "components/common/ContextMenu/ContextMenu";
import IconButton from "components/common/IconButton/IconButton";
import Loader from "components/common/Loader/Loader";
import TextField from "components/common/TextField/TextField";
import ToasterMessage from "components/common/ToasterMessage/ToasterMessage";
import Typography from "components/Typography/Typography";
import { PersonRow, PersonRowSkeleton } from "components/PersonRow";
import {
  DATA_LOAD_STATUS,
  USER_PERMISSION_CONFIG,
  ACCESS_REQUEST_STATUS,
  COMMUNITY_BASE_URL,
} from "config/appConfig";
import { CANVAS_PRIVACY, CANVAS_PRIVACY_DETAILS } from "config/canvasConfig";
import { debouncePromise } from "utils/helpers";
import { stringIsValidEmail } from "utils/validation";
import {
  RESOURCE_TYPE,
  UserPermission,
  type ResourceMember,
  type ResourceRequestAccess,
  type Resource,
} from "models/user";
import {
  MEMBERS_COUNT_DEFAULT,
  MESSAGE,
  USER_PERMISSIONS,
  resourceMemberHelper,
} from "./helpers";

type ShareResourceModalProps = PropsWithModal<{
  resourceId: string;
  resourceType: RESOURCE_TYPE;
  resourceLink: string;
  communityLink?: string;
  invitationPermissionDefault?: UserPermission;
  getCanvasPrivacy?: () => Promise<CANVAS_PRIVACY>;
  updateCanvasPrivacy?: (privacy: CANVAS_PRIVACY) => Promise<void>;
  onLeaveResource?: () => void;
}>;

export function ShareResourceModal({
  resourceId,
  resourceType,
  resourceLink,
  communityLink,
  invitationPermissionDefault = UserPermission.canEdit,
  getCanvasPrivacy,
  updateCanvasPrivacy,
  onLeaveResource,
  onModalClose,
}: ShareResourceModalProps) {
  const flags = useFlags();
  const userID = useUserState((slice) => slice.userID);
  const { username } = useUserSettingsState((slice) => slice.profile);
  const addToast = useToastsState((slice) => slice.addToast);
  const { openConfirmModal } = useConfirmModal();
  const navigate = useNavigate();

  const [emailToInvite, setEmailToInvite] = useState("");
  const [invitationPermission, setInvitationPermission] =
    useState<UserPermission>(invitationPermissionDefault);

  const [resourceData, setResourceData] = useState<Resource | null>(null);
  const [resourceDataLoadStatus, setResourceDataLoadStatus] =
    useState<DATA_LOAD_STATUS>(DATA_LOAD_STATUS.NOT_LOADED);

  const [isInvitePending, setIsInvitePending] = useState(false);
  const [resourceMembers, setResourceMembers] = useState<
    Map<ResourceMember["id"], ResourceMember>
  >(new Map());

  const [resourceAccessRequestUsers, setResourceAccessRequestUsers] = useState<
    ResourceRequestAccess[]
  >([]);

  const [resourceMembersLoadStatus, setResourceMembersLoadStatus] =
    useState<DATA_LOAD_STATUS>(DATA_LOAD_STATUS.NOT_LOADED);

  const [processingResourceMembersIds, setProcessingResourceMembersIds] =
    useState<Set<ResourceMember["id"]>>(new Set());

  const [canvasPrivacy, setCanvasPrivacy] = useState<string>(
    CANVAS_PRIVACY.PRIVATE
  );

  const [privacyLoadStatus, setPrivacyLoadStatus] = useState<DATA_LOAD_STATUS>(
    DATA_LOAD_STATUS.NOT_LOADED
  );
  const [isUpdatePrivacyRequestPending, setUpdatePrivacyRequestPending] =
    useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [errorMessageAccessRequest, setErrorMessageAccessRequest] = useState<
    string | null
  >(null);
  const [errorMessagePrivacy, setErrorMessagePrivacy] = useState<string | null>(
    null
  );

  const isUserResourceOwner =
    Boolean(userID) &&
    Boolean(resourceData?.owner) &&
    userID === resourceData?.owner;

  const makePrivacyEnabled =
    isUserResourceOwner && !!getCanvasPrivacy && !!updateCanvasPrivacy;

  const privacyDropdownDisabled =
    isUpdatePrivacyRequestPending ||
    privacyLoadStatus !== DATA_LOAD_STATUS.LOADED ||
    (!flags.enableCanvasPublicSharing && !flags.enableCommunity);

  const handleGetPrivacy = useCallback(async () => {
    if (!getCanvasPrivacy) {
      return;
    }
    setPrivacyLoadStatus(DATA_LOAD_STATUS.LOADING);
    try {
      const data = await getCanvasPrivacy();
      setCanvasPrivacy(data);
      setPrivacyLoadStatus(DATA_LOAD_STATUS.LOADED);
    } catch (err: any) {
      setPrivacyLoadStatus(DATA_LOAD_STATUS.ERROR);
      const cause =
        typeof err?.cause?.detail === "string" ? err.cause.detail : "";
      const errorMessage = cause
        ? `${MESSAGE.getFailedToLoadPrivacyStatus()}: "${cause}". ${MESSAGE.tryAgainOrContactSupport}.`
        : `${MESSAGE.getFailedToLoadPrivacyStatus()}. ${MESSAGE.tryAgainOrContactSupport}.`;
      setErrorMessagePrivacy(errorMessage);
    }
  }, [getCanvasPrivacy]);

  useEffect(() => {
    if (makePrivacyEnabled) {
      handleGetPrivacy();
    }
  }, [handleGetPrivacy, makePrivacyEnabled]);

  const isResourceEditor = resourceData?.permission === UserPermission.canEdit;
  const isLoadingData =
    resourceMembersLoadStatus === DATA_LOAD_STATUS.LOADING ||
    resourceDataLoadStatus === DATA_LOAD_STATUS.LOADING;
  const actionsDisabled =
    isInvitePending ||
    resourceMembersLoadStatus !== DATA_LOAD_STATUS.LOADED ||
    resourceDataLoadStatus !== DATA_LOAD_STATUS.LOADED;

  const usernameConfirm = async () => {
    let usernameConfirmed = true;
    usernameConfirmed = await openConfirmModal({
      title: "Add Username",
      message: MESSAGE.getAddUsername,
      confirmButtonLabel: "Go to Profile",
    });
    if (!usernameConfirmed) {
      return;
    }
    navigate("/settings/personal-profile");
  };

  const addProcessingResourceMemberId = useCallback((id: string) => {
    setProcessingResourceMembersIds((prev) => {
      return new Set(prev).add(id);
    });
  }, []);

  const deleteProcessingResourceMemberId = useCallback((id: string) => {
    setProcessingResourceMembersIds((prev) => {
      const result = new Set(prev);
      result.delete(id);

      return result;
    });
  }, []);

  const resourceMembersList = useMemo(() => {
    return [...resourceMembers.values()];
  }, [resourceMembers]);

  // Request access is added to the canvas's and not the assets. Asset is outside the scope.
  // For the assets it will return an empty list and will not show in the ui for request access
  const getResourceAccessRequest = useCallback(async () => {
    if (!resourceId) {
      return;
    }
    if (resourceType !== RESOURCE_TYPE.CANVAS) {
      return;
    }

    try {
      const members =
        await userService.getResourceAccessRequestMembers(resourceId);

      setResourceAccessRequestUsers(members);
      setErrorMessageAccessRequest(null);
    } catch (rejection: any) {
      const cause =
        typeof rejection?.cause?.detail === "string"
          ? rejection.cause.detail
          : "";
      const errorMessage = cause
        ? `${MESSAGE.failedToGetCanvasAccessRequestList}: "${cause}". ${MESSAGE.tryAgainOrContactSupport}.`
        : `${MESSAGE.failedToGetCanvasAccessRequestList}. ${MESSAGE.tryAgainOrContactSupport}.`;
      setResourceAccessRequestUsers([]);
      setErrorMessageAccessRequest(errorMessage);
    }
  }, [resourceId]);

  useEffect(() => {
    getResourceAccessRequest();
  }, [getResourceAccessRequest]);

  const getResourceData = useCallback(async () => {
    setResourceDataLoadStatus(DATA_LOAD_STATUS.LOADING);
    try {
      const data = await userService.getResource(resourceId);
      setResourceData(data);
      setResourceDataLoadStatus(DATA_LOAD_STATUS.LOADED);
    } catch (err) {
      setResourceDataLoadStatus(DATA_LOAD_STATUS.ERROR);
    }
  }, [resourceId]);

  const getResourceMembers = useCallback(async () => {
    if (!userID) {
      return;
    }

    setResourceMembersLoadStatus(DATA_LOAD_STATUS.LOADING);
    try {
      const [members, invites] = await Promise.all([
        userService.getResourceMembers(resourceId),
        userService.getResourceInvites(resourceId),
      ]);

      // make sure the current user appears in the head of the list
      const orderedMembers = members.reduce<typeof resourceMembers>(
        (list, person) => {
          if (person.user_id === userID) {
            return new Map([[person.id, person], ...Array.from(list)]);
          }

          list.set(person.id, person);

          return list;
        },
        new Map()
      );

      invites.forEach((invite) => {
        orderedMembers.set(invite.id, invite);
      });

      setResourceMembers(orderedMembers);
      setErrorMessage(null);
      setResourceMembersLoadStatus(DATA_LOAD_STATUS.LOADED);
    } catch (rejection: any) {
      const cause =
        typeof rejection?.cause?.detail === "string"
          ? rejection.cause.detail
          : "";
      const errorMessage = cause
        ? `${MESSAGE.failedToGetMembersList}: "${cause}". ${MESSAGE.tryAgainOrContactSupport}.`
        : `${MESSAGE.failedToGetMembersList}. ${MESSAGE.tryAgainOrContactSupport}.`;
      setResourceMembers(new Map());
      setErrorMessage(errorMessage);
      setResourceMembersLoadStatus(DATA_LOAD_STATUS.ERROR);
    }
  }, [userID, resourceId]);

  const getInitialData = useCallback(async () => {
    await getResourceData();
    await getResourceMembers();
  }, [getResourceData, getResourceMembers]);

  useEffect(() => {
    getInitialData();
  }, [getInitialData]);

  const formattedEmailToInvite = useMemo(() => {
    return emailToInvite.trim().toLocaleLowerCase();
  }, [emailToInvite]);

  const isInvitingDisabled = useMemo(() => {
    return (
      !isResourceEditor ||
      isInvitePending ||
      processingResourceMembersIds.size > 0 ||
      !stringIsValidEmail(formattedEmailToInvite) ||
      resourceMembersList.some((member) => {
        return (
          resourceMemberHelper.getMemberEmail(member) === formattedEmailToInvite
        );
      })
    );
  }, [
    formattedEmailToInvite,
    isInvitePending,
    resourceMembersList,
    isResourceEditor,
    processingResourceMembersIds.size,
  ]);

  const handleSendInvitations = useCallback(
    async (e) => {
      e.preventDefault();

      if (isInvitingDisabled) {
        return;
      }

      try {
        setIsInvitePending(true);
        /* temporarily disabled members update from userService.inviteUserToResource response
        as it provides incorrect user_id (null), but we need user id for deletion operation 
        https://linear.app/zerve-ai/issue/ZER-1250/post-apiuser-serviceresourcesmembers-response-provides-user-id-null */
        await userService.inviteUserToResource({
          resourceId,
          userEmail: formattedEmailToInvite,
          permission: invitationPermission,
        });
        onModalClose();
        addToast({
          variant: "success",
          message: MESSAGE.getSucceedToInviteByEmailMessage(
            formattedEmailToInvite
          ),
        });
      } catch (err: any) {
        const cause =
          typeof err?.cause?.detail === "string" ? err.cause.detail : "";
        const message = MESSAGE.getFailedToInviteByEmailMessage(
          formattedEmailToInvite
        );
        const errorMessage = cause ? `${message}: ${cause}` : `${message}`;
        addToast({
          variant: "error",
          message: errorMessage,
        });
        setIsInvitePending(false);
      }
    },
    [
      resourceId,
      formattedEmailToInvite,
      isInvitingDisabled,
      invitationPermission,
      getResourceMembers,
    ]
  );

  const updateMemberResourcePermission = useCallback(
    async (member: ResourceMember, permission: UserPermission) => {
      if (permission === member.permission) {
        return;
      }

      try {
        addProcessingResourceMemberId(member.id);
        setResourceMembers((prev) => {
          return new Map(prev).set(member.id, { ...member, permission });
        });

        await resourceMemberHelper.updateResourceMemberPermission(
          resourceId,
          member,
          permission
        );

        addToast({
          variant: "success",
          message:
            resourceMemberHelper.getSucceedToUpdateMemberPermissionMessage(
              member
            ),
        });
        deleteProcessingResourceMemberId(member.id);
      } catch (err: any) {
        setResourceMembers((prev) => {
          return new Map(prev).set(member.id, member);
        });
        const cause =
          typeof err?.cause?.detail === "string" ? err.cause.detail : "";
        const message =
          resourceMemberHelper.getFailedToUpdateMemberPermissionMessage(member);
        const errorMessage = cause ? `${message}: ${cause}` : `${message}`;
        addToast({
          variant: "error",
          message: errorMessage,
        });
        deleteProcessingResourceMemberId(member.id);
      }
    },
    [resourceId]
  );

  const invitationPermissionOptions = useMemo(() => {
    return USER_PERMISSIONS.map((permission) => {
      return {
        key: permission,
        type: "option" as const,
        label: USER_PERMISSION_CONFIG[permission]?.label || permission,
        onClick: () => {
          setInvitationPermission(permission);
        },
      };
    });
  }, []);

  const createMemberPermissionOptions = useCallback(
    (member: ResourceMember) => {
      return USER_PERMISSIONS.map((permission) => {
        return {
          key: permission,
          type: "option" as const,
          label: USER_PERMISSION_CONFIG[permission]?.label || permission,
          onClick: () => {
            updateMemberResourcePermission(member, permission);
          },
        };
      });
    },
    [updateMemberResourcePermission]
  );

  const handleDeleteMember = useCallback(
    async (member: ResourceMember) => {
      let confirmed = true;
      const userLeavesResource =
        resourceMemberHelper.isUser(member) && member.user_id === userID;
      if (userLeavesResource) {
        confirmed = await openConfirmModal({
          message:
            MESSAGE.getLeaveResourceMembershipConfirmMessage(resourceType),
        });
      }
      if (!confirmed) {
        return;
      }

      try {
        addProcessingResourceMemberId(member.id);
        await resourceMemberHelper.deleteResourceMember(resourceId, member);
        if (userLeavesResource) {
          onModalClose();
          onLeaveResource?.();
        } else {
          setResourceMembers((prev) => {
            const result = new Map(prev);
            result.delete(member.id);

            return result;
          });
          deleteProcessingResourceMemberId(member.id);
        }
        addToast({
          variant: "success",
          message: resourceMemberHelper.getSucceedToRemoveMemberMessage(member),
        });
      } catch (err: any) {
        const cause =
          typeof err?.cause?.detail === "string" ? err.cause.detail : "";
        const message =
          resourceMemberHelper.getFailedToRemoveMemberMessage(member);
        const errorMessage = cause ? `${message}: ${cause}` : `${message}`;
        addToast({
          variant: "error",
          message: errorMessage,
        });
        deleteProcessingResourceMemberId(member.id);
      }
    },
    [resourceId, resourceType, userID]
  );

  const handleCanvasAccessRequest = useCallback(
    async (resource_access_id: string, status: ACCESS_REQUEST_STATUS) => {
      try {
        await userService.updateResourceAccessRequest(
          resource_access_id,
          status
        );
        setResourceAccessRequestUsers((prev) => {
          return prev.filter((item) => item.id !== resource_access_id);
        });
        if (status === ACCESS_REQUEST_STATUS.ACCEPTED) {
          getResourceMembers();
        }
        const message =
          status === ACCESS_REQUEST_STATUS.ACCEPTED
            ? "Access request has been accepted"
            : "Access request has been denied";
        addToast({
          variant: "success",
          message: message,
        });
      } catch (error) {
        addToast({
          variant: "error",
          message: "Failed to update access request",
        });
      }
    },
    [addToast, setResourceAccessRequestUsers, userService]
  );

  const saveCanvasPrivacy = useCallback(
    async (privacy: CANVAS_PRIVACY) => {
      if (privacy === canvasPrivacy || !makePrivacyEnabled || actionsDisabled) {
        return;
      }
      if (privacy === CANVAS_PRIVACY.COMMUNITY && !username) {
        usernameConfirm();
        return;
      }
      let confirmed = true;
      confirmed = await openConfirmModal({
        message: MESSAGE.getTogglePrivacyConfirm(privacy),
      });
      if (!confirmed) {
        return;
      }
      setUpdatePrivacyRequestPending(true);
      if (updateCanvasPrivacy) {
        try {
          await debouncePromise({
            promise: updateCanvasPrivacy(privacy),
          });
          setCanvasPrivacy(privacy);
          setUpdatePrivacyRequestPending(false);
          addToast({
            variant: "success",
            message: MESSAGE.getSucceedToUpdatePrivacyStatus(privacy),
          });
        } catch (err: any) {
          setUpdatePrivacyRequestPending(false);
          const cause =
            typeof err?.cause?.detail === "string" ? err.cause.detail : "";
          const message = MESSAGE.getFailedToUpdatePrivacyStatus(privacy);
          const errorMessage = cause ? `${message}: ${cause}` : `${message}`;
          addToast({
            variant: "error",
            message: errorMessage,
          });
        }
      } else {
        const message = MESSAGE.getFailedToUpdatePrivacyStatus(privacy);
        addToast({
          variant: "error",
          message: message,
        });
      }
    },
    [
      actionsDisabled,
      makePrivacyEnabled,
      resourceType,
      updateCanvasPrivacy,
      canvasPrivacy,
    ]
  );

  const canvasPrivacyOptions = useMemo(() => {
    const options: ContextMenuItem[] = [
      // Private (always available)
      {
        key: CANVAS_PRIVACY.PRIVATE,
        type: "option",
        icon: CANVAS_PRIVACY_DETAILS[CANVAS_PRIVACY.PRIVATE].Icon,
        label: CANVAS_PRIVACY_DETAILS[CANVAS_PRIVACY.PRIVATE].label,
        description: CANVAS_PRIVACY_DETAILS[CANVAS_PRIVACY.PRIVATE].description,
        onClick: () => {
          saveCanvasPrivacy(CANVAS_PRIVACY.PRIVATE);
        },
      },
    ];

    // Public
    if (flags.enableCanvasPublicSharing) {
      options.push({
        key: CANVAS_PRIVACY.PUBLIC,
        type: "option",
        icon: CANVAS_PRIVACY_DETAILS[CANVAS_PRIVACY.PUBLIC].Icon,
        label: CANVAS_PRIVACY_DETAILS[CANVAS_PRIVACY.PUBLIC].label,
        description: CANVAS_PRIVACY_DETAILS[CANVAS_PRIVACY.PUBLIC].description,
        onClick: () => {
          saveCanvasPrivacy(CANVAS_PRIVACY.PUBLIC);
        },
      });
    }

    // Community
    if (flags.enableCommunity) {
      options.push({
        key: CANVAS_PRIVACY.COMMUNITY,
        type: "option",
        icon: CANVAS_PRIVACY_DETAILS[CANVAS_PRIVACY.COMMUNITY].Icon,
        label: CANVAS_PRIVACY_DETAILS[CANVAS_PRIVACY.COMMUNITY].label,
        description:
          CANVAS_PRIVACY_DETAILS[CANVAS_PRIVACY.COMMUNITY].description,
        onClick: () => {
          saveCanvasPrivacy(CANVAS_PRIVACY.COMMUNITY);
        },
      });
    }

    return options;
  }, [
    flags.enableCanvasPublicSharing,
    flags.enableCommunity,
    saveCanvasPrivacy,
  ]);

  const handleCopyLink = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(
        canvasPrivacy === CANVAS_PRIVACY.COMMUNITY
          ? communityLink || `${COMMUNITY_BASE_URL}/community/`
          : resourceLink
      );
      addToast({
        variant: "success",
        message: MESSAGE.linkCopiedToClipboard,
      });
    } catch (err: any) {
      const cause = err?.message;
      const errMessage = cause
        ? `${MESSAGE.failedCopyLinkToClipboard}: ${cause}`
        : MESSAGE.failedCopyLinkToClipboard;
      addToast({
        variant: "error",
        message: errMessage,
      });
    }
  }, [resourceLink, canvasPrivacy]);

  const createAccessRequestRow = useCallback(
    (member: ResourceRequestAccess) => {
      const { id } = member;
      const email = member.email;
      const avatar = member.avatar;
      const username = member.username;

      return (
        <div
          key={id}
          className={cn(styles.list_item, {
            [styles.list_item_processed]: false,
          })}
        >
          <PersonRow
            id={id}
            email={email}
            avatar={avatar}
            username={username}
          />
        </div>
      );
    },
    []
  );

  const createMemberRow = useCallback(
    (member: ResourceMember) => {
      if (!userID) {
        return null;
      }
      const { id, permission } = member;
      const email = resourceMemberHelper.getMemberEmail(member);
      const isOwner = resourceMemberHelper.getIsOwner(member);

      const isMemberProcessed = processingResourceMembersIds.has(id);
      const isUser = resourceMemberHelper.isUser(member);
      const permissionEditDisabled =
        isMemberProcessed ||
        !isResourceEditor ||
        (isUser && member.user_id === userID);

      const deleteMemberDisabled =
        isMemberProcessed ||
        (!isResourceEditor &&
          (!isUser || (isUser && member.user_id !== userID)));

      const personRowRightSideContent = isOwner ? (
        <div className={styles.person_row_right_side_content}>Owner</div>
      ) : (
        <div className={styles.person_row_right_side_content}>
          {!!USER_PERMISSION_CONFIG[permission] && (
            <DropdownMenuButton
              menuOptions={createMemberPermissionOptions(member)}
              buttonVariant="text"
              disabled={actionsDisabled || permissionEditDisabled}
              nodesClassNames={{
                button: styles.dropdown_menu_button,
              }}
              text={USER_PERMISSION_CONFIG[permission]?.label || permission}
            />
          )}
          <IconButton
            disabled={actionsDisabled || deleteMemberDisabled}
            tooltip={resourceMemberHelper.getDeleteMemberButtonTooltip(member)}
            onClick={() => {
              handleDeleteMember(member);
            }}
          >
            <BiX size={16} />
          </IconButton>
        </div>
      );

      let avatar;
      let username;
      let status;

      if (isUser) {
        avatar = member.avatar;
        username = member.username;
        status = member.status;
      }

      return (
        <div
          key={id}
          className={cn(styles.list_item, {
            [styles.list_item_processed]: isMemberProcessed,
          })}
        >
          <PersonRow
            id={id}
            email={email}
            avatar={avatar}
            username={username}
            status={status}
            invited={!isUser}
            rightSideContent={personRowRightSideContent}
          />
        </div>
      );
    },
    [
      handleDeleteMember,
      createMemberPermissionOptions,
      processingResourceMembersIds,
      isResourceEditor,
      userID,
      actionsDisabled,
    ]
  );

  const renderInviteSection = () => {
    if (resourceDataLoadStatus === DATA_LOAD_STATUS.ERROR) {
      return (
        <ToasterMessage variant="error" animate={false} fullWidth>
          {MESSAGE.failedLoadResourceData}
        </ToasterMessage>
      );
    }
    if (!!resourceData && !isResourceEditor) {
      return (
        <ToasterMessage variant="warning" animate={false} fullWidth>
          {MESSAGE.cannotInvite}
        </ToasterMessage>
      );
    }

    return (
      <form className={styles.form} onSubmit={handleSendInvitations}>
        <div className={styles.form_field}>
          <TextField
            autoFocus
            name="email"
            placeholder="Email"
            containerClassName={styles.email_input_container}
            inputClassName={styles.email_input}
            value={emailToInvite}
            isDisabled={actionsDisabled || !isResourceEditor}
            onChange={(e) => {
              setEmailToInvite(e.target.value);
            }}
          />
          <DropdownMenuButton
            menuOptions={invitationPermissionOptions}
            nodesClassNames={{
              button: styles.dropdown_menu_button,
            }}
            text={
              USER_PERMISSION_CONFIG[invitationPermission]?.label ||
              invitationPermission
            }
            disabled={actionsDisabled || !isResourceEditor}
          />
        </div>
        <Button
          type="submit"
          size="large"
          disabled={actionsDisabled || isInvitingDisabled}
          className={styles.invite_button}
        >
          {isInvitePending ? <Loader /> : <span>Invite</span>}
        </Button>
      </form>
    );
  };

  const renderPrivacyContent = () => {
    if (!makePrivacyEnabled) {
      return null;
    }

    const PrivacyIcon = CANVAS_PRIVACY_DETAILS[canvasPrivacy].Icon;

    return (
      <>
        <Typography variant="body1" className={styles.list_header}>
          Canvas Privacy
        </Typography>

        {errorMessagePrivacy ? (
          <ToasterMessage
            fullWidth
            variant="error"
            animate={false}
            className={styles.error_message}
          >
            {errorMessagePrivacy}
          </ToasterMessage>
        ) : (
          <div className={styles.footer_privacy}>
            <DropdownMenuButton
              buttonSize="small"
              placement="bottom-start"
              disabled={privacyDropdownDisabled}
              text={
                <div className={styles.canvasPrivacyDropdownLabel}>
                  <PrivacyIcon size={16} />
                  <span>
                    {CANVAS_PRIVACY_DETAILS[canvasPrivacy].label} —{" "}
                    {CANVAS_PRIVACY_DETAILS[canvasPrivacy].description}
                  </span>
                </div>
              }
              menuOptions={canvasPrivacyOptions}
            />
            <Button
              className={styles.copy_link_button}
              size="small"
              variant="secondary"
              disabled={
                isUpdatePrivacyRequestPending ||
                privacyLoadStatus !== DATA_LOAD_STATUS.LOADED
              }
              onClick={handleCopyLink}
            >
              {isUpdatePrivacyRequestPending ||
              privacyLoadStatus !== DATA_LOAD_STATUS.LOADED ? (
                <Loader size={16} />
              ) : (
                <>
                  <BiCopy className={styles.copy_link_glyph} />
                  <span>Copy Link</span>
                </>
              )}
            </Button>
          </div>
        )}
      </>
    );
  };

  return (
    <div className={styles.container}>
      {renderInviteSection()}
      <div className={styles.list_container}>
        <Typography variant="body1" className={styles.list_header}>
          People With Access
        </Typography>
        <div className={styles.list}>
          {isLoadingData ? (
            <>
              {Array.from({
                length: resourceMembersList.length || MEMBERS_COUNT_DEFAULT,
              }).map((_, index) => {
                return <PersonRowSkeleton key={index} />;
              })}
            </>
          ) : errorMessage ? (
            <ToasterMessage
              fullWidth
              variant="error"
              animate={false}
              className={styles.error_message}
            >
              {errorMessage}
            </ToasterMessage>
          ) : (
            <>{resourceMembersList.map((member) => createMemberRow(member))}</>
          )}
        </div>
      </div>
      {(resourceAccessRequestUsers.length > 0 || errorMessageAccessRequest) && (
        <div className={styles.list_container}>
          <Typography variant="body1" className={styles.list_header}>
            Canvas Access Requests
          </Typography>
          {errorMessageAccessRequest ? (
            <ToasterMessage
              fullWidth
              variant="error"
              animate={false}
              className={styles.error_message}
            >
              {errorMessageAccessRequest}
            </ToasterMessage>
          ) : (
            <div className={styles.list}>
              {resourceAccessRequestUsers.map((request) => (
                <div key={request.id} className={styles.request_item}>
                  <div className={styles.request_content}>
                    {createAccessRequestRow(request)}
                  </div>
                  <div className={styles.button_container}>
                    <Button
                      size="small"
                      onClick={() =>
                        handleCanvasAccessRequest(
                          request.id,
                          ACCESS_REQUEST_STATUS.ACCEPTED
                        )
                      }
                      variant="outline"
                    >
                      Accept
                    </Button>
                    <Button
                      size="small"
                      onClick={() =>
                        handleCanvasAccessRequest(
                          request.id,
                          ACCESS_REQUEST_STATUS.DENIED
                        )
                      }
                      variant="outline"
                    >
                      Deny
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      <div className={styles.footer}>{renderPrivacyContent()}</div>
    </div>
  );
}
