import styles from "./IconButton.module.scss";
import { forwardRef, type PropsWithChildren, type ReactNode } from "react";
import cn from "classnames";
import Tooltip, { type TooltipProps } from "components/common/Tooltip/Tooltip";
import { Button } from "components/common/Button/Button";
import { type ButtonProps } from "components/common/Button/Button";
import { type Placement } from "@floating-ui/react";

export type IconButtonProps = PropsWithChildren<{
  icon?: string;
  tooltip?: ReactNode;
  hotKey?: string;
  size?: ButtonProps["size"];
  variant?: ButtonProps["variant"];
  disabled?: boolean;
  className?: string;
  tooltipClassName?: string;
  tooltipPlacement?: Placement;
  tooltipHideOnClick?: boolean;
  tooltipHoverProps?: TooltipProps["hoverProps"];
  onClick: ButtonProps["onClick"];
}>;

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      children,
      icon,
      tooltip,
      hotKey,
      size = "small",
      variant = "text",
      disabled = false,
      className,
      tooltipClassName,
      tooltipPlacement = "top",
      tooltipHideOnClick = false,
      tooltipHoverProps,
      onClick,
    },
    ref
  ) => {
    const button = (
      <Button
        ref={ref}
        className={cn(
          styles.iconButton,
          {
            [styles.sizeSmall]: size === "small",
            [styles.sizeLarge]: size === "large",
          },
          className
        )}
        size={size}
        variant={variant}
        disabled={disabled}
        onClick={onClick}
      >
        {icon ? (
          <img draggable={false} src={icon} alt="icon_button" />
        ) : (
          children
        )}
      </Button>
    );

    if (!tooltip) {
      return button;
    }

    return (
      <Tooltip
        className={tooltipClassName}
        text={tooltip}
        placement={tooltipPlacement}
        hideOnClick={tooltipHideOnClick}
        withArrow
        hotkey={hotKey}
        hoverProps={tooltipHoverProps}
      >
        {button}
      </Tooltip>
    );
  }
);

IconButton.displayName = "IconButton";

export default IconButton;
