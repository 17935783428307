import styles from "./BlockScreen.module.scss";
import { type ReactNode } from "react";
import Logo from "assets/icons/logo-white-text.svg?react";
import { BlockLikeContainer } from "components/blocks/BlockContainer/BlockLikeContainer";
import { BLOCK_STATUS } from "config/canvasConfig";

type BlockScreenProps = {
  status?: BLOCK_STATUS;
  title: string;
  header: ReactNode;
  message: ReactNode;
  actions?: ReactNode;
};

export default function BlockScreen({
  status = BLOCK_STATUS.ERROR,
  title,
  header,
  message,
  actions,
}: BlockScreenProps) {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Logo className={styles.logo} />
      </div>
      <div className={styles.body}>
        <BlockLikeContainer title={title} status={status}>
          <div className={styles.content}>
            <div className={styles.monoFont}>
              <div>{header}</div>
              <div>===========</div>
              <div>{message}</div>
            </div>

            {actions ? <div className={styles.actions}>{actions}</div> : null}
          </div>
        </BlockLikeContainer>
      </div>
    </div>
  );
}
