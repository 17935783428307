import type { Organization, Workspace } from "models/organization";
import { type StateCreator } from "store";
import { DATA_LOAD_STATUS } from "config/appConfig";
import type { CloudCredentials } from "models/organization";

type State = {
  organizations: Organization[];
  organizationsLoadStatus: DATA_LOAD_STATUS;
  cloudCredentials: CloudCredentials | null;
  cloudCredentialsLoadStatus: DATA_LOAD_STATUS;
};

type Actions = {
  setOrganizations: (data: Organization[]) => void;
  setOrganizationsLoadStatus: (status: DATA_LOAD_STATUS) => void;
  updateOrganization: (
    id: Organization["id"],
    data: Partial<Organization>
  ) => void;
  updateOrganizationWorkspace: (
    organizationId: Organization["id"],
    workspaceId: Workspace["id"],
    data: Partial<Workspace>
  ) => void;
  deleteOrganizationWorkspace: (
    organizationId: Organization["id"],
    workspaceId: Workspace["id"]
  ) => void;
  deleteOrganization: (id: Organization["id"]) => void;
  setCloudCredentialsLoadStatus: (status: DATA_LOAD_STATUS) => void;
  setCloudCredentials: (data: CloudCredentials) => void;
  clearCloudCredentials: () => void;
};

const getInitialState = (): State => ({
  organizations: [],
  organizationsLoadStatus: DATA_LOAD_STATUS.NOT_LOADED,
  cloudCredentials: null,
  cloudCredentialsLoadStatus: DATA_LOAD_STATUS.NOT_LOADED,
});

export type OrganizationSlice = State & Actions;

export const createOrganizationsSlice: StateCreator<OrganizationSlice> = (
  set
) => ({
  ...getInitialState(),
  setOrganizations: (data) => {
    set(
      (store) => {
        store.organizationsSlice.organizations = data;
      },
      false,
      "organizations/setOrganizations"
    );
  },
  setOrganizationsLoadStatus: (status) => {
    set(
      (store) => {
        store.organizationsSlice.organizationsLoadStatus = status;
      },
      false,
      "organizations/setOrganizationsLoadStatus"
    );
  },
  updateOrganization: (id, data) => {
    set(
      (store) => {
        store.organizationsSlice.organizations =
          store.organizationsSlice.organizations.map((item) => {
            if (item.id === id) {
              return { ...item, ...data };
            }

            return item;
          });
      },
      false,
      "organizations/updateOrganization"
    );
  },
  updateOrganizationWorkspace: (organizationId, workspaceId, data) => {
    set(
      (store) => {
        const workspace = store.organizationsSlice.organizations
          .find(({ id }) => id === organizationId)
          ?.workspaces.find(({ id }) => id === workspaceId);
        if (!workspace) {
          return;
        }

        Object.assign(workspace, data);
      },
      false,
      "organizations/updateOrganizationWorkspace"
    );
  },
  deleteOrganizationWorkspace: (organizationId, workspaceId) => {
    set(
      (store) => {
        const organization = store.organizationsSlice.organizations.find(
          ({ id }) => id === organizationId
        );
        if (!organization) {
          return;
        }

        organization.workspaces = organization.workspaces.filter(
          ({ id }) => id !== workspaceId
        );
      },
      false,
      "organizations/deleteOrganizationWorkspace"
    );
  },
  deleteOrganization: (id) => {
    set(
      (store) => {
        store.organizationsSlice.organizations =
          store.organizationsSlice.organizations.filter(
            (item) => item.id !== id
          );
      },
      false,
      "organizations/deleteOrganization"
    );
  },
  setCloudCredentialsLoadStatus: (status) => {
    set(
      (store) => {
        store.organizationsSlice.cloudCredentialsLoadStatus = status;
      },
      false,
      "organizations/setCloudCredentialsLoadStatus"
    );
  },
  setCloudCredentials: (data) => {
    set(
      (store) => {
        store.organizationsSlice.cloudCredentials = data;
      },
      false,
      "organizations/setCloudCredentials"
    );
  },
  clearCloudCredentials: () => {
    set(
      (store) => {
        const { cloudCredentialsLoadStatus, cloudCredentials } =
          getInitialState();
        store.organizationsSlice.cloudCredentialsLoadStatus =
          cloudCredentialsLoadStatus;
        store.organizationsSlice.cloudCredentials = cloudCredentials;
      },
      false,
      "organizations/clearCloudCredentials"
    );
  },
});
