const PORTAL_ID = 20289263;

export const submitToHubSpot = async (formId, fields) => {
  const endpoint = `https://api.hsforms.com/submissions/v3/integration/submit/${PORTAL_ID}/${formId}`;
  const body = {
    submittedAt: Date.now(),
    fields: fields,
  };

  try {
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    const data = await response.json();
    return { success: true, data: data };
  } catch (error) {
    return { success: false, error: error };
  }
};
