import ImportsMenu from "components/menus/ImportsMenu/ImportsMenu";
import SideNavContent from "components/common/SideNavContent/SideNavContent";
import ResizableNav from "components/common/ResizableNav/ResizableNav";

export default function Imports() {
  return (
    <SideNavContent>
      <ResizableNav position={"right"}>
        <ImportsMenu />
      </ResizableNav>
    </SideNavContent>
  );
}
