import styles from "./WorkspaceApp.module.scss";
import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  PanelGroup,
  Panel,
  PanelResizeHandle,
  type ImperativePanelGroupHandle,
} from "react-resizable-panels";
import { useFlags } from "launchdarkly-react-client-sdk";
import { BiTrash, BiCopy, BiTerminal, BiX, BiCaretRight } from "react-icons/bi";
import * as Yup from "yup";
import { useFormik } from "formik";
import moment from "moment";
import canvasService from "api/http/canvas-service";
import { uploadFile } from "api/http/canvas-files-service";
import { useToastsState } from "store";
import Typography from "components/Typography/Typography";
import { Button } from "components/common/Button/Button";
import Loader from "components/common/Loader";
import TextField from "components/common/TextField";
import IconButton from "components/common/IconButton/IconButton";
import { RequirementsTable } from "components/common/RequirementsTable/RequirementsTable";
import ToasterMessage from "components/common/ToasterMessage/ToasterMessage";
import FormDropdown from "components/common/FormDropdown/FormDropdown";
import Tooltip from "components/common/Tooltip/Tooltip";
import { getWorkspaceAppAddress } from "utils/helpers";
import { APP_STATUS, APP_TYPE, type AppResponseType } from "models/app";
import { WorkspaceAppLogsViewer } from "./LogsViewer/WorkspaceAppLogsViewer";

type RequirementItem = {
  name: string;
  version: string;
  operator: string;
  required: boolean;
};

export function WorkspaceApp() {
  const { orgID = "", appID = "" } = useParams();
  const flags = useFlags();
  const addToast = useToastsState((slice) => slice.addToast);
  const [appProperties, setAppProperties] = useState<AppResponseType>();
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isFormSaved, setIsFormSaved] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [isDeploying, setIsDeploying] = useState(false);
  const [isDeployed, setIsDeployed] = useState(false);
  const [isStopping, setIsStopping] = useState(false);
  const isButtonDisabled = isSaving || isDeploying || isDeployed || isStopping;
  const [appRequirements, setAppRequirements] = useState<RequirementItem[]>([]);
  const [requirementsChanged, setRequirementsChanged] = useState(false);
  const [selectedAppType, setSelectedAppType] = useState<APP_TYPE | null>(null);
  const [showAppLogs, setShowAppLogs] = useState(false);
  const [showBuildLogs, setShowBuildLogs] = useState(false);
  const appIsErrored = appProperties?.status === APP_STATUS.ERROR;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      canvasService
        .getApp(appID)
        .then((appProperties) => {
          setAppProperties(appProperties);
          setSelectedAppType(appProperties.type || null);
          if (
            appProperties.s3_path &&
            appProperties.dns_name &&
            appProperties.python_script_name &&
            appProperties.type
          ) {
            setIsFormSaved(true);
          }
          switch (appProperties?.status) {
            case APP_STATUS.READY:
              setIsDeployed(true);
              break;
            case APP_STATUS.BUILDING:
              setIsDeploying(true);
              break;
            case APP_STATUS.STOPPING:
              setIsStopping(true);
              break;
          }
        })
        .catch(() => {
          addToast({
            message: "Failed to fetch app properties, please try again.",
            variant: "error",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    if (!appProperties && !isLoading) {
      fetchData();
    }
  }, [appID, appProperties]);

  const handleAppStatus = (status: APP_STATUS) => {
    switch (status) {
      case APP_STATUS.BUILDING:
      case APP_STATUS.STOPPING:
        return true; // Indicates that the fetch should be retried
      case APP_STATUS.READY:
        if (!isStopping) {
          addToast({
            message: "App deployed successfully",
            variant: "success",
          });
          setIsDeploying(false);
          setIsDeployed(true);
        }
        break;
      case APP_STATUS.ERROR:
        addToast({ message: "Failed to deploy app", variant: "error" });
        setIsDeploying(false);
        break;
      case APP_STATUS.PENDING:
        addToast({ message: "App deployment stopped", variant: "info" });
        setIsStopping(false);
        break;
      default:
        addToast({ message: "Unexpected app status", variant: "warning" });
        setIsDeploying(false);
    }
    return false;
  };

  useEffect(() => {
    let timeoutId;

    const fetchData = async () => {
      try {
        const appProperties = await canvasService.getApp(appID);
        setAppProperties(appProperties);
        const shouldRetry = handleAppStatus(
          appProperties.status || APP_STATUS.PENDING
        );
        if (shouldRetry) {
          timeoutId = setTimeout(fetchData, 5000);
        }
      } catch (error) {
        addToast({
          message: "Failed to fetch app properties, please try again.",
          variant: "error",
        });
      }
    };

    if (isDeploying || isStopping) {
      fetchData();
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isDeploying, appID, isStopping]);

  const makeInitialValues = (appProperties?: AppResponseType) => {
    if (!appProperties) {
      return {
        dns_name: "",
        requirements: [],
        python_script_name: "",
      };
    }
    return {
      dns_name: appProperties.dns_name || "",
      requirements: appProperties.requirements,
      python_script_name: appProperties.python_script_name || "",
      type: appProperties.type,
    };
  };

  const initialValues = useMemo(() => {
    return makeInitialValues(appProperties);
  }, [appProperties]);

  useMemo(() => {
    if (!appProperties?.requirements?.length) {
      return;
    }
    const formattedAppReqs = appProperties.requirements.map((requirement) => {
      const containsVersion = requirement.includes("==");
      return containsVersion
        ? {
            name: requirement?.split("==")[0],
            version: requirement?.split("==")[1],
            operator: "==",
            required: false,
          }
        : { name: requirement, operator: "==", version: "", required: false };
    });
    setAppRequirements(formattedAppReqs);
  }, [appProperties?.requirements]);

  const scriptNameValidation = useMemo(() => {
    if (selectedAppType === APP_TYPE.PYTHON) {
      return {
        regex: /^[a-zA-Z0-9_-]+\.py$/,
        message:
          "Script name must consist of alphanumeric characters, underscores, and end with .py",
      };
    }
    if (selectedAppType === APP_TYPE.R) {
      return {
        regex: /^[a-zA-Z0-9_-]+\.R$/,
        message:
          "Script name must consist of alphanumeric characters, underscores, and end with .R",
      };
    }
    return {
      regex: /^[a-zA-Z0-9_-]+\.(R|py)$/,
      message:
        "Script name must consist of alphanumeric characters, underscores, and end with .py or .R",
    };
  }, [selectedAppType]);

  const validationSchema = Yup.object().shape({
    dns_name: Yup.string()
      .matches(
        /^[a-zA-Z0-9-]+$/,
        "Address must consist of alphanumeric characters and dashes only"
      )
      .matches(/^(?!-)(?!.*-$).+/, "Address cannot start or end with a hyphen"),
    python_script_name: Yup.string().matches(
      scriptNameValidation.regex,
      scriptNameValidation.message
    ),
    type: Yup.string().oneOf([APP_TYPE.PYTHON, APP_TYPE.R]),
  });

  const handleSubmit = useCallback(
    async (values: typeof initialValues) => {
      setIsSaving(true);
      setIsFormSaved(true);
      const payload = Object.fromEntries(
        Object.entries(values).filter(
          ([, value]) =>
            value !== null &&
            !(
              typeof value === "object" &&
              !Array.isArray(value) &&
              Object.keys(value).length === 0
            )
        )
      );
      try {
        const response = await canvasService.patchUpdateApp({
          appID,
          payload,
        });
        formik.resetForm({
          values: makeInitialValues(response),
        });
        setRequirementsChanged(false);
        setAppProperties(response);
        addToast({
          message: "Hosted App configuration saved",
          variant: "success",
        });
      } catch (rejection: any) {
        const cause =
          typeof rejection?.cause?.detail === "string"
            ? rejection.cause.detail
            : "";
        const errorMessage = cause
          ? `Failed to save Hosted App configuration: "${cause}".`
          : "Failed to save Hosted App configuration.";
        addToast({
          message: errorMessage,
          variant: "error",
        });
      }
      setIsSaving(false);
    },
    [appProperties]
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  const dnsNameInputRef = useRef<HTMLInputElement>(null);
  const pythonScriptNameInputRef = useRef<HTMLInputElement>(null);

  const { dns_name, python_script_name, type } = formik.values;

  const deployApp = () => {
    canvasService.deployApp(appID).then(() => {
      addToast({
        message: `Deploying app...`,
        variant: "info",
      });
      setIsDeploying(true);
    });
  };

  const deleteApp = () => {
    setShowBuildLogs(false);
    setShowAppLogs(false);
    canvasService.deleteAppService(appID).then(() => {
      addToast({
        message: `Deleteing app deployment service...`,
        variant: "info",
      });
      setIsDeployed(false);
      setIsStopping(true);
    });
  };

  const apiAddress = useMemo(() => {
    return getWorkspaceAppAddress({ dns_name: dns_name || "<dns_name>" });
  }, [dns_name]);

  const handleCopyApiAddress = useCallback(() => {
    if (!apiAddress) {
      return;
    }
    navigator.clipboard.writeText(apiAddress).then(() => {
      addToast({
        message: "Copied API address to clipboard",
        variant: "info",
      });
    });
  }, [apiAddress]);

  const handleOnRequirementsChanged = (newRequirements: RequirementItem[]) => {
    setRequirementsChanged(true);

    if (!newRequirements.length) {
      setAppRequirements([]);
    }
    if (!appProperties) {
      return;
    }
    const appPropertiesCopy = { ...appProperties };
    const reqs = newRequirements.map((requirement) => {
      return requirement.version
        ? `${requirement.name}==${requirement.version}`
        : requirement.name;
    });
    appPropertiesCopy.requirements = reqs;
    appPropertiesCopy.dns_name = dns_name;
    appPropertiesCopy.python_script_name = python_script_name;
    appPropertiesCopy.type = type;
    setAppProperties(appPropertiesCopy);
  };

  const inputRef = useRef<HTMLInputElement>(null);

  type AppFile = {
    name: string;
    size: number;
    lastModified: string;
    uploadPercent: number;
  };

  const onFileUploadError = (file: AppFile, err?) => {
    const cause =
      typeof err?.cause?.detail === "string" ? err.cause.detail : "";
    const errorMessagePrefix = "Failed to upload";
    const errorMessage = cause
      ? `${errorMessagePrefix} ${file.name}: "${cause}". Try again later or contact support.`
      : `${errorMessagePrefix} ${file.name}. Try again later or contact support.`;
    addToast({
      message: errorMessage,
      variant: "error",
    });
  };

  const onFilesUploadProgress = () => () => {
    setIsFileUploading(true);
  };

  const onFileUploadCompleted = (file: AppFile) => () => {
    const payload = { s3_path: file.name };
    canvasService.patchUpdateApp({ appID, payload }).then(() => {
      addToast({
        message: `${file.name} uploaded`,
        variant: "success",
      });
      setIsFileUploading(false);
      if (!appProperties) {
        return;
      }
      const appPropertiesCopy = { ...appProperties };
      appPropertiesCopy.s3_path = file.name;
      setAppProperties(appPropertiesCopy);
      if (
        appProperties.dns_name &&
        appProperties.python_script_name &&
        appProperties.type
      ) {
        setIsFormSaved(true);
      }
    });
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const fileData = {
      name: file.name,
      size: file.size,
      uploadPercent: 0,
      lastModified: moment.utc().format(),
    };
    canvasService
      .getUploadAppFileToS3PresignedInfo(orgID, appID, file.name)
      .then((res) => {
        addToast({
          message: `${file.name} being uploaded...`,
          variant: "info",
        });
        uploadFile({
          presignedInfo: res,
          file,
          onProgress: onFilesUploadProgress(),
          onError: () => {
            onFileUploadError(fileData);
          },
          onComplete: onFileUploadCompleted(fileData),
        });
      })
      .catch((err) => {
        onFileUploadError(fileData, err);
      });
  };

  const handleUploadButtonClick = useCallback(() => {
    inputRef.current?.click();
  }, [inputRef, inputRef?.current]);

  const onFileDelete = () => {
    if (appProperties?.s3_path) {
      addToast({
        message: `${appProperties?.s3_path} being deleted...`,
        variant: "info",
      });
      canvasService
        .deleteAppFile(orgID, appID, appProperties?.s3_path)
        .then(() => {
          addToast({
            message: `${appProperties?.s3_path} deleted...`,
            variant: "success",
          });
        });
      const app_properties = { ...appProperties, s3_path: undefined };
      setAppProperties(app_properties);
    }
  };

  function isDeployButtonDisabled() {
    return (
      isSaving ||
      !formik.isValid ||
      formik.dirty ||
      !appProperties?.dns_name ||
      !appProperties?.python_script_name ||
      !appProperties?.s3_path ||
      !appProperties?.type ||
      isButtonDisabled ||
      !isFormSaved
    );
  }

  function isStopButtonDisabled() {
    return (
      isSaving ||
      !formik.isValid ||
      !dns_name ||
      !python_script_name ||
      isStopping
    );
  }

  const acceptedFileTypes = useMemo(() => {
    if (selectedAppType === APP_TYPE.PYTHON) {
      return ".zip,application/zip,.py";
    } else if (selectedAppType === APP_TYPE.R) {
      return ".zip,application/zip,.R";
    }
    return ".zip,application/zip";
  }, [selectedAppType]);

  const panelRef = useRef<ImperativePanelGroupHandle>(null);

  const appActionButtons = () => {
    if (appProperties?.status === APP_STATUS.PENDING || isDeploying) {
      return (
        <Button
          variant="primary"
          className={styles.showLogsButton}
          onClick={deployApp}
          disabled={isDeployButtonDisabled()}
        >
          {isDeploying ? (
            <Loader size={16}>
              <Typography variant="caption1" className={styles.loadingMessage}>
                Deploying...
              </Typography>
            </Loader>
          ) : (
            <span>Deploy App</span>
          )}
        </Button>
      );
    }
    if (appIsErrored) {
      return (
        <Button
          variant="primary"
          className={styles.showLogsButton}
          onClick={deleteApp}
        >
          <span>Reset App</span>
        </Button>
      );
    }
    if (appProperties?.status === APP_STATUS.READY || isStopping) {
      return (
        <Button
          variant="primary"
          className={styles.showLogsButton}
          onClick={deleteApp}
          disabled={isStopButtonDisabled()}
        >
          {isStopping ? (
            <Loader size={16}>
              <Typography variant="caption1" className={styles.loadingMessage}>
                Stopping...
              </Typography>
            </Loader>
          ) : (
            <span>Stop App</span>
          )}
        </Button>
      );
    }
    return null;
  };

  return (
    <div className={styles.container}>
      <PanelGroup direction="horizontal" ref={panelRef}>
        <Panel
          id={`app-settings`}
          order={0}
          minSize={20}
          className={styles.settingsWrapperPanel}
          style={{ overflow: "auto" }}
        >
          <div className={styles.settingsWrapper}>
            <Typography variant="h1">Create a hosted app</Typography>
            <Typography variant="body2" className={styles.subHeading}>
              Configure and create a hosted app which will be deployed to a
              custom DNS.
            </Typography>
            <div className={styles.deployButtonWrapper}>
              {appActionButtons()}
              {flags.enableAppBuildLogs ? (
                <>
                  <Button
                    className={styles.showLogsButton}
                    variant="secondary"
                    onClick={() => {
                      setShowAppLogs(!showAppLogs);
                      setShowBuildLogs(false);
                    }}
                    disabled={
                      isStopping || appProperties?.status === APP_STATUS.PENDING
                    }
                  >
                    <BiTerminal size={16} />
                    App Logs
                    <Tooltip
                      withArrow
                      placement="top"
                      text={showAppLogs ? "Hide Logs" : "Show Logs"}
                      hideOnClick
                      className={styles.toggleStatusIcon}
                    >
                      {showAppLogs ? (
                        <BiX size={16} />
                      ) : (
                        <BiCaretRight size={16} />
                      )}
                    </Tooltip>
                  </Button>
                  <Button
                    className={styles.showLogsButton}
                    variant="secondary"
                    onClick={() => {
                      setShowBuildLogs(!showBuildLogs);
                      setShowAppLogs(false);
                    }}
                    disabled={
                      isStopping || appProperties?.status === APP_STATUS.PENDING
                    }
                  >
                    <BiTerminal size={16} />
                    Build Logs
                    <Tooltip
                      withArrow
                      placement="top"
                      text={showBuildLogs ? "Hide Logs" : "Show Logs"}
                      hideOnClick
                      className={styles.toggleStatusIcon}
                    >
                      {showBuildLogs ? (
                        <BiX size={16} />
                      ) : (
                        <BiCaretRight size={16} />
                      )}
                    </Tooltip>
                  </Button>
                </>
              ) : null}
            </div>
            {(isDeploying || isDeployed) && !appIsErrored ? (
              <ToasterMessage fullWidth variant="info" animate={false}>
                Before you can use this DNS, initialization may take a few
                minutes.
              </ToasterMessage>
            ) : null}
            {appIsErrored ? (
              <ToasterMessage fullWidth variant="error" animate={false}>
                App deployment failed. Reset the app to try again.
              </ToasterMessage>
            ) : null}
            <form onSubmit={formik.handleSubmit}>
              <Typography variant="h2" className={styles.formTitle}>
                Configuration
              </Typography>
              <section className={styles.formSection}>
                <FormDropdown
                  id="app_type"
                  name="app_type"
                  label="App Type"
                  placeholder="Select App Type"
                  options={[
                    {
                      value: APP_TYPE.PYTHON,
                      label: "Python",
                    },
                    { value: APP_TYPE.R, label: "R" },
                  ]}
                  value={type}
                  onChange={(value) => {
                    formik.setFieldValue("type", value);
                    setSelectedAppType(value as APP_TYPE);
                  }}
                  disabled={
                    isButtonDisabled ||
                    !!appProperties?.s3_path?.includes(".R") ||
                    !!appProperties?.s3_path?.includes(".py")
                  }
                />
              </section>
              {appProperties?.s3_path && (
                <Typography variant="body2" className={styles.helperText}>
                  To change the app type, please delete the existing file
                </Typography>
              )}
              <TextField
                containerClassName={styles.textField}
                ref={dnsNameInputRef}
                id="dns_name"
                name="dns_name"
                label="Host Name"
                value={dns_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isDisabled={isButtonDisabled}
                outerControls={
                  <IconButton
                    variant="secondary"
                    size="large"
                    tooltip="Copy API address"
                    disabled={Boolean(formik.errors.dns_name || !dns_name)}
                    onClick={handleCopyApiAddress}
                  >
                    <BiCopy size="20" />
                  </IconButton>
                }
                helperText={`The API address will be ${apiAddress}`}
                error={formik.errors.dns_name}
              />

              <TextField
                containerClassName={styles.textField}
                ref={pythonScriptNameInputRef}
                id="python_script_name"
                name="python_script_name"
                label="App Script Name"
                value={python_script_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isDisabled={isButtonDisabled}
                helperText="The app script name which will be executed on the server."
                error={formik.errors.python_script_name}
              />

              <section className={styles.formSection}>
                <Typography variant="h3" component="label">
                  File
                </Typography>
                {!appProperties?.s3_path ? (
                  <>
                    <Button
                      variant="secondary"
                      onClick={handleUploadButtonClick}
                    >
                      {isFileUploading ? <Loader /> : <span>Upload File</span>}
                    </Button>
                    <Typography variant="body2" className={styles.subTitle}>
                      We support a .py file, .R file, or zip.
                    </Typography>
                    <input
                      ref={inputRef}
                      className={styles.hiddenInput}
                      onChange={handleFileUpload}
                      type="file"
                      accept={acceptedFileTypes}
                    />
                  </>
                ) : (
                  <Typography variant="body2" className={styles.subTitle}>
                    {appProperties?.s3_path}
                    <IconButton
                      tooltip="Delete File"
                      variant="plain"
                      className={styles.deleteButton}
                      onClick={onFileDelete}
                      disabled={isButtonDisabled}
                    >
                      <BiTrash />
                    </IconButton>
                  </Typography>
                )}
              </section>

              <section className={styles.formSection}>
                <Typography variant="h3" component="label">
                  Requirements
                </Typography>

                <RequirementsTable
                  requirements={appRequirements || []}
                  setRequirements={handleOnRequirementsChanged}
                  readOnly={isButtonDisabled}
                />
              </section>
              <Button
                type="submit"
                size="large"
                variant="primary"
                disabled={
                  !formik.isValid ||
                  isSaving ||
                  (!formik.dirty && !requirementsChanged)
                }
                className={styles.submitBtn}
              >
                {isSaving ? <Loader /> : <span>Save</span>}
              </Button>
            </form>
          </div>
        </Panel>
        {(showBuildLogs || showAppLogs) && flags.enableAppBuildLogs ? (
          <>
            <PanelResizeHandle id="app-settings" />
            <Panel id="app-logs" order={1} minSize={20}>
              <WorkspaceAppLogsViewer
                appId={appID}
                showBuildLogs={showBuildLogs}
                showAppLogs={showAppLogs}
                setShowAppLogs={showAppLogs ? setShowAppLogs : setShowBuildLogs}
              />
            </Panel>
          </>
        ) : null}
      </PanelGroup>
    </div>
  );
}
