import styles from "./Breadcrumb.module.scss";
import { type PropsWithChildren } from "react";
import { Link, type LinkProps } from "react-router-dom";
import { BiChevronDown } from "react-icons/bi";
import { DropdownMenuButton } from "components/common/DropdownMenuButton/DropdownMenuButton";
import type { ContextMenuItem } from "components/common/ContextMenu/ContextMenu";

type BreadcrumbProps = PropsWithChildren<{
  to: LinkProps["to"];
  menuOptions: ContextMenuItem[];
}>;

export function Breadcrumb({ children, to, menuOptions }: BreadcrumbProps) {
  return (
    <div className={styles.breadcrumb}>
      <Link className={styles.link} to={to}>
        {children}
      </Link>

      {menuOptions.length > 0 ? (
        <DropdownMenuButton
          hideChevron
          buttonVariant="text"
          placement="bottom"
          menuOptions={menuOptions}
          nodesClassNames={{
            button: styles.dropdownButton,
            text: styles.dropdownButtonText,
          }}
          text={<BiChevronDown size={16} />}
        />
      ) : null}
    </div>
  );
}
