import styles from "./PersonRow.module.scss";
import { memo, useMemo, type ReactNode } from "react";
import { BiX } from "react-icons/bi";
import { type UserStatus } from "models/user";
import Avatar from "components/common/Avatar/Avatar";
import IconButton from "components/common/IconButton/IconButton";
import { useUserState } from "store";

type PersonRowProps = {
  id: string;
  email: string;
  avatar?: string;
  username?: string;
  status?: UserStatus | null;
  invited?: boolean;
  canDeleteSelf?: boolean;
  onDeletePerson?: (id: string) => void;
  rightSideContent?: ReactNode;
};

export const PersonRow = memo(function PersonRow({
  id,
  email,
  avatar,
  username,
  status,
  invited,
  canDeleteSelf = false,
  onDeletePerson,
  rightSideContent,
}: PersonRowProps) {
  const userID = useUserState((slice) => slice.userID);

  const canDeletePerson = useMemo(() => {
    return !!onDeletePerson && (id !== userID || canDeleteSelf);
  }, [id, userID, canDeleteSelf, onDeletePerson]);

  return (
    <div className={styles.personRow}>
      {/* Image */}
      {avatar ? (
        <Avatar userName={username || email} userPicture={avatar || ""} />
      ) : (
        <Avatar mode="icon" />
      )}
      {/* Email */}
      <div className={styles.user}>
        <span className={styles.name}>
          {username ? username : email}

          {!invited && userID === id ? (
            <span className={styles.personYou}>(you)</span>
          ) : null}

          {status ? <span className={styles.status}>{status}</span> : null}
        </span>
        <span className={styles.email}>{email}</span>
        {invited ? <span className={styles.invited}> (Invited)</span> : null}
      </div>
      <div className={styles.rightSideContent}>
        {rightSideContent ?? null}
        {/* Delete button */}
        {canDeletePerson ? (
          <IconButton
            className={styles.removeButton}
            tooltip={`Remove ${email}`}
            onClick={() => {
              onDeletePerson?.(id);
            }}
          >
            <BiX size={16} />
          </IconButton>
        ) : null}
      </div>
    </div>
  );
});
