// This compoenent is responsible for checking the enableMaintenancePage flag
// if the flag is true it will show the maintenance page
// if the flag is false it will proceed to the Outlet

import { type PropsWithChildren } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { MaintenancePage } from "pages/MaintenancePage/MaintenancePage";

type MaintenanceProps = PropsWithChildren;

export function Maintenance({ children }: MaintenanceProps) {
  const { enableMaintenancePage } = useFlags();

  // TODO: move the condition to loader
  // See: https://github.com/remix-run/react-router/discussions/10223#discussioncomment-5909050
  if (enableMaintenancePage) {
    return <MaintenancePage />;
  }

  return <>{children}</>;
}
