import { PiFunctionBold } from "react-icons/pi";
import {
  TbVariable,
  TbVariableOff,
  TbDatabaseSearch,
  TbDatabase,
} from "react-icons/tb";

export enum ASSET_TYPE {
  FUNCTIONS = "functions",
  CONSTANTS = "constants",
  CONNECTIONS = "connections",
  QUERIES = "queries",
}

export const ASSET_DETAILS = Object.freeze({
  [ASSET_TYPE.FUNCTIONS]: {
    label: "Functions",
    fullLabel: "Functions & Classes",
    Icon: PiFunctionBold,
  },
  [ASSET_TYPE.CONSTANTS]: {
    label: "Constants",
    fullLabel: "Constants & Secrets",
    Icon: TbVariable,
    HiddenIcon: TbVariableOff,
  },
  [ASSET_TYPE.CONNECTIONS]: {
    label: "Connections",
    fullLabel: "Connections",
    Icon: TbDatabase,
  },
  [ASSET_TYPE.QUERIES]: {
    label: "Queries",
    fullLabel: "Queries",
    Icon: TbDatabaseSearch,
  },
});
