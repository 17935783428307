import pluralize from "pluralize";
import { useCanvasSorting } from "hooks/useCanvasSorting";
import {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
  type MouseEvent,
} from "react";
import { deletePublishedReportsByIds } from "api/http/canvas-report-service";
import { BiX } from "react-icons/bi";
import { useConfirmModal } from "hooks/useConfirmModal";
import { useToastsState, useLocalReportPreferencesState } from "store";
import { Button } from "components/common/Button/Button";
import Typography from "components/Typography/Typography";
import IconButton from "components/common/IconButton/IconButton";
import { type SORTING_COLUMN } from "config/localPreferences";
import { type SORTING_DIRECTION } from "config/appConfig";
import styles from "components/canvases/CanvasesListLayout/CanvasesListLayout.module.scss";
import type { ReportTableType } from "models/publishedReport";
import { ConfirmReportsDeletionModal } from "components/blocks/ConfirmReportsDeletionModal/ConfirmReportsDeletionModal";

enum REPORT_ACTION {
  delete = "delete",
}

interface UseReportsProps {
  isLoadingInitial?: boolean;
}

export function useReports({ isLoadingInitial = false }: UseReportsProps) {
  const addToast = useToastsState((slice) => slice.addToast);
  const { openConfirmModal } = useConfirmModal();

  const [isLoading, setIsLoading] = useState(isLoadingInitial);
  const [searchQuery, setSearchQuery] = useState("");
  const [reports, setReports] = useState<ReportTableType[]>([]);
  const [selectedReportIds, setSelectedReportIds] = useState<
    Record<string, string>
  >({});
  // report id to action map
  const [processedReportsIdActionMap, setProcessedReportsIdActionMap] =
    useState<Record<string, REPORT_ACTION>>({});

  // timeout is needed to prevent firing onClick before onDoubleClick
  const selectReportTimeoutId = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    return () => {
      clearTimeout(selectReportTimeoutId.current);
    };
  }, []);

  const {
    reportsSortingColumn,
    reportsSortingDirection,
    setReportsSortingColumn,
    setReportsSortingDirection,
  } = useLocalReportPreferencesState((slice) => {
    return {
      reportsSortingColumn: slice.reportsSortingColumn,
      reportsSortingDirection: slice.reportsSortingDirection,
      setReportsSortingColumn: slice.setReportsSortingColumn,
      setReportsSortingDirection: slice.setReportsSortingDirection,
    };
  });

  const currentSorting = useMemo(() => {
    return {
      column: reportsSortingColumn,
      direction: reportsSortingDirection,
    };
  }, [reportsSortingColumn, reportsSortingDirection]);

  const selectedReportsIdsList = useMemo(() => {
    return Object.values(selectedReportIds);
  }, [selectedReportIds]);

  const sortedItems = useCanvasSorting(currentSorting, reports, searchQuery);

  const handleSearchQueryChange = useCallback((e) => {
    setSearchQuery(e.target.value);
  }, []);

  const handleDropSelection = useCallback(() => {
    setSelectedReportIds({});
  }, []);

  const setProcessedReportIdAction = useCallback(
    (id: string, action: REPORT_ACTION) => {
      setProcessedReportsIdActionMap((ids) => {
        return {
          ...ids,
          [id]: action,
        };
      });
    },
    []
  );

  const removeProcessedReportId = useCallback((id: string) => {
    setProcessedReportsIdActionMap((ids) => {
      const { [id]: _, ...rest } = ids;

      return rest;
    });
  }, []);

  useEffect(() => {
    if (!sortedItems.length && selectedReportsIdsList.length > 0) {
      handleDropSelection();

      return;
    }

    const idsToRemove: string[] = [];
    selectedReportsIdsList.forEach((id) => {
      if (!sortedItems.some((report) => report.id === id)) {
        idsToRemove.push(id);
      }
    });

    if (!idsToRemove.length) {
      return;
    }

    setSelectedReportIds((ids) => {
      return idsToRemove.reduce((acc, curr) => {
        const { [curr]: _, ...rest } = acc;

        return rest;
      }, ids);
    });
  }, [sortedItems]);

  const noReports = reports.length === 0;
  const notFound = !noReports && sortedItems.length === 0;

  const handleChangeSorting = ({
    column,
    direction,
  }: {
    column: SORTING_COLUMN;
    direction: SORTING_DIRECTION;
  }) => {
    setReportsSortingColumn(column);
    setReportsSortingDirection(direction);
  };

  const openReport = useCallback((reportName: string) => {
    //open in new tab
    window.open(`/report/${reportName}`, "_blank");
  }, []);

  const toggleSelectReport = useCallback((reportId: string) => {
    setSelectedReportIds((ids) => {
      if (ids[reportId]) {
        const { [reportId]: _, ...rest } = ids;

        return rest;
      }

      return {
        ...ids,
        [reportId]: reportId,
      };
    });
  }, []);

  const handleReportClick = useCallback(
    (reportId: string, event: MouseEvent<HTMLElement>) => {
      clearTimeout(selectReportTimeoutId.current);
      // find report by id
      const report = reports.find((item) => item.id === reportId);
      if (report && event.detail !== 1) {
        openReport(report.name);
        return;
      }

      selectReportTimeoutId.current = setTimeout(() => {
        toggleSelectReport(reportId);
      }, 250);
    },
    []
  );

  const handleDeleteReports = useCallback(
    async (reportIds: string[]) => {
      if (!reportIds.length) {
        return;
      }

      const confirmed = await openConfirmModal({
        message: (
          <ConfirmReportsDeletionModal
            reportIds={reportIds}
            reports={reports}
          />
        ),
        confirmButtonLabel: "Delete",
        confirmButtonVariant: "crucial",
      });

      if (!confirmed) {
        return;
      }

      setProcessedReportsIdActionMap((ids) => {
        return reportIds.reduce((acc, curr) => {
          return {
            ...acc,
            [curr]: REPORT_ACTION.delete,
          };
        }, ids);
      });

      try {
        await deletePublishedReportsByIds(reportIds);
        setReports((items) =>
          items.filter((item) => {
            return !reportIds.includes(item.id);
          })
        );
        addToast({
          variant: "success",
          message:
            reportIds.length > 1
              ? "Successfully deleted reports"
              : "Successfully deleted report",
        });
      } catch (rejection) {
        addToast({
          variant: "error",
          message:
            reportIds.length > 1
              ? "Failed to delete reports"
              : "Failed to delete report",
        });
      }

      setProcessedReportsIdActionMap((ids) => {
        const newIds = { ...ids };
        reportIds.forEach((id) => {
          delete newIds[id];
        });
        return newIds;
      });
    },
    [reports]
  );

  const handleDeleteSelectedReports = useCallback(() => {
    handleDeleteReports(selectedReportsIdsList);
  }, [selectedReportsIdsList, handleDeleteReports]);

  const toolbar = useMemo(() => {
    if (isLoading) {
      return null;
    }

    const selectedReportsCount = selectedReportsIdsList.length;

    if (!selectedReportsCount) {
      return null;
    }

    const {
      openReportsDisabled,
      deleteReportsDisabled,
      clearSelectedDisabled,
    } = (() => {
      const disabledButtonMap = {
        openReportsDisabled: false,
        deleteReportsDisabled: false,
        clearSelectedDisabled: false,
      };

      if (!Object.keys(processedReportsIdActionMap).length) {
        return disabledButtonMap;
      }

      for (const selectedReportId of selectedReportsIdsList) {
        const action = processedReportsIdActionMap[selectedReportId];

        if (!action) {
          continue;
        }
        if (action === REPORT_ACTION.delete) {
          disabledButtonMap.openReportsDisabled = true;
          disabledButtonMap.deleteReportsDisabled = true;
          disabledButtonMap.clearSelectedDisabled = true;
          break;
        }

        disabledButtonMap.deleteReportsDisabled = true;
        break;
      }

      return disabledButtonMap;
    })();

    return (
      <div className={styles.toolbar}>
        <div>
          <Typography
            component="span"
            variant="caption1"
            className={styles.selected_count}
          >
            {selectedReportsCount}
          </Typography>
          <Typography
            component="span"
            variant="caption1"
            className={styles.selected_label}
          >
            {`${pluralize("Item", selectedReportsCount)} Selected`}
          </Typography>
        </div>
        <div className={styles.toolbar_actions}>
          {selectedReportsCount === 1 && (
            <Button
              className={styles.toolbar_actions_button}
              variant="secondary"
              disabled={openReportsDisabled}
              onClick={() => {
                // get the first selected report and open it
                const report = reports.find(
                  (item) => item.id === selectedReportsIdsList[0]
                );
                if (!report) {
                  return;
                }
                openReport(report.name);
              }}
            >
              Open
            </Button>
          )}
          <Button
            className={styles.toolbar_actions_button}
            variant="crucial"
            disabled={deleteReportsDisabled}
            onClick={handleDeleteSelectedReports}
          >
            Delete
          </Button>
          <IconButton
            disabled={clearSelectedDisabled}
            onClick={handleDropSelection}
          >
            <BiX size={20} />
          </IconButton>
        </div>
      </div>
    );
  }, [
    selectedReportsIdsList,
    handleDeleteSelectedReports,
    processedReportsIdActionMap,
    isLoading,
  ]);

  return {
    searchQuery,
    isLoading,
    noReports,
    notFound,
    sortedItems,
    reportsSortingColumn,
    reportsSortingDirection,
    processedReportsIdActionMap,
    selectedReportIds,
    toolbar,
    handleDropSelection,
    handleReportClick,
    handleDeleteReports,
    handleChangeSorting,
    handleSearchQueryChange,
    openReport,
    removeProcessedReportId,
    setReports,
    setIsLoading,
    setProcessedReportIdAction,
    setSearchQuery,
  };
}
