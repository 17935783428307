import {
  createContext,
  useMemo,
  useState,
  useCallback,
  type MouseEvent,
  type ReactNode,
} from "react";
import {
  autoUpdate,
  flip,
  offset,
  shift,
  useFloating,
  useFloatingNodeId,
  useDismiss,
  FloatingNode,
  FloatingPortal,
  FloatingTree,
} from "@floating-ui/react";
import ContextMenu, {
  type ContextMenuItem,
} from "components/common/ContextMenu/ContextMenu";

export const ContextMenuContext = createContext<{
  openContextMenu: (event: MouseEvent, options: ContextMenuItem[]) => void;
  closeContextMenu: () => void;
} | null>(null);

export function ContextMenuProvider({ children }: { children: ReactNode }) {
  const floatingNodeId = useFloatingNodeId();

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<ContextMenuItem[]>([]);
  const [position, setPosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  const openContextMenu = useCallback(
    (event: MouseEvent, options: ContextMenuItem[]) => {
      setOptions(options);
      setPosition({ x: event.clientX, y: event.clientY });
      setOpen(true);
    },
    []
  );

  const closeContextMenu = useCallback(() => {
    setOpen(false);
  }, []);

  const { x, y, refs, strategy, context } = useFloating({
    nodeId: floatingNodeId,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    placement: "bottom-start",
    middleware: [flip(), shift(), offset(8)],
  });

  useDismiss(context, {
    escapeKey: true,
    outsidePress: true,
    // allow clicks on sub-menu
    capture: {
      escapeKey: true,
      outsidePress: false,
    },
  });

  const value = useMemo(() => {
    return { openContextMenu, closeContextMenu };
  }, [openContextMenu, closeContextMenu]);

  return (
    <ContextMenuContext.Provider value={value}>
      <FloatingTree>
        {children}
        <FloatingNode id={floatingNodeId}>
          {open ? (
            <FloatingPortal>
              <div
                ref={refs.setReference}
                style={{
                  position: "fixed",
                  zIndex: 2000,
                  top: position.y ?? 0,
                  left: position.x ?? 0,
                }}
                onClick={closeContextMenu}
              >
                <div
                  ref={refs.setFloating}
                  style={{
                    position: strategy,
                    top: y ?? 0,
                    left: x ?? 0,
                    width: "max-content",
                    maxHeight: "60vh",
                  }}
                >
                  <ContextMenu
                    menuOptions={options}
                    onClose={() => {
                      setOpen(false);
                    }}
                  />
                </div>
              </div>
            </FloatingPortal>
          ) : null}
        </FloatingNode>
      </FloatingTree>
    </ContextMenuContext.Provider>
  );
}
