import styles from "./ButtonsDropdown.module.scss";
import { useCallback, useMemo, type ComponentType, type SVGProps } from "react";
import { type IconBaseProps } from "react-icons";
import { DropdownMenuButton } from "components/common/DropdownMenuButton/DropdownMenuButton";
import type { ContextMenuItem } from "components/common/ContextMenu/ContextMenu";

type IconComponentProps = IconBaseProps | SVGProps<SVGSVGElement>;

interface Option {
  id: string;
  label: string;
  icon: ComponentType<IconComponentProps>;
}

interface ButtonsDropdownProps {
  label: string;
  options: Option[];
  disabled?: boolean;
  onSelect: (option: Option) => void;
}

export default function ButtonsDropdown({
  label,
  options,
  disabled = false,
  onSelect,
}: ButtonsDropdownProps) {
  const handleOptionClick = useCallback(
    (option) => () => {
      onSelect(option);
    },
    [onSelect]
  );

  const menuOptions = useMemo<ContextMenuItem[]>(() => {
    return [
      {
        key: "connections-list",
        type: "custom",
        component: (
          <div className={styles.menu}>
            {options.map((option) => {
              const { id, label, icon: Icon } = option;

              return (
                <div
                  key={id}
                  className={styles.option}
                  onClick={handleOptionClick(option)}
                >
                  <div className={styles.option_icon}>
                    <Icon />
                  </div>
                  <div className={styles.option_label}>{label}</div>
                </div>
              );
            })}
          </div>
        ),
      },
    ];
  }, [options, handleOptionClick]);

  return (
    <DropdownMenuButton
      text={label}
      disabled={disabled}
      menuOptions={menuOptions}
      nodesClassNames={{
        contextMenu: styles.dropdownContainer,
      }}
    />
  );
}
