// This page redirects to the Auth0 sign up page if the user is not logged in.
// otherwise it redirects to the home page on /

import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useLocation } from "react-router-dom";

export const SignUp = () => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();

  // the path to redirect to after the user signs up
  const redirectPath =
    new URLSearchParams(location.search).get("redirectTo") || "/";

  useEffect(() => {
    if (!isLoading) {
      isAuthenticated
        ? navigate(redirectPath)
        : loginWithRedirect({
            appState: {
              returnTo: redirectPath,
            },
            authorizationParams: {
              screen_hint: "signup",
            },
          });
    }
  }, [isLoading, isAuthenticated]);

  return null;
};
