import { useCallback, useEffect, useMemo } from "react";
import { BiLinkExternal, BiX } from "react-icons/bi";
import pluralize from "pluralize";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  type ColumnDef,
} from "@tanstack/react-table";
import cn from "classnames";
import { EmptyState } from "components/common/EmptyState/EmptyState";
import Loader from "components/common/Loader/Loader";
import Typography from "components/Typography/Typography";
import LabeledDropdownButton from "components/common/LabeledDropdownButton/LabeledDropdownButton";
import Search from "components/common/Search/Search";
import Table from "components/common/Table/Table";
import { Checkbox } from "components/common/Checkbox/Checkbox";
import IconButton from "components/common/IconButton/IconButton";
import Avatar from "components/common/Avatar/Avatar";
import { Button } from "components/common/Button/Button";
import useMultiselection from "hooks/useMultiselection";
import useManageUsage from "./useManageUsage";
import {
  LABEL,
  TEXT,
  USAGE_TYPE_CONFIG,
  USAGE_TYPES_OPTIONS,
  getAssetOption,
  getUsageLink,
} from "./constants";
import type { ManageUsageProps, TableRowData, UsageType } from "./types";
import styles from "./ManageUsage.module.scss";

const ManageUsage = ({ assetId }: ManageUsageProps) => {
  const {
    actionInProgress,
    assetsOptions,
    searchQuery,
    setSearchQuery,
    tableData,
    emptyState,
    selectedAsset,
    selectedTypeOption,
    setSelectedTypeOption,
    deleteAssetUsageGroup,
    setSelectedAssetId,
  } = useManageUsage(assetId);

  const {
    isSelectedAll,
    selectedElements: selectedRowIds,
    setSelectedElements: setSelectedRowIds,
    selectAll,
  } = useMultiselection<string>(tableData.map((el) => el.id));

  const selectedRowIdsLength = selectedRowIds.length;

  useEffect(() => {
    setSelectedRowIds([]);
  }, [tableData]);

  const assetOwner = useMemo(() => {
    const assetOwner = selectedAsset?.connection.owner;

    if (assetOwner) {
      const { username, email } = assetOwner;

      return username || email;
    }

    return "Unknown";
  }, [selectedAsset]);

  const onSearchQueryChange = useCallback((e) => {
    e.stopPropagation();
    setSearchQuery(e.target.value);
  }, []);

  const handleLinksOpen = useCallback(() => {
    // if browser block popups - only one tab will be opened
    selectedRowIds.forEach((id) => {
      const rowData = tableData.find((rowData) => rowData.id === id);

      if (rowData) {
        const usageLink = getUsageLink(id, rowData?.type);

        if (usageLink) {
          window.open(usageLink, "_blank", "noopener,noreferrer");
        }
      }
    });
  }, [selectedRowIds, tableData]);

  const handleDropSelection = useCallback(() => {
    setSelectedRowIds([]);
  }, []);

  const handleDeleteSelected = useCallback(async () => {
    const preparedArgs = selectedRowIds.reduce(
      (res: [string, UsageType][], id: string) => {
        const rowData = tableData.find((el) => el.id === id);
        if (rowData?.type) {
          res.push([id, rowData.type]);
        }

        return res;
      },
      []
    );

    await deleteAssetUsageGroup(preparedArgs);

    setSelectedRowIds([]);
  }, [selectedRowIds, deleteAssetUsageGroup, tableData]);

  const columns = useMemo<ColumnDef<TableRowData>[]>(
    () => [
      {
        id: "checkbox",
        accessor: "",
        accessorKey: "",
        header: () => (
          <Checkbox
            checked={isSelectedAll}
            onChange={selectAll}
            disabled={!tableData.length}
          />
        ),
        cell: (info) => {
          const id = info.row.original.id;
          const isChecked = selectedRowIds.includes(id);

          const handleCheck = () => {
            if (isChecked) {
              setSelectedRowIds((prev) => prev.filter((selId) => selId !== id));
            } else {
              setSelectedRowIds((prev) => [...prev, id]);
            }
          };

          return <Checkbox checked={isChecked} onChange={handleCheck} />;
        },
      },
      {
        id: "name",
        accessorKey: "name",
        enableSorting: true,
        header: () => <div className={styles.name_header}>Name</div>,
        cell: (info) => {
          return (
            <Typography
              component="div"
              variant="body1"
              className={styles.name_cell}
            >
              {info.row.original.name}
            </Typography>
          );
        },
      },
      {
        accessorKey: "type",
        enableSorting: false,
        header: "Type",
        cell: (info) => {
          const { id, type } = info.row.original;
          const handleDelete = () => {
            deleteAssetUsageGroup([[id, type]]);
          };
          const usageLink = getUsageLink(id, type);

          return (
            <div className={styles.type_cell}>
              <Typography component="span" variant="body1">
                {USAGE_TYPE_CONFIG[type]?.name ?? ""}
              </Typography>
              <div className={styles.row_actions}>
                <a
                  className={cn(styles.row_action, {
                    [styles.row_action_hidden]: !usageLink,
                  })}
                  href={usageLink ?? ""}
                  target="_blank"
                  rel="noreferrer"
                >
                  <BiLinkExternal size={16} />
                </a>
                <span className={styles.row_action} onClick={handleDelete}>
                  <BiX size={16} />
                </span>
              </div>
            </div>
          );
        },
      },
    ],
    [isSelectedAll, selectedRowIds, tableData.length, deleteAssetUsageGroup]
  );

  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel<TableRowData>(),
    getSortedRowModel: getSortedRowModel<TableRowData>(),
    initialState: {
      sorting: [{ id: "name", desc: false }],
    },
  });

  const bottomBar = useMemo(() => {
    return (
      <div className={styles.bottom_bar}>
        <div>
          <Typography
            component="span"
            variant="caption1"
            className={styles.selected_count}
          >
            {selectedRowIdsLength}
          </Typography>
          <Typography
            component="span"
            variant="caption1"
            className={styles.selected_label}
          >
            {`${pluralize("Item", selectedRowIdsLength)} Selected`}
          </Typography>
        </div>
        <div className={styles.bottom_bar_actions}>
          {selectedRowIdsLength === 1 && (
            <Button
              className={styles.bottom_bar_actions_button}
              variant="secondary"
              onClick={handleLinksOpen}
            >
              {LABEL.open}
            </Button>
          )}
          <Button
            className={styles.bottom_bar_actions_button}
            variant="crucial"
            onClick={handleDeleteSelected}
          >
            {LABEL.remove}
          </Button>
          <IconButton onClick={handleDropSelection}>
            <BiX size={20} />
          </IconButton>
        </div>
      </div>
    );
  }, [
    selectedRowIdsLength,
    handleLinksOpen,
    handleDeleteSelected,
    handleDropSelection,
  ]);

  return (
    <div className={styles.container}>
      <Typography component="p" variant="body2" className={styles.description}>
        {TEXT.manageUsageDescription}
      </Typography>
      <div className={styles.main}>
        <Search value={searchQuery} onChange={onSearchQueryChange} />
        <div className={styles.dropdowns}>
          <div className={styles.assets_dropdown_wrapper}>
            <LabeledDropdownButton
              classes={{ contextMenu: styles.assets_dropdown_menu }}
              title={LABEL.assetsDropdown}
              items={assetsOptions}
              selectedItem={getAssetOption(selectedAsset)}
              setSelectedItem={(item) => {
                setSelectedAssetId(item.id);
              }}
            />
          </div>
          <LabeledDropdownButton
            title={LABEL.typesDropdown}
            items={USAGE_TYPES_OPTIONS}
            selectedItem={selectedTypeOption}
            setSelectedItem={setSelectedTypeOption}
          />
        </div>
        <Table
          table={table}
          emptyState={
            emptyState ? (
              <EmptyState
                variant="info"
                title={emptyState.title}
                description={emptyState.text}
              />
            ) : null
          }
          nodesClassNames={{ row: styles.row, wrapper: styles.table_wrapper }}
          bottomBar={selectedRowIdsLength !== 0 && bottomBar}
        />
        <div className={styles.footer}>
          <Typography
            component="span"
            variant="body2"
            className={styles.owner_title}
          >
            {LABEL.assetOwner}
          </Typography>

          <div className={styles.owner_info_block}>
            <Avatar
              size="small"
              mode="icon"
              wrapperClassName={styles.owner_avatar}
            />
            <Typography component="div" variant="body1">
              {assetOwner}
            </Typography>
          </div>
        </div>
        {actionInProgress && <Loader withOverlay />}
      </div>
    </div>
  );
};

export default ManageUsage;
