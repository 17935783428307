import { useContext } from "react";
import { TokenContext } from "context/Token";

const useToken = () => {
  const context = useContext(TokenContext);

  if (!context) {
    throw new Error("useToken has to be used within <TokenContext.Provider>");
  }

  return context;
};

export default useToken;
