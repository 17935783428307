import styles from "./UserSettingsBilling.module.scss";
import { type FC, useState, useMemo, useEffect } from "react";
import cloneDeep from "lodash-es/cloneDeep";
import { PaymentTip, PaymentPlan, PaymentHistory } from "./components";
import { DowngradeBillingModal } from "components/modals";
import Switch from "components/common/Switch/Switch";
import Typography from "components/Typography/Typography";
import { EmptyState } from "components/common/EmptyState/EmptyState";
import { useModal } from "hooks/useModal";
import { useBillingSettings } from "hooks/useBillingSettings";
import { useUserBillingState, useUserState } from "store";
import { UserSettingsTabHeader } from "components/UserSettingsTabHeader";
import { type PaymentPlan as PaymentPlanType } from "models/settings/billing-interface";
import userService from "api/http/user-service";
import { ProgressBar } from "components/common/ProgressBar/ProgressBar";

const comingPlan: PaymentPlanType = {
  description: "Custom features",
  features: {
    sharing: "Privately projects sharing",
    assets: "Private asset and environment",
    hosting: "Self hosting",
    control: "Organization control",
    auth: "SSO/Custom AUTH",
  },
  id: "00yymm",
  name: "Organization",
  prices: [
    {
      description: "default",
      id: "11aabb",
      period: null,
      status: "downgrade",
      action: () => {},
      stripe_id: "default",
      total_payment: 0,
    },
  ],
  stripe_id: "default",
  disabledMessage: "Coming Soon",
  total_payment: 0,
};

const TOTAL_USAGE = 360000;

const UserSettingsBilling: FC = () => {
  const [isBillAnnuallyOn, setIsBillAnnuallyOn] = useState<boolean>(false);
  const { onUpgrade, onDowngrade, billingTips, preparedHistory } =
    useBillingSettings();
  const { openModal } = useModal();
  const userID = useUserState((slice) => slice.userID);
  const [userComputeUsage, setUserComputeUsage] = useState<any>(null);

  useEffect(() => {
    if (!userID) {
      return;
    }
    userService.getComputeUsage(userID).then((data) => {
      setUserComputeUsage(data?.[0]?.total_execution_seconds ?? 0);
    });
  }, [userID]);

  const userComputeUsagePercentage = useMemo(
    () => Math.round((userComputeUsage / TOTAL_USAGE) * 100),
    [userComputeUsage]
  );

  const tiers = useUserBillingState((slice) => slice.tiers);
  const tier = useUserBillingState((slice) => slice.user_billing.tier);

  const preparedPlans = useMemo(() => {
    const clone = cloneDeep(tiers);

    return clone.map((t: any) => {
      const actualPeriod = t.prices.reduce((acc, curr, i, arr) => {
        if (curr.period === null) {
          acc = t.prices[0];
        } else if (isBillAnnuallyOn) {
          acc = arr.find((p) => p.period === "year");
          acc.discount = 30;
        } else {
          acc = arr.find((p) => p.period === "month");
        }

        const userPayment = tier.billing_plan?.total_payment ?? 0;
        const planPayment = acc.total_payment ?? 0;

        if (userPayment > planPayment) {
          acc.status = "downgrade";
          acc.action = handleDowngrade;
        } else if (userPayment < planPayment) {
          acc.status = "upgrade";
          acc.action = onUpgrade;
        } else {
          acc.status = "active";
          acc.action = () => null;
        }
        return acc;
      }, t.prices[0]);

      t.prices = [actualPeriod];
      return t;
    });
  }, [tiers, isBillAnnuallyOn]);

  function handleDowngrade() {
    openModal({
      title: "Downgrade to Always Free",
      content: ({ onModalClose }) => (
        <DowngradeBillingModal
          onDowngrade={onDowngrade}
          endSubscriptionDate={tier.next_billing}
          onModalClose={onModalClose}
        />
      ),
    });
  }

  return (
    <div className={styles.billing}>
      <UserSettingsTabHeader
        heading="Billing"
        subHeading="View your Plan information, upgrade to better one or manage your invoices information."
      />

      <div className={styles["billing__tips"]}>
        {billingTips.map((tip) => {
          return <PaymentTip tip={tip} key={tip.subtitle} />;
        })}
      </div>

      <div className={styles.userComputeUsage}>
        <Typography
          component="h2"
          variant="h2"
          className={styles.userComputeUsageTitle}
        >
          <span>User Compute Usage</span>
          <span>{userComputeUsagePercentage} %</span>
        </Typography>
        <ProgressBar
          value={userComputeUsagePercentage}
          containerClassName={styles.userComputeUsageProgressBar}
        />
      </div>

      <div>
        <div className={styles["billing__discount"]}>
          <div>
            <Typography component="h2" variant="h2">
              Plans
            </Typography>

            <Typography
              className={styles["billing__subtitle"]}
              component="p"
              variant="body2"
            >
              Select a plan depending from your needs.
            </Typography>
          </div>

          <div className={styles["billing__discount__annual"]}>
            <Switch
              title="Bill Annually"
              isChecked={isBillAnnuallyOn}
              onChange={setIsBillAnnuallyOn}
            />

            <Typography
              component="span"
              variant="shortcuts"
              className={styles["billing__discount__value"]}
            >
              30% off
            </Typography>
          </div>
        </div>

        <div className={styles["billing__plans"]}>
          {[...preparedPlans, comingPlan]?.map((plan) => {
            return <PaymentPlan key={plan.id} plan={plan} />;
          })}
        </div>

        <div className={styles["billing__history"]}>
          <Typography component="h2" variant="h2">
            Payment History
          </Typography>

          <PaymentHistory historyData={preparedHistory} />

          {preparedHistory.length === 0 && (
            <EmptyState
              variant="info"
              containerClassName={styles.empty_state}
              title="No invoices yet..."
              description="There are no invoices to display yet."
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default UserSettingsBilling;
