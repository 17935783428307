import { Navigate, type RouteObject } from "react-router-dom";
import { FunctionsPages } from "layout/FunctionsPages/FunctionsPages";
import { ConstantsPages } from "layout/ConstantsPages/ConstantsPages";
import { ConnectionsPages } from "layout/ConnectionsPages/ConnectionsPages";
import { QueriesPage } from "layout/QueriesPage/QueriesPage";
import { Functions } from "pages/Assets/Functions/Functions";
import { Constants } from "pages/Assets/Constants/Constants";
import { Connections } from "pages/Assets/Connections/Connections";
import { Queries } from "pages/Assets/Queries/Queries";

export const PERSONAL_ASSETS_ROUTES: RouteObject[] = [
  {
    index: true,
    element: <Navigate replace to="functions" />,
  },
  {
    path: "functions",
    element: <FunctionsPages />,
    children: [
      {
        path: ":versionID",
        element: <Functions />,
      },
    ],
  },
  {
    path: "constants",
    element: <ConstantsPages />,
    children: [
      {
        path: ":versionID",
        element: <Constants />,
      },
    ],
  },
  {
    path: "connections",
    element: <ConnectionsPages />,
    children: [
      {
        path: ":connectionId",
        element: <Connections />,
      },
    ],
  },
  {
    path: "queries",
    element: <QueriesPage />,
    children: [
      {
        path: ":versionID",
        element: <Queries />,
      },
    ],
  },
];
