import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAssetsState, useToastsState } from "store";
import type { FunctionItemType } from "pages/Assets/types/functionTypes";
import {
  deleteFunctionById,
  deleteFunctionVersion,
  putFunctionAssetArchive,
  putFunctionVersionArchive,
  putFunctionVersionStatus,
} from "api/http/assets/functions-service";
import { getAssetsBaseUrl } from "pages/Assets/utils";

export default function useCurrentFunctionsData() {
  const addToast = useToastsState((slice) => slice.addToast);
  const navigate = useNavigate();
  const { orgID, workspaceID } = useParams();

  const baseURL = useMemo(() => {
    return getAssetsBaseUrl({
      organizationId: orgID,
      workspaceId: workspaceID,
    });
  }, [orgID, workspaceID]);

  const {
    functionsData,
    isDisplayArchivedFunctions,
    selectedFunction,
    functionsDataLoadState,
  } = useAssetsState((slice) => slice.functions);

  const {
    setSelectedFunctionId,
    setSelectedFunctionVersionNumber,
    setFunctionsData,
    setIsDisplayArchivedFunctions,
    clearSelectedFunction,
    deleteFunctionVersion: deleteFunctionVersionStore,
    updateFunctionVersion,
    deleteFunctionById: deleteFunctionByIdFromStore,
    clearStore: clearFunctionsStore,
  } = useAssetsState((slice) => slice.functionsActions);

  const currentFunctionsData = {
    assetsData: functionsData,
    isDisplayArchivedItems: isDisplayArchivedFunctions,
    activeItemData: selectedFunction.id
      ? functionsData[selectedFunction.id]
      : null,
    activeVersionData:
      selectedFunction.id && selectedFunction.versionNumber
        ? functionsData[selectedFunction?.id]?.versions[
            selectedFunction.versionNumber
          ]
        : null,
    activeItemId: selectedFunction.id,
    activeItemVersionId:
      selectedFunction.id && selectedFunction.versionNumber
        ? functionsData[selectedFunction.id]?.versions[
            selectedFunction.versionNumber
          ]?.id
        : null,
    assetDataLoadState: functionsDataLoadState,
  };

  const currentFunctionsActions = {
    setActiveItemId: (id: string) => {
      setSelectedFunctionId(id);
    },
    setActiveVersionNumber: (versionNumber: number) => {
      setSelectedFunctionVersionNumber(versionNumber);
    },
    setAssetsData: (data) => {
      setFunctionsData(data as Record<string, FunctionItemType>);
    },
    deleteAssetItem: (assetItemId) => {
      const deletedFunctionId = assetItemId ?? selectedFunction.id;
      if (deletedFunctionId) {
        deleteFunctionById(deletedFunctionId)
          .then(() => {
            clearSelectedFunction();
            deleteFunctionByIdFromStore(deletedFunctionId);
            navigate(`${baseURL}/functions`, { replace: true });
            addToast({
              variant: "success",
              message: "Function has been successfully deleted",
            });
          })
          .catch((error) => {
            console.error(error);
            addToast({
              variant: "error",
              message:
                "Failed to delete function. Please refresh the page and try again",
            });
          });
      }
    },
    deleteAssetVersion: (versionId: string, versionNumber: number) => {
      const assetId = selectedFunction.id;

      if (assetId) {
        void deleteFunctionVersion(versionId)
          .then(() => {
            const assetData = functionsData[assetId];
            const versionsList = Object.values(assetData.versions);

            if (versionsList.length <= 1) {
              clearSelectedFunction();
              navigate(`${baseURL}/functions`, { replace: true });
              const newFunctionsData = { ...functionsData };
              delete newFunctionsData[assetId];
              setFunctionsData(newFunctionsData);
              addToast({
                variant: "success",
                message: "Version and Asset have been successfully deleted.",
              });

              return;
            }

            deleteFunctionVersionStore(versionNumber);

            if (selectedFunction.versionNumber === versionNumber) {
              const currentVersionIndex = versionsList.findIndex(
                (version) => version.version === versionNumber
              );
              const nextSelectedVersionIndex =
                currentVersionIndex === versionsList.length - 1
                  ? currentVersionIndex - 1
                  : currentVersionIndex + 1;
              const nextSelectedVersionData =
                versionsList[nextSelectedVersionIndex];

              if (nextSelectedVersionData) {
                const { id, version } = nextSelectedVersionData;
                setSelectedFunctionVersionNumber(version);
                navigate(`${baseURL}/functions/${id}`, { replace: true });
              } else {
                clearSelectedFunction();
                navigate(`${baseURL}/functions`, { replace: true });
              }
            }

            addToast({
              variant: "success",
              message: `Version ${versionNumber} has been successfully deleted.`,
            });
          })
          .catch((error) => {
            console.error(error);
            addToast({
              variant: "error",
              message: "Failed to delete version",
            });
          });
      }
    },
    setAssetVersionStatus: (id, status) => {
      void putFunctionVersionStatus(id, status).then((respFunc) => {
        updateFunctionVersion(respFunc);
      });
    },
    setAssetVersionIsArchived: async (versionId, isArchived) => {
      try {
        const data = await putFunctionVersionArchive(versionId, isArchived);
        const {
          asset: { id: assetId, archive: assetIsArchived },
          archive: versionIsArchived,
          version,
        } = data;

        const currentAssetData = functionsData[assetId];
        const {
          function: { archive },
        } = currentAssetData;

        if (assetIsArchived !== archive) {
          const updatedAssetData = {
            function: {
              ...currentAssetData.function,
              archive: assetIsArchived,
            },
            versions: {
              ...currentAssetData.versions,
              [version]: data,
            },
          };
          const newFunctionsData = {
            ...functionsData,
            [assetId]: updatedAssetData,
          };
          setFunctionsData(newFunctionsData);
        } else {
          /* We loose version owner info after updateFunctionVersion(data)
            because putFunctionVersionArchive response is a bit different from
            asset version data we get when fetch assets list (list item version has
            owner info as object, but in putFunctionVersionArchive response (data)
            owner has only owner id info). Will be automatically fixed when
            https://linear.app/zerve-ai/issue/ZER-1080/send-owner-as-dictionary-instead-of-string-on-put-asset-typeidarchive
            is done */
          updateFunctionVersion(data);
        }

        if (!isDisplayArchivedFunctions) {
          const selectedFunctionVersionId =
            selectedFunction.id && selectedFunction.versionNumber
              ? functionsData[selectedFunction.id]?.versions[
                  selectedFunction.versionNumber
                ]?.id
              : null;
          if (
            (versionId === selectedFunctionVersionId && versionIsArchived) ||
            (selectedFunction.id === assetId && assetIsArchived)
          ) {
            setIsDisplayArchivedFunctions(true);
          }
        }

        addToast({
          variant: "success",
          message: `Version successfully ${
            isArchived ? "archived" : "unarchived"
          }`,
        });
      } catch (error) {
        console.error(error);
        clearSelectedFunction();
        navigate("/assets/functions");
        addToast({
          variant: "error",
          message:
            "Something went wrong! Please refresh the page and try again",
        });
      }
    },
    setIsDisplayArchivedItems: () => {
      setIsDisplayArchivedFunctions(!isDisplayArchivedFunctions);
      const selectedFunctionData = selectedFunction.id
        ? functionsData[selectedFunction.id]
        : null;
      if (selectedFunctionData?.function.archive) {
        clearSelectedFunction();
      }
    },
    setAssetIsArchived: (assetId, isAssetArchived) => {
      const currentFunctionId = assetId ?? selectedFunction.id;
      const requestData = {
        archive: isAssetArchived,
        asset_id: currentFunctionId,
      };
      if (currentFunctionId) {
        void putFunctionAssetArchive(requestData)
          .then((data) => {
            const assetData = data[currentFunctionId];
            setFunctionsData({
              ...functionsData,
              [currentFunctionId]: { ...assetData },
            });
            const selectedFunctionVersionId =
              selectedFunction.id && selectedFunction.versionNumber
                ? functionsData[selectedFunction.id]?.versions[
                    selectedFunction.versionNumber
                  ]?.id
                : null;
            const {
              function: { archive },
              versions,
            } = assetData;

            if (
              selectedFunctionVersionId &&
              !Object.values(versions).find(
                (version) => version.id === selectedFunctionVersionId
              )
            ) {
              clearSelectedFunction();
              navigate(`${baseURL}/functions`);
            } else {
              if (
                !isDisplayArchivedFunctions &&
                archive &&
                selectedFunction.id === currentFunctionId
              ) {
                setIsDisplayArchivedFunctions(true);
              }
            }

            addToast({
              message: `Asset was successfully ${
                isAssetArchived ? "archived" : "unarchived"
              }.`,
              variant: "success",
            });
          })
          .catch((error) => {
            console.error(error);
            clearSelectedFunction();
            navigate(`${baseURL}/functions`);
            addToast({
              variant: "error",
              message:
                "Something went wrong! Please refresh the page and try again",
            });
          });
      }
    },
    setActiveItemByVersionId: (id?: string) => {
      if (!id || (selectedFunction.id && selectedFunction.versionNumber)) {
        return;
      }
      let selectedAssetVersionNumber: number | null = null;
      const selectedAsset = Object.values(functionsData).find((assetData) =>
        Object.values(assetData.versions).find((version) => {
          if (version.id === id) {
            selectedAssetVersionNumber = version.version;
            return true;
          }
          return false;
        })
      );

      if (!selectedAsset) {
        navigate(`${baseURL}/functions`);
      }

      const isAssetOrVersionArchived =
        selectedAsset?.function.archive ||
        (selectedAssetVersionNumber &&
          selectedAsset?.versions[selectedAssetVersionNumber]?.archive);

      if (isAssetOrVersionArchived) {
        setIsDisplayArchivedFunctions(true);
      }

      if (selectedAsset?.function.id && selectedAssetVersionNumber) {
        setSelectedFunctionId(selectedAsset.function.id);
        setSelectedFunctionVersionNumber(selectedAssetVersionNumber);
      }
    },
    clearActiveItemData: () => {
      clearSelectedFunction();
    },
    clearStore: () => {
      clearFunctionsStore();
    },
  };

  return { currentFunctionsData, currentFunctionsActions };
}
