import { useCallback, useMemo } from "react";
import { useAssetsState, useToastsState } from "store";
import {
  getAssetsByUser,
  getAssetsByWorkspaceId,
  getAssetsByCanvasId,
} from "api/http/assets-service";
import { getConnectionTypes } from "api/http/assets/connections-service";
import { DATA_LOAD_STATUS } from "config/appConfig";

import type {
  ConnectionTypeData,
  Connection,
} from "pages/Assets/types/connectionTypes";

const transformConnectionTypesData = (data: ConnectionTypeData[] = []) => {
  return data.reduce((res, item) => {
    res[item.id] = { ...item };

    return res;
  }, {});
};

const transformConnectionsData = (data: Connection[] = []) => {
  return data.reduce((res, item) => {
    res[item.id] = {
      connection: { ...item },
    };

    return res;
  }, {});
};

export const useConnectionsDataLoadActions = () => {
  const {
    getLoadInfo,
    setConnectionTypes,
    setData,
    setLoadInfo,
    setCanvasConnectionsData,
  } = useAssetsState((slice) => slice.connectionsActions);
  const addToast = useToastsState((slice) => slice.addToast);

  const getConnectionTypesData = useCallback(async (forceLoad = true) => {
    if (!forceLoad) {
      const { connectionTypesLoadStatus } = getLoadInfo();
      if (connectionTypesLoadStatus !== DATA_LOAD_STATUS.NOT_LOADED) {
        return;
      }
    }
    setLoadInfo({ connectionTypesLoadStatus: DATA_LOAD_STATUS.LOADING });
    try {
      const data = await getConnectionTypes();
      setConnectionTypes(transformConnectionTypesData(data));
      setLoadInfo({
        connectionTypesLoadStatus: DATA_LOAD_STATUS.LOADED,
      });
    } catch (error: any) {
      setLoadInfo({ connectionTypesLoadStatus: DATA_LOAD_STATUS.ERROR });
      addToast({
        message: error?.message ?? "Failed to load connection types",
        variant: "error",
      });
    }
  }, []);

  const getConnections = useCallback(
    async ({
      workspaceId = null,
      forceLoad = true,
    }: {
      workspaceId?: string | null;
      forceLoad?: boolean;
    }) => {
      if (!forceLoad) {
        const { dataLoadStatus } = getLoadInfo();
        if (dataLoadStatus !== DATA_LOAD_STATUS.NOT_LOADED) {
          return;
        }
      }
      setLoadInfo({ dataLoadStatus: DATA_LOAD_STATUS.LOADING });
      try {
        const data = workspaceId
          ? await getAssetsByWorkspaceId(workspaceId, "connection")
          : await getAssetsByUser("connection");
        setData(transformConnectionsData(data));
        setLoadInfo({
          dataLoadStatus: DATA_LOAD_STATUS.LOADED,
        });
      } catch (error: any) {
        setLoadInfo({ dataLoadStatus: DATA_LOAD_STATUS.ERROR });
        addToast({
          message: error?.message ?? "Failed to load connections assets",
          variant: "error",
        });
      }
    },
    []
  );

  const loadInitialConnectionsData = useCallback(
    async ({
      workspaceId = null,
      forceLoadConnectionTypes = true,
      forceLoadConnectionsData = true,
    }: {
      workspaceId?: string | null;
      forceLoadConnectionTypes?: boolean;
      forceLoadConnectionsData?: boolean;
    }) => {
      await getConnectionTypesData(forceLoadConnectionTypes);
      await getConnections({
        workspaceId,
        forceLoad: forceLoadConnectionsData,
      });
    },
    [getConnectionTypesData, getConnections]
  );

  const getConnectionsByCanvasId = useCallback(
    async ({
      canvasId,
      forceLoad = true,
    }: {
      canvasId: string;
      forceLoad?: boolean;
    }) => {
      if (!canvasId) {
        return;
      }
      if (!forceLoad) {
        const { canvasConnectionsLoadStatus } = getLoadInfo();
        if (canvasConnectionsLoadStatus !== DATA_LOAD_STATUS.NOT_LOADED) {
          return;
        }
      }
      try {
        setLoadInfo({ canvasConnectionsLoadStatus: DATA_LOAD_STATUS.LOADING });
        const data = await getAssetsByCanvasId(canvasId, "connection");
        setCanvasConnectionsData(transformConnectionsData(data));
        setLoadInfo({
          canvasConnectionsLoadStatus: DATA_LOAD_STATUS.LOADED,
        });
      } catch (error) {
        setLoadInfo({ canvasConnectionsLoadStatus: DATA_LOAD_STATUS.ERROR });
        addToast({
          message: "Failed to load connections assets related to canvas",
          variant: "error",
        });
      }
    },
    []
  );

  return useMemo(() => {
    return {
      getConnections,
      getConnectionTypesData,
      loadInitialConnectionsData,
      getConnectionsByCanvasId,
    };
  }, [
    getConnections,
    getConnectionTypesData,
    loadInitialConnectionsData,
    getConnectionsByCanvasId,
  ]);
};
