import { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAssetsState, useToastsState } from "store";
import {
  type AssetItemOperationType,
  AssetPageTabUrl,
} from "pages/Assets/types/abstractTypes";
import {
  postQuery,
  postWorkspaceQuery,
  postQueryVersion,
  putQuery,
} from "api/http/assets/queries-service";
import { getAssetsBaseUrl } from "pages/Assets/utils";

export default function useCreatedQueriesData() {
  const navigate = useNavigate();
  const addToast = useToastsState((slice) => slice.addToast);
  const { orgID, workspaceID } = useParams();
  const { queriesData, createdQueryData, selectedQuery } = useAssetsState(
    (slice) => slice.queries
  );

  const createQuery = useCallback(
    (requestData) => {
      if (workspaceID) {
        return postWorkspaceQuery(workspaceID, requestData);
      } else {
        return postQuery(requestData);
      }
    },
    [workspaceID]
  );

  const baseURL = useMemo(() => {
    return getAssetsBaseUrl({
      organizationId: orgID,
      workspaceId: workspaceID,
    });
  }, [orgID, workspaceID]);

  const {
    clearCreatedQueryData,
    setCreatedQueryAssetOperationType,
    setCreatedQueryData,
    setCreatedQueryError,
    setCreatedQueryLoading,
    setQueriesData,
    setSelectedQueryId,
    setSelectedQueryVersionNumber,
    updateQueryVersion,
  } = useAssetsState((slice) => slice.queriesActions);

  const createdQueriesData = {
    createdAssetItemData: createdQueryData.queryData,
    assetOperationType: createdQueryData.assetOperationType,
    createdAssetError: createdQueryData.error,
    createdAssetLoading: createdQueryData.loading,
  };
  const createdQueriesActions = {
    setAssetItemOperationType: (operationType: AssetItemOperationType) => {
      setCreatedQueryAssetOperationType(operationType);
    },
    setCreatedAssetItemData: (data) => {
      setCreatedQueryData(data);
    },
    createAssetItem: () => {
      setCreatedQueryLoading(true);
      void createQuery(createdQueryData.queryData.name)
        .then((createdQuery) => {
          setQueriesData({
            ...queriesData,
            [createdQuery.asset_id]: {
              query: createdQuery.asset,
              versions: {
                [createdQuery.version]: createdQuery,
              },
            },
          });
          setSelectedQueryId(createdQuery.asset_id);
          setSelectedQueryVersionNumber(createdQuery.version);
          navigate(
            `${baseURL}/${AssetPageTabUrl.query}/${createdQuery.id as string}`
          );
          clearCreatedQueryData();
          addToast({
            message: "Success! New query has been successfully created.",
            variant: "success",
          });
        })
        .catch((error) => {
          setCreatedQueryError(error.message);
          setCreatedQueryLoading(false);
        });
    },
    editAssetItem: () => {},
    addNewVersion: (versionData) => {
      const latestVersionNumber =
        (selectedQuery.id &&
          Object.keys(queriesData[selectedQuery.id].versions).sort(
            (a, b) => Number(b) - Number(a)
          )[0]) ??
        1;

      const versionToCopyData =
        versionData ??
        (selectedQuery.id &&
          queriesData[selectedQuery.id]?.versions?.[latestVersionNumber]);

      if (selectedQuery.id) {
        postQueryVersion({
          asset_id: selectedQuery.id,
          code: versionToCopyData?.code ?? "",
        }).then((createdVersion) => {
          updateQueryVersion(createdVersion);
          setSelectedQueryVersionNumber(createdVersion.version);
          navigate(
            `${baseURL}/${AssetPageTabUrl.query}/${createdVersion.id as string}`
          );
        });
      }
    },
    setCreatedAssetError: () => {},
    updateAssetItemName: () => {
      const { name, id } = createdQueryData.queryData;

      if (id && queriesData[id]?.query?.name !== name) {
        setCreatedQueryLoading(true);
        putQuery(id, { name })
          .then((responseData) => {
            const editedQueryData = queriesData[id];
            setQueriesData({
              ...queriesData,
              [id]: {
                ...editedQueryData,
                query: {
                  ...editedQueryData.query,
                  name: responseData.name,
                },
              },
            });
            addToast({
              message: "Success! Query name has been successfully updated.",
              variant: "success",
            });
          })
          .catch((error) => {
            addToast({
              message: error?.message ?? "Failed to update query name",
              variant: "error",
            });
          })
          .finally(() => {
            clearCreatedQueryData();
            setCreatedQueryLoading(false);
          });
      } else {
        clearCreatedQueryData();
      }
    },
    clearCreatedAsset: () => {
      clearCreatedQueryData();
    },
  };

  return {
    createdQueriesData,
    createdQueriesActions,
  };
}
