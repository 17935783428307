import { useCallback, useMemo, useState } from "react";
import {
  postPublishedReport,
  putPublishedReport,
} from "api/http/canvas-report-service";
import { useToastsState } from "store";
import { debouncePromise } from "utils/helpers";
import type { PublishedReport } from "models/publishedReport";

const usePublishReportFormActions = () => {
  const addToast = useToastsState((slice) => slice.addToast);

  const [isSubmitActionInProgress, setIsSubmitActionInProgress] =
    useState(false);

  const submitCreatePublishedReport = useCallback(
    async ({
      blockId,
      data,
      onSuccess,
    }: {
      blockId: string;
      data: {
        name: PublishedReport["name"];
        workspace_id?: PublishedReport["workspace_id"];
      };
      onSuccess?: () => void;
    }) => {
      setIsSubmitActionInProgress(true);
      try {
        await debouncePromise({
          promise: postPublishedReport(blockId, data),
        });

        onSuccess?.();
        setIsSubmitActionInProgress(false);
        addToast({
          message: "Report published successfully",
          variant: "success",
        });
      } catch (err: any) {
        setIsSubmitActionInProgress(false);
        const cause =
          typeof err?.cause?.detail === "string" ? err.cause.detail : "";
        const errorMessage = cause
          ? `Failed to publish report: "${cause}".`
          : "Failed to publish report.";
        addToast({
          message: errorMessage,
          variant: "error",
        });
      }
    },
    []
  );

  const submitUpdatePublishedReportJob = useCallback(
    async ({
      reportId,
      blockId,
      data,
      onSuccess,
    }: {
      reportId: PublishedReport["id"];
      blockId: PublishedReport["block_id"];
      data: Partial<{
        name: PublishedReport["name"];
      }>;
      onSuccess?: () => void;
    }) => {
      setIsSubmitActionInProgress(true);

      try {
        await debouncePromise({
          promise: putPublishedReport(reportId, blockId, data),
        });
        onSuccess?.();
        setIsSubmitActionInProgress(false);
        addToast({
          message: "Report successfully updated",
          variant: "success",
        });
      } catch (err: any) {
        setIsSubmitActionInProgress(false);
        const cause =
          typeof err?.cause?.detail === "string" ? err.cause.detail : "";
        const errorMessage = cause
          ? `Failed to publish report: "${cause}".`
          : "Failed to publish report.";
        addToast({
          message: errorMessage,
          variant: "error",
        });
      }
    },
    []
  );

  return useMemo(
    () => ({
      isSubmitActionInProgress,
      submitCreatePublishedReport,
      submitUpdatePublishedReportJob,
    }),
    [
      isSubmitActionInProgress,
      submitCreatePublishedReport,
      submitUpdatePublishedReportJob,
    ]
  );
};

export { usePublishReportFormActions };
