import { type StateCreator } from "store";
import {
  LOCAL_STORAGE_KEY,
  DISPLAY_MODE,
  SORTING_COLUMN,
  CANVASES_REQUIREMENTS_SECTION,
  CANVASES_GLOBAL_IMPORTS_SECTION,
  LOCAL_PREFERENCE_KEY,
} from "config/localPreferences";
import { SORTING_DIRECTION } from "config/appConfig";
import {
  COMPUTE_ENV_TYPE,
  LANGUAGE,
  LOG_START_TIME,
} from "config/canvasConfig";
import { CANVAS_SOURCE_CONTROL_SECTION } from "components/menus/SourceControlMenu/config";

export const CANVASES_SORTING_COLUMN_DEFAULT = SORTING_COLUMN.createdAt;
export const CANVASES_SORTING_DIRECTION_DEFAULT = SORTING_DIRECTION.DESCENDING;

type State = {
  [LOCAL_PREFERENCE_KEY.canvasesDisplayMode]: DISPLAY_MODE;
  [LOCAL_PREFERENCE_KEY.canvasesSortingColumn]: SORTING_COLUMN;
  [LOCAL_PREFERENCE_KEY.canvasesSortingDirection]: SORTING_DIRECTION;
  [LOCAL_PREFERENCE_KEY.canvasShowAddBlockMenu]: boolean;
  [LOCAL_PREFERENCE_KEY.canvasShowMiniMap]: boolean;
  [LOCAL_PREFERENCE_KEY.canvasDontAskBeforeBlockOrLayerDeletion]: boolean;
  [LOCAL_PREFERENCE_KEY.canvasDontAskBeforeMovingFiles]: boolean;
  [LOCAL_PREFERENCE_KEY.canvasRequirementsSections]: CANVASES_REQUIREMENTS_SECTION[];
  [LOCAL_PREFERENCE_KEY.canvasGlobalImportsSections]: CANVASES_GLOBAL_IMPORTS_SECTION[];
  [LOCAL_PREFERENCE_KEY.canvasExecutorLogsComputeType]: COMPUTE_ENV_TYPE;
  [LOCAL_PREFERENCE_KEY.canvasExecutorLogsBlockLanguage]: LANGUAGE;
  [LOCAL_PREFERENCE_KEY.canvasExecutorLogsStartTime]: number;
  [LOCAL_PREFERENCE_KEY.canvasSourceControlSections]: CANVAS_SOURCE_CONTROL_SECTION[];
};

type Actions = {
  setCanvasesDisplayMode: (mode: DISPLAY_MODE) => void;
  setCanvasesSortingColumn: (column: SORTING_COLUMN) => void;
  setCanvasesSortingDirection: (direction: SORTING_DIRECTION) => void;
  setCanvasShowAddBlockMenu: (show: boolean) => void;
  setCanvasShowMiniMap: (show: boolean) => void;
  setCanvasDontAskBeforeBlockOrLayerDeletion: (dontAsk: boolean) => void;
  setCanvasDontAskBeforeMovingFiles: (dontAsk: boolean) => void;
  setCanvasRequirementsSections: (
    sections: CANVASES_REQUIREMENTS_SECTION[]
  ) => void;
  setCanvasGlobalImportsSections: (
    sections: CANVASES_GLOBAL_IMPORTS_SECTION[]
  ) => void;
  setCanvasExecutorLogsComputeType: (computeType: COMPUTE_ENV_TYPE) => void;
  setCanvasExecutorLogsBlockLanguage: (language: LANGUAGE) => void;
  setCanvasExecutorLogsStartTime: (time: number) => void;
  setCanvasSourceControlSections: (
    sections: CANVAS_SOURCE_CONTROL_SECTION[]
  ) => void;
};

const initialState: State = getInitialState();

export type LocalCanvasPreferencesSlice = State & Actions;

export const createLocalCanvasPreferencesSlice: StateCreator<
  LocalCanvasPreferencesSlice
> = (set) => ({
  ...initialState,
  setCanvasesDisplayMode: (mode) => {
    set(
      (store) => {
        store.localCanvasPreferencesSlice.canvasesDisplayMode = mode;
        setPreference(LOCAL_PREFERENCE_KEY.canvasesDisplayMode, mode);
      },
      false,
      "localPreferences/setCanvasesDisplayMode"
    );
  },
  setCanvasesSortingColumn: (column) => {
    set(
      (store) => {
        store.localCanvasPreferencesSlice.canvasesSortingColumn = column;
        setPreference(LOCAL_PREFERENCE_KEY.canvasesSortingColumn, column);
      },
      false,
      "localPreferences/setCanvasesSortingColumn"
    );
  },
  setCanvasesSortingDirection: (direction) => {
    set(
      (store) => {
        store.localCanvasPreferencesSlice.canvasesSortingDirection = direction;
        setPreference(LOCAL_PREFERENCE_KEY.canvasesSortingDirection, direction);
      },
      false,
      "localPreferences/setCanvasesSortingDirection"
    );
  },
  setCanvasShowAddBlockMenu: (show) => {
    set(
      (store) => {
        store.localCanvasPreferencesSlice.canvasShowAddBlockMenu = show;
        setPreference(LOCAL_PREFERENCE_KEY.canvasShowAddBlockMenu, show);
      },
      false,
      "localPreferences/setCanvasShowAddBlockMenu"
    );
  },
  setCanvasShowMiniMap: (show) => {
    set(
      (store) => {
        store.localCanvasPreferencesSlice.canvasShowMiniMap = show;
        setPreference(LOCAL_PREFERENCE_KEY.canvasShowMiniMap, show);
      },
      false,
      "localPreferences/setCanvasShowMiniMap"
    );
  },
  setCanvasDontAskBeforeBlockOrLayerDeletion: (dontAsk) => {
    set(
      (store) => {
        store.localCanvasPreferencesSlice.canvasDontAskBeforeBlockOrLayerDeletion =
          dontAsk;
        setPreference(
          LOCAL_PREFERENCE_KEY.canvasDontAskBeforeBlockOrLayerDeletion,
          dontAsk
        );
      },
      false,
      "localPreferences/setCanvasDontAskBeforeBlockOrLayerDeletion"
    );
  },
  setCanvasDontAskBeforeMovingFiles: (dontAsk) => {
    set(
      (store) => {
        store.localCanvasPreferencesSlice.canvasDontAskBeforeMovingFiles =
          dontAsk;
        setPreference(
          LOCAL_PREFERENCE_KEY.canvasDontAskBeforeMovingFiles,
          dontAsk
        );
      },
      false,
      "localPreferences/setCanvasDontAskBeforeMovingFiles"
    );
  },
  setCanvasRequirementsSections: (sections) => {
    set(
      (store) => {
        store.localCanvasPreferencesSlice.canvasRequirementsSections = sections;
        setPreference(
          LOCAL_PREFERENCE_KEY.canvasRequirementsSections,
          sections
        );
      },
      false,
      "localPreferences/setCanvasRequirementsSections"
    );
  },
  setCanvasGlobalImportsSections: (sections) => {
    set(
      (store) => {
        store.localCanvasPreferencesSlice.canvasGlobalImportsSections =
          sections;
        setPreference(
          LOCAL_PREFERENCE_KEY.canvasGlobalImportsSections,
          sections
        );
      },
      false,
      "localPreferences/setCanvasGlobalImportsSections"
    );
  },
  setCanvasExecutorLogsComputeType: (computeType) => {
    set(
      (store) => {
        store.localCanvasPreferencesSlice.canvasExecutorLogsComputeType =
          computeType;
        setPreference(
          LOCAL_PREFERENCE_KEY.canvasExecutorLogsComputeType,
          computeType
        );
      },
      false,
      "localPreferences/setCanvasExecutorLogsComputeType"
    );
  },
  setCanvasExecutorLogsBlockLanguage: (language) => {
    set(
      (store) => {
        store.localCanvasPreferencesSlice.canvasExecutorLogsBlockLanguage =
          language;
        setPreference(
          LOCAL_PREFERENCE_KEY.canvasExecutorLogsBlockLanguage,
          language
        );
      },
      false,
      "localPreferences/setCanvasExecutorLogsBlockLanguage"
    );
  },
  setCanvasExecutorLogsStartTime: (time) => {
    set(
      (store) => {
        store.localCanvasPreferencesSlice.canvasExecutorLogsStartTime = time;
        setPreference(LOCAL_PREFERENCE_KEY.canvasExecutorLogsStartTime, time);
      },
      false,
      "localPreferences/setCanvasExecutorLogsStartTime"
    );
  },
  setCanvasSourceControlSections: (sections) => {
    set(
      (store) => {
        store.localCanvasPreferencesSlice.canvasSourceControlSections =
          sections;
        setPreference(
          LOCAL_PREFERENCE_KEY.canvasSourceControlSections,
          sections
        );
      },
      false,
      "localPreferences/setCanvasSourceControlSections"
    );
  },
});

/**
 * Restore preference from the local storage.
 * If the restored value is empty or invalid, use the default.
 */
function getInitialState() {
  // restore/validate canvases display mode
  let canvasesDisplayMode = getPreference(
    LOCAL_PREFERENCE_KEY.canvasesDisplayMode
  );
  if (!Object.values(DISPLAY_MODE).includes(canvasesDisplayMode)) {
    canvasesDisplayMode = DISPLAY_MODE.CARDS;
    setPreference(
      LOCAL_PREFERENCE_KEY.canvasesDisplayMode,
      canvasesDisplayMode
    );
  }

  // restore/validate canvases sorting column/direction
  let canvasesSortingColumn = getPreference(
    LOCAL_PREFERENCE_KEY.canvasesSortingColumn
  );
  let canvasesSortingDirection = getPreference(
    LOCAL_PREFERENCE_KEY.canvasesSortingDirection
  );
  if (
    !Object.values(SORTING_COLUMN).includes(canvasesSortingColumn) ||
    !Object.values(SORTING_DIRECTION).includes(canvasesSortingDirection)
  ) {
    canvasesSortingColumn = CANVASES_SORTING_COLUMN_DEFAULT;
    canvasesSortingDirection = CANVASES_SORTING_DIRECTION_DEFAULT;
    setPreference(
      LOCAL_PREFERENCE_KEY.canvasesSortingColumn,
      canvasesSortingColumn
    );
    setPreference(
      LOCAL_PREFERENCE_KEY.canvasesSortingDirection,
      canvasesSortingDirection
    );
  }

  // restore/validate canvas show the "Add Block" menu
  let canvasShowAddBlockMenu = getPreference(
    LOCAL_PREFERENCE_KEY.canvasShowAddBlockMenu
  );
  if (typeof canvasShowAddBlockMenu !== "boolean") {
    canvasShowAddBlockMenu = true;
    setPreference(
      LOCAL_PREFERENCE_KEY.canvasShowAddBlockMenu,
      canvasShowAddBlockMenu
    );
  }

  // restore/validate canvas show the mini-map
  let canvasShowMiniMap = getPreference(LOCAL_PREFERENCE_KEY.canvasShowMiniMap);
  if (typeof canvasShowMiniMap !== "boolean") {
    canvasShowMiniMap = true;
    setPreference(LOCAL_PREFERENCE_KEY.canvasShowMiniMap, canvasShowMiniMap);
  }

  // restore/validate canvas don't ask before deletion
  let canvasDontAskBeforeBlockOrLayerDeletion = getPreference(
    LOCAL_PREFERENCE_KEY.canvasDontAskBeforeBlockOrLayerDeletion
  );
  if (typeof canvasDontAskBeforeBlockOrLayerDeletion !== "boolean") {
    canvasDontAskBeforeBlockOrLayerDeletion = false;
    setPreference(
      LOCAL_PREFERENCE_KEY.canvasDontAskBeforeBlockOrLayerDeletion,
      canvasDontAskBeforeBlockOrLayerDeletion
    );
  }

  // restore/validate canvas don't ask before moving files
  let canvasDontAskBeforeMovingFiles = getPreference(
    LOCAL_PREFERENCE_KEY.canvasDontAskBeforeMovingFiles
  );
  if (typeof canvasDontAskBeforeMovingFiles !== "boolean") {
    canvasDontAskBeforeMovingFiles = false;
    setPreference(
      LOCAL_PREFERENCE_KEY.canvasDontAskBeforeMovingFiles,
      canvasDontAskBeforeMovingFiles
    );
  }

  // restore/validate canvas active requirements menu sections
  let canvasRequirementsSections = getPreference(
    LOCAL_PREFERENCE_KEY.canvasRequirementsSections
  );
  if (
    !canvasRequirementsSections?.every((item) =>
      Object.values(CANVASES_REQUIREMENTS_SECTION).includes(item)
    )
  ) {
    canvasRequirementsSections = [
      CANVASES_REQUIREMENTS_SECTION.REQUIREMENTS,
      CANVASES_REQUIREMENTS_SECTION.LINUX_PACKAGES,
      CANVASES_REQUIREMENTS_SECTION.ENVIRONMENT_VARIABLES,
    ];
    setPreference(
      LOCAL_PREFERENCE_KEY.canvasRequirementsSections,
      canvasRequirementsSections
    );
  }

  // restore/validate canvas active requirements menu sections
  let canvasGlobalImportsSections = getPreference(
    LOCAL_PREFERENCE_KEY.canvasGlobalImportsSections
  );
  if (
    !canvasGlobalImportsSections?.every((item) =>
      Object.values(CANVASES_GLOBAL_IMPORTS_SECTION).includes(item)
    )
  ) {
    canvasGlobalImportsSections = [
      CANVASES_GLOBAL_IMPORTS_SECTION.PYTHON,
      CANVASES_GLOBAL_IMPORTS_SECTION.R,
    ];
    setPreference(
      LOCAL_PREFERENCE_KEY.canvasGlobalImportsSections,
      canvasGlobalImportsSections
    );
  }

  let canvasExecutorLogsComputeType = getPreference(
    LOCAL_PREFERENCE_KEY.canvasExecutorLogsComputeType
  );
  if (
    !Object.values(COMPUTE_ENV_TYPE).includes(canvasExecutorLogsComputeType) ||
    canvasExecutorLogsComputeType === COMPUTE_ENV_TYPE.GPU
  ) {
    canvasExecutorLogsComputeType = COMPUTE_ENV_TYPE.SPRINT;
    setPreference(
      LOCAL_PREFERENCE_KEY.canvasExecutorLogsComputeType,
      canvasExecutorLogsComputeType
    );
  }

  let canvasExecutorLogsBlockLanguage = getPreference(
    LOCAL_PREFERENCE_KEY.canvasExecutorLogsBlockLanguage
  );
  if (!Object.values(LANGUAGE).includes(canvasExecutorLogsBlockLanguage)) {
    canvasExecutorLogsBlockLanguage = LANGUAGE.PYTHON;
    setPreference(
      LOCAL_PREFERENCE_KEY.canvasExecutorLogsBlockLanguage,
      canvasExecutorLogsBlockLanguage
    );
  }

  let canvasExecutorLogsStartTime = getPreference(
    LOCAL_PREFERENCE_KEY.canvasExecutorLogsStartTime
  );
  if (!Object.values(LOG_START_TIME).includes(canvasExecutorLogsStartTime)) {
    canvasExecutorLogsStartTime = LOG_START_TIME.FIFTEEN;
    setPreference(
      LOCAL_PREFERENCE_KEY.canvasExecutorLogsStartTime,
      canvasExecutorLogsStartTime
    );
  }

  // restore/validate canvas active source control menu sections
  let canvasSourceControlSections = getPreference(
    LOCAL_PREFERENCE_KEY.canvasSourceControlSections
  );
  if (
    !canvasSourceControlSections?.every((item) =>
      Object.values(CANVAS_SOURCE_CONTROL_SECTION).includes(item)
    )
  ) {
    canvasSourceControlSections = [
      CANVAS_SOURCE_CONTROL_SECTION.COMMIT,
      CANVAS_SOURCE_CONTROL_SECTION.HISTORY,
      CANVAS_SOURCE_CONTROL_SECTION.INCLUDE,
    ];
    setPreference(
      LOCAL_PREFERENCE_KEY.canvasSourceControlSections,
      canvasSourceControlSections
    );
  }

  return {
    canvasesDisplayMode,
    canvasesSortingColumn,
    canvasesSortingDirection,
    canvasShowAddBlockMenu,
    canvasShowMiniMap,
    canvasDontAskBeforeBlockOrLayerDeletion,
    canvasDontAskBeforeMovingFiles,
    canvasRequirementsSections,
    canvasGlobalImportsSections,
    canvasExecutorLogsComputeType,
    canvasExecutorLogsBlockLanguage,
    canvasExecutorLogsStartTime,
    canvasSourceControlSections,
  };
}

/**
 * Get the object with all preferences from the local storage.
 */
function getAllPreferences() {
  return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || "{}");
}

/**
 * Get a preference value from the local storage.
 */
function getPreference(key: LOCAL_PREFERENCE_KEY) {
  const allPreferences = getAllPreferences();
  return allPreferences[key] ?? null;
}

/**
 * Set a preference value into the local storage.
 */
function setPreference(
  key: LOCAL_PREFERENCE_KEY.canvasesDisplayMode,
  value: DISPLAY_MODE
): void;
function setPreference(
  key: LOCAL_PREFERENCE_KEY.canvasesSortingColumn,
  value: SORTING_COLUMN
): void;
function setPreference(
  key: LOCAL_PREFERENCE_KEY.canvasesSortingDirection,
  value: SORTING_DIRECTION
): void;
function setPreference(
  key: LOCAL_PREFERENCE_KEY.canvasShowAddBlockMenu,
  value: boolean
): void;
function setPreference(
  key: LOCAL_PREFERENCE_KEY.canvasShowMiniMap,
  value: boolean
): void;
function setPreference(
  key: LOCAL_PREFERENCE_KEY.canvasDontAskBeforeBlockOrLayerDeletion,
  value: boolean
): void;
function setPreference(
  key: LOCAL_PREFERENCE_KEY.canvasDontAskBeforeMovingFiles,
  value: boolean
): void;
function setPreference(
  key: LOCAL_PREFERENCE_KEY.canvasRequirementsSections,
  value: CANVASES_REQUIREMENTS_SECTION[]
): void;
function setPreference(
  key: LOCAL_PREFERENCE_KEY.canvasGlobalImportsSections,
  value: CANVASES_GLOBAL_IMPORTS_SECTION[]
): void;
function setPreference(
  key: LOCAL_PREFERENCE_KEY.canvasExecutorLogsComputeType,
  value: COMPUTE_ENV_TYPE
): void;
function setPreference(
  key: LOCAL_PREFERENCE_KEY.canvasExecutorLogsBlockLanguage,
  value: LANGUAGE
): void;
function setPreference(
  key: LOCAL_PREFERENCE_KEY.canvasExecutorLogsStartTime,
  value: number
): void;
function setPreference(
  key: LOCAL_PREFERENCE_KEY.canvasSourceControlSections,
  value: CANVAS_SOURCE_CONTROL_SECTION[]
): void;
function setPreference(key: LOCAL_PREFERENCE_KEY, value: any): void {
  const allPreferences = getAllPreferences();
  allPreferences[key] = value;
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(allPreferences));
}
