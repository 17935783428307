import styles from "./AppsListLayout.module.scss";
import type { ChangeEvent, MouseEvent, ReactNode } from "react";
import cn from "classnames";
import { BiGridAlt, BiListUl, BiSearch } from "react-icons/bi";
import { DISPLAY_MODE, type SORTING_COLUMN } from "config/localPreferences";
import { type SORTING_DIRECTION } from "config/appConfig";
import { EmptyState } from "components/common/EmptyState/EmptyState";
import {
  SortingDropdown,
  type SortOptions,
} from "components/common/SortingDropdown/SortingDropdown";
import type { AppCardType } from "models/app";
import type { ContextMenuItem } from "components/common/ContextMenu/ContextMenu";
import { AppsGrid } from "../AppsGrid/AppsGrid";
import { AppsTable } from "../AppsTable/AppsTable";

type AppsListLayoutProps = {
  apps: AppCardType[];
  selectedAppsIds: Record<string, string>;
  processedAppsIdActionMap: Record<string, string>;
  notFound: boolean;
  noApps: boolean;
  noAppsPlaceholder: ReactNode;
  displayMode: DISPLAY_MODE;
  searchQuery: string;
  sortingColumn: SORTING_COLUMN;
  sortingDirection: SORTING_DIRECTION;
  sortOptions: SortOptions;
  toolbar: ReactNode;
  topBarClassName?: string;
  showSharingInfo?: boolean;
  onGetAppOptions: (canvas: AppCardType) => ContextMenuItem[];
  onSearchQueryChange: (e?: ChangeEvent<HTMLInputElement>) => void;
  onChangeSorting: ({
    column,
    direction,
  }: {
    column: SORTING_COLUMN;
    direction: SORTING_DIRECTION;
  }) => void;
  onAppClick: (id: string, event: MouseEvent<HTMLElement>) => void;
  setDisplayMode: (mode: DISPLAY_MODE) => void;
};

export function AppsListLayout({
  apps,
  selectedAppsIds,
  processedAppsIdActionMap,
  notFound,
  noApps,
  noAppsPlaceholder,
  displayMode,
  searchQuery,
  sortingColumn,
  sortingDirection,
  sortOptions,
  toolbar,
  topBarClassName,
  showSharingInfo = false,
  onGetAppOptions,
  onSearchQueryChange,
  onChangeSorting,
  onAppClick,
  setDisplayMode,
}: AppsListLayoutProps) {
  return (
    <>
      <div className={cn(styles.top_bar, topBarClassName)}>
        <div className={styles.search}>
          <BiSearch size={24} className={styles.searchIcon} />
          <input
            placeholder="Search..."
            value={searchQuery}
            onChange={onSearchQueryChange}
          />
        </div>

        <div className={styles.actions}>
          {/* Sorting */}
          <div className={styles.sort}>
            <span className={styles.sortLabel}>Sort by:</span>
            <SortingDropdown
              column={sortingColumn}
              direction={sortingDirection}
              sortOptions={sortOptions}
              onChange={onChangeSorting}
            />
          </div>

          {/* Cards mode */}
          <BiGridAlt
            size={16}
            className={cn(styles.displayModeBtn, {
              [styles.activeDisplayMode]: displayMode === DISPLAY_MODE.CARDS,
            })}
            onClick={() => {
              setDisplayMode(DISPLAY_MODE.CARDS);
            }}
          />

          {/* Table mode */}
          <BiListUl
            size={16}
            className={cn(styles.displayModeBtn, {
              [styles.activeDisplayMode]: displayMode === DISPLAY_MODE.TABLE,
            })}
            onClick={() => {
              setDisplayMode(DISPLAY_MODE.TABLE);
            }}
          />
        </div>
      </div>
      <div className={styles.content}>
        {/* placeholder when no apps */}
        {noApps && noAppsPlaceholder}

        {/* placeholder when not found any canvas  */}
        {notFound && !noApps && (
          <EmptyState
            variant="info"
            size="large"
            containerClassName={styles.placeholder}
            title="No Apps found..."
            description="Your search does not match the name of any of your apps"
          />
        )}

        {!notFound && !noApps && (
          <>
            {displayMode === DISPLAY_MODE.CARDS && (
              <AppsGrid
                items={apps}
                onGetAppOptions={onGetAppOptions}
                processedItemsIds={processedAppsIdActionMap}
                selectedItemsIds={selectedAppsIds}
                onItemClick={onAppClick}
              />
            )}
            {displayMode === DISPLAY_MODE.TABLE && (
              <AppsTable
                items={apps}
                onGetAppOptions={onGetAppOptions}
                processedItemsIds={processedAppsIdActionMap}
                selectedItemsIds={selectedAppsIds}
                sortingColumn={sortingColumn}
                sortingDirection={sortingDirection}
                onChangeSorting={onChangeSorting}
                showSharingInfo={showSharingInfo}
                onRowClick={onAppClick}
              />
            )}
          </>
        )}
      </div>

      {toolbar}
    </>
  );
}
