import styles from "./LinuxPackages.module.scss";
import { useState, useCallback, useMemo } from "react";
import { useCanvasRequirementsState } from "store";
import { SideBarSearch } from "components/SideBarSearch/SideBarSearch";
import Loader from "components/common/Loader/Loader";
import { Button } from "components/common/Button/Button";
import { EmptyState } from "components/common/EmptyState/EmptyState";
import PanelRowButton from "components/common/PanelRowButton/PanelRowButton";
import { EditableTable } from "components/EditableTable";
import { useRequirements } from "components/menus/RequirmentsMenu/useRequirements";
import { DATA_LOAD_STATUS } from "config/appConfig";
import type { LinuxPackage } from "models/requirements";

enum COLUMN_KEY {
  NAME = "name",
}

const COLUMNS = [
  {
    label: "Name",
    key: COLUMN_KEY.NAME,
    searchable: true,
    sortable: true,
    placeholder: "required",
  },
];

export function LinuxPackages() {
  const {
    requirementsStatus,
    executorLoadingStatus,
    linuxPackagesLoadingStatus,
    linuxPackages,
    setLinuxPackages,
  } = useCanvasRequirementsState((slice) => ({
    requirementsStatus: slice.requirementsStatus,
    executorLoadingStatus: slice.executorLoadingStatus,
    linuxPackagesLoadingStatus: slice.linuxPackagesLoadingStatus,
    linuxPackages: slice.linuxPackages,
    setLinuxPackages: slice.setLinuxPackages,
  }));

  const { checkIsBuildDisabled, fetchLinuxPackages, updateLinuxPackages } =
    useRequirements();

  const [searchText, setSearchText] = useState("");

  const isBuildDisabled = useMemo(
    () =>
      checkIsBuildDisabled({
        requirementsStatus,
        executorLoadingStatus,
      }),
    [requirementsStatus, executorLoadingStatus, checkIsBuildDisabled]
  );

  const handleLinuxPackagesChange = useCallback(
    (newLinuxPackages: LinuxPackage[]) => {
      setLinuxPackages(newLinuxPackages);
      updateLinuxPackages({
        linuxPackages: newLinuxPackages,
      });
    },
    [updateLinuxPackages]
  );

  // not loaded / loading
  if (
    linuxPackagesLoadingStatus === DATA_LOAD_STATUS.NOT_LOADED ||
    linuxPackagesLoadingStatus === DATA_LOAD_STATUS.LOADING
  ) {
    return <Loader withOverlay>Loading...</Loader>;
  }

  // error
  if (linuxPackagesLoadingStatus === DATA_LOAD_STATUS.ERROR) {
    return (
      <div className={styles.fillWithScroll}>
        <div className={styles.errorMessageContainer}>
          <EmptyState
            variant="error"
            title="Something went wrong"
            description="Failed to load Linux packages."
          >
            <Button variant="text" onClick={fetchLinuxPackages}>
              Try again
            </Button>
          </EmptyState>
        </div>
      </div>
    );
  }

  // loaded
  return (
    <>
      <SideBarSearch
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
      />

      <EditableTable
        rows={linuxPackages || []}
        columns={COLUMNS}
        searchText={searchText}
        readOnly={isBuildDisabled}
        addRowControl={(onRowAdd) => {
          return (
            <PanelRowButton
              label="Add package"
              disabled={isBuildDisabled}
              onClick={(e) => {
                e.stopPropagation();
                onRowAdd({ name: "" });
              }}
            />
          );
        }}
        onChange={handleLinuxPackagesChange}
      />
    </>
  );
}
