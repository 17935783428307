import { type PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import { Auth0Provider, type AppState } from "@auth0/auth0-react";
import {
  AUTH0_DOMAIN,
  AUTH0_CLIENT_ID,
  AUTH0_AUDIENCE,
} from "config/appConfig";

export function Auth0({ children }: PropsWithChildren) {
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState) => {
    // navigate to the page the user was on before being redirected to the login page
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      useRefreshTokens={true}
      cacheLocation="localstorage"
      authorizationParams={{
        redirect_uri: window.location.origin,
        prompt: "select_account",
        scope: "profile email offline_access",
        audience: AUTH0_AUDIENCE,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
}
