import { callAPI } from "./call-api";
import type {
  CanvasBranchItemType,
  CanvasBranchFullInfo,
  CommitsHistoryItemType,
} from "models/github-integration";
import { USER_API_URL, CANVAS_API_URL } from "config/appConfig";
import { type Resource } from "models/user";

const getAppInstallUrlUser = async (canvasId?: string | null) => {
  let url = `${USER_API_URL}/github/install_url`;
  if (canvasId) {
    url = url + `?canvas_id=${canvasId}`;
  }

  return await callAPI({
    method: "GET",
    url,
  });
};

const getAppInstallUrlOrganization = async (
  organizationId: string,
  canvasId?: string | null
) => {
  let url = `${USER_API_URL}/github/install_url?organization_id=${organizationId}`;
  if (canvasId) {
    url = url + `&canvas_id=${canvasId}`;
  }

  return await callAPI({
    method: "GET",
    url,
  });
};

const commitCanvas = async (canvasId: string, data: { message: string }) =>
  await callAPI({
    method: "POST",
    url: `${CANVAS_API_URL}/canvas/github/commit_canvas/${canvasId}`,
    data,
  });

const restoreCanvas = async (canvasId: string, sha?: string) => {
  let url = `${CANVAS_API_URL}/canvas/github/restore_canvas/${canvasId}`;
  if (sha) {
    url = url + `?sha=${sha}`;
  }
  return await callAPI({
    method: "GET",
    url,
  });
};

const getCommitHistory = async (
  resourceId: string,
  branchName: string | null
) => {
  let url = `${USER_API_URL}/github/${resourceId}/commits`;
  if (branchName) {
    url = url + `?branch=${branchName}`;
  }

  return await callAPI<CommitsHistoryItemType[]>({
    method: "GET",
    url,
  });
};

const getCanvasCommitsDiff = async (
  canvasId: string,
  base_sha: string,
  compare_sha: string = "current"
) =>
  await callAPI<string>({
    method: "GET",
    url: `${CANVAS_API_URL}/canvas/github/compare/${canvasId}?base_sha=${base_sha}&compare_sha=${compare_sha}`,
  });

const updateResourceOrigin = async (
  resourceId: string,
  data: Partial<{
    github_repository: Resource["github_repository"];
    github_repository_main_branch: Resource["github_repository_main_branch"];
  }>
) =>
  await callAPI<Resource>({
    method: "PATCH",
    url: `${USER_API_URL}/resources/${resourceId}`,
    data,
  });

const getUserRepositories = async () =>
  await callAPI({
    method: "GET",
    url: `${USER_API_URL}/github/repos`,
  });

const getOrganizationRepositories = async (organizationId: string) =>
  await callAPI({
    method: "GET",
    url: `${USER_API_URL}/github/organization/repos/${organizationId}`,
  });

const getResourceRepositoryBranches = async ({
  resourceId,
  excludeCanvasBranches,
  repositoryName,
}: {
  resourceId: string;
  excludeCanvasBranches?: boolean;
  repositoryName?: string;
}) => {
  let url = `${USER_API_URL}/github/resource/${resourceId}/branches`;

  const queryParts: string[] = [];

  if (repositoryName) {
    queryParts.push(`repo_name=${repositoryName}`);
  }

  if (excludeCanvasBranches) {
    queryParts.push(`exclude_canvas_branches=${excludeCanvasBranches}`);
  }

  if (queryParts.length) {
    url = url + "?" + queryParts.join("&");
  }

  return await callAPI<string[]>({
    method: "GET",
    url,
  });
};
// Note: The canvas_id here is the canvas id of the main branch canvas (branched_from_canvas_id in canvasBranchInfo),
// the canvas_branch_id is the id of the canvas_branch.
const getCanvasBranchFullInfo = async (
  canvasId: string,
  canvasBranchId: string
) =>
  await callAPI<CanvasBranchFullInfo>({
    method: "GET",
    url: `${CANVAS_API_URL}/canvas/${canvasId}/branch/${canvasBranchId}`,
  });

const getCanvasBranches = async (canvasId: string) =>
  await callAPI<CanvasBranchItemType[]>({
    method: "GET",
    url: `${CANVAS_API_URL}/canvas/${canvasId}/branches`,
  });

const postCanvasBranch = async (
  canvasId: string,
  data: { branch_name: string }
) =>
  await callAPI<CanvasBranchItemType>({
    method: "POST",
    url: `${CANVAS_API_URL}/canvas/github/branch/${canvasId}`,
    data,
  });

// Note: The canvas_id here is the canvas id of the main branch canvas (branched_from_canvas_id in canvasBranchInfo),
// the canvas_branch_id is the id of the canvas_branch.
const deleteCanvasBranch = async (canvasId: string, canvasBranchId: string) =>
  await callAPI<boolean>({
    method: "DELETE",
    url: `${CANVAS_API_URL}/canvas/${canvasId}/branch/${canvasBranchId}`,
  });

const pullBranch = async (canvasId: string, branchName: string | null) => {
  let url = `${CANVAS_API_URL}/canvas/github/pull/${canvasId}`;
  if (branchName) {
    url = url + `?branch=${branchName}`;
  }

  return await callAPI({
    method: "GET",
    url,
  });
};

const githubService = {
  getAppInstallUrlUser,
  getAppInstallUrlOrganization,
  getUserRepositories,
  getOrganizationRepositories,
  commitCanvas,
  restoreCanvas,
  getCommitHistory,
  getCanvasCommitsDiff,
  getCanvasBranches,
  getCanvasBranchFullInfo,
  getResourceRepositoryBranches,
  deleteCanvasBranch,
  postCanvasBranch,
  pullBranch,
  updateResourceOrigin,
};

export default githubService;
