import styles from "./ProgressBar.module.scss";
import { memo } from "react";
import cn from "classnames";

type ProgressBarProps = {
  value: number;
  total?: number;
  containerClassName?: string;
};

export const ProgressBar = memo(function ProgressBar({
  value = 0,
  total = 100,
  containerClassName,
}: ProgressBarProps) {
  const progress = (value / total) * 100;

  return (
    <div className={cn(styles.container, containerClassName)}>
      <div className={styles.progress} style={{ width: `${progress}%` }} />
    </div>
  );
});
