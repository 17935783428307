import { useState, useEffect } from "react";
import { BLOCK_STATUS } from "config/canvasConfig";
import Typography from "components/Typography/Typography";
import styles from "./BlockExecutionTime.module.scss";
import { formatBlockExecutionTime } from "utils/helpers";
import Tooltip from "components/common/Tooltip/Tooltip";
import cn from "classnames";

const BLOCK_STATUS_TO_CLASS_EXTENSION_MAP = Object.freeze({
  [BLOCK_STATUS.COMPLETED]: "completed",
  [BLOCK_STATUS.ERROR]: "error",
  [BLOCK_STATUS.RUNNING]: "running",
});

type BlockExecutionTimeProps = {
  status: BLOCK_STATUS;
  lastRunExecutionTime?: number;
  currentRunExecutionTimeStart?: string | null;
};

export default function BlockExecutionTime({
  status,
  lastRunExecutionTime,
  currentRunExecutionTimeStart,
}: BlockExecutionTimeProps) {
  const classExtension = BLOCK_STATUS_TO_CLASS_EXTENSION_MAP[status];
  const [elapsedTime, setElapsedTime] = useState(0);
  const toolTipText =
    status === BLOCK_STATUS.RUNNING
      ? "Elapsed execution duration"
      : "Last execution duration";

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (status === BLOCK_STATUS.RUNNING) {
      const startTime = currentRunExecutionTimeStart
        ? new Date(currentRunExecutionTimeStart).getTime()
        : new Date().getTime();
      const updateElapsedTime = () => {
        const currentTime = new Date().getTime();
        setElapsedTime(Math.floor((currentTime - startTime) / 1000));
      };

      updateElapsedTime();
      intervalId = setInterval(updateElapsedTime, 1000);
    }

    return () => clearInterval(intervalId);
  }, [status, currentRunExecutionTimeStart]);

  const displayTime =
    status === BLOCK_STATUS.RUNNING
      ? elapsedTime
      : lastRunExecutionTime
        ? Math.ceil(lastRunExecutionTime)
        : 0;

  return (
    <Tooltip text={toolTipText} placement="top" withArrow>
      <div
        className={cn(styles.container, styles[`container__${classExtension}`])}
      >
        <Typography component="div" variant="caption2" className={styles.text}>
          {formatBlockExecutionTime(displayTime)}
        </Typography>
      </div>
    </Tooltip>
  );
}
