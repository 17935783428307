import { useState, useEffect, type ReactNode } from "react";
import moment, { type MomentInput } from "moment";
import { type Placement } from "@floating-ui/react";
import Tooltip from "components/common/Tooltip/Tooltip";
import { humanizeDate } from "utils/helpers";

type TimeAgoProps = {
  date: MomentInput;
  className?: string;
  tooltipPlacement?: Placement;
  tooltipMainAxisOffset?: number;
  label?: (fromNow: string) => ReactNode;
};

const DEFAULT_LABEL_FN = (fromNow: string) => fromNow;

export function TimeAgo({
  date,
  className,
  tooltipPlacement = "top",
  tooltipMainAxisOffset,
  label = DEFAULT_LABEL_FN,
}: TimeAgoProps) {
  // state to change when it's time to re-render the time
  const [, setCount] = useState(0);

  // re-render time every minute
  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prev) => prev + 1);
    }, 60 * 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const fromNow = moment(date).fromNow();

  return (
    <Tooltip
      withArrow
      placement={tooltipPlacement}
      hoverProps={{ delay: { open: 200 } }}
      className={className}
      text={humanizeDate({ date })}
      mainAxisOffset={tooltipMainAxisOffset}
    >
      {label(fromNow)}
    </Tooltip>
  );
}
