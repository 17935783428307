import styles from "./OrganizationHome.module.scss";
import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { getWorkspaces } from "api/http/workspace-service";
import { getOrganization } from "api/http/organization-service";
import { useModal } from "hooks/useModal";
import { Button } from "components/common/Button/Button";
import { CreateWorkspaceModal } from "components/modals/CreateWorkspaceModal/CreateWorkspaceModal";
import { TitleSection } from "components/organization/TitleSection/TitleSection";
import { OrgEmptyState } from "components/organization/OrgEmptyState/OrgEmptyState";
import { WorkspaceCard } from "components/organization/WorkspaceCard/WorkspaceCard";
import { WorkspaceCardSkeleton } from "components/organization/WorkspaceCardSkeleton/WorkspaceCardSkeleton";
import { debouncePromise } from "utils/helpers";
import type { Organization, Workspace } from "models/organization";

export function OrganizationHome() {
  const { openModal } = useModal();

  const [isLoading, setIsLoading] = useState(true);
  const [organization, setOrganization] = useState<Organization>();
  const [workspaces, setWorkspaces] = useState<Workspace[]>([]);
  const { orgID = "" } = useParams();

  const isAdmin = organization?.role === "Organization Admin";

  // fetch organization and workspaces
  useEffect(() => {
    setIsLoading(true);
    debouncePromise({ promise: getOrganization(orgID) }).then((orgResult) => {
      setOrganization(orgResult);
      getWorkspaces(orgID)
        .then((returnedValues) => {
          setWorkspaces(returnedValues);
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  }, [orgID]);

  const handleCreateWorkspace = useCallback(() => {
    openModal({
      title: "Create Workspace",
      content: ({ onModalClose }) => (
        <CreateWorkspaceModal
          organizationId={orgID}
          workspaces={workspaces}
          onModalClose={onModalClose}
        />
      ),
    });
  }, [workspaces, openModal]);

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <TitleSection
          title="Workspaces"
          rightControls={
            isAdmin ? (
              <Button
                size="large"
                variant="primary"
                onClick={handleCreateWorkspace}
              >
                Create Workspace
              </Button>
            ) : null
          }
        />

        {/* Loading */}
        {isLoading ? (
          <>
            <WorkspaceCardSkeleton />
            <WorkspaceCardSkeleton />
            <WorkspaceCardSkeleton />
          </>
        ) : null}

        {/* Empty State */}
        {!isLoading && workspaces.length === 0 ? (
          <OrgEmptyState
            title="No workspaces..."
            description={
              isAdmin
                ? "Create a new workspace to start collaborating with your team."
                : "The organization doesn't have any workspaces yet."
            }
          >
            {isAdmin ? (
              <Button
                size="large"
                variant="secondary"
                onClick={handleCreateWorkspace}
              >
                Create Workspace
              </Button>
            ) : null}
          </OrgEmptyState>
        ) : null}

        {/* Workspace Cards */}
        {workspaces.map((workspace) => {
          return (
            <WorkspaceCard
              key={workspace.id}
              orgID={orgID}
              workspace={workspace}
            />
          );
        })}
      </div>
    </div>
  );
}
