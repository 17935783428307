import cn from "classnames";
import { BiCheck } from "react-icons/bi";

import { Button } from "components/common/Button/Button";
import Typography from "components/Typography";
import { type PaymentPlan as PaymentPlanType } from "models/settings/billing-interface";
import styles from "./PaymentPlan.module.scss";

interface PaymentPlanProps {
  plan: PaymentPlanType;
}

const actionMap = Object.freeze({
  active: {
    buttonLabel: "Your Current Plan",
    buttonVariant: "secondary" as const,
  },
  downgrade: { buttonLabel: "Downgrade", buttonVariant: "secondary" as const },
  upgrade: { buttonLabel: "Upgrade", buttonVariant: "primary" as const },
});

const PaymentPlan = ({ plan }: PaymentPlanProps) => {
  const { prices, name, description, disabledMessage, features } = plan;
  const currPriceObj = prices[0];
  const discount = currPriceObj.discount ?? 0;
  const priceId =
    currPriceObj.status === "upgrade" ? currPriceObj.stripe_id : null;

  const periodMap = {
    year: {
      periodLabel: "annually",
      payment: Math.round(currPriceObj.total_payment / 100 / 12),
    },
    month: {
      periodLabel: "monthly",
      payment: Math.round(currPriceObj.total_payment / 100),
    },
    null: { periodLabel: "monthly", payment: 0 },
  };

  const definePrice = (priceObj) => {
    if (!priceObj) {
      return;
    }
    const { period } = priceObj;

    return (
      <>
        {!!discount && (
          <s>
            {Math.round((periodMap[period].payment / (100 - discount)) * 100)}
          </s>
        )}
        <h2>{periodMap[period].payment}</h2>
        <h3>$</h3>
        <Typography
          component="span"
          variant="body2"
          className={styles["plan__period"]}
        >
          &nbsp;/ month, billed {periodMap[period]?.periodLabel}
        </Typography>
      </>
    );
  };

  const FeatureItem = ({ featureText }: { featureText: string }) => {
    return (
      <li className={styles["plan__feature"]}>
        <BiCheck size={20} />
        <Typography component="span" variant="body2">
          {featureText}
        </Typography>
      </li>
    );
  };

  return (
    <div
      className={cn(styles["plan"], {
        [styles["plan__disabled"]]: !!disabledMessage,
      })}
    >
      <Typography component="h2" variant="h2" className={styles["plan__title"]}>
        {name}
      </Typography>

      <Typography
        component="p"
        variant="body2"
        className={styles["plan__subtitle"]}
      >
        {description}
      </Typography>

      <div className={styles["plan__price"]}>
        {disabledMessage ? (
          <h3 style={{ fontWeight: 500 }}>CUSTOM</h3>
        ) : (
          <>{definePrice(currPriceObj)}</>
        )}
      </div>

      <ul className={styles["plan__features"]}>
        {Object.values(features).map((item) => (
          <FeatureItem key={item} featureText={item} />
        ))}
      </ul>

      <Button
        className={styles["plan__action"]}
        variant={actionMap[currPriceObj.status ?? "downgrade"].buttonVariant}
        size="large"
        disabled={currPriceObj.status === "active"}
        onClick={() => {
          currPriceObj.action?.(priceId);
        }}
      >
        {disabledMessage || actionMap[prices[0].status]?.buttonLabel}
      </Button>

      {disabledMessage ? (
        <div className={styles["plan__message"]}>
          <div className={styles["plan__tag"]}>
            {disabledMessage.toUpperCase()}
          </div>
          <div className={styles["plan__backdrop"]} />
        </div>
      ) : null}
    </div>
  );
};

export default PaymentPlan;
