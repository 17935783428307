import {
  createContext,
  useMemo,
  useCallback,
  useState,
  type ReactNode,
} from "react";
import { AnimatePresence } from "framer-motion";
import { Modal, type ModalProps } from "components/common/Modal/Modal";

type ModalType = {
  id: string;
  content: ReactNode;
  title: ModalProps["title"];
  classes: ModalProps["classes"];
  closeOnLocationChange: ModalProps["closeOnLocationChange"];
  onClose: ModalProps["onClose"];
};
type OpenModal = (modal: ModalType) => void;
type CloseModal = ({ id }: { id: string }) => void;

export const ModalContext = createContext<{
  openModal: OpenModal;
  closeModal: CloseModal;
} | null>(null);

export function ModalProvider({ children }: { children: ReactNode }) {
  const [modals, setModals] = useState(new Map<ModalType["id"], ModalType>());

  const openModal = useCallback<OpenModal>((modal) => {
    setModals((modals) => {
      const newModals = new Map(modals);
      newModals.set(modal.id, modal);
      return newModals;
    });
  }, []);

  const closeModal = useCallback<CloseModal>(({ id }) => {
    setModals((modals) => {
      const newModals = new Map(modals);
      newModals.delete(id);
      return newModals;
    });
  }, []);

  const value = useMemo(() => {
    return { openModal, closeModal };
  }, [openModal, closeModal]);

  return (
    <ModalContext.Provider value={value}>
      {children}
      <>
        {[...modals.entries()].map(([id, modal]) => {
          return (
            <AnimatePresence key={id}>
              <Modal
                id={id}
                title={modal.title}
                classes={modal.classes}
                closeOnLocationChange={modal.closeOnLocationChange}
                onClose={modal.onClose}
              >
                {modal.content}
              </Modal>
            </AnimatePresence>
          );
        })}
      </>
    </ModalContext.Provider>
  );
}
