import { CANVAS_API_URL } from "config/appConfig";
import { callAPI } from "./call-api";
import type {
  ScheduledJob,
  ScheduledJobRunsHistoryData,
} from "models/scheduledJobs";

const getScheduledJobByLayerId = async (layerId: string) =>
  await callAPI<ScheduledJob>({
    url: `${CANVAS_API_URL}/scheduled_job/by_layer/${layerId}`,
    method: "GET",
  });

const getScheduledJobRuns = async ({
  jobId,
  offset = 0,
  limit = 50,
}: {
  jobId: ScheduledJob["id"];
  offset?: number;
  limit?: number;
}) => {
  return await callAPI<ScheduledJobRunsHistoryData>({
    url: `${CANVAS_API_URL}/scheduled_job/${jobId}/runs?offset=${offset}&limit=${limit}`,
    method: "GET",
  });
};

const postScheduledJob = async (
  data: {
    name: ScheduledJob["name"];
    layer_id: ScheduledJob["layer_id"];
    crontab: ScheduledJob["crontab"];
  },
  connectionId: string
) =>
  await callAPI<ScheduledJob>({
    url: `${CANVAS_API_URL}/scheduled_job?connection_id=${connectionId}`,
    method: "POST",
    data,
  });

const patchScheduledJob = async (
  jobId: ScheduledJob["id"],
  data: Partial<{
    scheduled_job_status: ScheduledJob["scheduled_job_status"];
    crontab: ScheduledJob["crontab"];
  }>,
  connectionId: string
) =>
  await callAPI<ScheduledJob>({
    url: `${CANVAS_API_URL}/scheduled_job/${jobId}?connection_id=${connectionId}`,
    method: "PATCH",
    data,
  });

export {
  getScheduledJobByLayerId,
  getScheduledJobRuns,
  patchScheduledJob,
  postScheduledJob,
};
