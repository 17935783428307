import styles from "./DeploymentDisabilityReasonsModal.module.scss";
import { type PropsWithModal } from "hooks/useModal";
import { Button } from "components/common/Button/Button";
import ToasterMessage from "components/common/ToasterMessage/ToasterMessage";
import { type getDeploymentDisabilityReasons } from "components/SageMaker/helpers";
import { SAGEMAKER_DEPLOYMENT_DISABILITY } from "config/deploymentConfig";

type DeploymentDisabilityReasonsModalProps = PropsWithModal<{
  reasons: ReturnType<typeof getDeploymentDisabilityReasons>;
}>;

export function DeploymentDisabilityReasonsModal({
  reasons,
  onModalClose,
}: DeploymentDisabilityReasonsModalProps) {
  return (
    <>
      <ToasterMessage
        fullWidth
        variant="warning"
        animate={false}
        className={styles.alertMessage}
      >
        Before deploying the SageMaker Endpoint, the following issues must be
        fixed:
      </ToasterMessage>

      <ul className={styles.reasonsList}>
        {reasons.map(({ disability, blockId }, index) => {
          if (!blockId) {
            return null;
          }
          switch (disability) {
            case SAGEMAKER_DEPLOYMENT_DISABILITY.NO_CODE_CONNECTED: {
              return (
                <li key={index}>
                  SageMaker Endpoint block must be connected to a code block
                </li>
              );
            }
            default: {
              return null;
            }
          }
        })}
      </ul>

      <div className={styles.controls}>
        <Button size="large" variant="secondary" onClick={onModalClose}>
          Close
        </Button>
      </div>
    </>
  );
}
