import { type StateCreator } from "store";
import type {
  CommitsHistoryItemType,
  CanvasBranchItemTypeBase,
  CanvasBranchItemType,
  CanvasBranchFullInfo,
} from "models/github-integration";
import { type Resource } from "models/user";
import { DATA_LOAD_STATUS } from "config/appConfig";

interface ConfigurationData {
  gitHubAppInstallationId?: string | null;
}

interface LoadInfo {
  configuration: DATA_LOAD_STATUS;
  historyData: DATA_LOAD_STATUS;
  repositories: DATA_LOAD_STATUS;
  repositoryBranches: DATA_LOAD_STATUS;
  resourceData: DATA_LOAD_STATUS;
  canvasBranches: DATA_LOAD_STATUS;
  canvasBranchFullInfo: DATA_LOAD_STATUS;
}

type State = {
  actionsDisabled: boolean;
  configurationData: ConfigurationData;
  repositories: string[];
  repositoryBranches: string[];
  resourceData: Resource | null;
  canvasBranch: CanvasBranchItemTypeBase | null;
  canvasBranchFullInfo: CanvasBranchFullInfo | null;
  canvasBranches: CanvasBranchItemType[];
  historyData: CommitsHistoryItemType[];
  loadInfo: LoadInfo;
  restoringCanvasCommitHash: CommitsHistoryItemType["sha"] | null;
  showDiffViewer: boolean;
  diffBaseCommitHash: CommitsHistoryItemType["sha"] | null;
  diffCompareCommitHash: CommitsHistoryItemType["sha"] | null;
  updateCanvasBranchInProgress: boolean;
  pullBranchInProgress: boolean;
  deleteInProgressCanvasBranchIds: Set<CanvasBranchItemType["id"]>;
  deleteRepositoryInProgress: boolean;
  editResourceOriginInProgress: boolean;
  updateResourceInProgress: boolean;
  includedRepositories: string[];
};

type Actions = {
  setActionsDisabled: (value: State["actionsDisabled"]) => void;
  setConfigurationData: (data: Partial<ConfigurationData>) => void;
  setRepositories: (data: State["repositories"]) => void;
  setRepositoryBranches: (data: string[]) => void;
  setCanvasBranch: (data: State["canvasBranch"]) => void;
  setCanvasBranchFullInfo: (data: State["canvasBranchFullInfo"]) => void;
  setCanvasBranches: (data: State["canvasBranches"]) => void;
  setResourceData: (data: State["resourceData"]) => void;
  setHistoryData: (data: State["historyData"]) => void;
  setLoadInfo: (loadInfo: Partial<LoadInfo>) => void;
  setRestoringCanvasCommitHash: (
    hash: State["restoringCanvasCommitHash"]
  ) => void;
  setShowDiffViewer: (show: State["showDiffViewer"]) => void;
  setDiffBaseCommitHash: (hash: State["diffBaseCommitHash"]) => void;
  setDiffCompareCommitHash: (hash: State["diffCompareCommitHash"]) => void;
  setPullBranchInProgress: (inProgress: State["pullBranchInProgress"]) => void;
  setUpdateCanvasBranchInProgress: (
    inProgress: State["updateCanvasBranchInProgress"]
  ) => void;
  setDeleteRepositoryInProgress: (
    inProgress: State["deleteRepositoryInProgress"]
  ) => void;
  setEditResourceOriginInProgress: (
    inProgress: State["editResourceOriginInProgress"]
  ) => void;
  setUpdateResourceInProgress: (
    inProgress: State["updateResourceInProgress"]
  ) => void;
  updateResourceData: (
    data: Partial<NonNullable<State["resourceData"]>>
  ) => void;
  addDeleteInProgressCanvasBranchIds: (
    ids: CanvasBranchItemType["id"][]
  ) => void;
  removeDeleteInProgressCanvasBranchIds: (
    ids: CanvasBranchItemType["id"][]
  ) => void;
  removeCanvasBranches: (branchIds: CanvasBranchItemType["id"][]) => void;
  setIncludedRepositories: (data: State["includedRepositories"]) => void;
  clearStore: () => void;
};

const getInitialState = (): State => ({
  actionsDisabled: false,
  configurationData: {
    gitHubAppInstallationId: null,
  },
  repositories: [],
  repositoryBranches: [],
  resourceData: null,
  canvasBranch: null,
  canvasBranches: [],
  canvasBranchFullInfo: null,
  historyData: [],
  loadInfo: {
    configuration: DATA_LOAD_STATUS.NOT_LOADED,
    historyData: DATA_LOAD_STATUS.NOT_LOADED,
    repositories: DATA_LOAD_STATUS.NOT_LOADED,
    repositoryBranches: DATA_LOAD_STATUS.NOT_LOADED,
    resourceData: DATA_LOAD_STATUS.NOT_LOADED,
    canvasBranches: DATA_LOAD_STATUS.NOT_LOADED,
    canvasBranchFullInfo: DATA_LOAD_STATUS.NOT_LOADED,
  },
  restoringCanvasCommitHash: null,
  showDiffViewer: false,
  diffBaseCommitHash: null,
  diffCompareCommitHash: null,
  updateCanvasBranchInProgress: false,
  pullBranchInProgress: false,
  deleteInProgressCanvasBranchIds: new Set(),
  deleteRepositoryInProgress: false,
  editResourceOriginInProgress: false,
  updateResourceInProgress: false,
  includedRepositories: [],
});

export type CanvasGitHubSlice = State & Actions;

export const createCanvasGitHubSlice: StateCreator<CanvasGitHubSlice> = (
  set
) => ({
  ...getInitialState(),
  setActionsDisabled: (value) => {
    set(
      (store) => {
        store.canvasGitHubSlice.actionsDisabled = value;
      },
      false,
      "canvasGitHub/setActionsDisabled"
    );
  },
  setConfigurationData: (data) => {
    set(
      (store) => {
        store.canvasGitHubSlice.configurationData = {
          ...store.canvasGitHubSlice.configurationData,
          ...data,
        };
      },
      false,
      "canvasGitHub/setConfigurationData"
    );
  },
  setRepositories: (data) => {
    set(
      (store) => {
        store.canvasGitHubSlice.repositories = data;
      },
      false,
      "canvasGitHub/setRepositories"
    );
  },
  setRepositoryBranches: (data) => {
    set(
      (store) => {
        store.canvasGitHubSlice.repositoryBranches = data;
      },
      false,
      "canvasGitHub/setRepositoryBranches"
    );
  },
  setCanvasBranch: (data) => {
    set(
      (store) => {
        store.canvasGitHubSlice.canvasBranch = data;
      },
      false,
      "canvasGitHub/setCanvasBranch"
    );
  },
  setCanvasBranchFullInfo: (data) => {
    set(
      (store) => {
        store.canvasGitHubSlice.canvasBranchFullInfo = data;
      },
      false,
      "canvasGitHub/setCanvasBranchFullInfo"
    );
  },
  setCanvasBranches: (data) => {
    set(
      (store) => {
        store.canvasGitHubSlice.canvasBranches = data;
      },
      false,
      "canvasGitHub/setCanvasBranches"
    );
  },
  removeCanvasBranches: (branchIds) => {
    set(
      (store) => {
        store.canvasGitHubSlice.canvasBranches =
          store.canvasGitHubSlice.canvasBranches.filter(
            (item) => !branchIds.includes(item.id)
          );
      },
      false,
      "canvasGitHub/removeCanvasBranches"
    );
  },
  setResourceData: (data) => {
    set(
      (store) => {
        store.canvasGitHubSlice.resourceData = data;
      },
      false,
      "canvasGitHub/setResourceData"
    );
  },
  updateResourceData: (data) => {
    set(
      (store) => {
        const currData = store.canvasGitHubSlice.resourceData;
        if (!currData) {
          return;
        }

        store.canvasGitHubSlice.resourceData = {
          ...currData,
          ...data,
        };
      },
      false,
      "canvasGitHub/updateResourceData"
    );
  },
  setHistoryData: (data) => {
    set(
      (store) => {
        store.canvasGitHubSlice.historyData = data;
      },
      false,
      "canvasGitHub/setHistoryData"
    );
  },
  setLoadInfo: (loadInfo) => {
    set(
      (store) => {
        store.canvasGitHubSlice.loadInfo = {
          ...store.canvasGitHubSlice.loadInfo,
          ...loadInfo,
        };
      },
      false,
      "canvasGitHub/setLoadInfo"
    );
  },
  setRestoringCanvasCommitHash: (hash) => {
    set(
      (store) => {
        store.canvasGitHubSlice.restoringCanvasCommitHash = hash;
      },
      false,
      "canvasGitHub/setRestoringCanvasCommitHash"
    );
  },
  setShowDiffViewer: (show) => {
    set(
      (store) => {
        store.canvasGitHubSlice.showDiffViewer = show;
      },
      false,
      "canvasGitHub/setShowDiffViewer"
    );
  },
  setDiffBaseCommitHash: (hash) => {
    set(
      (store) => {
        store.canvasGitHubSlice.diffBaseCommitHash = hash;
      },
      false,
      "canvasGitHub/setDiffBaseCommitHash"
    );
  },
  setDiffCompareCommitHash: (hash) => {
    set(
      (store) => {
        store.canvasGitHubSlice.diffCompareCommitHash = hash;
      },
      false,
      "canvasGitHub/setDiffCompareCommitHash"
    );
  },
  setPullBranchInProgress: (inProgress) => {
    set(
      (store) => {
        store.canvasGitHubSlice.pullBranchInProgress = inProgress;
      },
      false,
      "setPullBranchInProgress"
    );
  },
  setUpdateCanvasBranchInProgress: (inProgress) => {
    set(
      (store) => {
        store.canvasGitHubSlice.updateCanvasBranchInProgress = inProgress;
      },
      false,
      "setUpdateCanvasBranchInProgress"
    );
  },
  addDeleteInProgressCanvasBranchIds: (ids) => {
    set(
      (store) => {
        store.canvasGitHubSlice.deleteInProgressCanvasBranchIds = new Set([
          ...store.canvasGitHubSlice.deleteInProgressCanvasBranchIds,
          ...ids,
        ]);
      },
      false,
      "addDeleteInProgressCanvasBranchIds"
    );
  },
  removeDeleteInProgressCanvasBranchIds: (ids) => {
    set(
      (store) => {
        const updatedData = new Set(
          store.canvasGitHubSlice.deleteInProgressCanvasBranchIds
        );
        ids.forEach((id) => {
          updatedData.delete(id);
        });
        store.canvasGitHubSlice.deleteInProgressCanvasBranchIds = updatedData;
      },
      false,
      "removeDeleteInProgressCanvasBranchIds"
    );
  },
  setDeleteRepositoryInProgress: (inProgress) => {
    set(
      (store) => {
        store.canvasGitHubSlice.deleteRepositoryInProgress = inProgress;
      },
      false,
      "setDeleteRepositoryInProgress"
    );
  },
  setEditResourceOriginInProgress: (inProgress) => {
    set(
      (store) => {
        store.canvasGitHubSlice.editResourceOriginInProgress = inProgress;
      },
      false,
      "setEditResourceOriginInProgress"
    );
  },
  setUpdateResourceInProgress: (inProgress) => {
    set(
      (store) => {
        store.canvasGitHubSlice.updateResourceInProgress = inProgress;
      },
      false,
      "setUpdateResourceInProgress"
    );
  },
  setIncludedRepositories(data) {
    set(
      (store) => {
        store.canvasGitHubSlice.includedRepositories = data;
      },
      false,
      "canvasGitHub/setIncludedRepositories"
    );
  },
  clearStore: () => {
    set(
      (store) => {
        Object.assign(store.canvasGitHubSlice, getInitialState());
      },
      false,
      "canvasGitHub/clearStore"
    );
  },
});
