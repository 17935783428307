import type { Viewport } from "reactflow";
import { type StateCreator } from "store";
import { transformUser } from "utils/canvas-users";
import type {
  LayerType,
  BlockType,
  ConnectedUserApiResponse,
  ConnectedUser,
} from "models/canvas";

type State = {
  connectedUsers: Map<ConnectedUser["connection_id"], ConnectedUser>;
  userIdToFollow: ConnectedUser["id"];
};

type Actions = {
  setConnectedUsers: (users: ConnectedUserApiResponse[]) => void;
  addConnectedUser: (
    connection_id: string,
    user: {
      user_id: string;
      user_name: string;
      profile_image: string;
    }
  ) => void;
  removeConnectedUser: (payload: { connection_id: string }) => void;
  updateConnectedUser: (user: {
    connection_id: string;
    x?: number;
    y?: number;
    zoom?: number;
    view?: Viewport;
    layer_id?: LayerType["id"];
    active_block_id?: BlockType["id"];
  }) => void;
  getConnectedUser: (
    connectionId: ConnectedUser["connection_id"]
  ) => ConnectedUser | null;
  setUserIdToFollow: (id: ConnectedUser["id"]) => void;
  clearUserIdToFollow: () => void;
  clearStore: () => void;
};

const getInitialState = (): State => ({
  connectedUsers: new Map(),
  userIdToFollow: "",
});

export type CanvasUsersSlice = State & Actions;

export const createCanvasUsersSlice: StateCreator<CanvasUsersSlice> = (
  set,
  get
) => ({
  ...getInitialState(),

  setConnectedUsers: (users) => {
    set(
      (store) => {
        store.canvasUsersSlice.connectedUsers = users.reduce<
          Map<string, ConnectedUser>
        >((acc, user) => {
          const transformedUser = transformUser(user.connection_id, user);
          acc.set(transformedUser.connection_id, transformedUser);
          return acc;
        }, new Map());
      },
      false,
      "canvasUsers/setConnectedUsers"
    );
  },
  addConnectedUser: (connection_id, user) => {
    set(
      (store) => {
        const transformedUser = transformUser(connection_id, user);
        store.canvasUsersSlice.connectedUsers.set(
          transformedUser.connection_id,
          transformedUser
        );
      },
      false,
      "canvasUsers/addConnectedUser"
    );
  },
  removeConnectedUser: (payload) => {
    const connectionId = payload.connection_id;
    set(
      (store) => {
        store.canvasUsersSlice.connectedUsers.delete(connectionId);
      },
      false,
      "canvasUsers/removeConnectedUser"
    );
  },
  updateConnectedUser: (payload) => {
    const connectionId = payload.connection_id;
    if (!get().canvasUsersSlice.connectedUsers.has(connectionId)) {
      return;
    }
    set(
      (store) => {
        const user = store.canvasUsersSlice.connectedUsers.get(connectionId);
        if (!user) {
          return;
        }
        // keep in mind, user position and user zoom can be in separate updates
        if (payload.x !== undefined && payload.y !== undefined) {
          user.position = {
            zoom: 1,
            ...user.position,
            x: payload.x,
            y: payload.y,
          };
        }
        if (payload.zoom !== undefined) {
          user.position = {
            x: 0,
            y: 0,
            ...user.position,
            zoom: payload.zoom,
          };
        }
        user.view = payload.view || user.view;
        user.layer_id = payload.layer_id || user.layer_id;
        user.active_block_id = payload.active_block_id ?? user.active_block_id;
      },
      false,
      "canvasUsers/updateConnectedUser"
    );
  },
  getConnectedUser: (connectionId) => {
    return get().canvasUsersSlice.connectedUsers.get(connectionId) || null;
  },
  setUserIdToFollow: (id) => {
    set(
      (store) => {
        store.canvasUsersSlice.userIdToFollow = id;
      },
      false,
      "canvasUsers/setUserIdToFollow"
    );
  },
  clearUserIdToFollow: () => {
    set(
      (store) => {
        store.canvasUsersSlice.userIdToFollow = "";
      },
      false,
      "canvasUsers/clearUserIdToFollow"
    );
  },
  clearStore: () => {
    set(
      (store) => {
        Object.assign(store.canvasUsersSlice, getInitialState());
      },
      false,
      "canvasUsers/clearStore"
    );
  },
});
