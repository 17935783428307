import styles from "./Button.module.scss";
import { type PropsWithChildren } from "react";
import { Link, type LinkProps } from "react-router-dom";
import cn from "classnames";
import { type ButtonProps } from "components/common/Button/Button";

export type LinkButtonProps = PropsWithChildren<
  LinkProps & {
    variant?: ButtonProps["variant"];
    size?: ButtonProps["size"];
  }
>;

export function LinkButton({
  children,
  to,
  reloadDocument,
  replace,
  state,
  preventScrollReset,
  relative,
  className,
  variant = "primary",
  size = "small",
}: LinkButtonProps) {
  return (
    <Link
      to={to}
      reloadDocument={reloadDocument}
      replace={replace}
      state={state}
      preventScrollReset={preventScrollReset}
      relative={relative}
      className={cn(
        styles.button,
        {
          [styles.typePrimary]: variant === "primary",
          [styles.typeSecondary]: variant === "secondary",
          [styles.typeOutline]: variant === "outline",
          [styles.typeCrucial]: variant === "crucial",
          [styles.typeText]: variant === "text",
          [styles.typePlain]: variant === "plain",
          [styles.sizeSmall]: size === "small",
          [styles.sizeLarge]: size === "large",
        },
        className
      )}
    >
      {children}
    </Link>
  );
}
