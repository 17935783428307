import { USER_API_URL } from "config/appConfig";
import { callAPI } from "./call-api";
import type { Organization } from "models/organization";

export const getOrganization = async (organizationId: string) =>
  await callAPI<Organization>({
    method: "GET",
    url: `${USER_API_URL}/organizations/${organizationId}`,
  });

export const getOrganizations = async () =>
  await callAPI<Organization[]>({
    method: "GET",
    url: `${USER_API_URL}/organizations`,
  });

export const postOrganization = async (data: any) =>
  await callAPI({
    method: "POST",
    url: `${USER_API_URL}/organizations`,
    data,
  });

export const patchOrganization = async (organizationId: string, data: any) =>
  await callAPI<Organization>({
    method: "PATCH",
    url: `${USER_API_URL}/organizations/${organizationId}`,
    data,
  });

export const deleteOrganization = async (organizationId: string) =>
  await callAPI({
    method: "DELETE",
    url: `${USER_API_URL}/organizations/${organizationId}`,
  });

export const getOrganizationMembers = async (organizationId: string) =>
  await callAPI({
    method: "GET",
    url: `${USER_API_URL}/organizations/${organizationId}/members`,
  });

export const postOrganizationMember = async (
  organizationId: string,
  userEmail: string
) =>
  await callAPI({
    method: "POST",
    url: `${USER_API_URL}/organizations/${organizationId}/members`,
    data: { organization_id: organizationId, user_email: userEmail },
  });

export const updateOrganizationMember = async (
  organizationId: string,
  userId: string,
  roleId: string
) =>
  await callAPI({
    method: "PATCH",
    url: `${USER_API_URL}/organizations/${organizationId}/members`,
    data: { organization_id: organizationId, user_id: userId, role_id: roleId },
  });

export const deleteOrganizationMembers = async (
  organizationId: string,
  userIds: string[]
) =>
  await callAPI({
    method: "DELETE",
    url: `${USER_API_URL}/organizations/${organizationId}/members/${userIds.join(
      ","
    )}`,
  });

export const getOrganizationInvites = async (organizationId: string) =>
  await callAPI({
    method: "GET",
    url: `${USER_API_URL}/organizations/${organizationId}/invites`,
  });

export const deleteOrganizationInvite = async (
  organizationId: string,
  inviteId: string
) =>
  await callAPI({
    method: "DELETE",
    url: `${USER_API_URL}/organizations/${organizationId}/invites/${inviteId}`,
  });

// deploy self-hosting
export const postDefaultExecutors = async (organizationId: string) =>
  await callAPI({
    method: "POST",
    url: `${USER_API_URL}/organizations/${organizationId}/default_executors`,
  });

// get self-hosting status
export const getDefaultExecutors = async (organizationId: string) =>
  await callAPI({
    method: "GET",
    url: `${USER_API_URL}/organizations/${organizationId}/default_executors`,
  });

const organizationService = {
  getOrganization,
  patchOrganization,
  deleteOrganization,
  postOrganizationMember,
  updateOrganizationMember,
  getOrganizationMembers,
  deleteOrganizationMembers,
  getOrganizationInvites,
  deleteOrganizationInvite,
  postDefaultExecutors,
  getDefaultExecutors,
};

export default organizationService;
