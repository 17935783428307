import moment from "moment";
import { type Edge } from "reactflow";
import isEmpty from "lodash-es/isEmpty";
import isInteger from "lodash-es/isInteger";
import isEmail from "validator/es/lib/isEmail";
import { BLOCK_DETAILS, LAYER_TYPE, BLOCK_TYPE } from "config/canvasConfig";
import type { BlockType } from "models/canvas";

export const stringIsTooLongErrorMessage = (maxLength: number) =>
  `Please shorten your input to no more than ${maxLength} characters.`;

export const stringIsTooShortErrorMessage = (minLength: number) =>
  `Please lengthen your input to at least ${minLength} characters.`;

export const validateDateString = (
  dateString: string,
  dateFormat = "DD/MM/YYYY"
) => {
  return moment(dateString, dateFormat, true).isValid();
};

// OWASP Special Characters: https://owasp.org/www-community/password-special-characters
// " !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"
const specialCharactersRegexp = new RegExp(
  // prettier-ignore
  [' ', '!', '"', '#', '\\$', '%', '&', '\'', '\\(', '\\)', '\\*', '\\+', ',', '-', '\\.', '/', ':',
   ';', '<', '=', '>', '\\?', '@', '\\[', '\\\\', '\\]', '\\^', '_','`','{','\\|', '}','~'].join('|')
);

const stringHasSpecialCharacter = (value: string) => {
  return specialCharactersRegexp.test(value);
};

const stringHasUpperCaseLetter = (value: string) => {
  return /[A-Z]/.test(value);
};

const stringHasLowerCaseLetter = (value: string) => {
  return /[a-z]/.test(value);
};

const stringHasNumber = (value: string) => {
  return /\d/.test(value);
};

const stringSatisfiesMinRulesCount = (
  value: string,
  validationRules: ((value: string) => boolean)[],
  satisfyMinRulesCount: number
) => {
  if (!isInteger(satisfyMinRulesCount) || satisfyMinRulesCount < 1) {
    console.error(
      "satisfyMinRulesCount should be a valid integer, greater than 0"
    );

    return false;
  }
  if (isEmpty(validationRules)) {
    console.error("validationRules should be an non-empty array");

    return false;
  }
  if (validationRules.length < satisfyMinRulesCount) {
    console.error(
      "validationRules length should be greater than or equal to satisfyMinRulesCount"
    );

    return false;
  }

  let satisfiesRulesCount = 0;

  for (const validationRule of validationRules) {
    if (validationRule(value)) {
      satisfiesRulesCount += 1;
    }

    if (satisfiesRulesCount === satisfyMinRulesCount) {
      break;
    }
  }

  return satisfiesRulesCount >= satisfyMinRulesCount;
};

const stringHasSpace = (string: string) => {
  return string.includes(" ");
};

export const stringStartingWithNumber = (string: string) => {
  const regex = /^[0-9]/;
  return regex.test(string);
};

export const stringContainsOnlyNumbersLettersAndUnderscores = (
  string: string
) => {
  const regex = /^[a-zA-Z0-9_]*$/;
  return regex.test(string);
};

export const stringIsValidEmail = (email: string) => {
  return isEmail(email);
};

export const getStringValidationError = (
  fieldName: string,
  newData: string
) => {
  switch (fieldName) {
    case "firstName":
    case "lastName":
      if (newData.length === 0) {
        return stringIsTooShortErrorMessage(1);
      }
      return newData.length < 100 ? null : stringIsTooLongErrorMessage(100);
    case "username":
      if (newData.length < 5) {
        return stringIsTooShortErrorMessage(5);
      }
      return stringHasSpace(newData)
        ? "Your username must not contain spaces."
        : null;
    case "bio":
      return newData.length < 1000 ? null : stringIsTooLongErrorMessage(1000);
    case "birthday":
      if (newData.length === 0) {
        return stringIsTooShortErrorMessage(1);
      }
      return validateDateString(newData)
        ? null
        : "Please enter the date in the correct format: DD/MM/YYYY.";
    case "oldPassword":
    case "newPassword":
    case "confirmPassword":
      /* our App is configured with "Good" password strength level
      (https://auth0.com/docs/authenticate/database-connections/password-strength).
      It means that for password:
        1) at least 8 characters are required
        2) at least 3 of the following 4 types of characters: a lower-case letter, an upper-case letter, a number,
        a special character (such as !@#$%^&*) are required */
      if (newData.length < 8) {
        return stringIsTooShortErrorMessage(8);
      }
      if (
        !stringSatisfiesMinRulesCount(
          newData,
          [
            stringHasLowerCaseLetter,
            stringHasUpperCaseLetter,
            stringHasNumber,
            stringHasSpecialCharacter,
          ],
          3
        )
      ) {
        return "At least 3 of the following 4 types of characters: a lower-case letter, an upper-case letter, a number, a special character (such as !_@#$%^&*) are required";
      }
      return newData.length < 100 ? null : stringIsTooLongErrorMessage(100);
    case "assetFunctionName":
      if (newData.length === 0) {
        return stringIsTooShortErrorMessage(1);
      }
      if (stringStartingWithNumber(newData)) {
        return "Please note that field names cannot start with a number. You can start function name using only letters and underscores.";
      }
      if (!stringContainsOnlyNumbersLettersAndUnderscores(newData)) {
        return "Please enter a name for this field using only letters, numbers, and underscores.";
      }
      return null;
    case "assetConnectionName":
      if (newData.length === 0) {
        return "Please complete the required input before leaving this field.";
      }
      if (stringStartingWithNumber(newData)) {
        return "Please note that field names cannot start with a number. You can start connection name using only letters and underscores.";
      }
      if (!stringContainsOnlyNumbersLettersAndUnderscores(newData)) {
        return "Please enter a name for this field using only letters, numbers, and underscores.";
      }
      return null;
    case "canvasBlockName":
    case "canvasLayerName": {
      const text = newData.trim();
      if (text.length === 0) {
        return "Name is required";
      }
      if (text.length > 64) {
        return "The name entered exceeds the maximum length (64 characters)";
      }
      if (!/^[a-zA-Z0-9_\-. ]*$/.test(text)) {
        return "Only letters, numbers, underscores, dots, and hyphens are allowed";
      }
      return null;
    }
    default:
      return null;
  }
};

/**
 * Check if two blocks of the given types can be connected.
 */
export const canConnectBlockTypes = ({
  sourceType,
  targetType,
}: {
  sourceType: BLOCK_TYPE;
  targetType: BLOCK_TYPE;
}) => {
  const canConnectTarget = BLOCK_DETAILS[targetType].allowedInputs.some(
    (type) => type === sourceType
  );
  const canConnectSource = BLOCK_DETAILS[sourceType].allowedOutputs.some(
    (type) => type === targetType
  );
  return canConnectTarget && canConnectSource;
};

export const canBlocksConnect = ({
  sourceId,
  targetId,
  sourceType,
  targetType,
  layerType,
  edges,
}: {
  sourceId?: BlockType["id"] | null;
  targetId?: BlockType["id"] | null;
  sourceType: BLOCK_TYPE;
  targetType: BLOCK_TYPE;
  layerType: LAYER_TYPE;
  edges: Edge[];
}) => {
  if (!canConnectBlockTypes({ sourceType, targetType })) {
    return false;
  }

  // On the API layer, only the API Controller block can have multiple connections.
  if (sourceType === BLOCK_TYPE.API_CONTROLLER) {
    return true;
  }

  // The rest of blocks on the API layer can only have one connection.
  // On the SageMaker Deployments layer, none of the blocks can have multiple connections.
  if (layerType === LAYER_TYPE.API || layerType === LAYER_TYPE.SAGEMAKER) {
    const sourceHasOutputs =
      !!sourceId && edges.some((edge) => edge.source === sourceId);
    const targetHasInputs =
      !!targetId && edges.some((edge) => edge.target === targetId);
    return !sourceHasOutputs && !targetHasInputs;
  }

  return true;
};
