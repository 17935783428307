import { USER_API_URL } from "config/appConfig";
import { callAPI } from "./call-api";

// Organization Features
export const MEMBERS = "Members";
export const SELF_HOSTING = "Self Hosting";
export const GROUPS = "Groups";
export const WORKSPACES = "Workspaces";
export const ROLES = "Roles";

// Workspace Features
export const CANVASES = "Canvases";
export const ENVIRONMENTS = "Environments";
export const DATA_CONNECTIONS = "Data Connections";
export const QUERIES = "Queries";
export const CONSTANTS_AND_SECRETS = "Constants & Secrets";
export const FUNCTIONS = "Functions";
export const DEPLOY_APIS = "Deploy APIs";
export const DATA_APPS = "Data Apps";
export const SCHEDULED_JOBS = "Scheduled Jobs";

export const checkOrganizationPermission = async (
  organizationId: string,
  feature: string,
  permission: string
) => {
  const data = {
    organization_id: organizationId,
    feature,
    permission,
  };

  const response = await callAPI({
    method: "POST",
    url: `${USER_API_URL}/auth/permission/organization`,
    data,
  });
  if (response.status === 200 && response) {
    return true;
  }
  return false;
};

export const checkWorkspacePermission = async (
  workspaceId: string,
  feature: string,
  permission: string
) => {
  const data = {
    workspace_id: workspaceId,
    feature,
    permission,
  };

  try {
    const response = await callAPI({
      method: "POST",
      url: `${USER_API_URL}/auth/permission/workspace`,
      data,
    });
    if (response === true) {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

const permissionService = {
  checkOrganizationPermission,
  checkWorkspacePermission,
};

export default permissionService;
