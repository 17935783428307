import styles from "./TabsButtons.module.scss";
import { Fragment, type ReactNode, type MouseEvent } from "react";
import cn from "classnames";
import {
  LinkButton,
  type LinkButtonProps,
} from "components/common/Button/LinkButton";
import { Button } from "components/common/Button/Button";

export type TabButton<Key extends string> = {
  label: ReactNode;
  key: Key;
  to?: LinkButtonProps["to"];
  disabled?: boolean;
};

type TabsButtonsProps<Key extends string> = {
  activeTab: Key | null;
  tabs: TabButton<Key>[];
  className?: string;
  onChange?: (e: MouseEvent<HTMLButtonElement>, tab: Key) => void;
};

export function TabsButtons<Key extends string>({
  activeTab,
  tabs,
  className,
  onChange,
}: TabsButtonsProps<Key>) {
  return (
    <div className={cn(styles.container, className)}>
      {tabs.map((tab) => {
        const className = cn(styles.tab, {
          [styles.active]: tab.key === activeTab,
        });
        return (
          <Fragment key={tab.key}>
            {tab.to ? (
              // link
              <LinkButton variant="text" className={className} to={tab.to}>
                {tab.label}
              </LinkButton>
            ) : (
              // button
              <Button
                variant="text"
                className={className}
                disabled={tab.disabled}
                onClick={(e) => {
                  onChange?.(e, tab.key);
                }}
              >
                {tab.label}
              </Button>
            )}
          </Fragment>
        );
      })}
    </div>
  );
}
