import styles from "./UserMFASettings.module.scss";
import { useCallback, useEffect, useState } from "react";
import userSettingsService from "api/http/user-settings-service";
import { useUserState, useToastsState } from "store";
import { Button } from "components/common/Button/Button";
import Switch from "components/common/Switch/Switch";
import Typography from "components/Typography/Typography";
import { DATA_LOAD_STATUS } from "config/appConfig";
import { debouncePromise } from "utils/helpers";

const MESSAGE = {
  mfaGetStatusFailed: "Failed to get MFA status",
  mfaEnabled: "MFA is enabled",
  mfaDisabled: "MFA is disabled",
  mfaFailedToEnable: "Failed to enable MFA",
  mfaFailedToDisable: "Failed to disable MFA",
};

export const UserMFASettings = () => {
  const userId = useUserState((slice) => slice.userID);
  const addToast = useToastsState((slice) => slice.addToast);

  const [mfaEnabled, setMFAEnabled] = useState(false);
  const [mfaEnabledLoadStatus, setMFAEnabledLoadStatus] =
    useState<DATA_LOAD_STATUS>(DATA_LOAD_STATUS.NOT_LOADED);
  const [updateMFAEnabledInProgress, setUpdateMFAEnabledInProgress] =
    useState(false);

  const getUserMFAEnabled = useCallback(async () => {
    if (!userId) {
      return;
    }
    setMFAEnabledLoadStatus(DATA_LOAD_STATUS.LOADING);
    try {
      const data = await debouncePromise({
        promise: userSettingsService.getUserMFAEnabled(userId),
      });
      setMFAEnabled(data);
      setMFAEnabledLoadStatus(DATA_LOAD_STATUS.LOADED);
    } catch (err: any) {
      setMFAEnabledLoadStatus(DATA_LOAD_STATUS.ERROR);
      const cause =
        typeof err?.cause?.detail === "string" ? err.cause.detail : "";
      const message = MESSAGE.mfaGetStatusFailed;
      const errorMessage = cause ? `${message}: ${cause}` : `${message}`;
      addToast({
        variant: "error",
        message: errorMessage,
      });
    }
  }, [userId]);

  useEffect(() => {
    getUserMFAEnabled();
  }, [getUserMFAEnabled]);

  const handleMFAEnabledChange = useCallback(
    async (newValue: boolean) => {
      if (!userId) {
        return;
      }
      setUpdateMFAEnabledInProgress(true);
      try {
        // res is true if successfully set
        const res = await debouncePromise({
          promise: userSettingsService.setUserMFAEnabled(userId, newValue),
        });

        if (res) {
          setMFAEnabled(newValue);
          addToast({
            variant: "success",
            message: newValue ? MESSAGE.mfaEnabled : MESSAGE.mfaDisabled,
          });
        } else {
          addToast({
            variant: "error",
            message: newValue
              ? MESSAGE.mfaFailedToEnable
              : MESSAGE.mfaFailedToDisable,
          });
        }

        setUpdateMFAEnabledInProgress(false);
      } catch (err: any) {
        setUpdateMFAEnabledInProgress(false);
        const cause =
          typeof err?.cause?.detail === "string" ? err.cause.detail : "";
        const message = newValue
          ? MESSAGE.mfaFailedToEnable
          : MESSAGE.mfaFailedToDisable;
        const errorMessage = cause ? `${message}: ${cause}` : `${message}`;
        addToast({
          variant: "error",
          message: errorMessage,
        });
      }
    },
    [userId]
  );

  const isLoading =
    mfaEnabledLoadStatus === DATA_LOAD_STATUS.NOT_LOADED ||
    mfaEnabledLoadStatus === DATA_LOAD_STATUS.LOADING ||
    updateMFAEnabledInProgress;

  return (
    <div className={styles.container}>
      <div>
        <Typography variant="h3">Enable MFA</Typography>
        <Typography variant="body2" className={styles.sub_heading}>
          Enable MFA to add a layer of security during login.
        </Typography>
      </div>
      {mfaEnabledLoadStatus === DATA_LOAD_STATUS.ERROR ? (
        <Button variant="crucial" onClick={getUserMFAEnabled}>
          Refetch MFA status
        </Button>
      ) : (
        <Switch
          isChecked={mfaEnabled}
          onChange={handleMFAEnabledChange}
          loading={isLoading}
          disabled={isLoading}
        />
      )}
    </div>
  );
};
