import styles from "./UserSettingsDeveloperAPI.module.scss";
import { useCallback, useEffect, useState } from "react";
import { useToastsState } from "store";
import { useModal } from "hooks/useModal";
import { BiCopy } from "react-icons/bi";
import { Button } from "components/common/Button/Button";
import ToasterMessage from "components/common/ToasterMessage/ToasterMessage";
import IconButton from "components/common/IconButton/IconButton";
import Tooltip from "components/common/Tooltip/Tooltip";
import Typography from "components/Typography/Typography";
import {
  getDeveloperAPISetting,
  postDeveloperAPISetting,
  deleteDeveloperAPISetting,
} from "api/http/developer-api-setting-service";
import { UserSettingsTabHeader } from "components/UserSettingsTabHeader";
import { DeleteDeveloperSettingsModal } from "./DeleteDeveloperSettingsModal";

const NO_KEY_STATE = 0;
const NEW_KEY_STATE = 1;
const EXISTING_KEY_STATE = 2;

export default function UserSettingsDeveloperAPI() {
  const addToast = useToastsState((slice) => slice.addToast);
  const { openModal } = useModal();
  const [keyState, setKeyState] = useState(-1);
  const [APIKey, setAPIKey] = useState("");

  // Fetch developer api setting when the component loads.
  useEffect(() => {
    async function initializeDeveloperAPISetting() {
      try {
        const existingSetting = await getDeveloperAPISetting();
        if (existingSetting?.api_key) {
          setKeyState(EXISTING_KEY_STATE);
          setAPIKey(existingSetting.api_key);
        } else {
          setKeyState(NO_KEY_STATE);
        }
      } catch (err) {
        setKeyState(NO_KEY_STATE);
      }
    }

    initializeDeveloperAPISetting();
  }, []);

  const onCreateAPIKey = async () => {
    const newSetting = await postDeveloperAPISetting();
    if (newSetting?.api_key) {
      setKeyState(NEW_KEY_STATE);
      setAPIKey(newSetting.api_key);
      addToast({
        message: "Developer API key successfully created",
        variant: "success",
        duration: 1000,
      });
    }
  };

  const onDeleteAPIKey = async () => {
    await deleteDeveloperAPISetting();
    setKeyState(NO_KEY_STATE);
    setAPIKey("");
    addToast({
      message: "Developer API key successfully deleted",
      variant: "success",
      duration: 1000,
    });
  };

  const onDelete = () => {
    openModal({
      title: "Delete Developer API Settings",
      content: ({ onModalClose }) => (
        <DeleteDeveloperSettingsModal
          onCancelDelete={onModalClose}
          onDeleteDeveloperSettings={() => {
            onDeleteAPIKey();
            onModalClose();
          }}
        />
      ),
    });
  };

  const handleAPIKeyCopy = useCallback(
    (e) => {
      e.stopPropagation();
      if (!APIKey) {
        return;
      }
      navigator.clipboard.writeText(APIKey).then(() => {
        addToast({
          message: "Copied Developer API Key to clipboard",
          variant: "info",
        });
      });
    },
    [APIKey]
  );

  return (
    <div className={styles.container}>
      <UserSettingsTabHeader
        heading="Developer API"
        subHeading="Manage your developer API key."
      />

      {keyState === NO_KEY_STATE && (
        <>
          <Typography
            variant="body2"
            className={`${styles.paragraphPadding} ${styles.explanationText}`}
          >
            You do not currently have a developer API key. In order to write
            code to work with the developer API, you must first create a key
            that will be your unique identification token for calls to the API.
          </Typography>
          <Typography
            variant="body2"
            className={`${styles.paragraphPadding} ${styles.explanationText}`}
          >
            Create a new API key by clicking the button below.
          </Typography>
          <div className={styles.buttonSection}>
            <Button
              className={styles.selfHostingButton}
              disabled={false}
              onClick={() => onCreateAPIKey()}
            >
              Create Developer API Key
            </Button>
          </div>
        </>
      )}
      {keyState === EXISTING_KEY_STATE && (
        <>
          <Typography
            variant="body2"
            className={`${styles.paragraphPadding} ${styles.explanationText}`}
          >
            A developer API key was previously created for your account. At the
            time this key was created it should have been copied and saved, but
            never shared with others.
          </Typography>
          <Typography
            variant="body2"
            className={`${styles.paragraphPadding} ${styles.explanationText}`}
          >
            Developer API Key:
            <span className={`${styles.leftPadding} ${styles.highlightText}`}>
              {APIKey}
            </span>
          </Typography>
          <Typography
            variant="body2"
            className={`${styles.paragraphPadding} ${styles.mutedText}`}
          >
            If your API was lost and you need to create a new one, you must
            first delete your existing API key.
          </Typography>
        </>
      )}
      {keyState === NEW_KEY_STATE && (
        <>
          <ToasterMessage variant="warning" animate={false}>
            Your secret developer API key is shown below. Please note that we
            will not display your API key again.
          </ToasterMessage>
          <div className={styles.singleRow}>
            <div className={styles.rowElement}>
              <Typography
                variant="body2"
                className={`${styles.paragraphPadding} ${styles.explanationText}`}
              >
                Developer API Key:
              </Typography>
            </div>
            <div className={styles.rowElement}>
              <Typography
                variant="body2"
                className={`${styles.leftPadding} ${styles.highlightText}`}
              >
                {APIKey}
              </Typography>
            </div>
            <div className={styles.rowElement}>
              {APIKey ? (
                <Tooltip
                  text="Copy Developer API Key"
                  placement="top"
                  withArrow
                >
                  <IconButton onClick={handleAPIKeyCopy}>
                    <BiCopy />
                  </IconButton>
                </Tooltip>
              ) : null}
            </div>
          </div>
          <Typography
            variant="body2"
            className={`${styles.paragraphPadding} ${styles.explanationText}`}
          >
            Do not share your API key with others, or expose it in the browser
            or other client side code. In order to protect the security of your
            account, Zerve may also automatically rotate any API key that we
            found has leaked publicly.
          </Typography>
        </>
      )}
      {(keyState === NEW_KEY_STATE || keyState === EXISTING_KEY_STATE) && (
        <div className={styles.deleteWrapper}>
          <div className={styles.deleteExplanationText}>
            <Typography variant="h2">Delete Developer API Settings</Typography>
            <Typography variant="body2" className={styles.subTitle}>
              If you delete your developer API data, it will be removed our
              system. Please be certain.
            </Typography>
          </div>
          <Button variant="crucial" onClick={onDelete}>
            Delete Developer API Key
          </Button>
        </div>
      )}
    </div>
  );
}
