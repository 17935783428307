import { type FC, useCallback, useMemo } from "react";
import { BiDotsHorizontal } from "react-icons/bi";
import { useModal } from "hooks/useModal";
import { useConfirmModal } from "hooks/useConfirmModal";

import useConnectionActions from "hooks/assets/useConnectionActions";
import { DropdownMenuButton } from "components/common/DropdownMenuButton/DropdownMenuButton";
import { getEnabledContextMenuOptions } from "utils/helpers";
import { ASSET_PERMISSION, LABEL, MESSAGE } from "pages/Assets/config";
import { type ConnectionItemType } from "pages/Assets/types/connectionTypes";
import itemDropdownStyles from "pages/Assets/components/AssetsNavItemDropdown/AssetsNavItemDropdown.module.scss";
import ManageUsage from "../ManageUsage/ManageUsage";
import {
  archiveOptionLabel,
  deleteOptionLabel,
  duplicateOptionLabel,
  manageUsageOptionLabel,
  OwnerInfo,
} from "./constants";

const ConnectionsNavItemDropdown: FC<ConnectionItemType> = (
  connectionData: ConnectionItemType
) => {
  const { openModal } = useModal();
  const { openConfirmModal } = useConfirmModal();

  const {
    connection: { id, owner, permission, value },
  } = connectionData;

  const {
    createConnectionDuplicate,
    deleteConnection,
    switchArchiveConnection,
  } = useConnectionActions();

  const handleCreateDuplicate = useCallback(() => {
    createConnectionDuplicate(id);
  }, [id, createConnectionDuplicate]);

  const handleConfirmDelete = useCallback(async () => {
    const confirmed = await openConfirmModal({
      message: MESSAGE.assetDeleteConfirm,
      confirmButtonLabel: LABEL.deleteConfirmButton,
      confirmButtonVariant: "crucial",
    });

    if (confirmed) {
      deleteConnection(id);
    }
  }, [deleteConnection, id]);

  const handleConfirmArchive = useCallback(async () => {
    const confirmed = await openConfirmModal({
      message: MESSAGE.assetArchiveConfirm,
      confirmButtonLabel: LABEL.archiveConfirmButton,
    });

    if (confirmed) {
      switchArchiveConnection(id, true);
    }
  }, [switchArchiveConnection, id]);

  const handleManageUsage = useCallback(() => {
    openModal({
      title: "Manage Usage",
      content: () => {
        return <ManageUsage assetId={id} />;
      },
    });
  }, [id]);

  const isEditor = permission === ASSET_PERMISSION.write;
  const isConfigured = !!value && Object.keys(value).length > 0;

  const menuOptions = useMemo(() => {
    const options = [
      {
        key: "duplicate",
        type: "option" as const,
        label: duplicateOptionLabel,
        disabled: !isEditor || !isConfigured,
        onClick: handleCreateDuplicate,
      },
      {
        key: "manage-usage",
        type: "option" as const,
        label: manageUsageOptionLabel,
        disabled: !isEditor,
        onClick: handleManageUsage,
      },
      {
        key: "archive",
        type: "option" as const,
        label: archiveOptionLabel,
        disabled: !isEditor,
        showDivider: true,
        onClick: handleConfirmArchive,
      },
      {
        key: "delete",
        type: "option" as const,
        label: deleteOptionLabel,
        disabled: !isEditor,
        showDivider: true,
        onClick: handleConfirmDelete,
      },
      {
        key: "owner-info",
        type: "custom" as const,
        component: <OwnerInfo owner={owner} key="owner-info" />,
      },
    ];

    return getEnabledContextMenuOptions(options);
  }, [
    isEditor,
    isConfigured,
    handleCreateDuplicate,
    handleManageUsage,
    handleConfirmArchive,
    handleConfirmDelete,
  ]);

  return (
    <DropdownMenuButton
      buttonVariant="text"
      hideChevron
      menuOptions={menuOptions}
      text={<BiDotsHorizontal />}
      nodesClassNames={{
        text: itemDropdownStyles.textWrapper,
        contextMenu: itemDropdownStyles.contextMenu,
        container: itemDropdownStyles.dropdownContainer,
        button: itemDropdownStyles.button,
      }}
      hideFloatingElWhenRefElIsHidden
      wrapContextMenuWithFloatingPortal
      contextMenuFitWidth
    />
  );
};

export default ConnectionsNavItemDropdown;
