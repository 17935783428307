import { KeyMod, KeyCode } from "monaco-editor";

const macPrimaryKey = "meta";
// (listens for ctrl on Windows/Linux)
// https://react-hotkeys-hook.vercel.app/docs/documentation/useHotkeys/basic-usage#modifiers--special-keys
const winPrimaryKey = "mod";

const macSecondaryKey = "alt";
const winSecondaryKey = "alt";

const isMac = navigator.platform.toUpperCase().includes("MAC");

const primaryKey = isMac ? macPrimaryKey : winPrimaryKey;
const secondaryKey = isMac ? macSecondaryKey : winSecondaryKey;

const primarySymbol = isMac ? "⌘" : "Ctrl";
const secondarySymbol = isMac ? "⌥" : "Alt";

const shiftKey = "shift";
// outlined arrow for shift symbol
const shiftSymbol = "⇧";
// backspace symbol
const backspaceSymbol = "⌫";

const enterSymbol = "↩";
const escapeSymbol = "esc";

const leftArrowSymbol = "←";
const rightArrowSymbol = "→";
const upArrowSymbol = "↑";
const downArrowSymbol = "↓";

export const canvasHotKeys = {
  SELECT_ALL: { shortcut: `${primaryKey} + a`, hotkey: `${primarySymbol} + A` },
  DELETE: { shortcut: "del, delete, backspace", hotkey: backspaceSymbol },
  SET_KEY_IMAGE: {
    shorttcut: `${primaryKey} + k`,
    hotkey: `${primarySymbol} K`,
  },
  // Export Selection
  EXPORT_SELECTION: {
    shortcut: `${primaryKey} + e`,
    hotkey: `${primarySymbol} E`,
  },
  // Copy, Paste, Cut
  COPY: { shortcut: `${primaryKey} + c`, hotkey: `${primarySymbol} C` },
  PASTE: { shortcut: `${primaryKey} + v`, hotkey: `${primarySymbol} V` },
  CUT: { shortcut: `${primaryKey} + x`, hotkey: `${primarySymbol} X` }, // what does cut do?
  // Undo and Redo
  UNDO: { shortcut: `${primaryKey} + z`, hotkey: `${primarySymbol} Z` },
  REDO: { shortcut: `${primaryKey} + y`, hotkey: `${primarySymbol} Y` },
  // Download
  DOWNLOAD: { shortcut: `${primaryKey} + d`, hotkey: `${primarySymbol} D` },
  // tidy
  TIDY: {
    shortcut: `${primaryKey} + .`,
    hotkey: `${primarySymbol} .`,
  },
  TOGGLE_AUTO_TIDY: {
    shortcut: `${shiftKey} + ${primaryKey} + t`,
    hotkey: `${shiftSymbol} ${primaryKey} .`,
  },
  // open find
  FIND: { shortcut: `${primaryKey} + f`, hotkey: `${primarySymbol} F` },
  FIND_REPLACE: {
    shortcut: `${primaryKey} + ${shiftKey} + f`,
    hotkey: `${primarySymbol} ${shiftSymbol} F`,
  },
  // Toggle Menu Open
  TOGGLE_MENU: {
    shortcut: `${primaryKey} + m`,
    hotkey: `${primarySymbol} M`,
  },
  TOGGLE_LAYERS_MENU: {
    shortcut: `${primaryKey} + 1`,
    hotkey: `${primarySymbol} 1`,
  },
  TOGGLE_FILES_MENU: {
    shortcut: `${primaryKey} + 2`,
    hotkey: `${primarySymbol} 2`,
  },
  TOGGLE_REQUIREMENTS_MENU: {
    shortcut: `${primaryKey} + 3`,
    hotkey: `${primarySymbol} 3`,
  },
  TOGGLE_IMPORTS_MENU: {
    shortcut: `${primaryKey} + 4`,
    hotkey: `${primarySymbol} 4`,
  },
  TOGGLE_ASSETS_MENU: {
    shortcut: `${primaryKey} + 5`,
    hotkey: `${primarySymbol} 5`,
  },
  TOGGLE_GITHUB_MENU: {
    shortcut: `${primaryKey} + 6`,
    hotkey: `${primarySymbol} 6`,
  },
  TOGGLE_MODELS_MENU: {
    shortcut: `${primaryKey} + 7`,
    hotkey: `${primarySymbol} 7`,
  },
  // Run
  RUN: {
    shortcut: `${primaryKey} + enter`,
    hotkey: `${primarySymbol} ${enterSymbol}`,
    editorKeybindings: [KeyMod.CtrlCmd | KeyCode.Enter],
  },
  FORCE_RUN: {
    shortcut: `${secondaryKey} + enter`,
    hotkey: `${secondarySymbol} ${enterSymbol}`,
    editorKeybindings: [KeyMod.Alt | KeyCode.Enter],
  },
  RUN_UP_TO_HERE: {
    shortcut: `${shiftKey} + ${primaryKey} + enter`,
    hotkey: `${shiftSymbol} ${primarySymbol} ${enterSymbol}`,
    editorKeybindings: [KeyMod.CtrlCmd | KeyMod.Shift | KeyCode.Enter],
  },
  FORCE_RUN_UP_TO_HERE: {
    shortcut: `${shiftKey} + ${secondaryKey} + enter`,
    hotkey: `${shiftSymbol} ${secondarySymbol} ${enterSymbol}`,
    editorKeybindings: [KeyMod.Alt | KeyMod.Shift | KeyCode.Enter],
  },
  RUN_ALL: {
    shortcut: `${secondaryKey} + ${primaryKey} + enter`,
    hotkey: `${secondarySymbol} ${primarySymbol} ${enterSymbol}`,
    editorKeybindings: [KeyMod.CtrlCmd | KeyMod.Alt | KeyCode.Enter],
  },
  FORCE_RUN_ALL: {
    shortcut: `${shiftKey} + ${secondaryKey} + ${primaryKey} + enter`,
    hotkey: `${shiftSymbol} ${secondarySymbol} ${primarySymbol} ${enterSymbol}`,
    editorKeybindings: [
      KeyMod.CtrlCmd | KeyMod.Alt | KeyMod.Shift | KeyCode.Enter,
    ],
  },
  // Save
  SAVE: {
    editorKeybindings: [KeyMod.CtrlCmd | KeyCode.KeyS],
  },
  // Deselect
  DESELECT: { shortcut: "esc", hotkey: escapeSymbol },
  // Zoom to Fit
  ZOOM_TO_FIT: {
    shortcut: `${primaryKey} + 0`,
    hotkey: `${primarySymbol} 0`,
  },
  // Zoom In
  ZOOM_IN: { shortcut: `${primaryKey} + =`, hotkey: `${primarySymbol} +` },
  // Zoom Out
  ZOOM_OUT: { shortcut: `${primaryKey} + -`, hotkey: `${primarySymbol} -` },
  // Zoom to Block
  ZOOM_TO_BLOCK: { shortcut: `z`, hotkey: `Z` },
  // Zoom to Dragged Area
  ZOOM_TO_AREA: {
    shortcut: `${shiftKey} ${primaryKey}`,
    hotkey: `${shiftSymbol} ${primarySymbol} drag`,
  },
  // Save and Recall Views with i,o, u
  SAVE_VIEW_1: {
    shortcut: `${shiftKey} + ${primaryKey} + i`,
    hotkey: `${shiftKey} ${primarySymbol} I`,
  },
  SAVE_VIEW_2: {
    shortcut: `${shiftKey} + ${primaryKey} + o`,
    hotkey: `${shiftKey} ${primarySymbol} O`,
  },
  SAVE_VIEW_3: {
    shortcut: `${shiftKey} + ${primaryKey} + u`,
    hotkey: `${shiftKey} ${primarySymbol} U`,
  },
  RECALL_VIEW_1: {
    shortcut: `${primaryKey} + i`,
    hotkey: `${primarySymbol} I`,
  },
  RECALL_VIEW_2: {
    shortcut: `${primaryKey} + o`,
    hotkey: `${primarySymbol} O`,
  },
  RECALL_VIEW_3: {
    shortcut: `${primaryKey} + u`,
    hotkey: `${primarySymbol} U`,
  },
  // Create New Block
  NEW_BLOCK: {
    shortcut: `${secondaryKey} + n`,
    hotkey: `${secondarySymbol} N`,
  },
  // Active Block Hotkeys
  FORMAT_BLOCK: {
    shortcut: `${shiftKey} + ${secondaryKey} + f`,
    hotkey: `${primaryKey} + ${secondarySymbol} F`,
  },
  FORMAT_ALL_BLOCKS: {
    shortcut: `${shiftKey} + ${secondaryKey} + ${primaryKey} + f`,
    hotkey: `${shiftSymbol} ${secondarySymbol} ${primarySymbol} F`,
  },
  // Navigate between blocks
  PREVIOUS_BLOCK: {
    shortcut: `${primaryKey} + left`,
    hotkey: `${primarySymbol} ${secondarySymbol} ${leftArrowSymbol}`,
  },
  NEXT_BLOCK: {
    shortcut: `${primaryKey} + right`,
    hotkey: `${primarySymbol} ${secondarySymbol} ${rightArrowSymbol}`,
  },
  // Block size
  INCREASE_BLOCK_WIDTH: {
    shortcut: `${secondaryKey} + ${primaryKey} + right`,
    hotkey: `${secondarySymbol} ${primarySymbol} ${rightArrowSymbol}`,
  },
  DECREASE_BLOCK_WIDTH: {
    shortcut: `${secondaryKey} + ${primaryKey} + left`,
    hotkey: `${secondarySymbol} ${primarySymbol} ${leftArrowSymbol}`,
  },
  INCREASE_BLOCK_HEIGHT: {
    shortcut: `${secondaryKey} + ${primaryKey} + up`,
    hotkey: `${secondarySymbol} ${primarySymbol} ${upArrowSymbol}`,
  },
  DECREASE_BLOCK_HEIGHT: {
    shortcut: `${secondaryKey} + ${primaryKey} + down`,
    hotkey: `${secondarySymbol} ${primarySymbol} ${downArrowSymbol}`,
  },
  TRUNCATE_TO_BLOCK: {
    shortcut: `${shiftKey} + ${secondaryKey} + x`,
    hotkey: `${shiftSymbol} ${secondarySymbol} X`,
  },
};

export enum HOTKEY_SCOPE_ID {
  /* By default all hotkeys are in the * (wildcard) scope. This means that they will be active
  regardless of where you are in your application. Wrapping your app with the <HotkeysProvider>
  component will enable the wildcard scope by default.
  https://react-hotkeys-hook.vercel.app/docs/documentation/hotkeys-provider */
  DEFAULT = "*",
  CANVAS = "CANVAS",
  CANVAS_FULLSCREEN = "CANVAS_FULLSCREEN",
  GITHUB_DIFFS = "GITHUB_DIFFS",
  MODAL = "MODAL",
  REQUIREMENTS_LOGS = "REQUIREMENTS_LOGS",
  SCHEDULED_JOB_RUNS = "SCHEDULED_JOB_RUNS",
  WORKSPACE_APP_LOGS = "WORKSPACE_APP_LOGS",
  CANVAS_EXECUTOR_LOGS = "CANVAS_EXECUTOR_LOGS",
  EXPANDED_API_CONTROLLER_BLOCK = "EXPANDED_API_CONTROLLER_BLOCK",
  EXPANDED_API_ROUTE_BLOCK = "EXPANDED_API_ROUTE_BLOCK",
  EXPANDED_AUTOML_BLOCK = "EXPANDED_AUTOML_BLOCK",
  EXPANDED_SAGEMAKER_ENDPOINT_BLOCK = "EXPANDED_SAGEMAKER_ENDPOINT_BLOCK",
}

const isModalScope = (scope: string) => {
  return scope.startsWith(HOTKEY_SCOPE_ID.MODAL);
};

export const getIsHotkeyValidInScope = ({
  hotkey,
  scope,
}: {
  hotkey: keyof typeof canvasHotKeys;
  scope: string;
}) => {
  if (scope === HOTKEY_SCOPE_ID.DEFAULT) {
    return false;
  }

  switch (hotkey) {
    case "TOGGLE_MENU":
    case "TOGGLE_LAYERS_MENU":
    case "TOGGLE_REQUIREMENTS_MENU":
    case "TOGGLE_IMPORTS_MENU":
    case "TOGGLE_FILES_MENU":
    case "TOGGLE_ASSETS_MENU":
    case "TOGGLE_GITHUB_MENU":
    case "TOGGLE_MODELS_MENU": {
      return (
        scope !== HOTKEY_SCOPE_ID.CANVAS_FULLSCREEN && !isModalScope(scope)
      );
    }
    case "FIND":
    case "FIND_REPLACE":
    case "TRUNCATE_TO_BLOCK":
    case "NEXT_BLOCK":
    case "PREVIOUS_BLOCK":
    case "DESELECT":
    case "ZOOM_IN":
    case "ZOOM_OUT":
    case "ZOOM_TO_FIT":
    case "ZOOM_TO_BLOCK":
    case "ZOOM_TO_AREA":
    case "DELETE":
    case "FORMAT_ALL_BLOCKS":
    case "NEW_BLOCK":
    case "TIDY": {
      return scope === HOTKEY_SCOPE_ID.CANVAS;
    }
    case "DOWNLOAD":
    case "FORMAT_BLOCK":
    case "RUN_ALL":
    case "FORCE_RUN_ALL":
    case "RUN":
    case "FORCE_RUN":
    case "RUN_UP_TO_HERE":
    case "FORCE_RUN_UP_TO_HERE": {
      return (
        scope === HOTKEY_SCOPE_ID.CANVAS ||
        scope === HOTKEY_SCOPE_ID.CANVAS_FULLSCREEN
      );
    }
    default: {
      return true;
    }
  }
};
