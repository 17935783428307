import styles from "./LabeledDropdownButton.module.scss";
import { type FC } from "react";
import cn from "classnames";
import { DropdownMenuButton } from "components/common/DropdownMenuButton/DropdownMenuButton";
import CheckmarkIcon from "../ContextMenu/MenuOption/CheckmarkIcon";

interface LabeledDropdownButtonProps {
  title: string;
  items: any;
  selectedItem: any;
  setSelectedItem: (item) => void;
  classes?: {
    contextMenu?: string;
  };
  disabled?: boolean;
}

const LabeledDropdownButton: FC<LabeledDropdownButtonProps> = ({
  title,
  items,
  selectedItem,
  setSelectedItem,
  classes = {},
  disabled = false,
}) => {
  const selectedItemId = selectedItem?.id;

  const menuOptions = items.map((itemObj) => {
    return {
      ...itemObj,
      key: itemObj.id,
      type: "option" as const,
      rightSideContent:
        itemObj.id === selectedItemId ? <CheckmarkIcon /> : null,
      onClick: () => {
        setSelectedItem(itemObj);
      },
    };
  });

  return (
    <DropdownMenuButton
      key={title}
      menuOptions={menuOptions}
      placement="bottom-start"
      nodesClassNames={{
        contextMenu: cn(styles.dropdownFloating, classes?.contextMenu),
        button: styles.dropdownButton,
      }}
      disabled={disabled}
    >
      <span className={styles.dropdownLabel}>{title}</span>
      <span className={styles.dropdownSelectedValue}>
        {selectedItem?.label ?? ""}
      </span>
    </DropdownMenuButton>
  );
};

export default LabeledDropdownButton;
