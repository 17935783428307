import { ASSET_API_URL } from "config/appConfig";
import { callAPI } from "../call-api";
import type { QueryType } from "pages/Assets/types/queryTypes";

const getUserQueries = async () => {
  return await callAPI({
    url: `${ASSET_API_URL}/queries/user`,
    method: "GET",
  });
};

const getWorkspaceQueries = async (workspaceId: string) => {
  return await callAPI({
    url: `${ASSET_API_URL}/queries/workspace/${workspaceId}`,
    method: "GET",
  });
};

const postQuery = async (name: string) =>
  await callAPI({
    url: `${ASSET_API_URL}/queries`,
    method: "POST",

    data: {
      asset: {
        name,
      },
      version: {
        code: "",
      },
    },
  });

const postWorkspaceQuery = async (workspaceId: string, name: string) =>
  await callAPI({
    url: `${ASSET_API_URL}/queries/workspace/${workspaceId}`,
    method: "POST",

    data: {
      asset: {
        name,
      },
      version: {
        code: "",
      },
    },
  });

const putQuery = async (
  id: string,
  data: {
    name: string;
  }
) =>
  await callAPI<QueryType>({
    url: `${ASSET_API_URL}/queries/${id}`,
    method: "PUT",

    data,
  });

const patchQueryVersion = async (
  assetVersionId: string,
  data: {
    id: string;
    code?: string;
  }
) =>
  await callAPI({
    url: `${ASSET_API_URL}/queries/version/${assetVersionId}`,
    method: "PATCH",

    data,
  });

const postQueryVersion = async (data: { asset_id: string; code?: string }) =>
  await callAPI({
    url: `${ASSET_API_URL}/queries/version`,
    method: "POST",

    data,
  });

const deleteQueryById = async (queryId: string) =>
  await callAPI({
    url: `${ASSET_API_URL}/queries/${queryId}`,
    method: "DELETE",
  });

const putQueryArchive = async (queryId: string, isArchived: boolean) =>
  await callAPI({
    url: `${ASSET_API_URL}/queries/${queryId}/archive`,
    method: "PUT",

    data: {
      archive: isArchived,
    },
  });

const putQueryVersionArchive = async (versionId: string, isArchived: boolean) =>
  await callAPI({
    url: `${ASSET_API_URL}/queries/version/${versionId}/archive`,
    method: "PUT",

    data: {
      archive: isArchived,
    },
  });

const putQueryVersionStatus = async (versionId: string, status: number) =>
  await callAPI({
    url: `${ASSET_API_URL}/queries/version/${versionId}/status`,
    method: "PUT",

    data: {
      status,
    },
  });

const deleteQueryVersion = async (versionId: string) =>
  await callAPI({
    url: `${ASSET_API_URL}/queries/version/${versionId}`,
    method: "DELETE",
  });

export {
  getUserQueries,
  getWorkspaceQueries,
  postQuery,
  postWorkspaceQuery,
  putQuery,
  deleteQueryById,
  patchQueryVersion,
  putQueryArchive,
  putQueryVersionArchive,
  postQueryVersion,
  putQueryVersionStatus,
  deleteQueryVersion,
};
