import { CANVAS_API_URL } from "config/appConfig";
import { callAPI } from "./call-api";
import type { CommentType, ReplyType } from "models/comments";

// fetch all comments for a canvas
export const getCanvasComments = async ({ canvasId }: { canvasId: string }) =>
  await callAPI<CommentType[]>({
    method: "GET",
    url: `${CANVAS_API_URL}/canvas/${canvasId}/comments`,
  });

// fetch all comments for a block
export const getBlockComments = async ({ blockId }: { blockId: string }) =>
  await callAPI<CommentType[]>({
    method: "GET",
    url: `${CANVAS_API_URL}/block/${blockId}/comments`,
  });

// create a new comment for a block
export const postBlockComment = async ({
  userId,
  blockId,
  comment,
}: {
  userId: string;
  blockId: string;
  comment: string;
}) =>
  await callAPI<CommentType>({
    method: "POST",
    url: `${CANVAS_API_URL}/block/${blockId}/comments`,
    data: { user_id: userId, block_id: blockId, comment },
  });

// update a comment
export const updateBlockComment = async ({
  blockId,
  blockCommentId,
  updatedComment,
}: {
  blockId: string;
  blockCommentId: string;
  updatedComment: string;
}) =>
  await callAPI<CommentType>({
    method: "PATCH",
    url: `${CANVAS_API_URL}/block/${blockId}/comments/${blockCommentId}`,
    data: { comment: updatedComment },
  });

// delete a comment
export const deleteBlockComment = async ({
  blockId,
  blockCommentId,
}: {
  blockId: string;
  blockCommentId: string;
}) =>
  await callAPI<boolean>({
    method: "DELETE",
    url: `${CANVAS_API_URL}/block/${blockId}/comments/${blockCommentId}`,
  });

// mark a comment as resolved
export const resolveBlockComment = async ({
  blockId,
  blockCommentId,
}: {
  blockId: string;
  blockCommentId: string;
}) =>
  await callAPI<CommentType>({
    method: "POST",
    url: `${CANVAS_API_URL}/block/${blockId}/comments/${blockCommentId}/resolve`,
  });

// mark a comment as unresolved
export const unresolveBlockComment = async ({
  blockId,
  blockCommentId,
}: {
  blockId: string;
  blockCommentId: string;
}) =>
  await callAPI<CommentType>({
    method: "POST",
    url: `${CANVAS_API_URL}/block/${blockId}/comments/${blockCommentId}/unresolve`,
  });

// reply to a comment
export const postBlockCommentReply = async ({
  userId,
  blockId,
  blockCommentId,
  reply,
}: {
  userId: string;
  blockId: string;
  blockCommentId: string;
  reply: string;
}) =>
  await callAPI<ReplyType>({
    method: "POST",
    url: `${CANVAS_API_URL}/block/${blockId}/comments/${blockCommentId}`,
    data: { user_id: userId, block_comment_id: blockCommentId, reply },
  });

// update a reply
export const updateBlockCommentReply = async ({
  blockId,
  blockCommentId,
  blockCommentReplyId,
  updatedReply,
}: {
  blockId: string;
  blockCommentId: string;
  blockCommentReplyId: string;
  updatedReply: string;
}) =>
  await callAPI<ReplyType>({
    method: "PATCH",
    url: `${CANVAS_API_URL}/block/${blockId}/comments/${blockCommentId}/reply/${blockCommentReplyId}`,
    data: { reply: updatedReply },
  });

// delete a reply
export const deleteBlockCommentReply = async ({
  blockId,
  blockCommentId,
  blockCommentReplyId,
}: {
  blockId: string;
  blockCommentId: string;
  blockCommentReplyId: string;
}) =>
  await callAPI<boolean>({
    method: "DELETE",
    url: `${CANVAS_API_URL}/block/${blockId}/comments/${blockCommentId}/reply/${blockCommentReplyId}`,
  });

export const getBlockComment = async ({
  blockId,
  blockCommentId,
}: {
  blockId: string;
  blockCommentId: string;
}) =>
  await callAPI<string>({
    method: "GET",
    url: `${CANVAS_API_URL}/block/${blockId}/comments/${blockCommentId}`,
  });

export const getBlockCommentReply = async ({
  blockCommentId,
  blockCommentReplyId,
}: {
  blockCommentId: string;
  blockCommentReplyId: string;
}) =>
  await callAPI<string>({
    method: "GET",
    url: `${CANVAS_API_URL}/block/comments/${blockCommentId}/replies/${blockCommentReplyId}`,
  });
