import { useRouteError, isRouteErrorResponse } from "react-router-dom";
import BlockScreen from "components/BlockScreen/BlockScreen";
import { LinkButton } from "components/common/Button/LinkButton";

export default function ErrorPage() {
  const error = useRouteError();

  let title = "Error";
  let status: string | number = "Something went wrong";
  let message = "Please try again later";

  if (isRouteErrorResponse(error)) {
    title = error.statusText;
    status = error.status;
    if (status === 404) {
      message = "The page you are looking for does not exist";
    }
  }

  return (
    <BlockScreen
      title={title}
      header={status}
      message={message}
      actions={
        <LinkButton variant="primary" size="large" to="/">
          Go Home
        </LinkButton>
      }
    />
  );
}
