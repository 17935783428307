import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { HotkeysProvider } from "react-hotkeys-hook";
import { Auth0 } from "context/Auth0";
import { LaunchDarkly } from "context/LaunchDarkly";
import { Sentry } from "context/Sentry";
import { TokenProvider } from "context/Token";
import { ModalProvider } from "context/Modal";
import { ContextMenuProvider } from "context/ContextMenu";
import { UserSettingsProvider } from "context/UserSettings";
import { BillingSettingsProvider } from "context/BillingSettings";
import { CodeEditorConfig } from "components/common/CodeEditor/CodeEditorConfig";
import { Maintenance } from "layout/Maintenance/Maintenance";
import ToasterContainer from "components/ToasterContainer/ToasterContainer";
import LoadingScreen from "components/common/LoadingScreen/LoadingScreen";

export function App() {
  return (
    <Auth0>
      <LaunchDarkly>
        <Sentry>
          <HelmetProvider>
            <TokenProvider>
              <HotkeysProvider>
                <ModalProvider>
                  <ContextMenuProvider>
                    <UserSettingsProvider>
                      <BillingSettingsProvider>
                        <CodeEditorConfig>
                          <Maintenance>
                            <Suspense
                              fallback={<LoadingScreen text="Loading..." />}
                            >
                              <Outlet />
                            </Suspense>
                          </Maintenance>
                        </CodeEditorConfig>
                      </BillingSettingsProvider>
                    </UserSettingsProvider>
                    <ToasterContainer />
                  </ContextMenuProvider>
                </ModalProvider>
              </HotkeysProvider>
            </TokenProvider>
          </HelmetProvider>
        </Sentry>
      </LaunchDarkly>
    </Auth0>
  );
}
