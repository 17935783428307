import styles from "./EnvironmentVariables.module.scss";
import { useState, useCallback, useMemo } from "react";
import { useCanvasRequirementsState } from "store";
import { SideBarSearch } from "components/SideBarSearch/SideBarSearch";
import Loader from "components/common/Loader/Loader";
import { Button } from "components/common/Button/Button";
import { EmptyState } from "components/common/EmptyState/EmptyState";
import PanelRowButton from "components/common/PanelRowButton/PanelRowButton";
import { EditableTable } from "components/EditableTable";
import { useRequirements } from "components/menus/RequirmentsMenu/useRequirements";
import { DATA_LOAD_STATUS } from "config/appConfig";
import type { EnvironmentVariable } from "models/requirements";

enum COLUMN_KEY {
  NAME = "name",
  VALUE = "value",
}

const COLUMNS = [
  {
    label: "Name",
    key: COLUMN_KEY.NAME,
    searchable: true,
    sortable: true,
    placeholder: "required",
  },
  {
    label: "Value",
    key: COLUMN_KEY.VALUE,
    searchable: true,
    sortable: false,
    placeholder: "required",
  },
];

export function EnvironmentVariables() {
  const {
    requirementsStatus,
    executorLoadingStatus,
    environmentVariablesLoadingStatus,
    environmentVariables,
    setEnvironmentVariables,
  } = useCanvasRequirementsState((slice) => ({
    requirementsStatus: slice.requirementsStatus,
    executorLoadingStatus: slice.executorLoadingStatus,
    environmentVariablesLoadingStatus: slice.environmentVariablesLoadingStatus,
    environmentVariables: slice.environmentVariables,
    setEnvironmentVariables: slice.setEnvironmentVariables,
  }));

  const {
    checkIsBuildDisabled,
    fetchEnvironmentVariables,
    updateEnvironmentVariables,
  } = useRequirements();

  const [searchText, setSearchText] = useState("");

  const isBuildDisabled = useMemo(
    () =>
      checkIsBuildDisabled({
        requirementsStatus,
        executorLoadingStatus,
      }),
    [requirementsStatus, executorLoadingStatus, checkIsBuildDisabled]
  );

  const handleEnvironmentVariablesChange = useCallback(
    (newEnvironmentVariables: EnvironmentVariable[]) => {
      setEnvironmentVariables(newEnvironmentVariables);
      updateEnvironmentVariables({
        environmentVariables: newEnvironmentVariables,
      });
    },
    [updateEnvironmentVariables]
  );

  // not loaded / loading
  if (
    environmentVariablesLoadingStatus === DATA_LOAD_STATUS.NOT_LOADED ||
    environmentVariablesLoadingStatus === DATA_LOAD_STATUS.LOADING
  ) {
    return <Loader withOverlay>Loading...</Loader>;
  }

  // error
  if (environmentVariablesLoadingStatus === DATA_LOAD_STATUS.ERROR) {
    return (
      <div className={styles.fillWithScroll}>
        <div className={styles.errorMessageContainer}>
          <EmptyState
            variant="error"
            title="Something went wrong"
            description="Failed to load environment variables."
          >
            <Button variant="text" onClick={fetchEnvironmentVariables}>
              Try again
            </Button>
          </EmptyState>
        </div>
      </div>
    );
  }

  // loaded
  return (
    <>
      <SideBarSearch
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
      />

      <EditableTable
        rows={environmentVariables || []}
        columns={COLUMNS}
        searchText={searchText}
        readOnly={isBuildDisabled}
        addRowControl={(onRowAdd) => {
          return (
            <PanelRowButton
              label="Add variable"
              disabled={isBuildDisabled}
              onClick={(e) => {
                e.stopPropagation();
                onRowAdd({ name: "", value: "" });
              }}
            />
          );
        }}
        onChange={handleEnvironmentVariablesChange}
      />
    </>
  );
}
