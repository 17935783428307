import styles from "./BlockTitle.module.scss";
import { useState, useEffect, useMemo, useCallback } from "react";
import cn from "classnames";
import {
  useFloating,
  useDismiss,
  autoUpdate,
  offset,
} from "@floating-ui/react";
import { useUserSettingsState, useCanvasBlocksState } from "store";
import { BLOCK_DETAILS } from "config/canvasConfig";
import canvasService from "api/http/canvas-service";
import { useBlock } from "hooks/useBlock";
import { Button } from "components/common/Button/Button";
import RenameInput from "components/common/RenameInput/RenameInput";
import {
  SavingStatusIndicator,
  type SAVING_STATUS,
} from "components/common/SavingStatusIndicator/SavingStatusIndicator";
import { getBlockName } from "utils/helpers";
import { getStringValidationError } from "utils/validation";
import type { BlockType } from "models/canvas";

type BlockTitleProps = {
  blockId?: BlockType["id"];
  title: string;
  disableEdit: boolean;
  contentSavingStatus?: SAVING_STATUS;
  fontSize: number;
  onTitleChange?: (title: string) => void;
};

type NameSuggestionProps = {
  blockId?: BlockType["id"];
  onAccept: (value: string) => void;
};

const NameSuggestion = ({ blockId = "", onAccept }: NameSuggestionProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [suggestion, setSuggestion] = useState("");

  const aiModel = useUserSettingsState((slice) => slice.preferences.aiModel);
  const content = useCanvasBlocksState((slice) => slice.content[blockId]);

  useEffect(() => {
    if (content) {
      setIsLoading(true);
      canvasService
        .postSuggestName(content, aiModel)
        .then((res) => {
          setSuggestion(res.suggestion);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [content]);

  return (
    <div className={styles.suggestion}>
      <div className={styles.suggestion__title}>
        {isLoading ? "Loading..." : suggestion}
      </div>
      <Button
        variant="secondary"
        disabled={!suggestion}
        className={styles.suggestion__button}
        onClick={() => {
          onAccept(suggestion);
        }}
      >
        Accept
      </Button>
    </div>
  );
};

export default function BlockTitle({
  blockId = "",
  title,
  disableEdit,
  contentSavingStatus,
  fontSize,
  onTitleChange,
}: BlockTitleProps) {
  const getBlockContent = useCanvasBlocksState(
    (slice) => slice.getBlockContent
  );
  const [isEditing, setIsEditing] = useState(false);

  const blockType = useBlock(blockId, (block) => block.type);

  const [open, setOpen] = useState(false);
  const { x, y, refs, context } = useFloating({
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    placement: "top-start",
    middleware: [offset(10)],
  });

  useDismiss(context, {
    escapeKey: true,
    outsidePress: true,
  });

  const Icon = useMemo(() => {
    return blockType ? BLOCK_DETAILS[blockType]?.Icon : null;
  }, [blockType]);

  const cancelEdit = useCallback(() => {
    setIsEditing(false);
  }, []);

  const onAcceptandClose = useCallback(
    (title: string) => {
      onTitleChange?.(title);
      setOpen(false);
      isEditing && cancelEdit();
    },
    [onTitleChange, cancelEdit]
  );

  const inputPlaceholder = useMemo(() => {
    return getBlockName({
      type: blockType,
    });
  }, [blockType]);

  const handleEnableTitleEditingMode = useCallback(() => {
    setIsEditing(true);
  }, []);

  const handleSuggestTitle = useCallback(() => {
    if (disableEdit || !onTitleChange) {
      return;
    }
    const content = getBlockContent(blockId);
    if (content) {
      setOpen(true);
    }
  }, [disableEdit, blockId, onTitleChange]);

  const onTitleSubmit = useCallback(
    (name: string) => {
      onTitleChange?.(name);
      setTimeout(() => {
        cancelEdit();
      }, 100);
    },
    [onTitleChange, cancelEdit]
  );

  return (
    <>
      <div
        ref={refs.setReference}
        className={cn(styles.block_title, {
          // nodrag is needed to allow selecting text in the input
          // See: https://linear.app/zerve-ai/issue/FRO-1495/when-trying-to-edit-the-name-a-block-selected-text-is-un-deselectable
          nodrag: isEditing,
        })}
        style={{ fontSize: `${fontSize}px` }}
      >
        {Icon ? <Icon className={styles.block_title__icon} /> : null}

        {onTitleChange && isEditing && !disableEdit ? (
          <RenameInput
            placeholder={inputPlaceholder}
            defaultName={title}
            className={styles["block_title__input"]}
            onRenameSubmit={onTitleSubmit}
            onRenameCancel={cancelEdit}
            getError={(name) =>
              getStringValidationError("canvasBlockName", name)
            }
            floatingElPlacement="bottom-start"
            floatingElOffset={0}
          />
        ) : (
          <>
            <h3
              className={cn(styles.block_title__text, {
                [styles.viewOnly]: disableEdit,
              })}
              onClick={handleSuggestTitle}
              onDoubleClick={handleEnableTitleEditingMode}
            >
              {title}
            </h3>

            <SavingStatusIndicator
              status={contentSavingStatus}
              className={styles.savingStatusIndicator}
            />
          </>
        )}
      </div>

      {open && (
        <div
          ref={refs.setFloating}
          style={{ position: "absolute", top: y ?? 0, left: x ?? 0 }}
        >
          <NameSuggestion blockId={blockId} onAccept={onAcceptandClose} />
        </div>
      )}
    </>
  );
}
