import { useNavigate, useParams } from "react-router-dom";
import {
  type AssetItemOperationType,
  AssetPageTabUrl,
} from "pages/Assets/types/abstractTypes";
import {
  postFunction,
  postWorkspaceFunction,
  postFunctionVersion,
  putFunction,
} from "api/http/assets/functions-service";
import { useAssetsState, useToastsState } from "store";
import { useCallback, useMemo } from "react";
import { getAssetsBaseUrl } from "pages/Assets/utils";

export default function useCreatedFunctionsData() {
  const navigate = useNavigate();
  const { orgID, workspaceID } = useParams();
  const addToast = useToastsState((slice) => slice.addToast);

  const { createdFunctionData, functionsData, selectedFunction } =
    useAssetsState((slice) => slice.functions);

  const {
    setFunctionOperationType,
    setCreatedFunctionData,
    setSelectedFunctionVersionNumber,
    setSelectedFunctionId,
    setFunctionsData,
    setCreatedFunctionError,
    setCreatedFunctionLoading,
    clearSelectedFunction,
    updateFunctionVersion,
    clearCreatedFunctionData,
  } = useAssetsState((slice) => slice.functionsActions);

  const createdFunctionsData = {
    createdAssetItemData: createdFunctionData.functionData,
    assetOperationType: createdFunctionData.assetOperationType,
    createdAssetError: createdFunctionData.error,
    createdAssetLoading: createdFunctionData.loading,
  };

  const createFunction = useCallback(
    (requestData) => {
      if (workspaceID) {
        return postWorkspaceFunction(workspaceID, requestData);
      } else {
        return postFunction(requestData);
      }
    },
    [workspaceID]
  );

  const baseURL = useMemo(() => {
    return getAssetsBaseUrl({
      organizationId: orgID,
      workspaceId: workspaceID,
    });
  }, [orgID, workspaceID]);

  const createdFunctionsActions = {
    setAssetItemOperationType: (operationType: AssetItemOperationType) => {
      setFunctionOperationType(operationType);
      if (operationType === "create") {
        setCreatedFunctionData({
          name: "",
          language: "python",
        });

        clearSelectedFunction();
      }
    },
    setCreatedAssetItemData: (data) => {
      setCreatedFunctionData(data);
    },
    createAssetItem: () => {
      const requestData = {
        asset: {
          name: createdFunctionData.functionData.name,
        },
        version: {
          language: "python",
          // we'll need to get this from the store later on when we'll add multiple languages support
          // language: createdFunctionData.functionData.language,
        },
      };
      setCreatedFunctionLoading(true);
      void createFunction(requestData)
        .then((createdFuncVersion) => {
          const createdFunction = {
            function: {
              ...createdFuncVersion.asset,
            },
            versions: {
              [createdFuncVersion.version]: {
                ...createdFuncVersion,
              },
            },
          };
          setFunctionsData({
            ...functionsData,
            [createdFuncVersion.asset_id]: createdFunction,
          });
          setSelectedFunctionId(createdFuncVersion.asset_id);
          setSelectedFunctionVersionNumber(createdFuncVersion.version);
          navigate(
            `${baseURL}/${AssetPageTabUrl.function}/${
              createdFuncVersion.id as string
            }`
          );
          setCreatedFunctionData({
            name: "",
            language: "python",
          });
          setFunctionOperationType(null);
          addToast({
            message: "Success! New function has been successfully created.",
            variant: "success",
          });
        })
        .catch((error) => {
          setCreatedFunctionError(error.message);
        })
        .finally(() => {
          setCreatedFunctionLoading(false);
        });
    },
    editAssetItem: () => {},
    updateAssetItemName: () => {
      const { name, id } = createdFunctionData.functionData;

      if (!id || functionsData[id]?.function?.name === name) {
        clearCreatedFunctionData();

        return;
      }
      setCreatedFunctionLoading(true);
      void putFunction(id, { name })
        .then((responseData) => {
          const editedFunctionData = functionsData[id];
          setFunctionsData({
            ...functionsData,
            [id]: {
              ...editedFunctionData,
              function: {
                ...editedFunctionData.function,
                name: responseData.name,
              },
            },
          });
          addToast({
            message: "Success! Function name has been successfully updated.",
            variant: "success",
          });
        })
        .catch((error) => {
          addToast({
            message: error?.message ?? "Failed to update function name",
            variant: "error",
          });
        })
        .finally(() => {
          clearCreatedFunctionData();
          setCreatedFunctionLoading(false);
        });
    },
    addNewVersion: (prevVersionData) => {
      if (selectedFunction.id) {
        void postFunctionVersion({
          asset_id: selectedFunction.id,
          language: "python",
          ...(prevVersionData ?? {}),
        }).then((createdVersion) => {
          updateFunctionVersion(createdVersion);
          setSelectedFunctionVersionNumber(createdVersion.version);
          navigate(
            `${baseURL}/${AssetPageTabUrl.function}/${
              createdVersion.id as string
            }`
          );
        });
      }
    },
    setCreatedAssetError: (error) => {
      setCreatedFunctionError(error);
    },
    clearCreatedAsset: () => {
      clearCreatedFunctionData();
    },
  };

  return {
    createdFunctionsData,
    createdFunctionsActions,
  };
}
