import { lazy } from "react";
import { createBrowserRouter, Navigate, Outlet } from "react-router-dom";
import { App } from "App";
import { AuthLogin } from "pages/AuthLogin/AuthLogin";
import VerifyEmail from "pages/VerifyEmail/VerifyEmail";
import ErrorPage from "pages/ErrorPage/ErrorPage";
import { UserSettings } from "pages/UserSettings/UserSettings";
import { CreateOrganization } from "pages/CreateOrganization/CreateOrganization";
import { Login } from "pages/Login/Login";
import { Logout } from "pages/Logout/Logout";
import { SignUp } from "pages/SignUp/SignUp";
import { AssetsPages } from "layout/AssetsPages/AssetsPages";
import { NotificationsPage } from "layout/NotificationsPage/NotificationsPage";
import { Auth } from "layout/Auth/Auth";
import { UserSignIn } from "layout/UserSignIn/UserSignIn";
import { CanvasAuth } from "layout/CanvasAuth/CanvasAuth";
import { CanvasesPage } from "layout/CanvasesPage/CanvasesPage";
import { PublishedReport } from "pages/PublishedReport/PublishedReport";
import { OnBoarding } from "layout/OnBoarding/OnBoarding";
import { EnvironmentsPages } from "layout/EnvironmentsPages/EnvironmentsPages";
import { Layout } from "layout/Layout/Layout";
import { PersonalTabs } from "layout/tabs/PersonalTabs";
import { OrganizationTabs } from "layout/tabs/OrganizationTabs";
import { WorkspaceTabs } from "layout/tabs/WorkspaceTabs";
import { PERSONAL_CANVASES_ROUTES } from "./personal/canvases";
import { PERSONAL_ENVIRONMENTS_ROUTES } from "./personal/environments";
import { PERSONAL_ASSETS_ROUTES } from "./personal/assets";
import { PERSONAL_SETTINGS_ROUTES } from "./personal/settings";
import { ORGANIZATION_ROUTES } from "./organization/org";
import { ORGANIZATION_WORKSPACE_ROUTES } from "./organization/workspace";

const CanvasLayout = lazy(() => import("layout/CanvasLayout/CanvasLayout"));

export default createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      // Auth0 login pages
      {
        path: "auth/login",
        element: <AuthLogin />,
      },
      {
        path: "auth/verify-email",
        element: <VerifyEmail />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "logout",
        element: <Logout />,
      },
      {
        path: "signup",
        element: <SignUp />,
      },
      {
        element: <CanvasAuth />,
        children: [
          {
            path: "canvas/:canvasId",
            element: <CanvasLayout />,
          },
        ],
      },
      // Published report page
      {
        path: "report/:reportName",
        element: <PublishedReport />,
      },
      // Gets the token from Auth0
      {
        element: <Auth />,
        children: [
          // Gets the user's data from the Zerve Backend
          {
            element: (
              <UserSignIn>
                <Outlet />
              </UserSignIn>
            ),
            children: [
              // Checks if the user is new and if so shows the onboarding modal
              {
                element: <OnBoarding />,
                children: [
                  // Shows the main app layout
                  {
                    element: <Layout />,
                    children: [
                      {
                        element: <PersonalTabs />,
                        children: [
                          {
                            index: true,
                            element: <Navigate replace to="canvases" />,
                          },
                          {
                            path: "canvases",
                            element: <CanvasesPage />,
                            children: PERSONAL_CANVASES_ROUTES,
                          },
                          {
                            path: "environments",
                            element: <EnvironmentsPages />,
                            children: PERSONAL_ENVIRONMENTS_ROUTES,
                          },
                          {
                            path: "assets",
                            element: <AssetsPages />,
                            children: PERSONAL_ASSETS_ROUTES,
                          },
                          {
                            path: "notifications",
                            element: <NotificationsPage />,
                          },
                          {
                            path: "settings",
                            element: <UserSettings />,
                            children: PERSONAL_SETTINGS_ROUTES,
                          },
                          {
                            // TODO: convert to a modal window
                            path: "organization/create",
                            element: <CreateOrganization />,
                          },
                        ],
                      },
                      {
                        path: "org/:orgID",
                        element: <OrganizationTabs />,
                        children: ORGANIZATION_ROUTES,
                      },
                      {
                        path: "org/:orgID/workspaces/:workspaceID",
                        element: <WorkspaceTabs />,
                        children: ORGANIZATION_WORKSPACE_ROUTES,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
]);
