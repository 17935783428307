import styles from "./SocialMediaLinks.module.scss";
import { useMemo, type FC, type ChangeEvent } from "react";
import { BiLinkAlt } from "react-icons/bi";
import TextField from "components/common/TextField/TextField";
import Typography from "components/Typography/Typography";

const textFieldSharedProps = Object.freeze({
  placeholder: "Link to your social media profile...",
  containerClassName: styles.textFieldContainer,
  leftIcon: BiLinkAlt,
});

interface SocialMediaLinksProps {
  itemsCount?: number;
  values?: string[];
  disabled?: boolean;
  onLinksChange: (newData: string[]) => void;
}
const SocialMediaLinks: FC<SocialMediaLinksProps> = ({
  itemsCount = 4,
  values = [],
  disabled = false,
  onLinksChange,
}: SocialMediaLinksProps) => {
  const rowsNumbers = useMemo(() => {
    return Array.from({ length: itemsCount }, (v, i) => i);
  }, []);

  const handleLinkChange = (
    e: ChangeEvent<HTMLInputElement>,
    itemNumber: number
  ) => {
    let newValues: string[] = [];

    if (values.length >= itemsCount) {
      newValues = [...values];
      newValues[itemNumber] = e.target.value;
    } else {
      for (let index = 0; index < itemNumber; index++) {
        newValues[index] = values[index] || "";
      }

      newValues[itemNumber] = e.target.value;
    }

    onLinksChange(newValues);
  };

  return (
    <div className={styles.wrapper}>
      <Typography variant="h2">Social Media Links</Typography>
      <Typography variant="body2" className={styles.subTitle}>
        Leave a link to your social media at Zerve.
      </Typography>
      {rowsNumbers.map((rowNumber) => {
        return (
          <TextField
            key={rowNumber}
            {...textFieldSharedProps}
            value={values[rowNumber] ?? ""}
            onChange={(e) => {
              handleLinkChange(e, rowNumber);
            }}
            isDisabled={disabled}
          />
        );
      })}
    </div>
  );
};

export default SocialMediaLinks;
