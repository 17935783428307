import { useState, useMemo } from "react";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  type SortingState,
  getSortedRowModel,
} from "@tanstack/react-table";
import { BiChevronDown, BiChevronUp, BiDownload } from "react-icons/bi";
import cn from "classnames";

import Typography from "components/Typography";
import styles from "./PaymentHistory.module.scss";
import { type TableHistory } from "models/settings/billing-interface";

interface PaymentHistoryProps {
  historyData: TableHistory[];
  className?: string;
}

const SORT_ORDER_ICON_MAP = {
  asc: <BiChevronUp size={16} />,
  desc: <BiChevronDown size={16} />,
};

const PaymentHistory = ({ className, historyData }: PaymentHistoryProps) => {
  const [sorting, setSorting] = useState<SortingState>([]);

  const getStatus = (status: string) => {
    return (
      <Typography
        component="h3"
        variant="shortcuts"
        className={cn(
          styles["status"],
          styles[`status__${status.toLowerCase()}`]
        )}
      >
        {status}
      </Typography>
    );
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "plan_name",
        header: "Plan",
        cell: (info) => {
          return (
            <Typography component="span" variant="body1">
              {info.row.original.plan_name}
            </Typography>
          );
        },
      },
      {
        accessorKey: "created",
        header: "Date",
        cell: (info) => {
          return (
            <Typography component="span" variant="body1">
              {info.row.original.created}
            </Typography>
          );
        },
      },
      {
        accessorKey: "payment_amount",
        header: "Payment Amount",
        cell: (info) => {
          return (
            <Typography component="span" variant="body1">
              ${info.row.original.payment_amount / 100} USD
            </Typography>
          );
        },
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: (info) => {
          return getStatus(info.row.original.status);
        },
      },
      {
        accessorKey: "invoice_pdf",
        header: "Invoice",
        cell: (info) => {
          const url = info.row.original.invoice_pdf;
          const splittedUrl = url.split("/");
          const invoiceId = splittedUrl[splittedUrl.length - 2];

          return (
            <div className={styles["invoice"]}>
              <Typography component="span" variant="body1">
                {invoiceId}
              </Typography>
              <a href={url} download>
                <BiDownload size={16} className={styles["invoice__icon"]} />
              </a>
            </div>
          );
        },
      },
    ],
    []
  );

  const table = useReactTable({
    data: historyData,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <table className={cn(styles["table"], className)}>
      <thead className={styles["table__header"]}>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th key={header.id} className={styles["table__header__cell"]}>
                {!header.isPlaceholder && (
                  <div
                    onClick={header.column.getToggleSortingHandler()}
                    className={styles["table__header__label"]}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </div>
                )}
                {SORT_ORDER_ICON_MAP[header.column.getIsSorted() as string] ??
                  null}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr key={row.id} className={styles["table__row"]}>
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id} className={styles["table__cell"]}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default PaymentHistory;
