import styles from "./Button.module.scss";
import {
  forwardRef,
  type PropsWithChildren,
  type DOMAttributes,
  type CSSProperties,
} from "react";
import cn from "classnames";

export type ButtonProps = PropsWithChildren<{
  variant?: "primary" | "secondary" | "outline" | "crucial" | "text" | "plain";
  type?: "button" | "submit" | "reset";
  size?: "large" | "small";
  className?: string;
  disabled?: boolean;
  style?: CSSProperties;
  onClick?: DOMAttributes<HTMLButtonElement>["onClick"];
}>;

const ButtonComponent = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      variant = "primary",
      type = "button",
      size = "small",
      disabled = false,
      className,
      style,
      onClick,
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        type={type}
        disabled={disabled}
        className={cn(
          styles.button,
          {
            [styles.typePrimary]: variant === "primary",
            [styles.typeSecondary]: variant === "secondary",
            [styles.typeOutline]: variant === "outline",
            [styles.typeCrucial]: variant === "crucial",
            [styles.typeText]: variant === "text",
            [styles.typePlain]: variant === "plain",
            [styles.sizeSmall]: size === "small",
            [styles.sizeLarge]: size === "large",
          },
          className
        )}
        style={style}
        onClick={onClick}
      >
        {children}
      </button>
    );
  }
);

ButtonComponent.displayName = "Button";

export const Button = ButtonComponent;
