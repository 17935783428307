import { useAssetsState } from "store";
import ConnectionForm from "../ConnectionForm";
import { CONNECTION_TYPES } from "../../constants";
import styles from "./CreateConnectionConfigForm.module.scss";

const CreateConnectionConfigForm = () => {
  const { connectionTypes, createdItem } = useAssetsState(
    (slice) => slice.connections
  );

  const {
    data: { connection_type_id, name },
  } = createdItem;

  const connectionType = connection_type_id
    ? connectionTypes[connection_type_id]?.connection_type
    : null;

  const isSupportedConnectionType =
    connectionType && CONNECTION_TYPES.includes(connectionType);

  if (!isSupportedConnectionType) {
    return null;
  }

  return (
    <div className={styles.container}>
      <ConnectionForm connectionType={connectionType} data={{ name }} />
    </div>
  );
};

export default CreateConnectionConfigForm;
