import styles from "./GroupSettings.module.scss";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams, useNavigate } from "react-router-dom";
import { BiChevronLeft } from "react-icons/bi";
import { useToastsState } from "store";
import { useModal } from "hooks/useModal";
import { Button } from "components/common/Button/Button";
import { LinkButton } from "components/common/Button/LinkButton";
import TextField from "components/common/TextField/TextField";
import Spinner from "components/common/Spinner/Spinner";
import Loader from "components/common/Loader/Loader";
import Tooltip from "components/common/Tooltip/Tooltip";
import Typography from "components/Typography/Typography";
import { TitleSection } from "components/organization/TitleSection/TitleSection";
import {
  getGroup,
  patchGroup,
  deleteGroup,
  getGroups,
} from "api/http/group-service";
import { getOrganization } from "api/http/organization-service";
import { GroupMembers } from "pages/GroupMembers/GroupMembers";
import { DeleteGroupModal } from "pages/GroupSettings/DeleteGroupModal";
import { debouncePromise } from "utils/helpers";
import type { Group } from "pages/OrganizationHome/types";
import type { Organization } from "models/organization";

export function GroupSettings() {
  const addToast = useToastsState((slice) => slice.addToast);
  const { groupID = "", orgID = "" } = useParams();
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();

  const [group, setGroup] = useState<Group>();
  const [groups, setGroups] = useState<Group[]>([]);
  const [organization, setOrganization] = useState<Organization>();
  const [isGroupSaving, setIsGroupSaving] = useState(false);

  // Fetch users group when the component loads.
  useEffect(() => {
    async function initializeGroup() {
      const groupsResult = await getGroup(orgID, groupID);
      if (groupsResult) {
        // store group
        setGroup(groupsResult);
        formik.setValues({ ...groupsResult });
        formik.resetForm({
          values: {
            ...groupsResult,
          },
        });
      }
      const allGroups = await getGroups(orgID);
      setGroups(allGroups);
      const organizationResult = await getOrganization(orgID);
      if (organizationResult) {
        setOrganization(organizationResult);
      }
    }
    if (groupID) {
      initializeGroup();
    }
  }, [groupID]);

  // Delete the group.
  const handleDeleteGroup = () => {
    deleteGroup(orgID, groupID)
      .then(() => {
        closeModal();
        formik.resetForm({
          values: {
            name: "",
          },
        });
        addToast({
          message: "Group successfully deleted",
          variant: "success",
        });
        navigate(`/org/${orgID}/groups`);
      })
      .catch((error) => {
        if (error.cause) {
          formik.setErrors(error.cause);
        }
        addToast({
          message: error.message,
          variant: "error",
        });
      });
  };

  // Save the group. This function calls the relevant API depending on
  // whether the group is new or it is updating an existing group.
  const onSubmit = (values) => {
    if (!isGroupSaving) {
      setIsGroupSaving(true);
      debouncePromise({
        promise: patchGroup(orgID, groupID, {
          name: values.name,
          organization_id: orgID,
        }),
      })
        .then((returnedValues) => {
          addToast({
            message: "Group name successfully updated",
            variant: "success",
          });
          formik.resetForm({
            values: {
              ...returnedValues,
            },
          });
          setGroup(returnedValues);
          setIsGroupSaving(false);
        })
        .catch((error) => {
          if (error.cause) {
            formik.setErrors(error.cause);
          }
          addToast({
            message: error.message,
            variant: "error",
          });
          setIsGroupSaving(false);
        });
    }
  };

  const onDelete = () => {
    openModal({
      title: "Delete Group",
      content: () => (
        <DeleteGroupModal
          onCancelDelete={closeModal}
          onDeleteGroup={handleDeleteGroup}
        />
      ),
    });
  };

  // Check if a user is an admin of the organization
  const isOrganizationAdmin = () => {
    if (organization?.role === "Organization Admin") {
      return true;
    }
    return false;
  };

  // Yup validation schema
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .matches(
        /^[a-zA-Z0-9-_ ]+$/,
        "Only alphanumeric characters, spaces, underscores and dashes are allowed"
      )
      .max(64, "Must be 64 characters or less")
      .required("Group name is required")
      .test("unique-name", "Group name must be unique", function (value) {
        if (!value) {
          return true;
        }

        // Convert the input value to lowercase for case-insensitive comparison
        const lowercaseValue = value.toLowerCase();

        // Check if the name already exists in the list of existing groups
        const nameExists = groups?.some(
          (existingGroup) =>
            existingGroup.name.toLowerCase() === lowercaseValue &&
            existingGroup.id !== groupID
        );

        // Return false if the name already exists, true otherwise
        return !nameExists;
      }),
  });

  // Initialize formik.
  const formik = useFormik({
    initialValues: {
      name: group?.name || "",
    },
    validationSchema,
    onSubmit,
  });

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        {!organization || !group ? (
          <Spinner />
        ) : (
          <>
            <TitleSection
              title={group.name}
              leftControls={
                <Tooltip text="Back to Groups" placement="top" withArrow>
                  <LinkButton
                    to={`/org/${orgID}/groups`}
                    variant="outline"
                    className={styles.backButton}
                  >
                    <BiChevronLeft size="24" />
                  </LinkButton>
                </Tooltip>
              }
            />

            {/* Only show the editable settings for the Organization Admin role */}
            {isOrganizationAdmin() ? (
              <section className={styles.section}>
                <Typography variant="h1">Group Settings</Typography>
                <Typography variant="body2" className={styles.subHeading}>
                  Update the name of this group.
                </Typography>

                <form onSubmit={formik.handleSubmit} className={styles.form}>
                  <TextField
                    id="name"
                    name="name"
                    label="Group Name"
                    placeholder={"Name of group..."}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    error={formik.errors.name}
                    outerControls={
                      <Button
                        size="large"
                        type="submit"
                        className={styles.updateGroupButton}
                        disabled={
                          !formik.isValid || !formik.dirty || isGroupSaving
                        }
                      >
                        {isGroupSaving ? <Loader /> : <span>Save</span>}
                      </Button>
                    }
                  />
                </form>
              </section>
            ) : null}

            {/* Group members is displayed regardless of what the permission level is */}
            <section className={styles.section}>
              <GroupMembers organization={organization} />
            </section>

            {/* Only show the delete group button for the Organization Admin role */}
            {isOrganizationAdmin() && groupID && (
              <div className={styles.deleteWrapper}>
                <div className={styles.deleteExplanationText}>
                  <Typography variant="h2">Delete Group</Typography>
                  <Typography variant="body2" className={styles.subTitle}>
                    This action will permanently delete this group and all of
                    it&#39;s data. There is no going back. Please be certain.
                  </Typography>
                </div>
                <Button
                  variant="crucial"
                  onClick={() => {
                    onDelete();
                  }}
                >
                  Delete Group
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
