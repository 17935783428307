import styles from "./ConfirmModal.module.scss";
import { useState, type PropsWithChildren, useCallback } from "react";
import { Button } from "components/common/Button/Button";
import { Checkbox } from "components/common/Checkbox/Checkbox";
import Typography from "components/Typography/Typography";
import type { PropsWithModal } from "hooks/useModal";
import type { ButtonProps } from "components/common/Button/Button";

export type ConfirmModalProps = PropsWithChildren<
  PropsWithModal<{
    cancelButtonVariant?: ButtonProps["variant"];
    confirmButtonVariant?: ButtonProps["variant"];
    cancelButtonLabel?: string;
    confirmButtonLabel?: string;
    onCancel: () => void;
    onConfirm: () => void;
    onSetDontAskAgain?: (dontAskAgain: boolean) => void;
  }>
>;

export function ConfirmModal({
  children,
  cancelButtonVariant = "secondary",
  confirmButtonVariant = "primary",
  cancelButtonLabel = "Cancel",
  confirmButtonLabel = "Confirm",
  onCancel,
  onConfirm,
  onSetDontAskAgain,
  onModalClose,
}: ConfirmModalProps) {
  const [dontAskAgain, setDontAskAgain] = useState(false);

  const handleConfirm = useCallback(() => {
    onSetDontAskAgain?.(dontAskAgain);
    onConfirm();
    onModalClose();
  }, [dontAskAgain, onConfirm, onSetDontAskAgain, onModalClose]);

  const handleCancel = useCallback(() => {
    onCancel();
    onModalClose();
  }, [onCancel, onModalClose]);

  return (
    <>
      {/* Message */}
      <Typography variant="body2" className={styles.message}>
        {children}
      </Typography>

      <div className={styles.controls}>
        {/* "Don't ask again" checkbox */}
        {onSetDontAskAgain ? (
          <Checkbox
            className={styles.dontAskAgainCheckbox}
            checked={dontAskAgain}
            onChange={(e) => {
              setDontAskAgain(e.target.checked);
            }}
            label="Don't ask me again"
          />
        ) : null}

        {/* Cancel */}
        <Button
          size="large"
          variant={cancelButtonVariant}
          onClick={handleCancel}
        >
          {cancelButtonLabel}
        </Button>

        {/* Confirm */}
        <Button
          size="large"
          variant={confirmButtonVariant}
          onClick={handleConfirm}
        >
          {confirmButtonLabel}
        </Button>
      </div>
    </>
  );
}
