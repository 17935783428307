import { CANVAS_API_URL } from "config/appConfig";
import { DEPLOYMENT_TYPE } from "config/deploymentConfig";
import { callAPI } from "./call-api";
import type { LayerType, BlockType } from "models/canvas";
import type {
  Deployment,
  LogResponse,
  PayloadSchema,
} from "models/deployments";

const MAX_LOGS_PER_PAGE = 500;

export const putAPIControllerProperties = async (
  block_id: BlockType["id"],
  properties: {
    dns_name: string;
    api_key: string;
    file_names: string[];
  }
) =>
  await callAPI({
    method: "PUT",
    url: `${CANVAS_API_URL}/api_builder/api_controller/${block_id}`,
    data: properties,
  });

export const putAPIRouteProperties = async (
  block_id: BlockType["id"],
  properties: {
    route_name: string;
    method: string;
    auth_enabled: boolean;
    model?: PayloadSchema[];
    response_model?: string[];
  }
) =>
  await callAPI({
    method: "PUT",
    url: `${CANVAS_API_URL}/api_builder/api_route/${block_id}`,
    data: properties,
  });

export const postDeployAPI = async ({
  canvasId,
  layerId,
}: {
  canvasId: string;
  layerId: LayerType["id"];
}) => {
  return await callAPI({
    method: "POST",
    url: `${CANVAS_API_URL}/api_builder/${canvasId}/deploy/${layerId}`,
    data: {
      deployment_type: DEPLOYMENT_TYPE.API,
    },
  });
};

export const postDeploySageMaker = async ({
  canvasId,
  layerId,
}: {
  canvasId: string;
  layerId: LayerType["id"];
}) => {
  return await callAPI({
    method: "POST",
    url: `${CANVAS_API_URL}/api_builder/${canvasId}/deploy/${layerId}`,
    data: {
      deployment_type: DEPLOYMENT_TYPE.SAGEMAKER,
    },
  });
};

export const downloadAPISource = async ({ api_id }: { api_id: string }) => {
  const response = await callAPI<{ presigned_url: string }>({
    url: `${CANVAS_API_URL}/user_api/${api_id}`,
    method: "GET",
  });
  return response;
};

export const downloadApiDoc = async ({ api_id }: { api_id: string }) => {
  const response = await callAPI<{ presigned_url: string }>({
    url: `${CANVAS_API_URL}/user_api/doc/${api_id}`,
    method: "GET",
  });
  return response;
};

export const fetchDeployment = async <T extends Deployment>({
  canvasId,
  deploymentId,
}: {
  canvasId: string;
  deploymentId: Deployment["id"];
}) => {
  return await callAPI<T>({
    url: `${CANVAS_API_URL}/exec/${canvasId}/deployments/${deploymentId}`,
    method: "GET",
  });
};

export const undeployDeployment = async ({
  canvasId,
  layerId,
}: {
  canvasId: string;
  layerId: LayerType["id"];
}) => {
  return await callAPI({
    url: `${CANVAS_API_URL}/api_builder/${canvasId}/undeploy/${layerId}`,
    method: "POST",
  });
};

type FetchLogParams = {
  canvasId: string;
  deploymentId: Deployment["id"];
  nextToken?: string | null;
  searchQuery?: string;
  signal?: AbortSignal;
};

export const fetchDeploymentBuildLogs = async ({
  canvasId,
  deploymentId,
  nextToken,
  searchQuery,
  signal,
}: FetchLogParams) => {
  const query = new URLSearchParams({
    next_token: nextToken || "",
    limit: MAX_LOGS_PER_PAGE.toString(),
    search_query: searchQuery || "",
  });
  return await callAPI<LogResponse>({
    url: `${CANVAS_API_URL}/exec/${canvasId}/deployments/${deploymentId}/build_logs?${query.toString()}`,
    method: "GET",
    signal,
  });
};

export const fetchDeploymentRuntimeLogs = async ({
  canvasId,
  deploymentId,
  nextToken,
  searchQuery,
  signal,
}: FetchLogParams) => {
  const query = new URLSearchParams({
    next_token: nextToken || "",
    limit: MAX_LOGS_PER_PAGE.toString(),
    search_query: searchQuery || "",
  });
  return await callAPI<LogResponse>({
    url: `${CANVAS_API_URL}/exec/${canvasId}/deployments/${deploymentId}/runtime_logs?${query.toString()}`,
    method: "GET",
    signal,
  });
};
