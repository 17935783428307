import { useCallback, useState } from "react";
import isEmpty from "lodash-es/isEmpty";
import { type FormikErrors } from "formik";
import { useToastsState } from "store";
import useConnectionActions from "hooks/assets/useConnectionActions";
import type {
  Connection,
  ConnectionConfigurationFieldKey,
} from "pages/Assets/types/connectionTypes";
import { CONNECTION_CONFIG_FIELD_CONFIG } from "../../constants";

const getFormErrorsFromResponseValidationErrors = (
  validationError: Partial<Record<string, string>>,
  formFields: ConnectionConfigurationFieldKey[]
) => {
  if (isEmpty(validationError)) {
    return;
  }

  return formFields.reduce((res, field) => {
    const error = validationError?.[`value.${field}`];

    if (error) {
      res[field] = error;
    }

    return res;
  }, {});
};

const transformFormValuesToConnectionConfig = (
  values: Connection["value"],
  formFields: ConnectionConfigurationFieldKey[]
): Connection["value"] => {
  return formFields.reduce((res, field) => {
    const fieldConfig = CONNECTION_CONFIG_FIELD_CONFIG[field];

    if (fieldConfig) {
      const { type, skipInRequestWhenIsEmpty } = fieldConfig;
      const value = values[field];

      if (type === "number" && value && typeof value === "string") {
        res[field] = Number(value);

        return res;
      }

      if (!value && skipInRequestWhenIsEmpty) {
        return res;
      }

      res[field] = values[field];
    }

    return res;
  }, {});
};

const useFormActions = () => {
  const [isSubmitActionInProgress, setIsSubmitActionInProgress] =
    useState(false);
  const [isTestActionInProgress, setIsTestActionInProgress] = useState(false);

  const addToast = useToastsState((slice) => slice.addToast);
  const { testConnection, updateConnection } = useConnectionActions();

  const submitForm = useCallback(
    async ({
      formId,
      values,
      formFields,
      onSuccess,
      onError,
    }: {
      formId?: string;
      values: Connection["value"];
      formFields: ConnectionConfigurationFieldKey[];
      onSuccess?: () => void;
      onError: (errors: FormikErrors<Record<string, unknown>>) => void;
    }) => {
      if (!formId) {
        return;
      }

      try {
        setIsSubmitActionInProgress(true);
        const data = transformFormValuesToConnectionConfig(values, formFields);
        await updateConnection(formId, data);
        onSuccess?.();
      } catch (error) {
        let errorMessage = "Failed to save connection configuration";

        if (error instanceof Error) {
          if (error?.message) {
            errorMessage = error.message;
          }

          if (error?.cause) {
            const formErrors = getFormErrorsFromResponseValidationErrors(
              error.cause,
              formFields
            );

            if (formErrors && !isEmpty(formErrors)) {
              onError(formErrors);
            }
          }
        }

        addToast({
          message: errorMessage,
          variant: "error",
          duration: 4000,
        });
      } finally {
        setIsSubmitActionInProgress(false);
      }
    },
    [updateConnection]
  );
  // to be changed when related API is provided
  const testForm = useCallback(async (formId?: string) => {
    if (!formId) {
      return;
    }

    try {
      setIsTestActionInProgress(true);
      await testConnection(formId);
    } catch (error) {
      console.error(error);
    } finally {
      setIsTestActionInProgress(false);
    }
  }, []);

  return {
    isSubmitActionInProgress,
    isTestActionInProgress,
    submitForm,
    testForm,
  };
};

export default useFormActions;
