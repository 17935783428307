import { type PropsWithChildren } from "react";
import cn from "classnames";
import styles from "./MutedMenuOptionContent.module.scss";

type MutedMenuOptionContentProps = PropsWithChildren<{
  className?: string;
}>;

const MutedMenuOptionContent = ({
  className = "",
  children,
}: MutedMenuOptionContentProps) => {
  return <div className={cn(styles.container, className)}>{children}</div>;
};

export default MutedMenuOptionContent;
