import { ASSET_API_URL } from "config/appConfig";
import { callAPI } from "../call-api";
import type {
  ConstantType,
  ConstantVersionType,
  ConstantItemType,
} from "pages/Assets/types/constantTypes";

const getUserConstants = async () => {
  return await callAPI({
    url: `${ASSET_API_URL}/constants/user`,
    method: "GET",
  });
};

const getWorkspaceConstants = async (workspaceId: string) => {
  return await callAPI({
    url: `${ASSET_API_URL}/constants/workspace/${workspaceId}`,
    method: "GET",
  });
};

const postConstant = async (data: any) =>
  await callAPI({
    url: `${ASSET_API_URL}/constants`,
    method: "POST",
    data,
  });

const postWorkspaceConstant = async (workspaceId: string, data: any) =>
  await callAPI({
    url: `${ASSET_API_URL}/constants/workspace/${workspaceId}`,
    method: "POST",
    data,
  });

const putConstant = async (
  id: string,
  data: {
    name: string;
  }
) =>
  await callAPI<ConstantType>({
    url: `${ASSET_API_URL}/constants/${id}`,
    method: "PUT",
    data,
  });

const deleteConstantById = async (assetId: string) =>
  await callAPI({
    url: `${ASSET_API_URL}/constants/${assetId}`,
    method: "DELETE",
  });

const postConstantVersion = async (data: {
  secret: boolean;
  value: string;
  description: string;
  asset_id: string;
}) =>
  await callAPI({
    url: `${ASSET_API_URL}/constants/version`,
    method: "POST",
    data,
  });

// usually I put method in the beginning of the function name but since patch and put is close to avoid confusion its edit and create
const editConstantVersion = async (
  assetVersionId: string,
  data: {
    id: string;
    value?: string;
    description?: string;
    secret?: boolean;
  }
) =>
  await callAPI({
    url: `${ASSET_API_URL}/constants/version/${assetVersionId}`,
    method: "PATCH",
    data,
  });

const createConstantVersion = async (data: {
  secret: boolean;
  value: string;
  description: string;
  asset_id: string;
}) =>
  await callAPI({
    url: `${ASSET_API_URL}/constants/version`,
    method: "POST",
    data,
  });

const deleteConstantVersion = async (assetVersionId: string) =>
  await callAPI({
    url: `${ASSET_API_URL}/constants/version/${assetVersionId}`,
    method: "DELETE",
  });

const putConstantVersionStatus = async (
  assetVersionId: string,
  status: number
) =>
  await callAPI({
    url: `${ASSET_API_URL}/constants/version/${assetVersionId}/status`,
    method: "PUT",
    data: {
      status,
    },
  });

const putArchiveConstantVersion = async (
  assetVersionId: string,
  archive: boolean
) =>
  await callAPI<ConstantVersionType>({
    url: `${ASSET_API_URL}/constants/version/${assetVersionId}/archive`,
    method: "PUT",
    data: {
      archive,
    },
  });

const putConstantVersionSecret = async (assetVersionId: string) =>
  await callAPI({
    url: `${ASSET_API_URL}/constants/version/${assetVersionId}/secret`,
    method: "PUT",
  });

const putConstantAssetArchive = async (data: {
  asset_id: string;
  archive: boolean;
}) =>
  await callAPI<Record<string, ConstantItemType>>({
    url: `${ASSET_API_URL}/constants/${data.asset_id}/archive`,
    method: "PUT",
    data,
  });

export {
  getUserConstants,
  getWorkspaceConstants,
  postConstant,
  postWorkspaceConstant,
  putConstant,
  deleteConstantById,
  postConstantVersion,
  editConstantVersion,
  createConstantVersion,
  deleteConstantVersion,
  putConstantVersionStatus,
  putArchiveConstantVersion,
  putConstantVersionSecret,
  putConstantAssetArchive,
};
