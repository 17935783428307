import styles from "./TextField.module.scss";
import {
  forwardRef,
  useRef,
  type RefObject,
  type ReactNode,
  type ChangeEvent,
  type FocusEvent,
  type KeyboardEvent,
  useCallback,
} from "react";
import { BiCaretUp, BiCaretDown } from "react-icons/bi";
import { type IconType } from "react-icons";
import cn from "classnames";
import Typography from "components/Typography/Typography";

type TextFieldRef = HTMLInputElement | HTMLTextAreaElement;

export type TextFieldProps = {
  readOnly?: boolean;
  autoFocus?: boolean;
  id?: string;
  name?: string;
  value?: string | number;
  defaultValue?: string;
  label?: string;
  placeholder?: string;
  helperText?: string;
  containerClassName?: string;
  labelClassName?: string;
  inputClassName?: string;
  helperTextClassName?: string;
  type?: "text" | "number" | "textarea" | "password";
  min?: number;
  max?: number;
  step?: number;
  leftIcon?: IconType;
  innerControls?: ReactNode | null;
  outerControls?: ReactNode | null;
  required?: boolean;
  isDisabled?: boolean;
  error?: string;
  onChange?: (event: ChangeEvent<any>) => void;
  onBlur?: (event: FocusEvent<any>) => void;
  onFocus?: (event: FocusEvent<any>) => void;
  onKeyDown?: (event: KeyboardEvent<any>) => void;
};

const TextField = forwardRef<TextFieldRef, TextFieldProps>((props, ref) => {
  const fallbackRef = useRef<TextFieldRef>(null);
  const elementRef = ref || fallbackRef;

  const {
    readOnly = false,
    autoFocus = false,
    id,
    name,
    value,
    defaultValue,
    label,
    placeholder,
    helperText,
    containerClassName = "",
    labelClassName = "",
    inputClassName = "",
    helperTextClassName = "",
    type = "text",
    min,
    max,
    step,
    leftIcon,
    innerControls = null,
    outerControls = null,
    required,
    isDisabled,
    error,
    onChange,
    onBlur,
    onFocus,
    onKeyDown,
  } = props;
  const LeftIcon = leftIcon;

  const handleOnNumberUp = () => {
    if (typeof elementRef !== "function") {
      const numberInput = (elementRef as RefObject<HTMLInputElement>).current;
      numberInput?.stepUp();
      numberInput?.dispatchEvent(new Event("change", { bubbles: true }));
    }
  };

  const handleOnNumberDown = () => {
    if (typeof elementRef !== "function") {
      const numberInput = (elementRef as RefObject<HTMLInputElement>).current;
      numberInput?.stepDown();
      numberInput?.dispatchEvent(new Event("change", { bubbles: true }));
    }
  };

  const renderInfoText = useCallback(
    (text: string, isError: boolean) => (
      <Typography
        variant="body2"
        className={cn(styles.helperText, helperTextClassName, {
          [styles.helperTextError]: isError,
        })}
      >
        {text}
      </Typography>
    ),
    []
  );

  return (
    <div className={cn(styles.container, containerClassName)}>
      {label ? (
        <Typography
          variant="h3"
          component="label"
          className={cn(styles.label, labelClassName)}
        >
          {label}
        </Typography>
      ) : null}

      <div className={styles.inputRow}>
        {type !== "textarea" ? (
          <div className={styles.inputWrapper}>
            {leftIcon ? (
              <>
                {/* @ts-expect-error legacy code before eslint */}
                <LeftIcon className={styles.leftIcon} />
                <div className={styles.leftDivider} />
              </>
            ) : null}
            <input
              ref={elementRef as RefObject<HTMLInputElement>}
              className={cn(styles.input, inputClassName, {
                [styles.withLeftIcon]: !!LeftIcon,
                [styles.disabled]: isDisabled,
                [styles.withError]: Boolean(error),
              })}
              placeholder={placeholder}
              readOnly={readOnly}
              autoFocus={autoFocus}
              id={id}
              name={name}
              required={required}
              disabled={isDisabled}
              aria-disabled={isDisabled}
              type={type}
              value={value}
              defaultValue={defaultValue}
              min={min}
              max={max}
              step={step}
              onChange={onChange}
              onBlur={onBlur}
              onFocus={onFocus}
              onKeyDown={onKeyDown}
            />

            {type === "number" && (
              <div className={styles.numberControls}>
                <button
                  type="button"
                  onClick={handleOnNumberUp}
                  className={styles.numberArrowButton}
                >
                  <BiCaretUp size={10} />
                </button>
                <button
                  type="button"
                  onClick={handleOnNumberDown}
                  className={styles.numberArrowButton}
                >
                  <BiCaretDown size={10} />
                </button>
              </div>
            )}

            {innerControls}
          </div>
        ) : null}

        {type === "textarea" ? (
          <textarea
            ref={elementRef as RefObject<HTMLTextAreaElement>}
            className={cn(styles.textarea, inputClassName, {
              [styles.disabled]: isDisabled,
              [styles.withError]: Boolean(error),
            })}
            placeholder={placeholder}
            readOnly={readOnly}
            autoFocus={autoFocus}
            id={id}
            name={name}
            required={required}
            disabled={isDisabled}
            aria-disabled={isDisabled}
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
          />
        ) : null}

        {outerControls}
      </div>

      {helperText ? renderInfoText(helperText, false) : null}
      {error ? renderInfoText(error, true) : null}
    </div>
  );
});

TextField.displayName = "TextField";

export default TextField;
