import styles from "./EditableCell.module.scss";
import {
  useEffect,
  useState,
  type InputHTMLAttributes,
  type ChangeEvent,
} from "react";
import {
  autoUpdate,
  flip,
  hide,
  offset,
  shift,
  useFloating,
  FloatingPortal,
} from "@floating-ui/react";
import { BiError } from "react-icons/bi";
import cn from "classnames";
import Typography from "components/Typography/Typography";

type InputProps = InputHTMLAttributes<HTMLInputElement>;

type EditableCellProps = {
  autoFocus?: InputProps["autoFocus"];
  placeholder?: InputProps["placeholder"];
  readOnly: InputProps["readOnly"];
  disabled?: InputProps["disabled"];
  value: string;
  inputClassName?: string;
  error?: string | null;
  onChange: (value: string) => void;
  onKeyDown: InputProps["onKeyDown"];
};

export function EditableCell({
  autoFocus,
  placeholder,
  readOnly,
  disabled,
  value,
  inputClassName,
  error,
  onChange,
  onKeyDown,
}: EditableCellProps) {
  const [inputValue, setInputValue] = useState(value);
  const [hasFocus, setHasFocus] = useState(false);
  const [open, setOpen] = useState(false);
  const { x, y, refs, strategy, middlewareData } = useFloating({
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    placement: "bottom-start",
    middleware: [offset(0), flip(), shift(), hide()],
  });

  useEffect(() => {
    setOpen(Boolean(error) && hasFocus);
  }, [error, hasFocus]);

  const handleFocus = () => {
    setHasFocus(true);
  };

  const handleBlur = () => {
    setHasFocus(false);
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const onChangeInputValue = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
    onChange(value);
  };

  return (
    <>
      <input
        ref={refs.setReference}
        autoFocus={autoFocus}
        className={cn(styles.input, inputClassName, {
          [styles.input_error]: !!error,
          [styles.input_disabled]: !!disabled,
        })}
        type="text"
        autoComplete="off"
        placeholder={placeholder}
        readOnly={readOnly}
        disabled={disabled}
        title={inputValue}
        value={inputValue}
        onChange={onChangeInputValue}
        onKeyDown={onKeyDown}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      {open && (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
            className={cn(styles.error, {
              [styles.hidden]: middlewareData.hide?.referenceHidden,
            })}
          >
            <div className={styles.error_content}>
              <BiError className={styles.error_icon} size={20} />
              <Typography variant="caption1" className={styles.error_text}>
                {error}
              </Typography>
            </div>
          </div>
        </FloatingPortal>
      )}
    </>
  );
}
