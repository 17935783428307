import { createContext } from "react";
import type {
  AssetsDataContextInterface,
  AssetPageTabType,
} from "pages/Assets/types/abstractTypes";
import { useCreatedAssetsData } from "../hooks/assets/useCreatedAssetsData/useCreatedAssetsData";
import { useCurrentAssetData } from "../hooks/assets/useCurrentAssetData/useCurrentAssetData";
import { useConstantData } from "../hooks/assets/useConstantData";
import { DATA_LOAD_STATUS } from "config/appConfig";

export const AssetsDataContext = createContext<AssetsDataContextInterface>({
  getCurrentAssetItemsData: () => ({
    assetsData: {},
    activeItemData: null,
    activeVersionData: null,
    activeItemId: null,
    activeItemVersionId: null,
    isDisplayArchivedItems: false,
    assetDataLoadState: DATA_LOAD_STATUS.NOT_LOADED,
  }),
  getCurrentAssetItemActions: () => ({
    setActiveItemId: () => {},
    setActiveVersionNumber: () => {},
    setAssetsData: () => {},
    deleteAssetItem: () => {},
    deleteAssetVersion: () => {},
    setAssetVersionStatus: () => {},
    setAssetVersionIsArchived: () => {},
    setIsDisplayArchivedItems: () => {},
    setAssetVersionIsSecret: () => {},
    setAssetIsArchived: () => {},
    setActiveItemByVersionId: () => {},
    clearActiveItemData: () => {},
    clearStore: () => {},
  }),
  getCreatedAssetItemData: () => ({
    createdAssetItemData: null,
    assetOperationType: null,
    createdAssetError: null,
    createdAssetLoading: false,
  }),
  getCreatedAssetItemActions: () => ({
    setCreatedAssetItemData: () => {},
    createAssetItem: () => {},
    editAssetItem: () => {},
    setAssetItemOperationType: () => {},
    addNewVersion: () => {},
    setCreatedAssetError: () => {},
    clearCreatedAsset: () => {},
    updateAssetItemName: () => {},
  }),
  getAssetSpecificData: () => ({}),
});

export const AssetsDataProvider = ({ children }: any) => {
  const { getCurrentAssetItemsData, getCurrentAssetItemActions } =
    useCurrentAssetData();
  const { getCreatedAssetItemData, getCreatedAssetItemActions } =
    useCreatedAssetsData();
  const constantSpecificData = useConstantData();

  const getAssetSpecificData = (assetType: AssetPageTabType) => {
    switch (assetType) {
      case "function":
        return {};
      case "constant":
        return constantSpecificData;
    }
  };

  const contextValue = {
    getCurrentAssetItemsData,
    getCurrentAssetItemActions,
    getCreatedAssetItemData,
    getCreatedAssetItemActions,
    getAssetSpecificData,
  };

  return (
    <AssetsDataContext.Provider value={contextValue}>
      {children}
    </AssetsDataContext.Provider>
  );
};
