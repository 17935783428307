import { useCanvasState } from "store";
import type { BlockType } from "models/canvas";

export function useBlock(blockId: BlockType["id"]): BlockType | null;
export function useBlock<T>(
  blockId: BlockType["id"],
  selector?: (block: BlockType) => T
): T | null;
export function useBlock<T>(
  blockId: BlockType["id"],
  selector?: (block: BlockType) => T
) {
  return useCanvasState((slice) => {
    const block = slice.getBlock(blockId);
    if (!block) {
      return null;
    }
    return selector ? selector(block) : block;
  });
}
