import styles from "./UserSettingsGitIntegration.module.scss";
import { useUserSettingsState } from "store";
import { useUserSettings } from "hooks/useUserSettings";
import { UserSettingsTabHeader } from "components/UserSettingsTabHeader";
import Spinner from "components/common/Spinner/Spinner";
import { InstallGitApp } from "components/InstallGitApp";

export default function UserSettingsGitIntegration() {
  const { isFetchingSettings } = useUserSettings();
  const profileData = useUserSettingsState((slice) => slice.profile);

  const isGitAppInstalled = Boolean(profileData.githubAppInstallationId);

  if (isFetchingSettings) {
    return <Spinner className={styles.spinner} />;
  }

  return (
    <div className={styles.container}>
      <UserSettingsTabHeader
        heading="Github Integration"
        subHeading="Manage github integraton settings."
      />
      <div className={styles.content}>
        <InstallGitApp
          showDescription={!isGitAppInstalled}
          buttonLabel={
            isGitAppInstalled
              ? "Configure Zerve Github App"
              : "Install Zerve Github App"
          }
        />
      </div>
    </div>
  );
}
