import styles from "./CanvasesListLayout.module.scss";
import type { ChangeEvent, MouseEvent, ReactNode } from "react";
import cn from "classnames";
import { BiGridAlt, BiListUl, BiSearch } from "react-icons/bi";
import { DISPLAY_MODE, type SORTING_COLUMN } from "config/localPreferences";
import { type SORTING_DIRECTION } from "config/appConfig";
import { CanvasesGrid } from "components/canvases/CanvasesGrid/CanvasesGrid";
import { EmptyState } from "components/common/EmptyState/EmptyState";
import {
  SortingDropdown,
  type SortOptions,
} from "components/common/SortingDropdown/SortingDropdown";
import { CanvasesTable } from "../CanvasesTable/CanvasesTable";
import type { CanvasCardType } from "models/canvas";
import type { ContextMenuItem } from "components/common/ContextMenu/ContextMenu";

type CanvasesListLayoutProps = {
  canvases: CanvasCardType[];
  selectedCanvasesIds: Record<string, string>;
  processedCanvasesIdActionMap: Record<string, string>;
  notFound: boolean;
  noCanvases: boolean;
  noCanvasesPlaceholder: ReactNode;
  displayMode: DISPLAY_MODE;
  searchQuery: string;
  sortingColumn: SORTING_COLUMN;
  sortingDirection: SORTING_DIRECTION;
  sortOptions: SortOptions;
  toolbar: ReactNode;
  topBarClassName?: string;
  showSharingInfo?: boolean;
  onGetCanvasOptions: (canvas: CanvasCardType) => ContextMenuItem[];
  onSearchQueryChange: (e?: ChangeEvent<HTMLInputElement>) => void;
  onChangeSorting: ({
    column,
    direction,
  }: {
    column: SORTING_COLUMN;
    direction: SORTING_DIRECTION;
  }) => void;
  onCanvasClick: (id: string, event: MouseEvent<HTMLElement>) => void;
  setDisplayMode: (mode: DISPLAY_MODE) => void;
};

export function CanvasesListLayout({
  canvases,
  selectedCanvasesIds,
  processedCanvasesIdActionMap,
  notFound,
  noCanvases,
  noCanvasesPlaceholder,
  displayMode,
  searchQuery,
  sortingColumn,
  sortingDirection,
  sortOptions,
  toolbar,
  topBarClassName,
  showSharingInfo = false,
  onGetCanvasOptions,
  onSearchQueryChange,
  onChangeSorting,
  onCanvasClick,
  setDisplayMode,
}: CanvasesListLayoutProps) {
  return (
    <>
      <div className={cn(styles.top_bar, topBarClassName)}>
        <div className={styles.search}>
          <BiSearch size={24} className={styles.searchIcon} />
          <input
            placeholder="Search..."
            value={searchQuery}
            onChange={onSearchQueryChange}
          />
        </div>

        <div className={styles.actions}>
          {/* Sorting */}
          <div className={styles.sort}>
            <span className={styles.sortLabel}>Sort by:</span>
            <SortingDropdown
              column={sortingColumn}
              direction={sortingDirection}
              sortOptions={sortOptions}
              onChange={onChangeSorting}
            />
          </div>

          {/* Cards mode */}
          <BiGridAlt
            size={16}
            className={cn(styles.displayModeBtn, {
              [styles.activeDisplayMode]: displayMode === DISPLAY_MODE.CARDS,
            })}
            onClick={() => {
              setDisplayMode(DISPLAY_MODE.CARDS);
            }}
          />

          {/* Table mode */}
          <BiListUl
            size={16}
            className={cn(styles.displayModeBtn, {
              [styles.activeDisplayMode]: displayMode === DISPLAY_MODE.TABLE,
            })}
            onClick={() => {
              setDisplayMode(DISPLAY_MODE.TABLE);
            }}
          />
        </div>
      </div>
      <div className={styles.content}>
        {/* placeholder when no canvases */}
        {noCanvases && noCanvasesPlaceholder}

        {/* placeholder when not found any canvas  */}
        {notFound && !noCanvases && (
          <EmptyState
            variant="info"
            size="large"
            containerClassName={styles.placeholder}
            title="No Canvases found..."
            description="Your search does not match the name of any of your canvases"
          />
        )}

        {!notFound && !noCanvases && (
          <>
            {displayMode === DISPLAY_MODE.CARDS && (
              <CanvasesGrid
                items={canvases}
                onGetCanvasOptions={onGetCanvasOptions}
                processedItemsIds={processedCanvasesIdActionMap}
                selectedItemsIds={selectedCanvasesIds}
                onItemClick={onCanvasClick}
              />
            )}
            {displayMode === DISPLAY_MODE.TABLE && (
              <CanvasesTable
                items={canvases}
                onGetCanvasOptions={onGetCanvasOptions}
                processedItemsIds={processedCanvasesIdActionMap}
                selectedItemsIds={selectedCanvasesIds}
                sortingColumn={sortingColumn}
                sortingDirection={sortingDirection}
                onChangeSorting={onChangeSorting}
                showSharingInfo={showSharingInfo}
                onRowClick={onCanvasClick}
              />
            )}
          </>
        )}
      </div>

      {toolbar}
    </>
  );
}
