import type {
  AssetPageTabType,
  CreatedAssetItemActionsInterface,
  CreatedAssetItemInterface,
} from "pages/Assets/types/abstractTypes";
import { showConsoleWarningNoAssetType } from "utils/helpers";
import {
  useCreatedConstantsData,
  useCreatedFunctionsData,
  useCreatedQueriesData,
} from "./components";

export const useCreatedAssetsData = () => {
  const { createdFunctionsData, createdFunctionsActions } =
    useCreatedFunctionsData();
  const { createdQueriesData, createdQueriesActions } = useCreatedQueriesData();
  const { createdConstantsData, createdConstantsActions } =
    useCreatedConstantsData();

  const getCreatedAssetItemData = (
    assetType: AssetPageTabType
  ): CreatedAssetItemInterface<AssetPageTabType> => {
    switch (assetType) {
      case "function":
        return createdFunctionsData;
      case "query":
        return createdQueriesData;
      case "constant":
        return createdConstantsData;
      default:
        return {
          createdAssetItemData: null,
          assetOperationType: null,
          createdAssetError: null,
          createdAssetLoading: false,
        };
    }
  };

  const getCreatedAssetItemActions = (
    assetType: AssetPageTabType
  ): CreatedAssetItemActionsInterface<AssetPageTabType> => {
    switch (assetType) {
      case "function":
        return createdFunctionsActions;
      case "query":
        return createdQueriesActions;
      case "constant":
        return createdConstantsActions;
      default:
        return {
          setCreatedAssetItemData: showConsoleWarningNoAssetType,
          createAssetItem: showConsoleWarningNoAssetType,
          setAssetItemOperationType: showConsoleWarningNoAssetType,
          editAssetItem: showConsoleWarningNoAssetType,
          updateAssetItemName: showConsoleWarningNoAssetType,
          addNewVersion: showConsoleWarningNoAssetType,
          setCreatedAssetError: showConsoleWarningNoAssetType,
          clearCreatedAsset: showConsoleWarningNoAssetType,
        };
    }
  };
  return {
    getCreatedAssetItemData,
    getCreatedAssetItemActions,
  };
};
