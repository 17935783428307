import moment, { type MomentInput } from "moment";
import tinycolor from "tinycolor2";
import { BLOCK_TYPE, BLOCK_DETAILS } from "config/canvasConfig";
import { PAYLOAD_TYPE } from "config/deploymentConfig";
import type {
  BlockType,
  EdgeType,
  ApiRouteDataProperties,
  BlockOutputVariable,
} from "models/canvas";
import { type ContextMenuItem } from "components/common/ContextMenu/ContextMenu";

export function camelCaseToSnakeCase(str: string) {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}

export function snakeCaseToCamelCase(str: string) {
  return str.replace(/([-_][a-z])/g, (group) =>
    group.toUpperCase().replace("-", "").replace("_", "")
  );
}

export function changeObjectSnakeCaseNameToCamelCase<T>(
  obj: Record<string, T>
) {
  const newObj: Record<string, T> = {};
  for (const key in obj) {
    const newKey = key.replace(/_./g, (x) => x[1].toUpperCase());
    newObj[newKey] = obj[key];
  }
  return newObj;
}

export function changeObjectCamelCaseToSnakeCase<T>(obj: Record<string, T>) {
  const newObj: Record<string, T> = {};
  for (const key in obj) {
    newObj[camelCaseToSnakeCase(key)] = obj[key];
  }
  return newObj;
}

export function showConsoleWarningNoAssetType() {
  console.warn("no appropriate action for this asset type");
}

// get a space that block occupies
export function getBlockSpace(block: BlockType, margin = 0) {
  const x = block.position.x;
  const y = block.position.y;
  const width = block.width;
  const height = block.height;
  return {
    left: x - margin,
    right: x + width + margin,
    top: y - margin,
    bottom: y + height + margin,
    x,
    y,
  };
}

// Get the name of a block. Fallback to default if empty.
export function getBlockName({
  name,
  type,
}: {
  name?: string;
  type?: BLOCK_TYPE | null;
}) {
  if (name) {
    return name;
  }

  if (type === BLOCK_TYPE.AUTOML) {
    return BLOCK_DETAILS[BLOCK_TYPE.AUTOML].name;
  }

  if (type === BLOCK_TYPE.API_CONTROLLER) {
    return BLOCK_DETAILS[BLOCK_TYPE.API_CONTROLLER].name;
  }

  if (type === BLOCK_TYPE.API_ROUTE) {
    return BLOCK_DETAILS[BLOCK_TYPE.API_ROUTE].name;
  }

  if (type === BLOCK_TYPE.SAGEMAKER_ENDPOINT) {
    return BLOCK_DETAILS[BLOCK_TYPE.SAGEMAKER_ENDPOINT].name;
  }

  return "Untitled";
}

export function getBlockVariableDisplayValue(variable: BlockOutputVariable) {
  const { data_type, value } = variable;

  if (typeof value === "boolean") {
    return `${data_type} (${value})`;
  }

  if (value || value === 0) {
    return value;
  }

  if (value === "") {
    return `${data_type} ("")`;
  }

  if (data_type === "str") {
    return `${data_type}`;
  }

  return `${data_type} (${variable.serializer})`;
}

// Replace middle of a string with ellipsis
export function truncateInTheMiddle({
  str,
  maxLength = 12,
}: {
  str: string;
  maxLength?: number;
}) {
  if (str.length <= maxLength) {
    return str;
  }

  const ellipsis = "...";
  const charsToShow = maxLength - ellipsis.length;

  const leftChars = Math.ceil(charsToShow / 2);
  const rightChars = Math.floor(charsToShow / 2);

  const truncated = str.slice(0, leftChars) + ellipsis + str.slice(-rightChars);

  return truncated;
}

// Get an address of a deployed API layer
export function getApiAddress({
  dns_name,
  hosted_zone_name = "hub.zerve.cloud",
  route_name = "",
}: {
  dns_name: string;
  hosted_zone_name?: string;
  route_name?: string;
}) {
  const base_domain =
    hosted_zone_name === "zerve.cloud"
      ? hosted_zone_name
      : `api.${hosted_zone_name}`;
  let address = `https://${dns_name}.${base_domain}`;
  if (route_name) {
    address = `${address}/${route_name}`;
  }
  return address;
}

// Get an address of a deployed SageMaker layer
export function getSageMakerEndpointAddress({
  dns_name,
  hosted_zone_name = "hub.zerve.cloud",
}: {
  dns_name: string;
  hosted_zone_name?: string;
}) {
  return `https://${dns_name}.sagemaker.${hosted_zone_name}/invocations`;
}

// Get an address of a Hosted App
export function getWorkspaceAppAddress({ dns_name }: { dns_name: string }) {
  return `https://${dns_name}.zerve.cloud`;
}

/**
 * Filter a list of blocks.
 * Search occurences in block names and output variables names.
 */
export function filterBlocks({
  blocks,
  blockNames,
  blockVariableNames,
  searchText,
}: {
  blocks: BlockType[];
  blockNames: Record<BlockType["id"], string>;
  blockVariableNames: Record<BlockType["id"], BlockOutputVariable["name"][]>;
  searchText: string;
}) {
  const searchString = searchText?.toLowerCase().trim() ?? "";
  if (!searchString) {
    return blocks;
  }
  return blocks.filter((block) => {
    const name = blockNames[block.id]?.toLowerCase() ?? "";
    const variableNames = blockVariableNames[block.id] ?? [];
    return (
      name.includes(searchString) ||
      variableNames.some((variableName) =>
        variableName.toLowerCase().includes(searchString)
      )
    );
  });
}

/**
 * Sort a list of blocks.
 * API Controller goes first, then API Route, then code blocks.
 * Blocks of the same group are ordered from recently created to older.
 */
export function sortBlocks({
  blocks,
  blockNames,
  blockTimeCreated,
}: {
  blocks: BlockType[];
  blockNames: Record<BlockType["id"], string>;
  blockTimeCreated: Record<BlockType["id"], string>;
}) {
  const getBlockSortingValue = (block: BlockType) => {
    const timeCreated = blockTimeCreated[block.id] || 0;
    // API Controller goes first
    if (
      block.type === BLOCK_TYPE.API_CONTROLLER ||
      block.type === BLOCK_TYPE.SAGEMAKER_ENDPOINT
    ) {
      return Number.MIN_SAFE_INTEGER;
    }
    // API Route blocks go before other blocks
    if (block.type === BLOCK_TYPE.API_ROUTE) {
      return Number.MIN_SAFE_INTEGER / 2 + new Date(timeCreated).valueOf();
    }
    // recently created blocks go after the old ones
    return new Date(timeCreated).valueOf();
  };

  const blocksWithGroups = blocks.reduce<
    Map<string, { blocks: BlockType[]; sortingValue: number }>
  >((acc, block) => {
    const blockName = blockNames[block.id];
    const blockNameParts = blockName.split(".");

    if (blockNameParts.length > 1) {
      const groupName = blockNameParts[0];
      const groupData = acc.get(groupName);
      if (groupData) {
        groupData.blocks.push(block);
        const blockSortingValue = getBlockSortingValue(block);
        if (groupData.sortingValue > blockSortingValue) {
          groupData.sortingValue = blockSortingValue;
        }
        acc.set(groupName, groupData);
      } else {
        acc.set(groupName, {
          blocks: [block],
          sortingValue: getBlockSortingValue(block),
        });
      }
    } else {
      acc.set(block.id, {
        blocks: [block],
        sortingValue: getBlockSortingValue(block),
      });
    }

    return acc;
  }, new Map());

  const sortedGroupsData = [...blocksWithGroups.values()].sort((a, b) => {
    return a.sortingValue - b.sortingValue;
  });

  return sortedGroupsData.reduce<BlockType[]>((res, item) => {
    return res.concat([
      ...item.blocks.sort((a, b) => {
        return getBlockSortingValue(a) - getBlockSortingValue(b);
      }),
    ]);
  }, []);
}

/**
 *
 * Find the first executable code block (Python, R, SQL) that is connected to a given block
 */
export function getFirstConnectedCodeBlock({
  blockId,
  blocks,
  edges,
}: {
  blockId: BlockType["id"];
  blocks: BlockType[];
  edges: EdgeType[];
}) {
  const connectingEdges = edges.filter((edge) => edge.source === blockId);

  if (!connectingEdges.length) {
    return null;
  }

  for (const edge of connectingEdges) {
    // check if the connecting block is executable
    const connectingBlock = blocks.find((b) => b.id === edge.target);
    if (connectingBlock && BLOCK_DETAILS[connectingBlock.type]?.canRun) {
      return connectingBlock;
    }

    // check deeper
    const codeBlock = getFirstConnectedCodeBlock({
      blockId: edge.target,
      blocks,
      edges,
    });
    if (codeBlock) {
      return codeBlock;
    }
  }

  return null;
}

/**
 * Convert API schema and test data values to Python code.
 */
export function getBlockContentFromPayloadSchema({
  model,
}: {
  model: ApiRouteDataProperties["model"];
}) {
  return model.reduce((content, { name, type, value }) => {
    let valueString = `${name} = `;
    switch (type) {
      case PAYLOAD_TYPE.NUMBER: {
        valueString += `${value}`;
        break;
      }
      case PAYLOAD_TYPE.BOOLEAN: {
        valueString += value ? "True" : "False";
        break;
      }
      case PAYLOAD_TYPE.LIST:
      case PAYLOAD_TYPE.DICT: {
        valueString += `json.loads(r"""${value}""")`;
        break;
      }
      case PAYLOAD_TYPE.STRING:
      default: {
        valueString += `"${value}"`;
        break;
      }
    }
    return content + valueString + "\n\n";
  }, "import json\n\n\n");
}

export function downloadFile({
  href,
  fileName,
  type,
}: {
  href: string;
  fileName?: string;
  type?: string;
}) {
  const a = document.createElement("a");
  a.href = href;

  if (fileName) {
    a.download = fileName;
  }
  if (type) {
    a.type = type;
  }

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

/**
 * Resolve a promise after a given timeout.
 */
export async function wait(timeout: number) {
  return await new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });
}

/**
 * Make sure that a promise isn't resolved or rejected before the given timeout.
 */
export async function debouncePromise<T>({
  promise,
  timeout = 500,
}: {
  promise: Promise<T>;
  timeout?: number;
}): Promise<T> {
  const start = Date.now();
  let isRejected = false;
  let resolveValue;
  let rejectValue;

  try {
    resolveValue = await promise;
  } catch (rejection) {
    isRejected = true;
    rejectValue = rejection;
  }

  const timeLeft = timeout - (Date.now() - start);
  if (timeLeft > 0) {
    await wait(timeLeft);
  }

  return isRejected ? await Promise.reject(rejectValue) : resolveValue;
}

/**
 * Format user's name
 */
export function getUserDisplayName({
  username,
  firstName,
  lastName,
  email,
}: {
  username?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
}) {
  if (firstName || lastName) {
    return `${firstName} ${lastName}`.trim();
  }
  if (username) {
    return username;
  }
  if (email) {
    return email;
  }
  return "Anonymous";
}

/**
 * Show date that is easy to read
 * Example:
 *   Today at 8:39:12 PM
 *   Nov 16 at 8:39:12 PM
 *   Nov 16, 2022 at 8:39:12 PM
 *   Thursday, Nov 16, 2022 at 8:39:12 PM
 */
export function humanizeDate({
  date,
  showWeekday = false,
}: {
  date: MomentInput;
  showWeekday?: boolean;
}) {
  const momentDate = moment(date);
  const currentDate = moment();

  // Example: Today at 8:39:12 PM
  if (momentDate.isSame(currentDate, "day")) {
    return momentDate.format("[Today at] h:mm:ss A");
  }

  let format = "";

  // Example: Thursday
  if (showWeekday) {
    format = "dddd, ";
  }

  // Example: Thursday, Nov 16
  format = `${format} MMM D`;

  // show year if it's not the current year
  // Example: Thursday, Nov 16, 2023
  if (momentDate.format("YYYY") !== currentDate.format("YYYY")) {
    format = `${format}, YYYY`;
  }

  // Example: Thursday, Nov 16, 2023 at 8:39:12 PM
  format = `${format} [at] h:mm:ss A`;

  return momentDate.format(format);
}

/**
 * Format time in seconds to human-readable format
 * Example:
 *    1m 23s
 */
export function formatBlockExecutionTime(totalSeconds: number) {
  if (totalSeconds < 1 && totalSeconds !== 0) {
    return `${totalSeconds.toFixed(2)}s`;
  } else if (totalSeconds < 60) {
    return `${totalSeconds.toFixed(0)}s`;
  } else {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    const formattedSeconds = seconds.toFixed(0);
    return `${minutes}m ${formattedSeconds}s`;
  }
}

export function getEnabledContextMenuOptions(options: ContextMenuItem[]) {
  const enabledOptions = options.reduce<typeof options>((acc, option) => {
    if (!("disabled" in option) || !option.disabled) {
      acc.push(option);
    }
    return acc;
  }, []);

  if (!enabledOptions.length) {
    return [];
  }

  const lastItem = enabledOptions[enabledOptions.length - 1];
  if ("showDivider" in lastItem && lastItem.showDivider) {
    lastItem.showDivider = false;
  }

  return enabledOptions;
}

/**
 * Returns true if black text is visible on the background of the given color
 */
export const getIsColorLight = ({
  color,
  luminanceThreshold = 0.4,
}: {
  color: string;
  luminanceThreshold?: number;
}) => {
  return tinycolor(color).getLuminance() > luminanceThreshold;
};
