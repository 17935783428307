import { useCallback, useMemo } from "react";
import {
  fetchDeployment as fetchDeploymentApi,
  postDeploySageMaker,
  undeployDeployment as undeployDeploymentApi,
} from "api/http/api-deployment-service";
import {
  useCanvasState,
  useToastsState,
  useCanvasDeploymentState,
} from "store";
import { useConfirmModal } from "hooks/useConfirmModal";
import { useModal } from "hooks/useModal";
import { DeploymentDisabilityReasonsModal } from "components/SageMaker/DeploymentDisabilityReasonsModal/DeploymentDisabilityReasonsModal";
import { getDeploymentDisabilityReasons } from "components/SageMaker/helpers";
import { debouncePromise } from "utils/helpers";
import { DATA_LOAD_STATUS } from "config/appConfig";
import type { LayerType } from "models/canvas";
import type { SageMakerDeployment } from "models/deployments";

export function useSageMakerDeploymentActions() {
  const addToast = useToastsState((slice) => slice.addToast);

  const setIsLayerDeploying = useCanvasDeploymentState(
    (slice) => slice.setIsLayerDeploying
  );
  const setLoadStatus = useCanvasDeploymentState(
    (slice) => slice.setLoadStatus
  );
  const setDeployment = useCanvasDeploymentState(
    (slice) => slice.setDeployment
  );
  const setIsDeploymentUndeploying = useCanvasDeploymentState(
    (slice) => slice.setIsDeploymentUndeploying
  );

  const getSelectedLayerBlocks = useCanvasState(
    (slice) => slice.getSelectedLayerBlocks
  );
  const getSelectedLayerEdges = useCanvasState(
    (slice) => slice.getSelectedLayerEdges
  );

  const { openConfirmModal } = useConfirmModal();
  const { openModal: openDeploymentDisabilityModal } = useModal();

  const fetchDeployment = useCallback(
    async ({
      canvasId,
      deploymentId,
      timeout,
    }: {
      canvasId: string;
      deploymentId: SageMakerDeployment["id"] | null;
      timeout?: number;
    }) => {
      if (!deploymentId) {
        return;
      }

      setLoadStatus(deploymentId, DATA_LOAD_STATUS.LOADING);

      try {
        const deployment = await debouncePromise({
          promise: fetchDeploymentApi({
            canvasId,
            deploymentId,
          }),
          timeout,
        });
        setDeployment(deploymentId, deployment);
        setLoadStatus(deploymentId, DATA_LOAD_STATUS.LOADED);
      } catch (err) {
        setLoadStatus(deploymentId, DATA_LOAD_STATUS.ERROR);
      }
    },
    []
  );

  const submitDeployment = useCallback(
    async ({
      canvasId,
      layerId,
      currentDeploymentId,
    }: {
      canvasId: string;
      layerId: LayerType["id"];
      currentDeploymentId: SageMakerDeployment["id"] | null;
    }) => {
      const blocks = getSelectedLayerBlocks();
      const edges = getSelectedLayerEdges();
      const deploymentDisabilityReasons = getDeploymentDisabilityReasons({
        blocks,
        edges,
      });
      if (deploymentDisabilityReasons.length) {
        openDeploymentDisabilityModal({
          title: "Cannot Deploy SageMaker",
          content: ({ onModalClose }) => {
            return (
              <DeploymentDisabilityReasonsModal
                reasons={deploymentDisabilityReasons}
                onModalClose={onModalClose}
              />
            );
          },
        });
        return;
      }

      const confirmed = await openConfirmModal({
        title: "Submit deployment",
        message: currentDeploymentId
          ? "Are you sure you want to submit another deployment? The previous deployment will be overwritten."
          : "Are you sure you want to submit a deployment?",
      });

      if (!confirmed) {
        return;
      }

      setIsLayerDeploying(layerId, true);
      try {
        await postDeploySageMaker({ canvasId, layerId });
        setIsLayerDeploying(layerId, false);
        addToast({
          variant: "success",
          message: "SageMaker deployment submitted",
        });
      } catch (rejection) {
        setIsLayerDeploying(layerId, false);
        addToast({
          variant: "error",
          message: "Failed to submit SageMaker deployment",
        });
      }
    },
    []
  );

  const undeployDeployment = useCallback(
    async ({
      canvasId,
      layerId,
      currentDeploymentId,
    }: {
      canvasId: string;
      layerId: LayerType["id"];
      currentDeploymentId: SageMakerDeployment["id"] | null;
    }) => {
      if (!currentDeploymentId) {
        // there is nothing to delete
        return;
      }

      const confirmed = await openConfirmModal({
        message: (
          <>
            Are you sure you want to remove the deployment?
            <br />
            The SageMaker endpoint will stop serving requests.
          </>
        ),
        confirmButtonLabel: "Stop",
        confirmButtonVariant: "crucial",
      });

      if (!confirmed) {
        return;
      }

      setIsDeploymentUndeploying(currentDeploymentId, true);
      try {
        await undeployDeploymentApi({ canvasId, layerId });
        addToast({
          variant: "success",
          message: "SageMaker deployment stopped",
        });
        setIsDeploymentUndeploying(currentDeploymentId, false);
      } catch (rejection) {
        setIsDeploymentUndeploying(currentDeploymentId, false);
        addToast({
          variant: "error",
          message: "Failed to stop SageMaker deployment",
        });
      }
    },
    []
  );

  return useMemo(
    () => ({
      fetchDeployment,
      submitDeployment,
      undeployDeployment,
    }),
    [fetchDeployment, submitDeployment, undeployDeployment]
  );
}
