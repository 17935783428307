/*
This component is responsible for checking if the user is authenticated
if they are not authenticated it will redirect them to /auth/login
if they are authenticated it will proceed to the Outlet
*/

import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import LoadingScreen from "components/common/LoadingScreen/LoadingScreen";

export function Auth() {
  const { isAuthenticated, isLoading } = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();

  // get path so it can be sent to the /auth/login page as a search param
  const redirectPath = location.pathname + location.search;

  // get the hint from the search params to help speed up the login process
  const hint = new URLSearchParams(location.search).get("hint");

  // build search params of redirect path and hint
  const searchParams = new URLSearchParams();
  if (redirectPath) {
    searchParams.set("redirectTo", redirectPath);
  }
  if (hint) {
    searchParams.set("hint", hint);
  }

  // redirect to the login page if the user is not authenticated
  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      navigate(`/auth/login?${searchParams.toString()}`);
    }
  }, [isLoading, isAuthenticated]);

  if (!isAuthenticated) {
    return <LoadingScreen text="Authenticating..." />;
  }

  return <Outlet />;
}
