import * as Yup from "yup";

export enum CLOUD_PROVIDER {
  AWS = "aws",
  MICROSOFT_AZURE = "azure",
  GOOGLE_CLOUD = "gcp",
}

export enum EXECUTOR_STATUS {
  // The executor is being initialized, which means the message has been sent
  // to the queue, but the executor service has not yet picked it up.
  INITIALIZING = 1,

  // The executor is being deployed, which means the executor service has picked up
  // the message and is deploying the executor.
  DEPLOYING = 2,

  // The executor is ready to be used.  This means the deployment has been completed and
  // the success message has been received from the executor service.
  READY = 3,

  // The executor has errored out.  This means the deployment has been completed but the
  // executor service has send back and error message
  ERROR = 4,
}

export const ORGANIZATION_NAME_YUP_VALIDATION_BASE = Yup.string()
  .required("Organization name is required")
  .max(128, "Must be 128 characters or less")
  .matches(
    /^[a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*$/,
    "Only alphanumeric characters and single hyphens are allowed, and cannot start or end with a hyphen"
  );

export const WORKSPACE_NAME_YUP_VALIDATION_BASE = Yup.string()
  .trim()
  .required("Workspace name is required")
  .max(128, "Must be 128 characters or less")
  .matches(
    /^[a-zA-Z0-9-\s]*$/,
    "Only alphanumeric characters, hyphens, and spaces are allowed"
  )
  .matches(/^(?!-)(?!.*-$).+/, "Cannot start or end with a hyphen")
  .matches(
    /^(?!.*[-\s]{2,}).*$/,
    "Consecutive hyphens and spaces are not allowed"
  );
