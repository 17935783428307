const emptyStateText = {
  function: {
    title: "No Functions & Classes yet...",
    subtitle: "Click Create Function to add new Function to this list.",
    buttonText: "Create Function",
  },
  constant: {
    title: "No Constants & Secrets yet...",
    subtitle: "Click Create Сonstant to add new Constant to this list.",
    buttonText: "Create Сonstant",
  },
  connection: {
    title: "No Connections yet...",
    subtitle: "Click Create Connection to add new connection to this list.",
    buttonText: "",
  },
  query: {
    title: "No Queries yet...",
    subtitle: "Click Create Query to add new Query to this list.",
    buttonText: "Create Query",
  },
};

const errorStateText = {
  function: {
    title: "Failed to load Functions & Classes...",
    subtitle: "Click Retry button to try again.",
    buttonText: "Retry",
  },
  constant: {
    title: "Failed to load Constants & Secrets...",
    subtitle: "Click Retry button to try again.",
    buttonText: "Retry",
  },
  connection: {
    title: "Failed to load Connections...",
    subtitle: "Click Retry button to try again.",
    buttonText: "Retry",
  },
  query: {
    title: "Failed to load Queries...",
    subtitle: "Click Retry button to try again.",
    buttonText: "Retry",
  },
};

export { emptyStateText, errorStateText };
