import styles from "./GroupMembers.module.scss";
import { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useUserState, useToastsState } from "store";
import { Button } from "components/common/Button/Button";
import { Checkbox } from "components/common/Checkbox/Checkbox";
import Table from "components/common/Table/Table";
import Typography from "components/Typography/Typography";
import { EmptyState } from "components/common/EmptyState/EmptyState";
import {
  getGroupMembers,
  postGroupMembers,
  deleteGroupMembers,
} from "api/http/group-service";
import { getOrganizationMembers } from "api/http/organization-service";

import {
  useReactTable,
  getCoreRowModel,
  createColumnHelper,
} from "@tanstack/react-table";
import type { Organization } from "models/organization";

// type declaration for organization table rows
type OrganizationMember = {
  id: string;
  user: {
    id: string;
    email: string;
  };
};

// type declaration for table rows
type GroupMember = {
  id: string;
  user: {
    id: string;
    email: string;
  };
};

interface GroupMembersProps {
  organization?: Organization;
}

export function GroupMembers({ organization }: GroupMembersProps) {
  const userID = useUserState((slice) => slice.userID);
  const { groupID = "", orgID = "" } = useParams();
  const addToast = useToastsState((slice) => slice.addToast);

  // Fetch organization members and group members when the component loads.
  useEffect(() => {
    async function initializeGroup() {
      updateGroupMembers(groupID);
      updateOrganizationMembers(orgID);
    }
    if (userID && groupID) {
      initializeGroup();
    }
  }, [userID, orgID, groupID]);

  // States for organization members table
  const [allOrganizationMembers, setAllOrganizationMembers] = useState<any[]>(
    []
  );
  const [orgTableData, setOrgTableData] = useState<any[]>([]);
  const [checkedForAddition, setCheckedForAddition] = useState<string[]>([]);
  const [isOrgSelectedAll, setOrgIsSelectedAll] = useState(false);

  // Handle select all for the organization table
  const handleOrgSelectAll = () => {
    if (isOrgSelectedAll) {
      setCheckedForAddition([]);
    } else {
      setCheckedForAddition(
        orgTableData.map((nextItem: OrganizationMember) => nextItem.user.id)
      );
    }
  };

  // Check if all items are selected in the organization table
  useEffect(() => {
    if (checkedForAddition.length === 0) {
      setOrgIsSelectedAll(false);
      return;
    }
    for (const nextItem of orgTableData) {
      if (!checkedForAddition.includes(nextItem.user.id)) {
        setOrgIsSelectedAll(false);
        return;
      }
    }
    setOrgIsSelectedAll(true);
  }, [checkedForAddition]);

  // States for group membership table
  const [groupTableData, setGroupTableData] = useState<any[]>([]);
  const [checkedForDeletion, setCheckedForDeletion] = useState<string[]>([]);
  const [isGroupSelectedAll, setIsGroupSelectedAll] = useState(false);

  // Handle select all for the group table
  const handleSelectAll = () => {
    if (isGroupSelectedAll) {
      setCheckedForDeletion([]);
    } else {
      setCheckedForDeletion(
        groupTableData.map((nextItem: GroupMember) => nextItem.user.id)
      );
    }
  };

  // Check if all items are selected in the group table
  useEffect(() => {
    if (checkedForDeletion.length === 0) {
      setIsGroupSelectedAll(false);
      return;
    }
    for (const nextItem of groupTableData) {
      if (!checkedForDeletion.includes(nextItem.user.id)) {
        setIsGroupSelectedAll(false);
        return;
      }
    }
    setIsGroupSelectedAll(true);
  }, [checkedForDeletion]);

  // Check if a user is an admin of the organization
  const isOrganizationAdmin = () => {
    if (organization?.role === "Organization Admin") {
      return true;
    }
    return false;
  };

  // Check if a user is already in the group
  const isInGroup = (id: string) => {
    const found = groupTableData.findIndex((nextRow) => nextRow.user.id === id);
    return found !== -1;
  };

  // Update the list of organization members
  const updateOrganizationMembers = (organizationId: string) => {
    getOrganizationMembers(organizationId)
      .then((returnedValues) => {
        setAllOrganizationMembers(returnedValues);
      })
      .catch((error) => {
        addToast({
          message: error.message,
          variant: "error",
          duration: 1000,
        });
      });
  };

  // Update the list of group members
  const updateGroupMembers = (groupId: string) => {
    getGroupMembers(orgID, groupId)
      .then((returnedValues) => {
        setGroupTableData(returnedValues);
      })
      .catch((error) => {
        addToast({
          message: error.message,
          variant: "error",
          duration: 1000,
        });
      });
  };

  // When the group or organization members change, update the organization table data
  useEffect(() => {
    const filtererOrganizationMembers = allOrganizationMembers.filter(
      (nextMember) => {
        return !isInGroup(nextMember.user.id);
      }
    );
    setOrgTableData(filtererOrganizationMembers);
  }, [groupTableData, allOrganizationMembers]);

  const addMembers = () => {
    postGroupMembers(orgID, groupID, checkedForAddition)
      .then(() => {
        addToast({
          message: "Member(s) successfully added to group.",
          variant: "success",
          duration: 1000,
        });
        updateGroupMembers(groupID);
        setCheckedForAddition([]);
      })
      .catch((error) => {
        addToast({
          message: error.message,
          variant: "error",
          duration: 1000,
        });
      });
  };

  const removeMembers = () => {
    deleteGroupMembers(orgID, groupID, checkedForDeletion)
      .then(() => {
        addToast({
          message: "Member(s) successfully deleted from group.",
          variant: "success",
          duration: 1000,
        });
        updateGroupMembers(groupID);
        setCheckedForDeletion([]);
      })
      .catch((error) => {
        addToast({
          message: error.message,
          variant: "error",
          duration: 1000,
        });
      });
  };

  // Organization Table column definitions
  const organizationColumnHelper = createColumnHelper<OrganizationMember>();
  const organizationColumns = useMemo(
    () => [
      organizationColumnHelper.accessor((row) => row.user.id, {
        id: "checkbox",
        cell: (info) => {
          const handleCheck = (check: boolean, rowId: string) => {
            if (check) {
              setCheckedForAddition([...checkedForAddition, rowId]);
            } else {
              setCheckedForAddition(
                checkedForAddition.filter((value) => value !== rowId)
              );
            }
          };
          return (
            <div style={{ width: "40px" }}>
              <Checkbox
                onChange={() => {
                  handleCheck(
                    !checkedForAddition.includes(info.getValue()),
                    info.getValue()
                  );
                }}
                checked={checkedForAddition.includes(info.getValue())}
                disabled={isInGroup(info.getValue()) || !isOrganizationAdmin()}
              />
            </div>
          );
        },
        header: () => (
          <Checkbox
            checked={isOrgSelectedAll}
            onChange={handleOrgSelectAll}
            disabled={!isOrganizationAdmin()}
          />
        ),
        enableSorting: false,
      }),
      organizationColumnHelper.accessor((row) => row.user.email, {
        id: "email",
        cell: (info) => (
          <span
            className={
              isInGroup(info.row.original.user.id) || !isOrganizationAdmin()
                ? styles.mutedRow
                : ""
            }
          >
            {info.getValue()}
          </span>
        ),
        header: () => <span>User Email</span>,
        enableSorting: false,
      }),
    ],
    [checkedForAddition, isOrgSelectedAll, orgTableData, groupTableData]
  );

  // Group table column definitions
  const groupColumnHelper = createColumnHelper<GroupMember>();
  const groupColumns = useMemo(
    () => [
      groupColumnHelper.accessor((row) => row.user.id, {
        id: "checkbox",
        cell: (info) => {
          const handleCheck = (check: boolean, rowId: string) => {
            if (check) {
              setCheckedForDeletion([...checkedForDeletion, rowId]);
            } else {
              setCheckedForDeletion(
                checkedForDeletion.filter((value) => value !== rowId)
              );
            }
          };
          return (
            <div style={{ width: "40px" }}>
              <Checkbox
                onChange={() => {
                  handleCheck(
                    !checkedForDeletion.includes(info.getValue()),
                    info.getValue()
                  );
                }}
                checked={checkedForDeletion.includes(info.getValue())}
                disabled={!isOrganizationAdmin()}
              />
            </div>
          );
        },
        header: () => (
          <Checkbox
            checked={isGroupSelectedAll}
            onChange={handleSelectAll}
            disabled={!isOrganizationAdmin()}
          />
        ),
        enableSorting: false,
      }),
      groupColumnHelper.accessor((row) => row.user.email, {
        id: "email",
        cell: (info) => (
          <span className={!isOrganizationAdmin() ? styles.mutedRow : ""}>
            {info.getValue()}
          </span>
        ),
        header: () => <span>User Email</span>,
        enableSorting: false,
      }),
    ],
    [checkedForDeletion, isGroupSelectedAll, groupTableData]
  );

  // Create react table for the organization members
  const organizationTable = useReactTable({
    data: orgTableData,
    columns: organizationColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  // Create react table for the group members
  const groupTable = useReactTable({
    data: groupTableData,
    columns: groupColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  return !groupID ? (
    <div className={styles.emptyBlockWrapper}>
      <EmptyState
        variant="info"
        title="No Group Created."
        description="You must first set up an group before adding members."
      />
    </div>
  ) : (
    <>
      <Typography variant="h1">Group Members</Typography>
      <Typography variant="body2" className={styles.subHeading}>
        Manage members and users of your group and set their access level. Only
        members already in your organization can be added to a group.
      </Typography>
      <div className={styles.tableSectionContainer}>
        <div className={styles.tableSection}>
          <div className={styles.tableTitle}>Available Members</div>
          <Table table={organizationTable} />
          <div className={styles.buttonSection}>
            <Button
              variant="primary"
              onClick={addMembers}
              disabled={checkedForAddition.length === 0}
            >
              Add Selected Members
            </Button>
          </div>
        </div>
        <div className={styles.tableSection}>
          <div className={styles.tableTitle}>Included Members</div>
          <Table table={groupTable} />
          <div className={styles.buttonSection}>
            <Button
              variant="crucial"
              onClick={removeMembers}
              disabled={checkedForDeletion.length === 0}
            >
              Remove Selected Members
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
