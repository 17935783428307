import styles from "./AssetListItemIcon.module.scss";
import { useMemo, type FC, type ReactNode } from "react";
import cn from "classnames";
import { MenuOptions } from "../../types";
import MaskIcon from "assets/icons/mask.svg?react";
import Tooltip from "components/common/Tooltip/Tooltip";
import { ASSET_TYPE, ASSET_DETAILS } from "config/assetsConfig";

interface AssetListItemIconProps {
  assetType: MenuOptions;
  isActive?: boolean;
  isMask?: boolean;
  isHidden?: boolean;
  maskTip?: ReactNode;
}

const AssetListItemIcon: FC<AssetListItemIconProps> = ({
  assetType,
  isActive,
  isMask,
  isHidden,
  maskTip,
}: AssetListItemIconProps) => {
  const Icon = useMemo(() => {
    switch (assetType) {
      case MenuOptions.Function:
        return ASSET_DETAILS[ASSET_TYPE.FUNCTIONS].Icon;
      case MenuOptions.Constant:
        return isHidden && !isMask
          ? ASSET_DETAILS[ASSET_TYPE.CONSTANTS].HiddenIcon
          : ASSET_DETAILS[ASSET_TYPE.CONSTANTS].Icon;
      case MenuOptions.Connections:
        return ASSET_DETAILS[ASSET_TYPE.CONNECTIONS].Icon;
      case MenuOptions.Query:
        return ASSET_DETAILS[ASSET_TYPE.QUERIES].Icon;
    }
  }, [assetType]);

  return (
    <div className={styles.container}>
      <Icon
        className={cn(styles.assetIcon, { [styles.isActiveIcon]: isActive })}
      />

      {isMask && (
        <div className={styles.subMask}>
          <Tooltip
            tipElement={maskTip}
            placement="top-end"
            mainAxisOffset={14}
            crossAxisOffset={-8}
            withArrow
          >
            <MaskIcon />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default AssetListItemIcon;
