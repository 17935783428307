import styles from "./UserDropdown.module.scss";
import { memo, useMemo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { BiBell, BiCog, BiHelpCircle, BiLogOut } from "react-icons/bi";
import {
  autoUpdate,
  flip,
  offset,
  FloatingPortal,
  useDismiss,
  useFloating,
  FloatingTree,
  useFloatingNodeId,
  FloatingNode,
} from "@floating-ui/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useUserSettingsState } from "store";
import Avatar from "components/common/Avatar/Avatar";
import ContextMenu, {
  type ContextMenuItem,
} from "components/common/ContextMenu/ContextMenu";
import { UserInfoSection } from "../UserInfoSection";
import { HELP_MENU_EXTERNAL_LINK_OPTION } from "config/externalLinks";

export const UserDropdown = memo(function UserDropdown() {
  const { user, logout } = useAuth0();
  const flags = useFlags();

  const floatingNodeId = useFloatingNodeId();
  const [isOpen, setIsOpen] = useState(false);
  const { x, y, refs, strategy, context } = useFloating({
    nodeId: floatingNodeId,
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    placement: "bottom-start",
    middleware: [flip(), offset(8)],
  });

  useDismiss(context, {
    escapeKey: true,
    outsidePress: true,
    // allow clicks on sub-menu
    capture: {
      escapeKey: true,
      outsidePress: false,
    },
  });

  const handleOnClick = (e) => {
    e.stopPropagation();
    setIsOpen((prev) => !prev);
  };

  const avatar = useUserSettingsState((slice) => slice.profile.avatar);

  const menuOptions = useMemo(() => {
    const options: ContextMenuItem[] = [
      {
        key: "user-info",
        type: "custom",
        component: <UserInfoSection />,
      },
      {
        key: "individual-settings",
        label: "Individual Settings",
        type: "option",
        linkProps: { to: "/settings/personal-profile" },
        icon: BiCog,
      },
    ];

    if (flags.enableNotifications) {
      options.push({
        key: "notifications-settings",
        label: "Notifications",
        type: "option",
        linkProps: { to: "/notifications" },
        icon: BiBell,
      });
    }

    options.push(
      {
        key: "help",
        label: "Help",
        type: "sub-menu",
        subMenu: [
          HELP_MENU_EXTERNAL_LINK_OPTION.documentation,
          HELP_MENU_EXTERNAL_LINK_OPTION.discord,
          HELP_MENU_EXTERNAL_LINK_OPTION.emailSupport,
        ],
        icon: BiHelpCircle,
        showDivider: true,
      },
      {
        key: "log-out",
        label: "Log out",
        type: "option",
        onClick: () => {
          logout({ logoutParams: { returnTo: window.location.origin } });
        },
        icon: BiLogOut,
      }
    );

    return options;
  }, [flags.enableNotifications]);

  return (
    <FloatingTree>
      <div ref={refs.setReference} className={styles.wrapper}>
        <Avatar
          userPicture={avatar || user?.picture}
          mode={avatar || user?.picture ? "image" : "letter"}
          userName={user?.name}
          size="large"
          onClick={handleOnClick}
        />
      </div>
      <FloatingNode id={floatingNodeId}>
        {isOpen && (
          <FloatingPortal>
            <div
              ref={refs.setFloating}
              className={styles["menu__floating"]}
              style={{
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
                width: "248px",
                zIndex: 3,
              }}
            >
              <ContextMenu
                fitWidth={true}
                menuOptions={menuOptions}
                onClose={() => {
                  setIsOpen(false);
                }}
              />
            </div>
          </FloatingPortal>
        )}
      </FloatingNode>
    </FloatingTree>
  );
});
