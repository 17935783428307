import styles from "./BlockHeader.module.scss";
import BlockStatus from "../BlockStatus/BlockStatus";
import BlockTitle from "../BlockTitle/BlockTitle";
import type { SAVING_STATUS } from "components/common/SavingStatusIndicator/SavingStatusIndicator";
import { BLOCK_STATUS } from "config/canvasConfig";
import BlockExecutionTime from "../BlockExecutionTime/BlockExecutionTime";
import { BlockComputeTypeInfo } from "../BlockComputeTypeInfo/BlockComputeTypeInfo";
import type { BlockType } from "models/canvas";

type BlockHeaderProps = {
  blockId?: BlockType["id"];
  isActive?: boolean;
  title: string;
  status: BLOCK_STATUS;
  disableEdit: boolean;
  zoom: number;
  showStatus?: boolean;
  showComputeTypeInfo?: boolean;
  contentSavingStatus?: SAVING_STATUS;
  onTitleChange?: (title: string) => void;
  lastRunExecutionTime?: number;
  currentRunExecutionTimeStart?: string | null;
};

export default function BlockHeader({
  blockId = "",
  isActive,
  title,
  status,
  disableEdit,
  zoom,
  showStatus = true,
  showComputeTypeInfo = false,
  contentSavingStatus,
  onTitleChange,
  lastRunExecutionTime,
  currentRunExecutionTimeStart,
}: BlockHeaderProps) {
  const headerScale = Math.min(Math.max(1, 1 / zoom), 1.5);

  return (
    <div className={styles.header}>
      <BlockTitle
        fontSize={14 * headerScale}
        blockId={blockId}
        title={title}
        disableEdit={disableEdit}
        contentSavingStatus={contentSavingStatus}
        onTitleChange={onTitleChange}
      />
      {showComputeTypeInfo && <BlockComputeTypeInfo blockId={blockId} />}
      {showStatus && <BlockStatus iconOnly={!isActive} status={status} />}
      {status === BLOCK_STATUS.COMPLETED ||
      status === BLOCK_STATUS.ERROR ||
      status === BLOCK_STATUS.RUNNING ? (
        <BlockExecutionTime
          status={status}
          lastRunExecutionTime={lastRunExecutionTime}
          currentRunExecutionTimeStart={currentRunExecutionTimeStart}
        />
      ) : null}
    </div>
  );
}
