import styles from "./Notification.module.scss";
import { useCallback, useState } from "react";
import { updateNotification } from "api/http/notification-service";
import { type NotificationType } from "models/notifications";
import Typography from "components/Typography";
import { TimeAgo } from "components/common/TimeAgo/TimeAgo";
import Avatar from "components/common/Avatar/Avatar";
import cn from "classnames";
import { useToastsState } from "store";
import {
  BiCaretRight,
  BiDotsHorizontalRounded,
  BiNotification,
  BiNotificationOff,
} from "react-icons/bi";
import { ExpandedNotification } from "./ExpandedNotification";
import { NOTIFICATION_TYPE } from "config/notificationConfig";
import { DropdownMenuButton } from "components/common/DropdownMenuButton/DropdownMenuButton";
import { type ContextMenuItem } from "components/common/ContextMenu/ContextMenu";

const getNotificationText = (notification: NotificationType): string => {
  switch (notification.type) {
    case NOTIFICATION_TYPE.BLOCK_COMMENT_MENTION:
      return `Mentioned in block comment${notification.properties.canvas_name ? ` on canvas "${notification.properties.canvas_name}"` : ""}`;
    case NOTIFICATION_TYPE.BLOCK_COMMENT_REPLIED_TO:
      return `Mentioned in a block comment thread${notification.properties.canvas_name ? ` on canvas "${notification.properties.canvas_name}"` : ""}`;
    case NOTIFICATION_TYPE.RESOURCE_INVITATION:
      return `Asset has been shared with you`;
    case NOTIFICATION_TYPE.ORGANIZATION_INVITATION:
      return `Invited to an organization ${notification.properties.organization_name ? ` "${notification.properties.organization_name}"` : ""}`;
    case NOTIFICATION_TYPE.CANVAS_INVITATION:
      return `Invited to canvas ${notification.properties.canvas_name ? ` "${notification.properties.canvas_name}"` : ""}`;
    case NOTIFICATION_TYPE.SCHEDULED_JOB_PAUSED:
      return "Scheduled job paused";
    default:
      return notification.type;
  }
};

export type NotificationProps = {
  notification: NotificationType;
  removeNotification: (notificationId: string) => void;
};

export function Notification({
  notification,
  removeNotification,
}: NotificationProps) {
  const addToast = useToastsState((slice) => slice.addToast);
  const [isNotificationExpanded, setIsNotificationExpanded] =
    useState<boolean>(false);
  const [renderedNotification, setRenderedNotification] =
    useState<NotificationType>(notification);

  const updateNotificationReadStatus = useCallback(async (isRead: boolean) => {
    try {
      const response = await updateNotification({
        notificationId: renderedNotification.id,
        payload: { read: isRead },
      });
      setRenderedNotification(response);
    } catch (error) {
      addToast({
        message: isRead
          ? "Failed to mark notification as read. Try again later."
          : "Failed to mark notification as unread. Try again later.",
        variant: "error",
      });
    }
  }, []);

  const notificationActionsMenu: ContextMenuItem[] = [
    {
      key: "mark_notifcation_read_status",
      type: "option",
      icon: BiNotification,
      label: renderedNotification.read ? "Mark as unread" : "Mark as read",
      onClick: () => updateNotificationReadStatus(!renderedNotification.read),
    },
    {
      key: "delete_notification",
      type: "option",
      icon: BiNotificationOff,
      label: "Delete notification",
      onClick: () => removeNotification(notification.id),
    },
  ];

  return (
    <div
      className={cn(styles.notification, {
        [styles.expanded]: isNotificationExpanded,
      })}
    >
      <div
        className={styles.notificationUnexpanded}
        onClick={() => {
          if (!renderedNotification.read && !isNotificationExpanded) {
            updateNotificationReadStatus(true);
          }
          setIsNotificationExpanded((prev) => !prev);
        }}
      >
        <BiCaretRight
          className={cn(styles.caretIcon, {
            [styles.expanded]: isNotificationExpanded,
          })}
        />

        <Avatar
          size="medium"
          userPicture={renderedNotification.properties.created_by_user_avatar}
        />
        <div className={styles.notificationTextContainer}>
          <div className={styles.notificationTextBlock}>
            <Typography
              variant="h3"
              className={cn(styles.notificationText, {
                [styles.read]: renderedNotification.read,
              })}
            >
              {getNotificationText(renderedNotification)}
            </Typography>
            {renderedNotification.properties.created_by_user_name ? (
              <Typography
                variant="caption2"
                className={styles.notificationText}
              >
                {`By ${renderedNotification.properties.created_by_user_name}`}
              </Typography>
            ) : null}
          </div>
          <div className={styles.notificationTimeActionContainer}>
            <TimeAgo
              className={styles.notificationTime}
              date={renderedNotification.time_created}
            />
            <div className={styles.notificationAction}>
              <DropdownMenuButton
                hideChevron
                wrapContextMenuWithFloatingPortal
                buttonVariant="text"
                placement="bottom"
                menuOptions={notificationActionsMenu}
              >
                <BiDotsHorizontalRounded size={16} />
              </DropdownMenuButton>
            </div>
          </div>
        </div>
      </div>
      {isNotificationExpanded ? (
        <ExpandedNotification notification={renderedNotification} />
      ) : null}
    </div>
  );
}
