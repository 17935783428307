import { type StateCreator } from "store";
import type { AssetItemOperationType } from "pages/Assets/types/abstractTypes";
import type { BaseAssetCanvasType } from "pages/Assets/types/baseAssetTypes";
import type {
  CreatedQueryType,
  QueryItemType,
  QueryVersionType,
} from "pages/Assets/types/queryTypes";
import { DATA_LOAD_STATUS } from "config/appConfig";

export type QueryVersionTypeWithAssetId = QueryVersionType & {
  asset_id: string;
};

interface QueriesAssets {
  queriesData: Record<string, QueryItemType>;
  selectedQuery: {
    id: string | null;
    versionNumber: number | null;
  };
  createdQueryData: {
    assetOperationType: AssetItemOperationType;
    queryData: CreatedQueryType;
    error: string | null;
    loading: boolean;
  };
  isDisplayArchivedQueries: boolean;
  canvasQueriesData: Record<string, QueryItemType>;
  queriesDataLoadState: DATA_LOAD_STATUS;
  canvasQueriesDataLoadState: DATA_LOAD_STATUS;
}

interface QueriesActions {
  setCanvasQuery: (data: QueryItemType) => void;
  setQueriesData: (queriesData: Record<string, QueryItemType>) => void;
  setCreatedQueryData: (createdQueryData: Partial<CreatedQueryType>) => void;
  setCreatedQueryError: (error: string | null) => void;
  setCreatedQueryLoading: (loading: boolean) => void;
  setCreatedQueryAssetOperationType: (
    assetOperationType: AssetItemOperationType
  ) => void;
  setSelectedQueryId: (selectedQueryId: string | null) => void;
  setSelectedQueryVersionNumber: (
    selectedQueryVersionNumber: number | null
  ) => void;
  clearCreatedQueryData: () => void;
  clearSelectedQuery: () => void;
  deleteQueryById: (id: string) => void;
  updateQueryVersion: (version: QueryVersionTypeWithAssetId) => void;
  updateQueryVersionData: (
    assetId: QueryVersionType["asset_id"],
    version: QueryVersionType["version"],
    data: Partial<QueryVersionType>
  ) => void;
  setIsDisplayArchivedQueries: (isDisplay: boolean) => void;
  deleteQueryVersion: (version: number) => void;
  setCanvasQueriesData: (
    canvasQueriesData: Record<string, QueryItemType>
  ) => void;
  setQueriesDataLoadState: (dataLoadState: DATA_LOAD_STATUS) => void;
  setCanvasQueriesDataLoadState: (dataLoadState: DATA_LOAD_STATUS) => void;
  deleteCanvasQueryById: (id: string) => void;
  deleteQueryVersionCanvasUsage: (data: {
    assetId: string;
    versionId: string;
    canvasId: string;
  }) => void;
  addQueryVersionCanvasUsage: (data: {
    assetId: string;
    versionId: string;
    canvasData: BaseAssetCanvasType;
  }) => void;
  clearStore: () => void;
}

export interface QueriesAssetsSlice {
  queries: QueriesAssets;
  queriesActions: QueriesActions;
}

const QUERIES_INITIAL_STATE: QueriesAssets = {
  queriesData: {},
  selectedQuery: {
    id: null,
    versionNumber: null,
  },
  createdQueryData: {
    assetOperationType: null,
    queryData: {
      name: "",
    },
    error: null,
    loading: false,
  },
  isDisplayArchivedQueries: false,
  canvasQueriesData: {},
  queriesDataLoadState: DATA_LOAD_STATUS.NOT_LOADED,
  canvasQueriesDataLoadState: DATA_LOAD_STATUS.NOT_LOADED,
};

const queriesAssetsSlice: StateCreator<QueriesAssetsSlice> = function (
  set,
  getState
) {
  return {
    queries: { ...QUERIES_INITIAL_STATE },
    queriesActions: {
      setCanvasQuery: (data) => {
        const assetId = data.query.id;

        set(
          (store) => {
            store.assetsSlice.queries.canvasQueriesData[assetId] = data;
          },
          false,
          "assets/setCanvasQuery"
        );
      },
      setQueriesData: (queriesData) => {
        set((store) => {
          store.assetsSlice.queries.queriesData = queriesData;
        });
      },
      setCreatedQueryData: (createdQueryData) => {
        set((store) => {
          store.assetsSlice.queries.createdQueryData.queryData = {
            ...store.assetsSlice.queries.createdQueryData.queryData,
            ...createdQueryData,
          };
        });
      },
      setCreatedQueryError: (error) => {
        set((store) => {
          store.assetsSlice.queries.createdQueryData.error = error;
        });
      },
      setCreatedQueryLoading: (loading) => {
        set((store) => {
          store.assetsSlice.queries.createdQueryData.loading = loading;
        });
      },
      setCreatedQueryAssetOperationType: (assetOperationType) => {
        set((store) => {
          store.assetsSlice.queries.createdQueryData.assetOperationType =
            assetOperationType;
        });
      },
      setSelectedQueryId: (id) => {
        set((store) => {
          store.assetsSlice.queries.selectedQuery.id = id;
        });
      },
      setSelectedQueryVersionNumber: (versionNumber) => {
        set((store) => {
          store.assetsSlice.queries.selectedQuery.versionNumber = versionNumber;
        });
      },
      clearCreatedQueryData: () => {
        set((store) => {
          store.assetsSlice.queries.createdQueryData = {
            ...QUERIES_INITIAL_STATE.createdQueryData,
          };
        });
      },
      clearSelectedQuery: () => {
        set((store) => {
          store.assetsSlice.queries.selectedQuery = {
            ...QUERIES_INITIAL_STATE.selectedQuery,
          };
        });
      },
      deleteQueryById: (id) => {
        set((store) => {
          delete store.assetsSlice.queries.queriesData[id];
        });
      },
      updateQueryVersion: (updatedVersionData) => {
        set((store) => {
          if (
            !store.assetsSlice.queries.queriesData[updatedVersionData.asset_id]
              ?.versions
          ) {
            return;
          }

          store.assetsSlice.queries.queriesData[
            updatedVersionData.asset_id
          ].versions[updatedVersionData.version] = updatedVersionData;
        });
      },
      updateQueryVersionData: (assetId, versionNumber, data) => {
        set(
          (store) => {
            const versionData =
              store.assetsSlice.queries.queriesData?.[assetId]?.versions?.[
                versionNumber
              ];

            if (!versionData) {
              return;
            }

            Object.assign(versionData, data);
          },
          false,
          "assets/updateQueryVersionData"
        );
      },
      setIsDisplayArchivedQueries: (isDisplay) => {
        set((store) => {
          store.assetsSlice.queries.isDisplayArchivedQueries = isDisplay;
        });
      },
      deleteQueryVersion: (versionNumber) => {
        set((store) => {
          if (!store.assetsSlice.queries.selectedQuery.id) {
            return;
          }

          delete store.assetsSlice.queries.queriesData[
            store.assetsSlice.queries.selectedQuery.id
          ].versions[versionNumber];
        });
      },
      setCanvasQueriesData: (canvasQueriesData) => {
        set((store) => {
          store.assetsSlice.queries.canvasQueriesData = canvasQueriesData;
        });
      },
      setQueriesDataLoadState: (dataLoadState) => {
        set(
          (store) => {
            store.assetsSlice.queries.queriesDataLoadState = dataLoadState;
          },
          false,
          "queriesDataLoadState"
        );
      },
      setCanvasQueriesDataLoadState: (dataLoadState) => {
        set(
          (store) => {
            store.assetsSlice.queries.canvasQueriesDataLoadState =
              dataLoadState;
          },
          false,
          "assets/setCanvasQueriesDataLoadState"
        );
      },
      deleteCanvasQueryById: (id) => {
        const { [id]: _, ...rest } =
          getState().assetsSlice.queries.canvasQueriesData;
        set(
          (store) => {
            store.assetsSlice.queries.canvasQueriesData = rest;
          },
          false,
          "assets/deleteCanvasQuery"
        );
      },
      deleteQueryVersionCanvasUsage: ({ assetId, versionId, canvasId }) => {
        const { [assetId]: assetData } =
          getState().assetsSlice.queries.queriesData;
        if (!assetData) {
          return;
        }
        const versionData = Object.values(assetData.versions).find(
          (version) => {
            return version.id === versionId;
          }
        );

        if (!versionData) {
          return;
        }
        const versionNumber = versionData.version;
        const canvases = versionData.canvases;

        if (!canvases?.length) {
          return;
        }

        set(
          (store) => {
            store.assetsSlice.queries.queriesData[assetId].versions[
              versionNumber
            ].canvases = canvases.filter((item) => item.canvas_id !== canvasId);
          },
          false,
          "assets/deleteQueryVersionCanvasUsage"
        );
      },
      addQueryVersionCanvasUsage: ({ assetId, versionId, canvasData }) => {
        const { [assetId]: assetData } =
          getState().assetsSlice.queries.queriesData;
        if (!assetData) {
          return;
        }
        const versionData = Object.values(assetData.versions).find(
          (version) => {
            return version.id === versionId;
          }
        );

        if (!versionData) {
          return;
        }
        const versionNumber = versionData.version;
        const canvases = versionData.canvases;

        if (!Array.isArray(canvases)) {
          return;
        }

        const canvasId = canvasData.canvas_id;

        if (canvases.findIndex((el) => el.canvas_id === canvasId) >= 0) {
          return;
        }

        set(
          (store) => {
            store.assetsSlice.queries.queriesData[assetId].versions[
              versionNumber
            ].canvases = [...canvases, canvasData];
          },
          false,
          "assets/addQueryVersionCanvasUsage"
        );
      },
      clearStore: () => {
        set(
          (store) => {
            store.assetsSlice.queries = { ...QUERIES_INITIAL_STATE };
          },
          false,
          "assets/clearQueriesStore"
        );
      },
    },
  };
};

export default queriesAssetsSlice;
