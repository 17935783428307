import styles from "./Queries.module.scss";
import { CodeEditorAndResults, SelectConnection } from "./components";

export function Queries() {
  return (
    <div className={styles.wrapper}>
      <SelectConnection />
      <CodeEditorAndResults />
    </div>
  );
}
