import userService from "api/http/user-service";
import { CANVAS_PRIVACY } from "config/canvasConfig";
import {
  type ResourceMember,
  type User,
  RESOURCE_TYPE,
  UserPermission,
} from "models/user";

export const MEMBERS_COUNT_DEFAULT = 1;

export const USER_PERMISSIONS = [
  UserPermission.canView,
  UserPermission.canEdit,
];

export const MESSAGE = {
  getAddUsername:
    "We noticed that you haven't added a username yet. To publish a canvas to the Zerve community you will need to add a username.",
  tryAgainOrContactSupport: "Try again later or contact support",
  failedToGetMembersList: "Failed to retrieve member list",
  failedToGetCanvasAccessRequestList:
    "Failed to retrieve canvas access request",
  linkCopiedToClipboard: "Link copied to clipboard",
  failedCopyLinkToClipboard: "Failed to copy link to clipboard",
  cannotInvite: "Users with read permission cannot invite other people",
  failedLoadResourceData: "Failed to load resource permission",
  getSucceedToInviteByEmailMessage: (email: string) =>
    `${email} has been invited`,
  getFailedToInviteByEmailMessage: (email: string) =>
    `Failed to invite ${email}`,
  getSucceedToRemoveMemberMessage: (email: string) =>
    `${email} permission has been removed`,
  getFailedToRemoveMemberMessage: (email: string) =>
    `Failed to remove ${email} access`,
  getSucceedToUpdateMemberPermissionMessage: (email: string) =>
    `${email} permission has been updated`,
  getFailedToUpdateMemberPermissionMessage: (email: string) =>
    `Failed to update ${email} permission`,
  getSucceedToRemoveInvitationMessage: (email: string) =>
    `${email} invitation has been removed`,
  getFailedToRemoveInvitationMessage: (email: string) =>
    `Failed to remove ${email} invitation`,
  getLeaveResourceMembershipConfirmMessage: (resourceType: RESOURCE_TYPE) => {
    switch (resourceType) {
      case RESOURCE_TYPE.CANVAS:
        return "Are you sure you want to leave the canvas?";
      case RESOURCE_TYPE.ASSET_VERSION:
        return "Are you sure you want to leave the asset version?";
      case RESOURCE_TYPE.CONNECTION_ASSET:
        return "Are you sure you want to leave the connection?";
      default:
        return "Are you sure you want to leave the resource?";
    }
  },
  getTogglePrivacyConfirm: (privacy: CANVAS_PRIVACY) => {
    switch (privacy) {
      case CANVAS_PRIVACY.PUBLIC:
        return "Are you sure you want to make the canvas accessible to anyone on the internet?";
      case CANVAS_PRIVACY.COMMUNITY:
        return "Are you sure you want to publish the canvas to the Zerve community? All users will be able to view the canvas including any future canvas updates. You can unpublish the canvas at any time.";
      case CANVAS_PRIVACY.PRIVATE:
      default:
        return "Are you sure you want to make the canvas private?";
    }
  },
  getSucceedToUpdatePrivacyStatus: (privacy: CANVAS_PRIVACY) => {
    switch (privacy) {
      case CANVAS_PRIVACY.COMMUNITY:
        return `The canvas has been published to the Zerve community`;
      case CANVAS_PRIVACY.PUBLIC:
        return `The canvas has been made public`;
      case CANVAS_PRIVACY.PRIVATE:
      default:
        return `The canvas has been made private`;
    }
  },
  getFailedToUpdatePrivacyStatus: (privacy: CANVAS_PRIVACY) => {
    switch (privacy) {
      case CANVAS_PRIVACY.COMMUNITY:
        return `Failed to publish canvas to the Zerve community`;
      case CANVAS_PRIVACY.PUBLIC:
        return `Failed to make canvas public`;
      case CANVAS_PRIVACY.PRIVATE:
      default:
        return `Failed to make canvas private`;
    }
  },
  getFailedToLoadPrivacyStatus: () => {
    return `Failed to load canvas privacy status`;
  },
};

export const resourceMemberHelper = Object.freeze({
  isUser: function (member: ResourceMember): member is User {
    return "user_id" in member;
  },
  getMemberEmail: function (member: ResourceMember) {
    if (this.isUser(member)) {
      return member.email;
    }

    return member.user_email;
  },
  getIsOwner: function (member: ResourceMember) {
    return this.isUser(member) && member.is_owner;
  },
  // update resource member permission
  updateResourceMemberPermission: function (
    resourceId: string,
    member: ResourceMember,
    permission: UserPermission
  ) {
    if (this.isUser(member)) {
      return userService.updateResourceMember(
        resourceId,
        member.user_id,
        permission
      );
    }

    return userService.updateResourceInvites(resourceId, [
      { [member.user_email]: permission },
    ]);
  },
  getSucceedToUpdateMemberPermissionMessage: function (member: ResourceMember) {
    return MESSAGE.getSucceedToUpdateMemberPermissionMessage(
      this.getMemberEmail(member)
    );
  },
  getFailedToUpdateMemberPermissionMessage: function (member: ResourceMember) {
    return MESSAGE.getFailedToUpdateMemberPermissionMessage(
      this.getMemberEmail(member)
    );
  },
  // delete resource member
  deleteResourceMember: function (resourceId: string, member: ResourceMember) {
    if (this.isUser(member)) {
      return userService.deleteResourceMember(resourceId, member.user_id);
    }

    return userService.deleteResourceInvite(resourceId, member.id);
  },
  getSucceedToRemoveMemberMessage: function (member: ResourceMember) {
    if (this.isUser(member)) {
      return MESSAGE.getSucceedToRemoveMemberMessage(member.email);
    }

    return MESSAGE.getSucceedToRemoveInvitationMessage(member.user_email);
  },
  getFailedToRemoveMemberMessage: function (member: ResourceMember) {
    if (this.isUser(member)) {
      return MESSAGE.getFailedToRemoveMemberMessage(member.email);
    }

    return MESSAGE.getFailedToRemoveInvitationMessage(member.user_email);
  },
  getDeleteMemberButtonTooltip: function (member: ResourceMember) {
    if (this.isUser(member)) {
      return `Remove ${member.email}`;
    }

    return `Revoke Invitation for ${member.user_email}`;
  },
});
