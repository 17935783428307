import { USER_API_URL } from "config/appConfig";
import { callAPI } from "./call-api";
import { type NotificationType } from "models/notifications";

export const getNotifications = async () =>
  await callAPI<NotificationType[]>({
    method: "GET",
    url: `${USER_API_URL}/notifications`,
  });

export const deleteNotification = async (notificationId: string) =>
  await callAPI({
    method: "DELETE",
    url: `${USER_API_URL}/notifications/${notificationId}`,
  });

type NotificationUpdatePayload = {
  read: boolean;
};

export async function updateNotification({
  notificationId,
  payload,
}: {
  notificationId: string;
  payload: NotificationUpdatePayload;
}) {
  return await callAPI<NotificationType>({
    url: `${USER_API_URL}/notifications/${notificationId}`,
    method: "PATCH",
    data: payload,
  });
}

const notificationService = {
  getNotifications,
  updateNotification,
  deleteNotification,
};

export default notificationService;
