import styles from "./SideBarSearch.module.scss";
import { forwardRef, type ChangeEventHandler, type ReactNode } from "react";
import { BiSearchAlt } from "react-icons/bi";
import cn from "classnames";

type SideBarSearchProps = {
  value: string;
  autoFocus?: boolean;
  placeholder?: string;
  containerClassname?: string;
  inputClassName?: string;
  innerControls?: ReactNode;
  disabled?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
};

export const SideBarSearch = forwardRef<HTMLInputElement, SideBarSearchProps>(
  function SideBarSearch(
    {
      value,
      autoFocus = false,
      placeholder = "Find...",
      containerClassname,
      inputClassName,
      innerControls,
      disabled = false,
      onChange,
    },
    ref
  ) {
    return (
      <div className={cn(styles.container, containerClassname)}>
        <BiSearchAlt size={20} className={styles.icon} />
        <input
          ref={ref}
          name="search"
          type="text"
          autoFocus={autoFocus}
          autoComplete="off"
          placeholder={placeholder}
          className={cn(styles.input, inputClassName)}
          value={value}
          disabled={disabled}
          onChange={onChange}
        />
        {innerControls}
      </div>
    );
  }
);
