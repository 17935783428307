import styles from "./CanvasCreateCard.module.scss";
import cn from "classnames";
import { BiPlus } from "react-icons/bi";

type CanvasCreateCardProps = {
  onCreateCanvas?: () => void;
};

export function CanvasCreateCard({ onCreateCanvas }: CanvasCreateCardProps) {
  return (
    <div className={cn(styles.card, styles.empty)} onClick={onCreateCanvas}>
      <BiPlus size={32} className={styles["create-icon"]} />
      <h3 className={styles.placeholder}>Create New Canvas</h3>
    </div>
  );
}
