import { type SVGProps } from "react";

const PostgreSQLIcon = ({
  color = "currentColor",
  width = "20",
  height = "20",
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      id="postresql"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g fill={color} fillRule="nonzero">
        <g>
          <path d="M15.1681 4.94157C15.2757 5.12093 15.4551 5.37204 15.5627 5.76664C15.6704 6.12537 15.6703 6.41235 15.6703 6.69934C15.6703 7.09394 15.6345 7.41679 15.5986 7.73965C15.5627 7.99076 15.5627 8.09837 15.5269 8.17012C15.5269 8.20599 15.5269 8.24187 15.491 8.31361C15.491 8.49297 15.491 8.56472 15.5269 8.70821C15.5269 8.8517 15.5627 8.99519 15.5627 9.2463C15.5986 9.71265 15.5986 10.0355 15.5269 10.4301V10.5736C15.4551 10.8965 15.3834 11.2193 15.204 11.5063C15.2399 11.578 15.2757 11.6139 15.3116 11.6857C15.491 11.4345 15.6345 11.1834 15.778 10.8965C16.2084 10.1072 16.4596 9.42567 16.6389 8.92345C16.9618 7.99076 17.1411 7.30917 17.2488 6.80695C17.4999 5.73077 17.5357 5.22855 17.4281 4.65459C17.4281 4.47522 17.3564 4.15237 17.177 3.82951C16.7824 3.11206 16.1726 2.7892 15.7421 2.57396C15.4551 2.43047 14.7018 2.03587 13.6615 2C13.1234 2 12.657 2.10762 12.2266 2.21524C12.9799 2.53809 13.4462 2.93269 13.6973 3.11206C14.2713 3.61428 14.5942 4.11649 15.1681 4.94157Z" />
          <path d="M13.9117 11.1121C13.6965 10.7175 13.6606 10.6099 13.6247 10.4306C13.5889 10.2871 13.5171 10.1077 13.266 9.56961C12.7279 8.34993 12.6562 7.99121 12.6562 7.70422C12.6203 7.30962 12.5844 6.77153 13.0149 6.4128C13.6606 5.83884 14.4857 5.87471 14.9162 6.0182C14.9162 5.98233 14.9162 5.98233 14.9162 5.94646C14.8444 5.65948 14.7009 5.44424 14.5933 5.30075C14.0552 4.47567 13.7682 4.0452 13.266 3.61473C13.0508 3.43536 12.5127 3.00489 11.7235 2.71791C11.3648 2.61029 10.9343 2.4668 10.3962 2.4668C10.2168 2.4668 10.0375 2.4668 9.8581 2.50267C9.14065 2.64616 8.45907 3.04076 7.92097 3.72234C7.38288 4.40393 7.23939 5.04964 7.0959 5.80297C7.02415 6.08995 7.02415 6.34106 6.98828 6.62804C7.20352 6.44868 7.4905 6.23344 7.92097 6.12582C8.17208 6.05408 8.81779 5.83884 9.35589 6.16169C9.53525 6.26931 9.78636 6.48455 10.0375 7.09439C10.5756 8.60104 10.0375 10.1436 9.96572 10.2871C9.92985 10.4306 9.8581 10.5382 9.82223 10.6099C9.75049 10.7893 9.67874 10.9328 9.60699 11.2198C9.57112 11.4709 9.53525 11.6861 9.53525 11.9372C9.64287 11.9372 9.75049 11.9731 9.8581 12.0448C10.0375 12.2242 10.0733 12.4753 10.1092 12.5829C10.181 13.1569 10.181 13.8743 10.181 14.5918C10.181 15.1658 10.181 15.7039 10.2168 16.0626C10.2886 16.8877 10.5397 17.4616 10.8984 17.7486C11.1495 17.9638 11.4724 17.9638 11.6517 17.9997C11.6876 17.9997 11.6876 17.9997 11.7235 17.9997C12.2616 17.9997 13.1225 17.641 13.4454 17.1388C13.6247 16.8518 13.6965 16.6007 13.7682 16.2419C13.8041 16.0267 13.8041 15.9191 13.84 15.7039C13.84 15.5962 13.8759 15.4169 13.8759 15.2016C13.8759 15.0223 13.9117 14.807 13.9476 14.5559C13.9835 13.9461 14.0911 13.2286 14.127 12.834C14.1628 12.3677 14.3781 11.9731 14.5574 11.722C14.3063 11.6861 14.0552 11.4709 13.9117 11.1121Z" />
          <path d="M14.8462 8.09742C14.8462 8.02567 14.8821 7.91805 14.9179 7.70281C14.9538 7.41583 14.9897 7.16472 14.9897 6.87774C14.9179 6.84187 14.8821 6.80599 14.8103 6.77012C14.8103 6.77012 14.057 6.44727 13.483 6.94949C13.3395 7.09298 13.3395 7.37996 13.3754 7.63107C13.3754 7.81043 13.4113 8.06154 13.9494 9.28122C14.2005 9.85518 14.2722 10.0345 14.3081 10.178C14.344 10.3215 14.3798 10.3933 14.5951 10.7879C14.6309 10.8596 14.6668 10.9314 14.7386 11.0031C14.8103 10.8955 14.8462 10.7161 14.8821 10.4291L14.9179 10.3215C14.9897 9.99867 14.9538 9.74756 14.9538 9.31709C14.9538 9.10185 14.9179 8.95836 14.9179 8.85074C14.8821 8.67138 14.8821 8.52789 14.8821 8.31265C14.8103 8.20503 14.8103 8.16916 14.8462 8.09742ZM14.1646 7.34409C14.1287 7.37996 14.057 7.45171 13.9494 7.48758C13.8059 7.52345 13.6983 7.41583 13.6624 7.41583C13.6265 7.37996 13.5189 7.27234 13.5548 7.16472C13.5906 7.09298 13.6265 7.09298 13.77 7.0571C13.8776 7.02123 14.1646 6.98536 14.2363 7.0571C14.344 7.12885 14.2005 7.30821 14.1646 7.34409Z" />
          <path d="M9.32066 7.41741C9.21304 7.13043 9.10542 6.95107 8.96193 6.87932C8.71082 6.73583 8.31622 6.84345 8.10098 6.9152C7.70638 7.02281 7.45527 7.27392 7.27591 7.41741C7.16829 7.52503 7.06067 7.63265 6.95305 7.77614C6.95305 8.02725 6.98893 8.27836 7.0248 8.52947V8.56534L6.95305 9.2828C6.88131 9.60565 6.84543 10.2155 7.20416 10.8253C7.38353 11.1841 7.63464 11.471 7.95749 11.6504C8.17273 11.7939 8.45971 11.9015 8.78256 11.9733C8.78256 11.7221 8.81844 11.4352 8.85431 11.1841C8.92606 10.8253 8.9978 10.646 9.10542 10.4307C9.14129 10.3231 9.17717 10.2514 9.24891 10.1437C9.28478 10.0361 9.787 8.67296 9.32066 7.41741ZM9.03367 7.74027C8.92606 7.84789 8.74669 7.84789 8.74669 7.84789C8.56733 7.84789 8.45971 7.74027 8.38796 7.63265C8.35209 7.59678 8.31622 7.52503 8.35209 7.45329C8.38796 7.38154 8.45971 7.34567 8.49558 7.34567C8.67495 7.27392 8.81844 7.34567 8.92606 7.38154C9.03367 7.41741 9.14129 7.45329 9.14129 7.52503C9.17717 7.63265 9.06955 7.7044 9.03367 7.74027Z" />
          <path d="M15.4904 13.2275C16.0643 13.1557 16.4589 12.8688 16.5307 12.797C16.7818 12.6177 17.1046 12.2589 17.0329 12.1154C16.997 12.0437 16.8894 12.0796 16.3154 12.1154C16.172 12.1154 15.7415 12.223 15.4545 12.1513C15.4186 12.1513 15.4186 12.1513 15.3828 12.1513C15.311 12.1154 15.2393 12.0796 15.2034 12.0078C15.024 12.2589 14.7729 12.5818 14.737 13.0123C14.737 13.084 14.737 13.1199 14.7012 13.1916C14.9882 13.2634 15.2393 13.2634 15.4904 13.2275Z" />
          <path d="M7.74201 12.296C7.70614 12.2601 7.63439 12.2601 7.59852 12.2242C7.16805 11.9731 6.84519 11.5785 6.59408 11.148C6.16361 10.3588 6.19948 9.56963 6.27123 9.17503L6.3071 8.56519C6.16361 7.59663 6.19948 6.66393 6.37885 5.73124C6.52234 4.94204 6.7017 4.15284 7.34741 3.32776C7.56265 3.04078 7.81376 2.78967 8.06487 2.61031C7.4909 2.3592 6.88107 2.21571 6.27123 2.17984C5.80488 2.14396 5.15917 2.10809 4.40584 2.46682C4.22648 2.53856 3.68839 2.78967 3.25791 3.36364C2.97093 3.72237 2.75569 4.26046 2.64808 4.87029C2.54046 5.40839 2.50458 6.0541 2.71982 7.27377C2.86331 8.13472 3.0068 8.70868 3.29379 9.89249C3.32966 10.036 3.5449 10.6817 4.01124 12.1166L4.04711 12.1883C4.11886 12.4395 4.3341 12.9775 4.80044 13.5156C5.1233 13.8744 5.41028 14.0896 5.66139 14.0896C5.69726 14.0896 5.69726 14.0896 5.73314 14.0896C6.19948 14.0896 6.52234 13.7309 6.80932 13.4439C6.80932 13.408 7.4909 12.5829 7.74201 12.296Z" />
        </g>
      </g>
    </g>
  </svg>
);

export default PostgreSQLIcon;
