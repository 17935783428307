import { type StateCreator } from "store";
import { AI_MODEL } from "config/appConfig";

export interface ProfileSettings {
  avatar: string;
  email: string;
  username: string;
  firstName: string;
  lastName: string;
  bio: string;
  city: string;
  country: string;
  birthday: string;
  timezone: string;
  githubUsername: string | null;
  githubAppInstallationId: string | null;
  socialMediaLinks: string[];
  isAccountDeleted: boolean | string | null;
  subscriptionTier: number | null;
}

export interface SecuritySettingsFields {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export interface SecuritySettings {
  fields: SecuritySettingsFields;
  isUpdated: boolean;
}

export interface PreferencesSettings {
  showLineNumbers: boolean;
  codeFontSize: number;
  aiModel: AI_MODEL;
  autoComplete: boolean;
}

type State = {
  profile: ProfileSettings;
  security: SecuritySettings;
  preferences: PreferencesSettings;
  errors: {
    profile: Partial<ProfileSettings>;
    security: Partial<SecuritySettingsFields>;
  };
};

type Actions = {
  updateProfileSettings: (data: Partial<ProfileSettings>) => void;
  updateSecuritySettingsFields: (
    fieldsData: Partial<SecuritySettingsFields>
  ) => void;
  updateSecuritySettingsIsUpdated: (isUpdated: boolean) => void;
  updateProfileSettingsErrors: (errorsData: Partial<ProfileSettings>) => void;
  updatePreferencesSettings: (data: Partial<PreferencesSettings>) => void;
  updateSecuritySettingsErrors: (
    errorsData: Partial<SecuritySettingsFields>
  ) => void;
};

const initialState: State = {
  profile: {
    avatar: "",
    email: "",
    username: "",
    firstName: "",
    lastName: "",
    bio: "",
    birthday: "",
    city: "",
    country: "",
    timezone: "",
    githubUsername: "",
    githubAppInstallationId: "",
    socialMediaLinks: [],
    isAccountDeleted: false,
    subscriptionTier: null,
  },
  preferences: {
    showLineNumbers: false,
    codeFontSize: 12,
    aiModel: AI_MODEL.OPENAI_GPT,
    autoComplete: true,
  },
  security: {
    fields: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    isUpdated: false,
  },
  errors: {
    profile: {},
    security: {},
  },
};

export type UserSettingsSlice = State & Actions;

export const createUserSettingsSlice: StateCreator<UserSettingsSlice> = (
  set
) => ({
  ...initialState,

  updateProfileSettings: (data) => {
    set(
      (store) => {
        store.userSettingsSlice.profile = {
          ...store.userSettingsSlice.profile,
          ...data,
        };
      },
      false,
      "userSettings/updateProfileSettings"
    );
  },

  updateSecuritySettingsFields: (fieldsData) => {
    set(
      (store) => {
        store.userSettingsSlice.security.fields = {
          ...store.userSettingsSlice.security.fields,
          ...fieldsData,
        };
      },
      false,
      "userSettings/updateSecuritySettingsFields"
    );
  },

  updateSecuritySettingsIsUpdated: (isUpdated) => {
    set(
      (store) => {
        store.userSettingsSlice.security.isUpdated = isUpdated;
      },
      false,
      "userSettings/updateSecuritySettingsIsUpdated"
    );
  },

  updatePreferencesSettings: (data) => {
    set(
      (store) => {
        store.userSettingsSlice.preferences = {
          ...store.userSettingsSlice.preferences,
          ...data,
        };
      },
      false,
      "userSettings/updatePreferencesSettings"
    );
  },

  updateProfileSettingsErrors: (errorsData) => {
    set(
      (store) => {
        store.userSettingsSlice.errors.profile = {
          ...store.userSettingsSlice.errors.profile,
          ...errorsData,
        };
      },
      false,
      "userSettings/updateProfileSettingsErrors"
    );
  },

  updateSecuritySettingsErrors: (errorsData) => {
    set(
      (store) => {
        store.userSettingsSlice.errors.security = {
          ...store.userSettingsSlice.errors.security,
          ...errorsData,
        };
      },
      false,
      "userSettings/updateSecuritySettingsErrors"
    );
  },
});
