import { NOTIFICATIONS_WS_URL } from "config/appConfig";
import { type StateCreator } from "store";
import { type NotificationType } from "models/notifications";

type State = {
  ws: WebSocket | null;
  wsUserID: string | null;
  notifications: NotificationType[];
};

type Actions = {
  openConnection: ({
    userID,
    token,
    onMessage,
  }: {
    userID: string;
    token: string;
    onMessage: (event: MessageEvent) => void;
  }) => void;
  setNotifications: (notifications: NotificationType[]) => void;
  addNotifications: (
    notifications: NotificationType | NotificationType[]
  ) => void;
};

const getInitialState = (): State => ({
  ws: null,
  wsUserID: null,
  notifications: [],
});

export type NotificationSlice = State & Actions;

export const createNotificationSlice: StateCreator<NotificationSlice> = (
  set,
  get
) => ({
  ...getInitialState(),
  openConnection: ({ userID, token, onMessage }) => {
    if (get().notificationSlice.wsUserID) {
      return;
    }
    const ws = new WebSocket(
      `${NOTIFICATIONS_WS_URL}/notifications/ws/${userID}?token=${token}`
    );
    ws.onmessage = onMessage;
    set(
      (store) => {
        store.notificationSlice.ws = ws;
        store.notificationSlice.wsUserID = userID;
        store.notificationSlice.notifications = [];
      },
      false,
      "notifications/openConnection"
    );
  },
  setNotifications: (notifications) => {
    set(
      (store) => {
        store.notificationSlice.notifications = notifications;
      },
      false,
      "notifications/setNotifications"
    );
  },
  addNotifications: (notifications) => {
    set(
      (store) => {
        if (Array.isArray(notifications)) {
          store.notificationSlice.notifications = [
            ...store.notificationSlice.notifications,
            ...notifications,
          ];
        } else {
          store.notificationSlice.notifications.push(notifications);
        }
      },
      false,
      "notifications/addNotifications"
    );
  },
});
