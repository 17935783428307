import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAssetsState, useToastsState, useUserState } from "store";
import type { ConstantItemType } from "pages/Assets/types/constantTypes";
import {
  deleteConstantById,
  deleteConstantVersion,
  putArchiveConstantVersion,
  putConstantAssetArchive,
  putConstantVersionStatus,
} from "api/http/assets/constants-service";
import { getAssetsBaseUrl } from "pages/Assets/utils";

export default function useCurrentConstantsData() {
  const userID = useUserState((slice) => slice.userID);
  const addToast = useToastsState((slice) => slice.addToast);
  const navigate = useNavigate();
  const { orgID = "", workspaceID = "" } = useParams<{
    orgID: string;
    workspaceID: string;
  }>();

  const baseURL = useMemo(() => {
    return getAssetsBaseUrl({
      organizationId: orgID,
      workspaceId: workspaceID,
    });
  }, [orgID, workspaceID]);

  const {
    constantsData,
    isDisplayArchivedConstants,
    selectedConstant,
    constantsDataLoadState,
  } = useAssetsState((slice) => slice.constants);

  const {
    setSelectedConstantId,
    setSelectedConstantVersionNumber,
    setConstantsData,
    setIsDisplayArchivedConstants,
    clearSelectedConstant,
    updateConstantVersion,
    deleteConstantVersion: deleteConstantVersionStore,
    deleteConstantById: deleteConstantByIdFromStore,
    clearStore: clearConstantsStore,
  } = useAssetsState((slice) => slice.constantsActions);

  const currentConstantsData = {
    assetsData: constantsData,
    activeItemData: selectedConstant.id
      ? constantsData[selectedConstant.id]
      : null,
    activeVersionData:
      selectedConstant.id && selectedConstant.versionNumber
        ? constantsData[selectedConstant.id]?.versions[
            selectedConstant.versionNumber
          ]
        : null,
    activeItemId: selectedConstant.id,
    activeItemVersionId:
      selectedConstant.id && selectedConstant.versionNumber
        ? constantsData[selectedConstant.id]?.versions[
            selectedConstant.versionNumber
          ]?.id
        : null,
    isDisplayArchivedItems: isDisplayArchivedConstants,
    assetDataLoadState: constantsDataLoadState,
  };

  const currentConstantsActions = {
    setActiveItemId: (id: string) => {
      setSelectedConstantId(id);
    },
    setActiveVersionNumber: (versionNumber) => {
      setSelectedConstantVersionNumber(versionNumber);
    },
    setIsDisplayArchivedItems: (isDisplay) => {
      if (selectedConstant.id && selectedConstant.versionNumber && !isDisplay) {
        const isSelectedConstantArchived =
          constantsData[selectedConstant.id]?.constant.archive;
        const isSelectedVersionArchived =
          constantsData[selectedConstant.id]?.versions[
            selectedConstant.versionNumber
          ]?.archive;

        if (isSelectedConstantArchived || isSelectedVersionArchived) {
          clearSelectedConstant();
          navigate(`${baseURL}/constants`);
        }
      }
      setIsDisplayArchivedConstants(isDisplay);
    },
    setAssetsData: (data) => {
      setConstantsData(data as Record<string, ConstantItemType>);
    },
    deleteAssetItem: (assetItemId) => {
      const currentAssetItemId = assetItemId ?? selectedConstant.id;
      if (currentAssetItemId) {
        deleteConstantById(currentAssetItemId)
          .then(() => {
            clearSelectedConstant();
            deleteConstantByIdFromStore(currentAssetItemId);
            navigate(`${baseURL}/constants`, { replace: true });
            addToast({
              variant: "success",
              message: "Constant has been successfully deleted",
            });
          })
          .catch((error) => {
            console.error(error);
            addToast({
              variant: "error",
              message:
                "Failed to delete constant. Please refresh the page and try again",
            });
          });
      }
    },
    deleteAssetVersion: (versionId: string, versionNumber: number) => {
      const assetId = selectedConstant.id;

      if (assetId) {
        void deleteConstantVersion(versionId)
          .then(() => {
            const assetData = constantsData[assetId];
            const versionsList = Object.values(assetData.versions);

            if (versionsList.length <= 1) {
              clearSelectedConstant();
              navigate(`${baseURL}/constants`, { replace: true });
              const newConstantsData = { ...constantsData };
              delete newConstantsData[assetId];
              setConstantsData(newConstantsData);
              addToast({
                variant: "success",
                message: "Version and Asset have been successfully deleted.",
              });

              return;
            }

            deleteConstantVersionStore(versionNumber);

            if (selectedConstant.versionNumber === versionNumber) {
              const currentVersionIndex = versionsList.findIndex(
                (version) => version.version === versionNumber
              );
              const nextSelectedVersionIndex =
                currentVersionIndex === versionsList.length - 1
                  ? currentVersionIndex - 1
                  : currentVersionIndex + 1;
              const nextSelectedVersionData =
                versionsList[nextSelectedVersionIndex];

              if (nextSelectedVersionData) {
                const { id, version } = nextSelectedVersionData;
                setSelectedConstantVersionNumber(version);
                navigate(`${baseURL}/constants/${id}`, { replace: true });
              } else {
                clearSelectedConstant();
                navigate(`${baseURL}/constants`, { replace: true });
              }
            }

            addToast({
              variant: "success",
              message: `Version ${versionNumber} has been successfully deleted.`,
            });
          })
          .catch((error) => {
            console.error(error);
            addToast({
              variant: "error",
              message: "Failed to delete version",
            });
          });
      }
    },
    setAssetVersionStatus: (versionId: string, status: number) => {
      void putConstantVersionStatus(versionId, status).then((res) => {
        updateConstantVersion(res);
      });
    },
    setAssetVersionIsArchived: async (versionId, isArchived) => {
      try {
        const data = await putArchiveConstantVersion(versionId, isArchived);
        const {
          asset: { id: assetId, archive: assetIsArchived },
          archive: versionIsArchived,
          version,
        } = data;

        const currentAssetData = constantsData[assetId];
        const {
          constant: { archive },
        } = currentAssetData;

        if (assetIsArchived !== archive) {
          const updatedAssetData = {
            constant: {
              ...currentAssetData.constant,
              archive: assetIsArchived,
            },
            versions: {
              ...currentAssetData.versions,
              [version]: data,
            },
          };
          const newConstantsData = {
            ...constantsData,
            [assetId]: updatedAssetData,
          };
          setConstantsData(newConstantsData);
        } else {
          /* We loose version owner info after updateConstantVersion(data)
            because putArchiveConstantVersion response is a bit different from
            asset version data we get when fetch assets list (list item version has
            owner info as object, but in putArchiveConstantVersion response (data)
            owner has only owner id info). Will be automatically fixed when
            https://linear.app/zerve-ai/issue/ZER-1080/send-owner-as-dictionary-instead-of-string-on-put-asset-typeidarchive
            is done */
          updateConstantVersion(data);
        }

        if (!isDisplayArchivedConstants) {
          const selectedConstantVersionId =
            selectedConstant.id && selectedConstant.versionNumber
              ? constantsData[selectedConstant.id]?.versions[
                  selectedConstant.versionNumber
                ]?.id
              : null;
          if (
            (versionId === selectedConstantVersionId && versionIsArchived) ||
            (selectedConstant.id === assetId && assetIsArchived)
          ) {
            setIsDisplayArchivedConstants(true);
          }
        }

        addToast({
          variant: "success",
          message: `Version successfully ${
            isArchived ? "archived" : "unarchived"
          }`,
        });
      } catch (error) {
        console.error(error);
        clearSelectedConstant();
        navigate(`${baseURL}/constants`);
        addToast({
          variant: "error",
          message:
            "Something went wrong! Please refresh the page and try again",
        });
      }
    },
    setAssetIsArchived: (assetId, isArchived) => {
      const currentConstantId = assetId ?? selectedConstant.id;
      const requestData = {
        archive: isArchived,
        asset_id: currentConstantId,
      };
      if (userID) {
        void putConstantAssetArchive(requestData)
          .then((data) => {
            const assetData = data[currentConstantId];
            setConstantsData({
              ...constantsData,
              [currentConstantId]: { ...assetData },
            });

            const selectedConstantVersionId =
              selectedConstant.id && selectedConstant.versionNumber
                ? constantsData[selectedConstant.id]?.versions[
                    selectedConstant.versionNumber
                  ]?.id
                : null;
            const {
              constant: { archive },
              versions,
            } = assetData;

            if (
              selectedConstantVersionId &&
              !Object.values(versions).find(
                (version) => version.id === selectedConstantVersionId
              )
            ) {
              clearSelectedConstant();
              navigate(`${baseURL}/constants`);
            } else {
              if (
                !isDisplayArchivedConstants &&
                archive &&
                selectedConstant.id === currentConstantId
              ) {
                setIsDisplayArchivedConstants(true);
              }
            }

            addToast({
              message: `Asset was successfully ${
                isArchived ? "archived" : "unarchived"
              }.`,
              variant: "success",
            });
          })
          .catch((error) => {
            console.error(error);
            clearSelectedConstant();
            navigate(`${baseURL}/constants`);
            addToast({
              variant: "error",
              message:
                "Something went wrong! Please refresh the page and try again",
            });
          });
      }
    },
    setActiveItemByVersionId: (id?: string) => {
      if (!id || (selectedConstant.id && selectedConstant.versionNumber)) {
        return;
      }
      let selectedAssetVersionNumber: number | null = null;
      const selectedAsset = Object.values(constantsData).find((assetData) =>
        Object.values(assetData.versions).find((version) => {
          if (version.id === id) {
            selectedAssetVersionNumber = version.version;
            return true;
          }
          return false;
        })
      );

      if (!selectedAsset) {
        navigate(`${baseURL}/constants`);
      }

      const isAssetOrVersionArchived =
        selectedAsset?.constant.archive ||
        (selectedAssetVersionNumber &&
          selectedAsset?.versions[selectedAssetVersionNumber]?.archive);

      if (isAssetOrVersionArchived) {
        setIsDisplayArchivedConstants(true);
      }

      if (selectedAsset?.constant.id && selectedAssetVersionNumber) {
        setSelectedConstantId(selectedAsset.constant.id);
        setSelectedConstantVersionNumber(selectedAssetVersionNumber);
      }
    },
    clearActiveItemData: () => {
      clearSelectedConstant();
    },
    clearStore: () => {
      clearConstantsStore();
    },
  };

  return { currentConstantsData, currentConstantsActions };
}
