import styles from "./AppsGrid.module.scss";
import { type MouseEvent } from "react";
import { AppCard } from "components/apps/AppCard/AppCard";
import type { AppCardType } from "models/app";
import type { ContextMenuItem } from "components/common/ContextMenu/ContextMenu";

type AppsGridProps = {
  items: AppCardType[];
  processedItemsIds?: Record<string, string> | null;
  selectedItemsIds?: Record<string, string> | null;
  onGetAppOptions: (app: AppCardType) => ContextMenuItem[];
  onItemClick: (
    appId: AppCardType["id"],
    event: MouseEvent<HTMLElement>
  ) => void;
};

export function AppsGrid({
  items,
  processedItemsIds,
  selectedItemsIds,
  onGetAppOptions,
  onItemClick,
}: AppsGridProps) {
  return (
    <div className={styles.container}>
      <div className={styles.apps}>
        {items.map((item) => {
          const { id } = item;
          const appOptions = onGetAppOptions(item);

          return (
            <AppCard
              key={id}
              isProcessed={Boolean(processedItemsIds?.[id])}
              isSelected={Boolean(selectedItemsIds?.[id])}
              item={item}
              options={appOptions}
              onClick={onItemClick}
            />
          );
        })}
      </div>
    </div>
  );
}
