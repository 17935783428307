import styles from "./PanelRowButton.module.scss";
import { BiPlus } from "react-icons/bi";

interface PanelRowButtonProps {
  label: string;
  disabled?: boolean;
  onClick: (e) => void;
}

const PanelRowButton = ({ label, disabled, onClick }: PanelRowButtonProps) => {
  return (
    <button className={styles.button} disabled={disabled} onClick={onClick}>
      <BiPlus size={20} />
      <span>{label}</span>
    </button>
  );
};

export default PanelRowButton;
