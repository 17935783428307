import styles from "./Fill.module.scss";
import cn from "classnames";

interface FillProps {
  children: React.ReactNode;
  className?: string;
}

const Fill = ({ children, className: customClassName }: FillProps) => {
  return <div className={cn(styles.fill, customClassName)}>{children}</div>;
};

export default Fill;
