import itemDropdownStyles from "../AssetsNavItemDropdown/AssetsNavItemDropdown.module.scss";
import styles from "./AssetsNavVersionDropdown.module.scss";
import { Fragment, type FC, type ReactNode } from "react";
import { useParams } from "react-router-dom";
import cn from "classnames";
import { Button } from "components/common/Button/Button";
import { DropdownMenuButton } from "components/common/DropdownMenuButton/DropdownMenuButton";
import Typography from "components/Typography/Typography";
import {
  BiArchiveIn,
  BiDotsHorizontal,
  BiDuplicate,
  BiInfoCircle,
  BiTrash,
} from "react-icons/bi";
import { FiEyeOff } from "react-icons/fi";
import { createCustomLabel } from "../AssetsNavItemDropdown/AssetsNavItemDropdown";
import type {
  AssetPageTabType,
  AssetVersionStatus,
  AssetPageTabTypeToVersionDataType,
} from "../../types/abstractTypes";
import { type ConstantVersionType } from "../../types/constantTypes";
import { useAssetsData } from "hooks/useAssetsData";
import { AssetStatus } from "../../types/abstractTypes";
import { useModal } from "hooks/useModal";
import { useConfirmModal } from "hooks/useConfirmModal";
import { ManageUsageModal } from "components/modals";
import { getEnabledContextMenuOptions } from "utils/helpers";
import { ASSET_PERMISSION, LABEL, MESSAGE } from "pages/Assets/config";

interface AssetsNavVersionDropdownProps {
  assetType: AssetPageTabType;
  versionId: string;
  versionNumber: number;
  versionData?: AssetPageTabTypeToVersionDataType[keyof AssetPageTabTypeToVersionDataType];
}

const assetVersionStatus: AssetVersionStatus[] = [
  "NO_STATUS",
  "PRIMARY",
  "DRAFT",
  "OUTDATED",
];

const statusMarkerData = {
  NO_STATUS: {
    text: "No Status",
    className: styles.noStatus,
  },
  PRIMARY: {
    text: "Primary",
    className: styles.primary,
  },
  DRAFT: {
    text: "Draft",
    className: styles.draft,
  },
  OUTDATED: {
    text: "Outdated",
    className: styles.outdated,
  },
};

const createStatusMarker = (
  status: AssetVersionStatus,
  onClick: (newStatus) => void
): ReactNode => {
  return (
    <Button
      key={status}
      className={styles.markerContainer}
      onClick={() => {
        onClick(status);
      }}
      variant="text"
    >
      <div className={cn(styles.marker, statusMarkerData[status].className)} />
      <Typography variant="caption1" className={styles.statusText}>
        {statusMarkerData[status].text}
      </Typography>
    </Button>
  );
};

const AssetsNavVersionDropdown: FC<AssetsNavVersionDropdownProps> = ({
  assetType,
  versionId,
  versionNumber,
  versionData,
}: AssetsNavVersionDropdownProps) => {
  const { openModal } = useModal();
  const { openConfirmModal } = useConfirmModal();
  const {
    getCurrentAssetItemActions,
    getCreatedAssetItemActions,
    getCurrentAssetItemsData,
    getAssetSpecificData,
  } = useAssetsData();
  const {
    deleteAssetVersion,
    setActiveVersionNumber,
    setAssetVersionStatus,
    setAssetVersionIsArchived,
  } = getCurrentAssetItemActions(assetType);
  const { setAssetItemOperationType, setCreatedAssetItemData, addNewVersion } =
    getCreatedAssetItemActions(assetType);
  const { workspaceID } = useParams<{ workspaceID: string }>();
  const { setAssetVersionIsSecret } = getAssetSpecificData("constant");
  const { activeItemId, activeItemData } = getCurrentAssetItemsData(assetType);

  const isEditor = versionData?.permission === ASSET_PERMISSION.write;

  const handleDelete = async () => {
    const isOnlyRemainingVersion =
      Object.values(activeItemData?.versions ?? {}).length === 1;

    const confirmed = await openConfirmModal({
      title: isOnlyRemainingVersion
        ? MESSAGE.deleteLastVersionAndAsset
        : MESSAGE.deleteAssetVersion,
      message: isOnlyRemainingVersion
        ? MESSAGE.getDeleteLastVersionAndAssetDescription(
            LABEL.deleteConfirmButton,
            "Cancel"
          )
        : MESSAGE.getDeleteAssetVersionDescription(versionNumber),
      confirmButtonLabel: LABEL.deleteConfirmButton,
      confirmButtonVariant: "crucial",
    });

    if (confirmed) {
      deleteAssetVersion(versionId, versionNumber);
    }
  };

  const handleCreate = (): void => {
    if (assetType === "constant") {
      setActiveVersionNumber(versionNumber);
      setCreatedAssetItemData({
        name: "",
        description: "",
        value: "",
        secret: false,
      });
      setAssetItemOperationType("newVersion");
    } else {
      addNewVersion(versionData);
    }
  };

  const handleStatusChange = (status: string): void => {
    setAssetVersionStatus(versionId, AssetStatus[status], versionNumber);
  };

  const handleManageUsage = () => {
    if (activeItemId) {
      openModal({
        title: "Manage Usage",
        content: () => (
          <ManageUsageModal
            assetId={activeItemId}
            assetType={assetType}
            versionId={versionId}
            workspaceID={workspaceID}
          />
        ),
      });
    }
  };

  const handleConfirmMakeSecret = async () => {
    const confirmed = await openConfirmModal({
      message: MESSAGE.constantVersionMakeSecretConfirm,
    });

    if (confirmed) {
      setAssetVersionIsSecret(versionId);
    }
  };

  const handleConfirmArchive = async () => {
    const confirmed = await openConfirmModal({
      message: MESSAGE.assetVersionArchiveConfirm,
      confirmButtonLabel: LABEL.archiveConfirmButton,
    });

    if (confirmed) {
      setAssetVersionIsArchived(versionId, true);
    }
  };

  const menuOptions = [
    {
      key: "create-new",
      type: "option" as const,
      label: createCustomLabel("Create New", BiDuplicate),
      onClick: handleCreate,
    },
    {
      key: "manage-usage",
      type: "option" as const,
      disabled: !isEditor,
      label: createCustomLabel("Manage Usage", BiInfoCircle),
      onClick: () => {
        handleManageUsage();
      },
    },
    {
      key: "archive",
      type: "option" as const,
      label: createCustomLabel("Archive", BiArchiveIn),
      disabled: !isEditor,
      showDivider: true,
      onClick: handleConfirmArchive,
    },
    {
      key: "delete",
      type: "option" as const,
      label: createCustomLabel("Delete", BiTrash),
      disabled: !isEditor,
      showDivider: true,
      onClick: handleDelete,
    },
    {
      key: "set-status",
      type: "custom" as const,
      disabled: !isEditor,
      component: (
        <Typography
          key="set-status"
          variant="caption1"
          className={styles.statusHeader}
        >
          Set Status
        </Typography>
      ),
    },
    {
      key: "statuses",
      type: "custom" as const,
      disabled: !isEditor,
      component: (
        <Fragment key="statuses">
          {assetVersionStatus.map((status) =>
            createStatusMarker(status, handleStatusChange)
          )}
        </Fragment>
      ),
    },
    {
      key: "owner",
      type: "custom" as const,
      component: (
        <div key="asset-owner" className={itemDropdownStyles.ownerRow}>
          <Typography variant="caption2" className={itemDropdownStyles.title}>
            Version Owner:
          </Typography>
          <Typography variant="caption1" className={itemDropdownStyles.name}>
            {versionData?.owner?.username ?? versionData?.owner?.email}
          </Typography>
        </div>
      ),
    },
  ];

  if (assetType === "constant") {
    const isSecret = (versionData as ConstantVersionType)?.secret;

    if (!isSecret) {
      const makeSecretOption = {
        key: "make-secret",
        type: "option" as const,
        label: createCustomLabel("Make Secret", FiEyeOff),
        disabled: !isEditor,
        onClick: handleConfirmMakeSecret,
      };

      menuOptions.splice(2, 0, makeSecretOption);
    }
  }

  const enabledMenuOptions = getEnabledContextMenuOptions(menuOptions);

  return (
    <DropdownMenuButton
      buttonVariant="text"
      hideChevron
      menuOptions={enabledMenuOptions}
      text={<BiDotsHorizontal />}
      nodesClassNames={{
        text: itemDropdownStyles.textWrapper,
        contextMenu: itemDropdownStyles.contextMenu,
        container: itemDropdownStyles.dropdownContainer,
        button: itemDropdownStyles.button,
      }}
      hideFloatingElWhenRefElIsHidden
      wrapContextMenuWithFloatingPortal
      contextMenuFitWidth
    />
  );
};

export default AssetsNavVersionDropdown;
