import { callAPI } from "./call-api";
import { CANVAS_API_URL } from "config/appConfig";
import { type GpuInstanceType } from "config/canvasConfig";

export async function getGpuInstanceTypes() {
  const instanceTypes = await callAPI<GpuInstanceType[]>({
    method: "GET",
    url: `${CANVAS_API_URL}/gpu-instance-types/`,
  });

  return instanceTypes.sort((a, b) => {
    if (a.gpu_model < b.gpu_model) {
      return -1;
    }
    if (a.gpu_model > b.gpu_model) {
      return 1;
    }
    if (a.gpu_count < b.gpu_count) {
      return -1;
    }
    if (a.gpu_count > b.gpu_count) {
      return 1;
    }
    return 0;
  });
}
