import RequirementsMenu from "components/menus/RequirmentsMenu/RequirementsMenu";
import SideNavContent from "components/common/SideNavContent/SideNavContent";
import ResizableNav from "components/common/ResizableNav/ResizableNav";

export default function Requirements() {
  return (
    <SideNavContent>
      <ResizableNav position={"right"}>
        <RequirementsMenu />
      </ResizableNav>
    </SideNavContent>
  );
}
