export enum APP_STATUS {
  READY = 1,
  BUILDING = 2,
  ERROR = 3,
  STOPPING = 4,
  PENDING = 5,
}

export enum APP_TYPE {
  PYTHON = "PYTHON",
  R = "R",
}

// App data returned from API
export type AppResponseType = {
  id: string;
  resource_id?: string;
  organization_id?: string;
  name: string;
  time_created?: string;
  time_updated?: string;
  workspace_id?: string;
  status?: APP_STATUS;
  dns_name?: string;
  s3_path?: string;
  requirements?: string[];
  python_script_name?: string;
  type?: APP_TYPE;
};

export interface AppCardType extends AppResponseType {
  createdAt: AppResponseType["time_created"];
  lastModified:
    | AppResponseType["time_updated"]
    | AppResponseType["time_created"];
  onTitleChange: (title: string) => void;
}
