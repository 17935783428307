import styles from "./AssetListItemPreview.module.scss";
import cn from "classnames";
import { type AssetsPreviewData } from "../../types";
import { type PropsWithModal } from "hooks/useModal";
import { LinkButton } from "components/common/Button/LinkButton";
import { Button } from "components/common/Button/Button";
import CodeEditor from "components/common/CodeEditor/CodeEditor";
import Typography from "components/Typography/Typography";
import { AssetPageTabUrl } from "pages/Assets/types/abstractTypes";
import { getAssetsBaseUrl } from "pages/Assets/utils";
import { ASSET_PERMISSION } from "pages/Assets/config";
import AssetListItemIcon from "../AssetListItemIcon";

type AssetListItemPreviewProps = PropsWithModal<{
  asset: AssetsPreviewData;
}>;

const AssetListItemPreview = ({
  asset,
  onModalClose,
}: AssetListItemPreviewProps) => {
  if (!asset) {
    return null;
  }

  const {
    name,
    version,
    versionId,
    preview,
    type,
    description,
    organization_id,
    workspace_id,
    permission,
  } = asset;

  const assetsBaseUrl = getAssetsBaseUrl({
    organizationId: organization_id,
    workspaceId: workspace_id,
  });

  return (
    <div className={styles.previewWrapper}>
      <div className={styles.assetInfoSection}>
        <div>
          <div className={styles.assetInfoData}>{name}</div>
          <div className={styles.assetInfoName}>Asset Name</div>
        </div>
        <div>
          <div className={styles.assetInfoData}>v.{version}</div>
          <div className={styles.assetInfoName}>Version</div>
        </div>
        <div>
          <div className={cn(styles.assetInfoData, styles.assetInfoDataType)}>
            <AssetListItemIcon assetType={type} isActive={true} />
            {type}
          </div>
          <div className={styles.assetInfoName}>Type</div>
        </div>
      </div>
      <div className={styles.main}>
        <div className={styles.codeEditorSection}>
          <Typography
            component="div"
            variant="shortcuts"
            className={styles.infoSectionTitle}
          >
            {type !== "constant" ? "Preview" : "Value"}
          </Typography>
          <div className={styles.codeEditorWrapper}>
            <CodeEditor
              value={preview}
              language="python"
              lineNumbers={type !== "constant"}
              readOnly
              transparentBackground
            />
          </div>
        </div>
        {description && (
          <div className={styles.descriptionSection}>
            <Typography
              component="div"
              variant="shortcuts"
              className={styles.infoSectionTitle}
            >
              Description
            </Typography>
            <div className={styles.description}>{description}</div>
          </div>
        )}
      </div>
      <div className={styles.navigationButtonsWrapper}>
        {permission ? (
          <LinkButton
            to={`${assetsBaseUrl}/${AssetPageTabUrl[asset.type]}/${versionId}`}
            variant="outline"
            className={styles.navigationButton}
          >
            <Typography component="span" variant="body1">
              {permission === ASSET_PERMISSION.write ? "Edit" : "View"}
            </Typography>
          </LinkButton>
        ) : null}
        <Button
          className={cn(styles.navigationButton, styles.exitButton)}
          onClick={onModalClose}
        >
          <Typography component="span" variant="body1">
            Done
          </Typography>
        </Button>
      </div>
    </div>
  );
};

export default AssetListItemPreview;
