import styles from "./OrganizationSelfHosting.module.scss";
import { useState, useEffect, useCallback } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useUserState, useToastsState } from "store";
import { useModal } from "hooks/useModal";
import { Button } from "components/common/Button/Button";
import SecuredField from "components/common/SecuredField/SecuredField";
import Spinner from "components/common/Spinner/Spinner";
import TextField from "components/common/TextField/TextField";
import Typography from "components/Typography/Typography";
import AWSLogo from "assets/icons/aws-logo.svg?react";
import AzureLogo from "assets/icons/azure-logo.svg?react";
import GCPLogo from "assets/icons/gcp-logo.svg?react";
import {
  getCloudSetting,
  postCloudSetting,
  deleteCloudSetting,
  patchCloudSetting,
} from "api/http/cloud-setting-service";
import { DeleteCloudSettingModal } from "./DeleteCloudSettingModal";
import { CLOUD_PROVIDER } from "config/organizationsConfig";
import { TitleSection } from "components/organization/TitleSection/TitleSection";
import { SelfHostingStatus } from "components/organization/SelfHostingStatus/SelfHostingStatus";
import type { CloudCredentials } from "models/organization";

export function OrganizationSelfHosting() {
  const userID = useUserState((slice) => slice.userID);
  const { orgID = "" } = useParams();
  const { openModal, closeModal } = useModal();
  const addToast = useToastsState((slice) => slice.addToast);

  // State that control user/form interaction.
  const [selectedCloudProvider, setSelectedCloudProvider] =
    useState<CLOUD_PROVIDER>(CLOUD_PROVIDER.AWS);
  const [settingsId, setSettingsId] = useState("");
  const [isFetchingInitialData, setIsFetchingInitialData] = useState(true);
  const location = useLocation();
  const [isInitialConfig, setIsInitialConfig] = useState(true);

  function onSelfHostClick() {
    const orgId: string = location.pathname.split("/")[2];
    window.location.replace(
      "https://eu-central-1.console.aws.amazon.com/cloudformation/home?region=eu-west-1#/stacks/create/review?templateURL=https://zerve-deployment-templates.s3.eu-west-1.amazonaws.com/ZerveStack.yaml&stackName=ZerveDeployment&param_Organization=" +
        orgId
    );
  }
  // return an object with client side value names, from the server side object.
  const clientValuesFromServerValues = (serverValues) => {
    return {
      accessKeyId: serverValues.credentials?.access_key_id || "",
      secretAccessKey: serverValues.credentials?.secret_access_key || "",
      region: serverValues.credentials?.region,
      S3BucketName: serverValues.credentials?.s3_bucket_name,
      vpcId: serverValues.credentials?.vpc_id,
      subnetId: serverValues.credentials?.subnet_id,
      publicSubnetId1: serverValues.credentials?.public_subnet_id_1,
      publicSubnetId2: serverValues.credentials?.public_subnet_id_2,
      securityGroupId: serverValues.credentials?.security_group_id,
      publicSecurityGroupId: serverValues.credentials?.public_security_group_id,
      acmCertificateArn: serverValues.credentials?.acm_certificate_arn,
      natGatewayIpAddress: serverValues.credentials?.nat_gateway_ip_address,
      accountId: serverValues.credentials?.account_id,
      roleArn: serverValues.credentials?.role_arn,
      hostedZoneName: serverValues.credentials?.hosted_zone?.name,
      hostedZoneId: serverValues.credentials?.hosted_zone?.id,
    };
  };

  // This function parses the cloudSettings data from the API call to the form values
  const parseServerCloudSettings = (serverSettings) => {
    const clientValues = clientValuesFromServerValues(serverSettings);
    setSettingsId(serverSettings.id);
    setSelectedCloudProvider(
      serverSettings.cloud_provider
        ? serverSettings.cloud_provider
        : CLOUD_PROVIDER.AWS
    );
    formik.resetForm({
      values: clientValues,
    });
    setIsInitialConfig(false);
  };

  // Fetch organization cloud settings when the component loads.
  useEffect(() => {
    async function initializeCloudSettings() {
      setIsFetchingInitialData(true);
      try {
        const cloudSettingsResult = await getCloudSetting(orgID);
        if (cloudSettingsResult) {
          // process cloud settings
          parseServerCloudSettings(cloudSettingsResult);
        }
      } catch (rejection: any) {
        if (rejection?.cause?.status !== 404) {
          // if 404 - settings haven't been created yet
          addToast({
            message: "Failed to fetch self-hosting settings",
            variant: "error",
          });
        }
      }
      setIsFetchingInitialData(false);
    }
    if (userID && orgID) {
      initializeCloudSettings();
    }
  }, [userID, orgID]);

  // Delete the cloud settings
  const handleDeleteCloudSettings = () => {
    if (userID) {
      deleteCloudSetting(orgID)
        .then(() => {
          formik.resetForm({
            values: {
              accessKeyId: "",
              secretAccessKey: "",
              region: "",
              S3BucketName: "",
              vpcId: "",
              subnetId: "",
              publicSubnetId1: "",
              publicSubnetId2: "",
              securityGroupId: "",
              publicSecurityGroupId: "",
              acmCertificateArn: "",
              natGatewayIpAddress: "",
              accountId: "",
              roleArn: "",
              hostedZoneName: "",
              hostedZoneId: "",
            },
          });
          setSelectedCloudProvider(CLOUD_PROVIDER.AWS);
          setSettingsId("");
          closeModal();
          addToast({
            message: "Self-hosting settings successfully deleted",
            variant: "success",
          });
        })
        .catch((error) => {
          if (error.cause) {
            formik.setErrors(clientValuesFromServerValues(error.cause));
          }
          addToast({
            message: error.message,
            variant: "error",
          });
        });
    }
  };

  // Save the cloud settings. This function calls the relevant API depending on
  // whether the settings are new or it is updating and existing cloud setting row.
  const onSubmit = (values) => {
    if (userID) {
      const cloudSettingAPIData: CloudCredentials = {
        organisation_id: orgID,
        cloud_provider: selectedCloudProvider,
        credentials: {
          access_key_id: values.accessKeyId || null,
          secret_access_key: values.secretAccessKey || null,
          region: values.region,
          s3_bucket_name: values.S3BucketName,
          vpc_id: values.vpcId,
          subnet_id: values.subnetId,
          public_subnet_id_1: values.publicSubnetId1,
          public_subnet_id_2: values.publicSubnetId2,
          security_group_id: values.securityGroupId,
          public_security_group_id: values.publicSecurityGroupId,
          acm_certificate_arn: values.acmCertificateArn,
          nat_gateway_ip_address: values.natGatewayIpAddress,
          account_id: values.accountId,
          role_arn: values.roleArn,
          hosted_zone: {
            id: values.hostedZoneId,
            name: values.hostedZoneName,
          },
        },
      };
      if (!settingsId) {
        postCloudSetting(orgID, cloudSettingAPIData)
          .then((returnedValues) => {
            setSettingsId(returnedValues.id);
            addToast({
              message: "Self-hosting successfully updated",
              variant: "success",
            });
            parseServerCloudSettings(returnedValues);
          })
          .catch((error) => {
            if (error.cause) {
              formik.setErrors(clientValuesFromServerValues(error.cause));
            }
            addToast({
              message: error.message,
              variant: "error",
            });
          });
      } else {
        patchCloudSetting(orgID, cloudSettingAPIData)
          .then((returnedValues) => {
            setSettingsId(returnedValues.id);
            addToast({
              message: "Self-hosting successfully updated",
              variant: "success",
            });
            parseServerCloudSettings(returnedValues);
          })
          .catch((error) => {
            if (error.cause) {
              formik.setErrors(clientValuesFromServerValues(error.cause));
            }
            addToast({
              message: error.message,
              variant: "error",
            });
          });
      }
    }
  };

  const onDelete = () => {
    openModal({
      title: "Delete Self-Hosting Settings",
      content: () => (
        <DeleteCloudSettingModal
          onCancelDelete={closeModal}
          onDeleteCloudSettings={() => {
            handleDeleteCloudSettings();
          }}
        />
      ),
    });
  };

  const handlePlatformClick = (platform: CLOUD_PROVIDER) => {
    if (platform === CLOUD_PROVIDER.AWS) {
      setSelectedCloudProvider(platform);
    }
  };

  const onAccessKeyIdValueReset = useCallback(() => {
    formik.setFieldValue("accessKeyId", "");
  }, []);

  const onSecretAccessKeyValueReset = useCallback(() => {
    formik.setFieldValue("secretAccessKey", "");
  }, []);

  // Yup validation schema
  const validationSchema = Yup.object().shape({
    accessKeyId: Yup.string().optional(),
    secretAccessKey: Yup.string().optional(),
    region: Yup.string().required("Region is required"),
    S3BucketName: Yup.string().required("S3 Bucket name is required"),
    vpcId: Yup.string().required("VPC ID is required"),
    subnetId: Yup.string().required("Subnet ID is required"),
    publicSubnetId1: Yup.string().optional(),
    publicSubnetId2: Yup.string().optional(),
    securityGroupId: Yup.string().optional(),
    publicSecurityGroupId: Yup.string().optional(),
    acmCertificateArn: Yup.string().optional(),
    natGatewayIpAddress: Yup.string().optional(),
    accountId: Yup.string().optional(),
    roleArn: Yup.string().optional(),
    hostedZoneName: Yup.string().optional(),
    hostedZoneId: Yup.string().optional(),
  });

  // Initialize formik.
  const formik = useFormik({
    initialValues: {
      accessKeyId: "",
      secretAccessKey: "",
      region: "",
      S3BucketName: "",
      vpcId: "",
      subnetId: "",
      publicSubnetId1: "",
      publicSubnetId2: "",
      securityGroupId: "",
      publicSecurityGroupId: "",
      acmCertificateArn: "",
      natGatewayIpAddress: "",
      accountId: "",
      roleArn: "",
      hostedZoneName: "",
      hostedZoneId: "",
    },
    validationSchema,
    onSubmit,
  });

  return (
    <div className={styles.settingsWrapper}>
      <div className={styles.settings}>
        <TitleSection title="Self-Hosting Settings" />

        {isFetchingInitialData ? (
          <Spinner className={styles.loadingSpinner} />
        ) : (
          <>
            <div className={styles.statusContainer}>
              <Typography variant="h3" className={styles.statusSubHeading}>
                Status
              </Typography>
              <SelfHostingStatus
                organizationId={orgID}
                settingsId={settingsId}
              />
            </div>

            <Typography variant="body2" className={styles.subHeading}>
              Click the quick start button to deploy to your AWS account
              directly:
            </Typography>

            <Button
              size="large"
              className={styles.awsQuickStartButton}
              onClick={onSelfHostClick}
            >
              AWS Quickstart
            </Button>

            <Typography variant="body2" className={styles.subHeading}>
              You'll need to provide a Developer API Key to complete this
              installation. If you don't have one already, click{" "}
              <Link to="/settings/developer-api" className={styles.link}>
                here
              </Link>
              .
            </Typography>

            <Typography variant="body2" className={styles.subHeading}>
              Or manage your pre-existing hosted environment settings.
            </Typography>

            <div className={styles.platformSelectionText}>
              <Typography variant="h3">Select Cloud Provider</Typography>
            </div>
            <div className={styles.platformSelection}>
              <div
                className={`${styles.logoContainer} ${
                  selectedCloudProvider === CLOUD_PROVIDER.AWS
                    ? styles.logoSelected
                    : ""
                }`}
              >
                <AWSLogo
                  onClick={() => {
                    handlePlatformClick(CLOUD_PROVIDER.AWS);
                  }}
                />
              </div>
              <div
                className={`${styles.logoContainer} ${
                  selectedCloudProvider === CLOUD_PROVIDER.MICROSOFT_AZURE
                    ? styles.logoSelected
                    : ""
                }`}
              >
                <AzureLogo
                  onClick={() => {
                    handlePlatformClick(CLOUD_PROVIDER.MICROSOFT_AZURE);
                  }}
                />
              </div>
              <div
                className={`${styles.logoContainer} ${
                  selectedCloudProvider === CLOUD_PROVIDER.GOOGLE_CLOUD
                    ? styles.logoSelected
                    : ""
                }`}
              >
                <GCPLogo
                  onClick={() => {
                    handlePlatformClick(CLOUD_PROVIDER.GOOGLE_CLOUD);
                  }}
                />
              </div>
            </div>

            <form onSubmit={formik.handleSubmit}>
              <div className={styles.form}>
                <TextField
                  id="accountId"
                  name="accountId"
                  label="Account Id"
                  placeholder={"Account Id..."}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.accountId}
                  error={
                    formik.touched.accountId ? formik.errors.accountId : ""
                  }
                />
                <TextField
                  id="roleArn"
                  name="roleArn"
                  label="Role ARN"
                  placeholder={"Role ARN..."}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.roleArn}
                  error={formik.touched.roleArn ? formik.errors.roleArn : ""}
                />
                <TextField
                  id="hostedZoneId"
                  name="hostedZoneId"
                  label="Hosted Zone Id"
                  placeholder={"Hosted Zone Id..."}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.hostedZoneId}
                  error={
                    formik.touched.hostedZoneId
                      ? formik.errors.hostedZoneId
                      : ""
                  }
                />
                <TextField
                  id="hostedZoneName"
                  name="hostedZoneName"
                  label="Hosted Zone Name"
                  placeholder={"Hosted Zone Name..."}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.hostedZoneName}
                  error={
                    formik.touched.hostedZoneName
                      ? formik.errors.hostedZoneName
                      : ""
                  }
                />
                <TextField
                  id="region"
                  name="region"
                  label="Region"
                  placeholder={"Region..."}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.region}
                  error={formik.touched.region ? formik.errors.region : ""}
                />
                <TextField
                  id="S3BucketName"
                  name="S3BucketName"
                  label="S3 Bucket name"
                  placeholder={"S3 Bucket name..."}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.S3BucketName}
                  error={
                    formik.touched.S3BucketName
                      ? formik.errors.S3BucketName
                      : ""
                  }
                />
                <TextField
                  id="vpcId"
                  name="vpcId"
                  label="VPC ID"
                  placeholder={"VPC ID..."}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.vpcId}
                  error={formik.touched.vpcId ? formik.errors.vpcId : ""}
                />
                <TextField
                  id="subnetId"
                  name="subnetId"
                  label="Subnet ID"
                  placeholder={"Subnet ID..."}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.subnetId}
                  error={formik.touched.subnetId ? formik.errors.subnetId : ""}
                />
                <TextField
                  id="publicSubnetId1"
                  name="publicSubnetId1"
                  label="Public Subnet ID 1"
                  placeholder={"Public Subnet ID 1..."}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.publicSubnetId1}
                  error={
                    formik.touched.publicSubnetId1
                      ? formik.errors.publicSubnetId1
                      : ""
                  }
                />
                <TextField
                  id="publicSubnetId2"
                  name="publicSubnetId2"
                  label="Public Subnet ID 2"
                  placeholder={"Public Subnet ID 2..."}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.publicSubnetId2}
                  error={
                    formik.touched.publicSubnetId2
                      ? formik.errors.publicSubnetId2
                      : ""
                  }
                />
                <TextField
                  id="securityGroupId"
                  name="securityGroupId"
                  label="Security Group ID"
                  placeholder={"Security Group ID..."}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.securityGroupId}
                  error={
                    formik.touched.securityGroupId
                      ? formik.errors.securityGroupId
                      : ""
                  }
                />
                <TextField
                  id="publicSecurityGroupId"
                  name="publicSecurityGroupId"
                  label="Public Security Group ID"
                  placeholder={"Public Security Group ID..."}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.publicSecurityGroupId}
                  error={
                    formik.touched.publicSecurityGroupId
                      ? formik.errors.publicSecurityGroupId
                      : ""
                  }
                />
                <TextField
                  id="acmCertificateArn"
                  name="acmCertificateArn"
                  label="ACM Certificate ARN"
                  placeholder={"ACM Certificate Arn..."}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.acmCertificateArn}
                  error={
                    formik.touched.acmCertificateArn
                      ? formik.errors.acmCertificateArn
                      : ""
                  }
                />
                <TextField
                  id="natGatewayIpAddress"
                  name="natGatewayIpAddress"
                  label="NAT Gateway IP Address"
                  placeholder={"Nat Gateway IP Address..."}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.natGatewayIpAddress}
                  error={
                    formik.touched.natGatewayIpAddress
                      ? formik.errors.natGatewayIpAddress
                      : ""
                  }
                />
                <SecuredField
                  id="accessKeyId"
                  name="accessKeyId"
                  label="Access Key ID"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.accessKeyId}
                  error={
                    formik.touched.accessKeyId ? formik.errors.accessKeyId : ""
                  }
                  showDummyTextIfEmptyValue={!isInitialConfig}
                  onReset={
                    !isInitialConfig ? onAccessKeyIdValueReset : undefined
                  }
                />
                <SecuredField
                  id="secretAccessKey"
                  name="secretAccessKey"
                  label="Secret Access Key"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.secretAccessKey}
                  error={
                    formik.touched.secretAccessKey
                      ? formik.errors.secretAccessKey
                      : ""
                  }
                  showDummyTextIfEmptyValue={!isInitialConfig}
                  onReset={
                    !isInitialConfig ? onSecretAccessKeyValueReset : undefined
                  }
                />
              </div>
              <div className={styles.buttonSection}>
                <Button
                  type="submit"
                  disabled={!formik.isValid || !formik.dirty}
                >
                  Save & Deploy
                </Button>
              </div>
            </form>

            {settingsId && (
              <div className={styles.deleteWrapper}>
                <div className={styles.deleteExplanationText}>
                  <Typography variant="h2">
                    Delete Self-Hosting Settings
                  </Typography>
                  <Typography variant="body2" className={styles.subTitle}>
                    If you delete your self-hosting data, it will be removed
                    from our system. To add self-hosting again, you will need to
                    re-enter this data. Please be certain.
                  </Typography>
                </div>
                <Button variant="crucial" onClick={onDelete}>
                  Delete Settings
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
