import styles from "./WorkspaceReports.module.scss";
import { useCallback, useEffect, useMemo } from "react";
import cn from "classnames";
import { useParams } from "react-router-dom";
import { useToastsState } from "store";
import Spinner from "components/common/Spinner/Spinner";
import { EmptyState } from "components/common/EmptyState/EmptyState";
import {
  getCanvasLinkForReport,
  getPublishedReportsByWorkspaceId,
} from "api/http/canvas-report-service";
import { useReports } from "pages/Reports/useReports";
import type { PublishedReport, ReportTableType } from "models/publishedReport";
import { ReportsListLayout } from "components/reports/ReportsListLayout/ReportsListLayout";
import { SORTING_COLUMN } from "config/localPreferences";

const SORT_OPTIONS = Object.freeze([
  { value: SORTING_COLUMN.name, label: "Name" },
  { value: SORTING_COLUMN.createdAt, label: "Date Created" },
  { value: SORTING_COLUMN.lastModified, label: "Last Modified" },
]);

export function WorkspaceReports() {
  const { workspaceID = "" } = useParams();

  const addToast = useToastsState((slice) => slice.addToast);

  const {
    searchQuery,
    noReports,
    notFound,
    isLoading,
    sortedItems,
    reportsSortingColumn,
    reportsSortingDirection,
    processedReportsIdActionMap,
    selectedReportIds,
    toolbar,
    handleReportClick,
    handleDeleteReports,
    handleChangeSorting,
    handleSearchQueryChange,
    openReport,
    setReports,
    setIsLoading,
  } = useReports({ isLoadingInitial: true });

  useEffect(() => {
    !isLoading && setIsLoading(true);
    getPublishedReportsByWorkspaceId(workspaceID)
      .then((data: PublishedReport[]) => {
        setReports(
          data.map((item) => {
            return {
              ...item,
              createdAt: item.time_created,
              lastModified: item.time_updated || item.time_created,
            };
          })
        );
      })
      .catch(() => {
        addToast({
          message: "Error loading reports",
          variant: "error",
        });
        setReports([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [workspaceID]);

  const handleGetReportOptions = useCallback(
    (report: ReportTableType) => {
      return [
        {
          key: "open",
          type: "option" as const,
          label: "Open Report",
          onClick: () => {
            openReport(report.name);
          },
        },
        {
          key: "view-in-canvas",
          type: "option" as const,
          label: "View on canvas",
          onClick: () => {
            getCanvasLinkForReport(report.id)
              .then((link) => {
                window.open(link, "_blank");
              })
              .catch(() => {
                addToast({
                  message:
                    "Failed to find block for this report. It may be deleted.",
                  variant: "error",
                });
              });
          },
        },
        {
          key: "copy-link",
          type: "option" as const,
          label: "Copy link to report",
          onClick: () => {
            navigator.clipboard.writeText(
              `${window.location.origin}/report/${report.name}`
            );
            addToast({
              message: "Link copied to clipboard",
              variant: "info",
            });
          },
        },
        {
          key: "delete",
          type: "option" as const,
          label: "Delete",
          onClick: () => {
            handleDeleteReports([report.id]);
          },
        },
      ];
    },
    [openReport, handleDeleteReports]
  );

  const noReportsPlaceholder = useMemo(() => {
    if (!noReports || isLoading) {
      return null;
    }

    return (
      <EmptyState
        variant="info"
        size="large"
        containerClassName={styles.placeholder}
        title="No reports yet..."
        description={
          <>
            Reports you will create will be here.
            <br />
            To create a new report use an RMarkdown block in a canvas.
          </>
        }
      ></EmptyState>
    );
  }, [isLoading, noReports]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className={styles.page}>
      <div
        className={cn(styles.container, {
          [styles.container_with_toolbar]: toolbar,
        })}
      >
        <ReportsListLayout
          topBarClassName={styles.top_bar}
          reports={sortedItems}
          onGetReportOptions={handleGetReportOptions}
          noReports={noReports}
          noReportsPlaceholder={noReportsPlaceholder}
          notFound={notFound}
          selectedReportsIds={selectedReportIds}
          processedReportsIdActionMap={processedReportsIdActionMap}
          searchQuery={searchQuery}
          sortingColumn={reportsSortingColumn}
          sortingDirection={reportsSortingDirection}
          sortOptions={SORT_OPTIONS}
          toolbar={toolbar}
          onSearchQueryChange={handleSearchQueryChange}
          onChangeSorting={handleChangeSorting}
          onReportClick={handleReportClick}
        />
      </div>
    </div>
  );
}
