import styles from "./ExpandedNotification.module.scss";
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  type ReactNode,
} from "react";
import { BiError } from "react-icons/bi";
import {
  getBlockComment,
  getBlockCommentReply,
} from "api/http/comments-service";
import { useComments } from "components/comments/useComments";
import { Skeleton } from "components/common/Skeleton/Skeleton";
import { LinkButton } from "components/common/Button/LinkButton";
import { getAssetsBaseUrl } from "pages/Assets/utils";
import { AssetPageTabUrl } from "pages/Assets/types/abstractTypes";
import { debouncePromise } from "utils/helpers";
import { DATA_LOAD_STATUS } from "config/appConfig";
import { NOTIFICATION_TYPE } from "config/notificationConfig";
import { type NotificationType } from "models/notifications";

type ExpandedNotificationProps = {
  notification: NotificationType;
};

export function ExpandedNotification({
  notification,
}: ExpandedNotificationProps) {
  const [comment, setComment] = useState("");
  const [commentLoadingStatus, setCommentLoadingStatus] = useState(
    DATA_LOAD_STATUS.NOT_LOADED
  );

  const { parseTextWithMentions } = useComments();

  const isBlockCommentNotification =
    notification.type === NOTIFICATION_TYPE.BLOCK_COMMENT_MENTION ||
    notification.type === NOTIFICATION_TYPE.BLOCK_COMMENT_REPLIED_TO;

  const assetUrl = () => {
    const assetType = notification.properties.asset_type;
    if (["function", "constant", "connection"].includes(assetType)) {
      return `${getAssetsBaseUrl({})}/${AssetPageTabUrl[assetType]}/${notification.properties.version_id}`;
    } else {
      return `${assetType}/${notification.properties.resource_id}`;
    }
  };

  const handleFetchComment = useCallback(async () => {
    setCommentLoadingStatus(DATA_LOAD_STATUS.LOADING);
    try {
      const promise = notification.properties.reply_id
        ? getBlockCommentReply({
            blockCommentId: notification.properties.comment_id,
            blockCommentReplyId: notification.properties.reply_id,
          })
        : getBlockComment({
            blockId: notification.properties.block_id,
            blockCommentId: notification.properties.comment_id,
          });
      const response = await debouncePromise({ promise });
      setComment(response);
      setCommentLoadingStatus(DATA_LOAD_STATUS.LOADED);
    } catch (error) {
      setCommentLoadingStatus(DATA_LOAD_STATUS.ERROR);
    }
  }, [notification]);

  useEffect(() => {
    if (
      isBlockCommentNotification &&
      commentLoadingStatus === DATA_LOAD_STATUS.NOT_LOADED
    ) {
      handleFetchComment();
    }
  }, [isBlockCommentNotification, commentLoadingStatus, handleFetchComment]);

  const parsedComment = useMemo(() => {
    return parseTextWithMentions(comment);
  }, [parseTextWithMentions, comment]);

  const baseNotification = (content, button) => {
    return (
      <>
        <div className={styles.notificationContent}>{content}</div>
        <div className={styles.notificationActions}>{button}</div>
      </>
    );
  };

  const renderExpandedNotification = () => {
    if (isBlockCommentNotification) {
      let content: ReactNode = null;
      switch (commentLoadingStatus) {
        case DATA_LOAD_STATUS.LOADED:
          content = parsedComment;
          break;
        case DATA_LOAD_STATUS.ERROR:
          content = (
            <div className={styles.labelWithIconContainer}>
              <BiError className={styles.error} size={16} />
              <span className={styles.error}>Failed to load the comment.</span>

              <span
                role="button"
                className={styles.retryButton}
                onClick={handleFetchComment}
              >
                Retry
              </span>
            </div>
          );
          break;
        case DATA_LOAD_STATUS.NOT_LOADED:
        case DATA_LOAD_STATUS.LOADING:
        default:
          content = <Skeleton height={20} width="75%" />;
          break;
      }

      return baseNotification(
        content,
        <LinkButton
          className={styles.notificationButton}
          variant="secondary"
          to={`/canvas/${notification.properties.canvas_id}?block_id=${notification.properties.block_id}`}
        >
          View Block
        </LinkButton>
      );
    }

    if (notification.type === NOTIFICATION_TYPE.RESOURCE_INVITATION) {
      return baseNotification(
        `A ${notification.properties.asset_type} asset has been shared with you. Click the button below to view the asset.`,
        <LinkButton
          className={styles.notificationButton}
          variant="secondary"
          to={assetUrl()}
        >
          View Asset
        </LinkButton>
      );
    }

    if (notification.type === NOTIFICATION_TYPE.CANVAS_INVITATION) {
      return baseNotification(
        "You have been invited to a canvas. Click the button below to view the canvas.",
        <LinkButton
          className={styles.notificationButton}
          variant="secondary"
          to={`/canvas/${notification.properties.canvas_id}`}
        >
          View Canvas
        </LinkButton>
      );
    }

    if (notification.type === NOTIFICATION_TYPE.ORGANIZATION_INVITATION) {
      return baseNotification(
        "You have been invited to an organization. Click the button below to view the organization.",
        <LinkButton
          className={styles.notificationButton}
          variant="secondary"
          to={`/org/${notification.properties.organization_id}/workspaces`}
        >
          View Organization
        </LinkButton>
      );
    }

    if (notification.type === NOTIFICATION_TYPE.SCHEDULED_JOB_PAUSED) {
      return baseNotification(
        "A scheduled job has been paused. Click the button below to view the job.",
        <LinkButton
          className={styles.notificationButton}
          variant="secondary"
          to={`/canvas/${notification.properties.canvas_id}?layer_id=${notification.properties.layer_id}`}
        >
          View Job
        </LinkButton>
      );
    }
  };

  return (
    <div className={styles.expandedNotification}>
      {renderExpandedNotification()}
    </div>
  );
}
