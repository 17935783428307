import cn from "classnames";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import Typography from "components/Typography";

import styles from "./CircularProgressBlock.module.scss";
import stylesEx from "styles/_exports.module.scss";

interface CircularProgressBlockProps {
  progress: number;
  title?: string;
  text?: string;
  className?: string;
}

const CircularProgressBlock = ({
  title,
  text,
  progress,
  className,
}: CircularProgressBlockProps) => {
  return (
    <div className={cn(styles["block"], className)}>
      <div className={styles["block__progressbar"]}>
        <CircularProgressbar
          value={progress}
          text={`${progress}%`}
          strokeWidth={8}
          counterClockwise
          styles={buildStyles({
            pathColor: stylesEx["yellow-500"],
            trailColor: stylesEx["gray-800"],
            textSize: 16,
            textColor: stylesEx["gray-50"],
          })}
        />
      </div>

      {title && (
        <Typography variant="h3" className={styles["block__title"]}>
          {title}
        </Typography>
      )}

      {text && (
        <Typography
          component="span"
          variant="caption2"
          className={styles["block__text"]}
        >
          {text.split("\n").map((line) => {
            return (
              <p className={styles["text"]} key={line}>
                {" "}
                {line}{" "}
              </p>
            );
          })}
        </Typography>
      )}
    </div>
  );
};

export default CircularProgressBlock;
