import styles from "./GroupCard.module.scss";
import Typography from "components/Typography/Typography";
import { useNavigate } from "react-router-dom";
import { type Group } from "pages/OrganizationHome/types";

type GroupCardProps = {
  orgID: string;
  group: Group;
};

export function GroupCard({ orgID, group }: GroupCardProps) {
  const { id, name } = group;
  const navigate = useNavigate();

  const handleTitleClick = () => {
    navigate(`/org/${orgID}/groups/${id}/settings`);
  };

  return (
    <div className={styles.card}>
      <div
        onClick={() => {
          handleTitleClick();
        }}
      >
        <Typography className={styles.title} variant="h3">
          {name}
        </Typography>
      </div>
    </div>
  );
}
