import type {
  AssetItemActionsInterface,
  AssetItemsDataInterface,
  AssetPageTabType,
} from "pages/Assets/types/abstractTypes";
import { showConsoleWarningNoAssetType } from "utils/helpers";
import {
  useCurrentConstantsData,
  useCurrentFunctionsData,
  useCurrentQueriesData,
} from "./components";
import { DATA_LOAD_STATUS } from "config/appConfig";

export const useCurrentAssetData = () => {
  const { currentFunctionsData, currentFunctionsActions } =
    useCurrentFunctionsData();
  const { currentConstantsData, currentConstantsActions } =
    useCurrentConstantsData();
  const { currentQueriesData, currentQueriesActions } = useCurrentQueriesData();

  const getCurrentAssetItemsData = (
    assetType: AssetPageTabType
  ): AssetItemsDataInterface<AssetPageTabType> => {
    switch (assetType) {
      case "function":
        return currentFunctionsData;
      case "query":
        return currentQueriesData;
      case "constant":
        return currentConstantsData;
      default:
        return {
          assetsData: {},
          activeItemData: null,
          activeVersionData: null,
          activeItemId: null,
          activeItemVersionId: null,
          isDisplayArchivedItems: false,
          assetDataLoadState: DATA_LOAD_STATUS.NOT_LOADED,
        };
    }
  };

  const getCurrentAssetItemActions = (
    assetType: AssetPageTabType
  ): AssetItemActionsInterface<AssetPageTabType> => {
    switch (assetType) {
      case "function":
        return currentFunctionsActions;
      case "query":
        return currentQueriesActions;
      case "constant":
        return currentConstantsActions;
      default:
        return {
          setActiveItemId: showConsoleWarningNoAssetType,
          setActiveVersionNumber: showConsoleWarningNoAssetType,
          setAssetsData: showConsoleWarningNoAssetType,
          deleteAssetItem: showConsoleWarningNoAssetType,
          deleteAssetVersion: showConsoleWarningNoAssetType,
          setAssetVersionStatus: showConsoleWarningNoAssetType,
          setAssetVersionIsArchived: showConsoleWarningNoAssetType,
          setIsDisplayArchivedItems: showConsoleWarningNoAssetType,
          clearActiveItemData: showConsoleWarningNoAssetType,
          setAssetIsArchived: showConsoleWarningNoAssetType,
          setActiveItemByVersionId: showConsoleWarningNoAssetType,
          clearStore: showConsoleWarningNoAssetType,
        };
    }
  };

  return {
    getCurrentAssetItemsData,
    getCurrentAssetItemActions,
  };
};
