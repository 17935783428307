import { type StateCreator } from "store";
import type { VALIDATION_STATUS } from "config/appConfig";
import { DATA_LOAD_STATUS } from "config/appConfig";
import { LANGUAGE, type REQUIREMENTS_STATUS } from "config/canvasConfig";
import { SAVING_STATUS } from "components/common/SavingStatusIndicator/SavingStatusIndicator";
import type {
  Requirement,
  LinuxPackage,
  EnvironmentVariable,
  Executor,
} from "models/requirements";

type State = {
  requirementsLoadingStatus: DATA_LOAD_STATUS;
  requirementsSavingStatus: SAVING_STATUS;
  requirementsValidationStatus: VALIDATION_STATUS | null;
  requirements: Requirement[] | null;

  linuxPackagesLoadingStatus: DATA_LOAD_STATUS;
  linuxPackagesSavingStatus: SAVING_STATUS;
  linuxPackages: LinuxPackage[] | null;

  environmentVariablesLoadingStatus: DATA_LOAD_STATUS;
  environmentVariablesSavingStatus: SAVING_STATUS;
  environmentVariables: EnvironmentVariable[] | null;

  executor: Executor | null;
  executorLoadingStatus: DATA_LOAD_STATUS;

  requirementsStatus: REQUIREMENTS_STATUS | null;
  showRequirementsLogs: boolean;
  requirementsLogs: string | null;

  globalImports: Record<LANGUAGE, string | null>;
};

type Actions = {
  getRequirementsStatus: () => State["requirementsStatus"];
  getRequirements: () => State["requirements"];
  setRequirementsLoadingStatus: (loadingStatus: DATA_LOAD_STATUS) => void;
  setRequirementsSavingStatus: (savingStatus: SAVING_STATUS) => void;
  setRequirementsValidationStatus: (
    validationStatus: State["requirementsValidationStatus"]
  ) => void;
  setRequirements: (requirements: Requirement[]) => void;

  setLinuxPackagesLoadingStatus: (loadingStatus: DATA_LOAD_STATUS) => void;
  setLinuxPackagesSavingStatus: (savingStatus: SAVING_STATUS) => void;
  setLinuxPackages: (linuxPackages: LinuxPackage[]) => void;

  setEnvironmentVariablesLoadingStatus: (
    loadingStatus: DATA_LOAD_STATUS
  ) => void;
  setEnvironmentVariablesSavingStatus: (savingStatus: SAVING_STATUS) => void;
  setEnvironmentVariables: (
    environmentVariables: EnvironmentVariable[]
  ) => void;

  setExecutor: (data: State["executor"]) => void;
  setExecutorLoadingStatus: (loadingStatus: DATA_LOAD_STATUS) => void;

  setRequirementsStatus: (status: State["requirementsStatus"]) => void;
  setShowRequirementsLogs: (show: boolean) => void;
  setRequirementsLogs: (logs: string) => void;

  setGlobalImportsByLanguage: (imports: string, language: LANGUAGE) => void;

  clearStore: () => void;
};

const getInitialState = (): State => ({
  requirementsLoadingStatus: DATA_LOAD_STATUS.NOT_LOADED,
  requirementsSavingStatus: SAVING_STATUS.IDLE,
  requirementsValidationStatus: null,
  requirements: null,

  linuxPackagesLoadingStatus: DATA_LOAD_STATUS.NOT_LOADED,
  linuxPackagesSavingStatus: SAVING_STATUS.IDLE,
  linuxPackages: null,

  environmentVariablesLoadingStatus: DATA_LOAD_STATUS.NOT_LOADED,
  environmentVariablesSavingStatus: SAVING_STATUS.IDLE,
  environmentVariables: null,

  executor: null,
  executorLoadingStatus: DATA_LOAD_STATUS.NOT_LOADED,

  requirementsStatus: null,
  showRequirementsLogs: false,
  requirementsLogs: null,

  globalImports: {
    [LANGUAGE.PYTHON]: null,
    [LANGUAGE.R]: null,
  },
});

export type CanvasRequirementsSlice = State & Actions;

export const createCanvasRequirementsSlice: StateCreator<
  CanvasRequirementsSlice
> = (set, get) => ({
  ...getInitialState(),
  getRequirementsStatus: () => get().canvasRequirementsSlice.requirementsStatus,
  getRequirements: () => get().canvasRequirementsSlice.requirements,
  setRequirementsLoadingStatus: (loadingStatus) => {
    set(
      (store) => {
        store.canvasRequirementsSlice.requirementsLoadingStatus = loadingStatus;
        store.canvasRequirementsSlice.requirementsSavingStatus =
          SAVING_STATUS.IDLE;
      },
      false,
      "canvasRequirements/setRequirementsLoadingStatus"
    );
  },
  setRequirementsSavingStatus: (savingStatus) => {
    set(
      (store) => {
        store.canvasRequirementsSlice.requirementsSavingStatus = savingStatus;
      },
      false,
      "canvasRequirements/setRequirementsSavingStatus"
    );
  },
  setRequirementsValidationStatus: (validationStatus) => {
    set(
      (store) => {
        store.canvasRequirementsSlice.requirementsValidationStatus =
          validationStatus;
      },
      false,
      "canvasRequirements/setRequirementsValidationStatus"
    );
  },
  setRequirements: (requirements) => {
    set(
      (store) => {
        store.canvasRequirementsSlice.requirements = requirements;
      },
      false,
      "canvasRequirements/setRequirements"
    );
  },

  setLinuxPackagesLoadingStatus: (loadingStatus) => {
    set(
      (store) => {
        store.canvasRequirementsSlice.linuxPackagesLoadingStatus =
          loadingStatus;
        store.canvasRequirementsSlice.linuxPackagesSavingStatus =
          SAVING_STATUS.IDLE;
      },
      false,
      "canvasRequirements/setLinuxPackagesLoadingStatus"
    );
  },
  setLinuxPackagesSavingStatus: (savingStatus) => {
    set(
      (store) => {
        store.canvasRequirementsSlice.linuxPackagesSavingStatus = savingStatus;
      },
      false,
      "canvasRequirements/setLinuxPackagesSavingStatus"
    );
  },
  setLinuxPackages: (linuxPackages) => {
    set(
      (store) => {
        store.canvasRequirementsSlice.linuxPackages = linuxPackages;
      },
      false,
      "canvasRequirements/setLinuxPackages"
    );
  },

  setEnvironmentVariablesLoadingStatus: (loadingStatus) => {
    set(
      (store) => {
        store.canvasRequirementsSlice.environmentVariablesLoadingStatus =
          loadingStatus;
        store.canvasRequirementsSlice.environmentVariablesSavingStatus =
          SAVING_STATUS.IDLE;
      },
      false,
      "canvasRequirements/setEnvironmentVariablesLoadingStatus"
    );
  },
  setEnvironmentVariablesSavingStatus: (savingStatus) => {
    set(
      (store) => {
        store.canvasRequirementsSlice.environmentVariablesSavingStatus =
          savingStatus;
      },
      false,
      "canvasRequirements/setEnvironmentVariablesSavingStatus"
    );
  },
  setEnvironmentVariables: (environmentVariables) => {
    set(
      (store) => {
        store.canvasRequirementsSlice.environmentVariables =
          environmentVariables;
      },
      false,
      "canvasRequirements/setEnvironmentVariables"
    );
  },

  setExecutor: (data) => {
    set(
      (store) => {
        store.canvasRequirementsSlice.executor = data;
      },
      false,
      "canvasRequirements/setExecutor"
    );
  },
  setExecutorLoadingStatus: (loadingStatus) => {
    set(
      (store) => {
        store.canvasRequirementsSlice.executorLoadingStatus = loadingStatus;
      },
      false,
      "canvasRequirements/setExecutorLoadingStatus"
    );
  },

  setRequirementsStatus(status) {
    set(
      (store) => {
        store.canvasRequirementsSlice.requirementsStatus = status;
      },
      false,
      "canvasRequirements/setRequirementsStatus"
    );
  },
  setShowRequirementsLogs: (show) => {
    set(
      (store) => {
        store.canvasRequirementsSlice.showRequirementsLogs = show;
      },
      false,
      "canvasRequirements/setShowRequirementsLogs"
    );
  },
  setRequirementsLogs: (logs) => {
    set(
      (store) => {
        store.canvasRequirementsSlice.requirementsLogs = logs;
      },
      false,
      "canvasRequirements/setRequirementsLogs"
    );
  },

  setGlobalImportsByLanguage: (imports: string, language: LANGUAGE) => {
    set(
      (store) => {
        store.canvasRequirementsSlice.globalImports[language] = imports;
      },
      false,
      "canvasRequirements/setGlobalImportsByLanguage"
    );
  },

  clearStore: () => {
    set(
      (store) => {
        Object.assign(store.canvasRequirementsSlice, getInitialState());
      },
      false,
      "canvasRequirements/clearStore"
    );
  },
});
