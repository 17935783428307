import styles from "./AtCapacityPage.module.scss";
import { useEffect, useState } from "react";
import Typography from "components/Typography/Typography";
import { submitToHubSpot } from "api/http/hubspot";
import { useAuth0 } from "@auth0/auth0-react";
import { useUserState } from "store";

// At Capacity Notification Form
const FORM_ID = "c143fb40-f7a5-49cf-acac-7b34f5018b35";

const TITLE = "Zerve is at capacity right now";
const NOTIFY_ME = "Get notified when we're back";
const SUCCESSFUL_SUBMISSION = "Thanks! We'll let you know when we're back.";
const UNSUCCESSFUL_SUBMISSION = "Something went wrong. Please try again.";
const DISCORD_URL = "https://discord.gg/AMF7rrs3hP";

export const AtCapacityPage = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);

  const { user } = useAuth0();
  const { userID } = useUserState((slice) => ({
    userID: slice.userID,
  }));

  const sendAtCapacityForm = async (clicked = "No") => {
    try {
      const FORM_DATA = [
        {
          name: "email",
          value: user?.email,
        },
        {
          name: "user_id",
          value: userID,
        },
        {
          name: "click_notify",
          value: clicked,
        },
      ];
      if (user?.given_name) {
        FORM_DATA.push({
          name: "firstname",
          value: user?.given_name,
        });
      }
      if (user?.family_name) {
        FORM_DATA.push({
          name: "lastname",
          value: user?.family_name,
        });
      }
      await submitToHubSpot(FORM_ID, FORM_DATA).then(() => {
        if (clicked === "Yes") {
          setIsSubmitted(true);
        }
      });
    } catch (error) {
      setErrorOccurred(true);
    }
  };

  useEffect(() => {
    sendAtCapacityForm();
  }, []);

  return (
    <div className={styles.container}>
      <Typography className={styles.container__title} variant="h1">
        {TITLE}
      </Typography>
      {isSubmitted ? (
        <>
          <Typography className={styles.container__success} variant="body1">
            {SUCCESSFUL_SUBMISSION}
          </Typography>
          <Typography className={styles.container__discord} variant="body1">
            You can join us on Discord for updates on{" "}
            <a href={DISCORD_URL} rel="noreferrer" target="_blank">
              Discord
            </a>
          </Typography>
        </>
      ) : errorOccurred ? (
        <Typography
          className={styles.container__notifyMe__error}
          variant="body1"
        >
          {UNSUCCESSFUL_SUBMISSION}
        </Typography>
      ) : (
        <div onClick={() => sendAtCapacityForm("Yes")}>
          <Typography className={styles.container__notifyMe} variant="body1">
            {NOTIFY_ME}
          </Typography>
        </div>
      )}
    </div>
  );
};
