import { type SVGProps } from "react";

const DataBricksIcon = ({
  color = "currentColor",
  width = "20",
  height = "20",
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      id="databricks"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g fill={color} fillRule="nonzero">
        <g>
          <path
            d="M16.3518 4.44207L9.99999 0.710938L1.75003 5.56037V6.38706L9.99969 11.1953L16.9312 7.17753V8.29529L10.0004 12.351L2.41243 7.88232L1.75079 8.25596L1.75 11.7825L9.99967 16.5923L16.9312 12.5745V13.6547L10.0005 17.7075L2.41243 13.2388L1.75076 13.6125V14.4795L10 19.2903L18.25 14.481V10.9607L17.631 10.5759L10.0008 15.0492L3.1065 10.9573V9.87717L10.0001 13.894L18.25 9.0848V5.6017L17.6307 5.21802L10 9.69161L3.72295 6.01165L10.0004 2.3315L15.6239 5.61068L16.3518 5.14677V4.44207Z"
            id="path"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default DataBricksIcon;
