import { type StateCreator } from "store";

type State = {
  userID?: string;
  isNewUser: boolean;
  onboardedUser: boolean;
  usernamePromptDismissed: boolean;
};

type Actions = {
  setUserID: (userID: string) => void;
  setIsNewUser: (isNewUser: boolean) => void;
  setOnboardedUser: (onboardedUser: boolean) => void;
  setUsernamePromptDismissed: (usernamePromptDismissed: boolean) => void;
};

const initialState: State = {
  userID: undefined,
  isNewUser: false,
  onboardedUser: false,
  usernamePromptDismissed: false,
};

export type UserSlice = State & Actions;

export const createUserSlice: StateCreator<UserSlice> = (set) => ({
  ...initialState,
  setUserID: (userID) => {
    set(
      (store) => {
        store.userSlice.userID = userID;
      },
      false,
      "userSlice/setUserID"
    );
  },
  setIsNewUser: (isNewUser) => {
    set(
      (store) => {
        store.userSlice.isNewUser = isNewUser;
      },
      false,
      "userSlice/setIsNewUser"
    );
  },
  setOnboardedUser: (onboardedUser) => {
    set(
      (store) => {
        store.userSlice.onboardedUser = onboardedUser;
      },
      false,
      "userSlice/setOnboardedUser"
    );
  },
  setUsernamePromptDismissed: (usernamePromptDismissed) => {
    set(
      (store) => {
        store.userSlice.usernamePromptDismissed = usernamePromptDismissed;
      },
      false,
      "userSlice/setUsernamePromptDismissed"
    );
  },
});
