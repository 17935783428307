import { type StateCreator } from "store";
import type {
  CanvasConnection,
  Connection,
  ConnectionItemType,
  CanvasConnectionItemType,
  CreatedConnectionType,
  ConnectionTypeData,
} from "pages/Assets/types/connectionTypes";
import type { AssetItemOperationType } from "pages/Assets/types/abstractTypes";
import { DATA_LOAD_STATUS } from "config/appConfig";

interface LoadInfo {
  dataLoadStatus: DATA_LOAD_STATUS;
  connectionTypesLoadStatus: DATA_LOAD_STATUS;
  canvasConnectionsLoadStatus: DATA_LOAD_STATUS;
}

const CONNECTIONS_INITIAL_STATE = {
  data: {},
  canvasConnectionsData: {},
  loadInfo: {
    dataLoadStatus: DATA_LOAD_STATUS.NOT_LOADED,
    connectionTypesLoadStatus: DATA_LOAD_STATUS.NOT_LOADED,
    canvasConnectionsLoadStatus: DATA_LOAD_STATUS.NOT_LOADED,
  },
  displayArchivedItems: false,
  selectedItem: {
    id: null,
  },
  createdItem: {
    assetOperationType: null,
    error: null,
    loading: false,
    data: {
      name: "",
      connection_type_id: null,
    },
  },
  connectionTypes: {},
};

interface CreatedItem {
  assetOperationType: AssetItemOperationType;
  data: CreatedConnectionType;
  error: string | null;
  loading: boolean;
}

interface ConnectionsAssets {
  data: Record<string, ConnectionItemType>;
  canvasConnectionsData: Record<string, CanvasConnectionItemType>;
  loadInfo: LoadInfo;
  displayArchivedItems: boolean;
  selectedItem: {
    id: string | null;
  };
  createdItem: CreatedItem;
  connectionTypes: Record<string, ConnectionTypeData>;
}

interface ConnectionsActions {
  addDataItem: (data: Connection) => void;
  setCanvasConnection: (data: CanvasConnection) => void;
  updateDataItem: (id: string, data: Connection) => void;
  getDataItem: (id: string) => ConnectionItemType | undefined;
  getLoadInfo: () => LoadInfo;
  getCreatedItem: () => CreatedItem;
  deleteDataItem: (id: string) => void;
  setData: (data: Record<string, ConnectionItemType>) => void;
  setCanvasConnectionsData: (
    data: Record<string, CanvasConnectionItemType>
  ) => void;
  setSelectedItemId: (id: string | null) => void;
  setCreatedItemData: (data: Partial<CreatedConnectionType>) => void;
  setOperationType: (operationType: AssetItemOperationType) => void;
  setDisplayArchivedItems: (display: boolean) => void;
  clearSelectedItem: () => void;
  clearCreatedItem: () => void;
  clearStore: () => void;
  setCreatedItemError: (error: string | null) => void;
  setCreatedItemLoading: (loading: boolean) => void;
  setConnectionTypes: (data: Record<string, ConnectionTypeData>) => void;
  setLoadInfo: (loadInfo: Partial<LoadInfo>) => void;
  deleteCanvasConnectionById: (id: string) => void;
  deleteConnectionCanvasUsage: (assetId: string, canvasId: string) => void;
}

export interface ConnectionsAssetsSlice {
  connections: ConnectionsAssets;
  connectionsActions: ConnectionsActions;
}

const connectionsAssetsSlice: StateCreator<ConnectionsAssetsSlice> = function (
  set,
  getState
) {
  return {
    connections: { ...CONNECTIONS_INITIAL_STATE },
    connectionsActions: {
      addDataItem: (data) => {
        set(
          (store) => {
            store.assetsSlice.connections.data = {
              ...getState().assetsSlice.connections.data,
              [data.id]: { connection: { ...data } },
            };
          },
          false,
          "assets/addConnectionDataItem"
        );
      },
      setCanvasConnection: (data) => {
        const { id } = data;
        set(
          (store) => {
            store.assetsSlice.connections.canvasConnectionsData[id] = {
              connection: data,
            };
          },
          false,
          "assets/setCanvasConnection"
        );
      },
      updateDataItem: (id, data) => {
        set(
          (store) => {
            store.assetsSlice.connections.data = {
              ...getState().assetsSlice.connections.data,
              [id]: { connection: { ...data } },
            };
          },
          false,
          "assets/updateConnectionDataItem"
        );
      },
      getDataItem: (id) => getState().assetsSlice.connections.data[id],
      getLoadInfo: () => getState().assetsSlice.connections.loadInfo,
      getCreatedItem: () => {
        return getState().assetsSlice.connections.createdItem;
      },
      deleteDataItem: (id: string) => {
        const { [id]: _, ...rest } = getState().assetsSlice.connections.data;
        set(
          (store) => {
            store.assetsSlice.connections.data = rest;
          },
          false,
          "assets/deleteConnection"
        );
      },
      setData: (data) => {
        set(
          (store) => {
            store.assetsSlice.connections.data = data;
          },
          false,
          "assets/setConnectionsData"
        );
      },
      setCanvasConnectionsData: (data) => {
        set(
          (store) => {
            store.assetsSlice.connections.canvasConnectionsData = data;
          },
          false,
          "assets/setCanvasConnectionsData"
        );
      },
      setConnectionTypes: (data) => {
        set(
          (store) => {
            store.assetsSlice.connections.connectionTypes = data;
          },
          false,
          "assets/setConnectionTypes"
        );
      },
      setDisplayArchivedItems: (display) => {
        set(
          (store) => {
            store.assetsSlice.connections.displayArchivedItems = display;
          },
          false,
          "assets/setIsDisplayArchivedConnections"
        );
      },
      setSelectedItemId: (id) => {
        set(
          (store) => {
            store.assetsSlice.connections.selectedItem.id = id;
          },
          false,
          "assets/setSelectedConnectionId"
        );
      },
      setOperationType: (operationType) => {
        set(
          (store) => {
            store.assetsSlice.connections.createdItem.assetOperationType =
              operationType;
          },
          false,
          "assets/setConnectionOperationType"
        );
      },
      setCreatedItemData: (data) => {
        set(
          (store) => {
            store.assetsSlice.connections.createdItem.data = {
              ...store.assetsSlice.connections.createdItem.data,
              ...data,
            };
          },
          false,
          "assets/setCreatedConnectionData"
        );
      },
      setCreatedItemError: (error) => {
        set(
          (store) => {
            store.assetsSlice.connections.createdItem.error = error;
          },
          false,
          "assets/setCreatedConnectionError"
        );
      },
      setCreatedItemLoading: (loading) => {
        set(
          (store) => {
            store.assetsSlice.connections.createdItem.loading = loading;
          },
          false,
          "assets/setCreatedConnectionLoading"
        );
      },
      setLoadInfo: (loadInfo) => {
        set(
          (store) => {
            store.assetsSlice.connections.loadInfo = {
              ...store.assetsSlice.connections.loadInfo,
              ...loadInfo,
            };
          },
          false,
          "assets/setConnectionLoadInfo"
        );
      },
      clearSelectedItem: () => {
        set(
          (store) => {
            store.assetsSlice.connections.selectedItem.id = null;
          },
          false,
          "assets/clearSelectedConnection"
        );
      },
      clearCreatedItem: () => {
        set(
          (store) => {
            store.assetsSlice.connections.createdItem = {
              ...CONNECTIONS_INITIAL_STATE.createdItem,
            };
          },
          false,
          "assets/clearCreatedConnection"
        );
      },
      clearStore: () => {
        set(
          (store) => {
            store.assetsSlice.connections = { ...CONNECTIONS_INITIAL_STATE };
          },
          false,
          "assets/clearConnectionsStore"
        );
      },
      deleteCanvasConnectionById: (id) => {
        const { [id]: _, ...rest } =
          getState().assetsSlice.connections.canvasConnectionsData;
        set(
          (store) => {
            store.assetsSlice.connections.canvasConnectionsData = rest;
          },
          false,
          "assets/deleteCanvasConnection"
        );
      },
      deleteConnectionCanvasUsage: (assetId, canvasId) => {
        const { [assetId]: assetData } =
          getState().assetsSlice.connections.data;
        const canvases = assetData?.connection?.canvases;

        if (!canvases?.length) {
          return;
        }

        set(
          (store) => {
            store.assetsSlice.connections.data[assetId].connection.canvases =
              canvases.filter((item) => item.canvas_id !== canvasId);
          },
          false,
          "assets/deleteConnectionCanvasUsage"
        );
      },
    },
  };
};

export default connectionsAssetsSlice;
