import { useState, useRef, type ReactNode } from "react";
import {
  autoUpdate,
  flip,
  type Placement,
  shift,
  useFloating,
  useHover,
  offset,
  arrow,
  useDismiss,
  useDelayGroupContext,
  useInteractions,
  FloatingPortal,
} from "@floating-ui/react";
import { motion, AnimatePresence } from "framer-motion";
import cn from "classnames";
import Typography from "components/Typography/Typography";
import { BiHelpCircle, BiInfoCircle } from "react-icons/bi";
import styles from "./HelperTooltip.module.scss";

type DelayType = {
  open: number;
  close: number;
};

type TooltipProps = {
  iconSize?: number;
  variant?: "info" | "help";
  title?: string;
  text?: string | ReactNode;
  children?: ReactNode;
  tipElement?: ReactNode;
  placement?: Placement;
  withArrow?: boolean;
  mainAxisOffset?: number;
  crossAxisOffset?: number;
  delay?: Partial<DelayType>;
  tooltipContentClassName?: string;
};

export function HelperTooltip({
  iconSize = 16,
  variant = "info",
  title,
  text,
  tipElement,
  placement = "right-start",
  withArrow,
  mainAxisOffset = 8,
  crossAxisOffset = 0,
  delay = { open: 100 },
  tooltipContentClassName = "",
  children,
}: TooltipProps) {
  const [open, setOpen] = useState(false);
  const arrowRef = useRef(null);
  const { setCurrentId } = useDelayGroupContext();

  const {
    x,
    y,
    refs,
    strategy,
    context,
    placement: floatingPlacement,
    middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
  } = useFloating({
    open,
    onOpenChange(open) {
      setOpen(open);
      if (open) {
        setCurrentId(text);
      }
    },

    whileElementsMounted: autoUpdate,
    placement,
    middleware: [
      flip(),
      shift(),
      offset({ mainAxis: mainAxisOffset, crossAxis: crossAxisOffset }),
      arrow({ element: arrowRef }),
    ],
  });

  useDismiss(context, {
    bubbles: false,
  });

  useInteractions([useHover(context, { delay })]);

  const staticSide =
    {
      top: "bottom",
      right: "left",
      bottom: "top",
      left: "right",
    }[floatingPlacement.split("-")[0]] ?? "bottom";

  return (
    <AnimatePresence>
      <div className={styles.tooltip} ref={refs.setReference}>
        {variant === "info" ? (
          <BiInfoCircle size={iconSize} />
        ) : (
          <BiHelpCircle size={iconSize} />
        )}
        {open && (text || tipElement) && (
          <FloatingPortal>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 0.1 } }}
              className={cn(styles.tooltip__content, tooltipContentClassName)}
              ref={refs.setFloating}
              style={{
                position: strategy,
                top: `${y || 0}px`,
                left: `${x || 0}px`,
                zIndex: 100,
              }}
            >
              {title && <Typography variant={"h3"}>{title}</Typography>}
              {text && <Typography variant="caption1">{text}</Typography>}
              {tipElement && tipElement}
              {withArrow && (
                <div
                  className={cn(
                    styles.tooltip__arrow,
                    styles[`tooltip__arrow__${staticSide}`]
                  )}
                  ref={arrowRef}
                  style={{
                    left: arrowX ? `${arrowX}px` : "",
                    top: arrowY ? `${arrowY}px` : "",
                    [staticSide]: "-5px",
                  }}
                />
              )}
              {children && children}
            </motion.div>
          </FloatingPortal>
        )}
      </div>
    </AnimatePresence>
  );
}
