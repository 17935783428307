import { useCallback, useMemo } from "react";
import {
  getScheduledJobByLayerId as getScheduledJobByLayerIdAPICall,
  patchScheduledJob,
} from "api/http/canvas-scheduled-jobs-service";
import { useCanvasState, useToastsState } from "store";
import type { LayerType } from "models/canvas";
import type { ScheduledJob } from "models/scheduledJobs";
import { DATA_LOAD_STATUS } from "config/appConfig";
import { debouncePromise } from "utils/helpers";

export function useCanvasScheduledJobsActions() {
  const addToast = useToastsState((slice) => slice.addToast);

  const updateLayerScheduledJobInfo = useCanvasState(
    (state) => state.updateLayerScheduledJobInfo
  );

  const getConnectionId = useCanvasState((state) => state.getConnectionId);

  const getScheduledJobByLayerId = useCallback(
    async (layerId: LayerType["id"]) => {
      updateLayerScheduledJobInfo(layerId, {
        loadStatus: DATA_LOAD_STATUS.LOADING,
      });
      try {
        const jobData = await getScheduledJobByLayerIdAPICall(layerId);
        updateLayerScheduledJobInfo(layerId, {
          loadStatus: DATA_LOAD_STATUS.LOADED,
          data: jobData,
        });
      } catch (err) {
        console.error(err);
        updateLayerScheduledJobInfo(layerId, {
          loadStatus: DATA_LOAD_STATUS.ERROR,
        });
      }
    },
    []
  );

  const updateScheduledJob = useCallback(
    async (
      data: Partial<{
        scheduled_job_status: ScheduledJob["scheduled_job_status"];
        crontab: ScheduledJob["crontab"];
      }> &
        Pick<ScheduledJob, "id" | "layer_id">
    ) => {
      updateLayerScheduledJobInfo(data.layer_id, { updateInProgress: true });
      try {
        const { id, layer_id, ...rest } = data;
        const updatedJobData = await debouncePromise({
          promise: patchScheduledJob(data.id, rest, getConnectionId()),
        });
        updateLayerScheduledJobInfo(layer_id, {
          data: updatedJobData,
          updateInProgress: false,
        });
        addToast({
          variant: "success",
          message: "Scheduled job successfully updated",
        });
      } catch (err: any) {
        updateLayerScheduledJobInfo(data.layer_id, { updateInProgress: false });
        const cause =
          typeof err?.cause?.detail === "string" ? err.cause.detail : "";
        const errorMessage = cause
          ? `Failed to update scheduled job: "${cause}".`
          : "Failed to update scheduled job.";
        addToast({
          message: errorMessage,
          variant: "error",
        });
      }
    },
    []
  );

  return useMemo(
    () => ({
      getScheduledJobByLayerId,
      updateScheduledJob,
    }),
    [getScheduledJobByLayerId, updateScheduledJob]
  );
}
