import styles from "./VersionsDropdown.module.scss";
import { type FC } from "react";
import { type AssetTableVersionType } from "hooks/assets/useAddAssetData";
import { DropdownMenuButton } from "components/common/DropdownMenuButton/DropdownMenuButton";
import Typography from "components/Typography/Typography";
import CheckmarkIcon from "../ContextMenu/MenuOption/CheckmarkIcon";

interface VersionsDropdownProps {
  versions: AssetTableVersionType[];
  activeVersion: AssetTableVersionType;
  onSelect: (v: AssetTableVersionType) => void;
  classes?: {
    contextMenu?: string;
  };
  disabled?: boolean;
}

const VersionsDropdown: FC<VersionsDropdownProps> = ({
  versions,
  activeVersion: activeVersionProp,
  onSelect,
  classes = {},
  disabled = false,
}) => {
  const handleSelect = (versionEl) => {
    onSelect(versionEl);
  };
  const activeVersion = activeVersionProp || versions[versions.length - 1];
  const activeVersionId = activeVersion?.id;

  const versionsOptions = versions.map((versionEl) => {
    const { id, version } = versionEl;

    return {
      id,
      key: id,
      type: "option" as const,
      label: `v.${version}`,
      rightSideContent: id === activeVersionId ? <CheckmarkIcon /> : null,
      onClick: () => {
        handleSelect(versionEl);
      },
    };
  });
  return (
    <div className={styles.cellVersions}>
      <DropdownMenuButton
        buttonVariant="text"
        menuOptions={versionsOptions}
        nodesClassNames={{
          button: styles.dropdownButton,
          container: styles.dropdownContainer,
          contextMenu: classes?.contextMenu,
        }}
        disabled={disabled}
      >
        <Typography component="span" variant="body1">
          v.{activeVersion?.version ?? ""}
        </Typography>
      </DropdownMenuButton>
    </div>
  );
};

export default VersionsDropdown;
