import { type ReactNode } from "react";
import { type StateCreator } from "store";
import { SORT_BY } from "components/comments/config";
import type { CommentType, ReplyType } from "models/comments";

type State = {
  isCommentsDataLoading: boolean;
  commentsErrorMessage: ReactNode;
  comments: Map<CommentType["block_id"], Map<CommentType["id"], CommentType>>;
  activeBlockCommentId: CommentType["id"] | null;
  searchValue: string;
  sortBy: SORT_BY;
  showResolved: boolean;
};

type Actions = {
  setIsCommentsDataLoading: (isLoading: boolean) => void;
  setCommentsErrorMessage: (errorMessage: ReactNode) => void;
  setComments: (commentsList: CommentType[]) => void;
  getCommentById: (commentId: CommentType["id"]) => CommentType | null;
  setActiveBlockCommentId: (commentId: CommentType["id"] | null) => void;
  setSearchValue: (value: string) => void;
  setSortBy: (sortBy: SORT_BY) => void;
  setShowResolved: (showResolved: boolean) => void;
  resolveComment: (commentId: CommentType["id"]) => void;
  unresolveComment: (commentId: CommentType["id"]) => void;
  deleteComment: (commentId: CommentType["id"]) => void;
  addComment: (comment: CommentType) => void;
  editComment: (updatedComment: CommentType) => void;
  deleteReply: ({
    commentId,
    replyId,
  }: {
    commentId: CommentType["id"];
    replyId: ReplyType["id"];
  }) => void;
  addReply: (reply: ReplyType) => void;
  editReply: (updatedReply: ReplyType) => void;
  clearStore: () => void;
};

const getInitialState = (): State => ({
  isCommentsDataLoading: false,
  commentsErrorMessage: null,
  comments: new Map(),
  activeBlockCommentId: null,
  searchValue: "",
  sortBy: SORT_BY.NEWEST_FIRST,
  showResolved: false,
});

export type CommentsSlice = State & Actions;

export const createCommentsSlice: StateCreator<CommentsSlice> = (set, get) => ({
  ...getInitialState(),
  setIsCommentsDataLoading(isLoading) {
    set(
      (store) => {
        store.commentsSlice.isCommentsDataLoading = isLoading;
      },
      false,
      "comments/setIsCommentsDataLoading"
    );
  },
  setCommentsErrorMessage(errorMessage) {
    set(
      (store) => {
        store.commentsSlice.commentsErrorMessage = errorMessage;
      },
      false,
      "comments/setCommentsErrorMessage"
    );
  },
  setComments: (commentsList) => {
    set(
      (store) => {
        const comments: State["comments"] = new Map();

        // group comments by block
        commentsList.forEach((comment) => {
          const blockComments = comments.get(comment.block_id) || new Map();
          blockComments.set(comment.id, comment);
          comments.set(comment.block_id, blockComments);
        });

        store.commentsSlice.comments = comments;
      },
      false,
      "comments/setComments"
    );
  },
  getCommentById: (commentId) => {
    const comments = get().commentsSlice.comments;
    for (const blockComments of comments.values()) {
      const comment = blockComments.get(commentId);
      if (comment) {
        return comment;
      }
    }
    return null;
  },
  setActiveBlockCommentId: (commentId) => {
    set(
      (store) => {
        store.commentsSlice.activeBlockCommentId = commentId;
      },
      false,
      "comments/setActiveBlockCommentId"
    );
  },
  setSearchValue: (value) => {
    set(
      (store) => {
        store.commentsSlice.searchValue = value;
      },
      false,
      "comments/setSearchValue"
    );
  },
  setSortBy: (sortBy) => {
    set(
      (store) => {
        store.commentsSlice.sortBy = sortBy;
      },
      false,
      "comments/setSortBy"
    );
  },
  setShowResolved: (showResolved) => {
    set(
      (store) => {
        store.commentsSlice.showResolved = showResolved;
      },
      false,
      "comments/setShowResolved"
    );
  },
  resolveComment: (commentId) => {
    set(
      (store) => {
        const comment = store.commentsSlice.getCommentById(commentId);
        if (!comment) {
          return;
        }

        const writableComment = store.commentsSlice.comments
          .get(comment.block_id)
          ?.get(commentId);

        if (!writableComment) {
          return;
        }

        writableComment.is_resolved = true;

        // clean active block comment id
        if (commentId === store.commentsSlice.activeBlockCommentId) {
          store.commentsSlice.activeBlockCommentId = null;
        }
      },
      false,
      "comments/resolveComment"
    );
  },
  unresolveComment: (commentId) => {
    set(
      (store) => {
        const comment = store.commentsSlice.getCommentById(commentId);
        if (!comment) {
          return;
        }

        const writableComment = store.commentsSlice.comments
          .get(comment.block_id)
          ?.get(commentId);

        if (!writableComment) {
          return;
        }

        writableComment.is_resolved = false;
      },
      false,
      "comments/unresolveComment"
    );
  },
  deleteComment: (commentId) => {
    set(
      (store) => {
        const comment = store.commentsSlice.getCommentById(commentId);
        if (!comment) {
          return;
        }

        // delete the comment
        store.commentsSlice.comments.get(comment.block_id)?.delete(commentId);

        // remove block from comments map if it has no comments
        if (store.commentsSlice.comments.get(comment.block_id)?.size === 0) {
          store.commentsSlice.comments.delete(comment.block_id);
        }

        // clean active block comment id
        if (commentId === store.commentsSlice.activeBlockCommentId) {
          store.commentsSlice.activeBlockCommentId = null;
        }
      },
      false,
      "comments/deleteComment"
    );
  },
  addComment: (comment) => {
    set(
      (store) => {
        let blockComments = store.commentsSlice.comments.get(comment.block_id);

        if (!blockComments) {
          // add new block to comments map
          blockComments = new Map();
          store.commentsSlice.comments.set(comment.block_id, blockComments);
        }

        blockComments.set(comment.id, comment);
      },
      false,
      "comments/addComment"
    );
  },
  editComment: (updatedComment) => {
    set(
      (store) => {
        const blockComments = store.commentsSlice.comments.get(
          updatedComment.block_id
        );

        if (!blockComments) {
          return;
        }

        blockComments.set(updatedComment.id, updatedComment);
      },
      false,
      "comments/editComment"
    );
  },
  deleteReply({ commentId, replyId }) {
    set(
      (store) => {
        const comment = store.commentsSlice.getCommentById(commentId);
        if (!comment) {
          return;
        }

        const writableComment = store.commentsSlice.comments
          .get(comment.block_id)
          ?.get(commentId);

        if (!writableComment) {
          return;
        }

        // delete the reply
        writableComment.block_comment_replies =
          writableComment.block_comment_replies.filter(
            (reply) => reply.id !== replyId
          );
      },
      false,
      "comments/deleteReply"
    );
  },
  addReply: (reply) => {
    set(
      (store) => {
        const comment = store.commentsSlice.comments
          .get(reply.block_id)
          ?.get(reply.block_comment_id);

        if (
          !comment ||
          comment.block_comment_replies.some(({ id }) => id === reply.id)
        ) {
          // avoid race condition
          return;
        }

        comment.block_comment_replies.push(reply);
      },
      false,
      "comments/addReply"
    );
  },
  editReply: (updatedReply) => {
    set(
      (store) => {
        const comment = store.commentsSlice.comments
          .get(updatedReply.block_id)
          ?.get(updatedReply.block_comment_id);

        if (!comment) {
          return;
        }

        const replyToUpdate = comment.block_comment_replies.find(
          ({ id }) => id === updatedReply.id
        );

        if (!replyToUpdate) {
          return;
        }

        Object.assign(replyToUpdate, updatedReply);
      },
      false,
      "comments/editReply"
    );
  },
  clearStore: () => {
    set(
      (store) => {
        Object.assign(store.commentsSlice, getInitialState());
      },
      false,
      "comments/clearStore"
    );
  },
});
