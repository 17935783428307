import styles from "./ConfirmAppsDeletionModal.module.scss";
import { useMemo } from "react";
import type { AppCardType } from "models/app";

const MAX_APPS_TO_SHOW = 5;

type ConfirmAppsDeletionModalProps = {
  appIds: AppCardType["id"][];
  apps: AppCardType[];
};

export function ConfirmAppsDeletionModal({
  appIds,
  apps,
}: ConfirmAppsDeletionModalProps) {
  const appsToDisplay = useMemo(() => {
    return appIds.slice(0, MAX_APPS_TO_SHOW);
  }, [appIds]);

  const extraCount = useMemo(() => {
    return appIds.length - appsToDisplay.length;
  }, [appIds, appsToDisplay]);

  return (
    <>
      {/* Question */}
      {appIds.length === 1
        ? "Are you sure you want to delete this app?"
        : `Are you sure you want to delete these ${appIds.length} apps?`}

      {/* Apps list */}
      <ul className={styles.appsList}>
        {appsToDisplay.map((appId) => {
          const app = apps.find((app) => app.id === appId);
          if (!app) {
            return null;
          }
          return (
            <li key={appId} className={styles.listItem}>
              <span className={styles.name}>{app.name}</span>
            </li>
          );
        })}
      </ul>

      {extraCount > 0 ? (
        <div className={styles.extraCount}>... and {extraCount} more</div>
      ) : null}
    </>
  );
}
