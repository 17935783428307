import styles from "./WindowHeader.module.scss";
import { type ReactNode } from "react";
import cn from "classnames";
import Typography from "components/Typography/Typography";

interface WindowHeaderProps {
  children: ReactNode;
  containerClassName?: string;
  contentClassName?: string;
}

export function WindowHeader({
  children,
  containerClassName = "",
  contentClassName = "",
}: WindowHeaderProps) {
  return (
    <div className={cn(styles.wrapper, containerClassName)}>
      <Typography className={contentClassName} variant="caption1">
        {children}
      </Typography>
    </div>
  );
}
