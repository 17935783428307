import styles from "./ReportsTable.module.scss";
import { useMemo, type MouseEvent } from "react";
import cn from "classnames";
import moment from "moment";
import {
  flexRender,
  useReactTable,
  getCoreRowModel,
  type ColumnDef,
} from "@tanstack/react-table";
import KebabMenu from "components/common/KebabMenu/KebabMenu";
import type { ReportTableType } from "models/publishedReport";
import type { ContextMenuItem } from "components/common/ContextMenu/ContextMenu";
import CheckmarkIcon from "components/common/ContextMenu/MenuOption/CheckmarkIcon";
import { Link } from "react-router-dom";
import { BiLinkExternal, BiDownArrowAlt, BiUpArrowAlt } from "react-icons/bi";
import Tooltip from "components/common/Tooltip/Tooltip";
import { SORTING_COLUMN } from "config/localPreferences";
import {
  SORTING_DIRECTION,
  SORTING_COLUMN_SORTING_TYPE,
  SORTING_TYPE,
} from "config/appConfig";
import { getSortingDirectionLabel } from "utils/sort";

const SORT_ORDER_ICON_MAP = {
  [SORTING_DIRECTION.ASCENDING]: <BiUpArrowAlt size={16} />,
  [SORTING_DIRECTION.DESCENDING]: <BiDownArrowAlt size={16} />,
};

enum TABLE_COLUMN {
  NAME = "name",
  CREATED_AT = "createdAt",
  LAST_MODIFIED = "lastModified",
  OPTIONS = "options",
}

const SORTING_COLUMN_TABLE_COLUMN = Object.freeze({
  [SORTING_COLUMN.name]: TABLE_COLUMN.NAME,
  [SORTING_COLUMN.createdAt]: TABLE_COLUMN.CREATED_AT,
  [SORTING_COLUMN.lastModified]: TABLE_COLUMN.LAST_MODIFIED,
});

const SORTABLE_TABLE_COLUMNS = Object.freeze(
  Object.values(SORTING_COLUMN_TABLE_COLUMN)
);

const TABLE_COLUMN_SORTING_COLUMN = Object.freeze(
  Object.fromEntries(
    Object.entries(SORTING_COLUMN_TABLE_COLUMN).map(([key, value]) => [
      value,
      key,
    ])
  )
);

const TABLE_COLUMNS_DEFAULT = new Set([
  TABLE_COLUMN.NAME,
  TABLE_COLUMN.CREATED_AT,
  TABLE_COLUMN.LAST_MODIFIED,
  TABLE_COLUMN.OPTIONS,
]);

interface ReportTableProps {
  items: ReportTableType[];
  processedItemsIds?: Record<string, string> | null;
  selectedItemsIds?: Record<string, string> | null;
  showSharingInfo?: boolean;
  sortingColumn: SORTING_COLUMN;
  sortingDirection: SORTING_DIRECTION;
  onChangeSorting: ({
    column,
    direction,
  }: {
    column: SORTING_COLUMN;
    direction: SORTING_DIRECTION;
  }) => void;
  onGetReportOptions: (report: ReportTableType) => ContextMenuItem[];
  onRowClick: (id: string, event: MouseEvent<HTMLElement>) => void;
}

const renderSortingDirectionIcon = ({
  sortingColumn,
  sortingDirection,
}: {
  sortingDirection: SORTING_DIRECTION;
  sortingColumn: SORTING_COLUMN;
}) => {
  const icon = SORT_ORDER_ICON_MAP[sortingDirection];

  const sortingType =
    SORTING_COLUMN_SORTING_TYPE[sortingColumn] || SORTING_TYPE.TEXT;

  const iconTooltip = getSortingDirectionLabel({
    sortingType,
    sortingDirection,
  });

  return (
    <Tooltip text={iconTooltip} placement="top">
      {icon}
    </Tooltip>
  );
};

export function ReportsTable({
  items,
  processedItemsIds,
  selectedItemsIds,
  sortingColumn,
  sortingDirection,
  onChangeSorting,
  onGetReportOptions,
  onRowClick,
}: ReportTableProps) {
  const sortByTableColumn = (columnId: string) => {
    const newSortingColumn = TABLE_COLUMN_SORTING_COLUMN[columnId];

    if (!newSortingColumn) {
      return;
    }

    if (newSortingColumn !== sortingColumn) {
      onChangeSorting({
        column: newSortingColumn as SORTING_COLUMN,
        direction: sortingDirection,
      });
    } else {
      onChangeSorting({
        column: sortingColumn,
        direction:
          sortingDirection === SORTING_DIRECTION.ASCENDING
            ? SORTING_DIRECTION.DESCENDING
            : SORTING_DIRECTION.ASCENDING,
      });
    }
  };
  const columnConfig = useMemo<
    Record<TABLE_COLUMN, ColumnDef<ReportTableType>>
  >(() => {
    return {
      [TABLE_COLUMN.NAME]: {
        id: TABLE_COLUMN.NAME,
        enableSorting: false,
        header: () => (
          <div className={cn(styles["table__header-label"])}>Report name</div>
        ),
        cell: (cellContext) => {
          const report = cellContext.row.original;
          return (
            <div key={report.id} className={styles["name-column"]}>
              <CheckmarkIcon
                className={cn(styles.checkmarkIcon, {
                  [styles.hidden]: !selectedItemsIds?.[report.id],
                })}
              />
              <div className={styles["name-column"]}>
                <Link
                  to={`/report/${report.name}`}
                  className={styles["name-column__link"]}
                  target="_blank"
                >
                  {report.name}
                  <BiLinkExternal size={12} />
                </Link>
              </div>
            </div>
          );
        },
      },
      [TABLE_COLUMN.CREATED_AT]: {
        id: TABLE_COLUMN.CREATED_AT,
        enableSorting: false,
        header: () => (
          <div className={cn(styles["table__header-label"])}>Created</div>
        ),
        cell: (cellContext) => {
          const report = cellContext.row.original;
          return <div>{moment(report.createdAt).format("DD MMMM YYYY")}</div>;
        },
      },
      [TABLE_COLUMN.LAST_MODIFIED]: {
        id: TABLE_COLUMN.LAST_MODIFIED,
        enableSorting: false,
        header: () => (
          <div className={cn(styles["table__header-label"])}>Last modified</div>
        ),
        cell: (cellContext) => {
          const report = cellContext.row.original;
          return (
            <div>{moment(report.lastModified).format("DD MMMM YYYY")}</div>
          );
        },
      },
      [TABLE_COLUMN.OPTIONS]: {
        id: TABLE_COLUMN.OPTIONS,
        enableSorting: false,
        header: () => (
          <div
            className={cn(
              styles["table__header-label"],
              styles["table__header-label__options"]
            )}
          >
            Options
          </div>
        ),
        cell: (cellContext) => {
          const report = cellContext.row.original;
          const options = onGetReportOptions(report);
          return (
            <div className={styles["options-column"]}>
              <KebabMenu
                menuOptions={options}
                classes={{ trigger: styles["options-column__menu"] }}
              />
            </div>
          );
        },
      },
    };
  }, [onGetReportOptions, selectedItemsIds]);

  const columns = useMemo(() => {
    const columnsToShow = TABLE_COLUMNS_DEFAULT;

    return [...columnsToShow.values()].map((column) => columnConfig[column]);
  }, [columnConfig]);

  const table = useReactTable({
    data: items,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className={styles.table}>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const columnId = header.id;
                const columnSortingEnabled = SORTABLE_TABLE_COLUMNS.some(
                  (item) => item === columnId
                );
                const sortedByColumn =
                  SORTING_COLUMN_TABLE_COLUMN[sortingColumn] === columnId;

                return (
                  <th
                    key={columnId}
                    className={cn(styles["table__header-cell"], {
                      [styles.header_cell_sortable]: columnSortingEnabled,
                    })}
                    onClick={() => {
                      if (columnSortingEnabled) {
                        sortByTableColumn(columnId);
                      }
                    }}
                  >
                    <div className={styles.header_cell_content}>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {sortedByColumn ? (
                        <div className={styles.sort_icon_wrapper}>
                          {renderSortingDirectionIcon({
                            sortingColumn,
                            sortingDirection,
                          })}
                        </div>
                      ) : null}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>

        <tbody>
          {table.getRowModel().rows.map((row) => {
            const report = row.original;
            return (
              <tr
                key={row.id}
                className={cn(styles["table__row"], {
                  [styles["table__row_processed"]]:
                    processedItemsIds?.[report.id],
                  [styles["table__row_selected"]]:
                    selectedItemsIds?.[report.id],
                })}
                onClick={(event) => {
                  onRowClick(report.id, event);
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className={styles["table__cell"]}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
