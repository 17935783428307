import { CANVAS_API_URL } from "config/appConfig";
import { callAPI } from "./call-api";
import type { PublishedReport } from "models/publishedReport";

const getPublishedReportByBlockId = async (blockId: string) =>
  await callAPI<PublishedReport>({
    url: `${CANVAS_API_URL}/report/block/${blockId}`,
    method: "GET",
  });

const getPublishedReportsByWorkspaceId = async (workspaceId: string) =>
  await callAPI<PublishedReport[]>({
    url: `${CANVAS_API_URL}/report/workspace/${workspaceId}`,
    method: "GET",
  });

const getPublishedReportsByUser = async () =>
  await callAPI<PublishedReport>({
    url: `${CANVAS_API_URL}/report/by_user`,
    method: "GET",
  });

const postPublishedReport = async (
  blockId: string,
  data: {
    name: PublishedReport["name"];
    workspace_id?: PublishedReport["workspace_id"];
  }
) =>
  await callAPI<PublishedReport>({
    url: `${CANVAS_API_URL}/report/block/${blockId}`,
    method: "POST",
    data,
  });

const putPublishedReport = async (
  reportId: PublishedReport["id"],
  blockId: PublishedReport["block_id"],
  data: Partial<{
    name: PublishedReport["name"];
  }>
) =>
  await callAPI<PublishedReport>({
    url: `${CANVAS_API_URL}/report/block/${blockId}/report_id/${reportId}`,
    method: "PUT",
    data,
  });

const deletePublishedReport = async (reportId: PublishedReport["id"]) =>
  await callAPI<PublishedReport>({
    url: `${CANVAS_API_URL}/report/delete/${reportId}`,
    method: "DELETE",
  });

const deletePublishedReportsByIds = async (reportIds: string[]) =>
  await callAPI<boolean>({
    url: `${CANVAS_API_URL}/report/delete_by_ids`,
    method: "DELETE",
    data: { ids: reportIds },
  });

const fetchPresignedUrl = async (reportName: string) =>
  await callAPI<string>({
    url: `${CANVAS_API_URL}/report/presigned_url/${reportName}`,
    method: "GET",
  });

const getCanvasLinkForReport = async (reportId: string) =>
  await callAPI<string>({
    url: `${CANVAS_API_URL}/report/canvas_link/${reportId}`,
    method: "GET",
  });

export {
  getPublishedReportByBlockId,
  getPublishedReportsByWorkspaceId,
  getPublishedReportsByUser,
  postPublishedReport,
  putPublishedReport,
  deletePublishedReport,
  fetchPresignedUrl,
  getCanvasLinkForReport,
  deletePublishedReportsByIds,
};
