import {
  DataBricksIcon,
  MariaDBIcon,
  MySQLIcon,
  PostgreSQLIcon,
  SnowflakeIcon,
} from "./components/icons";

export enum CONNECTION_TYPE {
  snowflake = "snowflake",
  postgreSQL = "postgresql",
  mySQL = "mysql",
  dataBricks = "databricks",
  mariaDB = "mariadb",
}

export const CONNECTION_TYPES = Object.values(CONNECTION_TYPE);

export const CONNECTION_TYPE_CONFIG = Object.freeze({
  [CONNECTION_TYPE.snowflake]: {
    id: CONNECTION_TYPE.snowflake,
    label: "Snowflake",
    icon: SnowflakeIcon,
  },
  [CONNECTION_TYPE.postgreSQL]: {
    id: CONNECTION_TYPE.postgreSQL,
    label: "PostgreSQL",
    icon: PostgreSQLIcon,
  },
  [CONNECTION_TYPE.mySQL]: {
    id: CONNECTION_TYPE.mySQL,
    label: "MySQL",
    icon: MySQLIcon,
  },
  [CONNECTION_TYPE.dataBricks]: {
    id: CONNECTION_TYPE.dataBricks,
    label: "DataBricks",
    icon: DataBricksIcon,
  },
  [CONNECTION_TYPE.mariaDB]: {
    id: CONNECTION_TYPE.mariaDB,
    label: "MariaDB",
    icon: MariaDBIcon,
  },
});

export const CONNECTION_CONFIG_FIELD = Object.freeze({
  host_url: "host_url",
  port: "port",
  database_name: "database_name",
  username: "username",
  password: "password",
  account_name: "account_name",
  warehouse: "warehouse",
  database: "database",
  jdbc_url: "jdbc_url",
  access_token: "access_token",
});

export const CONNECTION_CONFIG_FIELDS = Object.values(CONNECTION_CONFIG_FIELD);

export const CONNECTION_CONFIG_FIELD_CONFIG = Object.freeze({
  [CONNECTION_CONFIG_FIELD.host_url]: {
    name: CONNECTION_CONFIG_FIELD.host_url,
    title: "Host URL",
    placeholder: "Enter URL-link...",
    defaultValue: "",
    type: "string",
    skipInRequestWhenIsEmpty: false,
  },
  [CONNECTION_CONFIG_FIELD.port]: {
    name: CONNECTION_CONFIG_FIELD.port,
    title: "Port",
    placeholder: "Port...",
    defaultValue: "",
    type: "number",
    skipInRequestWhenIsEmpty: false,
  },
  [CONNECTION_CONFIG_FIELD.database_name]: {
    name: CONNECTION_CONFIG_FIELD.database_name,
    title: "Database Name",
    placeholder: "Database name...",
    defaultValue: "",
    type: "string",
    skipInRequestWhenIsEmpty: false,
  },
  [CONNECTION_CONFIG_FIELD.username]: {
    name: CONNECTION_CONFIG_FIELD.username,
    title: "Username",
    placeholder: "Enter username...",
    defaultValue: "",
    type: "string",
    skipInRequestWhenIsEmpty: false,
  },
  [CONNECTION_CONFIG_FIELD.password]: {
    name: CONNECTION_CONFIG_FIELD.password,
    title: "Password",
    placeholder: "Password...",
    defaultValue: "",
    type: "string",
    skipInRequestWhenIsEmpty: true,
  },
  [CONNECTION_CONFIG_FIELD.account_name]: {
    name: CONNECTION_CONFIG_FIELD.account_name,
    title: "Account name",
    placeholder: "Account name...",
    defaultValue: "",
    type: "string",
    skipInRequestWhenIsEmpty: false,
  },
  [CONNECTION_CONFIG_FIELD.warehouse]: {
    name: CONNECTION_CONFIG_FIELD.warehouse,
    title: "Warehouse",
    placeholder: "Warehouse...",
    defaultValue: "",
    type: "string",
    skipInRequestWhenIsEmpty: false,
  },
  [CONNECTION_CONFIG_FIELD.database]: {
    name: CONNECTION_CONFIG_FIELD.database,
    title: "Database",
    placeholder: "Database...",
    defaultValue: "",
    type: "string",
    skipInRequestWhenIsEmpty: false,
  },
  [CONNECTION_CONFIG_FIELD.jdbc_url]: {
    name: CONNECTION_CONFIG_FIELD.jdbc_url,
    title: "JDBC URL",
    placeholder: "URL-link...",
    defaultValue: "",
    type: "string",
    skipInRequestWhenIsEmpty: false,
  },
  [CONNECTION_CONFIG_FIELD.access_token]: {
    name: CONNECTION_CONFIG_FIELD.database,
    title: "Access token",
    placeholder: "Access token...",
    defaultValue: "",
    type: "string",
    skipInRequestWhenIsEmpty: true,
  },
});
/* https://linear.app/zerve-ai/issue/FRO-1183/hide-test-button-from-connections-screen-on-assets
  Temporary constant to hide Test button for all the Connections forms. Get rid of this constant
  when we are ready with Test connection functionality */
export const FORMS_SHOW_TEST_BUTTON = false;

/* https://linear.app/zerve-ai/issue/FRO-1185/hide-databricks-connection-option
  Temporary constant to hide DataBricks connections. Get rid of this constant
  when DataBricks BE functionality is ready */
export const CONNECTION_TYPES_TO_EXCLUDE = [CONNECTION_TYPE.dataBricks];
