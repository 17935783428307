import styles from "./CreateAppModal.module.scss";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import canvasService from "api/http/canvas-service";
import { type PropsWithModal } from "hooks/useModal";
import TextField from "components/common/TextField/TextField";
import { Button } from "components/common/Button/Button";
import Loader from "components/common/Loader/Loader";
import Typography from "components/Typography/Typography";

type CreateAppModalProps = PropsWithModal<{
  workspaceId?: string;
  organizationId?: string;
}>;

export const CreateAppModal = ({
  workspaceId,
  organizationId,
  onModalClose,
}: CreateAppModalProps) => {
  const [isAppCreationPending, setIsAppCreationPending] = useState(false);

  const navigate = useNavigate();
  const onCancel = () => {
    onModalClose();
  };

  const handleSubmit = (values) => {
    setIsAppCreationPending(true);
    canvasService
      .postCreateApp({
        name: values.appName,
        workspace_id: workspaceId,
        organization_id: organizationId,
      })
      .then((returnedValues) => {
        const {
          id = "",
          organization_id = "",
          workspace_id = "",
        }: {
          id: string;
          organization_id: string;
          workspace_id: string;
        } = returnedValues;
        onModalClose();
        navigate(
          `/org/${organization_id}/workspaces/${workspace_id}/apps/${id}`
        );
      })
      .catch((error) => {
        if (error.cause) {
          formik.setErrors(error.cause);
        }
        setIsAppCreationPending(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      appName: "",
    },
    validationSchema: Yup.object({
      appName: Yup.string().required("Required"),
    }),
    onSubmit: handleSubmit,
  });

  return (
    <div>
      <Typography
        component="span"
        variant="body2"
        className={styles["modal__label"]}
      >
        Enter the name for your new app. You can change it later.
      </Typography>
      <form className={styles.modal__form} onSubmit={formik.handleSubmit}>
        <TextField
          id="appName"
          placeholder="Type app name..."
          autoFocus
          inputClassName={styles["modal__input"]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.appName}
          error={formik.touched.appName ? formik.errors.appName : ""}
        />
        <div className={styles["modal__row"]}>
          <Button size="large" variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            disabled={!formik.isValid || !formik.dirty || isAppCreationPending}
            size="large"
            type="submit"
          >
            {isAppCreationPending ? <Loader /> : <span>Create</span>}
          </Button>
        </div>
      </form>
    </div>
  );
};
