import styles from "./Switch.module.scss";
import { useCallback } from "react";
import cn from "classnames";
import Loader from "components/common/Loader/Loader";
import Typography from "components/Typography/Typography";

type SwitchProps = {
  id?: string;
  name?: string;
  title?: string;
  helperText?: string;
  isChecked: boolean;
  className?: string;
  titleClassName?: string;
  disabled?: boolean;
  loading?: boolean;
  ariaLabel?: string;
  onChange: (newValue: boolean) => void;
};

const Switch = ({
  id,
  title,
  helperText,
  isChecked,
  className = "",
  titleClassName = "",
  disabled = false,
  loading = false,
  ariaLabel,
  onChange,
}: SwitchProps) => {
  const handleToggle = useCallback(() => {
    if (disabled) {
      return;
    }

    onChange(!isChecked);
  }, [onChange, isChecked, disabled]);

  return (
    <>
      <div
        id={id}
        role="switch"
        aria-checked={isChecked}
        aria-readonly={disabled}
        aria-label={ariaLabel}
        className={cn(styles.switch, className, {
          [styles["switch_disabled"]]: disabled,
        })}
        onClick={handleToggle}
      >
        {loading ? (
          <div className={styles.loader_container}>
            <Loader size={16} />
          </div>
        ) : (
          <div
            className={cn(styles["switch__track"], {
              [styles["switch__track__checked"]]: isChecked,
            })}
          >
            <div className={styles["switch__thumb"]} />
          </div>
        )}
        {title ? (
          <span className={cn(styles["switch__title"], titleClassName)}>
            {title}
          </span>
        ) : null}
      </div>

      {helperText ? (
        <Typography variant="body2" className={styles.helperText}>
          {helperText}
        </Typography>
      ) : null}
    </>
  );
};

export default Switch;
