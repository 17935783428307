/* 
This is an authentication page that gives the users the option to login or sign up. 
It uses the Auth0 React SDK to handle the authentication process.
On larger screens, the page is split into two sections, one with an attractive image and the other with the login and signup buttons.
On smaller screens, just the panels with the login and signup buttons are shown.
*/

import styles from "./AuthLogin.module.scss";
import { useEffect, useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useLocation } from "react-router-dom";
import image from "assets/images/auth_code-blocks.png";
import zerveLogo from "assets/icons/logo-white-text.svg";
import ToasterMessage from "components/common/ToasterMessage/ToasterMessage";

export function AuthLogin() {
  const { isAuthenticated, isLoading, error, loginWithRedirect } = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();

  // get the redirect path from the search params
  const redirectPath =
    new URLSearchParams(location.search).get("redirectTo") || "/";

  const hint = new URLSearchParams(location.search).get("hint");

  const validHint = useMemo(
    () => hint === "login" || hint === "signup",
    [hint]
  );

  // add the redirect path to the current path
  const handleAuth = (hint) => {
    loginWithRedirect({
      appState: {
        returnTo: redirectPath,
      },
      authorizationParams: {
        screen_hint: hint || "login",
        prompt: "login", // prompts user for reauthentication
      },
    });
  };

  // redirect to the home page if the user is authenticated
  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      navigate("/");
    } else if (validHint) {
      handleAuth(hint);
    }
  }, [isLoading, isAuthenticated]);

  // if the user is loading or the hint is valid, don't render anything
  if (isLoading || validHint) {
    return null;
  }

  return (
    <div className={styles.container}>
      {/* a decorative panel explaining what zerve is, displayed on larger screens */}
      <div className={styles.left}>
        <div className={styles.left__content}>
          <h1 className={styles.left__content__headline}>
            A Unified Developer Space For Data Science and AI
          </h1>

          <img src={image} className={styles.left__content__image} />

          <h2 className={styles.left__content__subheadline}>
            Zerve is a platform for data scientists and AI engineers to
            collaborate and build AI applications.
          </h2>
        </div>
      </div>
      {/* the login controls, on smaller screens this goes full width */}
      <div className={styles.right}>
        <div className={styles.right__content}>
          <h3 className={styles.getStartedBtn}>Get Started</h3>
          {/* the login and signup buttons */}
          <div className={styles.right__content__buttons}>
            <button
              className={styles.right__content__button}
              onClick={() => {
                handleAuth("login");
              }}
            >
              Login
            </button>
            <button
              className={styles.right__content__button}
              onClick={() => {
                handleAuth("signup");
              }}
            >
              Sign Up
            </button>
          </div>

          {/* render the auth0 error if there is one */}
          {error ? (
            <ToasterMessage animate={false} variant="error">
              {error.message}
            </ToasterMessage>
          ) : null}
        </div>

        {/* A footer that containers the zerve logo and required legal links */}
        <div className={styles.right__footer}>
          <img src={zerveLogo} className={styles.right__footer__logo} />
          <div className={styles.right__footer__links}>
            <a
              href="https://docs.zerve.ai/privacy-policy"
              target="_blank"
              rel="noreferrer"
              className={styles.right__footer__link}
            >
              Privacy Policy
            </a>
            <span className={styles.divider}>|</span>
            <a
              href="https://docs.zerve.ai/terms-of-use"
              target="_blank"
              rel="noreferrer"
              className={styles.right__footer__link}
            >
              Terms of Service
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
