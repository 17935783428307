import styles from "./ReportsListLayout.module.scss";
import type { ChangeEvent, MouseEvent, ReactNode } from "react";
import cn from "classnames";
import { BiSearch } from "react-icons/bi";
import { type SORTING_COLUMN } from "config/localPreferences";
import { type SORTING_DIRECTION } from "config/appConfig";
import { EmptyState } from "components/common/EmptyState/EmptyState";
import {
  SortingDropdown,
  type SortOptions,
} from "components/common/SortingDropdown/SortingDropdown";
import type { ReportTableType } from "models/publishedReport";
import type { ContextMenuItem } from "components/common/ContextMenu/ContextMenu";
import { ReportsTable } from "../ReportsTable/ReportsTable";

type ReportsListLayoutProps = {
  reports: ReportTableType[];
  selectedReportsIds: Record<string, string>;
  processedReportsIdActionMap: Record<string, string>;
  notFound: boolean;
  noReports: boolean;
  noReportsPlaceholder: ReactNode;
  searchQuery: string;
  sortingColumn: SORTING_COLUMN;
  sortingDirection: SORTING_DIRECTION;
  sortOptions: SortOptions;
  toolbar: ReactNode;
  topBarClassName?: string;
  showSharingInfo?: boolean;
  onGetReportOptions: (report: ReportTableType) => ContextMenuItem[];
  onSearchQueryChange: (e?: ChangeEvent<HTMLInputElement>) => void;
  onChangeSorting: ({
    column,
    direction,
  }: {
    column: SORTING_COLUMN;
    direction: SORTING_DIRECTION;
  }) => void;
  onReportClick: (id: string, event: MouseEvent<HTMLElement>) => void;
};

export function ReportsListLayout({
  reports,
  selectedReportsIds,
  processedReportsIdActionMap,
  notFound,
  noReports,
  noReportsPlaceholder,
  searchQuery,
  sortingColumn,
  sortingDirection,
  sortOptions,
  toolbar,
  topBarClassName,
  onGetReportOptions,
  onSearchQueryChange,
  onChangeSorting,
  onReportClick,
}: ReportsListLayoutProps) {
  return (
    <>
      <div className={cn(styles.top_bar, topBarClassName)}>
        <div className={styles.search}>
          <BiSearch size={24} className={styles.searchIcon} />
          <input
            placeholder="Search..."
            value={searchQuery}
            onChange={onSearchQueryChange}
          />
        </div>

        <div className={styles.actions}>
          {/* Sorting */}
          <div className={styles.sort}>
            <span className={styles.sortLabel}>Sort by:</span>
            <SortingDropdown
              column={sortingColumn}
              direction={sortingDirection}
              sortOptions={sortOptions}
              onChange={onChangeSorting}
            />
          </div>
        </div>
      </div>
      <div className={styles.content}>
        {/* placeholder when no apps */}
        {noReports && noReportsPlaceholder}

        {/* placeholder when not found any canvas  */}
        {notFound && !noReports && (
          <EmptyState
            variant="info"
            size="large"
            containerClassName={styles.placeholder}
            title="No Reports found..."
            description="Your search does not match the name of any of your reports"
          />
        )}

        {!notFound && !noReports && (
          <>
            <ReportsTable
              items={reports}
              onGetReportOptions={onGetReportOptions}
              processedItemsIds={processedReportsIdActionMap}
              selectedItemsIds={selectedReportsIds}
              sortingColumn={sortingColumn}
              sortingDirection={sortingDirection}
              onChangeSorting={onChangeSorting}
              showSharingInfo={false}
              onRowClick={onReportClick}
            />
          </>
        )}
      </div>

      {toolbar}
    </>
  );
}
