import { useState, useMemo } from "react";

import { useCanvasRequirementsState } from "store";
import LanguageVersionSelect from "components/common/LanguageSelector/components/LanguageVersionSelect";
import { SideBarSearch } from "components/SideBarSearch/SideBarSearch";
import { RequirementsTable } from "components/common/RequirementsTable/RequirementsTable";
import { DropdownMenuButton } from "components/common/DropdownMenuButton/DropdownMenuButton";
import { type ContextMenuItem } from "components/common/ContextMenu/ContextMenu";
import PythonIcon from "assets/icons/block-type/python.svg?react";
import RIcon from "assets/icons/block-type/r.svg?react";
import styles from "./EnvRequirementsPanel.module.scss";

const languages = [
  { id: "1", label: "Python", icon: PythonIcon },
  { id: "2", label: "R", icon: RIcon },
];
const versions = ["2.3.3", "1.8.1", "0.2.0", "4.5.0"];

const EnvRequirementsPanel = () => {
  const [searchText, setSearchText] = useState<string>("");
  const { requirements, setRequirements } = useCanvasRequirementsState(
    (slice) => ({
      requirements: slice.requirements,
      setRequirements: slice.setRequirements,
    })
  );

  const onSearch = (e) => {
    setSearchText(e.target.value);
  };

  const [selectedLanguage, setSelectedLanguage] = useState(languages[0].label);

  const languageMenuOptions = useMemo(() => {
    const languagesList: ContextMenuItem[] = [];

    languages.forEach((language) => {
      const Labelicon = language.icon;
      languagesList.push({
        key: language.id,
        type: "option",
        label: (
          <div className={styles["language-menu-item"]}>
            <Labelicon className={styles["language-menu-item__icon"]} />
            <span>{language.label}</span>
          </div>
        ),
        onClick: () => {
          setSelectedLanguage(language.label);
        },
      });
    });

    return languagesList;
  }, [setSelectedLanguage]);

  return (
    <div className={styles["panel"]}>
      <div className={styles["panel__language"]}>
        <DropdownMenuButton
          buttonVariant="text"
          menuOptions={languageMenuOptions}
        >
          {selectedLanguage}
        </DropdownMenuButton>
        <LanguageVersionSelect versions={versions} />
      </div>

      <SideBarSearch value={searchText} onChange={onSearch} />

      <RequirementsTable
        requirements={requirements || []}
        setRequirements={setRequirements}
        searchText={searchText}
      />
    </div>
  );
};

export default EnvRequirementsPanel;
