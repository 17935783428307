import styles from "./BlockContainer.module.scss";
import { BLOCK_STATUS } from "config/canvasConfig";

export const CONTAINER_STATUS_CLASS = Object.freeze({
  [BLOCK_STATUS.PENDING]: styles.container__pending,
  [BLOCK_STATUS.RUNNING]: styles.container__running,
  [BLOCK_STATUS.COMPLETED]: styles.container__completed,
  [BLOCK_STATUS.ERROR]: styles.container__error,
  [BLOCK_STATUS.STOPPING]: styles.container__stopping,
});
