import styles from "./DeleteGroupModal.module.scss";
import { useState } from "react";
import { Button } from "components/common/Button/Button";
import TextField from "components/common/TextField/TextField";
import Typography from "components/Typography/Typography";

interface DeleteGroupModalProps {
  onCancelDelete: () => void;
  onDeleteGroup: () => void;
}

export function DeleteGroupModal({
  onCancelDelete,
  onDeleteGroup,
}: DeleteGroupModalProps) {
  const [inputValue, setInputValue] = useState("");
  const handleOnInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const isInputError = inputValue !== "DELETE" && inputValue.length > 0;
  const handleGroupDeletion = () => {
    if (isInputError || inputValue.length === 0) {
      return;
    }
    onDeleteGroup();
  };

  return (
    <>
      <Typography variant="body2" className={styles.subHeading}>
        If you are sure you want to continue with the deletion of this group,
        please continue below.
      </Typography>
      <Typography variant="body2" className={styles.subHeading}>
        This action will delete all data associated with the group and cannot be
        undone. Please make sure you have exported any data you wish to keep
        before proceeding.
      </Typography>
      <TextField
        label="To verify, type DELETE below:"
        containerClassName={styles.deleteInput}
        onChange={handleOnInputChange}
      />
      <div className={styles.controls}>
        <Button variant="secondary" onClick={onCancelDelete}>
          Cancel
        </Button>
        <Button
          variant="crucial"
          disabled={isInputError || inputValue.length === 0}
          onClick={handleGroupDeletion}
        >
          Delete Group
        </Button>
      </div>
    </>
  );
}
