export enum UserPermission {
  "canView" = "read",
  "canEdit" = "write",
}
// FE resource types
export enum RESOURCE_TYPE {
  CANVAS = "canvas",
  ASSET_VERSION = "assetVersion",
  CONNECTION_ASSET = "connection",
}

export type UserStatus = "pending" | "rejected";

export interface User {
  email: string;
  permission: UserPermission;
  id: string;
  user_id: string;
  name?: string;
  username?: string;
  first_name?: string;
  last_name?: string;
  avatar?: string;
  status?: UserStatus | null;
  is_owner?: boolean;
  date_created?: string;
}

export interface Invitation {
  id: string;
  user_email: string;
  permission: UserPermission;
  date_created?: string;
}

export interface ResourceAccessRequestResponse {
  id: string;
  status: string;
  user_id?: string;
  resource_id?: string;
}

export type ResourceMember = User | Invitation;

export interface Resource {
  id: string;
  name: string | null;
  github_repository: string | null;
  github_repository_main_branch: string | null;
  organization_id: string | null;
  owner: string | null;
  permission: UserPermission;
  type: string | null;
  workspace_id: string | null;
}

export interface ResourceRequestAccess {
  id: string;
  resource_id: string;
  user_id: string;
  email: string;
  avatar?: string;
  username?: string;
  firstName?: string;
  lastName?: string;
}
