import { useCallback, useMemo } from "react";
import cn from "classnames";
import { useFormik } from "formik";
import isEmpty from "lodash-es/isEmpty";
import isInteger from "lodash-es/isInteger";
import * as Yup from "yup";
import { Button } from "components/common/Button/Button";
import SecuredField from "components/common/SecuredField/SecuredField";
import TextField from "components/common/TextField/TextField";
import Typography from "components/Typography/Typography";
import { type ConnectionConfigurationFieldValue } from "pages/Assets/types/connectionTypes";
import useFormActions from "../useFormActions";
import FormActionButtonProgressLoader from "../FormActionButtonProgressLoader";
import {
  CONNECTION_CONFIG_FIELD,
  CONNECTION_CONFIG_FIELD_CONFIG,
  FORMS_SHOW_TEST_BUTTON,
} from "../../../constants";
import { FORM_ERROR_MESSAGE, YUP_VALIDATION_METHODS_CHAIN } from "../constants";
import styles from "../Forms.module.scss";

const FIELD_KEYS = [
  CONNECTION_CONFIG_FIELD.host_url,
  CONNECTION_CONFIG_FIELD.port,
  CONNECTION_CONFIG_FIELD.database_name,
  CONNECTION_CONFIG_FIELD.username,
  CONNECTION_CONFIG_FIELD.password,
];

type FieldKey = (typeof FIELD_KEYS)[number];

interface ConnectionFormProps {
  id?: string;
  initialValues?: Partial<Record<FieldKey, ConnectionConfigurationFieldValue>>;
  disabled?: boolean;
}

const MySQLForm = ({
  id,
  initialValues,
  disabled = false,
}: ConnectionFormProps) => {
  const isInitialConfig = isEmpty(initialValues);

  const formInitialValues = useMemo(() => {
    if (isInitialConfig) {
      return FIELD_KEYS.reduce((res, key) => {
        res[key] = CONNECTION_CONFIG_FIELD_CONFIG[key].defaultValue;

        return res;
      }, {});
    }

    return { ...initialValues };
  }, [initialValues, isInitialConfig]);

  const validationSchema = useMemo(() => {
    if (disabled) {
      return;
    }

    return Yup.object().shape({
      [CONNECTION_CONFIG_FIELD.host_url]:
        YUP_VALIDATION_METHODS_CHAIN.stringRequiredMinMax,
      [CONNECTION_CONFIG_FIELD.port]: Yup.string()
        .required(FORM_ERROR_MESSAGE.provideRequiredInfo)
        .test(
          "typeNumber",
          FORM_ERROR_MESSAGE.shouldBeNumber,
          (value) => !isNaN(Number(value))
        )
        .test("port", FORM_ERROR_MESSAGE.shouldBeValidPort, (value) => {
          const portNumber = Number(value);

          return (
            portNumber >= 1 && portNumber <= 65535 && isInteger(portNumber)
          );
        }),
      [CONNECTION_CONFIG_FIELD.database_name]:
        YUP_VALIDATION_METHODS_CHAIN.stringRequiredMinMax,
      [CONNECTION_CONFIG_FIELD.username]:
        YUP_VALIDATION_METHODS_CHAIN.stringRequiredMinMax,
      [CONNECTION_CONFIG_FIELD.password]: Yup.string().when([], {
        is: () => isInitialConfig,
        then: () => YUP_VALIDATION_METHODS_CHAIN.stringRequiredMinMax,
        otherwise: () => Yup.string(),
      }),
    });
  }, [disabled, isInitialConfig]);

  const {
    isSubmitActionInProgress,
    isTestActionInProgress,
    submitForm,
    testForm,
  } = useFormActions();

  const onSubmit = (values) => {
    submitForm({
      formId: id,
      values,
      formFields: FIELD_KEYS,
      onSuccess: resetForm,
      onError: setErrors,
    });
  };

  const {
    values,
    touched,
    errors,
    isValid,
    dirty,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setErrors,
    resetForm,
  } = useFormik({
    initialValues: { ...formInitialValues },
    validationSchema,
    enableReinitialize: true,
    onSubmit,
  });

  const getFieldErrorMessage = (fieldName: FieldKey) => {
    if (touched[fieldName] && errors[fieldName]) {
      return errors[fieldName];
    }
  };

  const onPasswordReset = useCallback(() => {
    setFieldValue(CONNECTION_CONFIG_FIELD.password, "");
  }, [setFieldValue]);

  const onTest = useCallback(() => {
    testForm(id);
  }, [id]);

  const isActionInProgress = isSubmitActionInProgress || isTestActionInProgress;
  const isActionButtonDisabled = disabled || !isValid || isActionInProgress;

  return (
    <form
      className={cn(styles.form, {
        [styles.form_action_disabled]: isActionInProgress,
      })}
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <div className={styles.fields}>
        <div className={styles.field}>
          <Typography variant="body1" className={styles.field_label}>
            Host URL
          </Typography>
          <div className={styles.field_value}>
            <TextField
              name={CONNECTION_CONFIG_FIELD.host_url}
              placeholder="Enter URL-link..."
              value={values[CONNECTION_CONFIG_FIELD.host_url]}
              onChange={handleChange}
              onBlur={handleBlur}
              error={getFieldErrorMessage(CONNECTION_CONFIG_FIELD.host_url)}
              isDisabled={disabled}
            />
          </div>
        </div>
        <div className={styles.field}>
          <Typography variant="body1" className={styles.field_label}>
            Port
          </Typography>
          <div className={styles.field_value}>
            <TextField
              name={CONNECTION_CONFIG_FIELD.port}
              placeholder="Port..."
              value={values[CONNECTION_CONFIG_FIELD.port]}
              onChange={handleChange}
              onBlur={handleBlur}
              error={getFieldErrorMessage(CONNECTION_CONFIG_FIELD.port)}
              isDisabled={disabled}
            />
          </div>
        </div>
        <div className={styles.field}>
          <Typography variant="body1" className={styles.field_label}>
            Database Name
          </Typography>
          <div className={styles.field_value}>
            <TextField
              name={CONNECTION_CONFIG_FIELD.database_name}
              placeholder="Database name..."
              value={values[CONNECTION_CONFIG_FIELD.database_name]}
              onChange={handleChange}
              onBlur={handleBlur}
              error={getFieldErrorMessage(
                CONNECTION_CONFIG_FIELD.database_name
              )}
              isDisabled={disabled}
            />
          </div>
        </div>
        <div className={styles.field}>
          <Typography variant="body1" className={styles.field_label}>
            Username
          </Typography>
          <div className={styles.field_value}>
            <TextField
              name={CONNECTION_CONFIG_FIELD.username}
              placeholder="Enter username..."
              value={values[CONNECTION_CONFIG_FIELD.username]}
              onChange={handleChange}
              onBlur={handleBlur}
              error={getFieldErrorMessage(CONNECTION_CONFIG_FIELD.username)}
              isDisabled={disabled}
            />
          </div>
        </div>
        <div className={styles.field}>
          <Typography variant="body1" className={styles.field_label}>
            Password
          </Typography>
          <div className={styles.field_value}>
            <SecuredField
              name={CONNECTION_CONFIG_FIELD.password}
              value={values[CONNECTION_CONFIG_FIELD.password]}
              onChange={handleChange}
              onBlur={handleBlur}
              onReset={!isInitialConfig ? onPasswordReset : undefined}
              error={getFieldErrorMessage(CONNECTION_CONFIG_FIELD.password)}
              showDummyTextIfEmptyValue={!isInitialConfig}
              isDisabled={disabled}
            />
          </div>
        </div>
      </div>
      <div className={styles.controls}>
        <Button
          className={cn(styles.button, styles.submit_button)}
          type="submit"
          disabled={isActionButtonDisabled || !dirty}
        >
          {isSubmitActionInProgress ? (
            <FormActionButtonProgressLoader />
          ) : (
            "Save"
          )}
        </Button>
        {!isInitialConfig && FORMS_SHOW_TEST_BUTTON && (
          <Button
            className={cn(styles.button, styles.test_button)}
            disabled={isActionButtonDisabled || dirty}
            onClick={onTest}
          >
            {isTestActionInProgress ? (
              <FormActionButtonProgressLoader />
            ) : (
              "Test"
            )}
          </Button>
        )}
      </div>
    </form>
  );
};

export default MySQLForm;
