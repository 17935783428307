import styles from "./WorkspaceCard.module.scss";
import Typography from "components/Typography/Typography";
import IconButton from "components/common/IconButton/IconButton";
import { BiShapeSquare, BiLibrary } from "react-icons/bi";
import { BsPeople } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import type { Workspace } from "models/organization";

type WorkspaceCardProps = {
  orgID: string;
  workspace: Workspace;
};

/**
 * TODO: the backend does not yet sto be implemented with real data for
 * the following fields:
 * - canvases
 * - assets
 * - members
 * When the backend is ready, the component will be updated to use real data
 * */
export function WorkspaceCard({ orgID, workspace }: WorkspaceCardProps) {
  const { id, name } = workspace;

  // TODO: use real data
  const canvases = 0;
  const members = 1;
  const assets = 0;

  const navigate = useNavigate();

  const handleTitleClick = () => {
    navigate(`/org/${orgID}/workspaces/${id}`);
  };

  const handleSectionClick = (section: string) => {
    navigate(`/org/${orgID}/workspaces/${id}/${section}`);
  };

  return (
    <div className={styles.card}>
      <div onClick={handleTitleClick}>
        <Typography className={styles.title} variant="h3">
          {name}
        </Typography>
      </div>
      <div className={styles.stats}>
        <div className={styles.stat}>
          <IconButton
            tooltip="Canvases"
            onClick={() => {
              handleSectionClick("canvases");
            }}
          >
            <BiShapeSquare className={styles.stat__icon} />
          </IconButton>
          <Typography variant="caption2">{canvases}</Typography>
        </div>
        <div className={styles.stat}>
          <IconButton
            onClick={() => {
              handleSectionClick("assets");
            }}
            tooltip="Assets"
          >
            <BiLibrary className={styles.stat__icon} />
          </IconButton>
          <Typography variant="caption2">{assets}</Typography>
        </div>
        <div className={styles.stat}>
          <IconButton
            onClick={() => {
              handleSectionClick("members");
            }}
            tooltip="Members"
          >
            <BsPeople className={styles.stat__icon} />
          </IconButton>
          <Typography variant="caption2">{members}</Typography>
        </div>
      </div>
    </div>
  );
}
