import styles from "./EnvImportsPanel.module.scss";
import { useState } from "react";
import Typography from "components/Typography";
import Fill from "components/common/Fill/Fill";
import CodeEditor from "components/common/CodeEditor/CodeEditor";
import Tabs from "components/common/Tabs/Tabs";
import Python from "assets/icons/block-type/python.svg?react";
import R from "assets/icons/block-type/r.svg?react";

const EnvImportsPanel = () => {
  const [language, setLanguage] = useState<"python" | "r">("python");
  const languageTabs = [
    {
      id: 1,
      label: "Python",
      Icon: Python,
      onClick: () => {
        setLanguage("python");
      },
      active: language === "python",
    },
    {
      id: 2,
      label: "R",
      Icon: R,
      onClick: () => {
        setLanguage("r");
      },
      active: language === "r",
    },
  ];

  const mockText = `import numpy
import pandas
import sklearn
   `;

  return (
    <Fill>
      <div className={styles["panel__header"]}>
        <Typography
          component="div"
          variant="caption1"
          className={styles["panel__title"]}
        >
          Imports
        </Typography>
        <Tabs tabs={languageTabs} className={styles["panel__language"]} />
      </div>

      <CodeEditor
        value={mockText}
        language={language}
        readOnly
        lineNumbers={false}
        folding={false}
        renderLineHighlight={false}
      />
    </Fill>
  );
};

export default EnvImportsPanel;
