import { type StateCreator as ZustandStateCreator } from "zustand";
import { createWithEqualityFn } from "zustand/traditional";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { shallow } from "zustand/shallow";
import { enableMapSet } from "immer";
import { createUserSlice, type UserSlice } from "./slices/user-slice";
import { createModelSlice, type ModelSlice } from "./slices/model-slice";
import {
  createWebsocketSlice,
  type WebsocketSlice,
} from "./slices/websocket-slice";
import { createToastsSlice, type ToastsSlice } from "./slices/toasts-slice";
import {
  createUserSettingsSlice,
  type UserSettingsSlice,
} from "./slices/user-settings-slice";
import {
  createUserBillingSlice,
  type UserBillingSlice,
} from "./slices/user-billing-slice";
import { createAssetsSlice, type AssetsSlice } from "./slices/assets-slice";
import { createCanvasSlice, type CanvasSlice } from "./slices/canvas-slice";
import {
  createCanvasBlocksSlice,
  type CanvasBlocksSlice,
} from "./slices/canvas-blocks-slice";
import {
  createCanvasFilesSlice,
  type CanvasFilesSlice,
} from "./slices/canvas-files-slice";
import {
  createCanvasRequirementsSlice,
  type CanvasRequirementsSlice,
} from "./slices/canvas-requirements-slice";
import {
  createCanvasDeploymentsSlice,
  type CanvasDeploymentsSlice,
} from "./slices/canvas-deployments-slice";
import {
  createCanvasLayoutSlice,
  type CanvasLayoutSlice,
} from "./slices/canvas-layout-slice";
import {
  createCanvasUsersSlice,
  type CanvasUsersSlice,
} from "./slices/canvas-users-slice";
import {
  createCanvasGitHubSlice,
  type CanvasGitHubSlice,
} from "./slices/canvas-github-slice";
import {
  createOrganizationsSlice,
  type OrganizationSlice,
} from "./slices/organizations-slice";
import {
  createLocalCanvasPreferencesSlice,
  type LocalCanvasPreferencesSlice,
} from "./slices/local-canvas-preferences";
import {
  createLocalAppPreferencesSlice,
  type LocalAppPreferencesSlice,
} from "./slices/local-app-preferences";
import {
  createLocalReportPreferencesSlice,
  type LocalReportPreferencesSlice,
} from "./slices/local-report-preferences";
import {
  createNotificationSlice,
  type NotificationSlice,
} from "./slices/notification-slice";
import {
  createCommentsSlice,
  type CommentsSlice,
} from "./slices/comments-slice";

export type AppStore = {
  canvasSlice: CanvasSlice;
  canvasBlocksSlice: CanvasBlocksSlice;
  canvasFilesSlice: CanvasFilesSlice;
  canvasRequirementsSlice: CanvasRequirementsSlice;
  canvasDeploymentsSlice: CanvasDeploymentsSlice;
  canvasLayoutSlice: CanvasLayoutSlice;
  canvasUsersSlice: CanvasUsersSlice;
  canvasGitHubSlice: CanvasGitHubSlice;
  assetsSlice: AssetsSlice;
  userSlice: UserSlice;
  modelSlice: ModelSlice;
  userSettingsSlice: UserSettingsSlice;
  userBillingSlice: UserBillingSlice;
  websocketSlice: WebsocketSlice;
  toastsSlice: ToastsSlice;
  organizationsSlice: OrganizationSlice;
  localCanvasPreferencesSlice: LocalCanvasPreferencesSlice;
  localAppPreferencesSlice: LocalAppPreferencesSlice;
  localReportPreferencesSlice: LocalReportPreferencesSlice;
  notificationSlice: NotificationSlice;
  commentsSlice: CommentsSlice;
};

// enable Immer to operate on the native Map and Set collections
enableMapSet();

// for slices annotation
export type StateCreator<T> = ZustandStateCreator<
  AppStore,
  [["zustand/immer", never], ["zustand/devtools", never]],
  [],
  T
>;

export const useStore = createWithEqualityFn<AppStore>()(
  immer(
    devtools(
      (...args) => {
        return {
          canvasSlice: createCanvasSlice(...args),
          canvasBlocksSlice: createCanvasBlocksSlice(...args),
          canvasFilesSlice: createCanvasFilesSlice(...args),
          canvasRequirementsSlice: createCanvasRequirementsSlice(...args),
          canvasDeploymentsSlice: createCanvasDeploymentsSlice(...args),
          canvasLayoutSlice: createCanvasLayoutSlice(...args),
          canvasUsersSlice: createCanvasUsersSlice(...args),
          canvasGitHubSlice: createCanvasGitHubSlice(...args),
          assetsSlice: createAssetsSlice(...args),
          userSlice: createUserSlice(...args),
          modelSlice: createModelSlice(...args),
          userSettingsSlice: createUserSettingsSlice(...args),
          userBillingSlice: createUserBillingSlice(...args),
          websocketSlice: createWebsocketSlice(...args),
          toastsSlice: createToastsSlice(...args),
          organizationsSlice: createOrganizationsSlice(...args),
          localCanvasPreferencesSlice: createLocalCanvasPreferencesSlice(
            ...args
          ),
          localAppPreferencesSlice: createLocalAppPreferencesSlice(...args),
          localReportPreferencesSlice: createLocalReportPreferencesSlice(
            ...args
          ),
          notificationSlice: createNotificationSlice(...args),
          commentsSlice: createCommentsSlice(...args),
        };
      },
      { name: "Zerve Store" }
    )
  )
);

export function useCanvasState<U>(
  selector: (slice: CanvasSlice) => U,
  equals: (a: U, b: U) => boolean = shallow
) {
  return useStore((store) => selector(store.canvasSlice), equals);
}

export function useCanvasBlocksState<U>(
  selector: (slice: CanvasBlocksSlice) => U,
  equals: (a: U, b: U) => boolean = shallow
) {
  return useStore((store) => selector(store.canvasBlocksSlice), equals);
}

export function useCanvasFilesState<U>(
  selector: (slice: CanvasFilesSlice) => U,
  equals: (a: U, b: U) => boolean = shallow
) {
  return useStore((store) => selector(store.canvasFilesSlice), equals);
}

export function useCanvasRequirementsState<U>(
  selector: (slice: CanvasRequirementsSlice) => U,
  equals: (a: U, b: U) => boolean = shallow
) {
  return useStore((store) => selector(store.canvasRequirementsSlice), equals);
}

export function useCanvasDeploymentState<U>(
  selector: (slice: CanvasDeploymentsSlice) => U,
  equals: (a: U, b: U) => boolean = shallow
) {
  return useStore((store) => selector(store.canvasDeploymentsSlice), equals);
}

export function useCanvasLayoutState<U>(
  selector: (slice: CanvasLayoutSlice) => U,
  equals: (a: U, b: U) => boolean = shallow
) {
  return useStore((store) => selector(store.canvasLayoutSlice), equals);
}

export function useCanvasUsersState<U>(
  selector: (slice: CanvasUsersSlice) => U,
  equals: (a: U, b: U) => boolean = shallow
) {
  return useStore((store) => selector(store.canvasUsersSlice), equals);
}

export function useCanvasGitHubState<U>(
  selector: (slice: CanvasGitHubSlice) => U,
  equals: (a: U, b: U) => boolean = shallow
) {
  return useStore((store) => selector(store.canvasGitHubSlice), equals);
}

export function useAssetsState<U>(
  selector: (slice: AssetsSlice) => U,
  equals: (a: U, b: U) => boolean = shallow
) {
  return useStore((store) => selector(store.assetsSlice), equals);
}

export function useUserState<U>(
  selector: (slice: UserSlice) => U,
  equals: (a: U, b: U) => boolean = shallow
) {
  return useStore((store) => selector(store.userSlice), equals);
}

export function useModelState<U>(
  selector: (slice: ModelSlice) => U,
  equals: (a: U, b: U) => boolean = shallow
) {
  return useStore((store) => selector(store.modelSlice), equals);
}

export function useUserSettingsState<U>(
  selector: (slice: UserSettingsSlice) => U,
  equals: (a: U, b: U) => boolean = shallow
) {
  return useStore((store) => selector(store.userSettingsSlice), equals);
}

export function useUserBillingState<U>(
  selector: (slice: UserBillingSlice) => U,
  equals: (a: U, b: U) => boolean = shallow
) {
  return useStore((store) => selector(store.userBillingSlice), equals);
}

export function useWebsocketState<U>(
  selector: (slice: WebsocketSlice) => U,
  equals: (a: U, b: U) => boolean = shallow
) {
  return useStore((store) => selector(store.websocketSlice), equals);
}

export function useToastsState<U>(
  selector: (slice: ToastsSlice) => U,
  equals: (a: U, b: U) => boolean = shallow
) {
  return useStore((store) => selector(store.toastsSlice), equals);
}

export function useOrganizationsState<U>(
  selector: (slice: OrganizationSlice) => U,
  equals: (a: U, b: U) => boolean = shallow
) {
  return useStore((store) => selector(store.organizationsSlice), equals);
}

export function useLocalCanvasPreferencesState<U>(
  selector: (slice: LocalCanvasPreferencesSlice) => U,
  equals: (a: U, b: U) => boolean = shallow
) {
  return useStore(
    (store) => selector(store.localCanvasPreferencesSlice),
    equals
  );
}

export function useLocalAppPreferencesState<U>(
  selector: (slice: LocalAppPreferencesSlice) => U,
  equals: (a: U, b: U) => boolean = shallow
) {
  return useStore((store) => selector(store.localAppPreferencesSlice), equals);
}

export function useLocalReportPreferencesState<U>(
  selector: (slice: LocalReportPreferencesSlice) => U,
  equals: (a: U, b: U) => boolean = shallow
) {
  return useStore(
    (store) => selector(store.localReportPreferencesSlice),
    equals
  );
}

export function useNotificationState<U>(
  selector: (slice: NotificationSlice) => U,
  equals: (a: U, b: U) => boolean = shallow
) {
  return useStore((store) => selector(store.notificationSlice), equals);
}

export function useCommentsState<U>(
  selector: (slice: CommentsSlice) => U,
  equals: (a: U, b: U) => boolean = shallow
) {
  return useStore((store) => selector(store.commentsSlice), equals);
}
