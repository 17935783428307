import styles from "./UpdateCanvasModal.module.scss";
import { useState, useEffect, useCallback } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import canvasService from "api/http/canvas-service";
import { type PropsWithModal } from "hooks/useModal";
import TextField from "components/common/TextField/TextField";
import { Button } from "components/common/Button/Button";
import Loader from "components/common/Loader/Loader";
import { DATA_LOAD_STATUS } from "config/appConfig";
import { useToastsState } from "store";
import ToasterMessage from "components/common/ToasterMessage/ToasterMessage";
import { Skeleton } from "components/common/Skeleton/Skeleton";
import Typography from "components/Typography/Typography";

type UpdateCanvasModalProps = PropsWithModal<{
  canvasId: string;
  onCanvasUpdated: (data: { name: string; description: string }) => void;
}>;

export function UpdateCanvasModal({
  canvasId,
  onCanvasUpdated,
  onModalClose,
}: UpdateCanvasModalProps) {
  const addToast = useToastsState((slice) => slice.addToast);

  const [isCanvasUpdatePending, setIsCanvasUpdatePending] = useState(false);
  const [canvasLoadStatus, setCanvasLoadStatus] = useState<DATA_LOAD_STATUS>(
    DATA_LOAD_STATUS.NOT_LOADED
  );
  const [errorMessageCanvas, setErrorMessageCanvas] = useState<string | null>(
    null
  );
  const isLoadingData = canvasLoadStatus === DATA_LOAD_STATUS.LOADING;

  const handleGetCanvasDetails = useCallback(async () => {
    setCanvasLoadStatus(DATA_LOAD_STATUS.LOADING);
    try {
      const data = await canvasService.getCanvas(canvasId);
      setCanvasLoadStatus(DATA_LOAD_STATUS.LOADED);
      formik.setValues({
        canvasName: data.name,
        canvasDescription: data.description,
      });
      return data;
    } catch (error) {
      setCanvasLoadStatus(DATA_LOAD_STATUS.ERROR);
      setErrorMessageCanvas(
        "Failed to load canvas details. Please try again later."
      );
    }
  }, []);

  useEffect(() => {
    handleGetCanvasDetails();
  }, [handleGetCanvasDetails]);

  const handleSubmit = (values: {
    canvasName: string;
    canvasDescription: string;
  }) => {
    setIsCanvasUpdatePending(true);
    canvasService;
    canvasService
      .patchCanvas({
        canvasId: canvasId,
        payload: {
          name: values.canvasName,
          description: values.canvasDescription,
        },
      })
      .then((data) => {
        onCanvasUpdated(data);
        addToast({
          variant: "success",
          message: "Canvas details updated successfully",
        });
        onModalClose();
      })
      .catch((error) => {
        if (error.cause) {
          formik.setErrors(error.cause);
        }
        setIsCanvasUpdatePending(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      canvasName: "",
      canvasDescription: "",
    },
    validationSchema: Yup.object({
      canvasName: Yup.string().required("Required"),
    }),
    onSubmit: handleSubmit,
  });

  return (
    <div>
      {errorMessageCanvas ? (
        <ToasterMessage fullWidth variant="error" animate={false}>
          {errorMessageCanvas}
        </ToasterMessage>
      ) : (
        <form className={styles.form} onSubmit={formik.handleSubmit}>
          <section className={styles.formGroup}>
            <Typography
              component="label"
              variant="h3"
              otherProps={{ htmlFor: "canvasName" }}
            >
              Name
            </Typography>
            {isLoadingData ? (
              <Skeleton height={42} />
            ) : (
              <TextField
                id="canvasName"
                placeholder="Canvas name"
                autoFocus
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.canvasName}
                error={
                  formik.touched.canvasName ? formik.errors.canvasName : ""
                }
                isDisabled={isLoadingData}
              />
            )}
          </section>

          <section className={styles.formGroup}>
            <Typography
              component="label"
              variant="h3"
              otherProps={{ htmlFor: "canvasDescription" }}
            >
              Description
            </Typography>
            {isLoadingData ? (
              <Skeleton height={60} />
            ) : (
              <TextField
                id="canvasDescription"
                type="textarea"
                placeholder="Canvas description (Optional)"
                inputClassName={styles.textarea}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.canvasDescription || ""}
                isDisabled={isLoadingData}
                error={
                  formik.touched.canvasDescription
                    ? formik.errors.canvasDescription
                    : ""
                }
              />
            )}
          </section>

          <div className={styles.controls}>
            <Button size="large" variant="secondary" onClick={onModalClose}>
              Cancel
            </Button>

            <Button
              type="submit"
              size="large"
              disabled={
                !formik.isValid || !formik.dirty || isCanvasUpdatePending
              }
            >
              {isCanvasUpdatePending ? <Loader /> : <span>Update</span>}
            </Button>
          </div>
        </form>
      )}
    </div>
  );
}
