import styles from "./OrganizationSettings.module.scss";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams, useNavigate } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useUserState, useOrganizationsState, useToastsState } from "store";
import { useModal } from "hooks/useModal";
import { useOrganizationsActions } from "hooks/useOrganizationsActions";
import { Button } from "components/common/Button/Button";
import Spinner from "components/common/Spinner/Spinner";
import TextField from "components/common/TextField/TextField";
import Typography from "components/Typography/Typography";
import { InstallGitApp } from "components/InstallGitApp";
import { TitleSection } from "components/organization/TitleSection/TitleSection";
import {
  getOrganization,
  patchOrganization,
  deleteOrganization,
} from "api/http/organization-service";
import { ORGANIZATION_NAME_YUP_VALIDATION_BASE } from "config/organizationsConfig";
import { DeleteOrganizationModal } from "./DeleteOrganizationModal";
import type { Organization } from "models/organization";
import { DATA_LOAD_STATUS, AWS_REGION } from "config/appConfig";
import { debouncePromise } from "utils/helpers";
import FormDropdown from "components/common/FormDropdown/FormDropdown";

export function OrganizationSettings() {
  const flags = useFlags();
  const userID = useUserState((slice) => slice.userID);
  const addToast = useToastsState((slice) => slice.addToast);
  const { orgID = "" } = useParams();
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();
  const [organization, setOrganization] = useState<Organization>();
  const [isFetchingOrganization, setIsFetchingOrganization] = useState(true);
  const { organizations, organizationsLoadStatus } = useOrganizationsState(
    (slice) => ({
      organizations: slice.organizations,
      organizationsLoadStatus: slice.organizationsLoadStatus,
    })
  );
  const deleteOrganizationFromStore = useOrganizationsState(
    (slice) => slice.deleteOrganization
  );
  const updateOrganizationInStore = useOrganizationsState(
    (slice) => slice.updateOrganization
  );
  const { fetchOrganizations } = useOrganizationsActions();

  const RegionOptions = [
    { id: AWS_REGION.US_EAST_1, label: AWS_REGION.US_EAST_1 },
    { id: AWS_REGION.US_WEST_2, label: AWS_REGION.US_WEST_2 },
    { id: AWS_REGION.EU_CENTRAL_1, label: AWS_REGION.EU_CENTRAL_1 },
    { id: AWS_REGION.EU_EAST_1, label: AWS_REGION.EU_EAST_1 },
  ];

  // Fetch users organization when the component loads.
  useEffect(() => {
    async function initializeOrganization() {
      setIsFetchingOrganization(true);
      try {
        const organizationsResult = await debouncePromise({
          promise: getOrganization(orgID),
        });
        if (organizationsResult) {
          // store organization
          setOrganization(organizationsResult);
          formik.resetForm({
            values: {
              name: organizationsResult.name,
              genai_aws_region: organizationsResult.genai_aws_region,
            },
          });
          setIsFetchingOrganization(false);
          fetchOrganizations();
        }
        // TODO: add more intelligent error handling to display UI feedback (Retry, etc) on error
      } catch (err) {
        setIsFetchingOrganization(false);
        addToast({
          variant: "error",
          message: "Failed to fetch organization data",
        });
      }
    }
    if (userID && orgID) {
      initializeOrganization();
    }
  }, [userID, orgID]);

  // Delete the organization.
  const handleDeleteOrganization = () => {
    if (userID) {
      deleteOrganization(orgID)
        .then(() => {
          closeModal();
          formik.resetForm({
            values: {
              name: "",
              genai_aws_region: "",
            },
          });
          addToast({
            message: "Organization successfully deleted",
            variant: "success",
          });
          deleteOrganizationFromStore(orgID);
          navigate("/organization/create");
        })
        .catch((error) => {
          if (error.cause) {
            formik.setErrors(error.cause);
          }
          addToast({
            message: error.message,
            variant: "error",
          });
        });
    }
  };

  const onSubmit = (values) => {
    if (!userID) {
      return;
    }

    patchOrganization(orgID, {
      name: values.name,
      genai_aws_region: values.genai_aws_region,
    })
      .then((returnedValues) => {
        addToast({
          message: "Organization successfully updated",
          variant: "success",
        });
        const updatedValues = {
          name: returnedValues.name,
          genai_aws_region: returnedValues.genai_aws_region,
        };
        formik.resetForm({
          values: { ...updatedValues },
        });
        updateOrganizationInStore(returnedValues.id, {
          ...updatedValues,
        });
        setOrganization((prevData) => {
          if (!prevData) {
            return;
          }

          return {
            ...prevData,
            ...updatedValues,
          };
        });
      })
      .catch((error) => {
        if (error.cause) {
          formik.setErrors(error.cause);
        }
        addToast({
          message: error.message,
          variant: "error",
        });
      });
  };

  const onDelete = () => {
    openModal({
      title: "Delete Organization",
      content: () => (
        <DeleteOrganizationModal
          onCancelDelete={closeModal}
          onDeleteOrganization={handleDeleteOrganization}
        />
      ),
    });
  };

  // Yup validation schema
  const validationSchema = Yup.object().shape({
    name: ORGANIZATION_NAME_YUP_VALIDATION_BASE.test(
      "unique-name",
      "Organization name must be unique",
      function (value) {
        if (!value) {
          return true;
        }

        // Convert the input value to lowercase for case-insensitive comparison
        const lowercaseValue = value.toLowerCase();

        // Check if the name already exists in the list of existing organizations
        const nameExists = organizations?.some(
          (existingOrganization) =>
            existingOrganization.name.toLowerCase() === lowercaseValue &&
            existingOrganization.id !== orgID
        );

        // Return false if the name already exists, true otherwise
        return !nameExists;
      }
    ),
  });

  // Initialize formik.
  const formik = useFormik({
    initialValues: {
      name: organization?.name || "",
      genai_aws_region: organization?.genai_aws_region || "",
    },
    validationSchema,
    onSubmit,
  });

  const isGitAppInstalled = Boolean(organization?.github_app_installation_id);

  const isFetchingData =
    isFetchingOrganization ||
    organizationsLoadStatus === DATA_LOAD_STATUS.LOADING;

  const actionsDisabled = !organization;
  // TODO: add data fetching error(s) feedback
  return (
    <div className={styles.container}>
      {isFetchingData ? (
        <Spinner className={styles.spinner} />
      ) : (
        <div className={styles.content}>
          <div className={styles.settingsWrapper}>
            <TitleSection title="Organization Settings" />

            <form onSubmit={formik.handleSubmit}>
              <div className={styles.form}>
                <TextField
                  id="name"
                  name="name"
                  label="Name"
                  placeholder={"Type organization name..."}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  error={formik.errors.name}
                  isDisabled={actionsDisabled}
                />

                <FormDropdown
                  id="region"
                  name="region"
                  label="Bedrock AWS Region"
                  placeholder="Select Region"
                  options={RegionOptions}
                  value={formik.values.genai_aws_region}
                  error={formik.errors.genai_aws_region}
                  onChange={(e) => formik.setFieldValue("genai_aws_region", e)}
                />
              </div>
              <div className={styles.buttonSection}>
                <Button
                  type="submit"
                  className={styles.updateOrganizationButton}
                  disabled={!formik.isValid || !formik.dirty || actionsDisabled}
                >
                  Save Organization
                </Button>
              </div>
            </form>
            {flags.enableGitIntegration && (
              <div className={styles.gitHubSection}>
                <div className={styles.gitHubSectionBlock}>
                  <Typography variant="h2">Github Integration</Typography>
                  <Typography
                    variant="body2"
                    className={styles.sectionSubTitle}
                  >
                    Manage github integraton settings.
                  </Typography>
                  <div className={styles.gitHubSectionBlockContent}>
                    <InstallGitApp
                      showDescription={!isFetchingData && !isGitAppInstalled}
                      organizationId={organization?.id}
                      buttonLabel={
                        isGitAppInstalled
                          ? "Configure Zerve Github App"
                          : "Install Zerve Github App"
                      }
                      disabled={actionsDisabled}
                    />
                  </div>
                </div>
              </div>
            )}
            {orgID && (
              <div className={styles.deleteWrapper}>
                <div className={styles.deleteExplanationText}>
                  <Typography variant="h2">Delete Organization</Typography>
                  <Typography
                    variant="body2"
                    className={styles.sectionSubTitle}
                  >
                    Once you delete your account, you will permanently delete
                    this organization and all of it&#39;s data. There is no
                    going back. Please be certain.
                  </Typography>
                </div>
                <Button
                  variant="crucial"
                  disabled={actionsDisabled}
                  onClick={onDelete}
                >
                  Delete Organization
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
