import styles from "./PasswordField.module.scss";
import { forwardRef, useState, useMemo } from "react";
import { BiShow, BiHide } from "react-icons/bi";
import cn from "classnames";
import Tooltip from "components/common/Tooltip/Tooltip";
import TextField, {
  type TextFieldProps,
} from "components/common/TextField/TextField";

type PasswordFieldProps = Omit<TextFieldProps, "type" | "innerControls">;

const PasswordField = forwardRef<HTMLInputElement, PasswordFieldProps>(
  ({ isDisabled, inputClassName, ...rest }, ref) => {
    const [isPasswordShown, setIsPasswordShown] = useState(false);

    const type = useMemo(() => {
      return isPasswordShown ? "text" : "password";
    }, [isPasswordShown]);

    return (
      <TextField
        {...rest}
        ref={ref}
        type={type}
        isDisabled={isDisabled}
        inputClassName={cn(styles.withRightIcon, inputClassName)}
        innerControls={
          <Tooltip
            withArrow
            placement="top"
            className={styles.controlTooltip}
            text={isPasswordShown ? "Hide value" : "Show value"}
          >
            <button
              type="button"
              disabled={isDisabled}
              className={styles.control}
              onClick={() => {
                setIsPasswordShown((prev) => !prev);
              }}
            >
              {isPasswordShown ? <BiHide size={20} /> : <BiShow size={20} />}
            </button>
          </Tooltip>
        }
      />
    );
  }
);

PasswordField.displayName = "PasswordField";

export default PasswordField;
