import styles from "./Body.module.scss";
import { type PropsWithChildren } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useSidebarAccordionItemContext } from "components/SidebarAccordion/Item/ItemContext";

type BodyProps = PropsWithChildren;

export function Body({ children }: BodyProps) {
  const { isActive } = useSidebarAccordionItemContext();

  return (
    <AnimatePresence initial={false}>
      {isActive ? (
        <motion.section
          className={styles.container}
          initial="collapsed"
          animate="open"
          exit="collapsed"
          variants={{
            open: {
              opacity: 1,
              height: "100%",
            },
            collapsed: {
              opacity: 0,
              height: "0%",
            },
          }}
        >
          {children}
        </motion.section>
      ) : null}
    </AnimatePresence>
  );
}
