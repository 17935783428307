import styles from "./OrgEmptyState.module.scss";
import { type PropsWithChildren, type ReactNode } from "react";
import { EmptyState } from "components/common/EmptyState/EmptyState";

type EmptyStateProps = PropsWithChildren<{
  title: string;
  description: ReactNode;
}>;

export function OrgEmptyState({
  children,
  title,
  description,
}: EmptyStateProps) {
  return (
    <EmptyState
      variant="info"
      size="large"
      title={title}
      description={description}
      containerClassName={styles.container}
    >
      {children}
    </EmptyState>
  );
}
