// component that is renamable on double click with a hotkey that shows enter and esc buttons
import {
  useState,
  useCallback,
  useEffect,
  useRef,
  type ChangeEvent,
  type KeyboardEvent,
  type ReactNode,
  type CSSProperties,
} from "react";
import cn from "classnames";
import { mergeRefs } from "react-merge-refs";
import {
  autoUpdate,
  flip,
  offset,
  shift,
  useFloating,
  FloatingPortal,
  type Placement,
} from "@floating-ui/react";
import { BiError } from "react-icons/bi";
import Typography from "components/Typography/Typography";
import styles from "./RenameInput.module.scss";

const CONFIG = Object.freeze({
  floatingElPlacement: "right-start",
  floatingElOffset: 8,
});

type FloatingOffsetOptions =
  | number
  | {
      mainAxis?: number;
      crossAxis?: number;
      alignmentAxis?: number | null;
    };

type RenameInputProps = {
  defaultName: string;
  onRenameCancel?: () => void;
  onRenameSubmit?: (name: string) => void;
  className?: string;
  children?: ReactNode;
  getError?: (text: string) => string | null;
  style?: CSSProperties;
  onInput?: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  floatingElPlacement?: Placement;
  floatingElOffset?: FloatingOffsetOptions;
};

const RenameInput = ({
  defaultName,
  onRenameCancel,
  onRenameSubmit,
  className,
  getError,
  style,
  placeholder,
  floatingElPlacement = CONFIG.floatingElPlacement,
  floatingElOffset = CONFIG.floatingElOffset,
  onInput,
}: RenameInputProps) => {
  const [name, setName] = useState(defaultName);
  const [error, setError] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { x, y, refs, strategy } = useFloating({
    open: Boolean(error),
    whileElementsMounted: autoUpdate,
    placement: floatingElPlacement,
    middleware: [offset(floatingElOffset), flip(), shift()],
  });

  useEffect(() => {
    // close on click outside if error
    const handleClickOutside = (e) => {
      if (error && inputRef.current && !inputRef.current.contains(e.target)) {
        handleRenameCancel();
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [error]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.select();
      /* input auto focus, which prevents the window from scrolling on focus.
      Do note use input element autoFocus prop as it causes window scrolling when focusing in partially hidden input element.
      https://linear.app/zerve-ai/issue/FRO-1170/when-you-double-click-the-title-of-a-block-to-change-it-it-causes-the */
      inputRef.current.focus({ preventScroll: true });
    }
  }, []);

  const validate = useCallback(
    (text: string) => {
      const err = getError?.(text) || null;

      err !== error && setError(err);

      return err;
    },
    [error]
  );

  const handleRenameCancel = () => {
    onRenameCancel?.();
  };

  const handleRenameSubmit = () => {
    if (name !== defaultName) {
      const err = validate(name);

      if (!err) {
        onRenameSubmit?.(name);
      }
    } else {
      handleRenameCancel();
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleRenameSubmit();
    } else if (e.key === "Escape") {
      handleRenameCancel();
    }
  };

  const onTextChange = useCallback(
    (value: string) => {
      setName(value);
      validate(value);
    },
    [validate]
  );

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onTextChange(e.target.value);
    },
    [onTextChange]
  );

  return (
    <>
      <input
        ref={mergeRefs([refs.setReference, inputRef])}
        type="text"
        placeholder={placeholder}
        defaultValue={name}
        onChange={onChange}
        onKeyDown={handleKeyDown}
        onBlur={handleRenameSubmit}
        onInput={onInput}
        className={cn(styles.rename_input, className)}
        style={style}
      />
      {error && (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
            className={styles.error}
          >
            <BiError className={styles.error_icon} size={20} />
            <Typography variant="caption1" className={styles.error_text}>
              {error}
            </Typography>
          </div>
        </FloatingPortal>
      )}
    </>
  );
};

export default RenameInput;
