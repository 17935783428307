import styles from "./CreateCanvasModal.module.scss";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import canvasService from "api/http/canvas-service";
import { useUserState } from "store";
import { type PropsWithModal } from "hooks/useModal";
import TextField from "components/common/TextField/TextField";
import { Button } from "components/common/Button/Button";
import Loader from "components/common/Loader/Loader";
import Typography from "components/Typography/Typography";

type CreateCanvasModalProps = PropsWithModal<{
  workspaceId?: string;
}>;

export const CreateCanvasModal = ({
  workspaceId,
  onModalClose,
}: CreateCanvasModalProps) => {
  const [isCanvasCreationPending, setIsCanvasCreationPending] = useState(false);

  const navigate = useNavigate();
  const userID = useUserState((slice) => slice.userID);
  const onCancel = () => {
    onModalClose();
  };

  const handleSubmit = (values) => {
    setIsCanvasCreationPending(true);
    canvasService
      .postCreateCanvas({
        user_id: userID || "",
        name: values.canvasName,
        description: values.canvasDescription,
        workspace_id: workspaceId,
      })
      .then((returnedValues) => {
        const { id = "" }: { id: string } = returnedValues;
        onModalClose();
        navigate(`/canvas/${id}`);
      })
      .catch((error) => {
        if (error.cause) {
          formik.setErrors(error.cause);
        }
        setIsCanvasCreationPending(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      canvasName: "",
      canvasDescription: "",
    },
    validationSchema: Yup.object({
      canvasName: Yup.string().required("Required"),
    }),
    onSubmit: handleSubmit,
  });

  return (
    <div>
      <Typography
        component="span"
        variant="body2"
        className={styles["modal__label"]}
      >
        Enter the name for your new canvas. You can change it later.
      </Typography>
      <form className={styles.modal__form} onSubmit={formik.handleSubmit}>
        <TextField
          id="canvasName"
          placeholder="Canvas name"
          autoFocus
          inputClassName={styles["modal__input"]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.canvasName}
          error={formik.touched.canvasName ? formik.errors.canvasName : ""}
        />
        <TextField
          id="canvasDescription"
          type="textarea"
          placeholder="Canvas description (Optional)"
          inputClassName={styles["modal__textarea"]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.canvasDescription}
          error={
            formik.touched.canvasDescription
              ? formik.errors.canvasDescription
              : ""
          }
        />
        <div className={styles["modal__row"]}>
          <Button size="large" variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            disabled={
              !formik.isValid || !formik.dirty || isCanvasCreationPending
            }
            size="large"
            type="submit"
          >
            {isCanvasCreationPending ? <Loader /> : <span>Create</span>}
          </Button>
        </div>
      </form>
    </div>
  );
};
