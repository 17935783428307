import styles from "./ErrorFallbackMessage.module.scss";
import { useCallback, type ReactNode } from "react";
import { BiDizzy } from "react-icons/bi";
import cn, { type Argument } from "classnames";
import { Button } from "components/common/Button/Button";

type ErrorFallbackMessageProps = {
  title?: ReactNode;
  text?: ReactNode;
  className?: Argument;
  actions?: ReactNode;
};

const DEFAULT_TITLE = "Oops! An unexpected error occurred...";
const DEFAULT_TEXT = "While we are working on a fix, try to refresh the page.";

export function ErrorFallbackMessage({
  title = DEFAULT_TITLE,
  text = DEFAULT_TEXT,
  className = "",
  actions = null,
}: ErrorFallbackMessageProps) {
  const backHome = useCallback(() => {
    // do not use router, so the page is fully reloaded
    window.location.href = "/";
  }, []);

  const refresh = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <div className={cn(styles.container, className)}>
      <BiDizzy className={styles.glyph} size={48} />

      <h4 className={styles.title}>{title}</h4>

      <p className={styles.text}>{text}</p>

      {actions ? (
        <div className={styles.customActions}>{actions}</div>
      ) : (
        <div className={styles.actions}>
          <Button variant="primary" size="large" onClick={backHome}>
            Go Home
          </Button>

          <Button variant="primary" size="large" onClick={refresh}>
            Refresh
          </Button>
        </div>
      )}
    </div>
  );
}
