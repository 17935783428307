import styles from "./SectionHeader.module.scss";
import { type ReactNode } from "react";
import cn from "classnames";

type SectionHeaderProps = {
  label: ReactNode;
  roundedTop?: boolean;
};

export default function SectionHeader({
  label,
  roundedTop,
}: SectionHeaderProps) {
  return (
    <div
      role="menuitem"
      aria-disabled="true"
      className={
        roundedTop
          ? cn(styles["section-header"], styles["section-header__rounded_top"])
          : styles["section-header"]
      }
    >
      {label}
    </div>
  );
}
