import { useRef, useEffect, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { getBlockContent } from "api/http/blocks-service";
import {
  useCanvasState,
  useCanvasBlocksState,
  useCanvasRequirementsState,
  useCanvasDeploymentState,
  useCanvasUsersState,
  useCommentsState,
  useUserState,
  useWebsocketState,
  useToastsState,
  useUserSettingsState,
} from "store";
import { useCanvasScheduledJobsActions } from "hooks/useCanvasScheduledJobsActions";
import { useCanvasAssetsActions } from "hooks/assets/useCanvasAssetsActions";
import { tokenRef } from "context/Token";
import {
  WS_INSTRUCTION,
  REQUIREMENTS_BUILD_COMPLETED_STATUSES,
} from "config/canvasConfig";
import { DATA_LOAD_STATUS } from "config/appConfig";
import { LAYER_TYPE } from "config/canvasConfig";

async function addBlockContentToPayloadIfRequired(payload: any) {
  if (payload.content !== undefined) {
    return payload;
  }
  const blockContent = await getBlockContent(payload.id);
  payload.content = blockContent.block_content;
  return payload;
}

export function useCanvasWebsocket() {
  const { user } = useAuth0();
  const { canvasId = "" } = useParams();

  const addToast = useToastsState((slice) => slice.addToast);

  const userId = useUserState((slice) => slice.userID);
  const avatar = useUserSettingsState((slice) => slice.profile.avatar);

  const {
    ws,
    isInitialConnectionOpen,
    openConnection,
    send,
    setIsInitialConnectionOpen,
    setIsWebSocketDisconnected,
  } = useWebsocketState((slice) => ({
    ws: slice.ws,
    isInitialConnectionOpen: slice.isInitialConnectionOpen,
    isWebSocketDisconnected: slice.isWebSocketDisconnected,
    openConnection: slice.openConnection,
    send: slice.send,
    setIsInitialConnectionOpen: slice.setIsInitialConnectionOpen,
    setIsWebSocketDisconnected: slice.setIsWebSocketDisconnected,
  }));

  const { getScheduledJobByLayerId } = useCanvasScheduledJobsActions();

  const {
    onWsCanvasAssetMembershipAdded,
    onWsCanvasAssetMembershipDeleted,
    onWsCanvasAssetMembershipUpdated,
  } = useCanvasAssetsActions();

  const userName = user?.given_name || user?.name;
  const userProfileImage = avatar || user?.picture;

  const connectTimeout = useRef<NodeJS.Timeout | null>(null);
  const attemptNumber = useRef(0);

  const {
    addComment,
    editComment,
    deleteComment,
    addReply,
    editReply,
    deleteReply,
  } = useCommentsState((slice) => ({
    addComment: slice.addComment,
    editComment: slice.editComment,
    deleteComment: slice.deleteComment,
    addReply: slice.addReply,
    editReply: slice.editReply,
    deleteReply: slice.deleteReply,
  }));

  const {
    isEditor,
    setConnectionId,
    getConnectionId,
    getLayerBlocks,
    setZoomTo,
    setName,
    setSelectedLayerId,
    getLayer,
    addLayer,
    removeLayer,
    renameLayer,
    updateLayer,
    addBlock,
    removeBlock,
    resizeBlock,
    animateMoveBlock,
    addEdge,
    removeEdge,
    updateUserApi,
    setFocusedBlockId,
    updateLayerScheduledJobInfo,
  } = useCanvasState((slice) => ({
    isEditor: slice.isEditor,
    setConnectionId: slice.setConnectionId,
    getConnectionId: slice.getConnectionId,
    getLayerBlocks: slice.getLayerBlocks,
    setZoomTo: slice.setZoomTo,
    setName: slice.setName,
    setSelectedLayerId: slice.setSelectedLayerId,
    getLayer: slice.getLayer,
    addLayer: slice.addLayer,
    removeLayer: slice.removeLayer,
    renameLayer: slice.renameLayer,
    updateLayer: slice.updateLayer,
    addBlock: slice.addBlock,
    removeBlock: slice.removeBlock,
    animateMoveBlock: slice.animateMoveBlock,
    resizeBlock: slice.resizeBlock,
    addEdge: slice.addEdge,
    removeEdge: slice.removeEdge,
    updateUserApi: slice.updateUserApi,
    setFocusedBlockId: slice.setFocusedBlockId,
    updateLayerScheduledJobInfo: slice.updateLayerScheduledJobInfo,
  }));

  const {
    setConnectedUsers,
    addConnectedUser,
    removeConnectedUser,
    updateConnectedUser,
  } = useCanvasUsersState((slice) => ({
    setConnectedUsers: slice.setConnectedUsers,
    addConnectedUser: slice.addConnectedUser,
    removeConnectedUser: slice.removeConnectedUser,
    updateConnectedUser: slice.updateConnectedUser,
  }));

  const setBlockContent = useCanvasBlocksState(
    (slice) => slice.setBlockContent
  );
  const setBlockData = useCanvasBlocksState((slice) => slice.setBlockData);
  const patchBlockData = useCanvasBlocksState((slice) => slice.patchBlockData);
  const deleteBlock = useCanvasBlocksState((slice) => slice.deleteBlock);
  const setBlockOutput = useCanvasBlocksState((slice) => slice.setBlockOutput);

  const {
    setRequirementsStatus,
    setExecutorLoadingStatus,
    setGlobalImportsByLanguage,
  } = useCanvasRequirementsState((slice) => ({
    setRequirementsStatus: slice.setRequirementsStatus,
    setExecutorLoadingStatus: slice.setExecutorLoadingStatus,
    setGlobalImportsByLanguage: slice.setGlobalImportsByLanguage,
  }));

  const { setDeployment, deleteDeployment } = useCanvasDeploymentState(
    (slice) => ({
      setDeployment: slice.setDeployment,
      deleteDeployment: slice.deleteDeployment,
    })
  );

  const attemptToOpenConnection = useCallback(
    ({ canvasId }: { canvasId: string }) => {
      if (connectTimeout.current || !canvasId) {
        return;
      }

      const timeToWait = Math.min(
        30000,
        2 ** attemptNumber.current * 1000 - 1000
      ); // 0s, 1s, 3s, 7s, 15s, 30s, 30s, 30s, ...

      connectTimeout.current = setTimeout(() => {
        openConnection({
          canvasId,
          isPublic: !isEditor,
          token: tokenRef.current,
        });
      }, timeToWait);
    },
    [isEditor]
  );

  // listen to websocket open/close events
  useEffect(() => {
    if (!ws) {
      return;
    }

    const handleOpen = () => {
      connectTimeout.current = null;
      attemptNumber.current = 0;
      setIsInitialConnectionOpen(true);
      setIsWebSocketDisconnected(false);
      if (userId) {
        send({
          instruction: WS_INSTRUCTION.USER_CONNECT,
          message: {
            user_id: userId,
            user_name: userName || null,
            profile_image: userProfileImage || null,
            canvas_id: canvasId,
          },
        });
      }
    };
    const handleClose = () => {
      connectTimeout.current = null;
      attemptNumber.current = attemptNumber.current + 1;
      if (isInitialConnectionOpen) {
        setIsWebSocketDisconnected(true);
      }
      attemptToOpenConnection({ canvasId });
    };

    ws.addEventListener("open", handleOpen);
    ws.addEventListener("close", handleClose);

    return () => {
      ws.removeEventListener("open", handleOpen);
      ws.removeEventListener("close", handleClose);
    };
  }, [
    ws,
    isInitialConnectionOpen,
    userId,
    userName,
    userProfileImage,
    canvasId,
    attemptToOpenConnection,
  ]);

  const handleMessage = useCallback(
    (event: MessageEvent) => {
      const data = JSON.parse(JSON.parse(event.data));
      const { instruction, ...payload } = data;
      switch (instruction) {
        case WS_INSTRUCTION.USER_CONNECT:
          if (payload?.payload) {
            // add user to canvas
            if (payload?.connection_id !== getConnectionId()) {
              addConnectedUser(payload.connection_id, payload.payload);
            }
          } else {
            setConnectionId(payload.connection_id);
          }
          break;
        case WS_INSTRUCTION.USER_DISCONNECT:
          removeConnectedUser(payload);
          break;
        case WS_INSTRUCTION.UPDATE_USER_POSITION:
          if (payload?.connection_id !== getConnectionId()) {
            updateConnectedUser(payload);
          }
          break;
        case WS_INSTRUCTION.UPDATE_USER_ZOOM:
          if (payload?.connection_id !== getConnectionId()) {
            updateConnectedUser(payload);
          }
          break;
        case WS_INSTRUCTION.RENAME_CANVAS:
          setName(payload);
          break;
        case WS_INSTRUCTION.CREATE_LAYER:
          addLayer(payload);
          if (payload?.connection_id === getConnectionId()) {
            setSelectedLayerId(payload.id);
          } else if (payload?.type === LAYER_TYPE.SCHEDULED_JOBS) {
            getScheduledJobByLayerId(payload.id);
          }
          break;
        case WS_INSTRUCTION.DELETE_LAYER:
          removeLayer(payload);
          break;
        case WS_INSTRUCTION.RENAME_LAYER: {
          if (payload.connection_id !== getConnectionId()) {
            // do not rename for the same connection
            renameLayer(payload);
          }
          break;
        }
        case WS_INSTRUCTION.UPDATE_LAYER: {
          const layerId = payload.layer.id;
          const currentDeploymentId = getLayer(layerId)?.deployment_id;
          // delete deployment if layer no longer has deployment_id
          if (currentDeploymentId && !payload.layer.deployment_id) {
            deleteDeployment({
              deploymentId: currentDeploymentId,
              layerId,
            });
          }
          updateLayer(payload.layer);
          break;
        }
        case WS_INSTRUCTION.CREATE_BLOCK:
          addBlockContentToPayloadIfRequired(payload).then((payload) => {
            setBlockContent(payload.id, payload.content);
            setBlockData(payload.id, payload);
            addBlock(payload);
            if (
              payload.connection_id === getConnectionId() &&
              payload.origin !== "notebook_import"
            ) {
              setFocusedBlockId(payload.id, payload.layer_id);
            }
          });
          break;
        case WS_INSTRUCTION.DELETE_BLOCK:
          deleteBlock(payload.block_id);
          removeBlock(payload);
          break;
        case WS_INSTRUCTION.RENAME_BLOCK: {
          if (payload.connection_id !== getConnectionId()) {
            patchBlockData(payload.id, { name: payload.name });
          }
          break;
        }
        case WS_INSTRUCTION.UPDATE_BLOCK_CONTENT:
          if (payload.connection_id !== getConnectionId()) {
            addBlockContentToPayloadIfRequired(payload).then((payload) => {
              setBlockContent(payload.id, payload.content);
            });
          } else {
            patchBlockData(payload.id, { status: payload.status });
          }
          break;
        case WS_INSTRUCTION.UPDATE_BLOCK_PROPERTIES:
          if (payload.connection_id !== getConnectionId()) {
            patchBlockData(payload.id, { properties: payload.properties });
          }
          break;
        case WS_INSTRUCTION.BLOCK_UPDATED:
          setBlockData(payload.block.id, payload.block);
          break;
        case WS_INSTRUCTION.UPDATE_BLOCK_DESCRIPTION:
          patchBlockData(payload.id, { description: payload.description });
          break;
        case WS_INSTRUCTION.LINT_BLOCK:
          addBlockContentToPayloadIfRequired(payload).then((payload) => {
            setBlockContent(payload.id, payload.content);
          });
          break;
        case WS_INSTRUCTION.UPDATE_BLOCK_STATUS:
          patchBlockData(payload.id, {
            status: payload.status,
            last_run_execution_time: payload.last_run_execution_time,
            current_run_execution_time_start: null,
            fleet_id: payload.fleet_id,
          });
          break;
        case WS_INSTRUCTION.UPDATE_BLOCKS:
          for (const block of payload.blocks) {
            patchBlockData(block.b_id, { [payload.property]: block.value });
          }
          break;

        case WS_INSTRUCTION.UPDATE_BLOCK_STATUSES:
          for (const block of payload.blocks) {
            patchBlockData(block.b_id, { status: block.s });
          }
          break;
        case WS_INSTRUCTION.UPDATE_BLOCK_STATUSES_FOR_LAYER: {
          if (!payload.layer_id || !payload.status) {
            return;
          }
          const blocks = getLayerBlocks(payload.layer_id);
          for (const block of blocks) {
            patchBlockData(block.id, { status: payload.status });
          }
          break;
        }
        case WS_INSTRUCTION.RESIZE_BLOCK:
          resizeBlock(payload);
          break;
        case WS_INSTRUCTION.MOVE_BLOCK:
          if (
            payload.connection_id !== getConnectionId() ||
            payload.origin === "tidy"
          ) {
            animateMoveBlock(payload);
          }
          break;
        case WS_INSTRUCTION.CREATE_EDGE:
          addEdge(payload);
          break;
        case WS_INSTRUCTION.DELETE_EDGE:
          removeEdge(payload);
          break;
        case WS_INSTRUCTION.UPDATE_GLOBAL_IMPORTS_BY_LANGUAGE:
          if (payload.connection_id !== getConnectionId()) {
            setGlobalImportsByLanguage(
              payload.global_imports,
              payload.language
            );
          }
          break;
        case WS_INSTRUCTION.UPDATE_EXECUTOR_STATUS:
          // update execution status
          setRequirementsStatus(payload.status);
          if (
            REQUIREMENTS_BUILD_COMPLETED_STATUSES.some(
              (status) => status === payload.status
            )
          ) {
            // refetch executor when build is completed
            setExecutorLoadingStatus(DATA_LOAD_STATUS.NOT_LOADED);
          }
          break;
        case WS_INSTRUCTION.USER_MESSAGE:
          // send a toast notification to a single user
          if (!payload.message) {
            break;
          }
          if (
            payload.connection_id &&
            payload.connection_id === getConnectionId()
          ) {
            addToast({
              variant: payload.variant || "info",
              message: payload.message,
            });
          } else {
            addToast({
              variant: payload.variant || "info",
              message: payload.message,
            });
          }
          break;
        case WS_INSTRUCTION.ZOOM_TO:
          if (payload.connection_id === getConnectionId()) {
            const { x_min, x_max, y_min, y_max } = payload;
            const width = x_max - x_min;
            const height = y_max - y_min;
            setZoomTo({
              x: x_min,
              y: y_min,
              width,
              height,
              layer_id: payload.layer_id,
            });
          }
          break;
        case WS_INSTRUCTION.UPDATE_API_STATUS:
          updateUserApi(payload);
          break;
        case WS_INSTRUCTION.CANVAS_USERS_UPDATE:
          // update connected users
          setConnectedUsers(payload?.payload?.users);
          break;
        case WS_INSTRUCTION.BLOCK_COMMENT_ADDED:
          // if the comment is from the current user, it's already in the store
          if (payload.payload.user_id !== userId) {
            addComment(payload.payload);
          }
          break;
        case WS_INSTRUCTION.BLOCK_COMMENT_UPDATED:
          editComment(payload.payload);
          break;
        case WS_INSTRUCTION.BLOCK_COMMENT_DELETED:
          deleteComment(payload.payload.id);
          break;
        case WS_INSTRUCTION.BLOCK_COMMENT_REPLY_ADDED:
          // if the reply is from the current user, it's already in the store
          if (payload.payload.user_id !== userId) {
            addReply(payload.payload);
          }
          break;
        case WS_INSTRUCTION.BLOCK_COMMENT_REPLY_UPDATED:
          if (payload.payload.user_id !== userId) {
            editReply(payload.payload);
          }
          break;
        case WS_INSTRUCTION.CLEAR_BLOCK_OUTPUT:
          setBlockOutput(payload.block.id, null);
          break;
        case WS_INSTRUCTION.FLEET_SPEAD:
          console.log("FLEET_SPEAD", payload);
          patchBlockData(payload.block_id, {
            fleet_id: payload.fleet_id,
            slice_ids: payload.slice_ids,
          });
          payload.downstream_block_ids.forEach((block: any) => {
            patchBlockData(block, {
              fleet_id: payload.fleet_id,
            });
          });
          break;
        case WS_INSTRUCTION.BLOCK_COMMENT_REPLY_DELETED:
          deleteReply({
            commentId: payload.payload.block_comment_id,
            replyId: payload.payload.id,
          });
          break;
        case WS_INSTRUCTION.UPDATE_BLOCK_OUTPUT_PANE_SIZE:
          if (payload.connection_id !== getConnectionId()) {
            patchBlockData(payload.id, {
              outputPaneSize: payload.output_pane_size,
            });
          }
          break;
        case WS_INSTRUCTION.SCHEDULED_JOB_UPDATED: {
          const { connection_id, ...jobData } = payload;
          if (connection_id !== getConnectionId()) {
            updateLayerScheduledJobInfo(jobData.layer_id, {
              data: jobData,
            });
          }
          break;
        }
        case WS_INSTRUCTION.DEPLOYMENT_UPDATED: {
          const { deployment } = payload;
          setDeployment(deployment.id, deployment);
          break;
        }
        case WS_INSTRUCTION.ASSET_CANVAS_MEMBERSHIP_ADDED: {
          if (payload.connection_id !== getConnectionId()) {
            onWsCanvasAssetMembershipAdded(payload);
          }
          break;
        }
        case WS_INSTRUCTION.ASSET_CANVAS_MEMBERSHIP_UPDATED: {
          if (payload.connection_id !== getConnectionId()) {
            onWsCanvasAssetMembershipUpdated(payload);
          }
          break;
        }
        case WS_INSTRUCTION.ASSET_CANVAS_MEMBERSHIP_DELETED: {
          if (payload.connection_id !== getConnectionId()) {
            onWsCanvasAssetMembershipDeleted(payload);
          }
          break;
        }
        default:
          break;
      }
    },
    [
      getScheduledJobByLayerId,
      onWsCanvasAssetMembershipAdded,
      onWsCanvasAssetMembershipDeleted,
      onWsCanvasAssetMembershipUpdated,
    ]
  );

  // listen to websocket messages
  useEffect(() => {
    if (!ws) {
      return;
    }

    ws.addEventListener("message", handleMessage);

    return () => {
      ws.removeEventListener("message", handleMessage);
    };
  }, [ws, handleMessage]);

  return useMemo(
    () => ({
      attemptToOpenConnection,
    }),
    [attemptToOpenConnection]
  );
}
