import type { ASSET_PERMISSION } from "pages/Assets/config";
export enum MenuOptions {
  Function = "function",
  Query = "query",
  Constant = "constant",
  Connections = "connection",
}

export type EmptyStateType = {
  title: string;
  text?: string;
};

export interface AssetsPreviewData {
  id: string;
  name: string;
  type: MenuOptions;
  versionId: string;
  version: number;
  preview: string;
  description?: string;
  organization_id?: string | null;
  workspace_id?: string | null;
  permission?: ASSET_PERMISSION | null;
}
