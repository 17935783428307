import { Navigate, type RouteObject } from "react-router-dom";
import { Functions } from "pages/Assets/Functions/Functions";
import { Constants } from "pages/Assets/Constants/Constants";
import { Connections } from "pages/Assets/Connections/Connections";
import { Queries } from "pages/Assets/Queries/Queries";
import { WorkspaceCanvases } from "pages/WorkspaceCanvases/WorkspaceCanvases";
import { WorkspaceApps } from "pages/WorkspaceApps/WorkspaceApps";
import { WorkspaceApp } from "pages/WorkspaceApp/WorkspaceApp";
import { WorkspaceSettings } from "pages/WorkspaceSettings/WorkspaceSettings";
import { WorkspaceMembers } from "pages/WorkspaceMembers/WorkspaceMembers";
import { WorkspaceGroupMembers } from "pages/WorkspaceGroupMembers/WorkspaceGroupMembers";
import { AssetsPages } from "layout/AssetsPages/AssetsPages";
import { ConnectionsPages } from "layout/ConnectionsPages/ConnectionsPages";
import { ConstantsPages } from "layout/ConstantsPages/ConstantsPages";
import { FunctionsPages } from "layout/FunctionsPages/FunctionsPages";
import { QueriesPage } from "layout/QueriesPage/QueriesPage";
import { WorkspaceReports } from "pages/WorkspaceReports/WorkspaceReports";

export const ORGANIZATION_WORKSPACE_ROUTES: RouteObject[] = [
  { index: true, element: <Navigate to="canvases" /> },
  { path: "canvases", element: <WorkspaceCanvases /> },
  { path: "apps", element: <WorkspaceApps /> },
  {
    path: "apps",
    children: [{ path: ":appID", element: <WorkspaceApp /> }],
  },
  { path: "reports", element: <WorkspaceReports /> },
  { path: "members", element: <WorkspaceMembers /> },
  { path: "group_members", element: <WorkspaceGroupMembers /> },
  { path: "settings", element: <WorkspaceSettings /> },
  {
    path: "assets",
    element: <AssetsPages />,
    children: [
      { index: true, element: <Navigate to="functions" /> },
      {
        path: "functions",
        element: <FunctionsPages />,
        children: [{ path: ":versionID", element: <Functions /> }],
      },
      {
        path: "constants",
        element: <ConstantsPages />,
        children: [{ path: ":versionID", element: <Constants /> }],
      },
      {
        path: "connections",
        element: <ConnectionsPages />,
        children: [{ path: ":connectionId", element: <Connections /> }],
      },
      {
        path: "queries",
        element: <QueriesPage />,
        children: [{ path: ":versionID", element: <Queries /> }],
      },
    ],
  },
];
