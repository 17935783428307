import styles from "./Functions.module.scss";
import { useCallback, useEffect, useState, useRef } from "react";
import cn from "classnames";
import { useDebouncedCallback } from "use-debounce";
import { patchFunctionVersion } from "api/http/assets-service";
import { WindowHeader } from "components/common/WindowHeader/WindowHeader";
import CodeEditor from "components/common/CodeEditor/CodeEditor";
import {
  SAVING_STATUS,
  SavingStatusIndicator,
} from "components/common/SavingStatusIndicator/SavingStatusIndicator";
// import { RequirementsSection } from "./components";
import { useAssetsData } from "hooks/useAssetsData";
import { useAssetsState, useToastsState } from "store";
import { ASSET_PERMISSION } from "../config";
import { type FunctionVersionType } from "../types/functionTypes";

const FUNCTION_DEFINITION_PLACEHOLDER = `def my_func():
  # Write your code here
  pass`;

export function Functions() {
  const addToast = useToastsState((slice) => slice.addToast);
  const { getCurrentAssetItemsData, getCreatedAssetItemData } = useAssetsData();
  const { updateFunctionVersionData: updateFunctionVersionDataInStore } =
    useAssetsState((slice) => slice.functionsActions);

  const { activeVersionData: activeVersionStoreData, activeItemVersionId } =
    getCurrentAssetItemsData("function");

  const { assetOperationType } = getCreatedAssetItemData("function");

  const activeItemVersionIdRef = useRef(activeItemVersionId);
  const idleTimeout = useRef<NodeJS.Timeout | null>(null);

  const [savingStatusMap, setSavingStatusMap] = useState<
    Map<
      FunctionVersionType["id"],
      {
        status: SAVING_STATUS;
        timestamp: number;
      }
    >
  >(new Map());

  const activeVersionData =
    activeVersionStoreData as FunctionVersionType | null;

  // duplicate activeItemVersionId to ref
  useEffect(() => {
    activeItemVersionIdRef.current = activeItemVersionId;
  }, [activeItemVersionId]);

  const updateSavingStatus = useCallback(
    ({
      versionId,
      status,
      timestamp,
    }: {
      versionId: FunctionVersionType["id"];
      status: SAVING_STATUS;
      timestamp: number;
    }) => {
      setSavingStatusMap((prevState) => {
        // ignore status change if it's outdated
        const currentStatus = prevState.get(versionId);
        if (currentStatus && currentStatus.timestamp > timestamp) {
          return prevState;
        }

        // clear previous idleTimeout if any
        if (idleTimeout.current) {
          clearTimeout(idleTimeout.current);
          idleTimeout.current = null;
        }

        // if "saved", reset status to "idle" after 3 seconds
        if (status === SAVING_STATUS.COMPLETED) {
          idleTimeout.current = setTimeout(() => {
            updateSavingStatus({
              versionId,
              status: SAVING_STATUS.IDLE,
              timestamp,
            });
          }, 3000);
        }

        // save new status, timestamp, and timeout
        const newState = new Map(prevState);
        newState.set(versionId, { status, timestamp });
        return newState;
      });
    },
    []
  );

  const debouncedPatchFunctionVersion = useDebouncedCallback(
    useCallback(
      (value: string, versionId: FunctionVersionType["id"]) => {
        const requestStartTimestamp = Date.now();

        patchFunctionVersion(versionId, { id: versionId, code: value })
          .then(() => {
            if (activeItemVersionIdRef.current === versionId) {
              // show "✓ Saved" status
              updateSavingStatus({
                versionId,
                status: SAVING_STATUS.COMPLETED,
                timestamp: requestStartTimestamp,
              });
            } else {
              // clear status if version is not active anymore
              updateSavingStatus({
                versionId,
                status: SAVING_STATUS.IDLE,
                timestamp: requestStartTimestamp,
              });
            }
          })
          .catch((error) => {
            // clear status
            updateSavingStatus({
              versionId,
              status: SAVING_STATUS.FAILED,
              timestamp: requestStartTimestamp,
            });

            addToast({
              variant: "error",
              message: error?.message || "Failed to update version code",
            });
          });
      },
      [updateSavingStatus]
    ),
    3000
  );

  // patch version immediatelly after active version change or unmount
  useEffect(() => {
    return () => {
      debouncedPatchFunctionVersion.flush();
    };
  }, [debouncedPatchFunctionVersion, activeItemVersionId]);

  // clear COMPLETED statuses and idleTimeout on active version change
  useEffect(() => {
    setSavingStatusMap((prevState) => {
      const newState = new Map(prevState);
      for (const [versionId, status] of prevState.entries()) {
        if (status.status === SAVING_STATUS.COMPLETED) {
          newState.delete(versionId);
        }
      }
      return newState;
    });

    if (idleTimeout.current) {
      clearTimeout(idleTimeout.current);
    }
  }, [activeItemVersionId]);

  const handleFunctionCodeChange = useCallback(
    (value?: string) => {
      if (
        !activeItemVersionId ||
        !activeVersionData?.asset_id ||
        activeVersionData?.version === undefined
      ) {
        return;
      }

      // immediately reflect changes in store
      updateFunctionVersionDataInStore(
        activeVersionData.asset_id,
        activeVersionData.version,
        { code: value ?? "" }
      );

      // show "Saving..." status
      updateSavingStatus({
        versionId: activeItemVersionId,
        status: SAVING_STATUS.PENDING,
        timestamp: Date.now(),
      });

      // debounce PATCH request
      debouncedPatchFunctionVersion(value ?? "", activeItemVersionId);
    },
    [
      activeItemVersionId,
      debouncedPatchFunctionVersion,
      activeVersionData?.asset_id,
      activeVersionData?.version,
    ]
  );

  const readOnly =
    activeVersionData?.archive ||
    activeVersionData?.permission !== ASSET_PERMISSION.write;

  return activeItemVersionId ? (
    <div className={styles.container}>
      {/* https://linear.app/zerve-ai/issue/FRO-1193/hide-requirements-panel-on-functions-page-on-assets */}
      {/* <ResizableNav
        position="right"
        defaultSize={300}
        boxProps={{ minConstraints: [220, Infinity] }}
      >
        <RequirementsSection />
      </ResizableNav> */}
      <div
        className={cn(styles.codeSectionWrapper, {
          [styles.codeSectionWrapperActionsDisabled]:
            assetOperationType === "editName",
        })}
      >
        <WindowHeader contentClassName={styles.codeSectionHeaderContent}>
          <div>Function Definition</div>
          <SavingStatusIndicator
            className={styles.savingStatusIndicator}
            status={savingStatusMap.get(activeItemVersionId)?.status}
          />
        </WindowHeader>
        <CodeEditor
          language="python"
          placeholderText={FUNCTION_DEFINITION_PLACEHOLDER}
          value={activeVersionData?.code || ""}
          onChange={handleFunctionCodeChange}
          lineNumbers
          enableMinimap={!readOnly}
          readOnly={readOnly}
          transparentBackground={readOnly}
        />
      </div>
    </div>
  ) : null;
}
