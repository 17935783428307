import styles from "./ConfirmReportsDeletionModal.module.scss";
import { useMemo } from "react";
import type { ReportTableType } from "models/publishedReport";

const MAX_REPORTS_TO_SHOW = 5;

type ConfirmReportssDeletionModalProps = {
  reportIds: ReportTableType["id"][];
  reports: ReportTableType[];
};

export function ConfirmReportsDeletionModal({
  reportIds,
  reports,
}: ConfirmReportssDeletionModalProps) {
  const reportsToDisplay = useMemo(() => {
    return reportIds.slice(0, MAX_REPORTS_TO_SHOW);
  }, [reportIds]);

  const extraCount = useMemo(() => {
    return reportIds.length - reportsToDisplay.length;
  }, [reportIds, reportsToDisplay]);

  return (
    <>
      {/* Question */}
      {reportIds.length === 1
        ? "Are you sure you want to delete this report?"
        : `Are you sure you want to delete these ${reportIds.length} report?`}

      {/* Apps list */}
      <ul className={styles.appsList}>
        {reportsToDisplay.map((appId) => {
          const app = reports.find((app) => app.id === appId);
          if (!app) {
            return null;
          }
          return (
            <li key={appId} className={styles.listItem}>
              <span className={styles.name}>{app.name}</span>
            </li>
          );
        })}
      </ul>

      {extraCount > 0 ? (
        <div className={styles.extraCount}>... and {extraCount} more</div>
      ) : null}
    </>
  );
}
