import stylesEx from "styles/_exports.module.scss";
import AutoML from "assets/icons/block-type/automl.svg?react";
import Markdown from "assets/icons/block-type/markdown.svg?react";
import Python from "assets/icons/block-type/python.svg?react";
import R from "assets/icons/block-type/r.svg?react";
import SQL from "assets/icons/block-type/sql.svg?react";
import { GiFeather } from "react-icons/gi";
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import { LuBrainCircuit } from "react-icons/lu";
import ApiController from "assets/icons/block-type/api-controller.svg?react";
import ApiRoute from "assets/icons/block-type/api-route.svg?react";
import PendingRun from "assets/icons/pending-run.svg?react";
import PythonIcon from "assets/icons/python-logo.svg?react";
import RIcon from "assets/icons/r-logo.svg?react";
import {
  GPUIcon,
  MarathonIcon,
  SageMakerIcon,
  SprintIcon,
} from "components/icons";
import { SnowflakeIcon } from "pages/Assets/Connections/components/icons";
import {
  BiBracket,
  BiChart,
  BiCheck,
  BiCodeCurly,
  BiErrorCircle,
  BiGlobe,
  BiImage,
  BiLoader,
  BiTable,
  BiText,
  BiTime,
  BiWindows,
  BiCloudLightning,
  BiLockAlt,
  BiGroup,
  BiCollection,
} from "react-icons/bi";
import { GoFileBinary } from "react-icons/go";
import { IoWarningOutline } from "react-icons/io5";
import { TbNumbers, TbCheckbox } from "react-icons/tb";
import { GiSandsOfTime } from "react-icons/gi";
import type { IconType } from "react-icons";
import type { BlockData } from "models/canvas";

export enum WS_INSTRUCTION {
  USER_CONNECT = "user_connect",
  USER_DISCONNECT = "user_disconnect",
  CANVAS_USERS_UPDATE = "canvas_users_update",
  RENAME_CANVAS = "rename_canvas",
  CREATE_LAYER = "create_layer",
  DELETE_LAYER = "delete_layer",
  RENAME_LAYER = "rename_layer",
  UPDATE_LAYER = "update_layer",
  CREATE_BLOCK = "create_block",
  DELETE_BLOCK = "delete_block",
  MOVE_BLOCK = "move_block",
  RESIZE_BLOCK = "resize_block",
  UPDATE_BLOCK_OUTPUT_PANE_SIZE = "update_block_output_pane_size",
  RENAME_BLOCK = "rename_block",
  UPDATE_BLOCKS = "update_blocks",
  UPDATE_BLOCK_CONTENT = "update_block_content",
  UPDATE_BLOCK_PROPERTIES = "update_block_properties",
  UPDATE_BLOCK_DESCRIPTION = "update_block_description",
  UPDATE_BLOCK_STATUS = "update_block_status",
  UPDATE_BLOCK_STATUSES = "update_block_statuses",
  UPDATE_BLOCK_STATUSES_FOR_LAYER = "update_block_statuses_for_layer",
  MOVE_SELECTION = "move_selection",
  DELETE_SELECTION = "delete_selection",
  CREATE_EDGE = "create_edge",
  DELETE_EDGE = "delete_edge",
  PLAY_BLOCK = "play_block",
  STOP_BLOCK = "stop_block",
  PLAY_ALL = "play_all",
  FORCE_PLAY_ALL = "force_play_all",
  PLAY_TO_HERE = "play_to_here",
  FORCE_PLAY_TO_HERE = "force_play_to_here",
  UPDATE_API_STATUS = "update_api_status",
  UPDATE_USER_POSITION = "update_user_position",
  UPDATE_USER_ZOOM = "update_user_zoom",
  UPDATE_GLOBAL_IMPORTS_BY_LANGUAGE = "update_global_imports_by_language",
  TIDY = "tidy",
  LINT_BLOCK = "lint_block",
  LINT_ALL = "lint_all",
  CLEAR_BLOCK_OUTPUT = "clear_block_output",
  IMPORT_NOTEBOOK = "import_notebook",
  TRUNCATE_CANVAS_BY_BLOCK = "truncate_canvas_by_block",
  UPDATE_IS_FARGATE = "update_is_fargate",
  UPDATE_BLOCK = "update_block",
  BLOCK_UPDATED = "block_updated",
  UPDATE_EXECUTOR_STATUS = "update_executor_status",
  CREATE_PIPELINE = "create_pipeline",
  FILE_UPLOADED = "file_uploaded",
  USER_MESSAGE = "user_message",
  ZOOM_TO = "zoom_to",
  BLOCK_COMMENT_ADDED = "block_comment_added",
  BLOCK_COMMENT_UPDATED = "block_comment_updated",
  BLOCK_COMMENT_DELETED = "block_comment_deleted",
  BLOCK_COMMENT_REPLY_ADDED = "block_comment_reply_added",
  BLOCK_COMMENT_REPLY_UPDATED = "block_comment_reply_updated",
  BLOCK_COMMENT_REPLY_DELETED = "block_comment_reply_deleted",
  SCHEDULED_JOB_UPDATED = "scheduled_job_updated",
  DEPLOYMENT_UPDATED = "deployment_updated",
  PING = "ping",
  ASSET_CANVAS_MEMBERSHIP_ADDED = "add_asset_canvas_membership",
  ASSET_CANVAS_MEMBERSHIP_DELETED = "delete_asset_canvas_membership",
  ASSET_CANVAS_MEMBERSHIP_UPDATED = "update_asset_canvas_membership",
  FLEET_SPEAD = "fleet_spread",
}

// The list of WS instructions that are followed by a response from the backend
// with the same action_id parameter echoed back.
// It can be used to signalize the successful completion of the action.
export const PROMISEABLE_WS_INSTRUCTIONS = new Set([
  WS_INSTRUCTION.UPDATE_BLOCK_CONTENT,
  WS_INSTRUCTION.UPDATE_GLOBAL_IMPORTS_BY_LANGUAGE,
]);

// The list of WS instructions that are session-phobic.
// Sending WS messages with these instructions should not bump the session expiration time.
export const SESSION_PHOBIC_WS_INSTRUCTIONS = new Set([
  WS_INSTRUCTION.PING,
  WS_INSTRUCTION.UPDATE_USER_POSITION,
  WS_INSTRUCTION.UPDATE_USER_ZOOM,
]);

export enum BLOCK_TYPE {
  // service types (not returned from the backend)
  PLACEHOLDER = "placeholder",
  GROUP = "grouping", // avoid using "group" as it is a reserved reactflow type
  // actual block types
  PYTHON = "1",
  R = "2",
  SQL = "3",
  MARKDOWN = "4",
  AUTOML = "5",
  API_CONTROLLER = "7",
  API_ROUTE = "8",
  GEN_AI = "9",
  BEDROCK = "10",
  RMARKDOWN = "11",
  SAGEMAKER_ENDPOINT = "12",
  ENDPOINT_INVOCATION = "13",
  SNOWPARK = "14",
  AGGREGATOR = "15",
}

export enum MARATHON_SIZE {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
  XLARGE = "xlarge",
  XXLARGE = "2xlarge",
}

export const MARATHON_SIZE_DETAILS: Record<
  MARATHON_SIZE,
  { cpu: number; memory: number }
> = {
  [MARATHON_SIZE.SMALL]: { cpu: 1, memory: 8 },
  [MARATHON_SIZE.MEDIUM]: { cpu: 2, memory: 16 },
  [MARATHON_SIZE.LARGE]: { cpu: 4, memory: 30 },
  [MARATHON_SIZE.XLARGE]: { cpu: 8, memory: 60 },
  [MARATHON_SIZE.XXLARGE]: { cpu: 16, memory: 120 },
};

export enum COMPUTE_ENV_TYPE {
  MARATHON = "marathon",
  SPRINT = "sprint",
  GPU = "gpu",
}

export const COMPUTE_ENV_TYPE_CONFIG = Object.freeze({
  [COMPUTE_ENV_TYPE.SPRINT]: {
    Icon: SprintIcon,
    label: "Sprint",
  },
  [COMPUTE_ENV_TYPE.MARATHON]: {
    Icon: MarathonIcon,
    label: "Marathon",
  },
  [COMPUTE_ENV_TYPE.GPU]: {
    Icon: GPUIcon,
    label: "GPU",
  },
});

export enum SAGEMAKER_INSTANCE_TYPE {
  ML_P3_2XLARGE = "ml.p3.2xlarge",
  ML_P2_XLARGE = "ml.p2.xlarge",
  ML_G4DN_XLARGE = "ml.g4dn.xlarge",
  ML_G4DN_2XLARGE = "ml.g4dn.2xlarge",
  ML_G4DN_4XLARGE = "ml.g4dn.4xlarge",
  ML_G5_XLARGE = "ml.g5.xlarge",
  ML_G5_2XLARGE = "ml.g5.2xlarge",
  ML_G5_4XLARGE = "ml.g5.4xlarge",
}

export type GpuInstanceType = {
  name: SAGEMAKER_INSTANCE_TYPE;
  cpu: number;
  memory: number;
  gpu_model: string;
  gpu_count: number;
  gpu_memory: number;
};

export const SAGEMAKER_INSTANCE_TYPE_DETAILS: Record<
  SAGEMAKER_INSTANCE_TYPE,
  GpuInstanceType
> = {
  [SAGEMAKER_INSTANCE_TYPE.ML_G5_XLARGE]: {
    name: SAGEMAKER_INSTANCE_TYPE.ML_G5_XLARGE,
    cpu: 4,
    memory: 16,
    gpu_model: "NVIDIA A10G",
    gpu_count: 1,
    gpu_memory: 24,
  },
  [SAGEMAKER_INSTANCE_TYPE.ML_G5_2XLARGE]: {
    name: SAGEMAKER_INSTANCE_TYPE.ML_G5_2XLARGE,
    cpu: 8,
    memory: 32,
    gpu_model: "NVIDIA A10G",
    gpu_count: 1,
    gpu_memory: 24,
  },
  [SAGEMAKER_INSTANCE_TYPE.ML_G5_4XLARGE]: {
    name: SAGEMAKER_INSTANCE_TYPE.ML_G5_4XLARGE,
    cpu: 16,
    memory: 64,
    gpu_model: "NVIDIA A10G",
    gpu_count: 1,
    gpu_memory: 24,
  },
  [SAGEMAKER_INSTANCE_TYPE.ML_P2_XLARGE]: {
    name: SAGEMAKER_INSTANCE_TYPE.ML_P2_XLARGE,
    cpu: 4,
    memory: 61,
    gpu_model: "NVIDIA K80",
    gpu_count: 1,
    gpu_memory: 12,
  },
  [SAGEMAKER_INSTANCE_TYPE.ML_G4DN_XLARGE]: {
    name: SAGEMAKER_INSTANCE_TYPE.ML_G4DN_XLARGE,
    cpu: 4,
    memory: 16,
    gpu_model: "NVIDIA T4",
    gpu_count: 1,
    gpu_memory: 16,
  },
  [SAGEMAKER_INSTANCE_TYPE.ML_G4DN_2XLARGE]: {
    name: SAGEMAKER_INSTANCE_TYPE.ML_G4DN_2XLARGE,
    cpu: 8,
    memory: 32,
    gpu_model: "NVIDIA T4",
    gpu_count: 1,
    gpu_memory: 16,
  },
  [SAGEMAKER_INSTANCE_TYPE.ML_G4DN_4XLARGE]: {
    name: SAGEMAKER_INSTANCE_TYPE.ML_G4DN_4XLARGE,
    cpu: 16,
    memory: 64,
    gpu_model: "NVIDIA T4",
    gpu_count: 1,
    gpu_memory: 16,
  },
  [SAGEMAKER_INSTANCE_TYPE.ML_P3_2XLARGE]: {
    name: SAGEMAKER_INSTANCE_TYPE.ML_P3_2XLARGE,
    cpu: 8,
    memory: 61,
    gpu_model: "NVIDIA V100",
    gpu_count: 1,
    gpu_memory: 16,
  },
};

// A list of block types that have code
export const CODE_BLOCK_TYPES = Object.freeze([
  BLOCK_TYPE.PYTHON,
  BLOCK_TYPE.R,
  BLOCK_TYPE.SQL,
  BLOCK_TYPE.MARKDOWN,
  BLOCK_TYPE.GEN_AI,
  BLOCK_TYPE.BEDROCK,
  BLOCK_TYPE.RMARKDOWN,
  BLOCK_TYPE.ENDPOINT_INVOCATION,
  BLOCK_TYPE.SNOWPARK,
  BLOCK_TYPE.AGGREGATOR,
]);

// a list of block types that have enabled cloud compute settings
export const COMPUTE_SETTINGS_ENABLED_BLOCK_TYPES = Object.freeze([
  BLOCK_TYPE.PYTHON,
  BLOCK_TYPE.R,
  BLOCK_TYPE.SQL,
  BLOCK_TYPE.GEN_AI,
  BLOCK_TYPE.RMARKDOWN,
  BLOCK_TYPE.SNOWPARK,
  BLOCK_TYPE.AGGREGATOR,
]);

// A list of block types that support live logs
export const LIVE_LOGS_BLOCK_TYPES = Object.freeze([
  BLOCK_TYPE.PYTHON,
  BLOCK_TYPE.R,
]);

const GENERAL_ALLOWED_BLOCK_TYPE_INPUTS = Object.freeze([
  ...CODE_BLOCK_TYPES,
  BLOCK_TYPE.API_ROUTE,
  BLOCK_TYPE.SAGEMAKER_ENDPOINT,
]);
const GENERAL_ALLOWED_BLOCK_TYPE_OUTPUTS = Object.freeze([
  ...CODE_BLOCK_TYPES,
  BLOCK_TYPE.AUTOML,
]);

export const BLOCK_DETAILS = Object.freeze({
  // service blocks
  [BLOCK_TYPE.PLACEHOLDER]: Object.freeze({
    name: null,
    language: "plaintext",
    placeholderText: "",
    Icon: null,
    allowedInputs: [],
    allowedOutputs: [],
    canCopy: false,
    canEditName: false,
    canFullScreen: false,
    canRun: false,
    canDelete: false,
    showBlockStatusColor: false,
    hotkey: null,
    editorOptions: null,
  }),
  [BLOCK_TYPE.GROUP]: Object.freeze({
    name: null,
    language: "plaintext",
    placeholderText: "",
    Icon: null,
    allowedInputs: [],
    allowedOutputs: [],
    canCopy: false,
    canEditName: false,
    canFullScreen: false,
    canRun: false,
    canDelete: false,
    showBlockStatusColor: false,
    hotkey: null,
    editorOptions: null,
  }),
  // actual blocks
  [BLOCK_TYPE.PYTHON]: Object.freeze({
    name: "Python",
    language: "python",
    placeholderText: "",
    Icon: Python,
    allowedInputs: GENERAL_ALLOWED_BLOCK_TYPE_INPUTS,
    allowedOutputs: GENERAL_ALLOWED_BLOCK_TYPE_OUTPUTS,
    canCopy: true,
    canEditName: true,
    canFullScreen: true,
    canRun: true,
    canDelete: true,
    showBlockStatusColor: true,
    hotkey: "P",
    editorOptions: null,
  }),
  [BLOCK_TYPE.R]: Object.freeze({
    name: "R",
    language: "r",
    placeholderText: "",
    Icon: R,
    allowedInputs: GENERAL_ALLOWED_BLOCK_TYPE_INPUTS,
    allowedOutputs: GENERAL_ALLOWED_BLOCK_TYPE_OUTPUTS,
    canCopy: true,
    canEditName: true,
    canFullScreen: true,
    canRun: true,
    canDelete: true,
    showBlockStatusColor: true,
    hotkey: "R",
    editorOptions: null,
  }),
  [BLOCK_TYPE.RMARKDOWN]: Object.freeze({
    name: "R Markdown",
    language: "markdown",
    placeholderText: "",
    Icon: GiFeather,
    allowedInputs: GENERAL_ALLOWED_BLOCK_TYPE_INPUTS,
    allowedOutputs: GENERAL_ALLOWED_BLOCK_TYPE_OUTPUTS,
    canCopy: true,
    canEditName: true,
    canFullScreen: true,
    canRun: true,
    canDelete: true,
    showBlockStatusColor: true,
    hotkey: null,
    editorOptions: null,
  }),
  [BLOCK_TYPE.SQL]: Object.freeze({
    name: "SQL",
    language: "sql",
    placeholderText: "SELECT * FROM table",
    Icon: SQL,
    allowedInputs: GENERAL_ALLOWED_BLOCK_TYPE_INPUTS,
    allowedOutputs: GENERAL_ALLOWED_BLOCK_TYPE_OUTPUTS,
    canCopy: true,
    canEditName: true,
    canFullScreen: true,
    canRun: true,
    canDelete: true,
    showBlockStatusColor: true,
    hotkey: "S",
    editorOptions: null,
  }),
  [BLOCK_TYPE.MARKDOWN]: Object.freeze({
    name: "Markdown",
    language: "markdown",
    placeholderText: "Type your Markdown here (this is not a code block)...",
    Icon: Markdown,
    allowedInputs: GENERAL_ALLOWED_BLOCK_TYPE_INPUTS,
    allowedOutputs: GENERAL_ALLOWED_BLOCK_TYPE_OUTPUTS,
    canCopy: true,
    canEditName: true,
    canFullScreen: true,
    canRun: false,
    canDelete: true,
    showBlockStatusColor: false,
    hotkey: "M",
    editorOptions: null,
  }),
  [BLOCK_TYPE.AUTOML]: Object.freeze({
    name: "AutoML",
    language: "plaintext",
    placeholderText: "",
    Icon: AutoML,
    allowedInputs: GENERAL_ALLOWED_BLOCK_TYPE_INPUTS,
    allowedOutputs: Object.freeze([]), // no outputs allowed
    canCopy: true,
    canEditName: true,
    canFullScreen: false, // settings open full screen
    canRun: false,
    canDelete: true,
    showBlockStatusColor: true,
    hotkey: null,
    editorOptions: null,
  }),
  [BLOCK_TYPE.API_CONTROLLER]: Object.freeze({
    name: "API Controller",
    language: "plaintext",
    placeholderText: "",
    Icon: ApiController,
    allowedInputs: Object.freeze([]), // no inputs allowed
    allowedOutputs: Object.freeze([BLOCK_TYPE.API_ROUTE]),
    canCopy: false,
    // https://linear.app/zerve-ai/issue/FRO-929/remove-ability-to-rename-api-controller-blocker-in-layers-nav
    canEditName: false,
    canFullScreen: false, // settings open full screen
    canRun: false,
    canDelete: false,
    showBlockStatusColor: false,
    hotkey: null,
    editorOptions: null,
  }),
  [BLOCK_TYPE.API_ROUTE]: Object.freeze({
    name: "API Route",
    language: "plaintext",
    placeholderText: "",
    Icon: ApiRoute,
    allowedInputs: Object.freeze([BLOCK_TYPE.API_CONTROLLER]),
    allowedOutputs: GENERAL_ALLOWED_BLOCK_TYPE_OUTPUTS,
    canCopy: true,
    canEditName: true,
    canFullScreen: false, // settings open full screen
    canRun: false,
    canDelete: true,
    showBlockStatusColor: true,
    hotkey: null,
    editorOptions: null,
  }),
  [BLOCK_TYPE.GEN_AI]: Object.freeze({
    name: "Bedrock Prompt",
    language: "plaintext",
    placeholderText: "Type a prompt...",
    Icon: IoChatbubbleEllipsesOutline,
    allowedInputs: GENERAL_ALLOWED_BLOCK_TYPE_INPUTS,
    allowedOutputs: GENERAL_ALLOWED_BLOCK_TYPE_OUTPUTS,
    canCopy: true,
    canEditName: true,
    canFullScreen: true,
    canRun: true,
    canDelete: true,
    showBlockStatusColor: true,
    hotkey: null,
    editorOptions: Object.freeze({
      wordWrap: true,
      lineNumbers: false,
    }),
  }),
  [BLOCK_TYPE.BEDROCK]: Object.freeze({
    name: "Bedrock Fine Tuning",
    language: "json",
    placeholderText: "",
    Icon: LuBrainCircuit,
    allowedInputs: GENERAL_ALLOWED_BLOCK_TYPE_OUTPUTS,
    allowedOutputs: GENERAL_ALLOWED_BLOCK_TYPE_OUTPUTS,
    canCopy: true,
    canEditName: true,
    canFullScreen: true,
    canRun: true,
    canDelete: true,
    showBlockStatusColor: true,
    hotkey: null,
    editorOptions: null,
  }),
  [BLOCK_TYPE.SAGEMAKER_ENDPOINT]: Object.freeze({
    name: "SageMaker Endpoint",
    language: "plaintext",
    placeholderText: "",
    Icon: SageMakerIcon,
    allowedInputs: Object.freeze([]), // no inputs allowed
    allowedOutputs: Object.freeze([BLOCK_TYPE.PYTHON]),
    canCopy: false,
    canEditName: false,
    canFullScreen: false,
    canRun: true,
    canDelete: false,
    showBlockStatusColor: true,
    hotkey: null,
    editorOptions: null,
  }),
  [BLOCK_TYPE.ENDPOINT_INVOCATION]: Object.freeze({
    name: "Endpoint Invocation",
    language: "json",
    placeholderText: "",
    Icon: BiCloudLightning,
    allowedInputs: GENERAL_ALLOWED_BLOCK_TYPE_OUTPUTS,
    allowedOutputs: GENERAL_ALLOWED_BLOCK_TYPE_OUTPUTS,
    canCopy: true,
    canEditName: true,
    canFullScreen: true,
    canRun: true,
    canDelete: true,
    showBlockStatusColor: true,
    hotkey: null,
    editorOptions: null,
  }),
  [BLOCK_TYPE.SNOWPARK]: Object.freeze({
    name: "Snowpark",
    language: "python",
    placeholderText: "session.sql('SELECT * FROM table').show()",
    Icon: SnowflakeIcon,
    allowedInputs: GENERAL_ALLOWED_BLOCK_TYPE_INPUTS,
    allowedOutputs: GENERAL_ALLOWED_BLOCK_TYPE_OUTPUTS,
    canCopy: true,
    canEditName: true,
    canFullScreen: true,
    canRun: true,
    canDelete: true,
    showBlockStatusColor: true,
    hotkey: null,
    editorOptions: null,
  }),
  // actual blocks
  [BLOCK_TYPE.AGGREGATOR]: Object.freeze({
    name: "Aggregator",
    language: "python",
    placeholderText: "",
    Icon: BiCollection,
    allowedInputs: GENERAL_ALLOWED_BLOCK_TYPE_INPUTS,
    allowedOutputs: GENERAL_ALLOWED_BLOCK_TYPE_OUTPUTS,
    canCopy: true,
    canEditName: true,
    canFullScreen: true,
    canRun: true,
    canDelete: true,
    showBlockStatusColor: true,
    hotkey: null,
    editorOptions: null,
  }),
});

export enum BLOCK_STATUS {
  PENDING = 1,
  RUNNING = 2,
  COMPLETED = 3,
  ERROR = 4,
  STOPPING = 5,
}

export const BLOCK_STATUS_DETAILS = {
  [BLOCK_STATUS.PENDING]: {
    name: "Pending Run",
    color: stylesEx["statusPending-500"],
    Icon: PendingRun,
  },
  [BLOCK_STATUS.RUNNING]: {
    name: "Running",
    color: stylesEx["statusRunning-500"],
    Icon: BiLoader,
  },
  [BLOCK_STATUS.COMPLETED]: {
    name: "Completed",
    color: stylesEx["statusCompleted-500"],
    Icon: BiCheck,
  },
  [BLOCK_STATUS.ERROR]: {
    name: "Error",
    color: stylesEx["statusError-500"],
    Icon: BiErrorCircle,
  },
  [BLOCK_STATUS.STOPPING]: {
    name: "Stopping",
    color: stylesEx["statusStopping-500"],
    Icon: GiSandsOfTime,
  },
};

// in case a block is not found in the store, this data will be used
export const DEFAULT_BLOCK = Object.freeze({
  type: BLOCK_TYPE.PYTHON,
  width: 800,
  height: 500,
});

export const DEFAULT_BLOCK_DATA: BlockData = Object.freeze({
  name: "",
  layer_id: "",
  status: BLOCK_STATUS.PENDING,
  properties: Object.freeze({}),
  description: "",
  is_fargate: false,
  cpu: null,
  memory: null,
  timeCreated: "",
  outputPaneSize: 0.5,
  size: MARATHON_SIZE.MEDIUM,
  computeEnvironmentType: COMPUTE_ENV_TYPE.SPRINT,
  gpuInstanceType: null,
  last_run_execution_time: 0.0,
  current_run_execution_time_start: null,
  fleet_id: null,
  slice_ids: [],
});

export enum LAYER_TYPE {
  DEVELOPMENT = 1,
  API = 2,
  SLIDES = 3,
  APPS = 4,
  SCHEDULED_JOBS = 5,
  SAGEMAKER = 6,
}

export const LAYER_DETAILS: Record<
  LAYER_TYPE,
  {
    name: string;
    Icon: IconType;
    description: string;
    docLink?: string;
    requiresFeatureFlag?: string;
  }
> = {
  [LAYER_TYPE.DEVELOPMENT]: {
    name: "Development",
    Icon: BiCodeCurly,
    description: "Explore data, train models, and create visualizations.",
  },
  [LAYER_TYPE.API]: {
    name: "API",
    Icon: BiGlobe,
    description:
      "Build and deploy APIs faster by using the same code, environment and variables you produced in the development layer.",
    docLink:
      "https://docs.zerve.ai/guide/the-zerve-canvas/layers-overview/deployment",
  },
  [LAYER_TYPE.SLIDES]: {
    name: "Slides",
    Icon: BiImage,
    description:
      "Design repeatable slideshows based on your development layers",
  },
  [LAYER_TYPE.APPS]: {
    name: "Apps",
    Icon: BiWindows,
    description: "Design and manage browser-based applications",
  },
  [LAYER_TYPE.SCHEDULED_JOBS]: {
    name: "Scheduled Jobs",
    Icon: BiTime,
    description:
      "Schedule single and multiple block run jobs using crontab style scheduling expressions.",
    docLink:
      "https://docs.zerve.ai/guide/the-zerve-canvas/layers-overview/scheduled-jobs",
  },
  [LAYER_TYPE.SAGEMAKER]: {
    name: "AWS SageMaker Deployments",
    Icon: SageMakerIcon,
    description:
      "Turn your blocks into AWS SageMaker Endpoints ready for invocation.",
    docLink: "https://docs.zerve.ai/tbd",
    requiresFeatureFlag: "enableSageMakerDeployments",
  },
};

export const LAYER_BLOCKS = {
  [LAYER_TYPE.DEVELOPMENT]: [
    BLOCK_TYPE.PYTHON,
    BLOCK_TYPE.R,
    BLOCK_TYPE.RMARKDOWN,
    BLOCK_TYPE.MARKDOWN,
    BLOCK_TYPE.SQL,
    BLOCK_TYPE.SNOWPARK,
    BLOCK_TYPE.AUTOML,
    BLOCK_TYPE.GEN_AI,
    BLOCK_TYPE.BEDROCK,
    BLOCK_TYPE.ENDPOINT_INVOCATION,
    BLOCK_TYPE.AGGREGATOR,
  ],
  [LAYER_TYPE.API]: [
    BLOCK_TYPE.API_ROUTE,
    BLOCK_TYPE.PYTHON,
    BLOCK_TYPE.SQL,
    BLOCK_TYPE.MARKDOWN,
  ],
  [LAYER_TYPE.SLIDES]: [],
  [LAYER_TYPE.APPS]: [],
  [LAYER_TYPE.SCHEDULED_JOBS]: [
    BLOCK_TYPE.PYTHON,
    BLOCK_TYPE.R,
    BLOCK_TYPE.RMARKDOWN,
    BLOCK_TYPE.MARKDOWN,
    BLOCK_TYPE.SQL,
    BLOCK_TYPE.SNOWPARK,
    BLOCK_TYPE.GEN_AI,
    BLOCK_TYPE.BEDROCK,
    BLOCK_TYPE.ENDPOINT_INVOCATION,
    BLOCK_TYPE.AGGREGATOR,
  ],
  [LAYER_TYPE.SAGEMAKER]: [BLOCK_TYPE.PYTHON],
};

export enum LANGUAGE {
  PYTHON = "PYTHON",
  R = "R",
}
export const LANGUAGE_DETAILS = {
  [LANGUAGE.PYTHON]: { label: "Python", Icon: PythonIcon },
  [LANGUAGE.R]: { label: "R", Icon: RIcon },
};

export enum VARIABLE_TYPE {
  DATAFRAME = "DataFrame",
  DATAFRAME_R = "data.frame",
  DATAFRAME_R_TABLE = "tbl_df.tbl.data.frame",
  SERIES = "Series",
  TIBBLE = "tibble",
  FIGURE = "Figure",
  MATPLOTLIB = "matplotlib",
  PLOTLY = "plotly",
  DICT = "dict",
  LIST = "list",
  JSON = "json",
  STR = "str",
  INT = "int",
  FLOAT = "float",
  BOOL = "bool",
  NDARRAY = "ndarray",
  UNSERIALIZABLE = "unserializable",
  PICKLEDJSON = "pickled_json",
  PICKLE = "pickle",
  SKLEARN = "sklearn",
  BEDROCK_DATASET = "BedrockDataset",
  GG_PLOT = "gg.ggplot",
  TEXT = "text",
  VALUE = "value",
}

export const VARIABLE_TYPE_CONFIG = Object.freeze({
  [VARIABLE_TYPE.DATAFRAME]: {
    Icon: BiTable,
    iconStyle: null,
  },
  [VARIABLE_TYPE.DATAFRAME_R]: {
    Icon: BiTable,
    iconStyle: null,
  },
  [VARIABLE_TYPE.DATAFRAME_R_TABLE]: {
    Icon: BiTable,
    iconStyle: null,
  },
  [VARIABLE_TYPE.SERIES]: {
    Icon: BiTable,
    iconStyle: null,
  },
  [VARIABLE_TYPE.TIBBLE]: {
    Icon: BiTable,
    iconStyle: null,
  },
  [VARIABLE_TYPE.FIGURE]: {
    Icon: BiImage,
    iconStyle: null,
  },
  [VARIABLE_TYPE.MATPLOTLIB]: {
    Icon: BiChart,
    iconStyle: null,
  },
  [VARIABLE_TYPE.PLOTLY]: {
    Icon: BiChart,
    iconStyle: null,
  },
  [VARIABLE_TYPE.DICT]: {
    Icon: BiCodeCurly,
    iconStyle: null,
  },
  [VARIABLE_TYPE.JSON]: {
    Icon: BiCodeCurly,
    iconStyle: null,
  },
  [VARIABLE_TYPE.LIST]: {
    Icon: BiBracket,
    iconStyle: null,
  },
  [VARIABLE_TYPE.STR]: {
    Icon: BiText,
    iconStyle: null,
  },
  [VARIABLE_TYPE.INT]: {
    Icon: TbNumbers,
    iconStyle: null,
  },
  [VARIABLE_TYPE.FLOAT]: {
    Icon: TbNumbers,
    iconStyle: null,
  },
  [VARIABLE_TYPE.BOOL]: {
    Icon: TbCheckbox,
    iconStyle: null,
  },
  [VARIABLE_TYPE.NDARRAY]: {
    Icon: GoFileBinary,
    iconStyle: null,
  },
  [VARIABLE_TYPE.UNSERIALIZABLE]: {
    Icon: IoWarningOutline,
    iconStyle: { color: stylesEx["orange-500"] },
  },
  [VARIABLE_TYPE.PICKLEDJSON]: {
    Icon: GoFileBinary,
    iconStyle: null,
  },
  [VARIABLE_TYPE.PICKLE]: {
    Icon: GoFileBinary,
    iconStyle: null,
  },
  [VARIABLE_TYPE.SKLEARN]: {
    Icon: GoFileBinary,
    iconStyle: null,
  },
  [VARIABLE_TYPE.BEDROCK_DATASET]: {
    Icon: BiTable,
    iconStyle: null,
  },
  [VARIABLE_TYPE.GG_PLOT]: {
    Icon: BiChart,
    iconStyle: null,
  },
  [VARIABLE_TYPE.TEXT]: {
    Icon: BiText,
    iconStyle: null,
  },
  [VARIABLE_TYPE.VALUE]: {
    Icon: BiText,
    iconStyle: null,
  },
});

export const MARKDOWN_EDITOR_PADDING = {
  top: 8, // space between toolbar and editor
  bottom: 0,
};

export const SIDEBAR_DEFAULT_WIDTH = 272; // px

export enum CANVAS_PRIVACY {
  PUBLIC = "public",
  COMMUNITY = "community",
  PRIVATE = "private",
}

export enum CANVAS_PAGE_FILTERS {
  MyCanvases = "my-canvases",
  CommunityCanvases = "community-canvases",
  Starred = "starred",
  SharedWithMe = "shared-with-me",
}

export const CANVAS_PRIVACY_DETAILS = {
  [CANVAS_PRIVACY.PRIVATE]: {
    label: "Private",
    description: "Invited users only",
    Icon: BiLockAlt,
  },
  [CANVAS_PRIVACY.PUBLIC]: {
    label: "Public",
    description: "Anyone with the link",
    Icon: BiGlobe,
  },
  [CANVAS_PRIVACY.COMMUNITY]: {
    label: "Community",
    description: "Published on Zerve Community",
    Icon: BiGroup,
  },
};

export enum CANVAS_PERMISSION {
  WRITE = "write",
  READ = "read",
  PUBLIC = "public",
}

export const CANVAS_PERMISSION_LABEL = Object.freeze({
  [CANVAS_PERMISSION.WRITE]: "edit",
  [CANVAS_PERMISSION.READ]: "view",
  [CANVAS_PERMISSION.PUBLIC]: "view",
});

export enum CANVAS_LEFT_SIDEBAR {
  LAYERS = "layers",
  FILES = "files",
  REQUIREMENTS = "requirements",
  IMPORTS = "imports",
  ASSETS = "assets",
  GITHUB = "github",
  MODELS = "models",
  COLLAPSED = "collapsed",
}

export enum CANVAS_RIGHT_SIDEBAR {
  FIND = "find",
  FIND_AND_REPLACE = "find-and-replace",
  COMMENTS = "comments",
  ERROR_ASSIST = "error-assist",
  CANVAS_EXECUTOR_LOGS = "canvas-executor-logs",
}

export enum REQUIREMENTS_STATUS {
  // an executor is ready to be used
  READY = 1,

  // the docker image is being built on CodeBuild
  BUILDING = 2,

  // the executor failed to build
  ERROR = 3,

  // the executor is being initialized, this is the period
  // between the executor object being created in the database and
  // the lambda build process being started
  INITIALIZING = 4,

  // the period between the request for building the executor
  // is received and the docker build starting
  VALIDATING = 5,

  // the period when the docker image is being deployed to
  // its serverless locations
  DEPLOYING = 6,

  // not used in the backend, its added by the frontend
  // to indicate that the build requirements update has started
  SUBMITTING = 100,
  // not used in the backend, its added by the frontend
  // to indicate that the build requirements reset has started
  RESETTING = 101,
}

// The list of requirements statuses that indicate the ongoing building process
export const REQUIREMENTS_BUILDING_STATUSES = Object.freeze([
  REQUIREMENTS_STATUS.SUBMITTING,
  REQUIREMENTS_STATUS.BUILDING,
  REQUIREMENTS_STATUS.INITIALIZING,
  REQUIREMENTS_STATUS.VALIDATING,
  REQUIREMENTS_STATUS.DEPLOYING,
]);

export const REQUIREMENTS_BUILDING_PROGRESS = Object.freeze({
  [REQUIREMENTS_STATUS.SUBMITTING]: {
    progress: 0,
    label: "Submitting",
  },
  // SUBMITTING -> INITIALIZING = 1-2 seconds (time to get the message from the websocket)
  [REQUIREMENTS_STATUS.INITIALIZING]: {
    progress: 5,
    label: "Initializing",
  },
  // INITIALIZING -> VALIDATING = 1-2 seconds (should actually be almost instant)
  [REQUIREMENTS_STATUS.VALIDATING]: {
    progress: 10,
    label: "Validating",
  },
  // VALIDATING -> BUILDING = 5 seconds (just the amount of time it takes the message to be picked up by exeutor service)
  [REQUIREMENTS_STATUS.BUILDING]: {
    progress: 20,
    label: "Building",
  },
  // BUILDING -> DEPLOYING = 3-4 minutes (this is the biggest variable, could be 7-8 minutes for a really large package)
  [REQUIREMENTS_STATUS.DEPLOYING]: {
    progress: 80,
    label: "Deploying",
  },
  // DEPLOYING -> READY = 2 minutes (depends on AWS API)
  [REQUIREMENTS_STATUS.READY]: {
    progress: 100,
    label: "Ready",
  },
  [REQUIREMENTS_STATUS.ERROR]: {
    progress: 100,
    label: "Error",
  },
  [REQUIREMENTS_STATUS.RESETTING]: {
    progress: 0,
    label: "Resetting",
  },
});

// The list of requirements statuses that can have logs
export const REQUIREMENTS_BUILD_STATUSES_WITH_LOGS = Object.freeze([
  REQUIREMENTS_STATUS.BUILDING,
  REQUIREMENTS_STATUS.INITIALIZING,
  REQUIREMENTS_STATUS.VALIDATING,
  REQUIREMENTS_STATUS.DEPLOYING,
]);

// The list of requirements statuses that indicate the build has been completed
export const REQUIREMENTS_BUILD_COMPLETED_STATUSES = Object.freeze([
  REQUIREMENTS_STATUS.READY,
  REQUIREMENTS_STATUS.ERROR,
]);

export const LAYER_ID_QUERY_PARAM = "layer_id";
export const BLOCK_ID_QUERY_PARAM = "block_id";
export const LEFT_SIDEBAR_QUERY_PARAM = "left_sidebar";

export enum LOG_START_TIME {
  FIVE = 5,
  FIFTEEN = 15,
  HOUR = 60,
  DAY = 1440,
}

export enum LAYER_DISABILITY_REASON {
  CANVAS_READONLY = "canvasReadOnly",
  SCHEDULED_JOB_ACTIVATED = "scheduledJobActivated",
}

export enum BLOCKS_RUN_DISABILITY_REASON {
  USER_TIER = "userSubscriptionTier",
}

export const CANVAS_WRAPPER_ID = "canvasWrapper";
