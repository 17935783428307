import TextField from "components/common/TextField/TextField";
import { Button } from "components/common/Button/Button";
import Typography from "components/Typography";
import styles from "./CreateGroupModal.module.scss";
import { useNavigate } from "react-router-dom";
import { postGroup } from "api/http/group-service";
import { type PropsWithModal } from "hooks/useModal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { type Group } from "pages/OrganizationHome/types";

type CreateGroupModalProps = PropsWithModal<{
  organizationId: string;
  groups?: Group[];
}>;

export const CreateGroupModal = ({
  organizationId,
  groups,
  onModalClose,
}: CreateGroupModalProps) => {
  const navigate = useNavigate();

  const onCancel = () => {
    onModalClose();
  };

  const handleSubmit = (values) => {
    postGroup(organizationId, {
      organization_id: organizationId,
      name: values.groupName,
    })
      .then((returnedValues) => {
        const { id = "" }: { id: string } = returnedValues;
        onModalClose();
        navigate(`/org/${organizationId}/groups/${id}/settings`);
      })
      .catch((error) => {
        if (error.cause) {
          formik.setErrors(error.cause);
        }
      });
  };

  const formik = useFormik({
    initialValues: {
      groupName: "",
    },
    validationSchema: Yup.object({
      groupName: Yup.string()
        .matches(
          /^[a-zA-Z0-9-_ ]+$/,
          "Only alphanumeric characters, spaces, underscores and dashes are allowed"
        )
        .max(64, "Must be 64 characters or less")
        .required("Group name is required")
        .test("unique-name", "Group name must be unique", function (value) {
          if (!value) {
            return true;
          }

          // Convert the input value to lowercase for case-insensitive comparison
          const lowercaseValue = value.toLowerCase();

          // Check if the name already exists in the list of existing groups
          const nameExists = groups?.some(
            (existingGroup) =>
              existingGroup.name.toLowerCase() === lowercaseValue
          );

          // Return false if the name already exists, true otherwise
          return !nameExists;
        }),
    }),
    onSubmit: handleSubmit,
  });

  return (
    <div>
      <Typography
        component="span"
        variant="body2"
        className={styles["modal__label"]}
      >
        Enter the name for your new group.
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          id="groupName"
          placeholder="Type group name here..."
          inputClassName={styles["modal__input"]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.groupName}
          error={formik.touched.groupName ? formik.errors.groupName : ""}
        />
        <div className={styles["modal__row"]}>
          <Button size="large" variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
            size="large"
            type="submit"
          >
            Create
          </Button>
        </div>
      </form>
    </div>
  );
};
