import styles from "./Layout.module.scss";
import { useMemo, useEffect } from "react";
import { useParams, Outlet } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useAuth0 } from "@auth0/auth0-react";
import { useUserSettingsState, useOrganizationsState } from "store";
import { useOrganizationsActions } from "hooks/useOrganizationsActions";
import { UserDropdown } from "components/UserDropdown/UserDropdown";
import { Breadcrumbs } from "layout/Layout";
import { DATA_LOAD_STATUS } from "config/appConfig";

export function Layout() {
  const { orgID = "", workspaceID = "" } = useParams();
  const { user: auth0User } = useAuth0();
  const avatar = useUserSettingsState((slice) => slice.profile.avatar);
  const username = useUserSettingsState((slice) => slice.profile.username);
  const organizations = useOrganizationsState((slice) => slice.organizations);
  const organizationsLoadStatus = useOrganizationsState(
    (slice) => slice.organizationsLoadStatus
  );
  const { fetchOrganizations } = useOrganizationsActions();

  const user = useMemo(() => {
    return {
      avatar: avatar || auth0User?.picture || "",
      name: username || auth0User?.nickname || "",
    };
  }, [auth0User, avatar, username]);

  useEffect(() => {
    /* Fetching Organizations which contains workspaces */
    fetchOrganizations();
  }, [orgID, workspaceID]);

  const isLoading =
    organizationsLoadStatus === DATA_LOAD_STATUS.LOADING &&
    /* Avoid showing skeleton if Organizations already listed and silently updates */
    !organizations.length;

  return (
    <main className={styles.layout}>
      <Helmet>
        <title>Zerve AI</title>
      </Helmet>

      <header className={styles.header}>
        <Breadcrumbs
          isLoading={isLoading}
          userAvatar={user.avatar}
          userName={user.name}
          organizations={organizations}
          organizationId={orgID}
          workspaceId={workspaceID}
        />
        <UserDropdown />
      </header>

      <Outlet />
    </main>
  );
}
