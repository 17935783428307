import styles from "./AssetItemsSkeletons.module.scss";
import { memo } from "react";
import cn from "classnames";
import {
  AssetItemSkeleton,
  type AssetItemSkeletonProps,
} from "./AssetItemSkeleton";

const ITEMS_DEFAULT = [{ nameWidth: 90 }, { nameWidth: 45 }, { nameWidth: 60 }];

type AssetItemsSkeletonsProps = {
  items?: AssetItemSkeletonProps[];
  containerClassName?: string;
};

export const AssetItemsSkeletons = memo(function FileRowSkeleton({
  items = [...ITEMS_DEFAULT],
  containerClassName = "",
}: AssetItemsSkeletonsProps) {
  return (
    <div className={cn(styles.container, containerClassName)}>
      {items.map((item, index) => {
        return <AssetItemSkeleton key={index} {...item} />;
      })}
    </div>
  );
});
