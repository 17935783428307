import styles from "./SortingDropdown.module.scss";
import { useState, useCallback, useMemo } from "react";
import { autoUpdate, useDismiss, useFloating } from "@floating-ui/react";
import cn from "classnames";
import { BiChevronDown } from "react-icons/bi";
import type { SORTING_COLUMN } from "config/localPreferences";
import {
  SORTING_DIRECTION,
  SORTING_COLUMN_SORTING_TYPE,
  SORTING_TYPE,
} from "config/appConfig";

import CheckmarkIcon from "components/common/ContextMenu/MenuOption/CheckmarkIcon";
import { getSortingDirectionLabel } from "utils/sort";

export type SortOptions = readonly { value: SORTING_COLUMN; label: string }[];

type SortingDropdownProps = {
  column: SORTING_COLUMN;
  direction: SORTING_DIRECTION;
  sortOptions: SortOptions;
  onChange: ({
    column,
    direction,
  }: {
    column: SORTING_COLUMN;
    direction: SORTING_DIRECTION;
  }) => void;
};

export function SortingDropdown({
  column,
  direction,
  sortOptions,
  onChange,
}: SortingDropdownProps) {
  const [open, setOpen] = useState(false);

  const { x, y, refs, strategy, context } = useFloating({
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    placement: "bottom-end",
  });

  const getButtonLabel = (column) => {
    const labelIndex = sortOptions.findIndex((i) => i.value === column);

    return sortOptions[labelIndex]?.label ?? "";
  };

  useDismiss(context, {
    escapeKey: true,
    outsidePress: true,
  });

  const handleSortingColumnChange = useCallback(
    (newColumn: SORTING_COLUMN) => {
      onChange({ column: newColumn, direction });
      setOpen(false);
    },
    [direction]
  );
  const handleSortingDirectionChange = useCallback(
    (newDirection: SORTING_DIRECTION) => {
      onChange({ direction: newDirection, column });
      setOpen(false);
    },
    [column]
  );

  const sortDirections = useMemo(() => {
    const sortingType =
      SORTING_COLUMN_SORTING_TYPE[column] || SORTING_TYPE.TEXT;

    return [
      {
        value: SORTING_DIRECTION.ASCENDING,
        label: getSortingDirectionLabel({
          sortingType,
          sortingDirection: SORTING_DIRECTION.ASCENDING,
        }),
      },
      {
        value: SORTING_DIRECTION.DESCENDING,
        label: getSortingDirectionLabel({
          sortingType,
          sortingDirection: SORTING_DIRECTION.DESCENDING,
        }),
      },
    ];
  }, [column]);

  return (
    <div>
      <span
        ref={refs.setReference}
        onClick={() => {
          setOpen((open) => !open);
        }}
        className={styles.sortingLabel}
      >
        {getButtonLabel(column)}
        <BiChevronDown size={16} />
      </span>

      {open && (
        <div
          ref={refs.setFloating}
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
          }}
          className={styles.dropdownMenu}
        >
          <h4 className={styles.categoryTitle}>Sort By</h4>

          {sortOptions.map((item) => {
            const isSelected = item.value === column;
            return (
              <div
                key={item.value}
                className={cn(styles.menuItem, {
                  [styles.checked]: isSelected,
                })}
                onClick={() => {
                  handleSortingColumnChange(item.value);
                }}
              >
                {isSelected && (
                  <CheckmarkIcon className={styles.checkIcon} size={24} />
                )}
                {item.label}
              </div>
            );
          })}
          <h4 className={styles.categoryTitle}>Order</h4>
          {sortDirections.map((directionOption) => {
            const isSelected = directionOption.value === direction;
            return (
              <div
                key={directionOption.value}
                className={cn(styles.menuItem, {
                  [styles.checked]: isSelected,
                })}
                onClick={() => {
                  handleSortingDirectionChange(directionOption.value);
                }}
              >
                {isSelected && (
                  <CheckmarkIcon className={styles.checkIcon} size={24} />
                )}
                {directionOption.label}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
