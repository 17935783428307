import { useState, useEffect, Suspense } from "react";
import { Outlet, useParams, useSearchParams } from "react-router-dom";
import { ReactFlowProvider } from "reactflow";
import useToken from "hooks/useToken";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useAuth0 } from "@auth0/auth0-react";
import { UserSignIn } from "layout/UserSignIn/UserSignIn";
import LoadingScreen from "components/common/LoadingScreen/LoadingScreen";
import {
  useAssetsState,
  useCanvasState,
  useCanvasBlocksState,
  useCanvasDeploymentState,
  useCanvasLayoutState,
  useCanvasUsersState,
  useCanvasFilesState,
  useCanvasRequirementsState,
  useCanvasGitHubState,
  useCommentsState,
  useWebsocketState,
  useUserState,
  useOrganizationsState,
} from "store";
import { useComments } from "components/comments/useComments";
import { useCanvasWebsocket } from "pages/CanvasPage/hooks/useCanvasWebsocket";
import { useCanvasLayoutSetup } from "hooks/useCanvasLayoutSetup";
import Unauthorized from "./components/Unauthroized";
import {
  BLOCK_ID_QUERY_PARAM,
  LAYER_ID_QUERY_PARAM,
  LEFT_SIDEBAR_QUERY_PARAM,
} from "config/canvasConfig";

export function CanvasAuth() {
  const { isLoading: authIsLoading, isAuthenticated } = useAuth0();
  const { hasAccessToken } = useToken();
  const userID = useUserState((slice) => slice.userID);
  const isNewUser = useUserState((slice) => slice.isNewUser);
  const ldClient = useLDClient();
  const { canvasId = "" } = useParams();
  const [, setSearchParams] = useSearchParams();

  const [ldClientIsReady, setLdClientIsReady] = useState(false);
  const [authChecked, setAuthChecked] = useState(false);

  useEffect(() => {
    if (!ldClient) {
      return;
    }
    ldClient.waitUntilReady().then(() => {
      setLdClientIsReady(true);
    });
  }, [ldClient]);

  useEffect(() => {
    if (authIsLoading) {
      return;
    }

    if (!isAuthenticated || (isAuthenticated && hasAccessToken && userID)) {
      setAuthChecked(true);
    }
  }, [authIsLoading, isAuthenticated, hasAccessToken, userID]);

  const {
    activeBlockId,
    canAccessCanvas,
    canvasLayoutIsInitialized,
    isLayoutLoading,
    selectedLayerId,
    clearStore: clearCanvasStore,
  } = useCanvasState((slice) => ({
    activeBlockId: slice.activeBlockId,
    canAccessCanvas: slice.canAccessCanvas,
    canvasLayoutIsInitialized: slice.isLayoutInitialized,
    isLayoutLoading: slice.isLayoutLoading,
    selectedLayerId: slice.selectedLayerId,
    clearStore: slice.clearStore,
  }));

  const clearCanvasBlocksStore = useCanvasBlocksState(
    (slice) => slice.clearStore
  );

  const clearCanvasSageMakerDeploymentsStore = useCanvasDeploymentState(
    (slice) => slice.clearStore
  );

  const {
    leftSidebar,
    setShowAppTour,
    clearStore: clearCanvasLayoutStore,
  } = useCanvasLayoutState((slice) => ({
    leftSidebar: slice.leftSidebar,
    setShowAppTour: slice.setShowAppTour,
    clearStore: slice.clearStore,
  }));

  const clearCanvasUsersStore = useCanvasUsersState(
    (slice) => slice.clearStore
  );

  const clearCanvasFilesStore = useCanvasFilesState(
    (slice) => slice.clearStore
  );

  const clearCanvasRequirementsStore = useCanvasRequirementsState(
    (slice) => slice.clearStore
  );

  const clearCanvasGitHubStore = useCanvasGitHubState(
    (slice) => slice.clearStore
  );

  const clearCommentsStore = useCommentsState((slice) => slice.clearStore);

  const { isInitialConnectionOpen, clearWebsocketStore } = useWebsocketState(
    (slice) => ({
      isInitialConnectionOpen: slice.isInitialConnectionOpen,
      clearWebsocketStore: slice.clearStore,
    })
  );

  const clearConnectionsAssetStore = useAssetsState(
    (slice) => slice.connectionsActions.clearStore
  );

  const clearCloudCredentials = useOrganizationsState(
    (slice) => slice.clearCloudCredentials
  );

  const { setupCanvasLayout } = useCanvasLayoutSetup();

  const { fetchCommentsData } = useComments();

  const { attemptToOpenConnection } = useCanvasWebsocket();

  // reflect active layerId/blockId/sidebar in the url query params
  useEffect(() => {
    if (!canAccessCanvas || !canvasLayoutIsInitialized) {
      return;
    }

    const updateQueryParam = (param, value) => {
      setSearchParams(
        (prev) => {
          if (!value) {
            prev.delete(param);
          } else {
            prev.set(param, value);
          }
          return prev;
        },
        { replace: true }
      );
    };

    updateQueryParam(LAYER_ID_QUERY_PARAM, selectedLayerId); // set ?layer_id=... in the url
    updateQueryParam(BLOCK_ID_QUERY_PARAM, activeBlockId); // set ?block_id=... in the url
    updateQueryParam(LEFT_SIDEBAR_QUERY_PARAM, leftSidebar); // set ?left_siebar=... in the url
  }, [
    canAccessCanvas,
    canvasLayoutIsInitialized,
    activeBlockId,
    selectedLayerId,
    leftSidebar,
  ]);

  // fetch layout and block content
  useEffect(() => {
    if (!authChecked || !ldClientIsReady) {
      return;
    }
    setupCanvasLayout({ canvasId });
  }, [canvasId, authChecked, ldClientIsReady, setupCanvasLayout]);

  // open websocket connection
  useEffect(() => {
    if (!canAccessCanvas) {
      return;
    }

    attemptToOpenConnection({ canvasId });
  }, [canAccessCanvas, canvasId, attemptToOpenConnection]);

  // fetch canvas comments
  useEffect(() => {
    if (!canAccessCanvas || !userID) {
      return;
    }
    // do not block users from accessing the canvas while comments are loading
    fetchCommentsData({ canvasId });
  }, [canAccessCanvas, userID, canvasId]);

  // open Canvas Tour if the user is new
  useEffect(() => {
    if (isNewUser) {
      setShowAppTour(true);
    }
  }, [isNewUser]);

  // cleanup store on unmount or when a new canvas is made
  useEffect(() => {
    return () => {
      clearCanvasStore();
      clearCanvasBlocksStore();
      clearCanvasFilesStore();
      clearCanvasSageMakerDeploymentsStore();
      clearCanvasLayoutStore();
      clearCanvasUsersStore();
      clearCanvasRequirementsStore();
      clearCanvasGitHubStore();
      clearCommentsStore();
      clearWebsocketStore();
      clearConnectionsAssetStore();
      clearCloudCredentials();
    };
  }, [canvasId]);

  if (canAccessCanvas === false) {
    return <Unauthorized canvasId={canvasId} />;
  }

  const renderContent = () => {
    if (authIsLoading) {
      return <LoadingScreen />;
    }

    const content =
      (isLayoutLoading && !canvasLayoutIsInitialized) ||
      !isInitialConnectionOpen ? (
        <LoadingScreen text="Connecting to canvas..." />
      ) : (
        <Suspense fallback={<LoadingScreen text="Loading..." />}>
          <Outlet />
        </Suspense>
      );

    if (isAuthenticated) {
      return <UserSignIn>{content}</UserSignIn>;
    }

    return content;
  };

  return <ReactFlowProvider>{renderContent()}</ReactFlowProvider>;
}
