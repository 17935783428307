import styles from "../Forms.module.scss";
import Loader from "components/common/Loader/Loader";

const FormActionButtonProgressLoader = () => {
  return (
    <span className={styles.button_progress_loader}>
      <Loader />
    </span>
  );
};

export default FormActionButtonProgressLoader;
