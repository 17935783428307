import styles from "./PersonRowSkeleton.module.scss";

export function PersonRowSkeleton() {
  return (
    <div className={styles.container}>
      <div className={styles.avatar} />
      <div className={styles.user}>
        <div className={styles.name} />
        <div className={styles.email} />
      </div>
    </div>
  );
}
