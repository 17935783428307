import stylesEx from "styles/_exports.module.scss";
import * as monaco from "monaco-editor";
import { loader } from "@monaco-editor/react";
import editorWorker from "monaco-editor/esm/vs/editor/editor.worker?worker";
import jsonWorker from "monaco-editor/esm/vs/language/json/json.worker?worker";
import canvasService from "api/http/canvas-service";
import { wait } from "utils/helpers";
import { type AI_MODEL } from "config/appConfig";

export const CODE_EDITOR_FONT_FAMILY = "IBM Plex Mono";

export const GEN_AI_CODE_EDITOR_CONFIG = {
  wordWrap: true,
  lineNumbers: false,
};

const CODE_COMPLETE_DEBOUNCE_TIME = 300;

async function fetchSuggestions(
  code: string,
  aiModel: AI_MODEL,
  language: string
) {
  if (!code) {
    return {
      label: "",
      insertText: "",
    };
  }
  const suggestions =
    language === "markdown"
      ? await canvasService.postTextAutoComplete(code, aiModel)
      : await canvasService.postCodeAutoComplete(code, aiModel, language);
  return {
    label: suggestions.suggestion,
    insertText: suggestions.suggestion,
  };
}

// regular debouncing using useDebouncedCallback or useDebounce does not work for inline completions
// it prevents the inline completions from showing correctly to get around this we follow the suggested
// approach from the monaco editor documentation and use a sleep and check the cancellation token
// https://github.com/microsoft/monaco-editor/issues/4119
export function createInlineCompletionProvider(
  aiModel: AI_MODEL,
  language: string
) {
  return {
    provideInlineCompletions: async (model, position, context, token) => {
      // debounce the request to prevent spamming the backend
      await wait(CODE_COMPLETE_DEBOUNCE_TIME);
      // check cancellation token and return if it's cancelled to prevent unnecessary requests
      if (token.isCancellationRequested) {
        return;
      }
      const code = model.getValue();
      const suggestion = await fetchSuggestions(code, aiModel, language);
      return {
        items: [
          {
            range: new monaco.Range(
              position.lineNumber,
              position.column + 1,
              position.lineNumber,
              position.column
            ),
            text: suggestion.label,
            insertText: suggestion.insertText,
          },
        ],
      };
    },
    // This is a no-op function to prevent the inline completions from showing when the editor is not focused
    // This is necessary otherwise it will return an error in the console if removed
    freeInlineCompletions() {},
  };
}

export function configureMonacoEditor() {
  // See: https://linear.app/zerve-ai/issue/FRO-1308/error-unexpected-usage
  self.MonacoEnvironment = {
    getWorker(_, label) {
      if (label === "json") {
        return new jsonWorker();
      }
      return new editorWorker();
    },
  };

  // Do not load monaco-editor from CDN
  loader.config({ monaco });

  loader.init().then((monaco) => {
    // Define custom color theme
    monaco.editor.defineTheme("zerve-dark", {
      base: "vs-dark",
      inherit: true,
      rules: [
        {
          token: "string",
          foreground: stylesEx["codeStringToken"],
        },
        {
          token: "comment",
          foreground: stylesEx["codeCommentToken"],
        },
        {
          token: "keyword",
          foreground: stylesEx["codeKeywordToken"],
        },
      ],
      colors: {
        "editor.background": stylesEx["codeEditorBackground"],
      },
    });

    // Make sure editor works fine with a custom font
    // See: https://github.com/microsoft/monaco-editor/issues/1626
    document.fonts.load(`1em ${CODE_EDITOR_FONT_FAMILY}`).then(() => {
      monaco.editor.remeasureFonts();
    });
  });
}
