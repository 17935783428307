import { type AssetItemOperationType } from "pages/Assets/types/abstractTypes";

export const FORM_FIELD = {
  name: "name",
  value: "value",
  description: "description",
  secret: "secret",
} as const;

export const CONSTANT_NAME_SKIP_VALIDATION_OPERATION_TYPES: AssetItemOperationType[] =
  ["edit", "newVersion"];

export const ERROR_MESSAGE = {
  required:
    "Please complete the required field. This will ensure that your information is accurately captured and processed.",
  maxLengthExceeded: "The text entered exceeds the maximum length",
  nameAllowedSymbols:
    "Please enter a name for this field using only letters, numbers, and underscores.",
  nameStartWithNumber:
    "Please note that constant name cannot start with a number. You can start constant name using only letters and underscores.",
} as const;

export const FIELD_MAX_SYMBOLS_COUNT = {
  [FORM_FIELD.name]: 64,
  [FORM_FIELD.description]: 1024,
};

export const getMaxSymbolsCountExceededMessage = (count: number) => {
  return `${ERROR_MESSAGE.maxLengthExceeded} (${count} characters)`;
};

export const getModalTitle = (operationType: AssetItemOperationType) => {
  switch (operationType) {
    case "create":
      return "Create Constant";
    case "edit":
      return "Edit Version";
    case "newVersion":
      return "Add New Version";
    default:
      return "";
  }
};

export const getAcceptButtonLabel = (operationType: AssetItemOperationType) => {
  switch (operationType) {
    case "create":
      return "Create";
    case "edit":
      return "Edit";
    case "newVersion":
      return "Add Version";
    default:
      return "";
  }
};
