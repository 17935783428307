import { Button } from "components/common/Button/Button";
import Typography from "components/Typography";
import { type PropsWithModal } from "hooks/useModal";
import styles from "./DowngradeBillingModal.module.scss";

type DowngradeBillingModalProps = PropsWithModal<{
  endSubscriptionDate: string | undefined;
  onDowngrade: () => void;
}>;

const DowngradeBillingModal = ({
  endSubscriptionDate,
  onDowngrade,
  onModalClose,
}: DowngradeBillingModalProps) => {
  const handleDowngrade = () => {
    onDowngrade();
    onModalClose();
  };

  return (
    <div>
      <Typography
        component="p"
        variant="body2"
        className={styles["modal__description"]}
      >
        If you downgrade, your plan will be changed to Always Free Plan on{" "}
        {endSubscriptionDate}. You will then have:
      </Typography>

      <div className={styles["modal__features"]}>
        <div className={styles["modal__features__time"]}>
          <Typography component="h1" variant="h1">
            5 hours
          </Typography>
          <Typography component="p" variant="body2">
            You will have 5 times less of compute time
          </Typography>
        </div>

        <div className={styles["modal__features__memory"]}>
          <Typography component="h1" variant="h1">
            1 GB
          </Typography>

          <Typography component="p" variant="body2">
            You will have 20 times less of Storage
          </Typography>
        </div>

        <div className={styles["modal__features__layers"]}>
          <Typography component="h1" variant="h1">
            Development
          </Typography>

          <Typography component="p" variant="body2">
            You will have only one layer
          </Typography>
        </div>
      </div>

      <div className={styles["modal__buttons"]}>
        <Button variant="secondary" size="large" onClick={onModalClose}>
          Cancel
        </Button>

        <Button size="large" onClick={handleDowngrade}>
          Downgrade
        </Button>
      </div>
    </div>
  );
};

export default DowngradeBillingModal;
