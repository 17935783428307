/* 
This is a maintainence page that shows a message to the user that the site is under maintainence.
It also shows a link to the status page and a link to the discord and support email.
*/

import styles from "./MaintenancePage.module.scss";
import Typography from "components/Typography/Typography";
import MaintenanceImage from "assets/images/maintenance.png";

const STATUS_PAGE_URL = "https://zerve-ai-ltd.betteruptime.com/";
const DISCORD_URL = "https://discord.gg/AMF7rrs3hP";
const SUPPORT_EMAIL = "mailto:support@zerve.ai";

export const MaintenancePage = () => {
  return (
    <div className={styles.container}>
      <img src={MaintenanceImage} className={styles.image} alt="maintenance" />
      <Typography variant="h1">Sorry, we are under maintenance</Typography>
      <Typography variant="body1">
        We will be back shortly. Please check our{" "}
        <a href={STATUS_PAGE_URL} rel="noreferrer" target="_blank">
          status page
        </a>{" "}
        for updates.
      </Typography>
      <Typography variant="body1">
        If you have any questions, please reach out to us on{" "}
        <a href={DISCORD_URL} rel="noreferrer" target="_blank">
          Discord
        </a>{" "}
        or email us at{" "}
        <a href={SUPPORT_EMAIL} rel="noreferrer" target="_blank">
          support@zerve.ai
        </a>
        .
      </Typography>
    </div>
  );
};
