import styles from "./ImportsMenu.module.scss";
import { useState, useMemo, useCallback } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useLocalCanvasPreferencesState } from "store";
import MenuHeader from "components/common/MenuHeader/MenuHeader";
import SideNavContent from "components/common/SideNavContent/SideNavContent";
import {
  SavingStatusIndicator,
  SAVING_STATUS,
} from "components/common/SavingStatusIndicator/SavingStatusIndicator";
import SidebarAccordion from "components/SidebarAccordion/SidebarAccordion";
import { CANVASES_GLOBAL_IMPORTS_SECTION } from "config/localPreferences";
import { LANGUAGE } from "config/canvasConfig";
import { GlobalImports } from "./GlobalImports/GlobalImports";

const PYTHON_GLOBAL_IMPORTS_PLACEHOLDER = `Global Python Code
e.g.
import pandas as pd
import numpy as np
`;

const R_GLOBAL_IMPORTS_PLACEHOLDER = `Global R Code
e.g.
library(dplyr)
library(ggplot2)
`;

export default function ImportsMenu() {
  const { enableRLanguage } = useFlags();

  const { canvasGlobalImportsSections, setCanvasGlobalImportsSections } =
    useLocalCanvasPreferencesState((slice) => ({
      canvasGlobalImportsSections: slice.canvasGlobalImportsSections,
      setCanvasGlobalImportsSections: slice.setCanvasGlobalImportsSections,
    }));

  const [pythonSavingStatus, setPythonSavingStatus] = useState(
    SAVING_STATUS.IDLE
  );
  const [rSavingStatus, setRSavingStatus] = useState(SAVING_STATUS.IDLE);

  const activeSections = useMemo(
    () => new Set(canvasGlobalImportsSections),
    [canvasGlobalImportsSections]
  );

  const handleToggleSection = useCallback(
    (id: string) => {
      const sectionId = id as CANVASES_GLOBAL_IMPORTS_SECTION;
      const newSet = new Set(activeSections);
      if (newSet.has(sectionId)) {
        newSet.delete(sectionId);
      } else {
        newSet.add(sectionId);
      }
      setCanvasGlobalImportsSections([...newSet]);
    },
    [activeSections]
  );

  const handleChangePythonSavingStatus = useCallback(
    (status: SAVING_STATUS) => {
      setPythonSavingStatus(status);
    },
    []
  );

  const handleChangeRSavingStatus = useCallback((status: SAVING_STATUS) => {
    setRSavingStatus(status);
  }, []);

  return (
    <SideNavContent>
      <MenuHeader
        title="Global Imports"
        description="Global imports are available to every block. This makes sure your core pacakges are always available and easy to manage."
        borderBottom={true}
      />

      {!enableRLanguage ? (
        <GlobalImports
          language={LANGUAGE.PYTHON}
          placeholderText={PYTHON_GLOBAL_IMPORTS_PLACEHOLDER}
          savingStatus={pythonSavingStatus}
          onSavingStatusChange={handleChangePythonSavingStatus}
        />
      ) : (
        <SidebarAccordion
          activeIds={activeSections}
          onToggleId={handleToggleSection}
        >
          {/* Python Imports Sections */}
          <SidebarAccordion.Item id={CANVASES_GLOBAL_IMPORTS_SECTION.PYTHON}>
            <SidebarAccordion.Header>
              <span className={styles.accordionHeaderLabel}>Python</span>
              <SavingStatusIndicator
                status={pythonSavingStatus}
                className={styles.savingStatusIndicator}
              />
            </SidebarAccordion.Header>
            <SidebarAccordion.Body>
              <GlobalImports
                language={LANGUAGE.PYTHON}
                placeholderText={PYTHON_GLOBAL_IMPORTS_PLACEHOLDER}
                savingStatus={pythonSavingStatus}
                onSavingStatusChange={handleChangePythonSavingStatus}
              />
            </SidebarAccordion.Body>
          </SidebarAccordion.Item>

          {/* R Imports Sections */}
          <SidebarAccordion.Item id={CANVASES_GLOBAL_IMPORTS_SECTION.R}>
            <SidebarAccordion.Header>
              <span className={styles.accordionHeaderLabel}>R</span>
              <SavingStatusIndicator
                status={rSavingStatus}
                className={styles.savingStatusIndicator}
              />
            </SidebarAccordion.Header>
            <SidebarAccordion.Body>
              <GlobalImports
                language={LANGUAGE.R}
                placeholderText={R_GLOBAL_IMPORTS_PLACEHOLDER}
                savingStatus={rSavingStatus}
                onSavingStatusChange={handleChangeRSavingStatus}
              />
            </SidebarAccordion.Body>
          </SidebarAccordion.Item>
        </SidebarAccordion>
      )}
    </SideNavContent>
  );
}
