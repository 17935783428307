import styles from "./PageHeader.module.scss";
import { Button } from "components/common/Button/Button";
import Typography from "components/Typography/Typography";

type PageHeaderProps = {
  handleBuild: (...args) => void;
  handleCreateEnvironment: (...args) => void;
};

const PageHeader = ({
  handleCreateEnvironment,
  handleBuild,
}: PageHeaderProps) => {
  return (
    <header className={styles.header}>
      <div>
        <Typography component="h3" variant="h3">
          Environments
        </Typography>
        <Typography
          component="p"
          className={styles["header__subtitle"]}
          variant="caption2"
        >
          Requirements, global imports, and assets that can be used to quickly
          get started in a new canvas.
        </Typography>
      </div>

      <div className={styles["header__buttons-block"]}>
        <Button variant="secondary" onClick={handleCreateEnvironment}>
          Create Environment
        </Button>
        <Button variant="primary" onClick={handleBuild}>
          Build
        </Button>
      </div>
    </header>
  );
};

export default PageHeader;
