import { useAssetsState, useToastsState } from "store";
import { putConstantVersionSecret } from "api/http/assets-service";

interface UseConstantDataInterface {
  setAssetVersionIsSecret: (versionId: string) => void;
}

export const useConstantData = (): UseConstantDataInterface => {
  const updateConstantVersion = useAssetsState(
    (slice) => slice.constantsActions.updateConstantVersion
  );

  const addToast = useToastsState((slice) => slice.addToast);

  const setAssetVersionIsSecret = (versionId: string): void => {
    void putConstantVersionSecret(versionId)
      .then((res) => {
        updateConstantVersion(res);
        addToast({
          message: "Success! Your constant has been made secret",
          variant: "success",
        });
      })
      .catch((err) => {
        console.error(err);
        addToast({
          message: "Failed to make the constant secret",
          variant: "error",
        });
      });
  };

  return { setAssetVersionIsSecret };
};
