import type { CoordinateExtent, NodeOrigin } from "reactflow";

export const NODE_MIN_HEIGHT = 100;
export const NODE_MIN_WIDTH = 100;
export const NODE_MAX_HEIGHT = 6400;
export const NODE_MAX_WIDTH = 6400;

// We should allow only coordinates in range -2147483647 to 2147483647 in order to be able to save them in the database
// https://linear.app/zerve-ai/issue/FRO-1418/set-canvas-extents
const NODE_MAX_COORD = 2147483647 - 1;

export const NODE_MIN_COORD_X = -1 * NODE_MAX_COORD;
export const NODE_MAX_COORD_X = NODE_MAX_COORD;
export const NODE_MIN_COORD_Y = -1 * NODE_MAX_COORD;
export const NODE_MAX_COORD_Y = NODE_MAX_COORD;

export const CANVAS_NODE_EXTENT: CoordinateExtent = [
  [NODE_MIN_COORD_Y, NODE_MIN_COORD_X],
  [NODE_MAX_COORD_Y, NODE_MAX_COORD_X],
];

const CANVAS_TRANSLATE_PADDING = 64;

export const CANVAS_TRANSLATE_EXTENT: CoordinateExtent = [
  [
    NODE_MIN_COORD_Y - CANVAS_TRANSLATE_PADDING,
    NODE_MIN_COORD_X - CANVAS_TRANSLATE_PADDING,
  ],
  [
    NODE_MAX_COORD_Y + NODE_MAX_HEIGHT + CANVAS_TRANSLATE_PADDING,
    NODE_MAX_COORD_X + NODE_MAX_WIDTH + CANVAS_TRANSLATE_PADDING,
  ],
];

export const CANVAS_NODE_ORIGIN: NodeOrigin = [0, 0];
