import { type ReactNode } from "react";

// the key used in row objects to store the index in the original data list
export const indexKey = Symbol("index");

// the key used in row objects to indicate that they have been edited after being added
export const touchedKey = Symbol("touched");

// the key used in row objects to store row original columns data
export const originalRowDataKey = Symbol("originalRowData");

// the key used in row objects to store current columns data
export const displayColumnsDataKey = Symbol("displayColumnsData");

// the key used in row objects to store commited (data to be saved or rejected if invalid) columns data
export const committedColumnsDataKey = Symbol("committedColumnsData");

// the key used in row objects to specify row type
export const rowTypeKey = Symbol("rowType");

export type Column<Key extends string> = {
  label: ReactNode;
  key: Key;
  searchable?: boolean;
  sortable?: boolean;
  placeholder?: string;
};

export enum TABLE_ROW_TYPE {
  CREATED_ROW = "createdRow",
  EDITED_ROW = "editedRow",
}

export type ColumnError = { message: string; dependencies?: string[] } | null;

export type ColumnsErrorData<ColumnKey extends string> = Record<
  ColumnKey,
  ColumnError
> | null;

export type BaseTableRow<Row, ColumnKey extends string> = {
  [indexKey]: string;
  [displayColumnsDataKey]: Record<ColumnKey, string>;
  [originalRowDataKey]: Row;
  [committedColumnsDataKey]?: Record<ColumnKey, string> | null;
  [rowTypeKey]: TABLE_ROW_TYPE;
};

export type EnhancedRow<Row, ColumnKey extends string> = BaseTableRow<
  Row,
  ColumnKey
>;

export type AddedRow<Row, ColumnKey extends string> = BaseTableRow<
  Row,
  ColumnKey
> & {
  [touchedKey]?: boolean;
};

export type TableRow<Row, ColumnKey extends string> =
  | EnhancedRow<Row, ColumnKey>
  | AddedRow<Row, ColumnKey>;

export type MapValueType<A> = A extends Map<string, infer V> ? V : never;
