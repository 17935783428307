import styles from "./WorkspaceCanvases.module.scss";
import { useCallback, useEffect, useMemo } from "react";
import cn from "classnames";
import { useParams } from "react-router-dom";
import { useUserState, useToastsState } from "store";
import { Button } from "components/common/Button/Button";
import Spinner from "components/common/Spinner/Spinner";
import { EmptyState } from "components/common/EmptyState/EmptyState";
import { CanvasesListLayout } from "components/canvases/CanvasesListLayout/CanvasesListLayout";
import { CreateCanvasModal } from "components/modals";
import canvasService from "api/http/canvas-service";
import { useModal } from "hooks/useModal";
import { type Canvas } from "../OrganizationHome/types";
import {
  CANVASES_SORTING_COLUMN_DEFAULT,
  CANVASES_SORTING_DIRECTION_DEFAULT,
} from "store/slices/local-canvas-preferences";
import { useCanvases, SORT_OPTIONS } from "pages/Canvases/useCanvases";
import type { CanvasCardType } from "models/canvas";

export function WorkspaceCanvases() {
  const { workspaceID = "" } = useParams();

  const { openModal } = useModal();

  const userID = useUserState((slice) => slice.userID);
  const addToast = useToastsState((slice) => slice.addToast);

  const {
    searchQuery,
    noCanvases,
    notFound,
    isLoading,
    sortedItems,
    canvasesDisplayMode,
    canvasesSortingColumn,
    canvasesSortingDirection,
    processedCanvasesIdActionMap,
    selectedCanvasesIds,
    toolbar,
    canvasNameChangeHandler,
    handleCanvasClick,
    handleDeleteCanvases,
    handleChangeSorting,
    handleSearchQueryChange,
    openCanvas,
    setCanvases,
    setCanvasesDisplayMode,
    setIsLoading,
    handleOpenCanvasSettingsModal,
  } = useCanvases({ isLoadingInitial: true });

  useEffect(() => {
    !isLoading && setIsLoading(true);
    canvasService
      .getWorkspaceCanvases(workspaceID)
      .then((data: Canvas[]) => {
        setCanvases(
          data.map((item) => {
            const { id } = item;

            return {
              ...item,
              createdAt: item.time_created,
              lastModified: item.time_updated || item.time_created,
              onTitleChange: canvasNameChangeHandler(id),
            };
          })
        );
      })
      .catch(() => {
        addToast({
          message: "Error loading canvases",
          variant: "error",
        });
        setCanvases([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [workspaceID]);

  const handleGetCanvasOptions = useCallback(
    (canvas: CanvasCardType) => {
      return [
        {
          key: "open",
          type: "option" as const,
          label: "Open",
          onClick: () => {
            openCanvas(canvas.id);
          },
        },
        {
          key: "settings",
          type: "option" as const,
          label: "Settings",
          onClick: () => {
            handleOpenCanvasSettingsModal(canvas.id);
          },
        },
        {
          key: "delete",
          type: "option" as const,
          label: "Delete",
          onClick: () => {
            handleDeleteCanvases([canvas.id]);
          },
        },
      ];
    },
    [openCanvas, handleDeleteCanvases, handleOpenCanvasSettingsModal]
  );

  const handleCreateCanvas = useCallback(() => {
    if (!userID) {
      return;
    }
    openModal({
      title: "Create Canvas",
      content: ({ onModalClose }) => {
        return (
          <CreateCanvasModal
            workspaceId={workspaceID}
            onModalClose={onModalClose}
          />
        );
      },
    });
  }, [userID, workspaceID]);

  const noCanvasesPlaceholder = useMemo(() => {
    if (!noCanvases || isLoading) {
      return null;
    }

    return (
      <EmptyState
        variant="info"
        size="large"
        containerClassName={styles.placeholder}
        title="No canvases yet..."
        description={
          <>
            Canvases you will create will be here.
            <br />
            You have not created any canvases yet.
          </>
        }
      >
        <Button size="large" variant="text" onClick={handleCreateCanvas}>
          Create Canvas
        </Button>
      </EmptyState>
    );
  }, [isLoading, noCanvases, handleCreateCanvas]);

  useEffect(() => {
    if (
      !SORT_OPTIONS.some((option) => option.value === canvasesSortingColumn)
    ) {
      handleChangeSorting({
        column: CANVASES_SORTING_COLUMN_DEFAULT,
        direction: CANVASES_SORTING_DIRECTION_DEFAULT,
      });
    }
  }, [canvasesSortingColumn]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <Button
          className={styles.createCanvasButton}
          onClick={handleCreateCanvas}
        >
          Create Canvas
        </Button>
      </div>
      <div
        className={cn(styles.container, {
          [styles.container_with_toolbar]: toolbar,
        })}
      >
        <CanvasesListLayout
          topBarClassName={styles.top_bar}
          canvases={sortedItems}
          onGetCanvasOptions={handleGetCanvasOptions}
          noCanvases={noCanvases}
          noCanvasesPlaceholder={noCanvasesPlaceholder}
          notFound={notFound}
          selectedCanvasesIds={selectedCanvasesIds}
          processedCanvasesIdActionMap={processedCanvasesIdActionMap}
          searchQuery={searchQuery}
          sortingColumn={canvasesSortingColumn}
          sortingDirection={canvasesSortingDirection}
          sortOptions={SORT_OPTIONS}
          displayMode={canvasesDisplayMode}
          toolbar={toolbar}
          onSearchQueryChange={handleSearchQueryChange}
          onChangeSorting={handleChangeSorting}
          setDisplayMode={setCanvasesDisplayMode}
          onCanvasClick={handleCanvasClick}
        />
      </div>
    </div>
  );
}
