import {
  createContext,
  useState,
  useEffect,
  useMemo,
  type ReactNode,
} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { stopSession } from "context/Session";
import { AUTH0_TOKEN_REFRESH_INTERVAL } from "config/appConfig";

// Reference to access token. It is used by callApi to authorize requests.
export const tokenRef = { current: "" };

export const TokenContext = createContext<{
  isAccessTokenLoading: boolean;
  hasAccessToken: boolean;
} | null>(null);

export function TokenProvider({ children }: { children: ReactNode }) {
  const { isAuthenticated, isLoading, user, getAccessTokenSilently, logout } =
    useAuth0();
  const [isAccessTokenLoading, setIsAccessTokenLoading] =
    useState<boolean>(false);
  const [hasAccessToken, setHasAccessToken] = useState<boolean>(false);

  // get access token on initial load and then refresh it periodically
  useEffect(() => {
    if (!isAuthenticated) {
      tokenRef.current = "";
      setIsAccessTokenLoading(false);
      setHasAccessToken(false);
      return;
    }

    // get access token on initial load
    getAccessToken();

    // refresh access token periodically
    const intervalId = setInterval(async () => {
      getAccessToken();
    }, AUTH0_TOKEN_REFRESH_INTERVAL);

    // get access token
    async function getAccessToken() {
      try {
        const accessToken = await getAccessTokenSilently();
        tokenRef.current = accessToken;
        setIsAccessTokenLoading(false);
        setHasAccessToken(true);
      } catch (rejection: any) {
        tokenRef.current = "";
        setIsAccessTokenLoading(false);
        setHasAccessToken(false);
        clearInterval(intervalId);
        logout({ logoutParams: { returnTo: window.location.origin } });
      }
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [getAccessTokenSilently, isAuthenticated, user?.sub]);

  // stop session when user logs out
  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      stopSession();
    }
  }, [isAuthenticated, isLoading]);

  const value = useMemo(() => {
    return { isAccessTokenLoading, hasAccessToken };
  }, [isAccessTokenLoading, hasAccessToken]);

  return (
    <TokenContext.Provider value={value}>{children}</TokenContext.Provider>
  );
}
