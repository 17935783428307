import { Navigate, type RouteObject } from "react-router-dom";
import { OrganizationHome } from "pages/OrganizationHome/OrganizationHome";
import { OrganizationMembers } from "pages/OrganizationMembers/OrganizationMembers";
import { OrganizationSelfHosting } from "pages/OrganizationSelfHosting/OrganizationSelfHosting";
import { OrganizationSettings } from "pages/OrganizationSettings/OrganizationSettings";
import { OrganizationGroups } from "pages/OrganizationGroups/OrganizationGroups";
import { OrganizationRoles } from "pages/OrganizationRoles/OrganizationRoles";
import { GroupSettings } from "pages/GroupSettings/GroupSettings";
import { RoleSettings } from "pages/RoleSettings/RoleSettings";

export const ORGANIZATION_ROUTES: RouteObject[] = [
  {
    index: true,
    element: <Navigate to="workspaces" />,
  },
  {
    path: "workspaces",
    element: <OrganizationHome />,
  },
  {
    path: "self_hosting",
    element: <OrganizationSelfHosting />,
  },
  {
    path: "people",
    element: <OrganizationMembers />,
  },
  {
    path: "groups",
    element: <OrganizationGroups />,
  },
  {
    path: "groups/:groupID/settings",
    element: <GroupSettings />,
  },
  {
    path: "roles",
    element: <OrganizationRoles />,
  },
  {
    path: "roles/:roleID/settings",
    element: <RoleSettings />,
  },
  {
    path: "settings",
    element: <OrganizationSettings />,
  },
];
