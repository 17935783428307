import { useEffect, type PropsWithChildren } from "react";
import * as SentryReact from "@sentry/react";
import { useAuth0 } from "@auth0/auth0-react";
import { useUserState } from "store";
import { sentryConfig } from "config/sentryConfig";
import { ErrorFallbackMessage } from "components/ErrorFallbackMessage";

SentryReact.init(sentryConfig);

export function Sentry({ children }: PropsWithChildren) {
  const { user } = useAuth0();
  const userId = useUserState((slice) => slice.userID);

  useEffect(() => {
    if (userId && user?.email) {
      // Docs: https://docs.sentry.io/platforms/javascript/guides/react/enriching-events/identify-user/
      SentryReact.setUser({ id: userId, email: user.email });
    } else {
      SentryReact.setUser(null);
    }
  }, [userId, user?.email]);

  return (
    <SentryReact.ErrorBoundary fallback={<ErrorFallbackMessage />}>
      {children}
    </SentryReact.ErrorBoundary>
  );
}
