import { IconBase, type IconBaseProps } from "react-icons";

export default function MarathonIcon(props: IconBaseProps) {
  return (
    <IconBase viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M4.62141 4.6H7.39341L11.9694 14.544H12.0134L16.6114 4.6H19.3834V20H16.5014V10.628H16.4574L12.9154 17.844H11.0674L7.54741 10.628H7.50341V20H4.62141V4.6Z"
          fill="currentColor"
        />
      </g>
    </IconBase>
  );
}
