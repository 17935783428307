import { CANVAS_API_URL } from "config/appConfig";
import { callAPI } from "./call-api";
import type { CanvasFile, UploadFileS3PresignedInfo } from "models/canvas";

// fetch the list of files
export async function fetchFiles(canvasId: string) {
  return await callAPI<{ Key: string; Size: number; LastModified: string }[]>({
    url: `${CANVAS_API_URL}/canvas/files/${canvasId}`,
    method: "GET",
  }).then((files) => {
    return files.map((rawFile) => {
      // format files from S3
      const file: CanvasFile = {
        path: rawFile.Key,
        size: rawFile.Size,
        lastModified: rawFile.LastModified,
        uploadPercent: 100,
      };
      return file;
    });
  });
}

// get the S3 presigned URL for downloading a file
export async function getFileDownloadPresignedUrl(
  canvasId: string,
  path: string
) {
  return await callAPI({
    url: `${CANVAS_API_URL}/canvas/files/${canvasId}/${path}`,
    method: "GET",
  });
}

// get the S3 presigned URL for uploading a file
export async function getFileUploadPresignedUrl(
  canvasId: string,
  fileName: string,
  subPath?: string
) {
  const data = { file_name: fileName };
  if (subPath) {
    data["folder_subpath"] = subPath;
  }
  return await callAPI<UploadFileS3PresignedInfo>({
    url: `${CANVAS_API_URL}/canvas/files/${canvasId}`,
    method: "POST",
    data,
  });
}

// upload file to S3
export function uploadFile({
  presignedInfo,
  file,
  onProgress,
  onComplete,
  onError,
  onUploadStarted,
}: {
  presignedInfo: UploadFileS3PresignedInfo;
  file: File;
  onProgress?: (uploadPercent: number) => void;
  onComplete?: (name: string) => void;
  onError?: () => void;
  onUploadStarted?: (xhr: XMLHttpRequest) => void;
}) {
  const { url, fields } = presignedInfo;
  const xhr = new XMLHttpRequest();
  const formData = new FormData();

  // add all key value pairs from response.fields
  Object.keys(fields).forEach((key) => {
    formData.append(key, fields[key]);
  });

  // add the file
  formData.append("file", file);

  xhr.open("POST", url, true);
  xhr.upload.onprogress = (e) => {
    if (e.lengthComputable) {
      const percentComplete = (e.loaded / e.total) * 100;
      onProgress?.(percentComplete);
    }
  };
  xhr.onload = () => {
    if (xhr.status < 400) {
      if (onComplete) {
        onComplete(file.name);
      }
    } else {
      onError?.();
    }
  };
  xhr.onerror = () => {
    onError?.();
  };
  xhr.ontimeout = () => {
    // TODO: add handling for timeout
  };
  xhr.send(formData);
  onUploadStarted?.(xhr);
}

// create folder
export async function createFolder({
  canvasId,
  folderPath,
}: {
  canvasId: string;
  folderPath: string;
}) {
  return await callAPI({
    url: `${CANVAS_API_URL}/canvas/files/${canvasId}`,
    method: "POST",
    data: {
      file_name: "",
      folder_subpath: folderPath,
    },
  });
}

// delete file or folder
export async function deleteFileOrFolder(canvasId: string, path: string) {
  return await callAPI({
    url: `${CANVAS_API_URL}/canvas/s3/${canvasId}/${path}`,
    method: "DELETE",
  });
}

// move/rename file or folder
export async function moveFileOrFolder({
  canvasId,
  source,
  destination,
}: {
  canvasId: string;
  source: string;
  destination: string;
}) {
  return await callAPI({
    url: `${CANVAS_API_URL}/canvas/${canvasId}/s3/move`,
    method: "POST",
    data: { source, destination },
  });
}
