import { useCallback, useMemo } from "react";
import cn from "classnames";
import { useFormik } from "formik";
import isEmpty from "lodash-es/isEmpty";
import * as Yup from "yup";
import { Button } from "components/common/Button/Button";
import SecuredField from "components/common/SecuredField/SecuredField";
import TextField from "components/common/TextField/TextField";
import Typography from "components/Typography/Typography";
import { type ConnectionConfigurationFieldValue } from "pages/Assets/types/connectionTypes";
import useFormActions from "../useFormActions";
import FormActionButtonProgressLoader from "../FormActionButtonProgressLoader";
import {
  CONNECTION_CONFIG_FIELD,
  CONNECTION_CONFIG_FIELD_CONFIG,
  FORMS_SHOW_TEST_BUTTON,
} from "../../../constants";
import { YUP_VALIDATION_METHODS_CHAIN } from "../constants";
import styles from "../Forms.module.scss";

const FIELD_KEYS = [
  CONNECTION_CONFIG_FIELD.jdbc_url,
  CONNECTION_CONFIG_FIELD.access_token,
];

type FieldKey = (typeof FIELD_KEYS)[number];

interface ConnectionFormProps {
  id?: string;
  initialValues?: Partial<Record<FieldKey, ConnectionConfigurationFieldValue>>;
  disabled?: boolean;
}

const DatabricksForm = ({
  id,
  initialValues,
  disabled = false,
}: ConnectionFormProps) => {
  const isInitialConfig = isEmpty(initialValues);

  const formInitialValues = useMemo(() => {
    if (isInitialConfig) {
      return FIELD_KEYS.reduce((res, key) => {
        res[key] = CONNECTION_CONFIG_FIELD_CONFIG[key].defaultValue;

        return res;
      }, {});
    }

    return { ...initialValues };
  }, [initialValues, isInitialConfig]);

  const validationSchema = useMemo(() => {
    if (disabled) {
      return;
    }

    return Yup.object().shape({
      [CONNECTION_CONFIG_FIELD.jdbc_url]:
        YUP_VALIDATION_METHODS_CHAIN.stringRequiredMinMax,
      [CONNECTION_CONFIG_FIELD.access_token]: Yup.string().when([], {
        is: () => isInitialConfig,
        then: () => YUP_VALIDATION_METHODS_CHAIN.stringRequiredMinMax,
        otherwise: () => Yup.string(),
      }),
    });
  }, [disabled, isInitialConfig]);

  const {
    isSubmitActionInProgress,
    isTestActionInProgress,
    submitForm,
    testForm,
  } = useFormActions();

  const onSubmit = (values) => {
    submitForm({
      formId: id,
      values,
      formFields: FIELD_KEYS,
      onSuccess: resetForm,
      onError: setErrors,
    });
  };

  const {
    values,
    touched,
    errors,
    isValid,
    dirty,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setErrors,
    resetForm,
  } = useFormik({
    initialValues: { ...formInitialValues },
    validationSchema,
    enableReinitialize: true,
    onSubmit,
  });

  const getFieldErrorMessage = (fieldName: FieldKey) => {
    if (touched[fieldName] && errors[fieldName]) {
      return errors[fieldName];
    }
  };

  const onPasswordReset = useCallback(() => {
    setFieldValue(CONNECTION_CONFIG_FIELD.access_token, "");
  }, [setFieldValue]);

  const onTest = useCallback(() => {
    testForm(id);
  }, [id]);

  const isActionInProgress = isSubmitActionInProgress || isTestActionInProgress;
  const isActionButtonDisabled = disabled || !isValid || isActionInProgress;

  return (
    <form
      className={cn(styles.form, {
        [styles.form_action_disabled]: isActionInProgress,
      })}
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <div className={styles.fields}>
        <div className={styles.field}>
          <Typography variant="body1" className={styles.field_label}>
            JDBC URL
          </Typography>
          <div className={styles.field_value}>
            <TextField
              name={CONNECTION_CONFIG_FIELD.jdbc_url}
              placeholder="URL-link..."
              value={values[CONNECTION_CONFIG_FIELD.jdbc_url]}
              onChange={handleChange}
              onBlur={handleBlur}
              error={getFieldErrorMessage(CONNECTION_CONFIG_FIELD.jdbc_url)}
              isDisabled={disabled}
            />
          </div>
        </div>
        <div className={styles.field}>
          <Typography variant="body1" className={styles.field_label}>
            Access token
          </Typography>
          <div className={styles.field_value}>
            <SecuredField
              name={CONNECTION_CONFIG_FIELD.access_token}
              value={values[CONNECTION_CONFIG_FIELD.access_token]}
              onChange={handleChange}
              onBlur={handleBlur}
              onReset={!isInitialConfig ? onPasswordReset : undefined}
              error={getFieldErrorMessage(CONNECTION_CONFIG_FIELD.access_token)}
              showDummyTextIfEmptyValue={!isInitialConfig}
              isDisabled={disabled}
            />
          </div>
        </div>
      </div>
      <div className={styles.controls}>
        <Button
          className={cn(styles.button, styles.submit_button)}
          type="submit"
          disabled={isActionButtonDisabled || !dirty}
        >
          {isSubmitActionInProgress ? (
            <FormActionButtonProgressLoader />
          ) : (
            "Save"
          )}
        </Button>
        {!isInitialConfig && FORMS_SHOW_TEST_BUTTON && (
          <Button
            className={cn(styles.button, styles.test_button)}
            disabled={isActionButtonDisabled || dirty}
            onClick={onTest}
          >
            {isTestActionInProgress ? (
              <FormActionButtonProgressLoader />
            ) : (
              "Test"
            )}
          </Button>
        )}
      </div>
    </form>
  );
};

export default DatabricksForm;
