import { ASSET_API_URL } from "config/appConfig";
import { callAPI } from "./call-api";
import type { CloudCredentials } from "models/organization";

export const getCloudSetting = async (organizationId: string) =>
  await callAPI<CloudCredentials>({
    method: "GET",
    url: `${ASSET_API_URL}/cloud-credentials/${organizationId}`,
  });

export const postCloudSetting = async (organizationId: string, data: any) =>
  await callAPI({
    method: "POST",
    url: `${ASSET_API_URL}/cloud-credentials/${organizationId}`,
    data,
  });

export const patchCloudSetting = async (organizationId: string, data: any) =>
  await callAPI({
    method: "PATCH",
    url: `${ASSET_API_URL}/cloud-credentials/${organizationId}`,
    data,
  });

export const deleteCloudSetting = async (organizationId: string) =>
  await callAPI({
    method: "DELETE",
    url: `${ASSET_API_URL}/cloud-credentials/${organizationId}`,
  });

const cloudSettingService = {
  getCloudSetting,
  patchCloudSetting,
  postCloudSetting,
  deleteCloudSetting,
};

export default cloudSettingService;
