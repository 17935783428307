import { useCallback, useMemo } from "react";
import { BiPlusCircle } from "react-icons/bi";
import { useModal } from "hooks/useModal";
import { Breadcrumb } from "layout/Layout";
import { CreateWorkspaceModal } from "components/modals/CreateWorkspaceModal/CreateWorkspaceModal";
import type { ContextMenuItem } from "components/common/ContextMenu/ContextMenu";
import type { Organization, Workspace } from "models/organization";

type WorkspaceCrumbProps = {
  organizations: Organization[];
  organizationId: Organization["id"];
  workspaceId: Workspace["id"];
};

export function WorkspaceCrumb({
  organizations,
  workspaceId,
  organizationId,
}: WorkspaceCrumbProps) {
  const { openModal } = useModal();

  const { workspaces, workspace } = useMemo(() => {
    const workspaces =
      organizations.find((organization) => organization.id === organizationId)
        ?.workspaces || [];

    const workspace =
      workspaces.find((workspace) => workspace.id === workspaceId) || null;

    return { workspaces, workspace };
  }, [organizations, organizationId, workspaceId]);

  const handleCreateWorkspace = useCallback(() => {
    openModal({
      title: "Create Workspace",
      content: ({ onModalClose }) => (
        <CreateWorkspaceModal
          organizationId={organizationId}
          workspaces={workspaces}
          onModalClose={onModalClose}
        />
      ),
    });
  }, [openModal, organizationId, workspaces]);

  const isOrgAdmin = useMemo(() => {
    const organization = organizations.find((org) => org.id === organizationId);
    return organization?.role === "Organization Admin"; // TODO: use a proper key.
  }, [organizations, organizationId]);

  const menuOptions = useMemo(() => {
    // heading
    const list: ContextMenuItem[] = [
      { key: "heading", type: "heading", label: "Workspaces" },
    ];

    // worspaces list
    workspaces.forEach((workspace, index) => {
      list.push({
        key: workspace.id,
        type: "option",
        label: workspace.name,
        linkProps: {
          to: `/org/${organizationId}/workspaces/${workspace.id}`,
        },
        showDivider: isOrgAdmin && index === workspaces.length - 1,
      });
    });

    // Only an organization admin can create a new workspace.
    if (isOrgAdmin) {
      // create workspace
      list.push({
        key: "create-workspace",
        type: "option",
        label: "Create Workspace",
        disabled: !organizationId,
        icon: BiPlusCircle,
        onClick: handleCreateWorkspace,
      });
    }

    return list;
  }, [workspaces, organizationId, handleCreateWorkspace, isOrgAdmin]);

  return (
    <Breadcrumb
      to={`/org/${organizationId}/workspaces/${workspace?.id}`}
      menuOptions={menuOptions}
    >
      {workspace?.name}
    </Breadcrumb>
  );
}
