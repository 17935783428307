import styles from "./RequirementsBuildingProgress.module.scss";
import { ProgressBar } from "components/common/ProgressBar/ProgressBar";
import { useRequirementsBuildingProgress } from "hooks/useRequirementsBuildingProgress";

export function RequirementsBuildingProgress() {
  const { buildingProgress, label } = useRequirementsBuildingProgress();

  return (
    <div className={styles.container}>
      <div className={styles.labelsContainer}>
        <span className={styles.animatedEllipsis}>{label}</span>
        <span>{buildingProgress}%</span>
      </div>

      <ProgressBar
        containerClassName={styles.progressBar}
        value={buildingProgress}
      />
    </div>
  );
}
