import { useEffect, useState, useMemo } from "react";
import { BiLinkExternal, BiX } from "react-icons/bi";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import pluralize from "pluralize";
import { EmptyState } from "components/common/EmptyState/EmptyState";
import LabeledDropdownButton from "components/common/LabeledDropdownButton/LabeledDropdownButton";
import Search from "components/common/Search/Search";
import Table from "components/common/Table/Table";
import { Checkbox } from "components/common/Checkbox/Checkbox";
import { DropdownMenuButton } from "components/common/DropdownMenuButton/DropdownMenuButton";
import { Button } from "components/common/Button/Button";
import VersionsDropdown from "components/common/VersionsDropdown/VersionsDropdown";
import Avatar from "components/common/Avatar/Avatar";
import IconButton from "components/common/IconButton/IconButton";
import Typography from "components/Typography/Typography";
import useAssetUsage from "hooks/useAssetsUsage";
import { useToastsState } from "store";
import useMultiselection from "hooks/useMultiselection";
import { type AssetPageTabType } from "pages/Assets/types/abstractTypes";
import styles from "./ManageUsageModal.module.scss";

interface ManageUsageProps {
  assetId: string;
  assetType: AssetPageTabType;
  versionId?: string;
  workspaceID?: string;
}

const ManageUsage = ({
  assetType,
  assetId,
  versionId,
  workspaceID,
}: ManageUsageProps) => {
  const addToast = useToastsState((slice) => slice.addToast);
  const assetDropdownLabel = useMemo(() => {
    return assetType.replace(assetType[0], assetType[0].toUpperCase());
  }, [assetType]);
  const {
    assets,
    types,
    searchQuery,
    setSearchQuery,
    tableData,
    emptyState,
    selectedAsset,
    selectedVersion,
    selectedType,
    setSelectedType,
    deleteAttachedVersion,
    updateAttachedVersion,
    setSelectedAssetId,
    setSelectedVersionId,
    updateAssets,
  } = useAssetUsage(assetType, assetId, versionId);

  const {
    isSelectedAll,
    selectedElements: selectedRowIds,
    setSelectedElements: setSelectedRowIds,
    selectAll,
  } = useMultiselection<string>(tableData.map((el) => el.id));

  useEffect(() => {
    return () => {
      updateAssets(workspaceID);
    };
  }, [workspaceID]);

  useEffect(() => {
    setSelectedRowIds([]);
  }, [tableData]);

  const assetOwner = useMemo(() => {
    let assetOwnerObj;

    if (selectedVersion.label === "All") {
      assetOwnerObj = selectedAsset?.versions[1]?.owner;
    } else {
      assetOwnerObj = selectedVersion?.owner;
    }

    if (assetOwnerObj) {
      const { username, email } = assetOwnerObj;

      return username || email;
    }

    return "Unknown";
  }, [selectedAsset, selectedVersion]);

  const onChange = (e) => {
    e.stopPropagation();
    setSearchQuery(e.target.value);
  };

  const handleLinksOpen = (clientIds: string[]) => {
    // if browser block popups - only one tab will be opened
    clientIds.forEach((id) => {
      window.open(`/canvases/${id}`, "_blank", "noopener,noreferrer");
    });
  };

  const handleDropSelection = () => {
    setSelectedRowIds([]);
  };

  const handleDeleteSelected = async () => {
    const preparedArgs: [string, AssetPageTabType, string][] =
      selectedRowIds.map((canvasId) => {
        const canvasObj = tableData.find((el) => el.id === canvasId);
        const versionObj = canvasObj.versions.find(
          (el) => el.version === canvasObj.assetVersionNumber
        );

        return [canvasId, assetType, versionObj.id];
      });

    for (const entry of preparedArgs) {
      await deleteAttachedVersion(...entry);
    }

    setSelectedRowIds([]);
  };

  const columns = useMemo(
    () => [
      {
        id: "checkbox",
        accessorKey: "",
        header: () => <Checkbox checked={isSelectedAll} onChange={selectAll} />,
        cell: (info) => {
          const id = info.row.original.id;
          const isChecked = selectedRowIds.includes(id);

          const handleCheck = () => {
            if (isChecked) {
              setSelectedRowIds((prev) => prev.filter((selId) => selId !== id));
            } else {
              setSelectedRowIds((prev) => [...prev, id]);
            }
          };
          return <Checkbox checked={isChecked} onChange={handleCheck} />;
        },
      },
      {
        id: "name",
        accessorKey: "name",
        enableSorting: true,
        header: () => <div className={styles.headerName}>Name</div>,
        cell: (info) => {
          return (
            <Typography
              component="div"
              variant="body1"
              className={styles.cellName}
            >
              {info.row.original.name}
            </Typography>
          );
        },
      },
      {
        accessorKey: "type",
        enableSorting: false,
        header: "Type",
        cell: (info) => {
          return (
            <Typography component="span" variant="body1">
              {info.row.original.type}
            </Typography>
          );
        },
      },
      {
        accessorKey: "versions",
        enableSorting: false,
        header: "Version",
        cell: (info) => {
          const {
            versions: rawVersions,
            alias,
            membershipId,
            id: canvasId,
            assetVersionNumber,
          } = info.row.original;

          const getInitialVersion = () => {
            return rawVersions.find((el) => el.version === assetVersionNumber);
          };

          const currVersion = getInitialVersion();

          const handleSelect = (versionObj) => {
            const prevVersionId = getInitialVersion().id;

            const data = {
              alias,
              id: membershipId,
              asset_version_id: versionObj.id,
            };
            updateAttachedVersion(canvasId, assetType, data, prevVersionId);
          };

          const handleDelete = () => {
            deleteAttachedVersion(canvasId, assetType, currVersion.id);
          };

          return (
            <div className={styles.rowVersions}>
              <VersionsDropdown
                classes={{ contextMenu: styles.rowDropdownMenu }}
                versions={rawVersions}
                activeVersion={currVersion}
                onSelect={handleSelect}
              />

              <div className={styles.rowActions}>
                <a
                  className={styles.rowAction}
                  href={`/canvas/${canvasId as string}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <BiLinkExternal size={16} />
                </a>
                <a className={styles.rowAction} onClick={handleDelete}>
                  <BiX size={16} />
                </a>
              </div>
            </div>
          );
        },
      },
    ],
    [isSelectedAll, selectedRowIds, tableData, updateAttachedVersion]
  );

  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      sorting: [{ id: "name", desc: false }],
    },
  });

  const BottomBar = () => {
    const [selectedGroupVersion, setSelectedGroupVersion] = useState<any>({
      id: "000",
      label: "v. -",
    });

    const menuOptions = tableData[0]?.versions.map((v) => {
      const { id, version }: { id: string; version: string } = v;
      const versionObj = {
        label: `v. ${version}`,
        id,
        type: "option" as const,
      };

      const handleGroupVersionUpdate = async (versionObj) => {
        const { id: assetVersionId } = versionObj;
        const preparedArguments: [string, AssetPageTabType, any, string][] =
          selectedRowIds.map((rowId) => {
            const canvas = tableData.find((c) => c.id === rowId);
            const { alias, id: canvasId, membershipId } = canvas;
            const prevVersionId = canvas.versions.find(
              (v) => v.version === canvas.assetVersionNumber
            )?.id;

            const data = {
              alias,
              id: membershipId,
              asset_version_id: assetVersionId,
            };
            return [canvasId, assetType, data, prevVersionId];
          });

        let failedItemsCount = 0;
        for (const entry of preparedArguments) {
          const { success } = await updateAttachedVersion(...entry, {
            skipErrorMessage: true,
          });

          if (!success) {
            failedItemsCount += 1;
          }
        }
        if (failedItemsCount > 0) {
          const allItemsFailed = failedItemsCount === preparedArguments.length;
          const toastVariant = allItemsFailed ? "error" : "warning";
          const toastMessage = allItemsFailed
            ? "Failed to update"
            : `Failed to update ${pluralize("item", failedItemsCount, true)}`;
          addToast({
            variant: toastVariant,
            message: toastMessage,
          });
        }

        setSelectedGroupVersion(versionObj);
      };

      return {
        ...versionObj,
        onClick: () => {
          handleGroupVersionUpdate(versionObj);
        },
      };
    });

    useEffect(() => {
      const selectedCanvases = tableData.filter((el) =>
        selectedRowIds.includes(el.id)
      );

      const selectedVersions = selectedCanvases.map((el) => {
        const { assetVersionNumber, alias, versions } = el;
        const versionObj = versions.find(
          (el) => el.version === assetVersionNumber
        );

        return {
          assetVersionNumber,
          versionId: versionObj.id,
          alias,
        };
      });

      const isVersionsEqual = selectedVersions.every(
        (el, i, arr) => el.assetVersionNumber === arr[0].assetVersionNumber
      );

      if (isVersionsEqual) {
        setSelectedGroupVersion({
          label: `v. ${selectedVersions[0]?.assetVersionNumber as number}`,
        });
      }
    }, []);

    return (
      <div className={styles.selected}>
        <Typography
          component="span"
          variant="caption1"
          className={styles.selectedQuantity}
        >
          {selectedRowIds.length}
        </Typography>
        <Typography
          component="span"
          variant="caption1"
          className={styles.selectedLabel}
        >
          {`${pluralize("Item", selectedRowIds.length)} Selected`}
        </Typography>

        <DropdownMenuButton
          nodesClassNames={{
            contextMenu: styles.bottomBarDropdownMenu,
          }}
          menuOptions={menuOptions}
        >
          {selectedGroupVersion.label}
        </DropdownMenuButton>

        <Button
          variant="secondary"
          onClick={() => {
            handleLinksOpen(selectedRowIds);
          }}
        >
          Open
        </Button>
        <Button variant="crucial" onClick={handleDeleteSelected}>
          Remove
        </Button>
        <IconButton onClick={handleDropSelection}>
          <BiX size={20} />
        </IconButton>
      </div>
    );
  };

  return (
    <div className={styles["modal"]}>
      <Typography
        component="p"
        variant="body2"
        className={styles["modal__description"]}
      >
        You can check and visit environment or canvas which is using this
        version of function. Only canvases and environments that you have access
        to are included in this list.
      </Typography>

      <Search value={searchQuery} onChange={onChange} />
      <div className={styles["modal__dropdowns"]}>
        <div className={styles.assetsDropdownWrapper}>
          <LabeledDropdownButton
            classes={{ contextMenu: styles.headerDropdownMenu }}
            title={assetDropdownLabel}
            items={assets}
            selectedItem={selectedAsset}
            setSelectedItem={(item) => {
              setSelectedAssetId(item.id);
            }}
          />
        </div>
        <LabeledDropdownButton
          classes={{ contextMenu: styles.headerDropdownMenu }}
          title={"Version"}
          items={selectedAsset?.versions ?? []}
          selectedItem={selectedVersion}
          setSelectedItem={(item) => {
            setSelectedVersionId(item.id);
          }}
        />
        <LabeledDropdownButton
          title={"Type"}
          items={types}
          selectedItem={selectedType}
          setSelectedItem={setSelectedType}
        />
      </div>

      <Table
        table={table}
        emptyState={
          emptyState ? (
            <EmptyState
              variant="info"
              title={emptyState.title}
              description={emptyState.text}
            />
          ) : null
        }
        nodesClassNames={{ row: styles.row, wrapper: styles.tableWrapper }}
        bottomBar={selectedRowIds.length !== 0 && <BottomBar />}
      />

      <div className={styles["modal__footer"]}>
        <Typography
          component="span"
          variant="body2"
          className={styles.footerOwnerLabel}
        >
          Version Owner
        </Typography>

        <div className={styles.footerOwnerBlock}>
          <Avatar size="small" mode="icon" wrapperClassName={styles.avatar} />
          <Typography component="div" variant="body1">
            {assetOwner}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default ManageUsage;
