import styles from "./NotificationsPage.module.scss";
import { useCallback, useState, useEffect, useMemo } from "react";
import { tokenRef } from "context/Token";
import { useNotificationState, useToastsState, useUserState } from "store";
import { DATA_LOAD_STATUS } from "config/appConfig";
import { debouncePromise } from "utils/helpers";
import {
  deleteNotification,
  getNotifications,
} from "api/http/notification-service";
import type { NotificationType } from "models/notifications";
import Typography from "components/Typography";
import { EmptyState } from "components/common/EmptyState";
import { Button } from "components/common/Button/Button";
import { BiArrowBack, BiCaretRight } from "react-icons/bi";
import { Link } from "react-router-dom";
import { Notification } from "./Notification";
import { Skeleton } from "components/common/Skeleton/Skeleton";

export function NotificationsPage() {
  const addToast = useToastsState((slice) => slice.addToast);
  const [loadingStatus, setLoadingStatus] = useState(DATA_LOAD_STATUS.LOADING);
  const userID = useUserState((slice) => slice.userID);
  const numberOfSkeletonRenders = 4;

  const {
    ws,
    notifications,
    openConnection,
    setNotifications,
    addNotifications,
  } = useNotificationState((slice) => ({
    ws: slice.ws,
    notifications: slice.notifications,
    openConnection: slice.openConnection,
    setNotifications: slice.setNotifications,
    addNotifications: slice.addNotifications,
  }));

  const fetchNotifications = useCallback(async () => {
    setLoadingStatus(DATA_LOAD_STATUS.LOADING);
    try {
      const response = await debouncePromise({ promise: getNotifications() });
      setNotifications(response);
      setLoadingStatus(DATA_LOAD_STATUS.LOADED);
    } catch (error) {
      setLoadingStatus(DATA_LOAD_STATUS.ERROR);
    }
  }, []);

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  useEffect(() => {
    if (!ws && userID) {
      openConnection({
        userID,
        token: tokenRef.current,
        onMessage: (event) => {
          const jsonData = JSON.parse(JSON.parse(event.data));
          // TODO: get rid of null checks
          const cleanedNotification: NotificationType = {
            id: jsonData?.id ?? null,
            user_id: jsonData?.user_id ?? null,
            type: jsonData?.type ?? null,
            read: jsonData?.read ?? false,
            time_created: jsonData?.time_created ?? null,
            properties: jsonData?.properties ?? null,
          };
          addNotifications(cleanedNotification);
        },
      });
    }

    return () => ws?.close();
  }, [ws, userID, openConnection, addNotifications]);

  const sortedNotifications = useMemo(() => {
    return [...notifications].sort((a, b) => {
      return (
        new Date(b.time_created).getTime() - new Date(a.time_created).getTime()
      );
    });
  }, [notifications]);

  const renderError = () => {
    return (
      <EmptyState
        variant="error"
        containerClassName={styles.emptyState}
        title="Failed to load notifications"
        description="Try again or contact support."
      >
        <Button variant="secondary" onClick={fetchNotifications}>
          Retry
        </Button>
      </EmptyState>
    );
  };

  const renderLoading = () => {
    return (
      <div className={styles.loadingNotifications}>
        {Array.from({ length: numberOfSkeletonRenders }).map((_, index) => (
          <div key={index} className={styles.skeletonContainer}>
            <BiCaretRight className={styles.skeletonCaret} />
            <Skeleton className={styles.skeletonIcon} />
            <Skeleton height={36} width={500} />
          </div>
        ))}
      </div>
    );
  };

  const renderEmptyState = () => {
    return (
      <EmptyState
        variant="info"
        containerClassName={styles.emptyState}
        title="No notifications..."
        description="You currently have no notifications"
      />
    );
  };

  const removeNotification = useCallback(
    async (notificationId: string) => {
      try {
        await deleteNotification(notificationId);
        const updatedNotifications = notifications.filter(
          (notification) => notification.id !== notificationId
        );
        setNotifications(updatedNotifications);
        addToast({
          message: `Notification deleted.`,
          variant: "success",
        });
      } catch (error) {
        addToast({
          message: `Failed to delete notification. Try again later.`,
          variant: "error",
        });
      }
    },
    [setNotifications, notifications, deleteNotification]
  );

  const renderContent = () => {
    if (loadingStatus === DATA_LOAD_STATUS.ERROR) {
      return renderError();
    }

    if (loadingStatus === DATA_LOAD_STATUS.LOADING) {
      return renderLoading();
    }

    if (notifications.length === 0) {
      return renderEmptyState();
    }
    return (
      <div className={styles.notifications}>
        {sortedNotifications.map((notification) => (
          <Notification
            key={notification.id}
            notification={notification}
            removeNotification={removeNotification}
          />
        ))}
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Link to="/" className={styles.backButton}>
          <BiArrowBack className={styles.icon} />
          <Typography variant="caption1" className={styles.backButtonText}>
            Back to Homepage
          </Typography>
        </Link>
        <Typography variant="h1" className={styles.title}>
          Notifications
        </Typography>
      </div>
      {renderContent()}
    </div>
  );
}
