import { type StateCreator } from "store";
import type { BlockType, BlockOutput, BlockData } from "models/canvas";
import type {
  COMPUTE_ENV_TYPE,
  MARATHON_SIZE,
  SAGEMAKER_INSTANCE_TYPE,
} from "config/canvasConfig";

type RawBlockData = {
  name: string;
  status: string;
  layer_id: string;
  properties: any;
  description: string;
  is_fargate: boolean;
  cpu: string;
  memory: string;
  time_created: string;
  output_pane_size: number;
  size: MARATHON_SIZE;
  compute_environment_type: COMPUTE_ENV_TYPE;
  gpu_instance_type: SAGEMAKER_INSTANCE_TYPE;
  last_run_execution_time: number;
  current_run_execution_time_start: string | null;
  fleet_id: string | null;
  slice_ids: string[];
};

const transformData = (data: RawBlockData): BlockData => {
  return {
    name: data.name,
    status: parseInt(data.status),
    layer_id: data.layer_id,
    properties: data.properties,
    description: data.description,
    is_fargate: data.is_fargate,
    cpu: parseInt(data.cpu) || null,
    memory: parseInt(data.memory) || null,
    timeCreated: data.time_created,
    outputPaneSize: data.output_pane_size,
    size: data.size,
    computeEnvironmentType: data.compute_environment_type,
    gpuInstanceType: data.gpu_instance_type,
    last_run_execution_time: data.last_run_execution_time,
    current_run_execution_time_start: data.current_run_execution_time_start,
    fleet_id: data.fleet_id,
    slice_ids: data.slice_ids,
  };
};

type BlockOutputLoadingInfo = {
  isFetching: boolean;
  isFetchingOutputs: boolean;
  isInitialLoadingCompleted: boolean;
};

type State = {
  content: Record<BlockType["id"], string>;
  outputLoadingInfo: Record<
    BlockType["id"],
    BlockOutputLoadingInfo | undefined
  >;
  output: Record<BlockType["id"], BlockOutput | undefined>;
  liveLogs: Record<BlockType["id"], string | undefined>;
  data: Record<BlockType["id"], BlockData | undefined>;
};

type Actions = {
  getState: () => State;
  setBlocksContent: (content: State["content"]) => void;
  setBlockContent: (blockId: BlockType["id"], content: string) => void;
  getBlockContent: (blockId: BlockType["id"]) => string;
  setBlockOutputLoadingInfo: (
    blockId: BlockType["id"],
    data: Partial<BlockOutputLoadingInfo>
  ) => void;
  setBlockOutput: (blockId: BlockType["id"], output: any) => void;
  getBlockOutput: (blockId: BlockType["id"]) => BlockOutput | null;
  setLiveLogs: (blockId: BlockType["id"], logs: string) => void;
  setBlocksData: (data: Record<BlockType["id"], RawBlockData>) => void;
  setBlockData: (blockId: BlockType["id"], data: RawBlockData) => void;
  patchBlockData: (blockId: BlockType["id"], data: Partial<BlockData>) => void;
  getBlockData: (blockId: BlockType["id"]) => State["data"][BlockType["id"]];
  deleteBlock: (blockId: BlockType["id"]) => void;
  clearStore: () => void;
};

const getInitialState = (): State => ({
  content: {},
  outputLoadingInfo: {},
  output: {},
  liveLogs: {},
  data: {},
});

export type CanvasBlocksSlice = State & Actions;

export const createCanvasBlocksSlice: StateCreator<CanvasBlocksSlice> = (
  set,
  get
) => ({
  ...getInitialState(),
  getState: () => get().canvasBlocksSlice,
  setBlocksContent: (content) => {
    set(
      (store) => {
        Object.assign(store.canvasBlocksSlice.content, content);
      },
      false,
      "canvasBlocks/setBlocksContent"
    );
  },
  setBlockContent: (blockId, content) => {
    set(
      (store) => {
        store.canvasBlocksSlice.content[blockId] = content;
      },
      false,
      "canvasBlocks/setBlockContent"
    );
  },
  getBlockContent: (blockId) => {
    return get().canvasBlocksSlice.content[blockId];
  },
  setBlockOutputLoadingInfo: (blockId, data) => {
    set(
      (store) => {
        store.canvasBlocksSlice.outputLoadingInfo[blockId] = Object.assign(
          {
            isFetching: false,
            isFetchingOutputs: false,
            isInitialLoadingCompleted: false,
          },
          store.canvasBlocksSlice.outputLoadingInfo[blockId],
          data
        );
      },
      false,
      "canvasBlocks/setBlockOutputLoadingInfo"
    );
  },
  setBlockOutput: (blockId, output) => {
    set(
      (store) => {
        store.canvasBlocksSlice.output[blockId] = output;
      },
      false,
      "canvasBlocks/setBlockOutput"
    );
  },
  getBlockOutput: (blockId) => {
    return get().canvasBlocksSlice.output[blockId] || null;
  },
  setBlocksData: (data) => {
    set(
      (store) => {
        for (const blockId in data) {
          store.canvasBlocksSlice.data[blockId] = transformData(data[blockId]);
        }
      },
      false,
      "canvasBlocks/setBlocksData"
    );
  },
  setLiveLogs: (blockId, logs) => {
    set(
      (store) => {
        store.canvasBlocksSlice.liveLogs[blockId] = logs;
      },
      false,
      "canvasBlocks/setLiveLogs"
    );
  },
  setBlockData: (blockId, data) => {
    set(
      (store) => {
        store.canvasBlocksSlice.data[blockId] = transformData(data);
      },
      false,
      "canvasBlocks/setBlockData"
    );
  },
  patchBlockData: (blockId, data) => {
    set(
      (store) => {
        store.canvasBlocksSlice.data[blockId] = {
          ...(store.canvasBlocksSlice.data[blockId] as BlockData),
          ...data,
        };
      },
      false,
      "canvasBlocks/patchBlockData"
    );
  },
  getBlockData: (blockId) => {
    return get().canvasBlocksSlice.data[blockId];
  },
  deleteBlock: (blockId) => {
    set(
      (store) => {
        delete store.canvasBlocksSlice.content[blockId];
        delete store.canvasBlocksSlice.outputLoadingInfo[blockId];
        delete store.canvasBlocksSlice.output[blockId];
        delete store.canvasBlocksSlice.liveLogs[blockId];
        delete store.canvasBlocksSlice.data[blockId];
      },
      false,
      "canvasBlocks/deleteBlock"
    );
  },
  clearStore: () => {
    set(
      (store) => {
        Object.assign(store.canvasBlocksSlice, getInitialState());
      },
      false,
      "canvasBlocks/clearStore"
    );
  },
});
