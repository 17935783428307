import styles from "./EditableTable.module.scss";
import {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
  type ReactNode,
  type FocusEvent,
  type KeyboardEvent,
  type MouseEvent,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { BiCaretUp, BiX } from "react-icons/bi";
import cn from "classnames";
import { EmptyState } from "components/common/EmptyState/EmptyState";
import Tooltip from "components/common/Tooltip/Tooltip";
import { SORTING_DIRECTION, VALIDATION_STATUS } from "config/appConfig";
import { EditableRow } from "components/EditableTable/EditableRow";
import {
  committedColumnsDataKey,
  displayColumnsDataKey,
  indexKey,
  originalRowDataKey,
  rowTypeKey,
  touchedKey,
  TABLE_ROW_TYPE,
  type AddedRow,
  type Column,
  type ColumnError,
  type ColumnsErrorData,
  type EnhancedRow,
  type MapValueType,
  type TableRow,
} from "components/EditableTable/config";

export type EditableTableProps<Key extends string, Row> = {
  rows: Row[];
  columns: Column<Key>[];
  searchText?: string;
  defaultSortBy?: Key;
  defaultSortDirection?: SORTING_DIRECTION;
  className?: string;
  readOnly?: boolean;
  addRowControl?: (onRowAdd: (row: Row) => void) => ReactNode;
  onChange: (rows: Row[]) => void;
  getIsRowDisabled?: (row: Row) => boolean;
  validateRow?: ({
    columnKey,
    rowData,
    rows,
  }: {
    columnKey: Key;
    rowData: TableRow<Row, Key>;
    rows?: TableRow<Row, Key>[];
  }) => ColumnsErrorData<Key>;
  onValidationStatusChange?: (status: VALIDATION_STATUS) => void;
};

// TODO: The requirements table should be updated to better support non-string values
// Currently, Record<Key, unknown> is used to allow any value type for the columns
//https://linear.app/zerve-ai/issue/FRO-1497/update-editable-table-to-better-support-non-string-values
export function EditableTable<
  Key extends string,
  Row extends {
    [key in Key]: string;
  } & Record<string | number | symbol, unknown>,
>({
  rows,
  columns,
  searchText = "",
  defaultSortBy,
  defaultSortDirection = SORTING_DIRECTION.ASCENDING,
  className,
  readOnly = false,
  addRowControl,
  getIsRowDisabled,
  onChange,
  validateRow,
  onValidationStatusChange,
}: EditableTableProps<Key, Row>) {
  const [validationInProgress, setValidationInProgress] = useState(false);
  const [changesPendingPush, setChangesPendingPush] = useState(false);

  const [rowErrorsMap, setRowErrorsMap] = useState<
    Map<string, ColumnsErrorData<Key>>
  >(new Map());
  const [enhancedRowsMap, setEnhancedRowsMap] = useState<
    Map<string, EnhancedRow<Row, Key>>
  >(new Map());

  // not yet saved rows
  const [addedRowsMap, setAddedRowsMap] = useState<
    Map<string, AddedRow<Row, Key>>
  >(new Map());

  // keep track of the indexes that need to be saved
  // containes indexes of changed or deleted rows
  const updatedIndexes = useRef<Set<string>>(new Set());

  const [sortBy, setSortBy] = useState<Key | null>(defaultSortBy ?? null);
  const [sortingDirection, setSortingDirection] =
    useState(defaultSortDirection);

  const hasValidation = Boolean(validateRow);

  // add row
  const handleAddRow = useCallback(
    (row: Row) => {
      if (readOnly) {
        return;
      }

      // create object with only properties that are in the columns array
      const columnsData = columns.reduce(
        (acc, column) => {
          return { ...acc, [column.key]: row[column.key] || "" };
        },
        {} as Record<Key, string>
      );

      setAddedRowsMap((prev) => {
        const addedItem = {
          [indexKey]: uuidv4(),
          [displayColumnsDataKey]: { ...columnsData },
          [originalRowDataKey]: { ...row },
          [rowTypeKey]: TABLE_ROW_TYPE.CREATED_ROW,
        };

        return new Map([[addedItem[indexKey], addedItem], ...prev]);
      });
    },
    [readOnly, columns]
  );

  useEffect(() => {
    setEnhancedRowsMap(() => {
      const rowsMap = new Map() as typeof enhancedRowsMap;

      rows.forEach((row) => {
        const index = uuidv4();
        // create object with only properties that are in the columns array
        const columnsData = columns.reduce(
          (acc, column) => {
            return { ...acc, [column.key]: row[column.key] };
          },
          {} as Record<Key, string>
        );

        rowsMap.set(index, {
          [indexKey]: index,
          [displayColumnsDataKey]: { ...columnsData },
          [committedColumnsDataKey]: { ...columnsData },
          [originalRowDataKey]: { ...row },
          [rowTypeKey]: TABLE_ROW_TYPE.EDITED_ROW,
        });
      });

      return rowsMap;
    });
  }, [rows, columns]);

  const addedRows = useMemo(() => {
    return [...addedRowsMap.values()];
  }, [addedRowsMap]);

  const enhancedRows = useMemo(() => {
    return [...enhancedRowsMap.values()];
  }, [enhancedRowsMap]);

  const { uncomittedAddedRows, committedAddedRows } = useMemo(() => {
    const uncomittedRows: typeof addedRows = [];
    const committedRows: typeof addedRows = [];

    addedRows.forEach((row) => {
      if (row[committedColumnsDataKey]) {
        committedRows.push(row);
      } else {
        uncomittedRows.push(row);
      }
    });

    return {
      uncomittedAddedRows: uncomittedRows,
      committedAddedRows: committedRows,
    };
  }, [addedRows]);

  const searchableColumns = useMemo(() => {
    return columns
      .filter((column) => column.searchable)
      .map((column) => column.key);
  }, [columns]);

  // processed rows to display
  const rowsDisplay = useMemo(() => {
    const searchTextLowercase = searchText.toLocaleLowerCase();

    if (searchText && !searchableColumns.length) {
      console.error(
        "EditableTable was given a search text but no searchable columns",
        searchText,
        columns
      );
    }

    // filter rows
    const filteredRows = [...committedAddedRows, ...enhancedRows].reduce<
      TableRow<Row, Key>[]
    >((acc, row) => {
      // filter out rows that don't match the search text
      if (searchTextLowercase) {
        const isSearchMatch = searchableColumns.some((column) => {
          return String(
            (row[committedColumnsDataKey] || row[originalRowDataKey])[column]
          )
            .toLocaleLowerCase()
            .includes(searchTextLowercase);
        });
        if (!isSearchMatch) {
          return acc;
        }
      }

      return [...acc, { ...row }];
    }, []);

    // sort rows
    let sortedRows = filteredRows;
    if (sortBy) {
      sortedRows = filteredRows.sort((a, b) => {
        const aValue =
          (a[committedColumnsDataKey] || a[originalRowDataKey])[sortBy] || "";
        const bValue =
          (b[committedColumnsDataKey] || b[originalRowDataKey])[sortBy] || "";

        if (sortingDirection === SORTING_DIRECTION.ASCENDING) {
          return aValue.localeCompare(bValue);
        } else {
          return bValue.localeCompare(aValue);
        }
      });
    }

    return sortedRows;
  }, [
    enhancedRows,
    committedAddedRows,
    searchableColumns,
    searchText,
    sortBy,
    sortingDirection,
  ]);

  // change sorting
  const handleHeaderClick = useCallback(
    (columnKey: Key) => {
      // if the column is not sortable, do nothing
      const column = columns.find((column) => column.key === columnKey);
      if (!column?.sortable) {
        return;
      }

      // if the column is already sorted, change the sorting direction
      if (sortBy === columnKey) {
        setSortingDirection((prev) => {
          if (prev === SORTING_DIRECTION.ASCENDING) {
            return SORTING_DIRECTION.DESCENDING;
          } else {
            return SORTING_DIRECTION.ASCENDING;
          }
        });
      }
      // if the column is not sorted, sort by it
      else {
        setSortBy(columnKey);
      }
    },
    [columns, sortBy]
  );

  const isValid = !hasValidation || !rowErrorsMap.size;

  const updateRowErrorsMapData = useCallback(
    (rowIndex: string, errData?: ColumnsErrorData<Key>) => {
      setRowErrorsMap((prev) => {
        const newRowErrorsMap = new Map(prev);

        const hasError =
          !!errData &&
          Object.values<ColumnError>(errData).some((item) =>
            Boolean(item?.message)
          );
        if (hasError) {
          newRowErrorsMap.set(rowIndex, errData);
        } else if (prev.has(rowIndex)) {
          newRowErrorsMap.delete(rowIndex);
        }

        return newRowErrorsMap;
      });
    },
    []
  );

  const revalidateRow = (
    rowIndex: string,
    columnKey: Key,
    allRows: TableRow<Row, Key>[]
  ) => {
    const rowData = enhancedRowsMap.get(rowIndex) || addedRowsMap.get(rowIndex);

    if (rowData) {
      const errorData = validateRow?.({
        columnKey,
        rowData,
        rows: allRows,
      });

      const updatedErrorData = {
        ...rowErrorsMap.get(rowIndex),
        ...errorData,
      } as Record<Key, ColumnError>;

      updateRowErrorsMapData(rowIndex, updatedErrorData);

      return;
    }

    if (rowErrorsMap.has(rowIndex)) {
      updateRowErrorsMapData(rowIndex, null);
    }
  };

  const revalidateErrorDependentRows = (
    errorDependencies: string[] | undefined,
    columnKey: Key,
    allRows: TableRow<Row, Key>[]
  ) => {
    if (errorDependencies?.length) {
      errorDependencies.forEach((key) => {
        revalidateRow(key, columnKey, [...allRows]);
      });
    }
  };

  const sanitizeColumnsData = useCallback(
    (columnsData: Record<Key, string>) => {
      return Object.keys(columnsData).reduce(
        (acc, key) => {
          acc[key] = columnsData[key].trim();

          return acc;
        },
        {} as Record<Key, string>
      );
    },
    []
  );

  const pushTableChanges = () => {
    if (!isValid || readOnly) {
      return;
    }

    const touchedAddedRows = addedRows.filter((row) => row[touchedKey]);
    if (!touchedAddedRows.length && !updatedIndexes.current.size) {
      return;
    }

    const newRows = [...enhancedRowsMap.values()].reduce<Row[]>((acc, row) => {
      let newRow = row[originalRowDataKey];
      if (updatedIndexes.current.has(row[indexKey])) {
        const { [displayColumnsDataKey]: columnsData } = row;
        newRow = { ...newRow, ...sanitizeColumnsData(columnsData) };
      }
      return [...acc, newRow];
    }, []);

    // add
    const added = touchedAddedRows.map((row) => {
      const {
        [displayColumnsDataKey]: columnsData,
        [originalRowDataKey]: originalRowData,
      } = row;

      return { ...originalRowData, ...sanitizeColumnsData(columnsData) };
    });

    newRows.unshift(...added);
    updatedIndexes.current = new Set();
    setRowErrorsMap(new Map());
    setAddedRowsMap((prev) => {
      const newAddedRowsMap = new Map(prev);
      const indicesToDelele: string[] = [];
      newAddedRowsMap.forEach((value, key) => {
        if (value[committedColumnsDataKey]) {
          indicesToDelele.push(key);
        }
      });

      indicesToDelele.forEach((ind) => {
        newAddedRowsMap.delete(ind);
      });

      return newAddedRowsMap;
    });
    onChange(newRows);
  };

  // call onChange with updated and added rows
  const commitTableChange = () => {
    const touchedAddedRows = addedRows.filter((row) => row[touchedKey]);

    // do nothing if there are no changes
    if (!updatedIndexes.current.size && !touchedAddedRows.length) {
      return;
    }

    const touchedNotChangedAddedRowsIndices: string[] = [];
    const changedAddedRows = touchedAddedRows.filter((row) => {
      // check if the row has been changed on the editable columns
      const rowChanged = columns.some((column) => {
        const columnKey = column.key;
        return (
          row[displayColumnsDataKey][columnKey].trim() !==
          row[originalRowDataKey][columnKey].trim()
        );
      });
      if (!rowChanged) {
        touchedNotChangedAddedRowsIndices.push(row[indexKey]);
      }

      return rowChanged;
    });

    if (touchedAddedRows.length > 0) {
      setAddedRowsMap((prev) => {
        const newAddedRowsMap = new Map(prev);

        touchedAddedRows.forEach((item) => {
          const index = item[indexKey];
          const itemData = prev.get(index);

          if (itemData) {
            const itemNotChanged =
              touchedNotChangedAddedRowsIndices.includes(index);
            const committedColumnsData = itemNotChanged
              ? null
              : { ...itemData[displayColumnsDataKey] };
            newAddedRowsMap.set(index, {
              ...itemData,
              [committedColumnsDataKey]: committedColumnsData,
              [touchedKey]: !itemNotChanged,
            });
          }
        });

        return newAddedRowsMap;
      });
    }

    if (updatedIndexes.current.size > 0) {
      setEnhancedRowsMap((prev) => {
        const newEnhancedRowsMap = new Map(prev);

        updatedIndexes.current.forEach((ind) => {
          const itemData = prev.get(ind);
          if (itemData) {
            newEnhancedRowsMap.set(ind, {
              ...itemData,
              [committedColumnsDataKey]: {
                ...itemData[displayColumnsDataKey],
              },
            });
          }
        });

        return newEnhancedRowsMap;
      });
    }

    if (!updatedIndexes.current.size && !changedAddedRows.length) {
      return;
    }

    setChangesPendingPush(true);
  };

  useEffect(() => {
    if (validationInProgress || !changesPendingPush) {
      return;
    }
    if (isValid) {
      pushTableChanges();
    }
    setChangesPendingPush(false);
  }, [isValid, changesPendingPush, validationInProgress]);

  useEffect(() => {
    onValidationStatusChange?.(
      isValid ? VALIDATION_STATUS.VALID : VALIDATION_STATUS.INVALID
    );
  }, [isValid]);

  // commit changes when the table loses focus
  const handleBodyBlur = useCallback(
    (e: FocusEvent<HTMLDivElement>) => {
      const container = e.currentTarget;
      // give browser time to focus the next element
      requestAnimationFrame(() => {
        // check if the new focused element is a child of the original container
        if (!container.contains(document.activeElement)) {
          commitTableChange();
        }
      });
    },
    [commitTableChange]
  );

  const handleValidateRowOnChange = (
    columnKey: Key,
    rowData: TableRow<Row, Key>,
    allRows: TableRow<Row, Key>[]
  ) => {
    setValidationInProgress(true);
    const rowIndex = rowData[indexKey];
    const errorData = validateRow?.({
      columnKey,
      rowData: { ...rowData },
      rows: allRows,
    });
    const prevErrorData = rowErrorsMap.get(rowIndex);
    const updatedErrorData = {
      ...prevErrorData,
      ...errorData,
    } as Record<Key, ColumnError>;
    updateRowErrorsMapData(rowIndex, updatedErrorData);
    revalidateErrorDependentRows(
      prevErrorData?.[columnKey]?.dependencies,
      columnKey,
      allRows
    );
    revalidateErrorDependentRows(
      updatedErrorData?.[columnKey]?.dependencies,
      columnKey,
      allRows
    );
    setValidationInProgress(false);
  };

  // make local changes to added row column when the user types
  const handleAddedRowValueChange = (
    value: string,
    columnKey: Key,
    row: MapValueType<typeof addedRowsMap>
  ) => {
    const rowIndex = row[indexKey];
    const updatedRowData = {
      ...row,
      [displayColumnsDataKey]: {
        ...row[displayColumnsDataKey],
        [columnKey]: value,
      },
      [touchedKey]: true,
    };

    const newAddedRowsMap = new Map(addedRowsMap);
    if (newAddedRowsMap.has(rowIndex)) {
      newAddedRowsMap.set(rowIndex, updatedRowData);
      setAddedRowsMap(newAddedRowsMap);
    }

    if (hasValidation) {
      handleValidateRowOnChange(columnKey, updatedRowData, [
        ...newAddedRowsMap.values(),
        ...enhancedRows,
      ]);
    }
  };

  // make local changes to row column when the user types
  const handleValueChange = (
    value: string,
    columnKey: Key,
    row: MapValueType<typeof enhancedRowsMap>
  ) => {
    const rowIndex = row[indexKey];

    const updatedRowData = {
      ...row,
      [displayColumnsDataKey]: {
        ...row[displayColumnsDataKey],
        [columnKey]: value,
      },
    };

    const newEnhancedRowsMap = new Map(enhancedRowsMap);
    if (newEnhancedRowsMap.has(rowIndex)) {
      newEnhancedRowsMap.set(rowIndex, updatedRowData);
      setEnhancedRowsMap(newEnhancedRowsMap);
    }

    updatedIndexes.current.add(rowIndex);

    if (hasValidation) {
      handleValidateRowOnChange(columnKey, updatedRowData, [
        ...addedRows,
        ...newEnhancedRowsMap.values(),
      ]);
    }
  };

  // commit changes when user presses Enter or Escape
  const handleKeyDown = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" || e.key === "Escape") {
      // blur the input to trigger the onBlur event on the tbody;
      // this will commit the changes
      if (e.target instanceof HTMLInputElement) {
        e.target.blur();
      }
    }
  }, []);

  const revalidateTableOnRowDeleted = (
    rowIndex: string,
    allRows: TableRow<Row, Key>[]
  ) => {
    setValidationInProgress(true);
    const rowErrorData = rowErrorsMap.get(rowIndex);
    updateRowErrorsMapData(rowIndex, null);

    columns.forEach((column) => {
      const columnKey = column.key;

      revalidateErrorDependentRows(
        rowErrorData?.[columnKey]?.dependencies,
        columnKey,
        allRows
      );
    });
    setValidationInProgress(false);
  };

  // remove added row
  const handleDeleteAddedRow = (rowIndex: string) => {
    const row = addedRowsMap.get(rowIndex);
    if (!row) {
      return;
    }

    const newAddedRowsMap = new Map(addedRowsMap);
    newAddedRowsMap.delete(rowIndex);
    setAddedRowsMap(newAddedRowsMap);

    if (hasValidation) {
      revalidateTableOnRowDeleted(rowIndex, [
        ...newAddedRowsMap.values(),
        ...enhancedRows,
      ]);
    }

    commitTableChange();
  };

  // remove row
  const handleRowDelete = (rowIndex: string) => {
    if (readOnly) {
      return;
    }

    const row = enhancedRowsMap.get(rowIndex);
    if (!row) {
      return;
    }

    const newEnhancedRowsMap = new Map(enhancedRowsMap);
    newEnhancedRowsMap.delete(rowIndex);
    setEnhancedRowsMap(newEnhancedRowsMap);

    updatedIndexes.current.add(rowIndex);

    if (hasValidation) {
      revalidateTableOnRowDeleted(rowIndex, [
        ...addedRowsMap.values(),
        ...newEnhancedRowsMap.values(),
      ]);
    }

    commitTableChange();
  };

  // cancel sorting
  const handleCancelSorting = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation(); // do not change sorting direction
    setSortBy(null);
  }, []);

  return (
    <>
      {/* Add row */}
      {addRowControl ? addRowControl(handleAddRow) : null}

      {/* Table */}
      <div
        className={cn(
          styles.table,
          { [styles.readonly]: readOnly, [styles.table_invalid]: !isValid },
          className
        )}
      >
        {/* Table Header */}
        <div className={styles.thead}>
          <div className={styles.row}>
            {columns.map((column) => {
              const isSorted = column.key === sortBy;
              return (
                <div
                  key={column.key}
                  role={column.sortable ? "button" : undefined}
                  className={cn(styles.th, {
                    [styles.sortable]: column.sortable,
                    [styles.sorted]: isSorted,
                  })}
                  onClick={() => {
                    handleHeaderClick(column.key);
                  }}
                >
                  <span className={styles.label}>{column.label}</span>

                  {column.sortable ? (
                    <Tooltip
                      withArrow
                      placement="top"
                      text={isSorted ? "Cancel sorting" : undefined}
                      className={cn(styles.sortingIconWrap, {
                        [styles.ascending]:
                          sortingDirection === SORTING_DIRECTION.ASCENDING,
                      })}
                      onClick={isSorted ? handleCancelSorting : undefined}
                    >
                      <BiCaretUp className={styles.caret} />
                      <BiX className={styles.cancel} />
                    </Tooltip>
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>

        {/* Empty list (no rows at all) */}
        {rows?.length === 0 && uncomittedAddedRows.length === 0 ? (
          <EmptyState
            variant="info"
            containerClassName={styles.emptyStateContainer}
            title="No data..."
            description="The list is empty"
          />
        ) : null}

        {/* Empty list (nothing matched the search) */}
        {rows?.length > 0 &&
        rowsDisplay?.length === 0 &&
        uncomittedAddedRows.length === 0 &&
        searchText ? (
          <EmptyState
            variant="info"
            containerClassName={styles.emptyStateContainer}
            title="No data..."
            description="No items match the given search query"
          />
        ) : null}

        {/* Table Body */}
        {rowsDisplay.length > 0 || uncomittedAddedRows.length > 0 ? (
          <div className={styles.tbody} onBlur={handleBodyBlur}>
            {/* New uncommitted rows */}
            {uncomittedAddedRows.map((row, rowIndex) => {
              const index = row[indexKey];
              return (
                <EditableRow
                  key={index}
                  columns={columns}
                  row={row[displayColumnsDataKey]}
                  rowErrors={rowErrorsMap.get(index)}
                  readOnly={readOnly}
                  containerClassName={styles.row}
                  inputClassName={styles.td}
                  autofocusFirstInput={rowIndex === 0}
                  onRowDelete={() => {
                    handleDeleteAddedRow(index);
                  }}
                  onValueChange={(value, columnKey) => {
                    handleAddedRowValueChange(value, columnKey, row);
                  }}
                  onKeyDown={handleKeyDown}
                />
              );
            })}

            {/* Existing rows mixed with committed added rows */}
            {rowsDisplay.map((row) => {
              const index = row[indexKey];
              const rowType = row[rowTypeKey];
              const disabled =
                getIsRowDisabled?.(row[originalRowDataKey]) ?? false;

              if (rowType === TABLE_ROW_TYPE.CREATED_ROW) {
                return (
                  <EditableRow
                    key={index}
                    columns={columns}
                    row={row[displayColumnsDataKey]}
                    rowErrors={rowErrorsMap.get(index)}
                    readOnly={readOnly}
                    containerClassName={styles.row}
                    inputClassName={styles.td}
                    onRowDelete={() => {
                      handleDeleteAddedRow(index);
                    }}
                    onValueChange={(value, columnKey) => {
                      handleAddedRowValueChange(value, columnKey, row);
                    }}
                    onKeyDown={handleKeyDown}
                  />
                );
              }

              return (
                <EditableRow
                  key={index}
                  columns={columns}
                  row={row[displayColumnsDataKey]}
                  rowErrors={rowErrorsMap.get(index)}
                  readOnly={readOnly}
                  disabled={disabled}
                  containerClassName={styles.row}
                  inputClassName={styles.td}
                  onRowDelete={() => {
                    handleRowDelete(index);
                  }}
                  onValueChange={(value, columnKey) => {
                    handleValueChange(value, columnKey, row);
                  }}
                  onKeyDown={handleKeyDown}
                />
              );
            })}
          </div>
        ) : null}
      </div>
    </>
  );
}
