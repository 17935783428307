import styles from "./ConnectionsTabHeader.module.scss";
import { type FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Typography from "components/Typography/Typography";
import { Checkbox } from "components/common/Checkbox/Checkbox";
import { useAssetsState } from "store";
import ConnectionTypesDropdown from "pages/Assets/Connections/components/ConnectionTypesDropdown/ConnectionTypesDropdown";

interface ConnectionsTabHeaderProps {
  title: string;
  subtitle: string;
}

const ConnectionsTabHeader: FC<ConnectionsTabHeaderProps> = ({
  title,
  subtitle,
}) => {
  const navigate = useNavigate();

  const { displayArchivedItems, selectedItem, data } = useAssetsState(
    (slice) => slice.connections
  );
  const { setDisplayArchivedItems, clearSelectedItem } = useAssetsState(
    (slice) => slice.connectionsActions
  );

  const toggleDisplayArchivedItems = useCallback(() => {
    setDisplayArchivedItems(!displayArchivedItems);

    if (!displayArchivedItems) {
      return;
    }

    const selectedItemData = selectedItem.id ? data[selectedItem.id] : null;

    if (selectedItemData?.connection.archive) {
      clearSelectedItem();
      navigate("/assets/connections");
    }
  }, [displayArchivedItems, selectedItem.id, data]);

  return (
    <div className={styles.container}>
      <div className={styles.title_section}>
        <Typography variant="h3">{title}</Typography>
        <Typography variant="caption2">{subtitle}</Typography>
      </div>

      <div className={styles.controls}>
        <Checkbox
          checked={displayArchivedItems}
          onChange={toggleDisplayArchivedItems}
          label={<Typography variant="h3">Display Archived Items</Typography>}
        />
        <ConnectionTypesDropdown />
      </div>
    </div>
  );
};

export default ConnectionsTabHeader;
