import { USER_API_URL } from "config/appConfig";
import { callAPI } from "./call-api";

export const getDeveloperAPISetting = async () =>
  await callAPI({
    method: "GET",
    url: `${USER_API_URL}/developer_api_settings`,
  });

export const postDeveloperAPISetting = async () =>
  await callAPI({
    method: "POST",
    url: `${USER_API_URL}/developer_api_settings`,
  });

export const deleteDeveloperAPISetting = async () =>
  await callAPI({
    method: "DELETE",
    url: `${USER_API_URL}/developer_api_settings`,
  });

const developerAPISettingService = {
  getDeveloperAPISetting,
  postDeveloperAPISetting,
  deleteDeveloperAPISetting,
};

export default developerAPISettingService;
