import Typography from "components/Typography";
import styles from "./PaymentTip.module.scss";

const PaymentTip = ({ tip }: any) => {
  return (
    <div className={styles["tip"]}>
      <Typography component="h2" variant="h2" className={styles["tip__title"]}>
        {tip.title}
      </Typography>

      <Typography
        component="p"
        variant="body2"
        className={styles["tip__subtitle"]}
      >
        {tip.subtitle}
      </Typography>
    </div>
  );
};

export default PaymentTip;
