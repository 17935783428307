import { useRef, useEffect, type FC } from "react";
import styles from "./AssetsNavVersionItem.module.scss";
import RowButtons from "components/common/RowButtons/RowButtons";
import { BiArchiveOut, BiShareAlt, BiTrash } from "react-icons/bi";
import { FiEyeOff } from "react-icons/fi";

import { useNavigate } from "react-router-dom";
import Typography from "components/Typography/Typography";
import { useAssetsData } from "hooks/useAssetsData";
import { useModal } from "hooks/useModal";
import { useConfirmModal } from "hooks/useConfirmModal";
import { useAssetsDataLoadActions } from "hooks/assets/useAssetsDataLoadActions";
import { RESOURCE_TYPE } from "models/user";
import { ShareResourceModal } from "components/modals";
import shareResourceModalStyles from "components/modals/ShareResourceModal/ShareResourceModal.module.scss";
import {
  type AssetPageTabType,
  AssetPageTabUrl,
  AssetStatus,
} from "../../types/abstractTypes";
import { type ConstantVersionType } from "../../types/constantTypes";
import AssetsNavVersionDropdown from "../AssetsNavVersionDropdown";
import cn from "classnames";
import { LABEL, MESSAGE } from "pages/Assets/config";

export interface AssetsNavVersionItemProps {
  assetType: AssetPageTabType;
  id: string;
  version: number;
  disabled?: boolean;
  baseURL?: string;
  workspaceId?: string;
}

const AssetsNavVersionItem: FC<AssetsNavVersionItemProps> = ({
  version: versionNumber,
  id,
  assetType,
  disabled = false,
  baseURL = "/assets",
  workspaceId,
}: AssetsNavVersionItemProps) => {
  const rootRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const { openModal } = useModal();
  const { openConfirmModal } = useConfirmModal();

  const { loadInitialAssetsData } = useAssetsDataLoadActions();
  const { getCurrentAssetItemActions, getCurrentAssetItemsData } =
    useAssetsData();
  const { activeItemVersionId, activeItemData, isDisplayArchivedItems } =
    getCurrentAssetItemsData(assetType);
  const {
    setActiveVersionNumber,
    setAssetVersionIsArchived,
    deleteAssetVersion,
    clearActiveItemData,
  } = getCurrentAssetItemActions(assetType);
  let statusMarkerColorStyle: string = "no_status";

  if (activeItemData) {
    statusMarkerColorStyle =
      AssetStatus?.[
        activeItemData?.versions[versionNumber]?.status
      ]?.toLowerCase();
  }

  const isSelected = id === activeItemVersionId;

  useEffect(() => {
    if (isSelected) {
      // do not set behavior: "smooth" as it does not work properly on some browsers
      rootRef.current?.scrollIntoView({ block: "nearest" });
    }
  }, [isSelected]);

  const versionData = activeItemData?.versions[versionNumber];

  const isArchived = versionData?.archive;

  const isSecret =
    activeItemData &&
    "constant" in activeItemData &&
    (versionData as ConstantVersionType)?.secret;

  const onClick = (): void => {
    navigate(`${baseURL}/${AssetPageTabUrl[assetType]}/${id}`);
    setActiveVersionNumber(versionNumber);
  };

  const handleUnarchive = (): void => {
    setAssetVersionIsArchived(id, false);
  };

  const handleDelete = async (e) => {
    e.stopPropagation();
    const isOnlyRemainingVersion =
      Object.values(activeItemData?.versions ?? {}).length === 1;

    const confirmed = await openConfirmModal({
      title: isOnlyRemainingVersion
        ? MESSAGE.deleteLastVersionAndAsset
        : MESSAGE.deleteAssetVersion,
      message: isOnlyRemainingVersion
        ? MESSAGE.getDeleteLastVersionAndAssetDescription(
            LABEL.deleteConfirmButton,
            "Cancel"
          )
        : MESSAGE.getDeleteAssetVersionDescription(versionNumber),
      confirmButtonLabel: LABEL.deleteConfirmButton,
      confirmButtonVariant: "crucial",
    });

    if (confirmed) {
      deleteAssetVersion(id, versionNumber);
    }
  };

  if (!isDisplayArchivedItems && isArchived) {
    return null;
  }

  const handleShareClick = () => {
    if (activeItemData) {
      const versionData = activeItemData.versions[versionNumber];
      openModal({
        classes: {
          container: shareResourceModalStyles.modal,
          title: shareResourceModalStyles.modal_title,
        },
        title: `Share Asset Version (v.${versionNumber})`,
        content: ({ onModalClose }) => (
          <ShareResourceModal
            resourceId={versionData.resource_id}
            resourceType={RESOURCE_TYPE.ASSET_VERSION}
            resourceLink={`${window.location.origin}${baseURL}/${AssetPageTabUrl[assetType]}/${id}`}
            onModalClose={onModalClose}
            onLeaveResource={() => {
              clearActiveItemData();
              navigate(`${baseURL}/${AssetPageTabUrl[assetType]}`, {
                replace: true,
              });
              loadInitialAssetsData({ assetType, workspaceId });
            }}
          />
        ),
      });
    }
  };

  return (
    <div
      ref={rootRef}
      className={cn(styles.version, {
        [styles.versionActionsDisabled]: disabled,
        [styles.version_active]: isSelected,
      })}
      onClick={onClick}
      id={id}
    >
      <div className={styles.versionTitle}>
        <div
          className={cn(styles.statusMarker, styles[statusMarkerColorStyle], {
            [styles.no_status]: isArchived,
          })}
        />
        <Typography
          variant="caption1"
          className={cn({
            [styles.titleSelected]: isSelected,
            [styles.titleArchived]: isArchived && !isSelected,
          })}
        >
          {`v.${versionNumber}`}
        </Typography>
        {isSecret && (
          <div className={styles.secretIcon}>
            <FiEyeOff size={16} />
          </div>
        )}
      </div>
      {isArchived ? (
        <RowButtons
          buttons={[
            {
              icon: BiArchiveOut,
              tooltip: "Unarchive",
              onClick: handleUnarchive,
            },
            {
              icon: BiTrash,
              tooltip: "Delete",
              onClick: handleDelete,
            },
          ]}
          size="medium"
        />
      ) : (
        <div className={styles.version__actions}>
          {!workspaceId && (
            <RowButtons
              buttons={[
                {
                  icon: BiShareAlt,
                  tooltip: "Share",
                  onClick: handleShareClick,
                },
              ]}
              size="medium"
            />
          )}
          <AssetsNavVersionDropdown
            assetType={assetType}
            versionId={id}
            versionNumber={versionNumber}
            versionData={versionData}
          />
        </div>
      )}
    </div>
  );
};

export default AssetsNavVersionItem;
