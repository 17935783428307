import styles from "./WorkspaceMembers.module.scss";
import { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import cn from "classnames";
import { useUserState, useToastsState } from "store";
import { Button } from "components/common/Button/Button";
import { Checkbox } from "components/common/Checkbox/Checkbox";
import Table from "components/common/Table/Table";
import FormDropdown from "components/common/FormDropdown/FormDropdown";
import { TitleSection } from "components/organization/TitleSection/TitleSection";
import { EmptyState } from "components/common/EmptyState/EmptyState";
import {
  getWorkspace,
  getWorkspaceMembers,
  postWorkspaceMembers,
  updateWorkspaceMember,
  deleteWorkspaceMembers,
} from "api/http/workspace-service";
import {
  getOrganization,
  getOrganizationMembers,
} from "api/http/organization-service";
import { getRoles } from "api/http/role-service";
import {
  useReactTable,
  getCoreRowModel,
  createColumnHelper,
} from "@tanstack/react-table";
import type { Organization, Workspace } from "models/organization";

// type declaration for organization table rows
type OrganizationMember = {
  id: string;
  role_id: string;
  user: {
    id: string;
    email: string;
  };
};

// type declaration for table rows
type WorkspaceMember = {
  id: string;
  role_id: string;
  user: {
    id: string;
    email: string;
  };
};
// type declaration for role options for role selector dropdown
type RoleOption = {
  label: string;
  value: string;
};

export function WorkspaceMembers() {
  const userID = useUserState((slice) => slice.userID);
  const { workspaceID = "", orgID = "" } = useParams();
  const addToast = useToastsState((slice) => slice.addToast);
  const [organization, setOrganization] = useState<Organization>();
  const [workspace, setWorkspace] = useState<Workspace>();

  const [organizationRoleOptions, setOrganizationRoleOptions] = useState<
    RoleOption[]
  >([]);
  const [workspaceRoleOptions, setWorkspaceRoleOptions] = useState<
    RoleOption[]
  >([]);

  // Fetch organization members and workspace members when the component loads.
  useEffect(() => {
    async function initializeWorkspace() {
      const organizationResult = await getOrganization(orgID);
      setOrganization(organizationResult);
      const workspaceResult = await getWorkspace(orgID, workspaceID);
      setWorkspace(workspaceResult);
      updateWorkspaceMembers(workspaceID);
      updateOrganizationMembers(orgID);
      const allRoles = await getRoles(orgID);
      setOrganizationRoleOptions(
        allRoles
          .filter(
            (role) =>
              role.name === "Organization Admin" || role.name === "Member"
          )
          .map((nextRole) => {
            return { label: nextRole.name, value: nextRole.id };
          })
      );
      setWorkspaceRoleOptions(
        allRoles
          .filter(
            (role) =>
              (role.name !== "Super Admin" &&
                role.name !== "Organization Admin" &&
                role.name !== "Member") ||
              role.organization_id === orgID
          )
          .map((nextRole) => {
            return { label: nextRole.name, value: nextRole.id };
          })
      );
    }
    if (userID && workspaceID) {
      initializeWorkspace();
    }
  }, [userID, orgID, workspaceID]);

  // States for organization members table
  const [allOrganizationMembers, setAllOrganizationMembers] = useState<any[]>(
    []
  );
  const [orgTableData, setOrgTableData] = useState<any[]>([]);
  const [checkedForAddition, setCheckedForAddition] = useState<string[]>([]);
  const [isOrgSelectedAll, setOrgIsSelectedAll] = useState(false);

  // Handle select all for the organization table
  const handleOrgSelectAll = () => {
    if (isOrgSelectedAll) {
      setCheckedForAddition([]);
    } else {
      setCheckedForAddition(
        orgTableData.map((nextItem: OrganizationMember) => nextItem.user.id)
      );
    }
  };

  // Check if all items are selected in the organization table
  useEffect(() => {
    if (checkedForAddition.length === 0) {
      setOrgIsSelectedAll(false);
      return;
    }
    for (const nextItem of orgTableData) {
      if (!checkedForAddition.includes(nextItem.user.id)) {
        setOrgIsSelectedAll(false);
        return;
      }
    }
    setOrgIsSelectedAll(true);
  }, [checkedForAddition]);

  // States for workspace membership table
  const [workspaceTableData, setWorkspaceTableData] = useState<any[]>([]);
  const [checkedForDeletion, setCheckedForDeletion] = useState<string[]>([]);
  const [isWorkspaceSelectedAll, setIsWorkspaceSelectedAll] = useState(false);

  // Handle select all for the workspace table
  const handleSelectAll = () => {
    if (isWorkspaceSelectedAll) {
      setCheckedForDeletion([]);
    } else {
      setCheckedForDeletion(
        workspaceTableData.map((nextItem: WorkspaceMember) => nextItem.user.id)
      );
    }
  };

  // Check if all items are selected in the workspace table
  useEffect(() => {
    if (checkedForDeletion.length === 0) {
      setIsWorkspaceSelectedAll(false);
      return;
    }
    for (const nextItem of workspaceTableData) {
      if (!checkedForDeletion.includes(nextItem.user.id)) {
        setIsWorkspaceSelectedAll(false);
        return;
      }
    }
    setIsWorkspaceSelectedAll(true);
  }, [checkedForDeletion]);

  // Check if a user is an admin of the workspace
  const isWorkspaceAdmin = () => {
    if (
      organization?.role === "Organization Admin" ||
      workspace?.is_admin === true
    ) {
      return true;
    }
    return false;
  };

  // Check if a user is already in the workspace
  const isInWorkspace = (id: string) => {
    const found = workspaceTableData.findIndex(
      (nextRow) => nextRow.user.id === id
    );
    return found !== -1;
  };

  // Update the list of organization members
  const updateOrganizationMembers = (organizationId: string) => {
    getOrganizationMembers(organizationId)
      .then((returnedValues) => {
        setAllOrganizationMembers(returnedValues);
      })
      .catch((error) => {
        addToast({
          message: error.message,
          variant: "error",
          duration: 1000,
        });
      });
  };

  // Update the list of workspace members
  const updateWorkspaceMembers = (workspaceId: string) => {
    getWorkspaceMembers(orgID, workspaceId)
      .then((returnedValues) => {
        setWorkspaceTableData(returnedValues);
      })
      .catch((error) => {
        addToast({
          message: error.message,
          variant: "error",
          duration: 1000,
        });
      });
  };

  // When the workspace or organization members change, update the organization table data
  useEffect(() => {
    if (!allOrganizationMembers || allOrganizationMembers.length === 0) {
      return;
    }
    const filtererOrganizationMembers = allOrganizationMembers.filter(
      (nextMember) => {
        return !isInWorkspace(nextMember.user.id);
      }
    );
    setOrgTableData(filtererOrganizationMembers);
  }, [workspaceTableData, allOrganizationMembers]);

  // Get an organization role name from the id
  const getOrgRoleLabelFromId = (roleId: string) => {
    if (!organizationRoleOptions || organizationRoleOptions.length === 0) {
      return "";
    }
    const selectedOption = organizationRoleOptions.find((option) => {
      return option.value === roleId;
    });
    return selectedOption?.label ? selectedOption.label : "";
  };

  // Get a workspace role name from the id
  const getWorkspaceRoleLabelFromId = (roleId: string) => {
    if (!workspaceRoleOptions || workspaceRoleOptions.length === 0) {
      return "";
    }
    const selectedOption = workspaceRoleOptions.find((option) => {
      return option.value === roleId;
    });
    return selectedOption?.label ? selectedOption.label : "";
  };

  const addMembers = () => {
    postWorkspaceMembers(orgID, workspaceID, checkedForAddition)
      .then(() => {
        addToast({
          message: "Member(s) successfully added to workspace.",
          variant: "success",
        });
        updateWorkspaceMembers(workspaceID);
        setCheckedForAddition([]);
      })
      .catch((error) => {
        addToast({
          message: error.message,
          variant: "error",
        });
      });
  };

  const removeMembers = () => {
    deleteWorkspaceMembers(orgID, workspaceID, checkedForDeletion)
      .then(() => {
        addToast({
          message: "Member(s) successfully deleted from workspace.",
          variant: "success",
        });
        updateWorkspaceMembers(workspaceID);
        setCheckedForDeletion([]);
      })
      .catch((error) => {
        addToast({
          message: error.message,
          variant: "error",
        });
      });
  };

  // Organization Table column definitions
  const organizationColumnHelper = createColumnHelper<OrganizationMember>();
  const organizationColumns = useMemo(
    () => [
      organizationColumnHelper.accessor((row) => row.user.id, {
        id: "checkbox",
        cell: (info) => {
          const handleCheck = (check: boolean, rowId: string) => {
            if (check) {
              setCheckedForAddition([...checkedForAddition, rowId]);
            } else {
              setCheckedForAddition(
                checkedForAddition.filter((value) => value !== rowId)
              );
            }
          };
          return (
            <div className={styles.checkboxWrapper}>
              <Checkbox
                onChange={() => {
                  handleCheck(
                    !checkedForAddition.includes(info.getValue()),
                    info.getValue()
                  );
                }}
                checked={checkedForAddition.includes(info.getValue())}
                disabled={isInWorkspace(info.getValue()) || !isWorkspaceAdmin()}
              />
            </div>
          );
        },
        header: () => (
          <Checkbox
            checked={isOrgSelectedAll}
            onChange={handleOrgSelectAll}
            disabled={!isWorkspaceAdmin()}
          />
        ),
        enableSorting: false,
      }),
      organizationColumnHelper.accessor((row) => row.user.email, {
        id: "email",
        cell: (info) => (
          <div
            className={cn(styles.emailCell, {
              [styles.mutedRow]:
                isInWorkspace(info.row.original.user.id) || !isWorkspaceAdmin(),
            })}
          >
            {info.getValue()}
          </div>
        ),
        header: () => <div className={styles.emailHeader}>User Email</div>,
        enableSorting: false,
      }),
      organizationColumnHelper.accessor((row) => row.role_id, {
        id: "role",
        cell: (info) => (
          <div
            className={cn(styles.textCell, {
              [styles.mutedRow]:
                isInWorkspace(info.row.original.user.id) || !isWorkspaceAdmin(),
            })}
          >
            {getOrgRoleLabelFromId(info.getValue())}
          </div>
        ),
        header: () => <div className={styles.textCell}>Organization Role</div>,
        enableSorting: false,
      }),
    ],
    [
      checkedForAddition,
      isOrgSelectedAll,
      orgTableData,
      workspaceTableData,
      organizationRoleOptions,
    ]
  );

  // Workspace table column definitions
  const workspaceColumnHelper = createColumnHelper<WorkspaceMember>();
  const workspaceColumns = useMemo(
    () => [
      workspaceColumnHelper.accessor((row) => row.user.id, {
        id: "checkbox",
        cell: (info) => {
          const handleCheck = (check: boolean, rowId: string) => {
            if (check) {
              setCheckedForDeletion([...checkedForDeletion, rowId]);
            } else {
              setCheckedForDeletion(
                checkedForDeletion.filter((value) => value !== rowId)
              );
            }
          };
          return (
            <div className={styles.checkboxWrapper}>
              <Checkbox
                onChange={() => {
                  handleCheck(
                    !checkedForDeletion.includes(info.getValue()),
                    info.getValue()
                  );
                }}
                checked={checkedForDeletion.includes(info.getValue())}
                disabled={!isWorkspaceAdmin()}
              />
            </div>
          );
        },
        header: () => (
          <Checkbox
            checked={isWorkspaceSelectedAll}
            onChange={handleSelectAll}
            disabled={!isWorkspaceAdmin()}
          />
        ),
        enableSorting: false,
      }),
      workspaceColumnHelper.accessor((row) => row.user.email, {
        id: "email",
        cell: (info) => (
          <div
            className={cn(styles.emailCell, {
              [styles.mutedRow]: !isWorkspaceAdmin(),
            })}
          >
            {info.getValue()}
          </div>
        ),
        header: () => <div className={styles.emailHeader}>User Email</div>,
        enableSorting: false,
      }),
      workspaceColumnHelper.accessor((row) => row.role_id, {
        id: "role_select",
        cell: (info) => {
          const handleChange = (roleId) => {
            updateWorkspaceMember(
              orgID,
              workspaceID,
              info.row.original.user.id,
              roleId
            ).then(() => {
              addToast({
                message: "Members role successfully changed.",
                variant: "success",
              });
              updateWorkspaceMembers(workspaceID);
            });
          };
          return (
            <div style={{ width: "95%" }}>
              <FormDropdown
                id="role"
                name="role"
                label=""
                placeholder="Select Role"
                options={workspaceRoleOptions}
                value={getWorkspaceRoleLabelFromId(info.getValue())}
                onChange={handleChange}
                disabled={!isWorkspaceAdmin()}
                wrapContextMenuWithFloatingPortal
              />
            </div>
          );
        },
        header: () => (
          <div className={styles.textCell}>Workspace Member Role</div>
        ),
        enableSorting: false,
      }),
    ],
    [
      checkedForDeletion,
      isWorkspaceSelectedAll,
      workspaceTableData,
      workspaceRoleOptions,
    ]
  );

  // Create react table for the organization members
  const organizationTable = useReactTable({
    data: orgTableData,
    columns: organizationColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  // Create react table for the workspace members
  const workspaceTable = useReactTable({
    data: workspaceTableData,
    columns: workspaceColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  return !workspaceID ? (
    <div className={styles.emptyBlockWrapper}>
      <EmptyState
        variant="info"
        title="No Workspace..."
        description="You must first set up a workspace before adding members."
      />
    </div>
  ) : (
    <div className={styles.page}>
      <div className={styles.container}>
        <TitleSection
          title="Workspace User Members"
          subTitle={
            <>
              Manage members and users of your workspace and set their access
              level. Only members already in your organization can be added to a
              workspace.
            </>
          }
        />
        <div className={styles.tableSectionContainer}>
          <div className={styles.tableSection}>
            <div className={styles.tableTitle}>Available Members</div>
            <Table table={organizationTable} />
            <div className={styles.buttonSection}>
              <Button
                variant="primary"
                onClick={addMembers}
                disabled={checkedForAddition.length === 0}
              >
                Add Selected Members
              </Button>
            </div>
          </div>
          <div className={styles.tableSection}>
            <div className={styles.tableTitle}>Included Members</div>
            <Table table={workspaceTable} />
            <div className={styles.buttonSection}>
              <Button
                variant="crucial"
                onClick={removeMembers}
                disabled={checkedForDeletion.length === 0}
              >
                Remove Selected Members
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
