import { useEffect, useRef } from "react";
import { useLocation, type Location } from "react-router-dom";

type UseLocationChangeProps = {
  onChange: (prevLocation?: Location, nextLocation?: Location) => void;
};

export function useLocationChange({ onChange }: UseLocationChangeProps) {
  const location = useLocation();
  const prevLocationRef = useRef(location);
  const onChangeRef = useRef(onChange);

  // store actual callback in ref so we can always call the latest version
  useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  // fire onChangeRef when location changes
  useEffect(() => {
    if (prevLocationRef.current.key === location.key) {
      // prevent firing onChange on mount
      return;
    }
    onChangeRef.current(prevLocationRef.current, location);
    prevLocationRef.current = location;
  }, [location]);
}
