import styles from "./Breadcrumbs.module.scss";
import { Link } from "react-router-dom";
import Logo from "assets/icons/logo.svg?react";
import { RootCrumb, RootCrumbSkeleton, WorkspaceCrumb } from "layout/Layout";
import type { Organization, Workspace } from "models/organization";

interface BreadcrumbsProps {
  isLoading: boolean;
  userAvatar: string;
  userName: string;
  organizations: Organization[];
  organizationId: Organization["id"];
  workspaceId: Workspace["id"];
}

export function Breadcrumbs({
  isLoading,
  userAvatar,
  userName,
  organizations,
  organizationId,
  workspaceId,
}: BreadcrumbsProps) {
  return (
    <div className={styles.breadcrumbs}>
      <Link to="/" className={styles.logoLink}>
        <Logo className={styles.logo} />
      </Link>

      <div className={styles.divider} />

      {isLoading ? (
        <RootCrumbSkeleton />
      ) : (
        <>
          <RootCrumb
            userAvatar={userAvatar}
            userName={userName}
            organizations={organizations}
            organizationId={organizationId}
          />

          {organizationId && workspaceId ? (
            <>
              <div className={styles.divider} />
              <WorkspaceCrumb
                organizations={organizations}
                organizationId={organizationId}
                workspaceId={workspaceId}
              />
            </>
          ) : null}
        </>
      )}
    </div>
  );
}
