import { v4 as uuidv4 } from "uuid";

const SESSION_ID = "session_id";
const SESSION_USED_AT = "session_used_at";

const SESSION_EXPIRATION = 1000 * 60 * 30; // 30 minutes

/**
 * Generate a new session id and store it in the local storage.
 */
function startSession() {
  const sessionId = uuidv4();
  const timestamp = String(Date.now());

  localStorage.setItem(SESSION_ID, sessionId);
  localStorage.setItem(SESSION_USED_AT, timestamp);

  return { sessionId, usedAt: timestamp };
}

/**
 * Get the current session id from the local storage.
 * If the session is expired, a new one will be generated.
 * If the session id did exist, it will be bumped to keep it alive.
 */
export function getSessionId() {
  let sessionId = localStorage.getItem(SESSION_ID);
  const usedAt = localStorage.getItem(SESSION_USED_AT);
  const timestamp = Date.now();

  if (
    !sessionId ||
    !usedAt ||
    timestamp - Number(usedAt) > SESSION_EXPIRATION // expired
  ) {
    // start new session if the previous expired or never existed
    const newSession = startSession();
    sessionId = newSession.sessionId;
  } else {
    // bump session to keep it alive
    localStorage.setItem(SESSION_USED_AT, String(timestamp));
  }

  return sessionId;
}

/**
 * Remove session from the local storage.
 */
export function stopSession() {
  localStorage.removeItem(SESSION_ID);
  localStorage.removeItem(SESSION_USED_AT);
}
