import { Navigate, type RouteObject } from "react-router-dom";

import {
  UserSettingsProfile,
  UserSettingsPreferences,
  UserSettingsBilling,
  UserSettingsSecurity,
  UserSettingsDeveloperAPI,
  UserSettingsGitIntegration,
} from "components/Settings";

export const PERSONAL_SETTINGS_ROUTES: RouteObject[] = [
  {
    index: true,
    element: <Navigate replace to="personal-profile" />,
  },
  {
    path: "personal-profile",
    element: <UserSettingsProfile />,
  },
  {
    path: "personal-preferences",
    element: <UserSettingsPreferences />,
  },
  {
    path: "personal-billing",
    element: <UserSettingsBilling />,
  },
  {
    path: "personal-security",
    element: <UserSettingsSecurity />,
  },
  {
    path: "developer-api",
    element: <UserSettingsDeveloperAPI />,
  },
  {
    path: "git-integration",
    element: <UserSettingsGitIntegration />,
  },
];
