import styles from "./ConfirmLayersDeletionModal.module.scss";
import { useMemo } from "react";
import { useCanvasState } from "store";
import { LAYER_DETAILS } from "config/canvasConfig";
import type { LayerType } from "models/canvas";

const MAX_LAYERS_TO_SHOW = 5;

type ConfirmLayersDeletionModalProps = {
  layerIds: LayerType["id"][];
};

export function ConfirmLayersDeletionModal({
  layerIds,
}: ConfirmLayersDeletionModalProps) {
  const layers = useCanvasState((slice) => slice.layers);

  const layersToDisplay = useMemo(() => {
    return layerIds.slice(0, MAX_LAYERS_TO_SHOW);
  }, [layerIds]);

  const extraCount = useMemo(() => {
    return layerIds.length - layersToDisplay.length;
  }, [layerIds, layersToDisplay]);

  return (
    <>
      {/* Question */}
      {layerIds.length === 1
        ? "Are you sure you want to delete this layer?"
        : `Are you sure you want to delete these ${layerIds.length} layers?`}

      {/* Layers list */}
      <ul className={styles.layersList}>
        {layersToDisplay.map((layerId) => {
          const layer = layers.get(layerId);
          if (!layer) {
            return null;
          }

          const Icon = LAYER_DETAILS[layer.type].Icon;

          return (
            <li key={layerId} className={styles.listItem}>
              <span className={styles.iconWithName}>
                {Icon ? <Icon className={styles.typeIcon} /> : null}
                {layer.name}
              </span>
            </li>
          );
        })}
      </ul>

      {extraCount > 0 ? (
        <div className={styles.extraCount}>... and {extraCount} more</div>
      ) : null}
    </>
  );
}
