import styles from "./UserSettingsPreferences.module.scss";
import { useMemo } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useUserSettingsState, useLocalCanvasPreferencesState } from "store";
import { useUserSettings } from "hooks/useUserSettings";
import { DropdownMenuButton } from "components/common/DropdownMenuButton/DropdownMenuButton";
import { type ContextMenuItem } from "components/common/ContextMenu/ContextMenu";
import Switch from "components/common/Switch/Switch";
import Typography from "components/Typography/Typography";
import { UserSettingsTabHeader } from "components/UserSettingsTabHeader";
import { FIELD_NAME } from "components/Settings/UserSettingsPreferences/config";
import { AI_MODEL } from "config/appConfig";

const fontSizeOptions = [
  { label: "8px", id: 8, value: 8 },
  { label: "10px", id: 10, value: 10 },
  { label: "12px", id: 12, value: 12 },
  { label: "14px", id: 14, value: 14 },
  { label: "16px", id: 16, value: 16 },
  { label: "18px", id: 18, value: 18 },
  { label: "20px", id: 20, value: 20 },
];

const aiModelOptions = [
  {
    label: "OpenAI - GPT",
    value: AI_MODEL.OPENAI_GPT,
    id: AI_MODEL.OPENAI_GPT,
  },
  {
    label: "Bedrock Claude",
    value: AI_MODEL.BEDROCK_CLAUDE,
    id: AI_MODEL.BEDROCK_CLAUDE,
  },
  {
    label: "Mistral",
    value: AI_MODEL.MISTRAL,
    id: AI_MODEL.MISTRAL,
  },
];

export default function UserSettingsPreferences() {
  const { updatePreferences } = useUserSettings();
  const flags = useFlags();

  const showLineNumbers = useUserSettingsState(
    (slice) => slice.preferences.showLineNumbers
  );
  const codeFontSize = useUserSettingsState(
    (slice) => slice.preferences.codeFontSize
  );
  const aiModel = useUserSettingsState((slice) => slice.preferences.aiModel);
  const autoComplete = useUserSettingsState(
    (slice) => slice.preferences.autoComplete
  );
  const canvasDontAskBeforeBlockOrLayerDeletion =
    useLocalCanvasPreferencesState(
      (slice) => slice.canvasDontAskBeforeBlockOrLayerDeletion
    );
  const canvasDontAskBeforeMovingFiles = useLocalCanvasPreferencesState(
    (slice) => slice.canvasDontAskBeforeMovingFiles
  );
  const setCanvasDontAskBeforeBlockOrLayerDeletion =
    useLocalCanvasPreferencesState(
      (slice) => slice.setCanvasDontAskBeforeBlockOrLayerDeletion
    );
  const setCanvasDontAskBeforeMovingFiles = useLocalCanvasPreferencesState(
    (slice) => slice.setCanvasDontAskBeforeMovingFiles
  );

  const fontSizeMenuOptions = useMemo<ContextMenuItem[]>(() => {
    return fontSizeOptions.map((option) => ({
      key: option.label,
      type: "option",
      label: option.label,
      onClick: () => {
        updatePreferences(FIELD_NAME.codeFontSize, option.value);
      },
    }));
  }, [updatePreferences]);

  const aiModelMenuOptions = useMemo<ContextMenuItem[]>(() => {
    return aiModelOptions.map((option) => ({
      key: option.label,
      type: "option",
      label: option.label,
      onClick: () => {
        updatePreferences(FIELD_NAME.aiModel, option.value);
      },
    }));
  }, [updatePreferences]);

  const selectedFontSizeLabel = useMemo(() => {
    return fontSizeOptions.find((item) => item.value === codeFontSize)?.label;
  }, [codeFontSize]);

  const seletedAIModel = useMemo(() => {
    return aiModelOptions.find((item) => item.value === aiModel)?.label;
  }, [aiModel]);

  return (
    <div className={styles.container}>
      <UserSettingsTabHeader
        heading="User Preferences"
        subHeading="Manage your preferences."
      />

      <div className={styles.heading}>Code Editor</div>

      {/* Show line numbers */}
      <div className={styles.settingRow}>
        <div>
          <Typography variant="h3">Show line numbers</Typography>
          <Typography variant="body2" className={styles.subHeading}>
            Display line numbers in the editor.
          </Typography>
        </div>
        <Switch
          isChecked={showLineNumbers}
          ariaLabel="Show line numbers"
          onChange={(newValue) => {
            updatePreferences(FIELD_NAME.showLineNumbers, newValue);
          }}
        />
      </div>

      {/* Code Font Size */}
      <div className={styles.settingRow}>
        <div>
          <Typography variant="h3">Code Font Size</Typography>
          <Typography variant="body2" className={styles.subHeading}>
            Adjust the font size used in the code editor.
          </Typography>
        </div>

        <DropdownMenuButton
          buttonVariant="text"
          menuOptions={fontSizeMenuOptions}
        >
          {selectedFontSizeLabel}
        </DropdownMenuButton>
      </div>

      {flags.enableCodeComplete ? (
        <>
          {/* Enable code completion */}
          <div className={styles.settingRow}>
            <div>
              <Typography variant="h3">Enable code completion</Typography>
              <Typography variant="body2" className={styles.subHeading}>
                Enable code completion in code editor.
              </Typography>
            </div>
            <Switch
              isChecked={autoComplete}
              ariaLabel="Enable code completion"
              onChange={(newValue) => {
                updatePreferences(FIELD_NAME.autoComplete, newValue);
              }}
            />
          </div>

          {/* AI Model */}
          <div className={styles.settingRow}>
            <div>
              <Typography variant="h3">AI Model</Typography>
              <Typography variant="body2" className={styles.subHeading}>
                Chose the AI model used for code completion.
              </Typography>
            </div>

            <DropdownMenuButton
              buttonVariant="text"
              menuOptions={aiModelMenuOptions}
            >
              {seletedAIModel}
            </DropdownMenuButton>
          </div>
        </>
      ) : null}

      <div className={styles.heading}>Canvas</div>

      {/* Don't ask before deleting blocks or layers */}
      <div className={styles.settingRow}>
        <div>
          <Typography variant="h3">
            Don&apos;t ask before deleting blocks or layers
          </Typography>
          <Typography variant="body2" className={styles.subHeading}>
            If enabled, blocks or layers will be deleted without asking for
            confirmation.
          </Typography>
        </div>
        <Switch
          isChecked={canvasDontAskBeforeBlockOrLayerDeletion}
          ariaLabel="Don't ask before deleting blocks or layers"
          onChange={(dontAsk) => {
            setCanvasDontAskBeforeBlockOrLayerDeletion(dontAsk);
          }}
        />
      </div>

      {/* Don't ask before moving files or folders */}
      <div className={styles.settingRow}>
        <div>
          <Typography variant="h3">
            Don&apos;t ask before moving files or folders
          </Typography>
          <Typography variant="body2" className={styles.subHeading}>
            If enabled, files or folders will be moved without asking for
            confirmation.
          </Typography>
        </div>
        <Switch
          isChecked={canvasDontAskBeforeMovingFiles}
          ariaLabel="Don't ask before moving files or folders"
          onChange={(dontAsk) => {
            setCanvasDontAskBeforeMovingFiles(dontAsk);
          }}
        />
      </div>
    </div>
  );
}
