import styles from "./SelfHostingStatus.module.scss";
import { useState, useEffect, useCallback, Fragment } from "react";
import { BiCloudUpload, BiRefresh } from "react-icons/bi";
import { useFlags } from "launchdarkly-react-client-sdk";
import capitalize from "lodash-es/capitalize";
import { useConfirmModal } from "hooks/useConfirmModal";
import { useToastsState } from "store";
import Loader from "components/common/Loader/Loader";
import { Button } from "components/common/Button/Button";
import ToasterMessage from "components/common/ToasterMessage/ToasterMessage";
import { Skeleton } from "components/common/Skeleton/Skeleton";
import {
  postDefaultExecutors,
  getDefaultExecutors,
} from "api/http/organization-service";
import { EmptyState } from "components/common/EmptyState/EmptyState";
import { SelfHostingStatusBadge } from "components/organization/SelfHostingStatus/SelfHostingStatusBadge/SelfHostingStatusBadge";
import { debouncePromise } from "utils/helpers";
import { COMPUTE_ENV_TYPE } from "config/deploymentConfig";
import { type EXECUTOR_STATUS } from "config/organizationsConfig";

type SelfHostingStatusProps = {
  organizationId: string;
  settingsId: string;
};

enum Language {
  python = "python",
  r = "r",
}

type BuildLog = {
  build_log: {
    [computeEnvType in COMPUTE_ENV_TYPE]: {
      [language in Language]: {
        status: EXECUTOR_STATUS;
        last_updated_at: string;
      };
    };
  };
};

type StatusEntry = {
  label: string;
  status: EXECUTOR_STATUS;
  requiresFeatureFlag: string | undefined;
};

function executorBuildLogToStatus(data: BuildLog): StatusEntry[] {
  const computeEnvTypeLabels = {
    [COMPUTE_ENV_TYPE.AWS_LAMBDA]: "Lambda",
    [COMPUTE_ENV_TYPE.AWS_FARGATE]: "Fargate",
    [COMPUTE_ENV_TYPE.AWS_SAGEMAKER]: "SageMaker",
  };
  const langFeatureFlags = {
    [Language.r]: "enableRLanguage",
  };
  const computeEnvFeatureFlags = {
    [COMPUTE_ENV_TYPE.AWS_SAGEMAKER]: "enableGPUCompute",
  };
  const result: StatusEntry[] = [];

  for (const [computeEnvType, statuses] of Object.entries(data.build_log)) {
    for (const [language, { status }] of Object.entries(statuses)) {
      const computeEnvTypeLabel =
        computeEnvTypeLabels[computeEnvType] || "Unknown environment";
      const label = `${computeEnvTypeLabel} (${capitalize(language)})`;

      result.push({
        label: label,
        status: status,
        requiresFeatureFlag:
          computeEnvFeatureFlags[computeEnvType] || langFeatureFlags[language],
      });
    }
  }

  return result;
}

type StatusBadgesProps = {
  entries: StatusEntry[];
};

function StatusBadges({ entries }: StatusBadgesProps) {
  const flags = useFlags();
  const visibleEntries = entries.filter(({ requiresFeatureFlag }) => {
    if (requiresFeatureFlag) {
      return flags[requiresFeatureFlag];
    }
    return true;
  });
  return (
    <dl className={styles.statuses}>
      {visibleEntries.map(({ label, status }, index) => (
        <Fragment key={index}>
          <dt key={index}>{label}:</dt>
          <dd>
            <SelfHostingStatusBadge status={status} />
          </dd>
        </Fragment>
      ))}
    </dl>
  );
}

export function SelfHostingStatus({
  organizationId,
  settingsId,
}: SelfHostingStatusProps) {
  const { openConfirmModal } = useConfirmModal();
  const addToast = useToastsState((slice) => slice.addToast);
  const [isStatusFetching, setIsStatusFetching] = useState(false);
  const [isRedeploying, setIsRedeploying] = useState(false);
  const [statusEntries, setStatusEntries] = useState<StatusEntry[]>([]);
  const [statusError, setStatusError] = useState(null);

  const fetchStatus = useCallback(async () => {
    setIsStatusFetching(true);
    setStatusEntries([]);
    setStatusError(null);

    try {
      const data = await debouncePromise({
        promise: getDefaultExecutors(organizationId),
      });
      setStatusEntries(executorBuildLogToStatus(data));
    } catch (rejection: any) {
      const errorMessage =
        typeof rejection?.cause?.detail === "string"
          ? rejection?.cause?.detail
          : "Failed to fetch self-hosting status. Try again later or contact support.";
      setStatusError(errorMessage);
    }

    setIsStatusFetching(false);
  }, [organizationId]);

  const handleReDeploy = useCallback(async () => {
    const confirmed = await openConfirmModal({
      title: "Re-deploy",
      message: "Are you sure you want to re-deploy?",
    });
    if (!confirmed) {
      return;
    }
    setIsRedeploying(true);
    try {
      await postDefaultExecutors(organizationId);
      addToast({
        message: "Re-deployment successfully initiated",
        variant: "success",
      });
    } catch (rejection: any) {
      const defaultMessage = "Failed to re-deploy";
      addToast({
        message:
          typeof rejection?.cause?.detail === "string"
            ? rejection?.cause.detail
            : defaultMessage,
        variant: "error",
      });
    }
    setIsRedeploying(false);
  }, [organizationId]);

  // fetch status on init
  useEffect(() => {
    if (statusEntries || statusError || !settingsId || isStatusFetching) {
      return;
    }
    fetchStatus();
  }, [statusEntries, statusError, settingsId, isStatusFetching, fetchStatus]);

  return (
    <div className={styles.container}>
      {settingsId ? (
        <>
          {/* Refresh/Re-Deploy buttons */}
          <div className={styles.controls}>
            <Button
              variant="secondary"
              className={styles.button}
              onClick={fetchStatus}
              disabled={isStatusFetching}
            >
              {isStatusFetching ? (
                <Loader size={16} />
              ) : (
                <BiRefresh size={16} />
              )}
              <span>Refresh status</span>
            </Button>

            <Button
              variant="secondary"
              className={styles.button}
              disabled={!settingsId || isRedeploying}
              onClick={handleReDeploy}
            >
              {isRedeploying ? (
                <Loader size={16} />
              ) : (
                <BiCloudUpload size={16} />
              )}
              <span>Re-Deploy</span>
            </Button>
          </div>
          {/* Error message */}
          {statusError ? (
            <ToasterMessage
              fullWidth
              variant="error"
              animate={false}
              className={styles.statusError}
            >
              {statusError}
            </ToasterMessage>
          ) : null}
          {/* Skeleton placeholder */}
          {isStatusFetching ? (
            <dl className={styles.statuses}>
              {new Array(2).fill(null).map((_, index) => {
                return (
                  <Fragment key={index}>
                    <dt>
                      <Skeleton className={styles.statusSkeleton} />
                    </dt>
                    <dd>
                      <Skeleton className={styles.statusBadgeSkeleton} />
                    </dd>
                  </Fragment>
                );
              })}
            </dl>
          ) : null}
          {/* Status */}
          <StatusBadges entries={statusEntries} />
        </>
      ) : (
        <EmptyState
          variant="info"
          containerClassName={styles.emptyBlock}
          title="No self-hosting settings"
          description="To run Zerve on your own cloud, configure the self-hosting settings below."
        />
      )}
    </div>
  );
}
