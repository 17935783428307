import { type StateCreator } from "store";
import {
  functionsAssetsSlice,
  constantsAssetsSlice,
  connectionsAssetsSlice,
  queriesAssetsSlice,
  type FunctionsAssetsSlice,
  type ConstantsAssetsSlice,
  type ConnectionsAssetsSlice,
  type QueriesAssetsSlice,
} from "./assets";

export interface AssetsSlice
  extends FunctionsAssetsSlice,
    ConstantsAssetsSlice,
    ConnectionsAssetsSlice,
    QueriesAssetsSlice {}

export const createAssetsSlice: StateCreator<AssetsSlice> = (
  set,
  getState,
  store
) => {
  const functionsInitialState = functionsAssetsSlice(set, getState, store);
  const constantsInitialState = constantsAssetsSlice(set, getState, store);
  const connectionsInitialState = connectionsAssetsSlice(set, getState, store);
  const queriesInitialState = queriesAssetsSlice(set, getState, store);

  return {
    ...functionsInitialState,
    ...constantsInitialState,
    ...connectionsInitialState,
    ...queriesInitialState,
  };
};
