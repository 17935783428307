import styles from "./SidebarAccordion.module.scss";
import { useMemo, type PropsWithChildren } from "react";
import { MotionConfig } from "framer-motion";
import { SidebarAccordionContext } from "./SidebarAccordionContext";
import { Item } from "./Item/Item";
import { Header } from "./Header/Header";
import { Body } from "./Body/Body";

type SidebarAccordionProps = PropsWithChildren<{
  activeIds: Set<string>;
  onToggleId: (id: string) => void;
}>;

function SidebarAccordion({
  children,
  activeIds,
  onToggleId,
}: SidebarAccordionProps) {
  const contextValue = useMemo(() => {
    return { activeIds, onToggleId };
  }, [activeIds, onToggleId]);

  return (
    <SidebarAccordionContext.Provider value={contextValue}>
      <div className={styles.container}>
        <MotionConfig transition={{ duration: 0.25, ease: "easeInOut" }}>
          {children}
        </MotionConfig>
      </div>
    </SidebarAccordionContext.Provider>
  );
}

export default Object.assign(SidebarAccordion, { Item, Header, Body });
