import styles from "./EditableRow.module.scss";
import { type MouseEvent, type KeyboardEvent } from "react";
import { BiTrash } from "react-icons/bi";
import cn from "classnames";
import IconButton from "components/common/IconButton/IconButton";
import {
  type Column,
  type ColumnsErrorData,
} from "components/EditableTable/config";
import { EditableCell } from "components/EditableTable/EditableCell";

type EditableRowProps<Key extends string> = {
  columns: Column<Key>[];
  row: Record<Key, string>;
  rowErrors?: ColumnsErrorData<Key>;
  readOnly: boolean;
  disabled?: boolean;
  containerClassName?: string;
  inputClassName?: string;
  autofocusFirstInput?: boolean;
  onRowDelete: (e: MouseEvent<HTMLButtonElement>) => void;
  onValueChange: (value: string, key: Key) => void;
  onKeyDown: (e: KeyboardEvent<HTMLInputElement>) => void;
};

export function EditableRow<Key extends string>({
  columns,
  row,
  rowErrors,
  readOnly,
  disabled,
  containerClassName,
  inputClassName,
  autofocusFirstInput,
  onRowDelete,
  onValueChange,
  onKeyDown,
}: EditableRowProps<Key>) {
  return (
    <div className={cn(styles.container, containerClassName)}>
      {columns.map((column, columnIndex) => {
        const { key, placeholder } = column;
        const value = row[key] || "";
        return (
          <EditableCell
            key={key}
            autoFocus={autofocusFirstInput && columnIndex === 0}
            placeholder={placeholder}
            readOnly={readOnly}
            disabled={disabled}
            value={value}
            error={rowErrors?.[key]?.message || ""}
            inputClassName={cn(styles.input, inputClassName)}
            onChange={(value) => {
              onValueChange(value, key);
            }}
            onKeyDown={onKeyDown}
          />
        );
      })}

      {!readOnly && !disabled ? (
        <IconButton
          tooltip="Delete"
          variant="plain"
          tooltipClassName={styles.deleteButtonWrapper}
          className={styles.deleteButton}
          onClick={onRowDelete}
        >
          <BiTrash />
        </IconButton>
      ) : null}
    </div>
  );
}
