import PanelRowButton from "components/common/PanelRowButton/PanelRowButton";
import {
  EditableTable,
  type EditableTableProps,
} from "components/EditableTable";
import {
  displayColumnsDataKey,
  originalRowDataKey,
  indexKey,
  rowTypeKey,
  TABLE_ROW_TYPE,
  type ColumnsErrorData,
} from "components/EditableTable/config";
import type { Requirement } from "models/requirements";
import type { VALIDATION_STATUS } from "config/appConfig";

enum COLUMN_KEY {
  NAME = "name",
  OPERATOR = "operator",
  VERSION = "version",
  REQUIRED = "required",
}

type TableColumnKey =
  | COLUMN_KEY.NAME
  | COLUMN_KEY.OPERATOR
  | COLUMN_KEY.VERSION;

const COLUMNS = [
  {
    label: "Name",
    key: COLUMN_KEY.NAME as TableColumnKey,
    searchable: true,
    sortable: true,
    placeholder: "required",
  },
  {
    label: "Operator",
    key: COLUMN_KEY.OPERATOR as TableColumnKey,
    searchable: false,
    sortable: false,
    placeholder: "==",
  },
  {
    label: "Version",
    key: COLUMN_KEY.VERSION as TableColumnKey,
    searchable: true,
    sortable: true,
    placeholder: "optional",
  },
];

const ALLOWED_OPERATORS = new Set(["==", ">=", "<=", ">", "<"]);

type RequirementsTableProps = {
  requirements: Requirement[];
  searchText?: string;
  readOnly?: boolean;
  setRequirements: (requirements: Requirement[]) => void;
  onValidationStatusChange?: (status: VALIDATION_STATUS) => void;
};

const validateRow: EditableTableProps<
  TableColumnKey,
  Requirement
>["validateRow"] = ({ rowData, rows = [] }) => {
  const name = rowData[displayColumnsDataKey][COLUMN_KEY.NAME];
  const operator = rowData[displayColumnsDataKey][COLUMN_KEY.OPERATOR];
  const version = rowData[displayColumnsDataKey][COLUMN_KEY.VERSION];
  const index = rowData[indexKey];
  const rowType = rowData[rowTypeKey];
  const trimmedName = name.trim();

  const nonRequriedRows = rows.filter((item) => {
    if (item[originalRowDataKey][COLUMN_KEY.REQUIRED]) {
      return false;
    }
    return true;
  });

  const err: ColumnsErrorData<TableColumnKey> = {
    [COLUMN_KEY.NAME]: null,
    [COLUMN_KEY.OPERATOR]: null,
    [COLUMN_KEY.VERSION]: null,
  };

  // check if a required row has a version greater than the current version
  const matchedRequiredRow = rows.find(
    (item) =>
      item[originalRowDataKey][COLUMN_KEY.REQUIRED] &&
      item[displayColumnsDataKey][COLUMN_KEY.NAME] === name
  );

  if (matchedRequiredRow && version) {
    const currentOperator = operator ?? "==";
    const requiredVersion =
      matchedRequiredRow[displayColumnsDataKey][COLUMN_KEY.VERSION];
    const splitVersion = version.split(".");
    const splitRequiredVersion = requiredVersion.split(".");
    const assumedValue = ["<", "==", "<="].includes(operator) ? 0 : null;
    if (
      // there is a case where the required version is the same as the current version
      (currentOperator.includes("=") && version === requiredVersion) ||
      currentOperator.includes(">")
    ) {
      // do nothing, this is always valid
    } else if (currentOperator === "<" && version === requiredVersion) {
      err[COLUMN_KEY.VERSION] = {
        message: "Version must be greater than the required version",
      };
    } else {
      for (let i = 0; i < splitRequiredVersion.length; i++) {
        const versionPart = parseInt(splitVersion[i]);
        const versionPartToUse =
          isNaN(versionPart) && assumedValue !== null
            ? assumedValue
            : versionPart;
        const requiredVersionPart = parseInt(splitRequiredVersion[i]);
        if (isNaN(versionPartToUse) || isNaN(requiredVersionPart)) {
          // nothing to compare
          break;
        } else if (versionPartToUse > requiredVersionPart) {
          // version is greater than the required version
          break;
        } else if (versionPartToUse < requiredVersionPart) {
          // version is less than the required version
          err[COLUMN_KEY.VERSION] = {
            message: "Version must be greater than the required version",
          };
          break;
        }
      }
    }
  }

  if (!trimmedName) {
    err[COLUMN_KEY.NAME] = {
      message:
        version || rowType === TABLE_ROW_TYPE.EDITED_ROW ? "Required" : "",
    };
  }

  if (operator && !ALLOWED_OPERATORS.has(operator)) {
    err[COLUMN_KEY.OPERATOR] = {
      message: "Invalid operator",
    };
  }

  if (trimmedName) {
    const duplicates = nonRequriedRows.filter((item) => {
      if (item[indexKey] === index) {
        return false;
      }

      const itemName = item[displayColumnsDataKey][COLUMN_KEY.NAME];
      return !!itemName && itemName.trim() === trimmedName;
    });

    if (duplicates.length) {
      err[COLUMN_KEY.NAME] = {
        message: "No package duplicates are allowed",
        dependencies: duplicates.map((row) => row[indexKey]),
      };
    }
  }

  return err;
};

export function RequirementsTable({
  requirements,
  searchText = "",
  readOnly = false,
  setRequirements,
  onValidationStatusChange,
}: RequirementsTableProps) {
  return (
    <EditableTable
      rows={requirements}
      columns={COLUMNS}
      searchText={searchText}
      readOnly={readOnly}
      addRowControl={(onRowAdd) => {
        return (
          <PanelRowButton
            label="Add package"
            disabled={readOnly}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onRowAdd({
                name: "",
                operator: "",
                version: "",
                required: false,
              });
            }}
          />
        );
      }}
      getIsRowDisabled={(row) => {
        return row.required;
      }}
      onChange={setRequirements}
      validateRow={validateRow}
      onValidationStatusChange={onValidationStatusChange}
    />
  );
}
