import { useContext } from "react";
import { UserSettingsContext } from "context/UserSettings";

export const useUserSettings = () => {
  const context = useContext(UserSettingsContext);

  if (!context) {
    throw new Error(
      "useUserSettings has to be used within <UserSettingsContext.Provider>"
    );
  }

  return context;
};
