import { USER_API_URL } from "config/appConfig";
import { callAPI } from "./call-api";
import {
  type Invitation,
  type Resource,
  type User,
  type ResourceRequestAccess,
  UserPermission,
  type ResourceAccessRequestResponse,
} from "models/user";

const postAuthSignIn = async (email: any) => {
  return await callAPI({
    url: `${USER_API_URL}/auth/sign_in`,
    method: "POST",
    data: { email },
  });
};

const resendVerificationEmail = async (email: string) => {
  return await callAPI({
    url: `${USER_API_URL}/auth/verification_email`,
    method: "POST",
    data: { email },
  });
};

const getAuthMembership = async (user_id: string, project_id: string) => {
  return await callAPI({
    url: `${USER_API_URL}/auth/${user_id}/${project_id}`,
    method: "GET",
  });
};

// canvas resources

const getResource = async (resource_id: string) => {
  return await callAPI<Resource>({
    url: `${USER_API_URL}/resources/${resource_id}`,
    method: "GET",
  });
};

const deleteCanvasResourceById = async (resource_id: string) => {
  return await callAPI({
    url: `${USER_API_URL}/resources/${resource_id}`,
    method: "DELETE",
  });
};

const putUpdateStarredCanvasResources = async (
  user_id: string,
  resource_id: string,
  starred: boolean
) =>
  await callAPI({
    url: `${USER_API_URL}/resources/user/starred/${user_id}/${resource_id}`,
    method: "PUT",
    data: { starred },
  });

const getResourceMembers = async (resource_id: string) =>
  await callAPI<User[]>({
    url: `${USER_API_URL}/resources/members/${resource_id}`,
    method: "GET",
  });

const getResourceInvites = async (resource_id: string) =>
  await callAPI<Invitation[]>({
    url: `${USER_API_URL}/invites/${resource_id}`,
    method: "GET",
  });

const updateResourceInvites = async (
  resourceId: string,
  userInvites: Record<string, UserPermission>[]
) =>
  await callAPI({
    url: `${USER_API_URL}/invites/update-roles`,
    method: "PUT",
    data: {
      resource_id: resourceId,
      user_invites: userInvites,
    },
  });

const deleteResourceInvite = async (resourceId: string, inviteId: string) =>
  await callAPI({
    method: "DELETE",
    url: `${USER_API_URL}/resources/${resourceId}/invites/${inviteId}`,
  });

const checkResourcePermission = async (
  resource_id: string,
  permission: string
) =>
  await callAPI({
    url: `${USER_API_URL}/auth/permission/resource`,
    method: "POST",
    data: { resource_id, permission },
  }).then((response) => {
    return response;
  });

const inviteUserToResource = async ({
  resourceId,
  userEmail,
  permission = UserPermission.canEdit,
}: {
  resourceId: string;
  userEmail: string;
  permission?: UserPermission;
}) =>
  await callAPI({
    url: `${USER_API_URL}/resources/members`,
    method: "POST",
    data: {
      resource_id: resourceId,
      user_email: userEmail,
      permission,
    },
  });

const updateResourceMember = async (
  resource_id: string,
  user_id: string,
  permission: UserPermission = UserPermission.canEdit
) =>
  await callAPI({
    url: `${USER_API_URL}/resources/members/${resource_id}/${user_id}`,
    method: "PUT",
    data: { permission },
  });

const deleteResourceMember = async (resource_id: string, user_id: string) =>
  await callAPI({
    url: `${USER_API_URL}/resources/members/${resource_id}/${user_id}`,
    method: "DELETE",
  });

// resource access requests
const getResourceAccessRequestMembers = async (resource_access_id: string) =>
  await callAPI<ResourceRequestAccess[]>({
    url: `${USER_API_URL}/resource_access_requests/members/${resource_access_id}`,
    method: "GET",
  });

const updateResourceAccessRequest = async (
  resource_access_id: string,
  status: number
) =>
  await callAPI({
    url: `${USER_API_URL}/resource_access_requests/${resource_access_id}/${status}/read`,
    method: "PUT",
  });

// users
const getUsers = async () =>
  await callAPI({
    url: `${USER_API_URL}/users`,
    method: "GET",
  });

const getUser = async (user_id: string) =>
  await callAPI({
    url: `${USER_API_URL}/users/${user_id}`,
    method: "GET",
  });

const requestResourceAccess = async (canvas_id: string) =>
  await callAPI<ResourceAccessRequestResponse>({
    url: `${USER_API_URL}/resource_access_requests`,
    method: "POST",
    data: {
      canvas_id,
    },
  });

const getUserResourceRequestedAccess = async (canvas_id: string) =>
  await callAPI<boolean>({
    url: `${USER_API_URL}/resource_access_requests/${canvas_id}`,
    method: "GET",
  });

const findResourceUsersMatchingQuery = async (
  resource_id: string,
  query: string = "",
  limit: number = 5
) =>
  await callAPI<
    {
      id: User["id"];
      email: User["email"];
      username: User["username"];
      permission: User["permission"];
      user_id: User["user_id"];
      name: User["name"];
      avatar: User["avatar"];
      is_owner: User["is_owner"];
    }[]
  >({
    url: `${USER_API_URL}/resources/matching_members/${resource_id}?query=${query}&limit=${limit}`,
    method: "GET",
  });

const getComputeUsage = async (user_id: string) =>
  await callAPI({
    url: `${USER_API_URL}/users/user_compute_usage/${user_id}`,
    method: "GET",
  });

const userService = {
  postAuthSignIn,
  resendVerificationEmail,
  getAuthMembership,
  getResource,
  putUpdateStarredCanvasResources,
  deleteCanvasResourceById,
  getResourceMembers,
  getResourceInvites,
  deleteResourceInvite,
  checkResourcePermission,
  inviteUserToResource,
  deleteResourceMember,
  updateResourceInvites,
  updateResourceMember,
  getResourceAccessRequestMembers,
  updateResourceAccessRequest,
  getUsers,
  getUser,
  findResourceUsersMatchingQuery,
  requestResourceAccess,
  getUserResourceRequestedAccess,
  getComputeUsage,
};

export default userService;
