import { tokenRef } from "context/Token";
import { getSessionId } from "context/Session";

interface APICall {
  url: string;
  method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
  data?: any;
  files?: any;
  signal?: AbortSignal;
}

interface FetchParams {
  method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
  headers?: Record<string, string>;
  redirect: "follow";
  body?: string | FormData;
  signal?: AbortSignal;
}

export const callAPI = async <TResponse = any>({
  url,
  method,
  data,
  files,
  signal,
}: APICall) => {
  const fetchParams: FetchParams = {
    method,
    redirect: "follow",
    headers: {
      Authorization: `Bearer ${tokenRef.current}`,
      "Session-Id": getSessionId(),
    },
    signal,
  };

  if (data) {
    fetchParams.headers = {
      ...fetchParams.headers,
      "Content-Type": "application/json",
    };
    fetchParams.body = JSON.stringify(data);
  } else if (files) {
    const formData = new FormData();
    for (const key in files) {
      formData.append(key, files[key]);
    }
    fetchParams.body = formData;
  }

  const response = await fetch(url, fetchParams);

  // check status
  if (!response.ok) {
    const { status } = response;
    const error = await response.json();
    throw new Error(error?.detail, { cause: { ...error, status } });
  }
  if (response.status === 204) {
    return response as TResponse;
  }
  const responseJson = await (response.json() as Promise<TResponse>);
  return responseJson;
};
