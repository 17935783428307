import { type StateCreator } from "store";
import { type DATA_LOAD_STATUS } from "config/appConfig";
import type { LayerType } from "models/canvas";
import type { Deployment } from "models/deployments";

type State = {
  deployments: Record<Deployment["id"], Deployment | undefined>;
  loadStatus: Record<Deployment["id"], DATA_LOAD_STATUS | undefined>;
  isLayerDeploying: Record<LayerType["id"], boolean>;
  isDeploymentUndeploying: Record<Deployment["id"], boolean>;
};

type Actions = {
  setIsLayerDeploying: (id: LayerType["id"], value: boolean) => void;
  setLoadStatus: (id: Deployment["id"], status: DATA_LOAD_STATUS) => void;
  getDeployment: (id: Deployment["id"]) => Deployment | null;
  setDeployment: (id: Deployment["id"], deployment: Deployment) => void;
  setIsDeploymentUndeploying: (id: Deployment["id"], value: boolean) => void;
  deleteDeployment: ({
    deploymentId,
    layerId,
  }: {
    deploymentId: Deployment["id"];
    layerId: LayerType["id"];
  }) => void;
  clearStore: () => void;
};

const getInitialState = (): State => ({
  deployments: {},
  loadStatus: {},
  isLayerDeploying: {},
  isDeploymentUndeploying: {},
});

export type CanvasDeploymentsSlice = State & Actions;

export const createCanvasDeploymentsSlice: StateCreator<
  CanvasDeploymentsSlice
> = (set, get) => ({
  ...getInitialState(),
  setIsLayerDeploying: (id, value) => {
    set(
      (store) => {
        store.canvasDeploymentsSlice.isLayerDeploying[id] = value;
      },
      false,
      "canvasSageMakerDeployments/setIsLayerDeploying"
    );
  },
  setLoadStatus: (id, status) => {
    set(
      (store) => {
        store.canvasDeploymentsSlice.loadStatus[id] = status;
      },
      false,
      "canvasSageMakerDeployments/setLoadStatus"
    );
  },
  getDeployment: (id) => {
    return get().canvasDeploymentsSlice.deployments[id] || null;
  },
  setDeployment: (id, deployment) => {
    set(
      (store) => {
        store.canvasDeploymentsSlice.deployments[id] = deployment;
      },
      false,
      "canvasSageMakerDeployments/setDeployment"
    );
  },
  setIsDeploymentUndeploying: (id, value) => {
    set(
      (store) => {
        store.canvasDeploymentsSlice.isDeploymentUndeploying[id] = value;
      },
      false,
      "canvasSageMakerDeployments/setIsDeploymentUndeploying"
    );
  },
  deleteDeployment: ({ deploymentId, layerId }) => {
    set(
      (store) => {
        delete store.canvasDeploymentsSlice.deployments[deploymentId];
        delete store.canvasDeploymentsSlice.loadStatus[deploymentId];
        delete store.canvasDeploymentsSlice.isDeploymentUndeploying[
          deploymentId
        ];
        delete store.canvasDeploymentsSlice.isLayerDeploying[layerId];
      },
      false,
      "canvasSageMakerDeployments/deleteDeployment"
    );
  },
  clearStore: () => {
    set(
      (store) => {
        Object.assign(store.canvasDeploymentsSlice, getInitialState());
      },
      false,
      "canvasSageMakerDeployments/clearStore"
    );
  },
});
