import { type FC, useCallback, useMemo, useState } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import cn from "classnames";
import { useFormik } from "formik";
import isEmpty from "lodash-es/isEmpty";
import isEqual from "lodash-es/isEqual";
import isPlainObject from "lodash-es/isPlainObject";
import moment from "moment";
import * as Yup from "yup";
import { Button } from "components/common/Button/Button";
import Loader from "components/common/Loader/Loader";
import TimeZoneDropdown from "components/common/TimeZoneDropdown/TimeZoneDropdown";
import TextField from "components/common/TextField/TextField";
import { useUserSettings } from "hooks/useUserSettings";
import { useUserSettingsState, useToastsState } from "store";
import { UserSettingsTabHeader } from "components/UserSettingsTabHeader";
import {
  stringIsTooLongErrorMessage,
  stringIsTooShortErrorMessage,
  validateDateString,
} from "utils/validation";
import { camelCaseToSnakeCase } from "utils/helpers";
import {
  DeleteAccount,
  DeleteAccountModal,
  ProfilePicture,
  SocialMediaLinks,
} from "./components";

import {
  FIELD_NAME as PROFILE_FIELD_NAME,
  type UserSettingsProfileFormField as FormField,
  type UserSettingsProfileFormValues as FormValues,
} from "./config";
import styles from "./UserSettingsProfile.module.scss";

const BIRTHDAY_DATE_FORMAT = "DD/MM/YYYY";
const MIN_ALLOWED_DATE = "01/01/1900";

const FORM_FIELDS = Object.freeze(Object.values(PROFILE_FIELD_NAME));

const FORM_FIELD_VALIDATION = Object.freeze({
  [PROFILE_FIELD_NAME.username]: Yup.string()
    .required("Username is required.")
    .test(
      "usernameHasSpases",
      "Your username must not contain spaces.",
      (value) => {
        return !value?.includes(" ");
      }
    )
    .test("usernameTooShort", stringIsTooShortErrorMessage(5), (value) => {
      return !value || value.length >= 5;
    })
    .test("usernameTooLong", stringIsTooLongErrorMessage(64), (value) => {
      return !value || value.length <= 64;
    }),
  [PROFILE_FIELD_NAME.firstName]: Yup.string()
    .test("nameTooShort", stringIsTooShortErrorMessage(1), (value) => {
      return !value || value.length >= 1;
    })
    .test("nameTooLong", stringIsTooLongErrorMessage(100), (value) => {
      return !value || value.length <= 100;
    }),
  [PROFILE_FIELD_NAME.lastName]: Yup.string()
    .test("nameTooShort", stringIsTooShortErrorMessage(1), (value) => {
      return !value || value.length >= 1;
    })
    .test("nameTooLong", stringIsTooLongErrorMessage(100), (value) => {
      return !value || value.length <= 100;
    }),
  [PROFILE_FIELD_NAME.bio]: Yup.string().test(
    "bioTooLong",
    stringIsTooLongErrorMessage(1000),
    (value) => {
      return !value || value.length <= 1000;
    }
  ),
  [PROFILE_FIELD_NAME.birthday]: Yup.string()
    .test(
      "birthdayCorrectFormat",
      `Please enter the date in the correct format: ${BIRTHDAY_DATE_FORMAT}.`,
      (value) => {
        return !value || validateDateString(value, BIRTHDAY_DATE_FORMAT);
      }
    )
    .test("birthdayNotFromFuture", "Future dates are not allowed.", (value) => {
      return !value || moment().diff(moment(value, BIRTHDAY_DATE_FORMAT)) >= 0;
    })
    .test(
      "birthdayMinDate",
      `The minimum allowed date is ${MIN_ALLOWED_DATE}.`,
      (value) => {
        return (
          !value ||
          moment(value, BIRTHDAY_DATE_FORMAT).diff(
            moment(MIN_ALLOWED_DATE, BIRTHDAY_DATE_FORMAT)
          ) >= 0
        );
      }
    ),
  [PROFILE_FIELD_NAME.city]: Yup.string().test(
    "cityTooLong",
    stringIsTooLongErrorMessage(100),
    (value) => {
      return !value || value.length <= 100;
    }
  ),
});

const VALIDATION_SCHEMA = Yup.object().shape({
  ...FORM_FIELD_VALIDATION,
});

const UserSettingsProfile: FC = () => {
  const addToast = useToastsState((slice) => slice.addToast);
  const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] =
    useState(false);
  const { handleSubmitProfileSetting, isSubmitProfileSettingInProgress } =
    useUserSettings();
  const profileData = useUserSettingsState((slice) => slice.profile);
  const { enableSocialLinks } = useFlags();
  const handleToggleDeleteAccountModal = useCallback(() => {
    setIsDeleteAccountModalOpen((prevState) => !prevState);
  }, [setIsDeleteAccountModalOpen]);

  const formInitialValues = useMemo(() => {
    return FORM_FIELDS.reduce<FormValues>((res, key) => {
      switch (key) {
        case PROFILE_FIELD_NAME.timezone: {
          res[key] = profileData?.[key];
          break;
        }
        case PROFILE_FIELD_NAME.socialMediaLinks: {
          res[key] = [...(profileData?.[key] || [])];
          break;
        }
        default: {
          res[key] = profileData?.[key] || "";
          break;
        }
      }

      return res;
    }, {});
  }, [profileData]);

  const setSubmissionErrors = useCallback(
    (submissionErrors?: Record<string, string | undefined>) => {
      const errors = FORM_FIELDS.reduce((accum, field) => {
        const key = camelCaseToSnakeCase(field);

        if (submissionErrors?.[key]) {
          accum[field] = submissionErrors[key];
        }

        return accum;
      }, {});

      !isEmpty(errors) && setErrors(errors);
    },
    []
  );

  const onSubmit = (values: FormValues) => {
    /* send only values that have been changed, as e.g. BE responds with error
    "Username already exists" if username has not been changed but is present in request
    https://linear.app/zerve-ai/issue/ZER-1318/patch-apiuser-serviceuser-settings-improvements */
    const dataToSubmit = Object.keys(values).reduce<FormValues>((res, key) => {
      switch (key) {
        case PROFILE_FIELD_NAME.username:
        case PROFILE_FIELD_NAME.firstName:
        case PROFILE_FIELD_NAME.lastName:
        case PROFILE_FIELD_NAME.birthday:
        case PROFILE_FIELD_NAME.bio:
        case PROFILE_FIELD_NAME.city:
        case PROFILE_FIELD_NAME.timezone: {
          if (values[key] !== formInitialValues?.[key]) {
            res[key] = values[key];
          }
          break;
        }
        case PROFILE_FIELD_NAME.socialMediaLinks: {
          if (
            enableSocialLinks &&
            !isEqual(values[key], formInitialValues[key])
          ) {
            res[key] = values[key] || [];
          }
          break;
        }
        default: {
          res[key] = values[key];
          break;
        }
      }

      return res;
    }, {});

    handleSubmitProfileSetting({
      data: dataToSubmit,
      onSuccess: () => {
        resetForm();
        addToast({
          variant: "success",
          message: "Personal Settings have been saved successfully",
        });
      },
      onError: (err) => {
        let errMessage = "Failed to save profile settings";

        if (err instanceof Error) {
          if (err?.message) {
            errMessage = err.message;
          }

          const errDetails = err?.cause as any;

          if (errDetails && !isEmpty(errDetails) && isPlainObject(errDetails)) {
            setSubmissionErrors(errDetails);
          }
        }

        addToast({
          variant: "error",
          message: errMessage,
        });
      },
    });
  };

  const {
    values,
    errors,
    isValid,
    dirty,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setErrors,
    resetForm,
  } = useFormik({
    initialValues: { ...formInitialValues },
    validationSchema: VALIDATION_SCHEMA,
    enableReinitialize: true,
    onSubmit,
  });

  const isActionButtonDisabled = !isValid || isSubmitProfileSettingInProgress;
  const isFieldDisabled = isSubmitProfileSettingInProgress;

  const getTextFieldProps = (field: FormField) => {
    return {
      name: field,
      onChange: handleChange,
      onBlur: handleBlur,
      isDisabled: isFieldDisabled,
      error: errors[field],
      containerClassName: styles.textFieldContainer,
    };
  };

  const handleLinksChange = useCallback(
    (newValues: string[]) => {
      const initialLinks = formInitialValues?.socialMediaLinks;
      if (!initialLinks) {
        return;
      }

      const initialLinksLength = initialLinks.length;
      // need the next code for form correct "isDirty" check
      if (newValues.length <= initialLinksLength) {
        setFieldValue(PROFILE_FIELD_NAME.socialMediaLinks, newValues);
      } else {
        for (let ind = initialLinksLength; ind < newValues.length; ind++) {
          if (newValues[ind]) {
            setFieldValue(PROFILE_FIELD_NAME.socialMediaLinks, newValues);
            return;
          }
        }

        for (let index = 0; index < initialLinksLength; index++) {
          if (initialLinks[index] !== newValues[index]) {
            setFieldValue(
              PROFILE_FIELD_NAME.socialMediaLinks,
              newValues.slice(0, initialLinksLength)
            );
            return;
          }
        }

        setFieldValue(PROFILE_FIELD_NAME.socialMediaLinks, initialLinks);
      }
    },
    [formInitialValues?.socialMediaLinks]
  );

  return (
    <div className={styles.container}>
      <UserSettingsTabHeader
        heading="Personal Settings"
        subHeading="All of the fields on this page are optional and can be deleted at any time, and by filling them out, you're giving us consent to share this data wherever your user profile appears."
      />

      <ProfilePicture />
      <TextField
        label="Email Address"
        placeholder={profileData?.email ?? "Your email address..."}
        containerClassName={styles.emailText}
        value={profileData?.email ?? ""}
        isDisabled
      />
      <form
        className={cn(styles.form, {
          [styles.form_action_disabled]: isSubmitProfileSettingInProgress,
        })}
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <TextField
          label="Username"
          placeholder="Your username..."
          helperText="Your name may appear around Zerve where you contribute or are mentioned."
          value={values[PROFILE_FIELD_NAME.username]}
          {...getTextFieldProps(PROFILE_FIELD_NAME.username)}
        />
        <div className={styles.twoItemsRow}>
          <TextField
            label="First Name"
            placeholder="Your first name..."
            value={values[PROFILE_FIELD_NAME.firstName]}
            {...getTextFieldProps(PROFILE_FIELD_NAME.firstName)}
          />
          <TextField
            label="Last Name"
            placeholder="Your last name..."
            value={values[PROFILE_FIELD_NAME.lastName]}
            {...getTextFieldProps(PROFILE_FIELD_NAME.lastName)}
          />
        </div>
        <TextField
          type="textarea"
          label="Bio"
          placeholder="Tell us a bit about yourself..."
          value={values[PROFILE_FIELD_NAME.bio]}
          {...getTextFieldProps(PROFILE_FIELD_NAME.bio)}
        />
        <div className={styles.twoItemsRow}>
          <TextField
            label="Birthday"
            placeholder="DD/MM/YYYY"
            value={values[PROFILE_FIELD_NAME.birthday]}
            {...getTextFieldProps(PROFILE_FIELD_NAME.birthday)}
          />
          <TextField
            label="Location"
            placeholder="Where do you live?"
            value={values[PROFILE_FIELD_NAME.city]}
            {...getTextFieldProps(PROFILE_FIELD_NAME.city)}
          />
        </div>
        <TimeZoneDropdown
          placeholder="Your time zone..."
          label="Time Zone"
          value={values[PROFILE_FIELD_NAME.timezone]}
          disabled={isFieldDisabled}
          onTimezoneChange={(timezone) => {
            setFieldValue(PROFILE_FIELD_NAME.timezone, timezone);
          }}
        />
        {enableSocialLinks && (
          <SocialMediaLinks
            values={values[PROFILE_FIELD_NAME.socialMediaLinks]}
            disabled={isFieldDisabled}
            onLinksChange={handleLinksChange}
          />
        )}
        <div className={styles.controls}>
          <Button
            className={cn(styles.button, styles.submit_button)}
            type="submit"
            disabled={isActionButtonDisabled || !dirty}
          >
            {isSubmitProfileSettingInProgress ? (
              <span className={styles.button_progress_loader}>
                <Loader />
              </span>
            ) : (
              "Save"
            )}
          </Button>
        </div>
      </form>
      <DeleteAccount onModalOpen={handleToggleDeleteAccountModal} />
      {isDeleteAccountModalOpen ? (
        <DeleteAccountModal onModalClose={handleToggleDeleteAccountModal} />
      ) : null}
    </div>
  );
};

export default UserSettingsProfile;
