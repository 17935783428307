import styles from "./VerifyEmail.module.scss";
import { useEffect, useCallback, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BiEnvelope } from "react-icons/bi";
import userService from "api/http/user-service";
import { useToastsState } from "store";
import LoadingScreen from "components/common/LoadingScreen/LoadingScreen";
import { Button } from "components/common/Button/Button";
import { LinkButton } from "components/common/Button/LinkButton";
import Loader from "components/common/Loader/Loader";
import BlockScreen from "components/BlockScreen/BlockScreen";
import { debouncePromise } from "utils/helpers";
import { BLOCK_STATUS } from "config/canvasConfig";

export default function VerifyEmail() {
  const addToast = useToastsState((slice) => slice.addToast);
  const { isAuthenticated, isLoading } = useAuth0();

  const navigate = useNavigate();
  const [params] = useSearchParams();

  const [isResending, setIsResending] = useState(false);

  const email = params.get("email");

  // redirect to the home page if the user is authenticated
  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      navigate("/");
    }
  }, [isLoading, isAuthenticated]);

  const handleResendLink = useCallback(async () => {
    if (!email) {
      return;
    }

    setIsResending(true);

    try {
      await debouncePromise({
        promise: userService.resendVerificationEmail(email),
      });
      addToast({
        message: "Email sent. Check your inbox",
        variant: "success",
      });
      setIsResending(false);
    } catch (rejection) {
      addToast({
        message: "Failed to send email",
        variant: "error",
      });
      setIsResending(false);
    }
  }, [email]);

  if (isLoading) {
    // the user is still being authenticated by Auth0
    return <LoadingScreen text="Loading..." />;
  }

  if (isAuthenticated) {
    // the user is navigated away by now
    return <LoadingScreen text="Redirecting..." />;
  }

  return (
    <BlockScreen
      status={BLOCK_STATUS.PENDING}
      title="Not Verified"
      header="Verify Email"
      message={
        email ? (
          <div>
            We've sent a verification link to{" "}
            <span className={styles.email}>{email}</span>
          </div>
        ) : (
          "We've sent a verification link to your email"
        )
      }
      actions={
        <>
          {email ? (
            <Button
              variant="primary"
              size="large"
              disabled={isResending}
              className={styles.resendButton}
              onClick={handleResendLink}
            >
              {isResending ? <Loader size={20} /> : <BiEnvelope size={20} />}
              <span>Resend link</span>
            </Button>
          ) : null}
          <LinkButton variant="primary" size="large" to="/">
            Go Home
          </LinkButton>
        </>
      }
    />
  );
}
