import { type SVGProps } from "react";

const MariaDBIcon = ({
  color = "currentColor",
  width = "20",
  height = "20",
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      id="mariadb"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g fill={color} fillRule="nonzero">
        <g>
          <path d="M18.6041 4.00031C18.3264 4.00928 18.4142 4.0896 17.8141 4.23784C17.2082 4.38756 16.468 4.3416 15.8155 4.6164C13.8676 5.43664 13.4767 8.23991 11.7059 9.2441C10.3823 9.99473 9.04684 10.0546 7.84622 10.4324C7.05718 10.6808 6.19404 11.1902 5.47917 11.8088C4.92431 12.289 4.90983 12.7113 4.33002 13.3137C3.70992 13.958 1.8653 13.3246 1.0293 14.3107C1.29857 14.5841 1.41668 14.6607 1.94735 14.5897C1.83753 14.7988 1.18981 14.975 1.31657 15.2826C1.45001 15.6063 3.01586 15.8257 4.43934 14.9629C5.10226 14.561 5.63032 13.9818 6.6627 13.8436C7.99872 13.6648 9.53785 13.9582 11.0845 14.1822C10.8552 14.8687 10.3947 15.3253 10.0259 15.8721C9.91167 15.9956 10.2553 16.0094 10.6474 15.9348C11.3525 15.7597 11.8607 15.6187 12.3929 15.3077C13.0468 14.9255 13.1459 13.9458 13.9481 13.7338C14.395 14.4236 15.6107 14.5865 16.365 14.0348C15.7031 13.8467 15.5202 12.4321 15.7436 11.8088C15.9552 11.2187 16.1644 10.2749 16.3776 9.4949C16.6064 8.6573 16.6908 7.60151 16.9677 7.1748C17.3844 6.53288 17.8448 6.31243 18.2445 5.95045C18.6442 5.5884 19.0101 5.2361 18.9981 4.40775C18.9942 4.14092 18.8568 3.99226 18.6041 4.00031Z" />
          <path d="M1.6044 15.2232C2.61807 15.3691 3.23452 15.2232 4.04852 14.8689C4.74117 14.5672 5.41006 13.9455 6.228 13.6819C7.42918 13.295 8.74643 13.6824 10.0305 13.7597C10.3432 13.7786 10.6539 13.7788 10.9606 13.7452C11.4389 13.45 11.429 12.346 11.8946 12.2448C11.8809 13.7939 11.2483 14.722 10.5868 15.6208C11.981 15.3736 12.8153 14.5639 13.3788 13.4826C13.5498 13.1547 13.6958 12.802 13.8248 12.4322C14.0244 12.586 13.9113 13.054 14.0116 13.3075C14.9719 12.7704 15.5218 11.5447 15.886 10.3051C16.3073 8.87035 16.4797 7.41758 16.7515 6.99306C17.0166 6.57871 17.4293 6.32332 17.806 6.05805C18.2342 5.75642 18.616 5.44209 18.6819 4.86743C18.2305 4.82551 18.1261 4.72061 18.0593 4.49219C17.8333 4.62009 17.6254 4.64748 17.3906 4.65454C17.1869 4.66075 16.963 4.65165 16.6896 4.67981C14.4285 4.91296 14.1412 7.4151 12.692 8.8335C12.5851 8.93744 12.4716 9.03421 12.3521 9.1232C11.8445 9.50296 11.2218 9.77431 10.6493 9.99405C9.72241 10.3496 8.84127 10.3749 7.97173 10.6818C7.33335 10.907 6.68471 11.2338 6.16044 11.5941C6.03382 11.6806 5.91096 11.7725 5.79217 11.8696C5.48451 12.1223 5.28253 12.4027 5.08722 12.6912C4.88572 12.9884 4.69118 13.2943 4.39463 13.5864C3.91416 14.0601 2.11883 13.7246 1.48678 14.1638C1.41633 14.2127 1.36037 14.2713 1.32227 14.3418C1.66719 14.4991 1.89751 14.4026 2.29424 14.4466C2.34627 14.8246 1.47574 15.0492 1.6044 15.2233V15.2232ZM15.3029 12.5004C15.3299 12.934 15.5806 13.7942 15.8019 14.0034C15.3684 14.1093 14.6217 13.9344 14.4301 13.6274C14.5285 13.1841 15.0408 12.7789 15.3029 12.5004Z" />
          <path d="M15.9375 5.61918C16.2575 5.89815 16.9292 5.67424 16.8092 5.11913C16.3118 5.07777 16.0237 5.24725 15.9375 5.61918ZM18.1689 4.9704C18.0839 5.1497 17.9208 5.38088 17.9208 5.83723C17.9201 5.91558 17.8616 5.9693 17.8606 5.84852C17.865 5.4024 17.9826 5.20963 18.1075 4.95622C18.1656 4.85238 18.2005 4.89523 18.1689 4.9704Z" />
          <path d="M18.0828 4.9022C17.9823 5.07324 17.7405 5.38531 17.7005 5.83996C17.6931 5.91789 17.63 5.96618 17.6396 5.84568C17.6832 5.40182 17.8764 5.12399 18.0231 4.88251C18.0897 4.78432 18.121 4.83006 18.0828 4.9022ZM18.0046 4.81269C17.8902 4.97462 17.5179 5.34938 17.4401 5.79917C17.4261 5.87611 17.3592 5.91902 17.3789 5.79973C17.4594 5.36102 17.7803 5.0162 17.9467 4.78813C18.0212 4.69566 18.0485 4.74401 18.0046 4.81269ZM17.9349 4.71288C17.799 4.85702 17.3552 5.33491 17.2153 5.76916C17.1905 5.84335 17.1183 5.87646 17.1545 5.76112C17.2956 5.33815 17.6843 4.88251 17.8809 4.68027C17.9678 4.59931 17.9879 4.65098 17.9349 4.71288Z" />
        </g>
      </g>
    </g>
  </svg>
);

export default MariaDBIcon;
