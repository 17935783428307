import { useEffect, useMemo, useState } from "react";
import {
  REQUIREMENTS_BUILDING_PROGRESS,
  REQUIREMENTS_STATUS,
} from "config/canvasConfig";
import { useCanvasRequirementsState, useCanvasState } from "store";

const MAX_BUILDING_STATUS_PERCENTAGE =
  REQUIREMENTS_BUILDING_PROGRESS[REQUIREMENTS_STATUS.DEPLOYING].progress - 1;
const MAX_DEPLOYING_STATUS_PERCENTAGE =
  REQUIREMENTS_BUILDING_PROGRESS[REQUIREMENTS_STATUS.READY].progress - 1;
const PROGRESS_STORAGE_KEY_PREFIX = "requirements-build-progress";

export function useRequirementsBuildingProgress() {
  const canvasId = useCanvasState((state) => state.canvasId);
  const PROGRESS_STORAGE_KEY = useMemo(
    () => `${PROGRESS_STORAGE_KEY_PREFIX}-${canvasId}`,
    [canvasId]
  );
  const [buildingProgress, setBuildingProgress] = useState(
    () => Number(localStorage.getItem(PROGRESS_STORAGE_KEY)) || 0
  );

  const requirementsStatus = useCanvasRequirementsState(
    (slice) => slice.requirementsStatus
  );
  const { progress, label } = useMemo(
    () =>
      requirementsStatus
        ? REQUIREMENTS_BUILDING_PROGRESS[requirementsStatus]
        : { progress: 0, label: "" },
    [requirementsStatus]
  );

  useEffect(() => {
    if (buildingProgress < progress) {
      setBuildingProgress(progress);
    }
  }, [progress]);

  useEffect(() => {
    if (
      requirementsStatus === REQUIREMENTS_STATUS.READY ||
      requirementsStatus === REQUIREMENTS_STATUS.ERROR ||
      requirementsStatus === REQUIREMENTS_STATUS.RESETTING
    ) {
      localStorage.removeItem(PROGRESS_STORAGE_KEY);
      setBuildingProgress(0);
    } else if (buildingProgress !== 0) {
      localStorage.setItem(PROGRESS_STORAGE_KEY, String(buildingProgress));
    }
  }, [requirementsStatus, buildingProgress]);

  useEffect(() => {
    if (
      requirementsStatus === REQUIREMENTS_STATUS.BUILDING ||
      requirementsStatus === REQUIREMENTS_STATUS.DEPLOYING
    ) {
      const interval = setInterval(() => {
        setBuildingProgress((prev) => {
          if (
            requirementsStatus === REQUIREMENTS_STATUS.BUILDING &&
            prev < MAX_BUILDING_STATUS_PERCENTAGE
          ) {
            return prev + 1;
          } else if (
            requirementsStatus === REQUIREMENTS_STATUS.DEPLOYING &&
            prev < MAX_DEPLOYING_STATUS_PERCENTAGE
          ) {
            return prev + 1;
          } else {
            clearInterval(interval);
            return requirementsStatus === REQUIREMENTS_STATUS.BUILDING
              ? MAX_BUILDING_STATUS_PERCENTAGE
              : MAX_DEPLOYING_STATUS_PERCENTAGE;
          }
        });
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [requirementsStatus]);

  return useMemo(
    () => ({ buildingProgress, label }),
    [buildingProgress, label]
  );
}
