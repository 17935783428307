import styles from "./CanvasesGrid.module.scss";
import { type MouseEvent } from "react";
import { CanvasCard } from "components/canvases/CanvasCard/CanvasCard";
import type { CanvasCardType } from "models/canvas";
import type { ContextMenuItem } from "components/common/ContextMenu/ContextMenu";

type CanvasesGridProps = {
  items: CanvasCardType[];
  processedItemsIds?: Record<string, string> | null;
  selectedItemsIds?: Record<string, string> | null;
  onGetCanvasOptions: (canvas: CanvasCardType) => ContextMenuItem[];
  onItemClick: (
    canvasID: CanvasCardType["id"],
    event: MouseEvent<HTMLElement>
  ) => void;
};

export function CanvasesGrid({
  items,
  processedItemsIds,
  selectedItemsIds,
  onGetCanvasOptions,
  onItemClick,
}: CanvasesGridProps) {
  return (
    <div className={styles.container}>
      <div className={styles.canvases}>
        {items.map((item) => {
          const { id } = item;
          const canvasOptions = onGetCanvasOptions(item);

          return (
            <CanvasCard
              key={id}
              isProcessed={Boolean(processedItemsIds?.[id])}
              isSelected={Boolean(selectedItemsIds?.[id])}
              item={item}
              options={canvasOptions}
              onClick={onItemClick}
            />
          );
        })}
      </div>
    </div>
  );
}
