import { useMemo, type PropsWithChildren } from "react";
import { useSidebarAccordionContext } from "components/SidebarAccordion/SidebarAccordionContext";
import { ItemContext } from "components/SidebarAccordion/Item/ItemContext";

type ItemProps = PropsWithChildren<{ id: string }>;

export function Item({ children, id }: ItemProps) {
  const { activeIds } = useSidebarAccordionContext();

  const value = useMemo(() => {
    return {
      id,
      isActive: activeIds.has(id),
    };
  }, [id, activeIds]);

  return <ItemContext.Provider value={value}>{children}</ItemContext.Provider>;
}
