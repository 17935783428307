import tinycolor from "tinycolor2";
import type { ConnectedUser } from "models/canvas";

export function transformUser(
  connection_id: string,
  user: {
    user_id: string;
    user_name: string;
    profile_image: string;
    user_color?: string;
  }
): ConnectedUser {
  return {
    connection_id,
    id: user.user_id,
    name: user.user_name,
    picture: user.profile_image,
    color: user.user_color || tinycolor.random().toHexString(),
    position: undefined,
    view: undefined,
    layer_id: undefined,
    active_block_id: undefined,
  };
}
