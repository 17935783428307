import styles from "./Requirements.module.scss";
import { useState, useCallback, useMemo } from "react";
import { useCanvasRequirementsState } from "store";
import { SideBarSearch } from "components/SideBarSearch/SideBarSearch";
import { RequirementsTable } from "components/common/RequirementsTable/RequirementsTable";
import Loader from "components/common/Loader/Loader";
import { Button } from "components/common/Button/Button";
import { EmptyState } from "components/common/EmptyState/EmptyState";
import { useRequirements } from "components/menus/RequirmentsMenu/useRequirements";
import { DATA_LOAD_STATUS } from "config/appConfig";
import type { Requirement } from "models/requirements";

export function Requirements() {
  const {
    requirementsStatus,
    executorLoadingStatus,
    requirementsLoadingStatus,
    requirements,
    setRequirements,
    setRequirementsValidationStatus,
  } = useCanvasRequirementsState((slice) => ({
    requirementsStatus: slice.requirementsStatus,
    executorLoadingStatus: slice.executorLoadingStatus,
    requirementsLoadingStatus: slice.requirementsLoadingStatus,
    requirements: slice.requirements,
    setRequirements: slice.setRequirements,
    setRequirementsValidationStatus: slice.setRequirementsValidationStatus,
  }));

  const { checkIsBuildDisabled, fetchRequirements, updateRequirements } =
    useRequirements();

  const [searchText, setSearchText] = useState("");

  const isBuildDisabled = useMemo(
    () =>
      checkIsBuildDisabled({
        requirementsStatus,
        executorLoadingStatus,
      }),
    [requirementsStatus, executorLoadingStatus, checkIsBuildDisabled]
  );

  const handleRequirementsChange = useCallback(
    (newRequirements: Requirement[]) => {
      setRequirements(newRequirements);
      updateRequirements({ requirements: newRequirements });
    },
    [updateRequirements]
  );

  // not loaded / loading
  if (
    requirementsLoadingStatus === DATA_LOAD_STATUS.NOT_LOADED ||
    requirementsLoadingStatus === DATA_LOAD_STATUS.LOADING
  ) {
    return <Loader withOverlay>Loading...</Loader>;
  }

  // error
  if (requirementsLoadingStatus === DATA_LOAD_STATUS.ERROR) {
    return (
      <div className={styles.fillWithScroll}>
        <div className={styles.errorMessageContainer}>
          <EmptyState
            variant="error"
            title="Something went wrong"
            description="Failed to load requirements."
          >
            <Button variant="text" onClick={fetchRequirements}>
              Try again
            </Button>
          </EmptyState>
        </div>
      </div>
    );
  }

  // loaded
  return (
    <>
      <SideBarSearch
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
      />

      <RequirementsTable
        requirements={requirements || []}
        searchText={searchText}
        readOnly={isBuildDisabled}
        setRequirements={handleRequirementsChange}
        onValidationStatusChange={setRequirementsValidationStatus}
      />
    </>
  );
}
