import styles from "./GlobalImports.module.scss";
import { useEffect, useState, useCallback, useMemo } from "react";
import { useDebouncedCallback } from "use-debounce";
import canvasService from "api/http/canvas-service";
import { useCanvasState, useCanvasRequirementsState } from "store";
import { useCanvasActions } from "hooks/useCanvasActions";
import CodeEditor from "components/common/CodeEditor/CodeEditor";
import Fill from "components/common/Fill/Fill";
import { SAVING_STATUS } from "components/common/SavingStatusIndicator/SavingStatusIndicator";
import { LANGUAGE } from "config/canvasConfig";
import { DATA_LOAD_STATUS } from "config/appConfig";
import Loader from "components/common/Loader";
import { EmptyState } from "components/common/EmptyState";
import { Button } from "components/common/Button/Button";
import { debouncePromise } from "utils/helpers";

type GlobalImportsProps = {
  language: LANGUAGE;
  placeholderText: string;
  savingStatus: SAVING_STATUS;
  onSavingStatusChange: (status: SAVING_STATUS) => void;
};

export function GlobalImports({
  language,
  placeholderText,
  savingStatus,
  onSavingStatusChange,
}: GlobalImportsProps) {
  const isEditor = useCanvasState((slice) => slice.isEditor);
  const canvasId = useCanvasState((slice) => slice.canvasId);

  const globalImports = useCanvasRequirementsState(
    (slice) => slice.globalImports[language]
  );
  const setGlobalImportsByLanguage = useCanvasRequirementsState(
    (slice) => slice.setGlobalImportsByLanguage
  );

  const [loadStatus, setLoadStatus] = useState(DATA_LOAD_STATUS.NOT_LOADED);

  const { updateGlobalImports } = useCanvasActions();

  // language passed into CodeEditor component
  const codeEditorLanguage = useMemo(() => {
    if (language === LANGUAGE.PYTHON) {
      return "python";
    }
    if (language === LANGUAGE.R) {
      return "r";
    }
    return "plaintext";
  }, [language]);

  const fetchImports = useCallback(
    ({ timeout = 500 }: { timeout?: number } = {}) => {
      setLoadStatus(DATA_LOAD_STATUS.LOADING);

      let promise: Promise<string>;
      if (language === LANGUAGE.PYTHON) {
        promise = canvasService.getPythonGlobalImports(canvasId);
      } else if (language === LANGUAGE.R) {
        promise = canvasService.getRGlobalImports(canvasId);
      } else {
        promise = Promise.reject("Unsupported language");
      }

      debouncePromise({ promise, timeout })
        .then((fetchedImports) => {
          setLoadStatus(DATA_LOAD_STATUS.LOADED);
          setGlobalImportsByLanguage(fetchedImports, language);
        })
        .catch(() => {
          setLoadStatus(DATA_LOAD_STATUS.ERROR);
        });
    },
    [canvasId, language]
  );

  const debouncedSetImports = useDebouncedCallback(
    useCallback(
      (value: string) => {
        if (codeEditorLanguage !== "python" && codeEditorLanguage !== "r") {
          return;
        }
        updateGlobalImports(codeEditorLanguage, value)
          .then(() => {
            onSavingStatusChange(SAVING_STATUS.COMPLETED);
          })
          .catch(() => {
            onSavingStatusChange(SAVING_STATUS.FAILED);
          });
      },
      [codeEditorLanguage, updateGlobalImports, onSavingStatusChange]
    ),
    500
  );

  const handleChange = useCallback(
    (value) => {
      onSavingStatusChange(SAVING_STATUS.PENDING);
      debouncedSetImports(value);
    },
    [onSavingStatusChange, debouncedSetImports]
  );

  const handleBlur = useCallback(
    (value) => {
      if (value === globalImports) {
        return;
      }
      setGlobalImportsByLanguage(value, language);
    },
    [globalImports, language]
  );

  // fetch imports on mount, if not already fetched
  useEffect(() => {
    if (
      typeof globalImports === "string" ||
      loadStatus !== DATA_LOAD_STATUS.NOT_LOADED
    ) {
      return;
    }
    fetchImports({ timeout: 0 });
  }, [globalImports, loadStatus, fetchImports]);

  // change content saving status to IDLE after COMPLETED
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (savingStatus === SAVING_STATUS.COMPLETED) {
      timeout = setTimeout(() => {
        onSavingStatusChange(SAVING_STATUS.IDLE);
      }, 3000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [savingStatus, onSavingStatusChange]);

  return (
    <Fill>
      {/* Loading */}
      {loadStatus === DATA_LOAD_STATUS.LOADING ? (
        <Loader withOverlay>Loading...</Loader>
      ) : null}

      {/* Error */}
      {loadStatus === DATA_LOAD_STATUS.ERROR ? (
        <EmptyState
          variant="error"
          title="Something went wrong"
          description="Failed to load global imports"
          containerClassName={styles.emptyState}
        >
          <Button variant="text" onClick={() => fetchImports()}>
            Retry
          </Button>
        </EmptyState>
      ) : null}

      {/* Global Imports Editor */}
      {typeof globalImports === "string" &&
      loadStatus !== DATA_LOAD_STATUS.LOADING &&
      loadStatus !== DATA_LOAD_STATUS.ERROR ? (
        <CodeEditor
          language={codeEditorLanguage}
          value={globalImports}
          placeholderText={placeholderText}
          readOnly={!isEditor}
          lineNumbers={false}
          folding={false}
          renderLineHighlight={false}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      ) : null}
    </Fill>
  );
}
