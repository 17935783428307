import styles from "./Skeleton.module.scss";
import { memo, type CSSProperties } from "react";
import cn from "classnames";

type SkeletonProps = {
  width?: CSSProperties["width"];
  height?: CSSProperties["height"];
  className?: string;
};

export const Skeleton = memo(function Skeleton({
  width,
  height,
  className,
}: SkeletonProps) {
  const style: CSSProperties = {};
  if (width) {
    style["width"] = width;
  }
  if (height) {
    style["height"] = height;
  }

  return <div className={cn(styles.skeleton, className)} style={style} />;
});
