import { type StateCreator } from "store";
import type { CanvasFile } from "models/canvas";

type State = {
  files: Map<CanvasFile["path"], CanvasFile>;
  uploadRequestsAbort: Map<CanvasFile["path"], XMLHttpRequest["abort"]>;
};

type Actions = {
  addFile: (file: CanvasFile) => void;
  getFile: (path: CanvasFile["path"]) => CanvasFile | undefined;
  getFiles: () => State["files"];
  getFilesInFolder: (path: CanvasFile["path"]) => CanvasFile[];
  setFiles: (files: CanvasFile[]) => void;
  createFolder: (path: CanvasFile["path"] | null) => string;
  deleteFile: (path: CanvasFile["path"]) => void;
  deleteFolder: (path: CanvasFile["path"]) => void;
  moveFile: ({
    oldPath,
    newPath,
  }: {
    oldPath: CanvasFile["path"];
    newPath: CanvasFile["path"];
  }) => void;
  moveFolder: ({
    oldPath,
    newPath,
  }: {
    oldPath: CanvasFile["path"];
    newPath: CanvasFile["path"];
  }) => void;
  setFileUploadPercent: (
    path: CanvasFile["path"],
    uploadPercent: CanvasFile["uploadPercent"]
  ) => void;
  setUploadRequestAbort: (
    path: CanvasFile["path"],
    requestAbort: XMLHttpRequest["abort"]
  ) => void;
  deleteUploadRequestAbort: (path: CanvasFile["path"]) => void;
  clearStore: () => void;
};

const getInitialState = (): State => ({
  files: new Map(),
  uploadRequestsAbort: new Map(), // used for canceling duplicate requests
});

export type CanvasFilesSlice = State & Actions;

export const createCanvasFilesSlice: StateCreator<CanvasFilesSlice> = (
  set,
  get
) => ({
  ...getInitialState(),
  addFile: (newFile) => {
    set(
      (store) => {
        store.canvasFilesSlice.files.set(newFile.path, newFile);
      },
      false,
      "canvasFiles/addFile"
    );
  },
  getFile: (path) => {
    return get().canvasFilesSlice.files.get(path);
  },
  getFiles: () => {
    return get().canvasFilesSlice.files;
  },
  getFilesInFolder: (path) => {
    return Array.from(get().canvasFilesSlice.files.values()).filter(
      (file) => file.path.startsWith(path) && !file.path.endsWith("/")
    );
  },
  setFiles: (files) => {
    set(
      (store) => {
        const newFiles = new Map(files.map((file) => [file.path, file]));

        // keep uploading files
        for (const path of store.canvasFilesSlice.uploadRequestsAbort.keys()) {
          const file = store.canvasFilesSlice.files.get(path);
          if (file) {
            newFiles.set(path, file);
          }
        }

        store.canvasFilesSlice.files = newFiles;
      },
      false,
      "canvasFiles/setFiles"
    );
  },
  createFolder: (path) => {
    const newFolderPath = path ? path + "/" : "/";
    set(
      (store) => {
        store.canvasFilesSlice.files.set(newFolderPath, {
          path: newFolderPath,
          size: 0,
          lastModified: new Date().toISOString(),
          uploadPercent: 100,
          isNewFolder: true,
        });
      },
      false,
      "canvasFiles/createFolder"
    );
    return newFolderPath;
  },
  deleteFile: (path) => {
    set(
      (store) => {
        if (store.canvasFilesSlice.uploadRequestsAbort.has(path)) {
          store.canvasFilesSlice.uploadRequestsAbort.get(path)?.(); // abort upload
          store.canvasFilesSlice.uploadRequestsAbort.delete(path);
        }
        store.canvasFilesSlice.files.delete(path);
      },
      false,
      "canvasFiles/deleteFile"
    );
  },
  deleteFolder: (path) => {
    set(
      (store) => {
        for (const filePath of store.canvasFilesSlice.files.keys()) {
          if (filePath.startsWith(path)) {
            store.canvasFilesSlice.files.delete(filePath);
            store.canvasFilesSlice.uploadRequestsAbort.get(path)?.(); // abort upload
            store.canvasFilesSlice.uploadRequestsAbort.delete(path);
          }
        }
      },
      false,
      "canvasFiles/deleteFolder"
    );
  },
  moveFile: ({ oldPath, newPath }) => {
    set(
      (store) => {
        const file = store.canvasFilesSlice.files.get(oldPath);
        if (file) {
          store.canvasFilesSlice.files.set(newPath, { ...file, path: newPath });
          store.canvasFilesSlice.files.delete(oldPath);
        }
      },
      false,
      "canvasFiles/moveFile"
    );
  },
  moveFolder: ({ oldPath, newPath }) => {
    set(
      (store) => {
        for (const [filePath, file] of store.canvasFilesSlice.files.entries()) {
          // move source files (oldPath) to destination (newPath)
          if (filePath.startsWith(oldPath)) {
            const relativeNewPath = filePath.slice(oldPath.length);
            const newFilePath = newPath + relativeNewPath;
            store.canvasFilesSlice.files.set(newFilePath, {
              ...file,
              path: newFilePath,
              isNewFolder: false,
            });
            store.canvasFilesSlice.files.delete(filePath);
            continue;
          }

          // remove files in the destination folder (newPath)
          if (filePath.startsWith(newPath)) {
            store.canvasFilesSlice.files.delete(filePath);
          }
        }
      },
      false,
      "canvasFiles/moveFolder"
    );
  },
  setFileUploadPercent: (path, uploadPercent) => {
    set(
      (store) => {
        const file = store.canvasFilesSlice.files.get(path);
        if (!file) {
          return;
        }
        Object.assign(file, { uploadPercent });
      },
      false,
      "canvasFiles/setFileUploadPercent"
    );
  },
  setUploadRequestAbort: (path, requestAbort) => {
    set(
      (store) => {
        store.canvasFilesSlice.uploadRequestsAbort.get(path)?.(); // abort previous upload
        store.canvasFilesSlice.uploadRequestsAbort.set(path, requestAbort);
      },
      false,
      "canvasFiles/setUploadRequestAbort"
    );
  },
  deleteUploadRequestAbort: (path) => {
    set(
      (store) => {
        store.canvasFilesSlice.uploadRequestsAbort.delete(path);
      },
      false,
      "canvasFiles/deleteUploadRequestAbort"
    );
  },
  clearStore: () => {
    set(
      (store) => {
        Object.assign(store.canvasFilesSlice, getInitialState());
      },
      false,
      "canvasFiles/clearStore"
    );
  },
});
