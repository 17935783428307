import { useCallback, useMemo } from "react";
import {
  useCanvasState,
  useUserState,
  useWebsocketState,
  useToastsState,
} from "store";
import { WS_INSTRUCTION } from "config/canvasConfig";

export function useSendCanvasMessage() {
  const isEditor = useCanvasState((slice) => slice.isEditor);
  const userID = useUserState((slice) => slice.userID);

  const send = useWebsocketState((slice) => slice.send);
  const addToast = useToastsState((slice) => slice.addToast);

  /**
   * Send a WebSocket message to the Canvas Service
   */
  const sendCanvasMessage = useCallback(
    async (instruction: WS_INSTRUCTION, message: unknown) => {
      // only users with "Can Edit" access are allowed to send regular WS messages;
      // sending user cursor position and zoom is allowed to all logged in users
      const validInstruction =
        instruction === WS_INSTRUCTION.UPDATE_USER_ZOOM ||
        instruction === WS_INSTRUCTION.UPDATE_USER_POSITION;
      const messageSendEnabled = isEditor || (userID && validInstruction);

      if (messageSendEnabled) {
        return await send({ instruction, message });
      }

      addToast({
        variant: "warning",
        message: "You are not authorized to make changes to this canvas",
      });
    },
    [isEditor, userID]
  );

  return useMemo(
    () => ({
      sendCanvasMessage,
    }),
    [sendCanvasMessage]
  );
}
