import styles from "./Constants.module.scss";
import { useMemo } from "react";
import cn from "classnames";
import { useConfirmModal } from "hooks/useConfirmModal";
import { useToastsState } from "store";
import IconButton from "components/common/IconButton/IconButton";
import Switch from "components/common/Switch/Switch";
import TextField from "components/common/TextField/TextField";
import Typography from "components/Typography/Typography";
import { BiCopy, BiEditAlt } from "react-icons/bi";
import { useAssetsData } from "hooks/useAssetsData";
import { AssetStatus } from "../types/abstractTypes";
import type { ConstantVersionType } from "../types/constantTypes";
import { ASSET_PERMISSION, MESSAGE, SECRET_VALUE_PLACEHOLDER } from "../config";

export function Constants() {
  const addToast = useToastsState((slice) => slice.addToast);
  const { openConfirmModal } = useConfirmModal();
  const {
    getCurrentAssetItemsData,
    getCreatedAssetItemActions,
    getCreatedAssetItemData,
    getAssetSpecificData,
  } = useAssetsData();
  const { activeItemData, activeItemVersionId } =
    getCurrentAssetItemsData("constant");
  const { assetOperationType } = getCreatedAssetItemData("constant");
  const { setAssetItemOperationType, setCreatedAssetItemData } =
    getCreatedAssetItemActions("constant");
  const { setAssetVersionIsSecret } = getAssetSpecificData("constant");
  // TODO: review this stuff, the current approach is not the best
  let itemName: string | null = "";
  let itemDescription: string | null = "";
  let itemValue: string | null = "";
  let itemSecret: boolean | null = false;
  let itemVersion: number | null = null;
  let itemStatus: number | null = null;
  let itemId: string | null = null;
  let itemArchive: boolean = false;
  let itemPermission: ConstantVersionType["permission"] = null;

  if (
    activeItemData &&
    "constant" in activeItemData &&
    activeItemVersionId &&
    "versions" in activeItemData
  ) {
    const {
      constant: { name },
      versions,
    } = activeItemData;

    const activeVersionData =
      Object.values(versions).find((item) => item.id === activeItemVersionId) ??
      Object.values(versions)[0];

    itemName = name;
    itemDescription = activeVersionData?.description;
    itemValue = activeVersionData?.value;
    itemSecret = activeVersionData?.secret;
    itemVersion = activeVersionData?.version;
    itemStatus = activeVersionData?.status;
    itemId = activeVersionData?.id;
    itemArchive = activeVersionData?.archive;
    itemPermission = activeVersionData?.permission;
  }

  const isEditor = itemPermission === ASSET_PERMISSION.write;

  const constantStatusMarker = useMemo(() => {
    if (itemStatus) {
      return AssetStatus[itemStatus].toLowerCase();
    }
    return null;
  }, [itemStatus]);

  const constantValue = useMemo(() => {
    if (itemSecret) {
      return SECRET_VALUE_PLACEHOLDER;
    }

    return itemValue;
  }, [itemSecret, itemValue]);

  const copyValueToClipboard = (): void => {
    navigator.clipboard.writeText(constantValue ?? "").then(() => {
      addToast({
        message: "Copied value to clipboard",
        variant: "info",
      });
    });
  };

  const handleOpenConfirmSecretModal = async () => {
    if (itemSecret || !itemId) {
      return;
    }

    const confirmed = await openConfirmModal({
      message: MESSAGE.constantVersionMakeSecretConfirm,
    });

    if (confirmed) {
      setAssetVersionIsSecret(itemId);
    }
  };

  if (!activeItemVersionId) {
    return null;
  }

  return (
    <div
      className={cn(styles.container, {
        [styles.containerActionsDisabled]: assetOperationType === "editName",
      })}
    >
      <Typography variant="h3">Version Details</Typography>
      <div className={styles.valueSection}>
        <Typography variant="body2" className={styles.subtitleText}>
          Value
        </Typography>
        <div className={styles.valueRow}>
          <Typography variant="h2" className={styles.constantValueText}>
            {constantValue}
          </Typography>
          {!itemArchive && isEditor ? (
            <IconButton
              size="small"
              className={styles.icon}
              onClick={() => {
                setAssetItemOperationType("edit");
                setCreatedAssetItemData({
                  name: itemName,
                  description: itemDescription,
                  value: itemSecret ? "" : itemValue,
                  secret: itemSecret,
                });
              }}
            >
              <BiEditAlt />
            </IconButton>
          ) : null}
          {!itemSecret && constantValue && (
            <IconButton
              size="small"
              className={styles.icon}
              onClick={copyValueToClipboard}
            >
              <BiCopy />
            </IconButton>
          )}
        </div>
      </div>
      <div className={styles.additionalData}>
        <div className={styles.boxWrapper}>
          <Typography variant="body2" className={styles.subtitleText}>
            Name
          </Typography>
          <Typography variant="h2" className={styles.nameText}>
            {itemName ?? ""}
          </Typography>
        </div>
        <div className={styles.boxWrapper}>
          <Typography variant="body2" className={styles.subtitleText}>
            Version
          </Typography>
          <Typography variant="h2">v.{itemVersion ?? ""}</Typography>
        </div>
        <div className={styles.boxWrapper}>
          <Typography variant="body2" className={styles.subtitleText}>
            Secret
          </Typography>
          <Switch
            isChecked={!!itemSecret}
            disabled={itemSecret || itemArchive || !constantValue || !isEditor}
            onChange={handleOpenConfirmSecretModal}
          />
        </div>
        <div>
          <Typography variant="body2" className={styles.subtitleText}>
            Status
          </Typography>
          <Typography variant="h2" className={styles.statusMarkerContainer}>
            <div
              className={cn(
                styles.statusMarker,
                styles[constantStatusMarker ?? "no_status"]
              )}
            />
            {constantStatusMarker?.replace("_", " ") ?? "No status"}
          </Typography>
        </div>
      </div>
      <TextField
        type="textarea"
        inputClassName={styles.descriptionArea}
        value={itemDescription ?? ""}
        onChange={() => {}}
        isDisabled
      />
    </div>
  );
}
