// modal for welcoming new users to the app and guiding them to create their first canvas
import { useState } from "react";
import styles from "./NewUserWelcomeModal.module.scss";
import { motion } from "framer-motion";
import UsernameStep from "./components/UsernameStep/UsernameStep";
import FirstCanvasStep from "./components/FirstCanvasStep/FirstCanvasStep";

interface NewUserWelcomeModalProps {
  onCreate: () => void;
  onClose: () => void;
  isCreatingCanvas: boolean;
  errorCreatingCanvas: boolean;
}

export function NewUserWelcomeModal({
  onCreate,
  onClose,
  isCreatingCanvas,
  errorCreatingCanvas,
}: NewUserWelcomeModalProps) {
  const [currentStep, setCurrentStep] = useState(0);

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  return (
    <div className={styles.backdrop}>
      <motion.div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={styles.content}
        key="new-user-modal"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          duration: 0.2,
          type: "spring",
          damping: 12,
          stiffness: 100,
        }}
      >
        {currentStep === 0 && <UsernameStep onNext={handleNext} />}
        {currentStep === 1 && (
          <FirstCanvasStep
            onCreate={onCreate}
            onClose={onClose}
            isCreatingCanvas={isCreatingCanvas}
            errorCreatingCanvas={errorCreatingCanvas}
          />
        )}
      </motion.div>
    </div>
  );
}
