import shareResourceModalStyles from "components/modals/ShareResourceModal/ShareResourceModal.module.scss";
import styles from "./ConnectionNavItem.module.scss";
import {
  type FC,
  type SyntheticEvent,
  useEffect,
  useMemo,
  useRef,
} from "react";
import cn from "classnames";
import { useNavigate, useParams } from "react-router-dom";
import { BiArchiveOut, BiShareAlt, BiTrash } from "react-icons/bi";

import { useModal } from "hooks/useModal";
import { useConfirmModal } from "hooks/useConfirmModal";
import { RESOURCE_TYPE } from "models/user";
import RowButtons from "components/common/RowButtons/RowButtons";
import Typography from "components/Typography/Typography";
import { ShareResourceModal } from "components/modals";
import { useAssetsState } from "store";
import useConnectionActions from "hooks/assets/useConnectionActions";
import { type ConnectionItemType } from "pages/Assets/types/connectionTypes";

import { LABEL, MESSAGE } from "pages/Assets/config";
import { getAssetsBaseUrl } from "pages/Assets/utils";
import { ASSET_PERMISSION } from "pages/Assets/config";

import ConnectionsNavItemDropdown from "../ConnectionsNavItemDropdown";
import EditableConnectionsNavItem from "../EditableConnectionsNavItem";
import { getConnectionTypeIcon } from "../../utils";

const ConnectionsNavItem: FC<ConnectionItemType> = (
  data: ConnectionItemType
) => {
  const rootRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const { openModal } = useModal();
  const { openConfirmModal } = useConfirmModal();
  const { workspaceID, orgID } = useParams();

  const {
    connection: {
      id,
      resource_id,
      name,
      connection_type_id,
      archive,
      permission,
    },
  } = data;

  const { deleteConnection, switchArchiveConnection } = useConnectionActions();

  const baseURL = useMemo(() => {
    return getAssetsBaseUrl({
      organizationId: orgID,
      workspaceId: workspaceID,
    });
  }, [orgID, workspaceID]);

  const {
    connectionTypes,
    createdItem: {
      assetOperationType,
      data: { id: createdItemId },
    },
    displayArchivedItems,
    selectedItem: { id: activeItemId },
  } = useAssetsState((slice) => slice.connections);
  const {
    clearSelectedItem,
    setCreatedItemData,
    setOperationType,
    deleteDataItem,
  } = useAssetsState((slice) => slice.connectionsActions);

  const isActive = id === activeItemId;

  useEffect(() => {
    if (isActive) {
      rootRef.current?.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }, [isActive, displayArchivedItems]);

  const Icon = useMemo(() => {
    const connectionTypeData = connectionTypes[connection_type_id];
    const icon = getConnectionTypeIcon(connectionTypeData?.connection_type);

    return icon;
  }, [connectionTypes]);

  const isItemClickDisabled =
    isActive ||
    assetOperationType === "create" ||
    assetOperationType === "edit";

  const handleItemClick = (): void => {
    if (isItemClickDisabled) {
      return;
    }
    clearSelectedItem();
    navigate(`${baseURL}/connections/${id}`);
  };

  const handleUnarchive = (e: SyntheticEvent): void => {
    e.stopPropagation();
    switchArchiveConnection(id, false);
  };

  const handleConfirmDelete = async () => {
    const confirmed = await openConfirmModal({
      message: MESSAGE.assetDeleteConfirm,
      confirmButtonLabel: LABEL.deleteConfirmButton,
      confirmButtonVariant: "crucial",
    });

    if (confirmed) {
      deleteConnection(id);
    }
  };

  const handleShare = () => {
    openModal({
      classes: {
        container: shareResourceModalStyles.modal,
        title: shareResourceModalStyles.modal_title,
      },
      title: `Share Connection (${name || "Untitled"})`,
      content: ({ onModalClose }) => (
        <ShareResourceModal
          resourceId={resource_id}
          resourceType={RESOURCE_TYPE.CONNECTION_ASSET}
          resourceLink={`${window.location.origin}${baseURL}/connections/${id}`}
          onModalClose={onModalClose}
          onLeaveResource={() => {
            deleteDataItem(id);
            navigate(`${baseURL}/connections`, { replace: true });
          }}
        />
      ),
    });
  };

  const actionIsDisabled =
    assetOperationType === "edit" || assetOperationType === "create";

  const isEditor = permission === ASSET_PERMISSION.write;

  const editIsDisabled = archive || actionIsDisabled || !isEditor;

  const setEditableItemData = (e: SyntheticEvent) => {
    e.stopPropagation();

    if (editIsDisabled) {
      return;
    }

    setOperationType("edit");
    setCreatedItemData({
      name: name ?? "",
      connection_type_id,
      id,
    });
  };

  if (assetOperationType === "edit" && id === createdItemId) {
    return <EditableConnectionsNavItem />;
  }

  return (
    <>
      <div
        ref={rootRef}
        className={cn(styles.container, {
          [styles.container_active]:
            isActive && assetOperationType !== "create",
          [styles.container_click_disabled]: isItemClickDisabled,
        })}
        onClick={handleItemClick}
      >
        <div className={styles.title__block_wrapper}>
          <div className={styles.title_block}>
            <div
              className={cn(styles.icon, {
                [styles.icon_archived]: archive,
              })}
            >
              <Icon />
            </div>
            <div
              className={cn(styles.text_block, {
                [styles.text_block_action_disabled]: isItemClickDisabled,
              })}
              onDoubleClick={setEditableItemData}
            >
              <Typography
                variant="caption1"
                className={cn(styles.text, {
                  [styles.text_archived]: archive,
                })}
              >
                {name ?? ""}
              </Typography>
            </div>
          </div>
          {isActive && !archive && !actionIsDisabled && (
            <>
              {!workspaceID && (
                <RowButtons
                  buttons={[
                    {
                      icon: BiShareAlt,
                      tooltip: "Share",
                      onClick: handleShare,
                    },
                  ]}
                  size="medium"
                />
              )}
              <ConnectionsNavItemDropdown {...data} />
            </>
          )}
          {archive && !actionIsDisabled && isEditor ? (
            <RowButtons
              className={styles.row_buttons}
              buttons={[
                {
                  icon: BiArchiveOut,
                  tooltip: "Unarchive",
                  onClick: handleUnarchive,
                },
                {
                  icon: BiTrash,
                  tooltip: "Delete",
                  onClick: handleConfirmDelete,
                },
              ]}
              size="medium"
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default ConnectionsNavItem;
