import { type Viewport } from "reactflow";
import { type StateCreator } from "store";
import {
  SIDEBAR_DEFAULT_WIDTH,
  CANVAS_LEFT_SIDEBAR,
  type CANVAS_RIGHT_SIDEBAR,
} from "config/canvasConfig";
import type { LayerType } from "models/canvas";

type State = {
  leftSidebar: CANVAS_LEFT_SIDEBAR;
  leftSidebarWidth: number;
  rightSidebar: CANVAS_RIGHT_SIDEBAR | null;
  rightSidebarWidth: number;
  showAppTour: boolean;
  debouncedZoom: number;
  optimalFontSize: number;
  controlsScale: number;
  avatarScale: number;
  fleetScale: number;
  layerViewports: Record<LayerType["id"], Viewport>;
};

type Actions = {
  getLeftSidebar: () => State["leftSidebar"];
  setInitialLeftSidbar: (
    sidebarParam: string | null,
    options: CANVAS_LEFT_SIDEBAR[]
  ) => void;
  setLeftSidebar: (sidebar: CANVAS_LEFT_SIDEBAR) => void;
  setLeftSidebarWidth: (width: number) => void;
  setRightSidebar: (sidebar: CANVAS_RIGHT_SIDEBAR | null) => void;
  setRightSidebarWidth: (width: number) => void;
  setShowAppTour: (showAppTour: boolean) => void;
  setDebouncedZoom: (zoom: number) => void;
  setOptimalFontSize: (optimalFontSize: number) => void;
  setControlsScale: (scale: number) => void;
  setAvatarScale: (scale: number) => void;
  setFleetScale: (scale: number) => void;
  saveLayerViewport: (layerId: LayerType["id"], viewport: Viewport) => void;
  getLayerViewport: (layerId: LayerType["id"]) => Viewport | null;
  clearStore: () => void;
};

const getInitialState = (): State => ({
  leftSidebar: CANVAS_LEFT_SIDEBAR.LAYERS,
  leftSidebarWidth: SIDEBAR_DEFAULT_WIDTH,
  rightSidebar: null,
  rightSidebarWidth: SIDEBAR_DEFAULT_WIDTH,
  showAppTour: false,
  debouncedZoom: 1,
  optimalFontSize: 12,
  controlsScale: 1,
  avatarScale: 1,
  fleetScale: 1,
  layerViewports: {},
});

export type CanvasLayoutSlice = State & Actions;

export const createCanvasLayoutSlice: StateCreator<CanvasLayoutSlice> = (
  set,
  get
) => ({
  ...getInitialState(),
  getLeftSidebar: () => get().canvasLayoutSlice.leftSidebar,
  setInitialLeftSidbar: (sidebarParam, options) => {
    set(
      (store) => {
        let sidebar = CANVAS_LEFT_SIDEBAR.COLLAPSED;

        if (options.some((s) => s === sidebarParam)) {
          sidebar = sidebarParam as CANVAS_LEFT_SIDEBAR;
        } else if (options.some((s) => s === CANVAS_LEFT_SIDEBAR.LAYERS)) {
          sidebar = CANVAS_LEFT_SIDEBAR.LAYERS;
        }

        store.canvasLayoutSlice.leftSidebar = sidebar;
      },
      false,
      "canvasLayout/setInitialLeftSidbar"
    );
  },
  setLeftSidebar: (sidebar) => {
    set(
      (store) => {
        store.canvasLayoutSlice.leftSidebar = sidebar;
      },
      false,
      "canvasLayout/setLeftSidebar"
    );
  },
  setLeftSidebarWidth: (width) => {
    set(
      (store) => {
        store.canvasLayoutSlice.leftSidebarWidth = width;
      },
      false,
      "canvasLayout/setLeftSidebarWidth"
    );
  },
  setRightSidebar: (sidebar) => {
    set(
      (store) => {
        store.canvasLayoutSlice.rightSidebar = sidebar;
      },
      false,
      "canvasLayout/setRightSidebar"
    );
  },
  setRightSidebarWidth: (width) => {
    set(
      (store) => {
        store.canvasLayoutSlice.rightSidebarWidth = width;
      },
      false,
      "canvasLayout/setRightSidebarWidth"
    );
  },
  setShowAppTour: (showAppTour) => {
    set(
      (store) => {
        store.canvasLayoutSlice.showAppTour = showAppTour;
      },
      false,
      "canvasLayout/setShowAppTour"
    );
  },
  setOptimalFontSize: (optimalFontSize) => {
    set(
      (store) => {
        store.canvasLayoutSlice.optimalFontSize = optimalFontSize;
      },
      false,
      "canvasLayout/setOptimalFontSize"
    );
  },
  setDebouncedZoom: (zoom) => {
    set(
      (store) => {
        store.canvasLayoutSlice.debouncedZoom = zoom;
      },
      false,
      "canvasLayout/setDebouncedZoom"
    );
  },
  setControlsScale: (controlsScale) => {
    set(
      (store) => {
        store.canvasLayoutSlice.controlsScale = controlsScale;
      },
      false,
      "canvasLayout/setControlsScale"
    );
  },
  setAvatarScale: (avatarScale) => {
    set(
      (store) => {
        store.canvasLayoutSlice.avatarScale = avatarScale;
      },
      false,
      "canvasLayout/setAvatarScale"
    );
  },
  setFleetScale: (fleetScale) => {
    set(
      (store) => {
        store.canvasLayoutSlice.fleetScale = fleetScale;
      },
      false,
      "canvasLayout/setFleetScale"
    );
  },
  saveLayerViewport: (layerId, viewport) => {
    set(
      (store) => {
        store.canvasLayoutSlice.layerViewports[layerId] = viewport;
      },
      false,
      "canvasLayout/saveLayerViewport"
    );
  },
  getLayerViewport: (layerId) => {
    return get().canvasLayoutSlice.layerViewports[layerId] || null;
  },
  clearStore: () => {
    set(
      (store) => {
        Object.assign(store.canvasLayoutSlice, getInitialState());
      },
      false,
      "canvasLayout/clearStore"
    );
  },
});
