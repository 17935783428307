import { UserPermission } from "models/user";
import { SORTING_COLUMN } from "./localPreferences";

export const AUTH0_DOMAIN = import.meta.env.VITE_AUTH0_DOMAIN || "";
export const AUTH0_CLIENT_ID = import.meta.env.VITE_AUTH0_CLIENT_ID || "";
export const AUTH0_AUDIENCE = import.meta.env.VITE_AUTH0_AUDIENCE || "";

export const AUTH0_TOKEN_REFRESH_INTERVAL = Number(
  import.meta.env.VITE_AUTH0_TOKEN_REFRESH_INTERVAL ?? 60000
);

export const CANVAS_WS_URL =
  import.meta.env.VITE_CANVAS_WS_URL || "ws://localhost:8080/ws/canvas_service";

export const CANVAS_URL = import.meta.env.VITE_CANVAS_URL || "/canvas";

export const NOTIFICATIONS_WS_URL =
  import.meta.env.VITE_NOTIFICATIONS_WS_URL || "ws://localhost:3000";

export const USER_API_URL =
  import.meta.env.VITE_USER_API_URL || "/api/user_service";

export const CANVAS_API_URL =
  import.meta.env.VITE_CANVAS_API_URL || "/api/canvas_service";

export const ASSET_API_URL =
  import.meta.env.VITE_ASSET_API_URL || "/api/asset_service";

export const COMMUNITY_BASE_URL =
  import.meta.env.VITE_COMMUNITY_BASE_URL || "https://www.zerve.ai";

// do not send user_position_updated WS messages
export const DISABLE_UPDATE_USER_POSITION = Boolean(
  import.meta.env.VITE_DISABLE_UPDATE_USER_POSITION || false
);

export enum DATA_LOAD_STATUS {
  NOT_LOADED = "NOT_LOADED",
  LOADING = "LOADING",
  LOADED = "LOADED",
  ERROR = "ERROR",
}

export enum VALIDATION_STATUS {
  VALID = "VALID",
  INVALID = "INVALID",
}

export const USER_PERMISSION_CONFIG = Object.freeze({
  [UserPermission.canView]: {
    label: "Can View",
    value: UserPermission.canView,
  },
  [UserPermission.canEdit]: {
    label: "Can Edit",
    value: UserPermission.canEdit,
  },
});

export const FORM_TAGS = Object.freeze([
  "input",
  "textarea",
  "select",
  "INPUT",
  "TEXTAREA",
  "SELECT",
]);

export enum SORTING_DIRECTION {
  ASCENDING = "ascending",
  DESCENDING = "descending",
}

export enum AWS_REGION {
  US_EAST_1 = "us-east-1",
  US_WEST_2 = "us-west-2",
  EU_CENTRAL_1 = "eu-central-1",
  EU_EAST_1 = "eu-east-1",
}

export enum SORTING_TYPE {
  TEXT = "text",
  DATE = "date",
}

export const SORTING_COLUMN_SORTING_TYPE = Object.freeze({
  [SORTING_COLUMN.name]: SORTING_TYPE.TEXT,
  [SORTING_COLUMN.createdAt]: SORTING_TYPE.DATE,
  [SORTING_COLUMN.lastModified]: SORTING_TYPE.DATE,
  [SORTING_COLUMN.shareDate]: SORTING_TYPE.DATE,
  [SORTING_COLUMN.sharedBy]: SORTING_TYPE.TEXT,
  [SORTING_COLUMN.permission]: SORTING_TYPE.TEXT,
});

export enum ACCESS_REQUEST_STATUS {
  ACCEPTED = 1,
  DENIED = 2,
}

export enum AI_MODEL {
  OPENAI_GPT = "OPENAI_GPT",
  BEDROCK_CLAUDE = "BEDROCK_CLAUDE",
  MISTRAL = "MISTRAL",
}
