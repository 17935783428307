import cn from "classnames";

import styles from "./Tabs.module.scss";

import { type TabsProps } from "./types";

export default function Tabs({ tabs, className }: TabsProps) {
  return (
    <nav className={cn(styles.tabs, className)}>
      {tabs.map(({ id, active, Icon, label, onClick, indicator }) => {
        const indicatorClass =
          indicator === "success"
            ? styles.tabs__tab__idicator_success
            : styles.tabs__tab__idicator_fail;

        return (
          <div
            key={id}
            className={
              active
                ? cn(styles.tabs__tab, styles.tabs__tab__active)
                : styles.tabs__tab
            }
            onClick={onClick}
          >
            {Icon && <Icon className={styles.tabs__tab__icon} />}
            <span className={styles.tabs__tab__label}>{label}</span>
            {indicator && <div className={indicatorClass} />}
          </div>
        );
      })}
    </nav>
  );
}
