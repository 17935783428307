import { USER_API_URL } from "config/appConfig";
import { callAPI } from "./call-api";

interface UpgradeDetails {
  success_url: string;
  cancel_url: string;
  user_id: string;
  price: string;
}

export const getUserSettings = async () =>
  await callAPI({
    method: "GET",
    url: `${USER_API_URL}/user_settings`,
  });

export const patchUserSettings = async (data: any) =>
  await callAPI({
    method: "PATCH",
    url: `${USER_API_URL}/user_settings`,
    data,
  });

export const getUserAvatar = async () =>
  await callAPI({
    method: "GET",
    url: `${USER_API_URL}/user_settings/avatar`,
  });

export const postUserAvatar = async (avatar: string) =>
  await callAPI<string>({
    method: "POST",
    url: `${USER_API_URL}/user_settings/avatar`,
    files: { upload_file: avatar },
  });

export const patchUserPassword = async (data: any) =>
  await callAPI({
    method: "PATCH",
    url: `${USER_API_URL}/users/change_password`,
    data,
  });

export const getUserPreferences = async () =>
  await callAPI({
    method: "GET",
    url: `${USER_API_URL}/user_preferences`,
  });

export const patchUserPreferences = async (data: any) =>
  await callAPI({
    method: "PATCH",
    url: `${USER_API_URL}/user_preferences`,
    data,
  });

export const deleteUserAccount = async (userId: string) =>
  await callAPI({
    method: "DELETE",
    url: `${USER_API_URL}/users/${userId}`,
  });

/* BILLING */

export const getBillingProducts = async (userId: string | undefined) => {
  const personalUser = userId ? `?user_id=${userId}` : "";
  return await callAPI({
    url: `${USER_API_URL}/billing${personalUser}`,
    method: "GET",
  });
};

export const upgradeBillingPlan = async (data: UpgradeDetails) => {
  return await callAPI({
    url: `${USER_API_URL}/billing/upgrade`,
    method: "POST",
    data,
  });
};

export const downgradeBillingPlan = async (subscription_id: string) => {
  return await callAPI({
    url: `${USER_API_URL}/billing/downgrade?subscription_id=${subscription_id}`,
    method: "POST",
  });
};

/* END BILLING */

// user MFA
export const getUserMFAEnabled = async (userId: string) =>
  await callAPI<boolean>({
    method: "GET",
    url: `${USER_API_URL}/users/${userId}/mfa`,
  });

export const setUserMFAEnabled = async (userId: string, mfaEnabled: boolean) =>
  await callAPI<boolean>({
    method: "PATCH",
    url: `${USER_API_URL}/users/${userId}/mfa`,
    data: {
      use_mfa: mfaEnabled,
    },
  });

const userSettingsService = {
  getUserSettings,
  patchUserSettings,
  getUserAvatar,
  getBillingProducts,
  upgradeBillingPlan,
  downgradeBillingPlan,
  getUserMFAEnabled,
  setUserMFAEnabled,
};

export default userSettingsService;
