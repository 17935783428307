import styles from "./FormDropdown.module.scss";
import {
  useCallback,
  useState,
  useMemo,
  type CSSProperties,
  type ReactElement,
} from "react";
import cn from "classnames";
import {
  useFloating,
  useDismiss,
  type Placement,
  autoUpdate,
  offset,
  flip,
  shift,
  FloatingPortal,
} from "@floating-ui/react";
import Checkmark from "assets/icons/checkmark.svg";
import { BiCaretUp, BiCaretDown } from "react-icons/bi";
import Typography from "components/Typography/Typography";

const ConditionalFloatingPortalWrapper = ({
  wrapWithPortal = false,
  children,
}: {
  wrapWithPortal: boolean;
  children: ReactElement;
}) => {
  if (wrapWithPortal) {
    return <FloatingPortal>{children}</FloatingPortal>;
  }

  return children;
};

type DropdownItem = {
  label: string;
  [key: string]: any;
};

export type FormDropdownProps = {
  autocomplete?: boolean;
  label?: string;
  id?: string;
  name?: string;
  value?: string | null;
  defaultValue?: string;
  options?: DropdownItem[];
  onChange?: (value: any) => void;
  placeholder?: string;
  style?: CSSProperties;
  className?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  placement?: Placement;
  required?: boolean;
  withCheckmark?: boolean;
  helperText?: string;
  error?: string;
  noOptionsText?: string;
  wrapContextMenuWithFloatingPortal?: boolean;
  containerClassName?: string;
  labelClassName?: string;
  inputClassName?: string;
  helperTextClassName?: string;
  floatingContainerClassName?: string;
  menuItemClassName?: string;
  noOptionsTextClassName?: string;
};

const FormDropdown = ({
  autocomplete = false,
  options = [],
  id,
  name,
  value,
  required,
  onChange,
  placeholder,
  label,
  disabled,
  placement = "bottom-end",
  withCheckmark = false,
  helperText,
  error,
  noOptionsText = "No Options...",
  wrapContextMenuWithFloatingPortal = false,
  containerClassName = "",
  labelClassName = "",
  inputClassName = "",
  helperTextClassName = "",
  floatingContainerClassName = "",
  menuItemClassName = "",
  noOptionsTextClassName = "",
}: FormDropdownProps) => {
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isInputFocused, setIsInputFocused] = useState(false);

  const onOpenChange = useCallback(
    (isOpen: boolean) => {
      setOpen(isOpen);
      autocomplete && searchText && setSearchText("");
    },
    [autocomplete, searchText]
  );

  const { x, y, refs, strategy, context } = useFloating({
    open,
    onOpenChange,
    whileElementsMounted: autoUpdate,
    placement,
    middleware: [flip(), shift(), offset(4)],
  });

  useDismiss(context, {
    escapeKey: true,
    outsidePress: true,
  });

  const defineListItem = (item: DropdownItem, index: number) => {
    const isSelected = item.value === value;
    return (
      <li
        className={cn([styles["menu__item"]], menuItemClassName, {
          [styles["menu__with-checkmark"]]: withCheckmark && !isSelected,
        })}
        onClick={() => {
          onChange?.(item?.value || item.label);
          onOpenChange(false);
        }}
        key={`listItem-${index}`}
      >
        {withCheckmark && isSelected && (
          <img
            className={styles["role-menu__checkmark"]}
            src={Checkmark}
            alt="checkmark"
            draggable={false}
          />
        )}
        {item.label || ""}
      </li>
    );
  };

  const filteredOptions = useMemo(() => {
    if (!autocomplete || !searchText) {
      return options;
    }

    const searchTextLowerCase = searchText.toLocaleLowerCase();

    return options.filter((option) => {
      return option.label.toLocaleLowerCase().includes(searchTextLowerCase);
    });
  }, [searchText, options, autocomplete]);

  const displayValue = useMemo(() => {
    return options.find((item) => item.value === value)?.label || value || "";
  }, [options, value]);

  const onInputChange = (e) => {
    if (!autocomplete || disabled) {
      return;
    }

    setSearchText(e.target.value);
    !open && setOpen(true);
  };

  const onInputFocus = () => {
    autocomplete && setIsInputFocused(true);
  };

  const onInputBlur = () => {
    autocomplete && setIsInputFocused(false);
  };

  const inputValue = autocomplete && isInputFocused ? searchText : displayValue;
  const inputPlaceholder =
    autocomplete && isInputFocused ? displayValue || placeholder : placeholder;

  const renderInfoText = (text: string, isError: boolean) => (
    <Typography
      variant="body2"
      className={cn(styles.helperText, helperTextClassName, {
        [styles.helperTextError]: isError,
      })}
    >
      {text}
    </Typography>
  );

  return (
    <div className={cn(styles.container, containerClassName)}>
      {label && (
        <label className={cn(styles.label, labelClassName)}>
          {label}
          {required && <span className={styles.label__required}>&nbsp;*</span>}
        </label>
      )}
      <div className={styles.inputWrapper}>
        <div
          className={styles.innerWrapper}
          onClick={() => {
            if (disabled) {
              return;
            }

            onOpenChange(!open);
          }}
        >
          <input
            id={id}
            name={name}
            ref={refs.setReference}
            value={inputValue}
            readOnly={!autocomplete}
            className={cn(styles.input, inputClassName, {
              [styles.searchInputWithValue]:
                autocomplete && Boolean(displayValue),
            })}
            placeholder={inputPlaceholder}
            disabled={disabled}
            onChange={onInputChange}
            onFocus={onInputFocus}
            onBlur={onInputBlur}
          />
          <div
            className={cn(styles.input__arrow, {
              [styles.input__arrow__open]: open,
              [styles.input__arrow__disabled]: disabled,
            })}
          >
            {open ? <BiCaretUp /> : <BiCaretDown />}
          </div>
        </div>
      </div>
      {open && (
        <ConditionalFloatingPortalWrapper
          wrapWithPortal={wrapContextMenuWithFloatingPortal}
        >
          <div
            ref={refs.setFloating}
            className={cn(
              {
                [styles.floatingContainerInPortal]:
                  wrapContextMenuWithFloatingPortal,
                [styles.floatingContainer]: !wrapContextMenuWithFloatingPortal,
              },
              floatingContainerClassName
            )}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
          >
            <ul className={styles.menu}>
              {filteredOptions.length ? (
                filteredOptions.map((item, index) =>
                  defineListItem(item, index)
                )
              ) : (
                <div className={cn(styles.noOptions, noOptionsTextClassName)}>
                  {noOptionsText}
                </div>
              )}
            </ul>
          </div>
        </ConditionalFloatingPortalWrapper>
      )}
      {helperText ? renderInfoText(helperText, false) : null}
      {error ? renderInfoText(error, true) : null}
    </div>
  );
};

export default FormDropdown;
