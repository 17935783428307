import styles from "./UserSettingsTabHeader.module.scss";
import { Link } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import Typography from "components/Typography/Typography";

interface UserSettingsTabHeaderProps {
  heading: string;
  subHeading?: string;
}

export function UserSettingsTabHeader({
  heading,
  subHeading,
}: UserSettingsTabHeaderProps) {
  return (
    <div className={styles.container}>
      <Link to="/" className={styles.backButton}>
        <BiArrowBack className={styles.icon} />
        Back to Homepage
      </Link>

      <Typography variant="h1">{heading}</Typography>

      {subHeading ? (
        <Typography variant="body2" className={styles.subHeading}>
          {subHeading}
        </Typography>
      ) : null}
    </div>
  );
}
