import { useMemo, useCallback } from "react";
import sortBy from "lodash-es/sortBy";
import pluralize from "pluralize";
import canvasService from "api/http/canvas-service";
import * as canvasRequirementsService from "api/http/canvas-requirements-service";
import {
  useReactFlow,
  type ResizeParamsWithDirection,
  type ResizeParams,
  type Viewport,
} from "reactflow";
import { useModal } from "hooks/useModal";
import { useSendCanvasMessage } from "hooks/useSendCanvasMessage";
import { useConfirmModal } from "hooks/useConfirmModal";
import {
  useCanvasState,
  useCanvasBlocksState,
  useCanvasLayoutState,
  useCanvasRequirementsState,
  useToastsState,
  useUserState,
  useLocalCanvasPreferencesState,
} from "store";
import { toBlob } from "html-to-image";
import CloudSettingsModal from "components/blocks/CloudSettingsModal/CloudSettingsModal";
import { ConfirmBlocksDeletionModal } from "components/blocks/ConfirmBlocksDeletionModal/ConfirmBlocksDeletionModal";
import { ConfirmLayersDeletionModal } from "components/blocks/ConfirmLayersDeletionModal/ConfirmLayersDeletionModal";
import { getBlockSpace, downloadFile } from "utils/helpers";
import {
  WS_INSTRUCTION,
  BLOCK_DETAILS,
  BLOCK_TYPE,
  LAYER_TYPE,
  CANVAS_RIGHT_SIDEBAR,
  LANGUAGE,
  type COMPUTE_ENV_TYPE,
} from "config/canvasConfig";
import {
  NODE_MIN_COORD_X,
  NODE_MIN_COORD_Y,
  NODE_MAX_COORD_X,
  NODE_MAX_COORD_Y,
} from "config/reactFlowConfig";
import type { BlockType, BufferBlock, LayerType } from "models/canvas";
import { updateBlock, clearOutput } from "api/http/blocks-service";
import { PublishedReportModal } from "components/PublishedReport/PublishedReportModal/PublishedReportModal";

const BLOCKS_MARGIN = 120;

export function useCanvasActions() {
  // Actions that can be performed on the canvas
  // 1. Canvas Actions
  // 2. Layer Actions
  // 3. Block Actions
  // 4. Edge Actions
  // 5. Navigation Actions
  // 6. Environment Actions
  // 7. Menu Actions
  // 8. Files Actions

  const { sendCanvasMessage } = useSendCanvasMessage();

  const canvasDontAskBeforeBlockOrLayerDeletion =
    useLocalCanvasPreferencesState(
      (slice) => slice.canvasDontAskBeforeBlockOrLayerDeletion
    );
  const setCanvasDontAskBeforeBlockOrLayerDeletion =
    useLocalCanvasPreferencesState(
      (slice) => slice.setCanvasDontAskBeforeBlockOrLayerDeletion
    );
  const { getNode, fitBounds } = useReactFlow<BlockType>();
  const addToast = useToastsState((slice) => slice.addToast);
  const { openModal } = useModal();
  const { openConfirmModal } = useConfirmModal();

  const userID = useUserState((slice) => slice.userID);

  const {
    isEditor,
    canvasId,
    layers,
    getBlock,
    getSelectedLayerBlocks,
    getSelectedLayerEdges,
    selectedLayerId,
    moveBlock,
    getSelectedLayer,
    setSelectedLayerId,
    activeBlockId,
    setDynamicallyMoved,
    fullscreenBlockId,
    setFullscreenBlockId,
    clearFullscreenBlockId,
    clearActiveBlockId,
    resizeBlockAction,
    renameLayer,
    setBufferBlocks,
  } = useCanvasState((slice) => {
    return {
      isEditor: slice.isEditor,
      canvasId: slice.canvasId,
      layers: slice.layers,
      getBlock: slice.getBlock,
      getSelectedLayerBlocks: slice.getSelectedLayerBlocks,
      getSelectedLayerEdges: slice.getSelectedLayerEdges,
      moveBlock: slice.moveBlock,
      getSelectedLayer: slice.getSelectedLayer,
      setSelectedLayerId: slice.setSelectedLayerId,
      selectedLayerId: slice.selectedLayerId,
      activeBlockId: slice.activeBlockId,
      setDynamicallyMoved: slice.setDynamicallyMoved,
      fullscreenBlockId: slice.fullscreenBlockId,
      setFullscreenBlockId: slice.setFullscreenBlockId,
      clearFullscreenBlockId: slice.clearFullscreenBlockId,
      clearActiveBlockId: slice.clearActiveBlockId,
      resizeBlockAction: slice.resizeBlock,
      renameLayer: slice.renameLayer,
      setBufferBlocks: slice.setBufferBlocks,
    };
  });

  const getBlockContent = useCanvasBlocksState(
    (slice) => slice.getBlockContent
  );
  const getBlockData = useCanvasBlocksState((slice) => slice.getBlockData);
  const patchBlockData = useCanvasBlocksState((slice) => slice.patchBlockData);

  const { rightSidebar, setRightSidebar } = useCanvasLayoutState((slice) => ({
    rightSidebar: slice.rightSidebar,
    setRightSidebar: slice.setRightSidebar,
  }));

  const { globalImports, setRequirementsLogs } = useCanvasRequirementsState(
    (slice) => ({
      globalImports: slice.globalImports,
      setRequirementsLogs: slice.setRequirementsLogs,
    })
  );

  // When a block is moved, recursive move all the overlapping blocks as well.
  // Keep target blocks to the right of source blocks.
  const dynamicMove = useCallback(
    (
      id: string,
      newX: number,
      newY: number,
      skipCurrentBlock: boolean = false
    ) => {
      let targetBlock = getBlock(id);
      const layer_id = getBlockData(id)?.layer_id;

      if (targetBlock && layer_id && !skipCurrentBlock) {
        // for the case of resizing
        moveBlock({
          layer_id,
          id,
          x: newX,
          y: newY,
        });
        setDynamicallyMoved([id], true);
        targetBlock = getBlock(id);
      }

      if (!targetBlock) {
        return;
      }

      // find blocks connected to the target
      const { downStreamBlocksIds, upStreamBlocksIds } =
        getSelectedLayerEdges().reduce<{
          downStreamBlocksIds: Set<string>;
          upStreamBlocksIds: Set<string>;
        }>(
          (acc, edge) => {
            if (edge.source === id) {
              acc.downStreamBlocksIds.add(edge.target);
            }
            if (edge.target === id) {
              acc.upStreamBlocksIds.add(edge.source);
            }
            return acc;
          },
          { downStreamBlocksIds: new Set(), upStreamBlocksIds: new Set() }
        );

      // get a space that the target block occupies
      const targetSpace = getBlockSpace(targetBlock, BLOCKS_MARGIN);

      // find all blocks that overlap with the one and move them
      getSelectedLayerBlocks().forEach((b) => {
        if (b.id === id) {
          return;
        }

        const { left, right, top, bottom, x, y } = getBlockSpace(b);

        let xOverlap = 0;
        let yOverlap = 0;

        // make downstream blocks appear to the right-hand side
        if (downStreamBlocksIds.has(b.id)) {
          xOverlap = targetSpace.right - left;
          if (xOverlap > 0) {
            dynamicMove(b.id, x + xOverlap, y);
          }
          return;
        }

        // make upstream blocks appear to the left-hand side
        if (upStreamBlocksIds.has(b.id)) {
          xOverlap = targetSpace.left - right;
          if (xOverlap < 0) {
            dynamicMove(b.id, x + xOverlap, y);
          }
          return;
        }

        // detect sides of a block that overlap with the target -
        // target's and block's projections overlap partially
        const leftSide = left < targetSpace.right && left > targetSpace.left;
        const rightSide = right > targetSpace.left && right < targetSpace.right;
        const topSide = top < targetSpace.bottom && top > targetSpace.top;
        const bottomSide =
          bottom > targetSpace.top && bottom < targetSpace.bottom;

        // detect sides of the target that are entirely covered by block's projection
        const xPojectionOverlap =
          left < targetSpace.left && right > targetSpace.right;
        const yPojectionOverlap =
          top < targetSpace.top && bottom > targetSpace.bottom;

        // do nothing if blocks do not overlap
        const hasHorizontalOverlap = leftSide || rightSide || xPojectionOverlap;
        const hasVerticalOverlap = topSide || bottomSide || yPojectionOverlap;
        if (!hasHorizontalOverlap || !hasVerticalOverlap) {
          return;
        }

        // detect x overlap value in pixels
        if (leftSide) {
          xOverlap = targetSpace.right - left;
        } else if (rightSide) {
          xOverlap = targetSpace.left - right;
        }

        // detect y overlap value in pixels
        if (topSide) {
          yOverlap = targetSpace.bottom - top;
        } else if (bottomSide) {
          yOverlap = targetSpace.top - bottom;
        }

        // We should move the block only in one direction.
        // If x overlap is lower than y overlap, move the block in the x direction.
        // If y overlap is lower than x overlap, move the block in the y direction.
        // If x and y overlap are equal, move the block in the x direction.
        if (xOverlap !== 0 && yOverlap !== 0) {
          if (Math.abs(yOverlap) < Math.abs(xOverlap)) {
            xOverlap = 0;
          } else {
            yOverlap = 0;
          }
        }

        // move overlapping block
        if (xOverlap !== 0 || yOverlap !== 0) {
          dynamicMove(b.id, x + xOverlap, y + yOverlap);
        }
      });
    },
    []
  );

  const resizeBlockAndMove = useCallback(
    (id, data: ResizeParamsWithDirection) => {
      const block = getBlock(id);
      const layer_id = getBlockData(id)?.layer_id;
      if (!block || !layer_id) {
        return;
      }
      resizeBlockAction({
        id,
        layer_id,
        x: data.x,
        y: data.y,
        width: data.width,
        height: data.height,
      });
      dynamicMove(id, data.x, data.y, true);
    },
    [dynamicMove]
  );

  const horizontallyAlignSelection = useCallback(
    (nodes: BlockType[]) => {
      if (nodes.length < 2) {
        return;
      }
      // get the mean y position of the selected blocks
      const meanY =
        nodes.reduce((acc, node) => acc + node.position.y, 0) / nodes.length;
      // move all selected blocks to the mean y position
      nodes.forEach((node) => {
        dynamicMove(node.id, node.position.x, meanY);
      });
    },
    [dynamicMove]
  );

  const horizontallyDistributeSelection = useCallback(
    (nodes: BlockType[]) => {
      if (nodes.length < 3) {
        return;
      }
      // sort the selected blocks by x position
      const sortedBlocks: BlockType[] = sortBy(
        nodes,
        (node: BlockType) => node.position.x
      );
      // get the mean x position of the selected blocks
      const meanX =
        sortedBlocks.reduce((acc, block) => acc + block.position.x, 0) /
        sortedBlocks.length;
      // get the mean distance between the selected blocks
      const meanDistance =
        sortedBlocks.reduce((acc, block, index) => {
          if (index === 0) {
            return acc;
          }
          return acc + block.position.x - sortedBlocks[index - 1].position.x;
        }, 0) /
        (sortedBlocks.length - 1);
      // move all selected blocks to the mean x position
      sortedBlocks.forEach((block, index) => {
        const new_x =
          meanX + (index - (sortedBlocks.length - 1) / 2) * meanDistance;
        dynamicMove(block.id, new_x, block.position.y);
      });
    },
    [dynamicMove]
  );

  const verticallyAlignSelection = useCallback(
    (nodes: BlockType[]) => {
      if (nodes.length < 2) {
        return;
      }
      // get the mean x position of the selected blocks
      const meanX =
        nodes.reduce((acc, node) => acc + node.position.x, 0) / nodes.length;
      // move all selected blocks to the mean x position
      nodes.forEach((node) => {
        dynamicMove(node.id, meanX, node.position.y);
      });
    },
    [dynamicMove]
  );

  const verticallyDistributeSelection = useCallback(
    (nodes: BlockType[]) => {
      if (nodes.length < 3) {
        return;
      }
      // sort the selected blocks by y position
      const sortedBlocks: BlockType[] = sortBy(
        nodes,
        (node: BlockType) => node.position.y
      );
      // get the mean y position of the selected blocks
      const meanY =
        sortedBlocks.reduce((acc, block) => acc + block.position.y, 0) /
        sortedBlocks.length;
      // get the mean distance between the selected blocks
      const meanDistance =
        sortedBlocks.reduce((acc, block, index) => {
          if (index === 0) {
            return acc;
          }
          return acc + (block.position.y - sortedBlocks[index - 1].position.y);
        }, 0) /
        (sortedBlocks.length - 1);
      // move all selected blocks to the mean y position
      sortedBlocks.forEach((block, index) => {
        const new_y =
          meanY + (index - (sortedBlocks.length - 1) / 2) * meanDistance;
        dynamicMove(block.id, block.position.x, new_y);
      });
    },
    [dynamicMove]
  );

  const sendBlocksToMove = useCallback(() => {
    // get all blocks with dynamicallyMoved set to true
    const blocksToMove = getSelectedLayerBlocks().filter(
      (block) => block.data.dynamicallyMoved
    );
    // send all blocks with dynamicallyMoved set to true to the backend
    const payload = blocksToMove.map((block) => {
      return {
        block_id: block.id,
        x: block.position.x,
        y: block.position.y,
      };
    });
    sendCanvasMessage(WS_INSTRUCTION.MOVE_SELECTION, payload);
    // set dynamicallyMoved to false for all blocks
    const blocksToMoveIds = blocksToMove.map((block) => block.id);
    setDynamicallyMoved(blocksToMoveIds, false);
  }, [sendCanvasMessage]);

  const resizeBlock = useCallback(
    (id, data: ResizeParams) => {
      sendCanvasMessage(WS_INSTRUCTION.RESIZE_BLOCK, {
        block_id: id,
        width: Math.round(data.width),
        height: Math.round(data.height),
        x: Math.round(data.x),
        y: Math.round(data.y),
      });
      setDynamicallyMoved([id], false);
      sendBlocksToMove();
    },
    [sendCanvasMessage, sendBlocksToMove]
  );

  const updateBlockOutputPaneSize = useCallback(
    (blockId: string, outputPaneSize: number) => {
      sendCanvasMessage(WS_INSTRUCTION.UPDATE_BLOCK_OUTPUT_PANE_SIZE, {
        block_id: blockId,
        output_pane_size: outputPaneSize,
      });
    },
    [sendCanvasMessage]
  );

  // calculate position to place a new block without overlapping with existing blocks
  // must be placed to the right of provided x and y position
  // if connected to a block, must be placed to the right of the connected block and as close as possible to the connected block
  // width and height are the dimensions of the new block and default to 800 and 400 respectively
  const calculateNewBlockPosition = useCallback(
    (
      x: number,
      y: number,
      connectedTo: string | undefined = undefined,
      width: number = 600,
      height: number = 300,
      spacing: number = BLOCKS_MARGIN,
      firstIteration: boolean = true
    ) => {
      let new_x = x;
      let new_y = y;
      const connectedBlock = getBlock(connectedTo);
      if (firstIteration && connectedBlock) {
        new_x = connectedBlock.position.x + connectedBlock.width + spacing;
        new_y = connectedBlock.position.y;
      }
      const newBlock = {
        x: new_x,
        y: new_y,
        width,
        height,
      };
      const margin = spacing || BLOCKS_MARGIN;
      // find overlapping blocks where x, y is the top-left corner of the block
      const overlappingBlocks = getSelectedLayerBlocks().filter((block) => {
        return (
          block.position.x + block.width > newBlock.x - margin &&
          block.position.x < newBlock.x + newBlock.width + margin &&
          block.position.y + block.height > newBlock.y + margin &&
          block.position.y < newBlock.y + newBlock.height + margin
        );
      });
      if (overlappingBlocks.length > 0) {
        const overlappingBlock = overlappingBlocks[0];
        // place below or to the right of the overlapping block depending on which side has more space
        const referenceBlock = connectedTo
          ? getBlock(connectedTo)
          : overlappingBlock;
        if (
          referenceBlock &&
          referenceBlock.position.x + referenceBlock.width - new_x <
            new_y - referenceBlock.position.y
        ) {
          // if connected block place above or below depending on which is closer to the connected block
          if (
            connectedTo &&
            new_y - referenceBlock.position.y <
              referenceBlock.position.y - new_y
          ) {
            new_y = referenceBlock.position.y - height - margin;
          } else {
            new_y =
              overlappingBlock.position.y + overlappingBlock.height + margin;
          }
        } else {
          new_x = overlappingBlock.position.x + overlappingBlock.width + margin;
        }
        return calculateNewBlockPosition(
          new_x,
          new_y,
          connectedTo,
          width,
          height,
          margin,
          false
        );
      }
      return { x: new_x, y: new_y };
    },
    []
  );

  // USER ACTIONS
  /**
   * Send a message to the backend to update the user position
   */
  const updateUserPosition = useCallback(
    (x: number, y: number, viewport: Viewport) => {
      sendCanvasMessage(WS_INSTRUCTION.UPDATE_USER_POSITION, {
        x,
        y,
        canvas_id: canvasId,
        layer_id: selectedLayerId,
        user_id: userID,
        active_block_id: activeBlockId,
        view: viewport,
      });
    },
    [canvasId, selectedLayerId, userID, activeBlockId, sendCanvasMessage]
  );

  const updateUserZoom = useCallback(
    (zoom: number, viewport: Viewport) => {
      sendCanvasMessage(WS_INSTRUCTION.UPDATE_USER_ZOOM, {
        zoom,
        canvas_id: canvasId,
        layer_id: selectedLayerId,
        user_id: userID,
        active_block_id: activeBlockId,
        view: viewport,
      });
    },
    [canvasId, selectedLayerId, userID, activeBlockId, sendCanvasMessage]
  );

  // CANVAS ACTIONS
  const renameCanvas = useCallback(
    (name: string) => {
      /*
    send a message to the backend to rename the canvas
    */
      sendCanvasMessage(WS_INSTRUCTION.RENAME_CANVAS, {
        canvas_id: canvasId,
        canvas_name: name,
      });
    },
    [canvasId, sendCanvasMessage]
  );

  /**
   * Generic Close Right Nav
   */
  const closeRightNav = useCallback(() => {
    setRightSidebar(null);
  }, []);

  /**
   * Set the right nav state to 1
   */
  const openFindSidebar = useCallback(() => {
    setRightSidebar(CANVAS_RIGHT_SIDEBAR.FIND);
  }, []);

  /**
   * Set the right nav state to 5
   */
  const openCanvasExecutorLogsSidebar = useCallback(() => {
    setRightSidebar(CANVAS_RIGHT_SIDEBAR.CANVAS_EXECUTOR_LOGS);
  }, []);

  /**
   * Set the right nav state to 2
   */
  const openFindAndReplaceSidebar = useCallback(() => {
    setRightSidebar(CANVAS_RIGHT_SIDEBAR.FIND_AND_REPLACE);
  }, []);

  /**
   * Toggle the comments sidebar panel
   */
  const toggleCommentsSidebar = useCallback(
    (open?: boolean) => {
      // if `open` is not provided, toggle the comments nav
      const shouldOpen =
        typeof open === "boolean"
          ? open
          : rightSidebar !== CANVAS_RIGHT_SIDEBAR.COMMENTS;

      if (shouldOpen) {
        setRightSidebar(CANVAS_RIGHT_SIDEBAR.COMMENTS);
      } else {
        setRightSidebar(null);
      }
    },
    [rightSidebar]
  );

  /**
   * Send a message to the backend to truncate the canvas.
   * This will only keep the selected block and its dependencies in a layer
   */
  const truncateToBlock = useCallback(
    (blockId: string, layerId?: string) => {
      sendCanvasMessage(WS_INSTRUCTION.TRUNCATE_CANVAS_BY_BLOCK, {
        block_id: blockId,
        layer_id: layerId || selectedLayerId,
      });
    },
    [sendCanvasMessage, selectedLayerId]
  );

  /**
   * Send a message to the backend to tidy the canvas.
   * This automatically arranges the blocks in the layer
   */
  const tidyCanvas = useCallback(() => {
    sendCanvasMessage(WS_INSTRUCTION.TIDY, {
      layer_id: selectedLayerId,
    });
  }, [sendCanvasMessage, selectedLayerId]);

  /**
   * Send a message to the backend to import a notebook
   */
  const importNotebook = useCallback(
    (notebook: any) => {
      sendCanvasMessage(WS_INSTRUCTION.IMPORT_NOTEBOOK, {
        notebook,
        canvas_id: canvasId,
        layer_id: selectedLayerId,
      });
    },
    [sendCanvasMessage, selectedLayerId, canvasId]
  );

  /**
   * Send a message to the backend about uploaded file
   */
  const fileUploaded = useCallback(
    (filename: string) => {
      sendCanvasMessage(WS_INSTRUCTION.FILE_UPLOADED, {
        filename,
      });
    },
    [sendCanvasMessage]
  );

  /**
   * 1. Use html-to-image to convert the canvas to a png
   * 2. Send a message to the backend to set the key image
   */
  const setKeyImage = useCallback(() => {
    if (!isEditor) {
      addToast({
        variant: "warning",
        message: "You must be an editor to set the key image",
      });
      return;
    }
    // @ts-expect-error-next-line
    toBlob(document.querySelector(".react-flow"), {
      filter: (node) => {
        // we don't want to add the minimap and the controls to the image
        if (
          node?.classList?.contains("react-flow__minimap") ||
          node?.classList?.contains("react-flow__controls") ||
          node?.classList?.contains("CanvasActions") ||
          node?.classList?.contains("BlockOption") ||
          node?.classList?.contains("BlockSelector")
        ) {
          return false;
        }

        return true;
      },
      quality: 0.92,
    })
      .then((blob) => {
        canvasService.uploadKeyImage(canvasId, blob);
      })
      .catch((error) => {
        console.error("oops, something went wrong!", error);
      });
  }, [isEditor, canvasId]);

  // LAYER ACTIONS
  const createLayer = useCallback(
    ({
      canvas_id,
      layer_type,
    }: {
      canvas_id: string;
      layer_type: LAYER_TYPE;
    }) => {
      /*
    1. send a message to the backend to create a layer
    */
      sendCanvasMessage(WS_INSTRUCTION.CREATE_LAYER, {
        canvas_id,
        layer_type,
      });
    },
    [sendCanvasMessage]
  );

  /**
   * Send a message to the backend to delete a layer after user's confirmation
   */
  const deleteLayer = useCallback(
    async (layerId: LayerType["id"]) => {
      const layer = layers.get(layerId);

      if (!layer) {
        return;
      }

      // prevent deleting the only development layer
      const canDeleteLayer =
        layer.type !== LAYER_TYPE.DEVELOPMENT ||
        Array.from(layers.values()).some(
          (l) => l.id !== layer.id && l.type === LAYER_TYPE.DEVELOPMENT
        );
      if (!canDeleteLayer) {
        return;
      }

      const confirmed = await openConfirmModal({
        message: <ConfirmLayersDeletionModal layerIds={[layerId]} />,
        confirmButtonVariant: "crucial",
        confirmButtonLabel: "Delete",
        dontAskAgain: canvasDontAskBeforeBlockOrLayerDeletion,
        onSetDontAskAgain: (value: boolean) => {
          setCanvasDontAskBeforeBlockOrLayerDeletion(value);
        },
      });
      if (confirmed) {
        sendCanvasMessage(WS_INSTRUCTION.DELETE_LAYER, {
          layer_id: layerId,
        });
      }

      return confirmed;
    },
    [layers, canvasDontAskBeforeBlockOrLayerDeletion, sendCanvasMessage]
  );

  const updateLayerName = useCallback(
    (layerId: string, name: string) => {
      const trimmedName = name.trim();
      const params = {
        layer_id: layerId,
        layer_name: trimmedName,
      };
      renameLayer(params);
      /*
    1. send a message to the backend to update a layer name
    */
      sendCanvasMessage(WS_INSTRUCTION.RENAME_LAYER, params);
    },
    [sendCanvasMessage]
  );

  // SELECTION ACTIONS

  /**
   * Send a message to the backend to delete the selected blocks
   */
  const deleteBlocks = useCallback(
    async (blockIds: BlockType["id"][]) => {
      const ids = blockIds.filter((id) => {
        const block = getBlock(id);
        return block && BLOCK_DETAILS[block.type].canDelete;
      });

      if (!ids.length) {
        // deny opening the confirmation modal for blocks that cannot be deleted
        return null;
      }

      const confirmed = await openConfirmModal({
        message: <ConfirmBlocksDeletionModal blockIds={ids} />,
        confirmButtonVariant: "crucial",
        confirmButtonLabel: "Delete",
        dontAskAgain: canvasDontAskBeforeBlockOrLayerDeletion,
        onSetDontAskAgain: (value: boolean) => {
          setCanvasDontAskBeforeBlockOrLayerDeletion(value);
        },
      });

      if (confirmed) {
        sendCanvasMessage(
          WS_INSTRUCTION.DELETE_SELECTION,
          ids.map((id) => {
            return { block_id: id };
          })
        );
      }

      return confirmed;
    },
    [canvasDontAskBeforeBlockOrLayerDeletion, sendCanvasMessage]
  );

  const moveSelection = useCallback(
    (newBlocks) => {
      /*
    1. send a message to the backend to move the selected blocks
    */
      const payload = newBlocks.map((block) => {
        return {
          block_id: block.id,
          x: block.position.x,
          y: block.position.y,
        };
      });
      sendCanvasMessage(WS_INSTRUCTION.MOVE_SELECTION, payload);
    },
    [sendCanvasMessage]
  );

  const exportSelection = useCallback(
    (nodes?: BlockType[]) => {
      /* Create a .py file with the selected blocks and call it by the layer name
    1. get the imports
    2. get the block content
    3. try and order them by edges, sources before targets
    4. create a python file with the imports and the blocks
    */
      const blocks =
        nodes || getSelectedLayerBlocks().filter((block) => block.selected);
      const selectedBlockIds =
        blocks.map((block) => {
          return { block_id: block.id };
        }) || [];
      if (!selectedBlockIds.length) {
        return;
      }
      // only keep python blocks
      const pythonBlocks = blocks.filter((block) => {
        return block.type === BLOCK_TYPE.PYTHON;
      });
      const blockContent = pythonBlocks.map((block) => {
        return getBlockContent(block.id);
      });
      const importCode = globalImports[LANGUAGE.PYTHON] || "";
      const codeToExport = [importCode, ...blockContent].join("\n\n");
      const blob = new Blob([codeToExport], {
        type: "text/plain;charset=utf-8",
      });
      const blobUrl = URL.createObjectURL(blob);

      downloadFile({
        href: blobUrl,
        fileName: `${getSelectedLayer()?.name as string}.py`,
      });
    },
    [globalImports]
  );

  // BLOCK ACTIONS
  const openFullscreen = useCallback((blockId?: string) => {
    // check that block id is in blocks
    const block = getBlock(blockId);
    if (block) {
      setFullscreenBlockId(block.id);
    }
  }, []);

  const closeFullscreen = useCallback(() => {
    clearFullscreenBlockId();
  }, []);

  const clearActiveBlock = useCallback(() => {
    clearActiveBlockId();
  }, []);

  const createBlock = useCallback(
    (
      blockType: BLOCK_TYPE,
      x: number,
      y: number,
      layerId: string,
      source_block_id?: string,
      name?: string,
      block_content?: string,
      properties?: any,
      compute_environment_type?: COMPUTE_ENV_TYPE
    ) => {
      const { x: newPositionX, y: newPositionY } = calculateNewBlockPosition(
        x,
        y,
        source_block_id
      );

      if (
        newPositionX < NODE_MIN_COORD_X ||
        newPositionX > NODE_MAX_COORD_X ||
        newPositionY < NODE_MIN_COORD_Y ||
        newPositionY > NODE_MAX_COORD_Y
      ) {
        addToast({
          variant: "error",
          message:
            "Failed to create a new block: the canvas has reached its coordinate limits",
        });

        return;
      }
      sendCanvasMessage(WS_INSTRUCTION.CREATE_BLOCK, {
        block_type: parseInt(blockType as string),
        x: newPositionX,
        y: newPositionY,
        layer_id: layerId,
        source_block_id,
        name,
        block_content,
        properties,
        compute_environment_type,
      });
    },
    [calculateNewBlockPosition, sendCanvasMessage]
  );

  const copyBlocks = useCallback((blockIds: BlockType["id"][]) => {
    const copiedBlocks: BufferBlock[] = [];

    for (const blockId of blockIds) {
      const block = getBlock(blockId);
      const blockData = getBlockData(blockId);
      const blockContent = getBlockContent(blockId);

      if (
        !block ||
        !BLOCK_DETAILS[block.type].canCopy ||
        !blockData ||
        typeof blockContent !== "string"
      ) {
        continue;
      }

      copiedBlocks.push({
        block,
        content: blockContent,
        data: blockData,
      });
    }

    const copyBlocksLength = blockIds.length;
    const copiedBlocksLength = copiedBlocks.length;

    if (copiedBlocksLength > 0) {
      setBufferBlocks(copiedBlocks);

      if (copiedBlocksLength === copyBlocksLength) {
        addToast({
          variant: "info",
          message: `Copied ${copiedBlocksLength} ${pluralize("block", copiedBlocksLength)}`,
        });
      } else {
        addToast({
          variant: "warning",
          message: `Copied ${copiedBlocksLength} ${pluralize("block", copiedBlocksLength)} out of ${copyBlocksLength} selected`,
        });
      }
    } else {
      addToast({
        variant: "warning",
        message: "No blocks available to copy",
      });
    }
  }, []);

  /**
   * Send a message to the backend to delete a block after user's confirmation
   */
  const deleteBlock = useCallback(
    async (blockId: BlockType["id"]) => {
      const block = getBlock(blockId);
      if (!block || !BLOCK_DETAILS[block.type].canDelete) {
        // deny opening the confirmation modal for a block that cannot be deleted
        return null;
      }

      const confirmed = await openConfirmModal({
        message: <ConfirmBlocksDeletionModal blockIds={[blockId]} />,
        confirmButtonVariant: "crucial",
        confirmButtonLabel: "Delete",
        dontAskAgain: canvasDontAskBeforeBlockOrLayerDeletion,
        onSetDontAskAgain: (value: boolean) => {
          setCanvasDontAskBeforeBlockOrLayerDeletion(value);
        },
      });
      if (confirmed) {
        sendCanvasMessage(WS_INSTRUCTION.DELETE_BLOCK, {
          block_id: blockId,
        });
      }

      return confirmed;
    },
    [canvasDontAskBeforeBlockOrLayerDeletion, sendCanvasMessage]
  );

  const updateBlockName = useCallback(
    ({ blockId, name }: { blockId: string; name: string }) => {
      const trimmedName = name.trim();
      patchBlockData(blockId, { name: trimmedName });
      sendCanvasMessage(WS_INSTRUCTION.RENAME_BLOCK, {
        block_id: blockId,
        block_name: trimmedName,
      });
    },
    [sendCanvasMessage]
  );

  const updateBlockPosition = useCallback(
    (blockId: string, x: number, y: number) => {
      sendCanvasMessage(WS_INSTRUCTION.MOVE_BLOCK, {
        block_id: blockId,
        x,
        y,
      });
    },
    [sendCanvasMessage]
  );

  const updateBlockContent = useCallback(
    (blockId: string, content: string) => {
      return sendCanvasMessage(WS_INSTRUCTION.UPDATE_BLOCK_CONTENT, {
        block_id: blockId,
        block_content: content,
      });
    },
    [sendCanvasMessage]
  );

  const updateGlobalImports = useCallback(
    (language: "python" | "r", globalImports: string) => {
      return sendCanvasMessage(
        WS_INSTRUCTION.UPDATE_GLOBAL_IMPORTS_BY_LANGUAGE,
        {
          canvas_id: canvasId,
          language,
          global_imports: globalImports,
        }
      );
    },
    [canvasId, sendCanvasMessage]
  );

  const updateBlockDescription = useCallback(
    (blockId: string, description: string) => {
      sendCanvasMessage(WS_INSTRUCTION.UPDATE_BLOCK_DESCRIPTION, {
        block_id: blockId,
        description,
      });
    },
    [sendCanvasMessage]
  );

  /**
   * Open the cloud settings modal
   */
  const openCloudSettings = useCallback((blockId: string) => {
    const block = getBlock(blockId);

    if (!block) {
      return;
    }

    openModal({
      title: "Cloud Compute",
      content: ({ onModalClose }) => (
        <CloudSettingsModal
          block={block}
          handleConfirm={(blockUpdate) => {
            updateBlock(block.id, blockUpdate)
              .then(() => {
                addToast({
                  message: "Cloud Compute settings have been updated",
                  variant: "success",
                });
              })
              .catch(() => {
                addToast({
                  message: "Updating Cloud Compute settings failed",
                  variant: "error",
                });
              });
          }}
          onModalClose={onModalClose}
        />
      ),
    });
  }, []);

  const openPublishReportModal = useCallback((blockId: string) => {
    const block = getBlock(blockId);

    if (!block) {
      return;
    }
    openModal({
      title: "Publish Report",
      content: ({ onModalClose }) => (
        <PublishedReportModal blockId={blockId} onModalClose={onModalClose} />
      ),
    });
  }, []);

  const lintBlock = useCallback(
    (blockId?: string) => {
      if (!blockId && !activeBlockId) {
        return;
      }
      sendCanvasMessage(WS_INSTRUCTION.LINT_BLOCK, {
        block_id: blockId || activeBlockId,
      });
    },
    [sendCanvasMessage, activeBlockId]
  );

  const lintAllBlocks = useCallback(
    (layerId?: string) => {
      sendCanvasMessage(WS_INSTRUCTION.LINT_ALL, {
        layer_id: layerId || selectedLayerId,
      });
    },
    [sendCanvasMessage, selectedLayerId]
  );

  const clearBlockOutput = useCallback(
    (blockId: string) => {
      clearOutput(blockId);
    },
    [clearOutput]
  );

  const createPipeline = useCallback(
    (blockId?: string) => {
      if (!blockId) {
        return;
      }
      sendCanvasMessage(WS_INSTRUCTION.CREATE_PIPELINE, {
        block_id: blockId,
      });
    },
    [sendCanvasMessage]
  );

  // Edge Actions

  /**
   * Send a message to the backend to create an edge
   */
  const createEdge = useCallback(
    ({
      sourceBlockId,
      targetBlockId,
    }: {
      sourceBlockId: BlockType["id"];
      targetBlockId: BlockType["id"];
    }) => {
      const edges = getSelectedLayerEdges();
      // check if edge already exists
      const edgeExists = edges.find(
        (edge) => edge.source === sourceBlockId && edge.target === targetBlockId
      );
      if (edgeExists) {
        return;
      }
      sendCanvasMessage(WS_INSTRUCTION.CREATE_EDGE, {
        source_block_id: sourceBlockId,
        target_block_id: targetBlockId,
        layer_id: selectedLayerId,
        canvas_id: canvasId,
      });
    },
    [sendCanvasMessage, selectedLayerId, canvasId]
  );

  /**
   * Send a message to the backend to  delete an edge
   */
  const deleteEdge = useCallback(
    (edgeId: string) => {
      // if edge starts with reactflow__edge- keep the last 36 characters
      sendCanvasMessage(WS_INSTRUCTION.DELETE_EDGE, {
        edge_id: edgeId,
      });
    },
    [sendCanvasMessage]
  );

  // Navigation Actions

  const zoomToBlock = useCallback(
    (blockId: BlockType["id"], duration = 500) => {
      const blockLayerId = getBlockData(blockId)?.layer_id;
      if (!blockLayerId) {
        return;
      }
      if (blockLayerId !== selectedLayerId) {
        setSelectedLayerId(blockLayerId);
      }

      const block = getBlock(blockId);

      if (block) {
        // we cannot use fitView and pass node id because the layer might not be active yet
        fitBounds(
          {
            x: block.position.x,
            y: block.position.y,
            width: block.width,
            height: block.height,
          },
          { padding: 0.2, duration }
        );
      }
    },
    [selectedLayerId, fitBounds]
  );

  /**
   * Zoom to a node on the current canvas
   */
  const zoomToNode = useCallback(
    (nodeId: BlockType["id"], duration = 500) => {
      const node = getNode(nodeId);
      if (!node) {
        return;
      }
      const yOffset = node.type === BLOCK_TYPE.GROUP ? -50 : 0; // add extra 50 for group name
      fitBounds(
        {
          x: node.position.x,
          y: node.position.y + yOffset,
          width: node.width || 0,
          height: node.height || 0,
        },
        { padding: 0.2, duration }
      );
    },
    [getNode, fitBounds]
  );

  // update localStorage for the canvas, this is used to show the output in a seperate window
  const updateCanvasLocalStorage = useCallback(() => {
    if (canvasId) {
      localStorage.setItem(
        "canvas_" + canvasId,
        JSON.stringify({
          fullscreenBlockId,
          activeBlockId,
          selectedLayer: selectedLayerId,
          timestamp: new Date().getTime(),
        })
      );
    }
  }, [canvasId, fullscreenBlockId, activeBlockId, selectedLayerId]);

  const resetLocalStorage = useCallback(() => {
    if (canvasId) {
      localStorage.removeItem(canvasId);
    }
  }, [canvasId]);

  const setCanvasOutputWindowOpen = useCallback(() => {
    // use localstorage to set the canvas output window open
    if (canvasId) {
      localStorage.setItem(
        "canvas_output_" + canvasId,
        JSON.stringify({
          open: true,
          // currentTime
          timestamp: new Date().getTime(),
        })
      );
    }
  }, [canvasId]);

  const removeCanvasOutputWindowOpen = useCallback(() => {
    // use localstorage to set the canvas output window open
    if (canvasId) {
      localStorage.removeItem("canvas_output_" + canvasId);
    }
  }, [canvasId]);

  // REQUIREMENTS ACTIONS

  const getBuildLog = useCallback(() => {
    /*
    1. send a message to the backend to get the build log
    */
    canvasRequirementsService
      .getBuildLogs(canvasId)
      .then((log) => {
        const logString = log?.join("") || "";
        setRequirementsLogs(logString);
      })
      .catch((err) => {
        if (err?.response?.status !== 404) {
          console.log(err);
        }
        setRequirementsLogs("");
      });
  }, [canvasId]);

  return useMemo(
    () => ({
      calculateNewBlockPosition,
      dynamicMove,
      horizontallyAlignSelection,
      horizontallyDistributeSelection,
      verticallyAlignSelection,
      verticallyDistributeSelection,
      sendBlocksToMove,
      resizeBlockAndMove,
      resizeBlock,
      updateBlockOutputPaneSize,
      // local storage for detached window
      updateCanvasLocalStorage,
      resetLocalStorage,
      setCanvasOutputWindowOpen,
      removeCanvasOutputWindowOpen,
      // user
      updateUserPosition,
      updateUserZoom,
      // canvas
      renameCanvas,
      // right sidebars
      closeRightNav,
      openFindSidebar,
      openCanvasExecutorLogsSidebar,
      openFindAndReplaceSidebar,
      toggleCommentsSidebar,
      // general canvas actions
      truncateToBlock,
      setKeyImage,
      tidyCanvas,
      importNotebook,
      fileUploaded,
      // layer actions
      createLayer,
      deleteLayer,
      updateLayerName,
      // selection actions
      deleteBlocks,
      moveSelection,
      exportSelection,
      // block actions
      openFullscreen,
      closeFullscreen,
      clearActiveBlock,
      openCloudSettings,
      clearBlockOutput,
      openPublishReportModal,
      createBlock,
      copyBlocks,
      deleteBlock,
      updateBlockName,
      updateBlockPosition,
      updateBlockContent,
      updateGlobalImports,
      updateBlockDescription,
      lintBlock,
      lintAllBlocks,
      createPipeline,
      // edge actions
      createEdge,
      deleteEdge,
      // requirements actions
      getBuildLog,
      // navigation actions
      zoomToBlock,
      zoomToNode,
    }),
    [
      calculateNewBlockPosition,
      dynamicMove,
      horizontallyAlignSelection,
      horizontallyDistributeSelection,
      verticallyAlignSelection,
      verticallyDistributeSelection,
      sendBlocksToMove,
      resizeBlockAndMove,
      resizeBlock,
      updateBlockOutputPaneSize,
      updateCanvasLocalStorage,
      resetLocalStorage,
      setCanvasOutputWindowOpen,
      removeCanvasOutputWindowOpen,
      updateUserPosition,
      updateUserZoom,
      renameCanvas,
      closeRightNav,
      openFindSidebar,
      openCanvasExecutorLogsSidebar,
      openFindAndReplaceSidebar,
      toggleCommentsSidebar,
      truncateToBlock,
      setKeyImage,
      tidyCanvas,
      importNotebook,
      fileUploaded,
      createLayer,
      deleteLayer,
      updateLayerName,
      deleteBlocks,
      moveSelection,
      exportSelection,
      openFullscreen,
      closeFullscreen,
      clearActiveBlock,
      openCloudSettings,
      createBlock,
      copyBlocks,
      deleteBlock,
      updateBlockName,
      updateBlockPosition,
      updateBlockContent,
      updateGlobalImports,
      updateBlockDescription,
      lintBlock,
      lintAllBlocks,
      createPipeline,
      createEdge,
      deleteEdge,
      getBuildLog,
      zoomToBlock,
      zoomToNode,
    ]
  );
}
