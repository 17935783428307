import styles from "./PlaceholderWidget.module.scss";
import * as monaco from "monaco-editor/esm/vs/editor/editor.api";

export class PlaceholderContentWidget implements monaco.editor.IContentWidget {
  private static readonly ID = "editor.widget.placeholderHint";

  private domNode: HTMLElement | undefined;

  constructor(
    private readonly placeholder: string,
    private readonly editor: monaco.editor.ICodeEditor
  ) {
    // register a listener for editor code changes
    editor.onDidChangeModelContent(() => {
      this.onDidChangeModelContent();
    });
    // ensure that on initial load the placeholder is shown
    this.onDidChangeModelContent();
  }

  private onDidChangeModelContent() {
    if (this.editor.getValue() === "") {
      this.editor.addContentWidget(this);
    } else {
      this.editor.removeContentWidget(this);
    }
  }

  getId() {
    return PlaceholderContentWidget.ID;
  }

  getDomNode() {
    if (!this.domNode) {
      this.domNode = document.createElement("div");
      this.domNode.classList.add(styles.placeholder);
      this.domNode.textContent = this.placeholder;
    }

    return this.domNode;
  }

  getPosition() {
    return {
      position: { lineNumber: 1, column: 1 },
      preference: [monaco.editor.ContentWidgetPositionPreference.EXACT],
    };
  }

  updateFontSize(fontSize: number) {
    if (this.domNode) {
      this.domNode.style.fontSize = `${fontSize}px`;
    }
  }

  dispose() {
    this.editor.removeContentWidget(this);
  }
}
