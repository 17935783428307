export const LABEL = {
  archiveConfirmButton: "Archive",
  deleteConfirmButton: "Delete",
};

export const MESSAGE = {
  assetArchiveConfirm: "Are you sure you want to archive this asset?",
  assetVersionArchiveConfirm: "Are you sure you want to archive this version?",
  constantVersionMakeSecretConfirm:
    "Are you sure you want to make this version of the constant secret? This action won't affect other versions of this constant.",
  deleteAssetVersion: "Delete Version",
  deleteLastVersionAndAsset: " Delete Last Version and Asset",
  deleteAsset: "Delete Asset and All Versions",
  assetDeleteConfirm: "Are you sure you want to delete this asset?",
  getDeleteLastVersionAndAssetDescription: (
    confirmButtonLabel: string,
    cancelButtonLabel: string
  ) => {
    return `By deleting the final version you will also delete this asset. Click ${confirmButtonLabel} to proceed or ${cancelButtonLabel} to keep this asset.`;
  },
  getDeleteAssetVersionDescription: (versionNumber: number) => {
    return `Are you sure you want to delete version ${versionNumber}?`;
  },
};

export const SECRET_VALUE_PLACEHOLDER = "********";

export enum ASSET_PERMISSION {
  read = "read",
  write = "write",
}
