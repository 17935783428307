import styles from "./NavigationTabs.module.scss";
import { Fragment, type ReactNode, type DOMAttributes } from "react";
import { NavLink, type NavLinkProps } from "react-router-dom";
import cn from "classnames";

type ItemBase = {
  key: string;
  label: ReactNode;
  active?: boolean;
  className?: string;
};
type LinkItem = ItemBase & {
  to: NavLinkProps["to"];
  end?: NavLinkProps["end"];
  onClick?: NavLinkProps["onClick"];
};
type ButtonItem = ItemBase & {
  onClick: DOMAttributes<HTMLButtonElement>["onClick"];
};

export type NavigationTabsItem = LinkItem | ButtonItem;

type NavigationTabsProps = {
  orientation: "horizontal" | "vertical";
  className?: string;
  itemClassName?: string;
  activeItemClassName?: string;
  items: NavigationTabsItem[];
};

export function NavigationTabs({
  orientation = "horizontal",
  className = "",
  itemClassName = "",
  activeItemClassName = "",
  items,
}: NavigationTabsProps) {
  return (
    <nav
      className={cn(styles.container, className, {
        [styles.horizontal]: orientation === "horizontal",
        [styles.vertical]: orientation === "vertical",
      })}
    >
      {items.map((item) => {
        return (
          <Fragment key={item.key}>
            {"to" in item ? (
              <NavLink
                to={item.to}
                end={item.end}
                className={({ isActive }) => {
                  return cn(styles.item, itemClassName, item.className, {
                    [styles.active]: item.active || isActive,
                    [activeItemClassName]: item.active || isActive,
                  });
                }}
                onClick={item.onClick}
              >
                {item.label}
              </NavLink>
            ) : (
              <button
                className={cn(styles.item, itemClassName, item.className, {
                  [styles.active]: item.active,
                  [activeItemClassName]: item.active,
                })}
                onClick={item.onClick}
              >
                {item.label}
              </button>
            )}
          </Fragment>
        );
      })}
    </nav>
  );
}
