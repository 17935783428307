import styles from "./OrganizationGroups.module.scss";
import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { getGroups } from "api/http/group-service";
import { getOrganization } from "api/http/organization-service";
import { useModal } from "hooks/useModal";
import { Button } from "components/common/Button/Button";
import { CreateGroupModal } from "components/modals/CreateGroupModal/CreateGroupModal";
import { TitleSection } from "components/organization/TitleSection/TitleSection";
import { OrgEmptyState } from "components/organization/OrgEmptyState/OrgEmptyState";
import { GroupCard } from "components/organization/GroupCard/GroupCard";
import { GroupCardSkeleton } from "components/organization/GroupCardSkeleton/GroupCardSkeleton";
import { debouncePromise } from "utils/helpers";
import type { Organization } from "models/organization";
import type { Group } from "../OrganizationHome/types";

export function OrganizationGroups() {
  const { openModal } = useModal();

  const [isLoading, setIsLoading] = useState(true);
  const [organization, setOrganization] = useState<Organization>();
  const [groups, setGroups] = useState<Group[]>([]);
  const { orgID = "" } = useParams();

  const isAdmin = organization?.role === "Organization Admin";

  // fetch organization and groups
  useEffect(() => {
    setIsLoading(true);
    debouncePromise({ promise: getOrganization(orgID) }).then((orgResult) => {
      setOrganization(orgResult);
      getGroups(orgID)
        .then((returnedValues) => {
          setGroups(returnedValues);
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  }, [orgID]);

  const handleCreateGroup = useCallback(() => {
    openModal({
      title: "Create Group",
      content: ({ onModalClose }) => (
        <CreateGroupModal
          organizationId={orgID}
          groups={groups}
          onModalClose={onModalClose}
        />
      ),
    });
  }, [groups, openModal]);

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <TitleSection
          title="Groups"
          rightControls={
            isAdmin ? (
              <Button
                size="large"
                variant="primary"
                onClick={handleCreateGroup}
              >
                Create Group
              </Button>
            ) : null
          }
        />

        {/* Loading */}
        {isLoading ? (
          <>
            <GroupCardSkeleton />
            <GroupCardSkeleton />
            <GroupCardSkeleton />
          </>
        ) : null}

        {/* Empty State */}
        {!isLoading && groups.length === 0 ? (
          <OrgEmptyState
            title="No groups..."
            description={
              isAdmin
                ? "Create a new group to organize your team."
                : "The organization doesn't have any groups yet."
            }
          >
            {isAdmin ? (
              <Button
                size="large"
                variant="secondary"
                onClick={handleCreateGroup}
              >
                Create Group
              </Button>
            ) : null}
          </OrgEmptyState>
        ) : null}

        {/* Group Cards */}
        {groups.map((group) => {
          return <GroupCard key={group.id} orgID={orgID} group={group} />;
        })}
      </div>
    </div>
  );
}
