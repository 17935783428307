import React from "react";
import { BiCodeAlt } from "react-icons/bi";
import DragIndicator from "assets/icons/drag.svg?react";

import { useModal } from "hooks/useModal";
import IconButton from "components/common/IconButton/IconButton";
import LanguageVersionSelect from "components/common/LanguageSelector/components/LanguageVersionSelect";
import { MenuOptions } from "components/menus/AssetsMenu/types";
import AssetListItemPreview from "components/menus/AssetsMenu/components/AssetListItemPreview/AssetListItemPreview";
import styles from "./AssetEntry.module.scss";

interface AssetEntryProps {
  item: any;
  [key: string]: any;
}

const mockAsset: any = {
  id: "333",
  preview: "print(123)",
  name: "MyAIFunction-5",
  version: 13,
  type: MenuOptions.Function,
  availableVersions: ["1", "2"],
  size: 27,
};

const AssetEntry = React.forwardRef<HTMLDivElement, AssetEntryProps>(
  ({ item, ...other }, ref) => {
    const { name, size, version } = item;
    const { openModal } = useModal();

    const onPreview = () => {
      openModal({
        title: "Asset Preview",
        content: ({ onModalClose }) => (
          <AssetListItemPreview asset={mockAsset} onModalClose={onModalClose} />
        ),
      });
    };

    return (
      <div ref={ref} className={styles["entry"]} {...other}>
        <div className={styles["entry__info"]}>
          <DragIndicator className={styles["entry__drag"]} />
          <IconButton
            tooltip="Code Preview"
            hotKey="⌘ A"
            className={styles["entry__preview"]}
            onClick={onPreview}
          >
            <BiCodeAlt size={16} />
          </IconButton>
          <span className={styles["entry__name"]}>{name}</span>
        </div>

        <span>{size}</span>

        {typeof version === "string" ? (
          <span>{version}</span>
        ) : (
          <LanguageVersionSelect
            className={styles["entry__versions"]}
            versions={[]}
          />
        )}
      </div>
    );
  }
);

AssetEntry.displayName = "AssetEntry";
export default AssetEntry;
