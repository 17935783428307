import moment from "moment";
import { SORTING_DIRECTION, SORTING_TYPE } from "config/appConfig";

export const defineSortingFuction = (type: SORTING_TYPE, label) => {
  if (type === SORTING_TYPE.TEXT) {
    return (a, b) => {
      // todo: Back-End bug - when there is no name for canvas - it returns null
      const first = a[label]?.toLowerCase() || "untitled";
      const second = b[label]?.toLowerCase() || "untitled";

      if (first > second) {
        return 1;
      }
      if (first < second) {
        return -1;
      }
      return 0;
    };
  }

  if (type === SORTING_TYPE.DATE) {
    return (a, b) => {
      const aDate = moment(a[label]);
      const bDate = moment(b[label]);

      if (aDate.isAfter(bDate)) {
        return 1;
      }
      if (aDate.isBefore(bDate)) {
        return -1;
      }
      return 0;
    };
  }
};

export const getSortingDirectionLabel = ({
  sortingType,
  sortingDirection,
}: {
  sortingType: SORTING_TYPE;
  sortingDirection: SORTING_DIRECTION;
}) => {
  if (sortingType === SORTING_TYPE.DATE) {
    return sortingDirection === SORTING_DIRECTION.ASCENDING
      ? "Oldest First"
      : "Newest First";
  }

  return sortingDirection === SORTING_DIRECTION.ASCENDING ? "A-Z" : "Z-A";
};
