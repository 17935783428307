import { Navigate, type RouteObject } from "react-router-dom";
import Home from "pages/Canvases/Home/Home";

export const PERSONAL_CANVASES_ROUTES: RouteObject[] = [
  {
    index: true,
    element: <Navigate replace to="my-canvases" />,
  },
  { path: "my-canvases", element: <Home /> },
  { path: "community-canvases", element: <Home /> },
  { path: "starred", element: <Home /> },
  { path: "shared-with-me", element: <Home /> },
  { path: "trash", element: <Home /> },
];
