import { type FC } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Avatar from "components/common/Avatar/Avatar";
import styles from "./UserInfoSection.module.scss";
import { useUserSettingsState } from "store";

export const UserInfoSection: FC = () => {
  const { user } = useAuth0();
  const { email, name, picture } = user ?? {};
  const avatar = useUserSettingsState((slice) => slice.profile.avatar);
  return (
    <div className={styles.wrapper}>
      <Avatar mode="letter" userPicture={avatar || picture} size="large" />

      <div className={styles.userData}>
        <div className={styles.name}>{name}</div>
        <div className={styles.email}>{email}</div>
      </div>
    </div>
  );
};
