import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAssetsState, useToastsState, useUserState } from "store";
import {
  deleteQueryById,
  deleteQueryVersion,
  putQueryArchive,
  putQueryVersionArchive,
  putQueryVersionStatus,
} from "api/http/assets/queries-service";
import { getAssetsBaseUrl } from "pages/Assets/utils";

export default function useCurrentQueriesData() {
  const userID = useUserState((slice) => slice.userID);
  const addToast = useToastsState((slice) => slice.addToast);
  const navigate = useNavigate();
  const { orgID, workspaceID } = useParams();

  const baseURL = useMemo(() => {
    return getAssetsBaseUrl({
      organizationId: orgID,
      workspaceId: workspaceID,
    });
  }, [orgID, workspaceID]);

  const {
    queriesData,
    isDisplayArchivedQueries,
    selectedQuery,
    queriesDataLoadState,
  } = useAssetsState((slice) => slice.queries);

  const {
    setSelectedQueryId,
    setSelectedQueryVersionNumber,
    deleteQueryById: deleteQueryByIdFromStore,
    clearSelectedQuery,
    setQueriesData,
    setIsDisplayArchivedQueries,
    updateQueryVersion,
    deleteQueryVersion: deleteQueryVersionFromStore,
    clearStore: clearQueriesStore,
  } = useAssetsState((slice) => slice.queriesActions);

  const currentQueriesData = {
    assetsData: queriesData,
    isDisplayArchivedItems: isDisplayArchivedQueries,
    activeItemData: selectedQuery.id ? queriesData[selectedQuery.id] : null,
    activeVersionData:
      selectedQuery.id && selectedQuery.versionNumber
        ? queriesData[selectedQuery.id]?.versions[selectedQuery.versionNumber]
        : null,
    activeItemId: selectedQuery.id,
    activeItemVersionId:
      selectedQuery.id && selectedQuery.versionNumber
        ? queriesData[selectedQuery.id]?.versions[selectedQuery.versionNumber]
            ?.id
        : null,
    assetDataLoadState: queriesDataLoadState,
  };

  const currentQueriesActions = {
    setActiveItemId: (id: string) => {
      setSelectedQueryId(id);
    },
    setActiveVersionNumber: (versionNumber) => {
      setSelectedQueryVersionNumber(versionNumber);
    },
    setIsDisplayArchivedItems: (isDisplay) => {
      setIsDisplayArchivedQueries(isDisplay);
    },
    setAssetsData: () => {},
    deleteAssetVersion: (id, versionNumber) => {
      const assetId = selectedQuery.id;

      if (userID && assetId) {
        deleteQueryVersion(id)
          .then(() => {
            const assetData = queriesData[assetId];
            const versionsList = Object.values(assetData.versions);

            if (versionsList.length <= 1) {
              clearSelectedQuery();
              navigate(`${baseURL}/queries`, { replace: true });
              const newQueriesData = { ...queriesData };
              delete newQueriesData[assetId];
              setQueriesData(newQueriesData);
              addToast({
                variant: "success",
                message: "Version and Asset have been successfully deleted.",
              });

              return;
            }

            deleteQueryVersionFromStore(versionNumber);

            if (selectedQuery.versionNumber === versionNumber) {
              const currentVersionIndex = versionsList.findIndex(
                (version) => version.version === versionNumber
              );
              const nextSelectedVersionIndex =
                currentVersionIndex === versionsList.length - 1
                  ? currentVersionIndex - 1
                  : currentVersionIndex + 1;
              const nextSelectedVersionData =
                versionsList[nextSelectedVersionIndex];
              if (nextSelectedVersionData) {
                const { id, version } = nextSelectedVersionData;
                setSelectedQueryVersionNumber(version);
                navigate(`${baseURL}/queries/${id}`, { replace: true });
              } else {
                clearSelectedQuery();
                navigate(`${baseURL}/queries`, { replace: true });
              }
            }
            addToast({
              variant: "success",
              message: `Version ${
                versionNumber as number
              } has been successfully deleted.`,
            });
          })
          .catch((error) => {
            console.error(error);
            addToast({
              variant: "error",
              message: "Failed to delete version",
            });
          });
      }
    },
    setAssetVersionStatus: (id, status) => {
      void putQueryVersionStatus(id, status).then((resp) => {
        updateQueryVersion(resp);
      });
    },
    setAssetVersionIsArchived: (versionId, isArchived) => {
      putQueryVersionArchive(versionId, isArchived)
        .then((resp) => {
          const {
            asset: { id: assetId, archive: assetIsArchived },
            archive: versionIsArchived,
            version,
          } = resp;
          const currentAssetData = queriesData[assetId];
          const {
            query: { archive },
          } = currentAssetData;

          if (assetIsArchived !== archive) {
            const updatedAssetData = {
              query: {
                ...currentAssetData.query,
                archive: assetIsArchived,
              },
              versions: {
                ...currentAssetData.versions,
                [version]: { ...resp },
              },
            };
            const newQueriesData = {
              ...queriesData,
              [assetId]: { ...updatedAssetData },
            };
            setQueriesData(newQueriesData);
          } else {
            updateQueryVersion(resp);
          }

          if (!isDisplayArchivedQueries) {
            const selectedQueryVersionId =
              selectedQuery.id && selectedQuery.versionNumber
                ? queriesData[selectedQuery.id]?.versions[
                    selectedQuery.versionNumber
                  ]?.id
                : null;

            if (
              (versionId === selectedQueryVersionId && versionIsArchived) ||
              (selectedQuery.id === assetId && assetIsArchived)
            ) {
              setIsDisplayArchivedQueries(true);
            }
          }

          addToast({
            variant: "success",
            message: `Version successfully ${
              isArchived ? "archived" : "unarchived"
            }`,
          });
        })
        .catch(() => {
          clearSelectedQuery();
          navigate(`${baseURL}/queries`);
          addToast({
            variant: "error",
            message:
              "Something went wrong! Please refresh the page and try again",
          });
        });
    },
    setAssetIsArchived: (assetId, isAssetArchived) => {
      putQueryArchive(assetId, isAssetArchived).then((resp) => {
        const assetData = resp[assetId];
        setQueriesData({
          ...queriesData,
          [assetId]: { ...assetData },
        });

        if (
          selectedQuery.id === assetId &&
          isAssetArchived &&
          !isDisplayArchivedQueries
        ) {
          clearSelectedQuery();
          navigate(`${baseURL}/queries`);
        }

        addToast({
          message: `Asset was successfully ${
            isAssetArchived ? "archived" : "unarchived"
          }.`,
          variant: "success",
        });
      });
    },
    setActiveItemByVersionId: (id?: string) => {
      if (!id || (selectedQuery.id && selectedQuery.versionNumber)) {
        return;
      }
      let selectedAssetVersionNumber: number | null = null;
      const selectedAsset = Object.values(queriesData).find((assetData) =>
        Object.values(assetData.versions).find((version) => {
          if (version.id === id) {
            selectedAssetVersionNumber = version.version;
            return true;
          }
          return false;
        })
      );

      if (!selectedAsset) {
        navigate(`${baseURL}/queries`);
      }

      const isAssetOrVersionArchived =
        selectedAsset?.query.archive ||
        (selectedAssetVersionNumber &&
          selectedAsset?.versions[selectedAssetVersionNumber]?.archive);

      if (isAssetOrVersionArchived) {
        setIsDisplayArchivedQueries(true);
      }

      if (selectedAsset?.query.id && selectedAssetVersionNumber) {
        setSelectedQueryId(selectedAsset.query.id);
        setSelectedQueryVersionNumber(selectedAssetVersionNumber);
      }
    },
    clearActiveItemData: () => {
      clearSelectedQuery();
    },
    deleteAssetItem: (id) => {
      const currentQueryId = id ?? selectedQuery.id;
      if (currentQueryId) {
        deleteQueryById(currentQueryId)
          .then(() => {
            deleteQueryByIdFromStore(currentQueryId);
            clearSelectedQuery();
            navigate(`${baseURL}/queries`, { replace: true });
            addToast({
              variant: "success",
              message: "Query has been successfully deleted",
            });
          })
          .catch((error) => {
            console.error(error);
            addToast({
              variant: "error",
              message:
                "Failed to delete query. Please refresh the page and try again",
            });
          });
      }
    },
    clearStore: () => {
      clearQueriesStore();
    },
  };

  return { currentQueriesData, currentQueriesActions };
}
