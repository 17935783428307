import styles from "./DeleteAccountModal.module.scss";
import { type FC, useEffect, useMemo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "components/common/Button/Button";
import { Modal } from "components/common/Modal/Modal";
import TextField from "components/common/TextField/TextField";
import Typography from "components/Typography/Typography";
import { useUserSettings } from "hooks/useUserSettings";
import { useUserSettingsState } from "store";

interface DeleteAccountModalProps {
  onModalClose: () => void;
}

const DeleteAccountModal: FC<DeleteAccountModalProps> = ({
  onModalClose,
}: DeleteAccountModalProps) => {
  const [inputValue, setInputValue] = useState("");
  const { deleteAccount, cleanAccountDeleteError } = useUserSettings();
  const profileData = useUserSettingsState((slice) => slice.profile);
  const profileErrors = useUserSettingsState((slice) => slice.errors.profile);

  const { logout } = useAuth0();

  useEffect(() => {
    if (profileData.isAccountDeleted) {
      logout({ logoutParams: { returnTo: window.location.origin } });
    }
  }, [profileData?.isAccountDeleted]);
  const handleOnInputChange = (e) => {
    if (profileErrors.isAccountDeleted) {
      cleanAccountDeleteError();
    }
    setInputValue(e.target.value);
  };
  const isInputError = inputValue !== "DELETE" && inputValue.length > 0;
  const handleAccountDeletion = () => {
    if (isInputError || inputValue.length === 0) {
      return;
    }
    deleteAccount();
  };

  const deleteError = useMemo(() => {
    if (profileErrors?.isAccountDeleted) {
      /* TODO: J.H. I'm not sure what the purpose of the error slice is but we can likely
      refactor it a future PR after more assessment.
      */
      return;
    } else if (isInputError) {
      return "Incorrect confirmation text. Please type 'DELETE' to confirm account deletion.";
    }
    return undefined;
  }, [profileErrors?.isAccountDeleted, isInputError]);

  return (
    <Modal title="Delete Account" onClose={onModalClose}>
      <Typography variant="body2" className={styles.subHeading}>
        We will immediately delete all of your canvases, along with all of your
        assets and access to organizations and other canvases.
      </Typography>
      <TextField
        label="To verify, type DELETE below:"
        containerClassName={styles.deleteInput}
        error={deleteError}
        onChange={handleOnInputChange}
      />
      <div className={styles.controls}>
        <Button variant="secondary" onClick={onModalClose}>
          Cancel
        </Button>
        <Button
          variant="crucial"
          disabled={isInputError || inputValue.length === 0}
          onClick={handleAccountDeletion}
        >
          Delete Account
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteAccountModal;
