import { useCallback, useMemo } from "react";
import { useAssetsState, useToastsState, useUserState } from "store";
import {
  type AssetItemOperationType,
  AssetPageTabUrl,
  type CreatedAssetTypeToDataType,
} from "pages/Assets/types/abstractTypes";
import {
  createConstantVersion,
  editConstantVersion,
  postConstant,
  postWorkspaceConstant,
  putConstant,
} from "api/http/assets/constants-service";
import { useNavigate, useParams } from "react-router-dom";
import { getAssetsBaseUrl } from "pages/Assets/utils";

export default function useCreatedConstantsData() {
  const userID = useUserState((slice) => slice.userID);
  const addToast = useToastsState((slice) => slice.addToast);
  const navigate = useNavigate();

  const { constantsData, selectedConstant, createdConstantData } =
    useAssetsState((slice) => slice.constants);
  const { orgID, workspaceID } = useParams();

  const baseURL = useMemo(() => {
    return getAssetsBaseUrl({
      organizationId: orgID,
      workspaceId: workspaceID,
    });
  }, [orgID, workspaceID]);

  const createConstant = useCallback(
    (requestData) => {
      if (workspaceID) {
        return postWorkspaceConstant(workspaceID, requestData);
      } else {
        return postConstant(requestData);
      }
    },
    [workspaceID]
  );

  const {
    setSelectedConstantId,
    setSelectedConstantVersionNumber,
    setConstantsData,
    setConstantOperationType,
    setCreatedConstantData,
    setCreatedConstantError,
    setCreatedConstantLoading,
    updateConstantVersion,
    clearCreatedConstantData,
  } = useAssetsState((slice) => slice.constantsActions);

  const createdConstantsData = {
    createdAssetItemData: createdConstantData.constantData,
    assetOperationType: createdConstantData.assetOperationType,
    createdAssetError: createdConstantData.error,
    createdAssetLoading: createdConstantData.loading,
  };
  const createdConstantsActions = {
    setCreatedAssetItemData: (data) => {
      setCreatedConstantData(data);
    },
    setAssetItemOperationType: (operationType: AssetItemOperationType) => {
      setConstantOperationType(operationType);
    },
    createAssetItem: (
      data: CreatedAssetTypeToDataType["constant"]
    ): Promise<any> | undefined => {
      const { name, value, description, secret } = data;
      const requestData = {
        asset: {
          name,
        },
        version: {
          secret,
          value,
          description,
        },
      };
      if (userID) {
        setCreatedConstantLoading(true);
        return createConstant(requestData)
          .then((createdCont) => {
            const formattedCreatedConstant = {
              constant: {
                ...createdCont.asset,
              },
              versions: {
                [createdCont.version]: {
                  ...createdCont,
                },
              },
            };
            setSelectedConstantId(createdCont.asset_id);
            setSelectedConstantVersionNumber(createdCont.version);
            setConstantsData({
              ...constantsData,
              [createdCont.asset_id]: formattedCreatedConstant,
            });
            setCreatedConstantData({
              name: "",
              value: "",
              description: "",
              secret: false,
            });
            navigate(
              `${baseURL}/${AssetPageTabUrl.constant}/${
                createdCont.id as string
              }`
            );
            addToast({
              message: "Success! New constant has been successfully created",
              variant: "success",
            });
            return {
              success: true,
            };
          })
          .catch((err) => {
            // TODO: show validation error in form when BE provides field related details in response
            console.error(err);
            addToast({
              message: err?.message ?? "Failed to create new constant",
              variant: "error",
            });
          })
          .finally(() => {
            setCreatedConstantLoading(false);
          });
      }
    },
    editAssetItem: (
      data: Partial<CreatedAssetTypeToDataType["constant"]>
    ): Promise<any> | undefined => {
      if (userID && selectedConstant.versionNumber) {
        const selectedConstantVersionId =
          selectedConstant.id && selectedConstant.versionNumber
            ? constantsData[selectedConstant.id].versions[
                selectedConstant.versionNumber
              ].id
            : "";
        const requestData = {
          id: selectedConstantVersionId,
          ...data,
        };
        setCreatedConstantLoading(true);
        return editConstantVersion(selectedConstantVersionId, requestData)
          .then((editedCont) => {
            if (selectedConstant.id) {
              updateConstantVersion(editedCont);
              setCreatedConstantData({
                name: "",
                value: "",
                description: "",
                secret: false,
              });

              setSelectedConstantVersionNumber(editedCont.version);
              addToast({
                message: "Success! Your constant has been successfully edited",
                variant: "success",
              });
              return {
                success: true,
              };
            }
          })
          .catch((err) => {
            // TODO: show validation error in form when BE provides field related details in response
            console.error(err);
            addToast({
              message: err?.message ?? "Failed to edit the constant",
              variant: "error",
            });
          })
          .finally(() => {
            setCreatedConstantLoading(false);
          });
      }
    },
    updateAssetItemName: () => {
      const { name, id } = createdConstantData.constantData;

      if (!id || constantsData[id]?.constant?.name === name) {
        clearCreatedConstantData();

        return;
      }

      setCreatedConstantLoading(true);
      void putConstant(id, { name })
        .then((responseData) => {
          const editedConstantData = constantsData[id];
          setConstantsData({
            ...constantsData,
            [id]: {
              ...editedConstantData,
              constant: {
                ...editedConstantData.constant,
                name: responseData.name,
              },
            },
          });
          addToast({
            message: "Success! Constant name has been successfully updated",
            variant: "success",
          });
        })
        .catch((error) => {
          addToast({
            message: error?.message ?? "Failed to update constant name",
            variant: "error",
          });
        })
        .finally(() => {
          clearCreatedConstantData();
          setCreatedConstantLoading(false);
        });
    },
    addNewVersion: (
      data: CreatedAssetTypeToDataType["constant"]
    ): Promise<any> | undefined => {
      const { value, description, secret } = data;

      if (selectedConstant.id) {
        const requestData = {
          secret,
          value,
          description,
          asset_id: selectedConstant.id,
        };
        setCreatedConstantLoading(true);
        return createConstantVersion(requestData)
          .then((createdConst) => {
            if (selectedConstant.id) {
              updateConstantVersion(createdConst);
              setSelectedConstantVersionNumber(createdConst.version);
              navigate(
                `${baseURL}/${AssetPageTabUrl.constant}/${
                  createdConst.id as string
                }`
              );
              addToast({
                message: "Success! Your constant has been created",
                variant: "success",
              });
              return {
                success: true,
              };
            }
          })
          .catch((err) => {
            // TODO: show validation error in form when BE provides field related details in response
            console.error(err);
            addToast({
              message: err?.message ?? "Failed to create the constant",
              variant: "error",
            });
          })
          .finally(() => {
            setCreatedConstantLoading(false);
          });
      }
    },
    setCreatedAssetError: (error) => {
      setCreatedConstantError(error);
    },
    clearCreatedAsset: () => {
      clearCreatedConstantData();
    },
  };

  return { createdConstantsData, createdConstantsActions };
}
