import { type ReactNode } from "react";
import { type IconType } from "react-icons";
import {
  BiArchiveIn,
  BiDuplicate,
  BiInfoCircle,
  BiTrash,
} from "react-icons/bi";
import Typography from "components/Typography/Typography";
import type { Connection } from "pages/Assets/types/connectionTypes";
import styles from "./ConnectionsNavItemDropdown.module.scss";

const createCustomLabel = (text: string, icon: IconType): ReactNode => {
  const Icon = icon;

  return (
    <div className={styles.custom_label}>
      <Icon size={20} className={styles.label_icon} />
      <Typography variant="caption1" className={styles.text}>
        {text}
      </Typography>
    </div>
  );
};

type OwnerInfoProps = {
  owner: Connection["owner"];
};

export const OwnerInfo = ({ owner }: OwnerInfoProps) => {
  return (
    <div className={styles.owner}>
      <Typography variant="caption2" className={styles.title}>
        Asset Owner:
      </Typography>
      <Typography variant="caption1" className={styles.name}>
        {owner.username || owner.email}
      </Typography>
    </div>
  );
};

export const duplicateOptionLabel = createCustomLabel("Duplicate", BiDuplicate);
export const manageUsageOptionLabel = createCustomLabel(
  "Manage Usage",
  BiInfoCircle
);
export const archiveOptionLabel = createCustomLabel("Archive", BiArchiveIn);
export const deleteOptionLabel = createCustomLabel("Delete", BiTrash);
