// This page redirects to the Auth0 login page if the user is not logged in.
// otherwise it redirects to the home page on /
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

export const Login = () => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  // get path so it can be sent to the /auth/login page as a search param
  // get the redirect path from the search params
  const redirectPath =
    new URLSearchParams(location.search).get("redirectTo") || "/";

  useEffect(() => {
    if (!isLoading) {
      isAuthenticated
        ? navigate(redirectPath)
        : loginWithRedirect({
            appState: {
              returnTo: redirectPath,
            },
          });
    }
  }, [isLoading, isAuthenticated]);

  return null;
};
