import styles from "./RoleSettings.module.scss";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams, useNavigate } from "react-router-dom";
import { BiChevronLeft } from "react-icons/bi";
import { useUserState, useToastsState } from "store";
import { useModal } from "hooks/useModal";
import { DeleteRoleModal } from "./DeleteRoleModal";
import { Button } from "components/common/Button/Button";
import { LinkButton } from "components/common/Button/LinkButton";
import Tooltip from "components/common/Tooltip/Tooltip";
import TextField from "components/common/TextField/TextField";
import Typography from "components/Typography/Typography";
import FormDropdown from "components/common/FormDropdown/FormDropdown";
import { TitleSection } from "components/organization/TitleSection/TitleSection";
import {
  getRole,
  patchRole,
  deleteRole,
  getFeatures,
  updateRoleFeaturePermission,
  getRoles,
} from "api/http/role-service";
import { type Role, type Feature } from "../OrganizationHome/types";

const validCustomPermissionFeatures = [
  "Canvases",
  "Functions",
  "Queries",
  "Constants & Secrets",
  "Data Connections",
];

export function RoleSettings() {
  const userID = useUserState((slice) => slice.userID);
  const addToast = useToastsState((slice) => slice.addToast);
  const { roleID = "", orgID = "" } = useParams();
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();
  const [role, setRole] = useState<Role>();
  const [features, setFeatures] = useState<Feature[]>([]);
  const roleFeatureOptions = [
    { label: "No Access", value: "none" },
    { label: "View", value: "read" },
    { label: "Create/Edit", value: "write" },
  ];
  const [roleUpated, setRoleUpdated] = useState(true);
  const [roles, setRoles] = useState<Role[]>([]);

  // Fetch users role when the component loads.
  useEffect(() => {
    async function initializeRole() {
      const rolesResult = await getRole(orgID, roleID);
      if (rolesResult) {
        // store role
        setRole(rolesResult);
        formik.setValues({ ...rolesResult });
        formik.resetForm({
          values: {
            ...rolesResult,
          },
        });
      }
      const allFeatures = await getFeatures();
      // filter out features that are not currently valid for custom permissions
      const filteredFeatures = allFeatures.filter((feature) => {
        return validCustomPermissionFeatures.includes(feature.name);
      });
      setFeatures(filteredFeatures);
      const allRoles = await getRoles(orgID);
      setRoles(allRoles);
      setRoleUpdated(false);
    }
    if (userID && roleID && roleUpated) {
      initializeRole();
    }
  }, [userID, roleID, roleUpated]);

  // Delete the role.
  const handleDeleteRole = () => {
    if (userID) {
      deleteRole(orgID, roleID)
        .then(() => {
          closeModal();
          formik.resetForm({
            values: {
              name: "",
            },
          });
          addToast({
            message: "Role successfully deleted",
            variant: "success",
            duration: 1000,
          });
          navigate(`/org/${orgID}`);
        })
        .catch((error) => {
          if (error.cause) {
            formik.setErrors(error.cause);
          }
          addToast({
            message: error.message,
            variant: "error",
            duration: 1000,
          });
        });
    }
  };

  // Save the role. This function calls the relevant API depending on
  // whether the role is new or it is updating and existing role.
  const onSubmit = (values) => {
    if (userID) {
      patchRole(orgID, roleID, {
        name: values.name,
        organization_id: orgID,
        role_id: values.role_id,
      })
        .then((returnedValues) => {
          addToast({
            message: "Role successfully updated",
            variant: "success",
            duration: 1000,
          });
          formik.resetForm({
            values: {
              ...returnedValues,
            },
          });
        })
        .catch((error) => {
          if (error.cause) {
            formik.setErrors(error.cause);
          }
          addToast({
            message: error.message,
            variant: "error",
            duration: 1000,
          });
        });
    }
  };

  // Get a permission name from the value
  const getPermissionLabelFromValue = (permissionValue?: string) => {
    const selectedOption = roleFeatureOptions.find((option) => {
      return option.value === permissionValue;
    });
    return selectedOption?.label ? selectedOption.label : "";
  };

  // Get a permission value from the featureId and roleId
  const getPermissionFromSavedRole = (featureId) => {
    const roleFeature = role?.role_feature_permissions.find(
      (roleFeaturePermission) => {
        return (
          roleFeaturePermission.role_id === role?.id &&
          roleFeaturePermission.feature_id === featureId
        );
      }
    );
    return getPermissionLabelFromValue(roleFeature?.permission);
  };

  const saveRoleFeaturePermission = (featureId: string, permission: string) => {
    if (userID) {
      updateRoleFeaturePermission(orgID, roleID, featureId, permission)
        .then(() => {
          addToast({
            message: "Role successfully updated",
            variant: "success",
            duration: 1000,
          });
          setRoleUpdated(true); // trigger a re-fetch of the role
        })
        .catch((error) => {
          if (error.cause) {
            formik.setErrors(error.cause);
          }
          addToast({
            message: error.message,
            variant: "error",
            duration: 1000,
          });
        });
    }
  };

  const onDelete = () => {
    openModal({
      title: "Delete Role",
      content: () => (
        <DeleteRoleModal
          onCancelDelete={closeModal}
          onDeleteRole={handleDeleteRole}
        />
      ),
    });
  };

  // Yup validation schema
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .matches(
        /^[a-zA-Z0-9-_ ]+$/,
        "Only alphanumeric characters, spaces, underscores and dashes are allowed"
      )
      .max(16, "Must be 16 characters or less")
      .required("Role name is required")
      .test("unique-name", "Role name must be unique", function (value) {
        if (!value) {
          return true;
        }

        // Convert the input value to lowercase for case-insensitive comparison
        const lowercaseValue = value.toLowerCase();

        // Check if the name already exists in the list of existing roles
        const nameExists = roles?.some(
          (existingRole) =>
            existingRole.name.toLowerCase() === lowercaseValue &&
            existingRole.id !== roleID
        );

        // Return false if the name already exists, true otherwise
        return !nameExists;
      }),
  });

  // Initialize formik.
  const formik = useFormik({
    initialValues: {
      name: role?.name || "",
    },
    validationSchema,
    onSubmit,
  });

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <TitleSection
          title={role?.name || ""}
          leftControls={
            <Tooltip text="Back to Roles" placement="top" withArrow>
              <LinkButton
                to={`/org/${orgID}/roles`}
                variant="outline"
                className={styles.backButton}
              >
                <BiChevronLeft size="24" />
              </LinkButton>
            </Tooltip>
          }
        />

        <form onSubmit={formik.handleSubmit}>
          <div className={styles.form}>
            <TextField
              id="name"
              name="name"
              label="Role Name"
              placeholder={"Name of role..."}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              error={formik.errors.name}
            />
          </div>
          <div className={styles.buttonSection}>
            <Button
              type="submit"
              className={styles.updateRoleButton}
              disabled={!formik.isValid || !formik.dirty}
            >
              Save Role
            </Button>
          </div>
        </form>
        {roleID && (
          <div className={styles.permissionsWrapper}>
            <Typography className={styles.permissionHeading} variant="h2">
              Role Permission Selections
            </Typography>
            {features?.map((feature) => {
              return (
                <div key={feature.id} className={styles.permissionSelection}>
                  <FormDropdown
                    id="role_id"
                    name="role_id"
                    label={feature.name}
                    placeholder="Select Permission"
                    options={roleFeatureOptions}
                    value={getPermissionFromSavedRole(feature.id)}
                    onChange={(value) => {
                      saveRoleFeaturePermission(feature.id, value);
                    }}
                  />
                </div>
              );
            })}
          </div>
        )}
        {roleID && (
          <div className={styles.deleteWrapper}>
            <div className={styles.deleteExplanationText}>
              <Typography variant="h2">Delete Role</Typography>
              <Typography variant="body2" className={styles.subTitle}>
                This action will permanently delete this role and all of
                it&#39;s data. There is no going back. Please be certain.
              </Typography>
            </div>
            <Button
              variant="crucial"
              onClick={() => {
                onDelete();
              }}
            >
              Delete Role
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
