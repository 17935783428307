import { type StateCreator } from "store";
import {
  type PaymentPlan,
  type HistoryData,
  type UserTier,
} from "models/settings/billing-interface";

type State = {
  addons: any[];
  tiers: Partial<PaymentPlan>[];
  user_billing: {
    addons: any[];
    payment_history: HistoryData[];
    tier: Partial<UserTier>;
  };
};

type Actions = {
  setBillingInfo: (payload: any) => void;
};

const initialState: State = {
  tiers: [],
  addons: [],
  user_billing: {
    addons: [],
    payment_history: [],
    tier: {},
  },
};

export type UserBillingSlice = State & Actions;

export const createUserBillingSlice: StateCreator<UserBillingSlice> = (
  set
) => ({
  ...initialState,
  setBillingInfo: (payload: any) => {
    set(
      (store) => {
        store.userBillingSlice = {
          ...store.userBillingSlice,
          ...payload,
        };
      },
      false,
      "userBilling/setBillingInfo"
    );
  },
});
