import {
  type ChangeEvent,
  type FC,
  type ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  autoUpdate,
  flip,
  offset,
  shift,
  useFloating,
  FloatingPortal,
} from "@floating-ui/react";
import cn from "classnames";
import Loader from "components/common/Loader/Loader";
import TextField from "components/common/TextField/TextField";
import Typography from "components/Typography/Typography";
import {
  BiCaretDown,
  BiDotsHorizontal,
  BiError,
  BiShareAlt,
} from "react-icons/bi";
import type { AssetPageTabType } from "../../types/abstractTypes";
import { useAssetsData } from "hooks/useAssetsData";
import { getStringValidationError } from "utils/validation";
import styles from "./EditableAssetNavItem.module.scss";

interface EditableAssetNavItemProps {
  assetType: AssetPageTabType;
}

const EditableAssetNavItem: FC<EditableAssetNavItemProps> = ({
  assetType,
}: EditableAssetNavItemProps) => {
  const { getCreatedAssetItemActions, getCreatedAssetItemData } =
    useAssetsData();
  const {
    setCreatedAssetItemData,
    createAssetItem,
    setCreatedAssetError,
    clearCreatedAsset,
    updateAssetItemName,
  } = getCreatedAssetItemActions(assetType);
  const {
    assetOperationType,
    createdAssetItemData,
    createdAssetError,
    createdAssetLoading,
  } = getCreatedAssetItemData(assetType);

  const [hasFocus, setHasFocus] = useState(false);
  const textFieldRef = useRef<HTMLInputElement>(null);
  const capitalizedAssetType =
    assetType.charAt(0).toUpperCase() + assetType.slice(1);
  const handleAssetNameChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setCreatedAssetError(null);
    setCreatedAssetItemData({ name: e.target.value });
  };

  const handleFocus = (): void => {
    setHasFocus(true);
  };

  const validateAssetName = () => {
    const validationError = getStringValidationError(
      "assetFunctionName",
      createdAssetItemData.name
    );
    if (validationError) {
      setCreatedAssetError(validationError);
    }

    return validationError;
  };

  useEffect(() => {
    if (textFieldRef?.current && !hasFocus) {
      textFieldRef.current.focus();
    }
  }, [hasFocus]);

  useEffect(() => {
    return () => {
      setCreatedAssetError(null);
    };
  }, []);

  const handleSubmit = (): void => {
    if (createdAssetLoading) {
      return;
    }

    if (assetOperationType === "create") {
      createAssetItem();
    } else if (assetOperationType === "editName") {
      updateAssetItemName();
    } else {
      clearCreatedAsset();
    }
  };

  const handleCancel = (): void => {
    clearCreatedAsset();
  };

  const handleBlur = (): void => {
    setCreatedAssetError(null);
    const error = validateAssetName();

    if (!error) {
      handleSubmit();
    } else {
      setHasFocus(false);
    }
  };

  const handleKeyDown = (e: any): void => {
    setCreatedAssetError(null);
    if (e.key === "Enter") {
      const error = validateAssetName();
      if (!error) {
        handleSubmit();
      }
    }
    if (e.key === "Escape") {
      handleCancel();
    }
  };

  const { x, y, refs, strategy } = useFloating({
    open: Boolean(createdAssetError),
    whileElementsMounted: autoUpdate,
    placement: "right-start",
    middleware: [offset(8), flip(), shift()],
  });

  const renderErrorNotification = (errorText: string): ReactNode => (
    <div
      ref={refs.setFloating}
      style={{
        position: strategy,
        top: y ?? 0,
        left: x ?? 0,
      }}
      className={styles.errorContainer}
    >
      <BiError className={styles.errorIcon} size={20} />
      <Typography variant="caption1" className={styles.errorText}>
        {errorText}
      </Typography>
    </div>
  );

  return (
    <div
      ref={refs.setReference}
      className={cn(styles.wrapper, {
        [styles.wrapper_edit_mode]: assetOperationType === "editName",
      })}
    >
      <div className={styles.editableContainer}>
        <BiCaretDown className={styles.icon} />
        <TextField
          autoFocus
          placeholder={`Enter ${capitalizedAssetType} Name`}
          inputClassName={cn(styles.textFieldInput, {
            [styles.textFieldInputLoading]: createdAssetLoading,
          })}
          containerClassName={styles.textFieldContainer}
          value={createdAssetItemData.name}
          onChange={handleAssetNameChange}
          ref={textFieldRef}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          innerControls={
            createdAssetLoading ? (
              <Loader
                size={16}
                classes={{
                  overlay: styles.spinnerContainer,
                }}
                withOverlay
              />
            ) : null
          }
        />
      </div>
      {assetOperationType === "create" && (
        <div className={styles.versionWrapper}>
          <div className={styles.centeredContainer}>
            <div className={styles.statusMarker} />
            <Typography variant="caption2" className={styles.versionText}>
              v.1
            </Typography>
          </div>
          <div className={styles.centeredContainer}>
            <BiShareAlt size={16} className={styles.contextIcons} />
            <BiDotsHorizontal size={16} className={styles.contextIcons} />
          </div>
        </div>
      )}
      {createdAssetError && (
        <FloatingPortal>
          {renderErrorNotification(createdAssetError)}
        </FloatingPortal>
      )}
    </div>
  );
};

export default EditableAssetNavItem;
