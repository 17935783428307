import styles from "./Header.module.scss";
import { type PropsWithChildren } from "react";
import { motion } from "framer-motion";
import { BiChevronRight } from "react-icons/bi";
import cn from "classnames";
import { useSidebarAccordionContext } from "components/SidebarAccordion/SidebarAccordionContext";
import { useSidebarAccordionItemContext } from "components/SidebarAccordion/Item/ItemContext";

type HeaderProps = PropsWithChildren;

export function Header({ children }: HeaderProps) {
  const { id, isActive } = useSidebarAccordionItemContext();
  const { onToggleId } = useSidebarAccordionContext();

  return (
    <div
      role="button"
      className={cn(styles.container, { [styles.active]: isActive })}
      onClick={() => {
        onToggleId(id);
      }}
    >
      <motion.span
        className={styles.iconWrapper}
        initial={false}
        animate={{
          transform: isActive ? "rotate(90deg)" : "rotate(0deg)",
        }}
      >
        <BiChevronRight size={20} />
      </motion.span>

      {children}
    </div>
  );
}
