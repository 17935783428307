import { useState } from "react";
import {
  FloatingPortal,
  useFloating,
  useDismiss,
  autoUpdate,
  type Placement,
  flip,
} from "@floating-ui/react";
import { BiDotsVertical } from "react-icons/bi";
import cn from "classnames";

import ContextMenu from "../ContextMenu/ContextMenu";
import styles from "./KebabMenu.module.scss";

type KebabMenuProps = {
  menuOptions: any[];
  placement?: Placement | undefined;
  classes?: {
    trigger?: string;
    floatingContainer?: string;
  } | null;
};

const KebabMenu = ({
  placement = "right-start",
  menuOptions,
  classes = null,
}: KebabMenuProps) => {
  const [open, setOpen] = useState(false);
  const { x, y, refs, strategy, context } = useFloating({
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    placement,
    middleware: [flip()],
  });

  useDismiss(context, {
    escapeKey: true,
    outsidePress: true,
  });

  return (
    <>
      <div
        ref={refs.setReference}
        onClick={(e) => {
          e.stopPropagation();
          setOpen((open) => !open);
        }}
        className={cn(styles["menu__reference"], classes?.trigger)}
      >
        <BiDotsVertical size={20} />
      </div>
      <FloatingPortal>
        {open && (
          <div
            ref={refs.setFloating}
            className={cn(styles["menu__floating"], classes?.floatingContainer)}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
              width: "max-content",
            }}
          >
            <ContextMenu
              fitWidth={true}
              menuOptions={menuOptions}
              onClose={() => {
                setOpen(false);
              }}
            />
          </div>
        )}
      </FloatingPortal>
    </>
  );
};

export default KebabMenu;
