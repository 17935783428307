import styles from "./EnvironmentsNav.module.scss";
import cn from "classnames";
import { useNavigate, useLocation } from "react-router-dom";
import {
  BiShareAlt,
  BiTrash,
  BiPlus,
  BiDuplicate,
  BiDotsHorizontal,
} from "react-icons/bi";

import { useModal } from "hooks/useModal";
import { DropdownMenuButton } from "components/common/DropdownMenuButton/DropdownMenuButton";
import Fill from "components/common/Fill/Fill";
import { Button } from "components/common/Button/Button";
import IconButton from "components/common/IconButton/IconButton";
import { RemoveEnvModal } from "components/modals";
import { EmptyState } from "components/common/EmptyState/EmptyState";
import { getEnabledContextMenuOptions } from "utils/helpers";

export default function EnvironmentsNav() {
  const navigate = useNavigate();
  const location = useLocation();
  const urlComponents = location.pathname.split("/");
  const activeID = urlComponents[2];
  const { openModal } = useModal();

  const environments = [
    { id: "1", name: "env123-long-long" },
    { id: "2", name: "env456" },
  ];

  const menuOptions = [
    {
      key: "reuse-environment",
      type: "option" as const,
      label: (
        <div className={styles["menuItem"]}>
          <BiPlus />
          <span>Reuse Environment</span>
        </div>
      ),
      onClick: () => console.log("Reuse"),
    },
    {
      key: "duplicate-environment",
      type: "option" as const,
      label: (
        <div className={styles["menuItem"]}>
          <BiDuplicate />
          <span>Duplicate</span>
        </div>
      ),
      showDivider: true,
      onClick: () => console.log("Duplicate"),
    },
    {
      key: "delete-environment",
      type: "option" as const,
      label: (
        <div className={styles["menuItem"]}>
          <BiTrash />
          <span>Remove</span>
        </div>
      ),
      onClick: onDelete,
    },
  ];

  const onShare = () => {
    console.log("share environment");
  };

  function onDelete() {
    openModal({
      title: "Remove Environment",
      classes: { container: styles["environment__remove"] },
      content: ({ onModalClose }) => (
        <RemoveEnvModal onModalClose={onModalClose} />
      ),
    });
  }

  const onNewEnvironment = () => {
    console.log("New env");
  };

  const onEnvironmentClick = (id: string) => {
    navigate(`/environments/${id}/requirements`);
  };

  const enabledMenuOptions = getEnabledContextMenuOptions(menuOptions);

  return (
    <Fill>
      <>
        {environments.length === 0 && (
          <EmptyState
            variant="info"
            containerClassName={styles.environment__empty}
            title="No Environments yet..."
            description="Click Create Environment below to add new environment to this list."
          >
            <Button variant="text" onClick={onNewEnvironment}>
              Create Environment
            </Button>
          </EmptyState>
        )}
        {environments.map(({ name, id }) => {
          const isActive = id === activeID;

          return (
            <div
              key={id}
              onClick={() => {
                onEnvironmentClick(id);
              }}
              className={cn(styles["environment"], {
                [styles["environment__active"]]: isActive,
              })}
            >
              <span className={styles.environment__label}>{name}</span>

              <div className={styles["environment__actions"]}>
                <IconButton
                  size="small"
                  tooltip="Share"
                  onClick={onShare}
                  className={styles["environment__share"]}
                >
                  <BiShareAlt size={16} />
                </IconButton>

                <DropdownMenuButton
                  buttonVariant="text"
                  hideChevron
                  menuOptions={enabledMenuOptions}
                  icon={BiDotsHorizontal}
                  wrapContextMenuWithFloatingPortal
                />
              </div>
            </div>
          );
        })}
      </>
    </Fill>
  );
}
