import styles from "./ToasterContainer.module.scss";
import { useEffect, useState } from "react";
import { useToastsState, useCanvasLayoutState } from "store";
import ToasterMessage from "components/common/ToasterMessage/ToasterMessage";
import { type Toast } from "store/slices/toasts-slice";

const maxVisibleToasts = 3;

export default function ToasterContainer() {
  const toasts = useToastsState((slice) => slice.toasts);
  const deleteToast = useToastsState((slice) => slice.deleteToast);

  const showAppTour = useCanvasLayoutState((slice) => slice.showAppTour);

  const [visibleToasts, setVisibleToasts] = useState<Toast[]>([]);

  useEffect(() => {
    const initialToasts = toasts.slice(0, maxVisibleToasts);
    setVisibleToasts(initialToasts);
  }, [toasts]);

  const handleToastRemove = (id) => {
    deleteToast(id);

    if (toasts.length > maxVisibleToasts) {
      const nextToast = toasts[maxVisibleToasts];
      setVisibleToasts((prevToasts) => [...prevToasts, nextToast]);
    }
  };

  return (
    <div className={styles.container}>
      <div
        className={styles.container__content}
        style={{
          bottom: showAppTour ? "64px" : 0,
        }}
      >
        {visibleToasts.map((toast) => {
          return (
            <ToasterMessage
              key={toast.id}
              variant={toast.variant}
              action={toast.action}
              hotkey={toast.hotkey}
              onClick={toast.onClick}
              onRemove={() => {
                handleToastRemove(toast.id);
              }}
              duration={toast.duration}
            >
              {toast.message}
            </ToasterMessage>
          );
        })}
      </div>
    </div>
  );
}
