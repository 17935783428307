import { type StateCreator } from "store";
import { type ReactNode } from "react";

export interface Toast {
  id?: string;
  variant: "success" | "error" | "info" | "warning";
  message: ReactNode;
  action?: string;
  duration?: number;
  onClick?: () => void;
  hotkey?: string;
  createAt?: Date;
}

type State = {
  toasts: Toast[];
};

type Actions = {
  addToast: (toast: Toast) => void;
  deleteToast: (id: Toast["id"]) => void;
  clearToasts: () => void;
};

const initialState: State = {
  toasts: [],
};

export type ToastsSlice = State & Actions;

export const createToastsSlice: StateCreator<ToastsSlice> = (set) => ({
  ...initialState,
  deleteToast: (id) => {
    set(
      (store) => {
        store.toastsSlice.toasts = store.toastsSlice.toasts.filter(
          (toasts) => toasts.id !== id
        );
      },
      false,
      "toasts/deleteToast"
    );
  },
  addToast: (toast) => {
    // add a unique id
    toast.id = toast.id ? toast.id : Math.random().toString(36).substr(2, 9);
    // add date of creation to toastProps
    toast.createAt = new Date();
    toast.duration = toast.duration ? toast.duration : 3000;
    set(
      (store) => {
        store.toastsSlice.toasts.push(toast);
      },
      false,
      "toasts/addToast"
    );
  },
  clearToasts: () => {
    set(
      (store) => {
        store.toastsSlice.toasts = [];
      },
      false,
      "toasts/clearToasts"
    );
  },
});
