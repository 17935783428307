import styles from "./InstallGitApp.module.scss";
import { useState } from "react";
import githubService from "api/http/github-service";
import { useToastsState } from "store";
import Loader from "components/common/Loader/Loader";
import Typography from "components/Typography/Typography";
import { Button, type ButtonProps } from "components/common/Button/Button";
import { debouncePromise } from "utils/helpers";

const TEXT = {
  installApp:
    "To get started with Zerve Github Integration, you must first install the Zerve Github App.",
  loading: "Loading...",
};

type InstallGitAppProps = {
  organizationId?: string | null;
  canvasId?: string | null;
  buttonSize?: ButtonProps["size"];
  buttonLabel?: string;
  disabled?: boolean;
  showDescription?: boolean;
};

export function InstallGitApp({
  organizationId,
  canvasId,
  buttonSize = "large",
  buttonLabel = "Install Zerve Github App",
  disabled = false,
  showDescription = true,
}: InstallGitAppProps) {
  const addToast = useToastsState((slice) => slice.addToast);

  const [isFetchingInstallationUrl, setIsFetchingInstallationUrl] =
    useState(false);

  const handleInstallGitApp = async () => {
    setIsFetchingInstallationUrl(true);
    try {
      const response = await debouncePromise({
        promise: organizationId
          ? githubService.getAppInstallUrlOrganization(organizationId, canvasId)
          : githubService.getAppInstallUrlUser(canvasId),
      });
      setIsFetchingInstallationUrl(false);
      window.location.href = response.github_app_install_url;
    } catch (err) {
      setIsFetchingInstallationUrl(false);
      addToast({
        variant: "error",
        message: "Something went wrong",
      });
    }
  };

  const getInstallAppButtonLabel = () => {
    if (isFetchingInstallationUrl) {
      return (
        <div className={styles.loader_container}>
          <Loader size={20} />
          <div>{TEXT.loading}</div>
        </div>
      );
    }

    return buttonLabel;
  };

  return (
    <>
      {showDescription && (
        <Typography variant="body2" className={styles.description}>
          {TEXT.installApp}
        </Typography>
      )}
      <div className={styles.button_container}>
        <Button
          className={styles.button}
          size={buttonSize}
          disabled={isFetchingInstallationUrl || disabled}
          onClick={handleInstallGitApp}
        >
          {getInstallAppButtonLabel()}
        </Button>
      </div>
    </>
  );
}
