import styles from "./SavingStatusIndicator.module.scss";
import { memo } from "react";
import cn from "classnames";
import { BiSync, BiCheck, BiError } from "react-icons/bi";

export enum SAVING_STATUS {
  IDLE = "idle",
  PENDING = "pending",
  COMPLETED = "completed",
  FAILED = "failed",
}

interface SavingStatusIndicatorProps {
  status?: SAVING_STATUS;
  className?: string;
}

export const SavingStatusIndicator = memo(function SavingStatusIndicator({
  status,
  className,
}: SavingStatusIndicatorProps) {
  if (!status || status === SAVING_STATUS.IDLE) {
    return null;
  }

  return (
    <div
      className={cn(
        styles.container,
        { [styles.failed]: status === SAVING_STATUS.FAILED },
        className
      )}
    >
      {/* Pending */}
      {status === SAVING_STATUS.PENDING && (
        <>
          <BiSync className={styles.icon} />
          <span>Saving...</span>
        </>
      )}

      {/* Completed */}
      {status === SAVING_STATUS.COMPLETED && (
        <>
          <BiCheck className={styles.icon} />
          <span>Saved</span>
        </>
      )}

      {/* Failed */}
      {status === SAVING_STATUS.FAILED && (
        <>
          <BiError className={styles.icon} />
          <span>Saving failed</span>
        </>
      )}
    </div>
  );
});
