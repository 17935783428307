import styles from "./CreateEnvModal.module.scss";
import TextField from "components/common/TextField/TextField";
import { Button } from "components/common/Button/Button";
import Typography from "components/Typography";
import { type PropsWithModal } from "hooks/useModal";

type CreateEnvModalProps = PropsWithModal;

const CreateEnvModal = ({ onModalClose }: CreateEnvModalProps) => {
  const onChange = (e) => {
    console.log("On change", e.target.value);
  };

  const onCancel = () => {
    console.log("Cancel");
    onModalClose();
  };

  const onCreate = () => {
    console.log("Create");
    onModalClose();
  };

  return (
    <div>
      <Typography
        component="span"
        variant="body2"
        className={styles["modal__label"]}
      >
        Enter the name for your new environment.
      </Typography>

      <TextField
        placeholder="Type environment name here..."
        inputClassName={styles.modal__input}
        onChange={onChange}
      />

      <div className={styles["modal__row"]}>
        <Button size="large" variant="secondary" onClick={onCancel}>
          Cancel
        </Button>

        <Button size="large" onClick={onCreate}>
          Create
        </Button>
      </div>
    </div>
  );
};

export default CreateEnvModal;
