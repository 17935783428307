import stylesEx from "styles/_exports.module.scss";
import styles from "./EnvAssetsPanel.module.scss";
import { useState } from "react";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import Typography from "components/Typography/Typography";
import AssetEntry from "components/AssetEntry/AssetEntry";
import StrictModeDroppable from "./StrictModeDroppable";

const availableEntries = [
  {
    id: "1",
    name: "Function1-long-long-logn-long-long-long",
    size: 47,
    version: "v.32",
  },
  { id: "2", name: "Function2", size: 90, version: "v.59" },
  { id: "3", name: "Function3", size: 13, version: "v.11" },

  { id: "6", name: "Function6", size: 13, version: "v.11" },
  { id: "7", name: "Function7", size: 13, version: "v.11" },
  { id: "8", name: "Function8", size: 13, version: "v.11" },
  { id: "9", name: "Function9", size: 13, version: "v.11" },
  {
    id: "10",
    name: "Function10",
    size: 13,
    version: ["1.1.8", "2.4.6", "3.5.0"],
  },
];

const includedEntries = [
  { id: "4", name: "Const1", size: 1, version: "v.33" },
  { id: "5", name: "Const2", size: 2, version: "v.1" },
];

const EnvAssetsPanel = () => {
  const [state, setState] = useState({
    items1: availableEntries,
    items2: includedEntries,
  });

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    return [sourceClone, destClone];
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    border: isDragging ? `1px solid ${stylesEx["gray-800"]}` : "default",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = () => ({
    width: "50%",
  });

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.source.droppableId === result.destination.droppableId) {
      const items = reorder(
        state[result.source.droppableId],
        result.source.index,
        result.destination.index
      );

      const newState = {
        ...state,
        [result.source.droppableId]: items,
      };

      setState(newState);
    } else {
      const resultArray = move(
        state[result.source.droppableId],
        state[result.destination.droppableId],
        result.source,
        result.destination
      );

      const newState = {
        ...state,
        [result.source.droppableId]: resultArray[0],
        [result.destination.droppableId]: resultArray[1],
      };

      setState(newState);
    }
  };

  return (
    <div className={styles["assets"]}>
      <DragDropContext onDragEnd={handleDragEnd}>
        <StrictModeDroppable droppableId="items1">
          {(provided) => (
            <div
              ref={provided.innerRef}
              style={{
                ...getListStyle(),
                ...{ borderRight: "1px solid #282B33" },
              }}
            >
              <Typography
                component="div"
                variant="caption1"
                className={styles["title"]}
              >
                <span style={{ color: stylesEx["green-500"] }}>Available</span>
              </Typography>
              {state.items1.length > 0 &&
                state.items1.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => {
                      return (
                        <AssetEntry
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                          item={item}
                        />
                      );
                    }}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </StrictModeDroppable>
        <StrictModeDroppable droppableId="items2">
          {(provided) => (
            <div ref={provided.innerRef} style={getListStyle()}>
              <Typography
                component="div"
                variant="caption1"
                className={styles["title"]}
              >
                Included
              </Typography>

              {state.items2.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <AssetEntry
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                      item={item}
                    />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </StrictModeDroppable>
      </DragDropContext>
    </div>
  );
};

export default EnvAssetsPanel;
