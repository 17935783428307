import "modern-normalize/modern-normalize.css";
import "react-reflex/styles.css";
import "react-circular-progressbar/dist/styles.css";
import "./styles/index.scss";
import "./styles/theme.scss";
import React from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import router from "routes";
import LoadingScreen from "components/common/LoadingScreen/LoadingScreen";

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} fallbackElement={<LoadingScreen />} />
  </React.StrictMode>
);
