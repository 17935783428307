import { useMemo, useCallback, useContext, type ReactNode } from "react";
import { v4 as uuidv4 } from "uuid";
import { ModalContext } from "context/Modal";
import { type ModalProps } from "components/common/Modal/Modal";

type ModalType = {
  id: string;
  content: ReactNode;
  title: ModalProps["title"];
  classes: ModalProps["classes"];
  closeOnLocationChange: ModalProps["closeOnLocationChange"];
  onClose: ModalProps["onClose"];
};

export type PropsWithModal<T = unknown> = T & { onModalClose: () => void };

const DEFAULT_MODAL_PARAMS = {};

export function useModal({
  id: idFromProps,
}: { id?: string } = DEFAULT_MODAL_PARAMS) {
  const context = useContext(ModalContext);

  // modal id is either passed from props or randomly generated
  const id = useMemo(() => {
    return idFromProps || uuidv4();
  }, [idFromProps]);

  const openModal = useCallback(
    (modal: {
      title: ModalType["title"];
      // call onModalClose to close the modal
      content: ({ onModalClose }: { onModalClose: () => void }) => ReactNode;
      classes?: ModalType["classes"];
      closeOnLocationChange?: ModalType["closeOnLocationChange"];
      onClose?: ModalType["onClose"];
    }) => {
      const onClose = () => {
        context?.closeModal({ id });
        modal.onClose?.();
      };
      context?.openModal({
        id,
        title: modal.title,
        content: modal.content({ onModalClose: onClose }),
        classes: modal.classes,
        closeOnLocationChange: modal.closeOnLocationChange,
        onClose,
      });
    },
    [context?.openModal, context?.closeModal, id]
  );

  const closeModal = useCallback(() => {
    context?.closeModal({ id });
  }, [context?.closeModal, id]);

  return useMemo(() => {
    return {
      openModal,
      closeModal,
    };
  }, [openModal, closeModal]);
}
