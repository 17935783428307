import { useMemo } from "react";

import Typography from "components/Typography/Typography";
import { ASSET_PERMISSION } from "pages/Assets/config";
import type {
  Connection,
  ConnectionType,
} from "pages/Assets/types/connectionTypes";
import { DatabricksForm, MySQLForm, SnowflakeForm } from "../Forms";
import { CONNECTION_TYPE } from "../../constants";

import styles from "./ConnectionForm.module.scss";

export const notSupportedConnectionTypeBlock = (
  <div className={styles.not_supported_info}>
    <Typography variant="h3">Not supported connection type!</Typography>
  </div>
);

interface ConnectionFormProps {
  connectionType: ConnectionType;
  data: Partial<Connection>;
}

const ConnectionForm = ({ connectionType, data }: ConnectionFormProps) => {
  const FormComponent = useMemo(() => {
    switch (connectionType) {
      case CONNECTION_TYPE.mySQL:
      case CONNECTION_TYPE.mariaDB:
      case CONNECTION_TYPE.postgreSQL:
        return MySQLForm;
      case CONNECTION_TYPE.dataBricks:
        return DatabricksForm;
      case CONNECTION_TYPE.snowflake:
        return SnowflakeForm;
      default:
        return null;
    }
  }, [connectionType]);

  if (!connectionType) {
    return null;
  }

  if (!FormComponent) {
    return notSupportedConnectionTypeBlock;
  }

  return (
    <FormComponent
      key={data?.id}
      id={data?.id}
      initialValues={data?.value}
      disabled={data?.archive || data?.permission !== ASSET_PERMISSION.write}
    />
  );
};

export default ConnectionForm;
