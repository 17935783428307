import styles from "./QueriesPage.module.scss";
import { AssetsNav, AssetsTabHeader } from "pages/Assets/components";
import { useAssetsData } from "hooks/useAssetsData";
import { Outlet, useParams } from "react-router-dom";
import {
  CodeEditorAndResults,
  SelectConnection,
} from "pages/Assets/Queries/components";
import { useEffect } from "react";
import { useAssetsDataLoadActions } from "hooks/assets/useAssetsDataLoadActions";
import { useConnectionsDataLoadActions } from "hooks/assets/useConnectionsDataLoadActions";

const headerTitle = "Queries";
const headerSubtitle =
  "Execute and manage queries against a database or data storage system.";

const NoVersionSection = () => {
  return (
    <div className={styles.noVersionSection}>
      <SelectConnection />
      <CodeEditorAndResults />
    </div>
  );
};

export function QueriesPage() {
  const { workspaceID = "" } = useParams();
  const { getCurrentAssetItemsData, getCurrentAssetItemActions } =
    useAssetsData();
  const { activeItemVersionId } = getCurrentAssetItemsData("query");
  const { loadInitialAssetsData } = useAssetsDataLoadActions();
  const { loadInitialConnectionsData } = useConnectionsDataLoadActions();
  const { clearStore } = getCurrentAssetItemActions("query");

  useEffect(() => {
    loadInitialAssetsData({
      assetType: "query",
      workspaceId: workspaceID,
    });
    loadInitialConnectionsData({ workspaceId: workspaceID });

    return () => {
      clearStore();
    };
  }, [workspaceID]);

  return (
    <div className={styles.page}>
      <AssetsTabHeader
        title={headerTitle}
        subtitle={headerSubtitle}
        assetType="query"
      />
      <div className={styles.body}>
        <AssetsNav assetType="query" />
        {activeItemVersionId ? <Outlet /> : <NoVersionSection />}
      </div>
    </div>
  );
}
