import { useCallback, useMemo } from "react";
import type { AssetPageTabType } from "pages/Assets/types/abstractTypes";
import {
  getUserConstants,
  getWorkspaceConstants,
} from "api/http/assets/constants-service";
import {
  getUserFunctions,
  getWorkspaceFunctions,
} from "api/http/assets/functions-service";
import {
  getUserQueries,
  getWorkspaceQueries,
} from "api/http/assets/queries-service";
import { getAssetsByCanvasId } from "api/http/assets-service";
import { useAssetsState, useToastsState } from "store";
import { DATA_LOAD_STATUS } from "config/appConfig";

export const useAssetsDataLoadActions = () => {
  const addToast = useToastsState((slice) => slice.addToast);
  const {
    setConstantsData,
    setConstantDataLoadState,
    setCanvasConstantsData,
    setCanvasConstantDataLoadState,
  } = useAssetsState((slice) => slice.constantsActions);
  const {
    setFunctionsData,
    setFunctionsDataLoadState,
    setCanvasFunctionsData,
    setCanvasFunctionsDataLoadState,
  } = useAssetsState((slice) => slice.functionsActions);
  const {
    setQueriesData,
    setQueriesDataLoadState,
    setCanvasQueriesData,
    setCanvasQueriesDataLoadState,
  } = useAssetsState((slice) => slice.queriesActions);

  const loadInitialAssetsData = useCallback(
    async ({
      assetType,
      workspaceId,
    }: {
      assetType: AssetPageTabType;
      workspaceId?: string | null;
    }) => {
      switch (assetType) {
        case "function": {
          setFunctionsDataLoadState(DATA_LOAD_STATUS.LOADING);
          try {
            const data = workspaceId
              ? await getWorkspaceFunctions(workspaceId)
              : await getUserFunctions();
            setFunctionsData(data);
            setFunctionsDataLoadState(DATA_LOAD_STATUS.LOADED);
          } catch (err) {
            setFunctionsDataLoadState(DATA_LOAD_STATUS.ERROR);
          }
          break;
        }
        case "constant": {
          setConstantDataLoadState(DATA_LOAD_STATUS.LOADING);
          try {
            const data = workspaceId
              ? await getWorkspaceConstants(workspaceId)
              : await getUserConstants();
            setConstantsData(data);
            setConstantDataLoadState(DATA_LOAD_STATUS.LOADED);
          } catch (err) {
            setConstantDataLoadState(DATA_LOAD_STATUS.ERROR);
          }
          break;
        }
        case "query": {
          setQueriesDataLoadState(DATA_LOAD_STATUS.LOADING);
          try {
            const data = workspaceId
              ? await getWorkspaceQueries(workspaceId)
              : await getUserQueries();
            setQueriesData(data);
            setQueriesDataLoadState(DATA_LOAD_STATUS.LOADED);
          } catch (err) {
            setQueriesDataLoadState(DATA_LOAD_STATUS.ERROR);
          }
          break;
        }
        default:
          break;
      }
    },
    []
  );

  const loadCanvasAssetsData = useCallback(
    async ({
      assetType,
      canvasId,
    }: {
      assetType: AssetPageTabType;
      canvasId: string | null;
    }) => {
      if (!canvasId) {
        return;
      }

      switch (assetType) {
        case "function": {
          setCanvasFunctionsDataLoadState(DATA_LOAD_STATUS.LOADING);
          try {
            const canvasFunctionsData = await getAssetsByCanvasId(
              canvasId,
              "function"
            );
            setCanvasFunctionsData(canvasFunctionsData);
            setCanvasFunctionsDataLoadState(DATA_LOAD_STATUS.LOADED);
          } catch (err) {
            setCanvasFunctionsDataLoadState(DATA_LOAD_STATUS.ERROR);
            addToast({
              message: "Failed to load functions assets related to canvas",
              variant: "error",
            });
          }
          break;
        }
        case "constant": {
          setCanvasConstantDataLoadState(DATA_LOAD_STATUS.LOADING);
          try {
            const canvasConstantsData = await getAssetsByCanvasId(
              canvasId,
              "constant"
            );
            setCanvasConstantsData(canvasConstantsData);
            setCanvasConstantDataLoadState(DATA_LOAD_STATUS.LOADED);
          } catch (err) {
            setCanvasConstantDataLoadState(DATA_LOAD_STATUS.ERROR);
            addToast({
              message:
                "Failed to load constants and secrets assets related to canvas",
              variant: "error",
            });
          }
          break;
        }
        case "query": {
          setCanvasQueriesDataLoadState(DATA_LOAD_STATUS.LOADING);
          try {
            const canvasQueriesData = await getAssetsByCanvasId(
              canvasId,
              "query"
            );
            setCanvasQueriesData(canvasQueriesData);
            setCanvasQueriesDataLoadState(DATA_LOAD_STATUS.LOADED);
          } catch (err) {
            setCanvasQueriesDataLoadState(DATA_LOAD_STATUS.ERROR);
            addToast({
              message: "Failed to load queries assets related to canvas",
              variant: "error",
            });
          }
          break;
        }
        default:
          break;
      }
    },
    []
  );

  return useMemo(() => {
    return {
      loadInitialAssetsData,
      loadCanvasAssetsData,
    };
  }, [loadInitialAssetsData, loadCanvasAssetsData]);
};
