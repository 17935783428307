import styles from "./ComputeInstanceItem.module.scss";

type ComputeInstanceItemProps = {
  name?: string;
  cpu?: number;
  memory?: number;
  gpu?: number;
  gpuMemory?: number;
};

export function ComputeInstanceItem({
  name = "",
  cpu,
  memory,
  gpu,
  gpuMemory,
}: ComputeInstanceItemProps) {
  const hasDetails = cpu || memory || gpuMemory;
  return (
    <span className={styles.container}>
      {name ? <span className={styles.name}>{name}</span> : null}

      {hasDetails ? (
        <>
          {cpu ? <span className={styles.detail}>CPU {cpu}</span> : null}

          {memory ? (
            <span className={styles.detail}>Memory {memory}GB</span>
          ) : null}

          {gpu ? <span className={styles.detail}>GPU {gpu}</span> : null}

          {gpuMemory ? (
            <span className={styles.detail}>GPU Memory {gpuMemory}GB</span>
          ) : null}
        </>
      ) : null}
    </span>
  );
}
