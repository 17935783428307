import stylesEx from "styles/_exports.module.scss";
import { type FunctionComponent } from "react";
import { type IconType } from "react-icons";
import { BiCheck, BiErrorCircle, BiLoader, BiXCircle } from "react-icons/bi";
import PendingRun from "assets/icons/pending-run.svg?react";

export enum COMPUTE_ENV_TYPE {
  AWS_LAMBDA = "1",
  AWS_FARGATE = "2",
  AWS_SAGEMAKER = "3",
}

export enum DEPLOYMENT_TYPE {
  API = 1,
  SAGEMAKER = 2,
}

// API Route methods that are supported by the API Builder
export enum API_ROUTE_METHOD {
  GET = "get",
  POST = "post",
  PUT = "put",
  PATCH = "patch",
  DELETE = "delete",
}

export const API_ROUTE_METHOD_LABELS = Object.freeze({
  [API_ROUTE_METHOD.GET]: "GET",
  [API_ROUTE_METHOD.POST]: "POST",
  [API_ROUTE_METHOD.PUT]: "PUT",
  [API_ROUTE_METHOD.PATCH]: "PATCH",
  [API_ROUTE_METHOD.DELETE]: "DELETE",
});

// Payload types that are supported by the API Builder and SageMaker Endpoint
export enum PAYLOAD_TYPE {
  STRING = "string",
  NUMBER = "number",
  BOOLEAN = "boolean",
  LIST = "list",
  DICT = "dict",
}

export enum API_DEPLOYMENT_STATUS {
  // An executor is ready to be used
  READY = 1,

  // The docker image is being built on CodeBuild
  BUILDING = 2,

  // The executor failed to build
  ERROR = 3,

  // The executor is being initialized, this is the period
  // between the executor object being created in the database and
  // the lambda build process being started
  INITIALIZING = 4,

  // The period when the docker image is being deployed to
  // its serverless locations
  DEPLOYING = 5,

  // Not used in the backend, its reserved for the frontend
  // to indicate the requirements for the executor have been changed
  PENDING = 6,
}

export enum DEPLOYMENT_STATUS {
  READY = 1,
  BUILDING = 2,
  DEPLOYING = 3,
  FAILED = 4,
  PENDING = 5,
  DESTROYING = 6,
  DESTROYED = 7,
}

type DeploymentStatusDetails = {
  badgeLabel: string;
  color: string;
  Icon: IconType | FunctionComponent;
  shouldRotateIcon: boolean;
};

export const API_DEPLOYMENT_STATUS_DETAILS: Record<
  API_DEPLOYMENT_STATUS,
  DeploymentStatusDetails
> = {
  [API_DEPLOYMENT_STATUS.READY]: {
    badgeLabel: "Ready",
    color: stylesEx["statusCompleted-500"],
    Icon: BiCheck,
    shouldRotateIcon: false,
  },
  [API_DEPLOYMENT_STATUS.BUILDING]: {
    badgeLabel: "Building",
    color: stylesEx["statusRunning-500"],
    Icon: BiLoader,
    shouldRotateIcon: true,
  },
  [API_DEPLOYMENT_STATUS.ERROR]: {
    badgeLabel: "Error",
    color: stylesEx["statusError-500"],
    Icon: BiErrorCircle,
    shouldRotateIcon: false,
  },
  [API_DEPLOYMENT_STATUS.INITIALIZING]: {
    badgeLabel: "Initializing",
    color: stylesEx["statusRunning-500"],
    Icon: BiLoader,
    shouldRotateIcon: true,
  },
  [API_DEPLOYMENT_STATUS.DEPLOYING]: {
    badgeLabel: "Deploying",
    color: stylesEx["statusRunning-500"],
    Icon: BiLoader,
    shouldRotateIcon: true,
  },
  [API_DEPLOYMENT_STATUS.PENDING]: {
    badgeLabel: "Pending",
    color: stylesEx["statusPending-500"],
    Icon: PendingRun,
    shouldRotateIcon: false,
  },
};

export const DEPLOYMENT_STATUS_DETAILS: Record<
  DEPLOYMENT_STATUS,
  DeploymentStatusDetails
> = {
  [DEPLOYMENT_STATUS.READY]: {
    badgeLabel: "Ready",
    color: stylesEx["statusCompleted-500"],
    Icon: BiCheck,
    shouldRotateIcon: false,
  },
  [DEPLOYMENT_STATUS.BUILDING]: {
    badgeLabel: "In Progress",
    color: stylesEx["statusRunning-500"],
    Icon: BiLoader,
    shouldRotateIcon: true,
  },
  [DEPLOYMENT_STATUS.DEPLOYING]: {
    badgeLabel: "In Progress",
    color: stylesEx["statusRunning-500"],
    Icon: BiLoader,
    shouldRotateIcon: true,
  },
  [DEPLOYMENT_STATUS.FAILED]: {
    badgeLabel: "Error",
    color: stylesEx["statusError-500"],
    Icon: BiErrorCircle,
    shouldRotateIcon: false,
  },
  [DEPLOYMENT_STATUS.PENDING]: {
    badgeLabel: "Pending",
    color: stylesEx["statusPending-500"],
    Icon: PendingRun,
    shouldRotateIcon: false,
  },
  [DEPLOYMENT_STATUS.DESTROYING]: {
    badgeLabel: "Destroying",
    color: stylesEx["statusStopping-500"],
    Icon: BiLoader,
    shouldRotateIcon: true,
  },
  [DEPLOYMENT_STATUS.DESTROYED]: {
    badgeLabel: "Destroyed",
    color: stylesEx["statusPending-500"],
    Icon: BiXCircle,
    shouldRotateIcon: false,
  },
};

export enum API_DEPLOYMENT_DISABILITY {
  NO_DNS_NAME = "no-dns-name",
  NO_API_ROUTES = "no-api-routes",
  NO_ROUTE_NAME = "no-route-name",
  NO_ROUTE_CONNECTED = "no-route-connected",
  NO_CODE_CONNECTED = "no-code-connected",
}

export enum SAGEMAKER_DEPLOYMENT_DISABILITY {
  NO_CODE_CONNECTED = "no-code-connected",
}

export enum DEPLOYMENT_VALIDATOR_TYPE {
  STR = "str",
  FLOAT = "float",
  BOOL = "bool",
  LIST = "list",
  DICT = "dict",
  ANY = "Any",
}

export const DEPLOYMENT_VALIDATOR_TYPE_LABELS = Object.freeze({
  [DEPLOYMENT_VALIDATOR_TYPE.STR]: "String",
  [DEPLOYMENT_VALIDATOR_TYPE.FLOAT]: "Number",
  [DEPLOYMENT_VALIDATOR_TYPE.BOOL]: "Boolean",
  [DEPLOYMENT_VALIDATOR_TYPE.LIST]: "List",
  [DEPLOYMENT_VALIDATOR_TYPE.DICT]: "Dictionary",
  [DEPLOYMENT_VALIDATOR_TYPE.ANY]: "Any",
});
