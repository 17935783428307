import cn from "classnames";
import { BLOCK_STATUS, BLOCK_STATUS_DETAILS } from "config/canvasConfig";
import Typography from "components/Typography/Typography";
import styles from "./BlockStatus.module.scss";

const BLOCK_STATUS_TO_CLASS_EXTENSION_MAP = Object.freeze({
  [BLOCK_STATUS.PENDING]: "pending",
  [BLOCK_STATUS.RUNNING]: "running",
  [BLOCK_STATUS.COMPLETED]: "completed",
  [BLOCK_STATUS.ERROR]: "error",
  [BLOCK_STATUS.STOPPING]: "stopping",
});

type BlockToolbarProps = {
  status: BLOCK_STATUS;
  type?: "filled" | "outlined";
  iconOnly?: boolean;
  statusClassName?: string;
  statusTextClassName?: string;
};

export default function BlockStatus({
  status,
  iconOnly = false,
  type = "filled",
  statusClassName = "",
  statusTextClassName = "",
}: BlockToolbarProps) {
  const statusTitle = BLOCK_STATUS_DETAILS[status].name;
  const StatusIcon = BLOCK_STATUS_DETAILS[status].Icon;
  const classExtension = BLOCK_STATUS_TO_CLASS_EXTENSION_MAP[status];

  return (
    <div className={styles.status}>
      <div className={styles[`status__${type}`]}>
        <div
          className={cn(
            styles[`status__${type}__${classExtension}`],
            statusClassName,
            {
              [styles[`status__${type}__${classExtension}__inactive`]]:
                iconOnly,
            }
          )}
        >
          <StatusIcon
            className={cn(styles.status__icon, {
              [styles.status__icon__only]: iconOnly,
            })}
          />
          {!iconOnly && (
            <Typography
              component="div"
              variant="caption2"
              className={cn(styles.status_text, statusTextClassName)}
            >
              {statusTitle}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
}
