import {
  applyNodeChanges,
  applyEdgeChanges,
  addEdge,
  type XYPosition,
  type Dimensions,
  type NodeChange,
  type EdgeChange,
} from "reactflow";
import { type StateCreator } from "store";
import { DATA_LOAD_STATUS } from "config/appConfig";
import {
  LAYER_TYPE,
  BLOCK_TYPE,
  type CANVAS_PERMISSION,
} from "config/canvasConfig";
import { type LAYER_DISABILITY_REASON } from "config/canvasConfig";
import { transformBlock, transformEdge, animateMoveBlock } from "utils/canvas";
import type {
  LayerType,
  BlockType,
  BufferBlock,
  EdgeType,
  UserApiType,
} from "models/canvas";
import type { ScheduledJob, LayerScheduledJobInfo } from "models/scheduledJobs";

type State = {
  isLayoutInitialized: boolean;
  isLayoutLoading: boolean;
  canAccessCanvas: boolean | null;
  isEditor: boolean;
  permission: CANVAS_PERMISSION | null;
  canvasId: string;
  projectId: string;
  workspaceId: string;
  organizationId: string;
  connectionId: string; // socket connection id
  name: string;
  description: string;
  layers: Map<string, LayerType>;
  layout: Record<
    LayerType["id"],
    | { blocks: BlockType[]; edges: EdgeType[]; user_api: UserApiType | null }
    | undefined
  >;
  layersEditDisabilityReasons: Record<
    LayerType["id"],
    Set<LAYER_DISABILITY_REASON> | undefined
  >;
  layerScheduledJobInfo: Map<LayerType["id"], LayerScheduledJobInfo>;
  scheduledJobRunsHistoryJob: ScheduledJob | null;
  selectedLayerId: LayerType["id"];
  fullscreenBlockId: string;
  activeBlockId: string;
  focusedBlockId: string;
  blockBeingMoved?: string; // block id set in moveBlock
  zoomTo: {
    x: number;
    y: number;
    width: number;
    height: number;
    layer_id: LayerType["id"];
  } | null;
  assets: any[]; // TODO: annotate assets
  showPlaceholderBlock: boolean;
  placeholderBlockPosition: XYPosition | null;
  bufferBlocks: BufferBlock[];
};

type Actions = {
  clearStore: () => void;
  setAll: ({
    name,
    canvasId,
    projectId,
    workspaceId,
    organizationId,
    layers,
    layout,
    initialLayerId,
    initialBlockId,
    permission,
  }: {
    name: string;
    canvasId: string;
    projectId: string;
    workspaceId: string;
    organizationId: string;
    layers: LayerType[];
    layout: Record<
      LayerType["id"],
      { blocks: any[]; edges: any[]; user_api: UserApiType | null }
    >;
    initialLayerId: LayerType["id"] | null;
    initialBlockId: BlockType["id"] | null;
    permission: CANVAS_PERMISSION | null;
  }) => void;
  getIsLayoutInitialized: () => boolean;
  setIsLayoutInitialized: (state: boolean) => void;
  setIsLayoutLoading: (state: boolean) => void;
  setCanAccessCanvas: (state: boolean | null) => void;
  setIsEditor: (state: boolean) => void;
  getConnectionId: () => string;
  setConnectionId: (connection_id: string) => void;
  setName: (payload: any) => void;
  setCanvasSettings: (payload: { name: string; description: string }) => void;
  getSelectedLayer: () => LayerType | null;
  setSelectedLayerId: (layerId: LayerType["id"]) => void;
  getLayer: (layerId: LayerType["id"]) => LayerType | null;
  addLayer: (layer: any) => void;
  removeLayer: (payload: any) => void;
  renameLayer: (payload: any) => void;
  updateLayer: (layer: LayerType) => void;
  updateNodes: (changes: NodeChange[]) => void;
  updateEdges: (changes: EdgeChange[]) => void;
  getLayerEdges: (layerId: LayerType["id"]) => EdgeType[];
  getSelectedLayerEdges: () => EdgeType[];
  addEdge: (edge: any) => void;
  removeEdge: (edge: any) => void;
  addBlock: (block: any) => void;
  getBlock: (id?: string | null) => BlockType | null;
  getLayerBlocks: (layerId: LayerType["id"]) => BlockType[];
  getSelectedLayerBlocks: () => BlockType[];
  getSelectedLayerApiControllerId: () => BlockType["id"] | null;
  removeBlock: (payload: any) => void;
  moveBlock: (payload: {
    id: BlockType["id"];
    layer_id: LayerType["id"];
    x: number;
    y: number;
  }) => void;
  animateMoveBlock: (payload: {
    id: BlockType["id"];
    layer_id: LayerType["id"];
    x: number;
    y: number;
  }) => void;
  setDynamicallyMoved: (blockIds: BlockType["id"][], isMoved: boolean) => void;
  resizeBlock: (
    payload: XYPosition & Dimensions & { id: string; layer_id: string }
  ) => void;
  getConnectingBlocks: (blockId: BlockType["id"]) => BlockType[];
  getConnectedBlocks: (blockId: BlockType["id"]) => BlockType[];
  getLastConnectingBlockId: (blockId: BlockType["id"]) => BlockType["id"];
  clearBlockBeingMoved: () => void;
  getIsLayerEditDisabled: (layerId: LayerType["id"] | null) => boolean;
  setLayersEditDisabilityReasons: (
    data: Record<LayerType["id"], Set<LAYER_DISABILITY_REASON> | undefined>
  ) => void;
  setScheduledJobRunsHistoryJob: (
    value: CanvasSlice["scheduledJobRunsHistoryJob"]
  ) => void;
  updateLayerScheduledJobInfo: (
    layerId: LayerType["id"],
    data: Partial<LayerScheduledJobInfo>
  ) => void;
  updateUserApi: (userApi: UserApiType) => void;
  setFullscreenBlockId: (id: string) => void;
  clearFullscreenBlockId: () => void;
  setActiveBlockId: (blockId: BlockType["id"]) => void;
  clearActiveBlockId: () => void;
  setFocusedBlockId: (
    blockId: BlockType["id"],
    layerId?: LayerType["id"]
  ) => void;
  setZoomTo: (payload: State["zoomTo"]) => void;
  setAssets: (assets: any) => void;
  setPlaceholderBlockPosition: (
    position: State["placeholderBlockPosition"]
  ) => void;
  setBufferBlocks: (blocks: State["bufferBlocks"]) => void;
};

const getInitialState = (): State => ({
  isLayoutInitialized: false,
  isLayoutLoading: false,
  canAccessCanvas: null,
  isEditor: false,
  permission: null,
  canvasId: "",
  projectId: "",
  workspaceId: "",
  organizationId: "",
  connectionId: "",
  name: "",
  description: "",
  layers: new Map(),
  layout: {},
  layersEditDisabilityReasons: {},
  layerScheduledJobInfo: new Map(),
  scheduledJobRunsHistoryJob: null,
  selectedLayerId: "",
  fullscreenBlockId: "",
  activeBlockId: "",
  focusedBlockId: "",
  blockBeingMoved: undefined,
  zoomTo: null,
  assets: [],
  showPlaceholderBlock: false,
  placeholderBlockPosition: null,
  bufferBlocks: [],
});

export type CanvasSlice = State & Actions;

export const createCanvasSlice: StateCreator<CanvasSlice> = (set, get) => ({
  ...getInitialState(),
  clearStore: () => {
    set(
      (store) => {
        Object.assign(store.canvasSlice, getInitialState());
      },
      false,
      "canvas/clearStore"
    );
  },
  setAll: ({
    name,
    canvasId,
    projectId,
    workspaceId,
    organizationId,
    layers,
    layout,
    initialLayerId,
    initialBlockId,
    permission,
  }) => {
    // create layout object
    const transformedLayout: CanvasSlice["layout"] = {};
    for (const layer_id in layout) {
      const layer = layout[layer_id];
      const transformedBlocks: BlockType[] = layer.blocks.map((block) =>
        transformBlock(block)
      );
      const transformEdges = layer.edges.map((edge) => transformEdge(edge));
      transformedLayout[layer_id] = {
        blocks: transformedBlocks,
        edges: transformEdges,
        user_api: layer.user_api || null,
      };
    }

    // create layers map
    const layersMap = layers.reduce<Map<LayerType["id"], LayerType>>(
      (acc, layer) => {
        acc.set(layer.id, layer);
        return acc;
      },
      new Map()
    );

    // set the initial layer; use DEVELOPMENT layer as fallback
    const selectedLayerId =
      initialLayerId && layersMap.has(initialLayerId)
        ? initialLayerId
        : (
            layers.find(
              (layerData) => layerData.type === LAYER_TYPE.DEVELOPMENT
            ) || layers[0]
          ).id;

    // set the initial block
    const activeBlockId =
      initialBlockId &&
      transformedLayout[selectedLayerId]?.blocks.some(
        (b) => b.id === initialBlockId
      )
        ? initialBlockId
        : "";

    set(
      (store) => {
        store.canvasSlice.selectedLayerId = selectedLayerId;
        store.canvasSlice.activeBlockId = activeBlockId;
        store.canvasSlice.name = name;
        store.canvasSlice.canvasId = canvasId;
        store.canvasSlice.projectId = projectId;
        store.canvasSlice.workspaceId = workspaceId;
        store.canvasSlice.organizationId = organizationId;
        store.canvasSlice.layers = layersMap;
        store.canvasSlice.layout = transformedLayout;
        store.canvasSlice.permission = permission;
      },
      false,
      "canvas/setAll"
    );
  },
  getIsLayoutInitialized: () => get().canvasSlice.isLayoutInitialized,
  setIsLayoutInitialized: (state) => {
    set(
      (store) => {
        store.canvasSlice.isLayoutInitialized = state;
      },
      false,
      "canvas/setIsLayoutInitialized"
    );
  },
  setIsLayoutLoading: (state) => {
    set(
      (store) => {
        store.canvasSlice.isLayoutLoading = state;
      },
      false,
      "canvas/setIsLayoutLoading"
    );
  },
  setCanAccessCanvas: (state) => {
    set(
      (store) => {
        store.canvasSlice.canAccessCanvas = state;
      },
      false,
      "canvas/setCanAccessCanvas"
    );
  },
  setIsEditor: (state) => {
    set(
      (store) => {
        store.canvasSlice.isEditor = state;
      },
      false,
      "canvas/setIsEditor"
    );
  },
  getConnectionId: () => get().canvasSlice.connectionId,
  setConnectionId: (connectionId) => {
    set(
      (store) => {
        store.canvasSlice.connectionId = connectionId;
      },
      false,
      "canvas/setConnectionId"
    );
  },
  setName: (payload) => {
    set(
      (store) => {
        store.canvasSlice.name = payload.canvas_name;
      },
      false,
      "canvas/setName"
    );
  },
  setCanvasSettings: (payload) => {
    set(
      (store) => {
        store.canvasSlice.name = payload.name;
        store.canvasSlice.description = payload.description;
      },
      false,
      "canvas/setCanvasSettings"
    );
  },
  getSelectedLayer: () => {
    return (
      get().canvasSlice.getLayer(get().canvasSlice.selectedLayerId) || null
    );
  },
  setSelectedLayerId: (layerId) => {
    set(
      (store) => {
        const selectedLayerId = store.canvasSlice.selectedLayerId;
        if (
          store.canvasSlice.layers.has(layerId) &&
          selectedLayerId !== layerId
        ) {
          store.canvasSlice.activeBlockId = "";
          store.canvasSlice.selectedLayerId = layerId;

          // clear selection in the previous layer
          const blocks =
            store.canvasSlice.layout[selectedLayerId]?.blocks || [];
          for (const block of blocks) {
            block.selected = false;
          }
        }
      },
      false,
      "canvas/setSelectedLayerId"
    );
  },
  getLayer: (layerId) => {
    return get().canvasSlice.layers.get(layerId) || null;
  },
  addLayer: (layer) => {
    set(
      (store) => {
        store.canvasSlice.layers.set(layer.id, layer);
        store.canvasSlice.layout[layer.id] = {
          blocks: [],
          edges: [],
          user_api: null,
        };
      },
      false,
      "canvas/addLayer"
    );
  },
  removeLayer: (payload) => {
    const layer_id = payload.layer_id;
    if (layer_id === get().canvasSlice.selectedLayerId) {
      const layers = get().canvasSlice.layers;
      const newSelectedLayer = Array.from(layers.values()).find(
        (layer) => layer.id !== layer_id
      );
      if (newSelectedLayer) {
        get().canvasSlice.setSelectedLayerId(newSelectedLayer.id);
      }
    }
    set(
      (store) => {
        store.canvasSlice.layers.delete(layer_id);
        delete store.canvasSlice.layout[layer_id];
        if (store.canvasSlice.layerScheduledJobInfo.has(layer_id)) {
          store.canvasSlice.layerScheduledJobInfo.delete(layer_id);
        }
      },
      false,
      "canvas/removeLayer"
    );
  },
  renameLayer: (payload) => {
    const layer_id = payload.layer_id;
    const name = payload.layer_name;
    set(
      (store) => {
        const layer = store.canvasSlice.layers.get(layer_id);

        if (layer && layer.name !== name) {
          layer.name = name;
        }
      },
      false,
      "canvas/renameLayer"
    );
  },
  updateLayer: (layer) => {
    set(
      (store) => {
        store.canvasSlice.layers.set(layer.id, layer);
      },
      false,
      "canvas/updateLayer"
    );
  },
  updateNodes: (changes) => {
    set(
      (store) => {
        const selectedLayerId = store.canvasSlice.selectedLayerId;
        const layout = store.canvasSlice.layout[selectedLayerId];
        if (!layout) {
          return;
        }
        layout.blocks = applyNodeChanges(changes, layout.blocks) as BlockType[]; // "as BlockType[]" is fixed in reactflow v12
      },
      false,
      "canvas/updateNodes"
    );
  },
  updateEdges: (changes) => {
    set(
      (store) => {
        const selectedLayerId = store.canvasSlice.selectedLayerId;
        const layout = store.canvasSlice.layout[selectedLayerId];
        if (!layout) {
          return;
        }
        layout.edges = applyEdgeChanges(changes, layout.edges);
      },
      false,
      "canvas/updateEdges"
    );
  },
  getLayerEdges: (layerId) => {
    return get().canvasSlice.layout[layerId]?.edges || [];
  },
  getSelectedLayerEdges: () => {
    const selectedLayerId = get().canvasSlice.selectedLayerId;
    return get().canvasSlice.getLayerEdges(selectedLayerId);
  },
  addEdge: (newEdge) => {
    const layerId = newEdge.layer_id;
    const transformedEdge = transformEdge(newEdge);
    // add the edge to the layout
    set(
      (store) => {
        const layout = store.canvasSlice.layout[layerId];
        if (!layout) {
          return;
        }
        layout.edges = addEdge(transformedEdge, layout.edges);
      },
      false,
      "canvas/addEdge"
    );
  },
  removeEdge: (edgeToRemove) => {
    const layerId = edgeToRemove.layer_id;
    set(
      (store) => {
        const layer = store.canvasSlice.layout[layerId];
        if (layer) {
          layer.edges = layer.edges.filter(
            (edge) => edge.id !== edgeToRemove.edge_id
          );
        }
      },
      false,
      "canvas/removeEdge"
    );
  },
  addBlock: (block) => {
    const transformedBlock = transformBlock(block);
    const layerId = block.layer_id;
    set(
      (store) => {
        store.canvasSlice.layout[layerId]?.blocks.push(transformedBlock);
      },
      false,
      "canvas/addBlock"
    );
  },
  getBlock: (id) => {
    if (!id) {
      return null;
    }

    // look into the selected layer first
    const selectedLayerId = get().canvasSlice.selectedLayerId;
    let block = get()
      .canvasSlice.getLayerBlocks(selectedLayerId)
      .find((b) => b.id === id);
    if (block) {
      return block;
    }

    // if not found, look into the other layers
    for (const [layerId] of get().canvasSlice.layers) {
      block = get().canvasSlice.layout[layerId]?.blocks.find(
        (b) => b.id === id
      );
      if (block) {
        return block;
      }
    }

    return null;
  },
  getLayerBlocks: (layerId) => {
    return get().canvasSlice.layout[layerId]?.blocks || [];
  },
  getSelectedLayerBlocks: () => {
    const selectedLayerId = get().canvasSlice.selectedLayerId;
    return get().canvasSlice.getLayerBlocks(selectedLayerId);
  },
  getSelectedLayerApiControllerId: () => {
    const selectedLayerBlocks = get().canvasSlice.getSelectedLayerBlocks();
    let blockId: BlockType["id"] | null = null;
    for (const block of selectedLayerBlocks) {
      if (block.type === BLOCK_TYPE.API_CONTROLLER) {
        blockId = block.id;
        break;
      }
    }
    return blockId;
  },
  removeBlock: (payload) => {
    const layerId = payload.layer_id;
    const blockId = payload.block_id;
    set(
      (store) => {
        const layer = store.canvasSlice.layout[layerId];
        if (layer) {
          layer.blocks = layer.blocks.filter((b) => b.id !== blockId);
          layer.edges = layer.edges.filter(
            (e) => e.source !== blockId && e.target !== blockId
          );
        }

        // cleanup block id references
        if (store.canvasSlice.activeBlockId === blockId) {
          store.canvasSlice.activeBlockId = "";
        }
        if (store.canvasSlice.fullscreenBlockId === blockId) {
          store.canvasSlice.fullscreenBlockId = "";
        }
        if (store.canvasSlice.focusedBlockId === blockId) {
          store.canvasSlice.focusedBlockId = "";
        }

        // remove block comments
        store.commentsSlice.comments.delete(blockId);
      },
      false,
      "canvas/removeBlock"
    );
  },
  moveBlock: (payload) => {
    const layerId = payload.layer_id;
    set(
      (store) => {
        const block = store.canvasSlice.layout[layerId]?.blocks.find(
          (b) => b.id === payload.id
        );
        if (block) {
          block.position.x = payload.x;
          block.position.y = payload.y;
        }
      },
      false,
      "canvas/moveBlock"
    );
  },
  animateMoveBlock(payload) {
    const blockBeingMoved = payload.id;
    const block = get().canvasSlice.getBlock(blockBeingMoved);
    animateMoveBlock(block, payload, get().canvasSlice.moveBlock);
  },
  setDynamicallyMoved: (blockIds, isMoved) => {
    set(
      (store) => {
        const selectedLayerId = store.canvasSlice.selectedLayerId;
        const blocks = store.canvasSlice.layout[selectedLayerId]?.blocks;
        if (!blocks) {
          return;
        }
        for (const blockId of blockIds) {
          const block = blocks.find((b) => b.id === blockId);
          if (block) {
            block.data.dynamicallyMoved = isMoved;
          }
        }
      },
      false,
      "canvas/setDynamicallyMoved"
    );
  },
  resizeBlock: (payload) => {
    const layerId = payload.layer_id;
    set(
      (store) => {
        const block = store.canvasSlice.layout[layerId]?.blocks.find(
          (b) => b.id === payload.id
        );
        if (block) {
          block.position.x = payload.x;
          block.position.y = payload.y;
          block.width = payload.width || block.width;
          block.height = payload.height || block.height;
        }
      },
      false,
      "canvas/resizeBlock"
    );
  },
  getConnectingBlocks: (block_id) => {
    const connectingBlockIds = get()
      .canvasSlice.getSelectedLayerEdges()
      .filter((edge) => edge.source === block_id)
      .map((edge) => edge.target);
    const connectingBlocks = connectingBlockIds
      .map((id) => get().canvasSlice.getBlock(id))
      .filter((block): block is BlockType => !!block);
    return connectingBlocks;
  },
  getConnectedBlocks: (block_id) => {
    const connectedBlockIds = get()
      .canvasSlice.getSelectedLayerEdges()
      .filter((edge) => edge.target === block_id)
      .map((edge) => edge.source);
    const connectedBlocks = connectedBlockIds
      .map((id) => get().canvasSlice.getBlock(id))
      .filter((block): block is BlockType => !!block);
    return connectedBlocks;
  },
  getLastConnectingBlockId: (blockId) => {
    /**
     * Return the last block connected to the given block.
     */
    let lastBlockId = blockId;
    let hasMoreConnectingBlocks = true;

    while (hasMoreConnectingBlocks) {
      const connectingBlockIds = get()
        .canvasSlice.getSelectedLayerEdges()
        .filter((edge) => edge.source === lastBlockId)
        .map((edge) => edge.target);
      hasMoreConnectingBlocks = connectingBlockIds.length > 0;
      if (hasMoreConnectingBlocks) {
        lastBlockId = connectingBlockIds[0]; // take the first connecting block
      }
    }

    return lastBlockId;
  },
  clearBlockBeingMoved: () => {
    set(
      (store) => {
        store.canvasSlice.blockBeingMoved = undefined;
      },
      false,
      "canvas/clearBlockBeingMoved"
    );
  },
  getIsLayerEditDisabled: (layerId) => {
    const reasons =
      get().canvasSlice.layersEditDisabilityReasons[layerId || ""];
    return reasons ? reasons.size > 0 : false;
  },
  setLayersEditDisabilityReasons: (data) => {
    set(
      (store) => {
        store.canvasSlice.layersEditDisabilityReasons = data;
      },
      false,
      "canvas/setLayersEditDisabilityReasons"
    );
  },
  setScheduledJobRunsHistoryJob: (value) => {
    set(
      (store) => {
        store.canvasSlice.scheduledJobRunsHistoryJob = value;
      },
      false,
      "canvas/setScheduledJobRunsHistoryJob"
    );
  },
  updateLayerScheduledJobInfo: (layerId, data) => {
    set(
      (store) => {
        const curr = store.canvasSlice.layerScheduledJobInfo.get(layerId);
        store.canvasSlice.layerScheduledJobInfo.set(layerId, {
          loadStatus:
            data?.loadStatus || curr?.loadStatus || DATA_LOAD_STATUS.NOT_LOADED,
          data: data.data || curr?.data || null,
          updateInProgress:
            typeof data.updateInProgress === "boolean"
              ? data.updateInProgress
              : curr?.updateInProgress || false,
        });
      },
      false,
      "canvas/updateLayerScheduledJobInfo"
    );
  },
  updateUserApi: (userApi) => {
    const layerId = userApi.layer_id;
    set(
      (store) => {
        const layerLayout = store.canvasSlice.layout[layerId];
        if (layerLayout) {
          layerLayout.user_api = userApi;
        }
      },
      false,
      "canvas/updateUserApi"
    );
  },
  setFullscreenBlockId: (id) => {
    set(
      (store) => {
        store.canvasSlice.fullscreenBlockId = id;
      },
      false,
      "canvas/setFullscreenBlockId"
    );
  },
  clearFullscreenBlockId: () => {
    set(
      (store) => {
        store.canvasSlice.fullscreenBlockId = "";
      },
      false,
      "canvas/clearFullscreenBlockId"
    );
  },
  setActiveBlockId: (blockId) => {
    if (blockId === get().canvasSlice.activeBlockId) {
      return;
    }
    set(
      (store) => {
        store.canvasSlice.activeBlockId = blockId;
        store.canvasSlice.focusedBlockId = "";

        // select a block when it is activated
        const layerId = store.canvasBlocksSlice.data[blockId]?.layer_id || "";
        const blocks = store.canvasSlice.layout[layerId]?.blocks || [];
        for (const block of blocks) {
          if (block.id === blockId) {
            block.selected = true;
            break;
          }
        }
      },
      false,
      "canvas/setActiveBlockId"
    );
  },
  clearActiveBlockId: () => {
    if (get().canvasSlice.activeBlockId === "") {
      return;
    }
    set(
      (store) => {
        store.canvasSlice.activeBlockId = "";
      },
      false,
      "canvas/clearActiveBlockId"
    );
  },
  setFocusedBlockId: (blockId, layerId) => {
    set(
      (store) => {
        store.canvasSlice.focusedBlockId = blockId;
        if (layerId && store.canvasSlice.layers.has(layerId)) {
          store.canvasSlice.selectedLayerId = layerId;
        }
      },
      false,
      "canvas/setFocusedBlockId"
    );
  },
  setZoomTo: (payload) => {
    set(
      (store) => {
        store.canvasSlice.zoomTo = payload;
      },
      false,
      "canvas/setZoomTo"
    );
  },
  setAssets: (assets) => {
    set(
      (store) => {
        store.canvasSlice.assets = assets;
      },
      false,
      "canvas/setAssets"
    );
  },
  setPlaceholderBlockPosition(position) {
    set(
      (store) => {
        if (!position) {
          // placeholder has been placed
          store.canvasSlice.placeholderBlockPosition = null;
        } else {
          // placeholder has been moved
          const storedPosition = store.canvasSlice.placeholderBlockPosition;
          if (
            storedPosition?.x !== position.x ||
            storedPosition?.y !== position.y
          ) {
            // onDrag event is fired even if cursor doesn't move.
            // We don't want to trigger render if the position didn't change
            store.canvasSlice.placeholderBlockPosition = position;
          }
        }

        // show placeholder block only if there is a position for it
        store.canvasSlice.showPlaceholderBlock =
          !!store.canvasSlice.placeholderBlockPosition;
      },
      false,
      "canvas/setPlaceholderBlockPosition"
    );
  },
  setBufferBlocks: (blocks) => {
    set(
      (store) => {
        store.canvasSlice.bufferBlocks = blocks;
      },
      false,
      "canvas/setBufferBlocks"
    );
  },
});
