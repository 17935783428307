/* 
  Sentry config 
  https://zerve.sentry.io/projects/app-frontend/
*/
import { useEffect } from "react";
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import * as Sentry from "@sentry/react";

const ENVIRONMENT = import.meta.env.MODE; // production, development, test
const SENTRY_DSN_ENDPOINT = import.meta.env.VITE_SENTRY_DSN_ENDPOINT;

declare const __COMMIT_HASH__: string;

const productionConfig: Sentry.BrowserOptions = {
  environment: ENVIRONMENT,
  release: `frontend@${__COMMIT_HASH__}`,
  dsn: SENTRY_DSN_ENDPOINT,
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.3, // Capture % of the transactions

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
};

const developmentConfig: Sentry.BrowserOptions = {
  ...productionConfig,
  dsn: "", // comment out this line to enable Sentry for local development
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 1.0,
};

export const sentryConfig = import.meta.env.PROD
  ? productionConfig
  : developmentConfig;
