import styles from "./OrganizationMembers.module.scss";
import cn from "classnames";
import { useState, useEffect, useMemo } from "react";
import omit from "lodash-es/omit";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useUserState, useToastsState } from "store";
import { Button } from "components/common/Button/Button";
import TextField from "components/common/TextField/TextField";
import { Checkbox } from "components/common/Checkbox/Checkbox";
import Table from "components/common/Table/Table";
import FormDropdown from "components/common/FormDropdown/FormDropdown";
import { TitleSection } from "components/organization/TitleSection/TitleSection";
import { stringIsValidEmail } from "utils/validation";
import {
  getOrganization,
  getOrganizationMembers,
  postOrganizationMember,
  updateOrganizationMember,
  deleteOrganizationMembers,
  getOrganizationInvites,
  deleteOrganizationInvite,
} from "api/http/organization-service";
import { getRoles } from "api/http/role-service";

import {
  useReactTable,
  getCoreRowModel,
  createColumnHelper,
} from "@tanstack/react-table";
import type { Organization } from "models/organization";

// type declaration for table rows
type OrganizationMember = {
  id: string;
  role_id: string;
  invited: boolean;
  user: {
    id: string;
    email: string;
  };
};
// type declaration for role options for role selector dropdown
type RoleOption = {
  label: string;
  value: string;
};

export function OrganizationMembers() {
  const userID = useUserState((slice) => slice.userID);
  const { orgID = "" } = useParams();
  const addToast = useToastsState((slice) => slice.addToast);

  const [organizationRoleOptions, setOrganizationRoleOptions] = useState<
    RoleOption[]
  >([]);
  const [organization, setOrganization] = useState<Organization>();

  // Fetch organization and members when the component loads.
  useEffect(() => {
    async function initializeOrganization() {
      // get organization
      const organizationsResult = await getOrganization(orgID);
      if (organizationsResult) {
        const onlyCoreOrganizationObject = omit(
          organizationsResult,
          "cloud_settings"
        );
        setOrganization(onlyCoreOrganizationObject);
      }
      updateOrganizationMembers(orgID);
      const allRoles = await getRoles(orgID);
      setOrganizationRoleOptions(
        allRoles
          .filter(
            (role) =>
              role.name === "Organization Admin" || role.name === "Member"
          )
          .map((nextRole) => {
            return { label: nextRole.name, value: nextRole.id };
          })
      );
    }
    if (userID && orgID) {
      initializeOrganization();
    }
  }, [userID, orgID]);

  // return an object with client side value names, from the server side object.
  const clientValuesFromServerValues = (serverValues: {
    user_email: string;
  }) => {
    return {
      email: serverValues.user_email,
    };
  };

  const [tableData, setTableData] = useState<any[]>([]);
  const [checkedForDeletion, setCheckedForDeletion] = useState<string[]>([]);
  const [isSelectedAll, setIsSelectedAll] = useState(false);

  const handleSelectAll = () => {
    if (isSelectedAll) {
      setCheckedForDeletion([]);
    } else {
      setCheckedForDeletion(
        tableData.reduce((accum, item) => {
          if (item.user.id && !isOrganizationOwnerRow(item.user.id)) {
            accum.push(item.user.id);
          }

          return accum;
        }, [])
      );
    }
  };

  // Check if all items are selected
  useEffect(() => {
    if (checkedForDeletion.length === 0) {
      setIsSelectedAll(false);
      return;
    }
    for (const item of tableData) {
      if (
        item.user.id &&
        !isOrganizationOwnerRow(item.user.id) &&
        !checkedForDeletion.includes(item.user.id)
      ) {
        setIsSelectedAll(false);
        return;
      }
    }
    setIsSelectedAll(true);
  }, [checkedForDeletion]);

  const updateOrganizationMembers = async (organizationId: string) => {
    try {
      const [memberValues, inviteValues] = await Promise.all([
        getOrganizationMembers(organizationId),
        getOrganizationInvites(organizationId),
      ]);

      const invitesAsMembers = inviteValues.map((invite) => {
        return {
          id: invite.id,
          invited: true,
          organization_id: invite.organization_id,
          user: {
            email: invite.user_email,
          },
        };
      });

      const mergedArray = [...memberValues, ...invitesAsMembers];
      setTableData(mergedArray);
    } catch (error: any) {
      addToast({
        message: error.message,
        variant: "error",
      });
    }
  };

  const getRoleLabelFromId = (roleId: string) => {
    if (!organizationRoleOptions || organizationRoleOptions.length === 0) {
      return "";
    }
    const selectedOption = organizationRoleOptions.find((option) => {
      return option.value === roleId;
    });
    return selectedOption?.label ? selectedOption.label : "";
  };

  const onSubmit = (values: { email: string }) => {
    postOrganizationMember(orgID, values.email.trim())
      .then(() => {
        addToast({
          message: "Member successfully added to organization.",
          variant: "success",
        });
        updateOrganizationMembers(orgID);
        formik.resetForm({
          values: {
            email: "",
          },
        });
        setIsSelectedAll(false);
      })
      .catch((error) => {
        if (error.cause) {
          formik.setErrors(clientValuesFromServerValues(error.cause));
        }
        addToast({
          message: error.message,
          variant: "error",
        });
      });
  };

  const removeMembers = () => {
    deleteOrganizationMembers(orgID, checkedForDeletion)
      .then(() => {
        addToast({
          message: "Member(s) successfully deleted from organization.",
          variant: "success",
        });
        updateOrganizationMembers(orgID);
        formik.resetForm({
          values: {
            email: "",
          },
        });
        setCheckedForDeletion([]);
      })
      .catch((error) => {
        addToast({
          message: error.message,
          variant: "error",
        });
      });
  };

  const cancelOrganizationInvite = (inviteId) => {
    deleteOrganizationInvite(orgID, inviteId)
      .then(() => {
        addToast({
          message: "Invite successfully removed from organization.",
          variant: "success",
        });
        updateOrganizationMembers(orgID);
        formik.resetForm({
          values: {
            email: "",
          },
        });
        setCheckedForDeletion([]);
      })
      .catch((error) => {
        addToast({
          message: error.message,
          variant: "error",
        });
      });
  };

  // Check if a user is the owner of the organization
  const isOrganizationOwnerRow = (id: string) => {
    if (id === organization?.user_id) {
      return true;
    }
    return false;
  };

  // Check if a user is an admin of the organization
  const isOrganizationAdmin = () => {
    if (organization?.role === "Organization Admin") {
      return true;
    }
    return false;
  };

  // Yup validation schema
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .required("Email address is required.")
      .test(
        "test-email-custom",
        "Must be a valid email address.",
        stringIsValidEmail
      )
      .test(
        "test-email-duplication",
        "A member with this email has already been added/invited to the organization",
        (value) => {
          return !tableData.some((member) => {
            return member?.user.email === value;
          });
        }
      ),
  });

  // Initialize formik.
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit,
  });

  // Column definitions
  const columnHelper = createColumnHelper<OrganizationMember>();
  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.user.id, {
        id: "checkbox",
        cell: (info) => {
          const handleCheck = (check: boolean, rowId: string) => {
            if (check) {
              setCheckedForDeletion([...checkedForDeletion, rowId]);
            } else {
              setCheckedForDeletion(
                checkedForDeletion.filter((value) => value !== rowId)
              );
            }
          };
          const value = info.getValue();

          return info.row.original.invited ||
            isOrganizationOwnerRow(value) ? null : (
            <div style={{ width: "40px" }}>
              <Checkbox
                onChange={() => {
                  handleCheck(
                    !checkedForDeletion.includes(value),
                    info.getValue()
                  );
                }}
                checked={checkedForDeletion.includes(value)}
                disabled={
                  !isOrganizationAdmin() || isOrganizationOwnerRow(value)
                }
              />
            </div>
          );
        },
        header: () => (
          <Checkbox
            checked={isSelectedAll}
            onChange={handleSelectAll}
            disabled={
              !isOrganizationAdmin() ||
              !tableData.some((item) => {
                return item.user.id && !isOrganizationOwnerRow(item.user.id);
              })
            }
          />
        ),
        enableSorting: false,
      }),
      columnHelper.accessor((row) => row.user.email, {
        id: "email",
        cell: (info) => {
          const isOrganizationOwner = isOrganizationOwnerRow(
            info.row.original.user.id
          );

          return (
            <div
              className={cn(styles.emailCell, {
                [styles.mutedText]:
                  isOrganizationOwner || !isOrganizationAdmin(),
              })}
            >
              {info.getValue()}
              {isOrganizationOwner && (
                <span className={styles.mutedText}> (Organization Owner)</span>
              )}
              {info.row.original.invited && (
                <span className={styles.invitedRow}> (Invited)</span>
              )}
            </div>
          );
        },
        header: () => <span>User Email</span>,
        enableSorting: false,
      }),
      columnHelper.accessor((row) => row.role_id, {
        id: "role_select",
        cell: (info) => {
          const handleChange = (roleId) => {
            if (info.getValue() === roleId) {
              return;
            }

            updateOrganizationMember(
              orgID,
              info.row.original.user.id,
              roleId
            ).then(() => {
              addToast({
                message: "Members role successfully changed.",
                variant: "success",
              });
              updateOrganizationMembers(orgID);
            });
          };
          return (
            <div style={{ width: "80%" }}>
              {info.row.original.invited ? (
                <Button
                  variant="secondary"
                  size="large"
                  disabled={!isOrganizationAdmin()}
                  onClick={() => {
                    cancelOrganizationInvite(info.row.original.id);
                  }}
                >
                  Revoke Invitation
                </Button>
              ) : (
                <FormDropdown
                  id="role"
                  name="role"
                  label=""
                  placeholder="Select Role"
                  options={organizationRoleOptions}
                  value={getRoleLabelFromId(info.getValue())}
                  onChange={handleChange}
                  disabled={
                    isOrganizationOwnerRow(info.row.original.user.id) ||
                    !isOrganizationAdmin()
                  }
                />
              )}
            </div>
          );
        },
        header: () => <span>Organization Member Role</span>,
        enableSorting: false,
      }),
    ],
    [checkedForDeletion, isSelectedAll, tableData, organizationRoleOptions]
  );

  // Create react table
  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <TitleSection
          title="Organization Members"
          subTitle={
            <>
              Manage members and users of your organization and set their access
              level. You can invite new users up to the number of seats allowed
              in your plan.
            </>
          }
        />

        {isOrganizationAdmin() && (
          <form onSubmit={formik.handleSubmit}>
            <TextField
              id="email"
              name="email"
              label="Email address"
              placeholder={"Email address of a user..."}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              error={formik.errors.email}
            />
            <div className={styles.buttonSection}>
              <Button
                type="submit"
                className={styles.inviteButton}
                disabled={!formik.isValid || !formik.dirty}
              >
                Add to Organization
              </Button>
            </div>
          </form>
        )}
        <div className={styles.tableWrapper}>
          <Table table={table} />
        </div>
        <div className={styles.removeButtonSection}>
          <Button
            variant="crucial"
            onClick={removeMembers}
            disabled={checkedForDeletion.length === 0}
          >
            Delete Selected Members
          </Button>
        </div>
      </div>
    </div>
  );
}
