import styles from "./RoleCard.module.scss";
import Typography from "components/Typography/Typography";
import { useNavigate } from "react-router-dom";
import { type Role } from "pages/OrganizationHome/types";

interface RoleCardProps {
  orgID: string;
  role: Role;
}

export function RoleCard({ orgID, role }: RoleCardProps) {
  const { id, name } = role;
  const navigate = useNavigate();

  const handleTitleClick = () => {
    navigate(`/org/${orgID}/roles/${id}/settings`);
  };

  return (
    <div className={styles.card}>
      <div
        onClick={() => {
          handleTitleClick();
        }}
      >
        <Typography className={styles.title} variant="h3">
          {name}
        </Typography>
      </div>
    </div>
  );
}
