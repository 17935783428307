import { useCallback, useMemo } from "react";
import { useAssetsState } from "store";
import { DATA_LOAD_STATUS } from "config/appConfig";

import ButtonsDropdown from "../ButtonsDropdown";

import {
  CONNECTION_TYPE_CONFIG,
  CONNECTION_TYPES_TO_EXCLUDE,
} from "../../constants";
import { getConnectionTypeIcon } from "../../utils";

const ConnectionTypesDropdown = () => {
  const {
    connectionTypes,
    createdItem: { assetOperationType },
    loadInfo: { connectionTypesLoadStatus, dataLoadStatus },
  } = useAssetsState((slice) => slice.connections);

  const { setOperationType, setCreatedItemData } = useAssetsState(
    (slice) => slice.connectionsActions
  );

  const options = useMemo(() => {
    // https://linear.app/zerve-ai/issue/FRO-1185/hide-databricks-connection-option
    // Get rid of connection types filtering when DataBricks BE functionality is ready
    return Object.values(connectionTypes)
      .filter(
        (item) =>
          !CONNECTION_TYPES_TO_EXCLUDE.some(
            (typeToExclude) => typeToExclude === item.connection_type
          )
      )
      .map((typeData) => {
        const { name, connection_type, ...rest } = typeData;
        const icon = getConnectionTypeIcon(connection_type);
        const label = CONNECTION_TYPE_CONFIG[connection_type]?.label ?? name;

        return {
          name,
          label,
          icon,
          connection_type,
          ...rest,
        };
      });
  }, [connectionTypes]);

  const handleSelect = useCallback((data) => {
    setOperationType("create");
    setCreatedItemData({
      name: "",
      connection_type_id: data.id,
    });
  }, []);

  const disabled =
    connectionTypesLoadStatus !== DATA_LOAD_STATUS.LOADED ||
    dataLoadStatus !== DATA_LOAD_STATUS.LOADED ||
    assetOperationType === "create" ||
    assetOperationType === "edit";

  return (
    <ButtonsDropdown
      label="Create Connection"
      onSelect={handleSelect}
      options={options}
      disabled={disabled}
    />
  );
};

export default ConnectionTypesDropdown;
