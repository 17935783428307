import styles from "./UserSettingsSideMenu.module.scss";
import { useMemo } from "react";
import { BiUser } from "react-icons/bi";
import { useFlags } from "launchdarkly-react-client-sdk";
import SideNavContent from "components/common/SideNavContent/SideNavContent";
import { NavigationTabs } from "components/common/NavigationTabs/NavigationTabs";
import Typography from "components/Typography/Typography";

export function UserSettingsSideMenu() {
  const flags = useFlags();

  const navItems = useMemo(() => {
    const items = [
      { key: "profile", label: "Profile", to: "/settings/personal-profile" },
      {
        key: "preferences",
        label: "Preferences",
        to: "/settings/personal-preferences",
      },
    ];

    if (flags.enableBilling) {
      items.push({
        key: "billing",
        label: "Billing",
        to: "/settings/personal-billing",
      });
    }

    items.push(
      {
        key: "security",
        label: "Security",
        to: "/settings/personal-security",
      },
      {
        key: "developer-api",
        label: "Developer API",
        to: "/settings/developer-api",
      }
    );

    if (flags.enableGitIntegration) {
      items.push({
        key: "git-integration",
        label: "Git Integration",
        to: "/settings/git-integration",
      });
    }

    return items;
  }, [flags]);

  return (
    <SideNavContent>
      <div className={styles.title}>
        <BiUser className={styles.icon} />
        <Typography variant="caption1" className={styles.text}>
          Individual Settings
        </Typography>
      </div>

      <NavigationTabs orientation="vertical" items={navItems} />
    </SideNavContent>
  );
}
