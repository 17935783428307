import { useState, useEffect, useMemo, useCallback } from "react";
import pluralize from "pluralize";
import { deleteAssetFromCanvas } from "api/http/assets-service";
import { useToastsState, useAssetsState } from "store";
import { useConfirmModal } from "hooks/useConfirmModal";
import useConnectionActions from "hooks/assets/useConnectionActions";
import {
  DATA_SPECIFIC_USAGE_TYPES,
  LABEL,
  USAGE_TYPE,
  USAGE_TYPE_CONFIG,
  USAGE_TYPES_OPTIONS,
  TEXT,
  getAssetOption,
} from "./constants";
import type { TableRowData, UsageType } from "./types";

type EmptyState = {
  title: string;
  text?: string;
};

const getEmptyState = (usageType: UsageType, searchQueryIsEmpty: boolean) => {
  switch (usageType) {
    case USAGE_TYPE.canvases: {
      return {
        title: searchQueryIsEmpty
          ? TEXT.notFoundTitle
          : TEXT.noCanvasFoundTitle,
        text: searchQueryIsEmpty
          ? TEXT.noCanvasFoundText
          : TEXT.noCanvasFoundSearchText,
      };
    }
    case USAGE_TYPE.queries: {
      return {
        title: searchQueryIsEmpty ? TEXT.notFoundTitle : TEXT.noQueryFoundTitle,
        text: searchQueryIsEmpty
          ? TEXT.noQueryFoundText
          : TEXT.noQueryFoundSearchText,
      };
    }
    case USAGE_TYPE.all:
    default: {
      return {
        title: searchQueryIsEmpty
          ? TEXT.notFoundTitle
          : TEXT.notFoundSearchTitle,
        text: searchQueryIsEmpty ? TEXT.notFoundText : TEXT.notFoundSearchText,
      };
    }
  }
};

const useAssetUsage = (assetId: string) => {
  const { openConfirmModal } = useConfirmModal();
  const addToast = useToastsState((slice) => slice.addToast);

  const { data } = useAssetsState((slice) => slice.connections);
  const { getConnection } = useConnectionActions();

  const [actionInProgress, setActionInProgrees] = useState<boolean>(false);
  const [selectedAssetId, setSelectedAssetId] = useState<string>(assetId);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [tableData, setTableData] = useState<TableRowData[]>([]);
  const [selectedTypeOption, setSelectedTypeOption] = useState(
    USAGE_TYPES_OPTIONS[0]
  );

  const assetsOptions = useMemo(() => {
    return Object.values(data).map((item) => {
      return getAssetOption(item);
    });
  }, [data]);

  const selectedAsset = useMemo(() => {
    return data[selectedAssetId];
  }, [data, selectedAssetId]);

  useEffect(() => {
    if (!selectedAsset) {
      return;
    }

    const assetUsageData: TableRowData[] = DATA_SPECIFIC_USAGE_TYPES.reduce(
      (res: TableRowData[], type) => {
        const typeData = selectedAsset.connection?.[type];

        if (typeData?.length) {
          const transformedTypeData: TableRowData[] = typeData.reduce(
            (acc: TableRowData[], item) => {
              const dataItemUsageIdPropName =
                USAGE_TYPE_CONFIG[type].dataItemUsageIdPropName;
              const id = item[dataItemUsageIdPropName];

              if (id) {
                acc.push({
                  id,
                  type,
                  name: item?.name || LABEL.unknown,
                });
              }

              return acc;
            },
            []
          );

          res = res.concat(transformedTypeData);
        }

        return res;
      },
      []
    );

    setTableData(assetUsageData);
  }, [selectedAsset]);

  const filteredTableData = useMemo(() => {
    const selectedType = selectedTypeOption.value;
    const dataByType =
      selectedType !== USAGE_TYPE.all
        ? tableData.filter((item) => {
            return item.type === selectedType;
          })
        : tableData;
    const searchText = searchQuery.toLocaleLowerCase();
    const dataBySearchQuery = searchText
      ? dataByType.filter((item) => {
          return item?.name?.toLocaleLowerCase().includes(searchText);
        })
      : dataByType;

    return dataBySearchQuery;
  }, [selectedTypeOption.value, searchQuery, tableData]);

  const searchQueryIsEmpty = !searchQuery;

  const emptyState = useMemo<EmptyState | undefined>(() => {
    if (filteredTableData.length > 0) {
      return;
    }

    return getEmptyState(selectedTypeOption.value, searchQueryIsEmpty);
  }, [filteredTableData.length, selectedTypeOption.value, searchQueryIsEmpty]);

  const deleteAssetUsage = useCallback(
    (id: string, usageType: UsageType) => {
      if (usageType === USAGE_TYPE.canvases) {
        return deleteAssetFromCanvas({
          assetType: "connection",
          canvasId: id,
          assetId: selectedAssetId,
          assetVersionId: selectedAssetId,
        });
      } else {
        return Promise.reject(new Error("Not supported yet"));
      }
    },
    [selectedAssetId]
  );

  const deleteAssetUsageGroup = useCallback(
    async (data: [string, UsageType][]) => {
      const dataLength = data?.length;
      if (!dataLength) {
        return;
      }

      const confirmed = await openConfirmModal({
        title: "Remove Connection",
        message: `Are you sure you want to remove connection from the selected ${pluralize(
          "item",
          dataLength
        )}?`,
        confirmButtonLabel: "Remove",
        confirmButtonVariant: "crucial",
      });

      if (confirmed) {
        setActionInProgrees(true);
        let failedItemsCount = 0;
        // for BE it's better when we make requests one by one until we have a bulk API
        for (const args of data) {
          try {
            await deleteAssetUsage(...args);
          } catch (err) {
            failedItemsCount += 1;
          }
        }

        await getConnection(selectedAssetId, {
          showSuccessMessage: false,
          showErrorMessage: true,
          errorMessage:
            "Something went wrong! Please refresh the page and try again",
        });
        setActionInProgrees(false);

        if (failedItemsCount > 0) {
          const allItemsFailed = failedItemsCount === dataLength;
          const toastVariant = allItemsFailed ? "error" : "warning";
          const toastMessage = `Failed to remove ${pluralize(
            "item",
            failedItemsCount,
            failedItemsCount > 1 || dataLength > 1
          )}`;
          addToast({
            variant: toastVariant,
            message: toastMessage,
          });
        } else {
          addToast({
            variant: "info",
            message:
              dataLength > 1 ? `${dataLength} items removed` : "Item removed",
          });
        }
      }
    },
    [selectedAssetId, deleteAssetUsage, getConnection]
  );

  return {
    actionInProgress,
    selectedAsset,
    tableData: filteredTableData,
    emptyState,
    searchQuery,
    setSearchQuery,
    assetsOptions,
    selectedTypeOption,
    setSelectedTypeOption,
    deleteAssetUsageGroup,
    setSelectedAssetId,
  };
};

export default useAssetUsage;
