// list of icons with tooltips that are shown when hovering over the row
import Tooltip from "components/common/Tooltip/Tooltip";
import { type ReactNode } from "react";
import cn from "classnames";

import styles from "./RowButtons.module.scss";

type RowSize = "small" | "medium";
type RowButtonProp = {
  icon: any;
  tooltip: ReactNode;
  hotKey?: string;
  onClick: (e: any) => void;
};

type RowButtonsProps = {
  buttons: RowButtonProp[];
  size?: RowSize;
  className?: string;
};

const iconSizes = {
  small: 12,
  medium: 16,
};

const RowButtons = ({
  buttons,
  size = "small",
  className,
}: RowButtonsProps) => {
  return (
    <div className={cn(styles["row_buttons"], className)}>
      {buttons.map(({ tooltip, hotKey, icon, onClick }, index) => {
        const Icon = icon;

        return (
          <Tooltip
            key={index}
            text={tooltip}
            placement="top"
            hotkey={hotKey}
            withArrow
          >
            <div
              key={index}
              className={styles["row_buttons__button"]}
              onClick={onClick}
            >
              <Icon size={iconSizes[size]} />
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
};

export default RowButtons;
