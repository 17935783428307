import styles from "./LoadingScreen.module.scss";
import spinner from "assets/animations/zerve_spinner.gif";

type LoadingScreenProps = {
  text?: string;
};

export default function LoadingScreen({ text }: LoadingScreenProps) {
  return (
    <div className={styles.loadingScreen}>
      <img className={styles.spinner} src={spinner} />
      {text ? <p className={styles.text}>{text}</p> : null}
    </div>
  );
}
