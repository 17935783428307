import styles from "./CanvasesTable.module.scss";
import { useMemo, type MouseEvent } from "react";
import cn from "classnames";
import moment from "moment";
import {
  flexRender,
  useReactTable,
  getCoreRowModel,
  type ColumnDef,
} from "@tanstack/react-table";
import {
  BiCopy,
  BiDownArrowAlt,
  BiUpArrowAlt,
  BiShareAlt,
} from "react-icons/bi";
import { useToastsState } from "store";
import FavoriteIconButton from "components/common/FavoriteIconButton/FavoriteIconButton";
import KebabMenu from "components/common/KebabMenu/KebabMenu";
import { EditableTitle } from "components/canvases/EditableTitle/EditableTitle";
import type { CanvasCardType } from "models/canvas";
import type { ContextMenuItem } from "components/common/ContextMenu/ContextMenu";
import CheckmarkIcon from "components/common/ContextMenu/MenuOption/CheckmarkIcon";
import Tooltip from "components/common/Tooltip/Tooltip";
import { SORTING_COLUMN } from "config/localPreferences";
import {
  SORTING_DIRECTION,
  SORTING_COLUMN_SORTING_TYPE,
  SORTING_TYPE,
} from "config/appConfig";
import { CANVAS_PERMISSION_LABEL } from "config/canvasConfig";
import { getSortingDirectionLabel } from "utils/sort";

const NOOP = () => {};

const SORT_ORDER_ICON_MAP = {
  [SORTING_DIRECTION.ASCENDING]: <BiUpArrowAlt size={16} />,
  [SORTING_DIRECTION.DESCENDING]: <BiDownArrowAlt size={16} />,
};

enum TABLE_COLUMN {
  NAME = "name",
  CREATED_AT = "createdAt",
  LAST_MODIFIED = "lastModified",
  SHARED_BY = "sharedBy",
  SHARE_DATE = "shareDate",
  PERMISSION = "permission",
  OPTIONS = "options",
}

const SORTING_COLUMN_TABLE_COLUMN = Object.freeze({
  [SORTING_COLUMN.name]: TABLE_COLUMN.NAME,
  [SORTING_COLUMN.createdAt]: TABLE_COLUMN.CREATED_AT,
  [SORTING_COLUMN.lastModified]: TABLE_COLUMN.LAST_MODIFIED,
  [SORTING_COLUMN.shareDate]: TABLE_COLUMN.SHARE_DATE,
  [SORTING_COLUMN.sharedBy]: TABLE_COLUMN.SHARED_BY,
  [SORTING_COLUMN.permission]: TABLE_COLUMN.PERMISSION,
});

const SORTABLE_TABLE_COLUMNS = Object.freeze(
  Object.values(SORTING_COLUMN_TABLE_COLUMN)
);

const TABLE_COLUMN_SORTING_COLUMN = Object.freeze(
  Object.fromEntries(
    Object.entries(SORTING_COLUMN_TABLE_COLUMN).map(([key, value]) => [
      value,
      key,
    ])
  )
);

const TABLE_COLUMNS_DEFAULT = new Set([
  TABLE_COLUMN.NAME,
  TABLE_COLUMN.CREATED_AT,
  TABLE_COLUMN.LAST_MODIFIED,
  TABLE_COLUMN.OPTIONS,
]);

const TABLE_COLUMNS_WITH_SHARING_INFO = new Set([
  TABLE_COLUMN.NAME,
  TABLE_COLUMN.CREATED_AT,
  TABLE_COLUMN.LAST_MODIFIED,
  TABLE_COLUMN.SHARED_BY,
  TABLE_COLUMN.SHARE_DATE,
  TABLE_COLUMN.PERMISSION,
  TABLE_COLUMN.OPTIONS,
]);

interface CanvasTableProps {
  items: CanvasCardType[];
  processedItemsIds?: Record<string, string> | null;
  selectedItemsIds?: Record<string, string> | null;
  showSharingInfo?: boolean;
  sortingColumn: SORTING_COLUMN;
  sortingDirection: SORTING_DIRECTION;
  onChangeSorting: ({
    column,
    direction,
  }: {
    column: SORTING_COLUMN;
    direction: SORTING_DIRECTION;
  }) => void;
  onGetCanvasOptions: (canvas: CanvasCardType) => ContextMenuItem[];
  onRowClick: (id: string, event: MouseEvent<HTMLElement>) => void;
}

const renderSortingDirectionIcon = ({
  sortingColumn,
  sortingDirection,
}: {
  sortingDirection: SORTING_DIRECTION;
  sortingColumn: SORTING_COLUMN;
}) => {
  const icon = SORT_ORDER_ICON_MAP[sortingDirection];

  const sortingType =
    SORTING_COLUMN_SORTING_TYPE[sortingColumn] || SORTING_TYPE.TEXT;

  const iconTooltip = getSortingDirectionLabel({
    sortingType,
    sortingDirection,
  });

  return (
    <Tooltip text={iconTooltip} placement="top">
      {icon}
    </Tooltip>
  );
};

export function CanvasesTable({
  items,
  processedItemsIds,
  selectedItemsIds,
  showSharingInfo = false,
  sortingColumn,
  sortingDirection,
  onChangeSorting,
  onGetCanvasOptions,
  onRowClick,
}: CanvasTableProps) {
  const addToast = useToastsState((slice) => slice.addToast);

  const sortByTableColumn = (columnId: string) => {
    const newSortingColumn = TABLE_COLUMN_SORTING_COLUMN[columnId];

    if (!newSortingColumn) {
      return;
    }

    if (newSortingColumn !== sortingColumn) {
      onChangeSorting({
        column: newSortingColumn as SORTING_COLUMN,
        direction: sortingDirection,
      });
    } else {
      onChangeSorting({
        column: sortingColumn,
        direction:
          sortingDirection === SORTING_DIRECTION.ASCENDING
            ? SORTING_DIRECTION.DESCENDING
            : SORTING_DIRECTION.ASCENDING,
      });
    }
  };

  const columnConfig = useMemo<
    Record<TABLE_COLUMN, ColumnDef<CanvasCardType>>
  >(() => {
    return {
      [TABLE_COLUMN.NAME]: {
        id: TABLE_COLUMN.NAME,
        enableSorting: false,
        header: () => (
          <div className={cn(styles["table__header-label"])}>Canvas name</div>
        ),
        cell: (cellContext) => {
          const canvas = cellContext.row.original;
          return (
            <div key={canvas.id} className={styles["name-column"]}>
              <CheckmarkIcon
                className={cn(styles.checkmarkIcon, {
                  [styles.hidden]: !selectedItemsIds?.[canvas.id],
                })}
              />
              {canvas.onStarClick && (
                <FavoriteIconButton
                  className={styles["name-column__favorite"]}
                  isFavorite={canvas.starred}
                  handleToggleFavorite={canvas.onStarClick}
                />
              )}
              <EditableTitle
                placeholder="Type canvas name..."
                containerClassName={styles.canvasName}
                title={canvas.name || "Untitled"}
                // first priority is table row double click
                switchToEditingOnDoubleClick={false}
                readOnly={!canvas.onTitleChange}
                onSave={canvas.onTitleChange || NOOP}
              />
            </div>
          );
        },
      },
      [TABLE_COLUMN.CREATED_AT]: {
        id: TABLE_COLUMN.CREATED_AT,
        enableSorting: false,
        header: () => (
          <div className={cn(styles["table__header-label"])}>Created</div>
        ),
        cell: (cellContext) => {
          const canvas = cellContext.row.original;
          return <div>{moment(canvas.createdAt).format("DD MMMM YYYY")}</div>;
        },
      },
      [TABLE_COLUMN.LAST_MODIFIED]: {
        id: TABLE_COLUMN.LAST_MODIFIED,
        enableSorting: false,
        header: () => (
          <div className={cn(styles["table__header-label"])}>Last modified</div>
        ),
        cell: (cellContext) => {
          const canvas = cellContext.row.original;
          return (
            <div>{moment(canvas.lastModified).format("DD MMMM YYYY")}</div>
          );
        },
      },
      [TABLE_COLUMN.SHARED_BY]: {
        id: TABLE_COLUMN.SHARED_BY,
        enableSorting: false,
        header: () => (
          <div className={cn(styles["table__header-label"])}>Shared by</div>
        ),
        cell: (cellContext) => {
          const sharedBy = cellContext.row.original.sharedBy ?? "";

          return (
            <div className={styles.shared_by_cell}>
              <div className={styles.shared_by_text} title={sharedBy}>
                {sharedBy}
              </div>
              {sharedBy && (
                <div className={styles.shared_by_actions}>
                  <BiCopy
                    className={styles.action_icon}
                    size={16}
                    onClick={(e) => {
                      e.stopPropagation();
                      navigator.clipboard.writeText(sharedBy).then(() => {
                        addToast({
                          message: "Copied email to clipboard",
                          variant: "info",
                        });
                      });
                    }}
                  />
                </div>
              )}
            </div>
          );
        },
      },
      [TABLE_COLUMN.SHARE_DATE]: {
        id: TABLE_COLUMN.SHARE_DATE,
        enableSorting: false,
        header: () => (
          <div className={cn(styles["table__header-label"])}>Share date</div>
        ),
        cell: (cellContext) => {
          const shareDate = cellContext.row.original.shareDate;
          const shareDateValue = shareDate
            ? moment(shareDate).format("DD MMMM YYYY")
            : "";

          return <div>{shareDateValue}</div>;
        },
      },
      [TABLE_COLUMN.PERMISSION]: {
        id: TABLE_COLUMN.PERMISSION,
        enableSorting: false,
        header: () => (
          <div className={cn(styles["table__header-label"])}>Permission</div>
        ),
        cell: (cellContext) => {
          const permission = cellContext.row.original.permission;
          const permissionLabel = permission
            ? CANVAS_PERMISSION_LABEL[permission]
            : "";
          return (
            <div className={styles.permission_cell}>{permissionLabel}</div>
          );
        },
      },
      [TABLE_COLUMN.OPTIONS]: {
        id: TABLE_COLUMN.OPTIONS,
        enableSorting: false,
        header: () => (
          <div
            className={cn(
              styles["table__header-label"],
              styles["table__header-label__options"]
            )}
          >
            Options
          </div>
        ),
        cell: (cellContext) => {
          const canvas = cellContext.row.original;
          const options = onGetCanvasOptions(canvas);
          return (
            <div className={styles["options-column"]}>
              {canvas.onCanvasShare && (
                <BiShareAlt
                  size={16}
                  className={styles["options-column__share"]}
                  onClick={canvas.onCanvasShare(canvas.name)}
                />
              )}
              <KebabMenu
                menuOptions={options}
                classes={{ trigger: styles["options-column__menu"] }}
              />
            </div>
          );
        },
      },
    };
  }, [onGetCanvasOptions, selectedItemsIds]);

  const columns = useMemo(() => {
    const columnsToShow = showSharingInfo
      ? TABLE_COLUMNS_WITH_SHARING_INFO
      : TABLE_COLUMNS_DEFAULT;

    return [...columnsToShow.values()].map((column) => columnConfig[column]);
  }, [showSharingInfo, columnConfig]);

  const table = useReactTable({
    data: items,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className={styles.table}>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const columnId = header.id;
                const columnSortingEnabled = SORTABLE_TABLE_COLUMNS.some(
                  (item) => item === columnId
                );
                const sortedByColumn =
                  SORTING_COLUMN_TABLE_COLUMN[sortingColumn] === columnId;
                return (
                  <th
                    key={columnId}
                    className={cn(styles["table__header-cell"], {
                      [styles.header_cell_sortable]: columnSortingEnabled,
                    })}
                    onClick={() => {
                      if (columnSortingEnabled) {
                        sortByTableColumn(columnId);
                      }
                    }}
                  >
                    <div className={styles.header_cell_content}>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {sortedByColumn ? (
                        <div className={styles.sort_icon_wrapper}>
                          {renderSortingDirectionIcon({
                            sortingColumn,
                            sortingDirection,
                          })}
                        </div>
                      ) : null}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>

        <tbody>
          {table.getRowModel().rows.map((row) => {
            const canvas = row.original;
            return (
              <tr
                key={row.id}
                className={cn(styles["table__row"], {
                  [styles["table__row_processed"]]:
                    processedItemsIds?.[canvas.id],
                  [styles["table__row_selected"]]:
                    selectedItemsIds?.[canvas.id],
                })}
                onClick={(event) => {
                  onRowClick(canvas.id, event);
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className={styles["table__cell"]}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
