import { useEffect, type PropsWithChildren } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import * as monaco from "monaco-editor";
import { useUserSettingsState } from "store";
import {
  configureMonacoEditor,
  createInlineCompletionProvider,
} from "components/common/CodeEditor/config";

// apply theme and disable loading from CDN
configureMonacoEditor();

export const SUPPORTED_CODE_COMPLETION_LANGUAGES = [
  "python",
  "r",
  "sql",
  "markdown",
];

type CodeEditorConfigProps = PropsWithChildren;

export function CodeEditorConfig({ children }: CodeEditorConfigProps) {
  const flags = useFlags();
  const aiModel = useUserSettingsState((slice) => slice.preferences.aiModel);
  const autoComplete = useUserSettingsState(
    (slice) => slice.preferences.autoComplete
  );

  // enable code completion assistant
  useEffect(() => {
    if (!flags.enableCodeComplete || !autoComplete) {
      return;
    }

    const disposables = SUPPORTED_CODE_COMPLETION_LANGUAGES.map((language) => {
      const inlineCompletionProvider = createInlineCompletionProvider(
        aiModel,
        language
      );
      const { dispose } = monaco.languages.registerInlineCompletionsProvider(
        language,
        inlineCompletionProvider
      );
      return dispose;
    });

    return () => {
      disposables.forEach((dispose) => dispose());
    };
  }, [flags.enableCodeComplete, aiModel, autoComplete]);

  return <>{children}</>;
}
