import { createContext, useMemo } from "react";
import moment from "moment";
import {
  getBillingProducts,
  downgradeBillingPlan,
  upgradeBillingPlan,
} from "api/http/user-settings-service";

import { useUserState, useUserBillingState } from "store";
import {
  type HistoryData,
  type TableHistory,
} from "models/settings/billing-interface";

export const BillingSettingsContext = createContext<any>({});

export const BillingSettingsProvider = ({ children }: any) => {
  const userID = useUserState((slice) => slice.userID);

  const setBillingInfoStore = useUserBillingState(
    (slice) => slice.setBillingInfo
  );
  const tier = useUserBillingState((slice) => slice.user_billing.tier);
  const payment_history = useUserBillingState(
    (slice) => slice.user_billing.payment_history
  );

  const billingTips = useMemo(() => {
    const { billing_plan, next_billing } = tier;
    const plan = billing_plan?.product.name ?? "-";
    const totalPaymentValue = billing_plan?.total_payment || 0;
    const totalPayment = `$${totalPaymentValue / 100} USD`;
    const period = billing_plan?.period ?? "month";
    const nextBilling = moment(next_billing).isValid()
      ? moment(next_billing).format("D.MM.YYYY")
      : "-";

    return [
      { title: plan, subtitle: "Plan" },
      { title: totalPayment, subtitle: "Total Payment" },
      { title: nextBilling, subtitle: "Next Billing" },
      { title: period, subtitle: "Billing Period" },
    ];
  }, [tier]);

  const preparedHistory = useMemo(() => {
    return payment_history.map((entry: HistoryData): TableHistory => {
      const { created, invoice_pdf, payment_amount, status, subscription } =
        entry;

      return {
        created,
        invoice_pdf,
        payment_amount,
        status,
        plan_name: subscription.billing_plan.product.name,
      };
    });
  }, [payment_history]);

  /*
  TODO: This useEffect is not used currently as the backend code for the endpoints has changed
  This will have to be updated to use the new endpoints in the future.

  Ticket:
  https://linear.app/zerve-ai/issue/FRO-1277/call-the-new-billing-endpoint-to-get-customer-subscription-information

  useEffect(() => {
    getBillingInfo();
  }, [userID]);
  */

  const getBillingInfo = async (): Promise<any> => {
    const result = await getBillingProducts(userID).then((res) => res);
    setBillingInfoStore(result);
  };

  const onUpgrade = async (priceId) => {
    if (!userID) {
      return;
    }
    const url = window.location.href;
    const data = {
      success_url: url,
      cancel_url: url,
      user_id: userID,
      price: priceId,
    };

    const result = await upgradeBillingPlan(data).then((res) => res);
    const { url: redirectUrl } = result;
    window.open(redirectUrl, "_self");
  };

  const onDowngrade = async () => {
    const subscriptionId = tier.stripe_id;
    if (!subscriptionId) {
      return;
    }
    void downgradeBillingPlan(subscriptionId);
    setTimeout(() => {
      void getBillingInfo();
    }, 1000);
  };

  const contextValue = {
    getBillingInfo,
    onUpgrade,
    onDowngrade,
    billingTips,
    preparedHistory,
  };

  return (
    <BillingSettingsContext.Provider value={contextValue}>
      {children}
    </BillingSettingsContext.Provider>
  );
};
